<template>
  <header class="header-admin py-4">
    <div class="row mx-0 justify-content-between align-items-center">
      <div class="col-2 mt-4">
        <div class="logo">
          <router-link to="/"
          ><img src="../../../assets/img/logo.svg" class="logo"
          /></router-link>
        </div>
      </div>
      <div
          class="col-10 d-flex align-items-center log-in__header justify-content-end"
          :class="{ active: isActivemenu }"
      >

        <div class="row content-list h-100">
          <ul class="nav-list d-flex ps-0 gap-4">
            <li
                v-for="(item, i) in menuList"
                :key="i"
                :class="['nav-item', { active: item.active }]"
                class="position-relative"

            >
              <router-link
                  class="nav-links position-relative"
                  :to="item.url"
                  @click="item.id == 10 ? isActivemenu  : isActivemenu = false"
                  @click.stop="
                      this.activeClean(parseInt(item.id));
                      $router.push(item.url);
                    "

              >
                      <span class="position-relative z-3" v-if="sideBarShow">{{
                          item.name
                        }}</span>
              </router-link>
              <ul class="submenu" v-if="item.submenuShow" :class="{'submenu-drop': item.submenu}">
                <li
                    :class="{ active: item.active }"
                    v-for="(item, i) in item.submenu"
                    :key="i"
                    v-html="item.name"
                    @click="isActivemenu = false"
                    @click.stop="
                        this.activeClean(parseInt(item.id));
                        $router.push(item.url);
                      "
                ></li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="header-admin__block">
          <div class="header-admin__name pe-3 ps-3">
            <exit-button>Выход ({{ this.user.username }})</exit-button>

          </div>
          <div class="header-admin__photo col-auto">
            <img
                class="avatar"
                :src="this.apiDomine + '/web/uploads/avatar/no_photo.jpg'"
                alt="user"
            />
          </div>
        </div>
      </div>
      <div class="col-md-4 header-admin__menu">
        <button>
          <img
              class="header-admin__open"
              v-if="!isActivemenu"
              src="../../../assets/img/menu.svg"
              alt=""
              @click="isActivemenu = true"
          />
          <img
              class="header-admin__close"
              v-else
              src="../../../assets/img/menuclose.svg"
              alt=""
              @click="isActivemenu = false"
          />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import UiSwitch from '@/components/ui/UiSwitch.vue';
import LogInBlock from '@/components/ui/LogInBlock.vue';
import {defineComponent} from 'vue';
import ExitButton from "@/components/ui/ExitButton.vue";

export default defineComponent({
  components: {
    ExitButton,
    LogInBlock,
    UiSwitch,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiDomine: this.$store.getters.getApiDomine,
      city: 'Екатеринбург',
      popupPagesShow: false,
      popuCitiesShow: false,
      popupPagesShowMobile: false,
      popuCitiesShowMobile: false,
      isShowMenu: false,
      menuShow: false,
      list: [],
      isShowMenu: false,
      isActivemenu: false,
      buttonsDropdown: [
        {
          title: 'Анкеты',
          showDropdown: false,
          entries: [
            {text: 'Мои анкеты', link: '/girl/lk'},
            {text: 'Инди', link: '#'},
            {text: 'Premium', link: '#'},
            {text: 'Статистика', link: '#'},
            {text: 'Расписание работы', link: '/girl/schedule'},
            {text: 'Заказы', link: '#'},
          ],
        },
        {
          title: 'Продвижение',
          showDropdown: false,
          entries: [
            {text: 'Продвижение на сайте', link: '/girl/promotion'},
            {text: 'Продвижение в Telegram', link: '#'},
          ],
        },
        {
          title: 'Настройка и баланс',
          showDropdown: false,
          entries: [
            {text: 'Финансы', link: '/girl/billing'},
            {text: 'Настройка аккаунта', link: '#'},
          ],
        },
        {
          title: 'О сервисе',
          showDropdown: false,
          entries: [
            {
              text: 'Не скромно, об этом - EGOZA',
              link: '#',
              blank: 'https://www.google.com/',
            },
            {
              text: 'Для СМИ и авторов статей',
              link: '#',
              blank: 'https://www.google.com/',
            },
            {
              text: 'История эскорт сайта EGOZA',
              link: '#',
              blank: 'https://www.google.com/',
            },
            {
              text: 'Telegram канал  ',
              link: '#',
              blank: 'https://t.me/EgozaBiz',
            },
          ],
        },
      ],
      menuList: [
        {
          id: 10,
          name: 'Модерация',
          ico: 'item1.svg',
          url: '#',
          submenuShow: false,
          submenu: [
            {
              id: 101,
              name: 'Отзывы',
              url: '/admin/moderation/feedback',
            },
            {
              id: 102,
              name: 'Платежи',
              url: '/admin/moderation/billing',
            },
            {
              id: 50,
              name: 'Видео-проходки',
              url: '/admin/videoPenetration'
            },
          ],
        },
        {
          id: 20,
          name: 'Анкеты',
          ico: 'item7.svg',
          url: '/admin/profiles',
        },
        {
          id: 30,
          name: 'Пользователи',
          ico: 'item7.svg',
          url: '/admin/users',
        },
        {
          id: 40,
          name: 'Списки',
          ico: 'item7.svg',
          url: '/admin/lists'
        },
      ],
      // admin
      user: this.$store.getters.getUser,
      sideBarShow: true,
      showLoader: true,
      // admin
    };
  },
  methods: {
    // admin
    sidebarHide() {
      for (let i of this.menuList) {
        i.submenuShow = false;
      }
      this.sideBarShow = false;
    },

    sidebarShow() {
      this.sideBarShow = true;
    },
    sidebarToggle() {
      if (this.sideBarShow) {
        for (let i of this.menuList) {
          i.submenuShow = false;
        }
        this.sideBarShow = false;
      } else {
        this.sideBarShow = true;
      }
    },
    activeClean(id) {
      this.sidebarShow();
      for (let i of this.menuList) {
        i.active = false;

        i.submenuShow = false;
        if (i.id === id) {
          i.active = true;
          i.submenuShow = true;
        }

        if (i.submenu) {
          for (let sub of i.submenu) {
            sub.active = false;
            if (sub.id === id) {
              sub.active = true;
              i.active = true;
            }
          }
        }
      }
    },

    // admin

    checkWindowSize() {
      this.isSmallScreen = window.innerWidth <= 1370;
      if (this.isSmallScreen) {
        this.buttonsDropdown.forEach((button) => {
          button.showDropdown = true;
        });
      } else {
        this.buttonsDropdown.forEach((button) => {
          button.showDropdown = false;
        });
      }
    },
    toggleDropdown(index) {
      this.buttonsDropdown.forEach((button, i) => {
        if (i === index) {
          button.showDropdown = !button.showDropdown;
        } else {
          button.showDropdown = false;
        }
      });
    },
    menuHide() {
      this.menuShow = false;
    },
    getCategories() {
      this.list = this.$router.options.routes.filter((route) => route.name);
    },
    pickCity(namePath) {
      this.city = namePath;
      this.popuCitiesShow = false;
      this.popuCitiesShowMobile = false;
    },
    popupCitiesHide() {
      this.popuCitiesShow = false;
    },
    showMenu() {
      this.isShowMenu = true;
    },
    popupCitiesHideMobile() {
      this.popuCitiesShowMobile = false;
    },
    popupPagesHideMobile() {
      this.popupPagesShowMobile = false;
    },
    swipingMenu(
        event,
        element,
        elementHeight,
        startY,
        deltaY,
        classNonTransition,
        swipeResult,
        touchEnd
    ) {
      if (classNonTransition) element.classList.add('non-transition');
      else element.classList.remove('non-transition');
      if (deltaY > 25 || deltaY < -25) element.style.bottom = `-${deltaY}px`;
      if (touchEnd && swipeResult === 'Up' && (deltaY > 25 || deltaY < -25)) {
        this.isShowMenu = true;
        element.style.bottom = '0';
      } else if (
          touchEnd &&
          swipeResult === 'Down' &&
          (deltaY > 25 || deltaY < -25)
      ) {
        this.isShowMenu = false;
        element.style.bottom = '0';
      }
    },
    openSubmenu(i, nav) {
      const currentRef = `userMenuLink_${i}`;
      const element = this.$refs[currentRef][0];
      const isChild = element.querySelectorAll('.header-user-menu__submenu li')
          .length
          ? true
          : false;
      if (!isChild) {
        //this.$router.push(nav)
        return;
      }
      if (element.classList.contains('is-active')) {
        element.classList.remove('is-active');
      } else {
        element.classList.add('is-active');
      }
    },
    openSubmenuMobile() {
      const element = event.target;
      const elementWrap = element.closest('li');
      if (elementWrap.classList.contains('is-active')) {
        elementWrap.classList.remove('is-active');
      } else {
        elementWrap.classList.add('is-active');
      }
    },
  },
  created() {
    this.getCategories();
  },
  mounted() {
    if (this.$store.state.user.role.item_name !== 'admin') {
      this.m_setFlash(
          'В доступе отказано',
          'Страница доступна только администраторам',
          'danger'
      );
      window.location.href = '/';
    } else {
      this.showLoader = false;
    }
    window.addEventListener('click', (e) => {
      if (
          e.target.classList.contains('header-admin__btn') ||
          e.target.classList.contains('header-admin__title') ||
          e.target.classList.contains('header-admin__arrow') ||
          e.target.classList.contains('header-admin__open') ||
          e.target.classList.contains('header-admin__close')
      ) {
        return;
      } else {
        this.buttonsDropdown.forEach((button) => {
          button.showDropdown = false;
        });
      }
    });
    window.addEventListener('resize', this.checkWindowSize);
    this.checkWindowSize();
  },
});
</script>

<style lang="scss">
.nav {
  &-list {
    margin-bottom: 0 !important;
    @media (max-width: 1370px) {
      flex-direction: column;
      padding-left: 20px !important;
      margin-top: 10px;
    }
  }

  &-links {
    padding: 11px 15px 11px 15px;
    border-radius: 70px;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    text-decoration: none;
    font-size: 17px;

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: 70px;
      z-index: 1;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      background: #413d51;
      @media (max-width: 1370px) {
        box-shadow: none;
      }

    }

    @media (max-width: 1370px) {
      padding: 0;
      box-shadow: none;
    }
  }

  &-item.active .nav-links {
    background: linear-gradient(to right, #21045d 17%, #db0f00 100%);
    @media (max-width: 1370px) {
      background: none;
    }
  }
}

.submenu-drop {
  position: absolute;
  top: 40px;
  left: 0;
  padding-top: 15px;
  z-index: 99;
  padding: 11px 13px 13px 23px;
  width: 150px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 7px;
  @media (max-width: 1370px) {
    padding: 11px 13px 13px 7px;
  }

  & li {
    color: white;
    cursor: pointer;

  }

  border-radius: 25px;
  background: linear-gradient(to right, #21045d 17%, #db0f00 100%);
  @media (max-width: 510px) {
    min-width: auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: #413d51;
    z-index: -1;
    border-radius: 25px;
  }

  @media (max-width: 1370px) {
    position: static !important;
    background: transparent;
    box-shadow: none;
  }
}

.content-list {
  margin-right: 50px;
}

.header-admin {
  &__name {
    color: #fff;
    cursor: pointer;
    @media (max-width: 1370px) {
      padding: 0 !important;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    @media (max-width: 1370px) {
      gap: 18px;
      flex-direction: row-reverse;
      margin-bottom: 8px;
    }
  }

  &__btns {
    margin-right: 180px;
    @media (max-width: 1370px) {
      flex-direction: column;
      overflow: auto;
      width: 100%;
      margin-right: 0 !important;
    }
  }

  &__close {
    position: fixed;
    right: 25px;
    top: 25px;
    z-index: 9999;
  }

  &__open {
    position: absolute;
    top: 23px;
    right: 35px;
  }

  &__menu {
    display: none !important;
    @media (max-width: 1370px) {
      display: flex !important;
      justify-content: flex-end;
    }
  }
}

.body-backdrop {
  position: fixed;
  backdrop-filter: blur(8px);
  background-color: rgba(#000, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.log-in {
  &__btn {
    button {
      display: inline-block;
      border-radius: 15px;
      transition: 0.3s;
      background-color: #524b5f;
      color: #fff;
      padding: 5px 10px;

      &:hover {
        box-shadow: 0 0 5px rgba(#fff, 0.5) inset;
      }
    }
  }
}

.header-admin {
  &__photo {
    max-width: 50px;
  }
}

.avatar {
  border-radius: 50%;
}

.logo {
  max-width: 160px;
  height: auto;
}
</style>
