<script>
import {defineComponent} from "vue";
import getApi from "@/mixins/getApi";
import CloseX from "@/components/ui/CloseX.vue";
import SwipingMenu from "@/components/ui/SwipingMenu.vue";
import ModalDialog from "@/components/ui/common/ModalDialog.vue";

export default defineComponent({
    name: 'UiSearchDistrict',
    components: {ModalDialog, SwipingMenu, CloseX},
    mixins: [getApi],
    emits: ['searchDistrictClose'],
    props: {
        position: {
            type: String,
            default: 'top'
        },
        show: {
            type: Boolean,
            default: false
        },
        cities:{
            type: Array,
            default: []
        }
    },
    methods: {
        btnClick(e) {
            let btns = document.querySelectorAll('.cat-btn')
            for (let b of btns) {
                b.classList.remove('active')
            }
            e.target.classList.add('active')
            this.selected.city_id = parseInt(e.target.dataset.city)
            this.city_id = this.selected.city_id
            console.log('this.cities', this.cities)

            for (let c of this.cities) {
                if (c.id === this.city_id) {
                    console.log('this.cities', this.cities)
                    this.user.city = c
                    console.log('this.user.city ', this.user.city)
                    this.$store.commit('setCity', c)
                    this.allDistrict = c.districtWithMetro
                    this.district = this.allDistrict
                }
            }

            this.mobileStep = 2;
        },

        startData() {

            this.city = []
            for (let c of this.cities) {
                if (c.districtWithMetro.length !== 0) {
                    this.city.push(c)
                }
            }

            this.showMobile = true
        },

        addInfoService(e, i) {
            this.selected.district_id = parseInt(e.target.dataset.district)

            for (const d of this.user.city.districtWithMetro) {

                if(this.selected.district_id === d.id){

                    this.user.district = d
                    this.$store.commit('setDistrict', d)
                }
            }
            this.$emit('searchDistrictClose')
            this.compilationService = this.$store.getters.getCompilationService
            if(this.compilationService.id){
                this.$router.push(`/compilation-service/${this.compilationService.id}/${i.city_id}`);
            }else {
                this.$router.push(`/compilation-service/8/${i.city_id}`);
            }

            //this.$emit('modalClose'); this.$emit('close');
        },


        compareObjects(o1, o2) {
            let k = '';
            for (k in o1) if (o1[k] != o2[k]) return false;
            for (k in o2) if (o1[k] != o2[k]) return false;
            return true;
        },

        searchFor(e) {
            let toSearch = this.$refs.searchDistrict.value;
            let results = [];

            for (let m of this.allDistrict) {

                if (m.name.toUpperCase().includes(toSearch.toUpperCase())) {

                    results.push(m)

                }

            }
            this.district = results;
        },
        stepPrev(){
            if(this.mobileStep > 1){
                this.mobileStep = this.mobileStep - 1
            }
        }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            compilationService: this.$store.getters.getCompilationService,
            addActive: '',
            city_id: 1,
            searchMyDistrict: '',
            city: [],
            allDistrict: [],
            district: [],
            myDistrict: 'Метро',
            selected: {
                city_id: 1,
                district_id: 1
            },
            mobileStep: 1,
            showMobile: true,
        }
    },
    computed: {
        isMobile() {
            return this.$store.getters['getIsMobile']
        }
    },
    mounted() {

        this.allDistrict = this.district
    },
    watch:{
        cities: function (){
            if(this.cities.length > 0 ){
                this.startData()
            }
        }
    }

})
</script>

<template>

    <div
        :class="['search-list p-3', {top: this.position === 'top'}, {bottom: this.position === 'bottom'}]"
         v-if="show && !isMobile">
        <div class="row">
            <div class="col-12">
                <close-x v-if="this.position === 'bottom'" :preset="'top'" @click.stop="this.$emit('searchDistrictClose')"/>
            </div>
        </div>

        <div class="row h-100">
            <div class="col-5 left-menu ">
                <div class="d-flex flex-column">
                    <button
                        class="cat-btn text-start p-1 ps-2 mb-1 hover-btn"
                        v-for="(item, i) in city"
                        :key="i"
                        v-html="item.name"
                        @click="btnClick($event, item.districtWithMetro)"
                        aria-current="page"
                        href="#"
                        :id="'district'+ item.id"
                        :data-city="item.id"
                    ></button>
                </div>

            </div>
            <div class="col-12 col-md-7 h-100 ">
                <input
                    ref="searchDistrict"
                    type="text"
                    placeholder="Поиск"
                    class="mb-3"
                    @keyup="searchFor"
                >
                <div class="long-list">
                    <p v-if="this.district.length === 0">Выберите город</p>
                    <p
                        v-for="(item, i) in this.district"
                        :key="i"
                        v-html="item.name"
                        @click="addInfoService($event, item)"
                        :data-district="item.id"
                        class="text-grey cursor"
                    ></p>
                </div>

            </div>
        </div>
        <close-x v-if="this.position === 'top'" :preset="'bottom'" @click="this.$emit('searchDistrictClose')"/>
    </div>
    <div class="m-search-list" v-if="show && isMobile">
        <modal-dialog
            @modalClose="this.showMobile = false;this.$emit('close');"
            :id="'uiSearchDistrictModal'"
            :show="showMobile"
            :dialog-params="{height: '60vh', overflow: 'auto'}"
            :btn-display="false"
        >
            <div class="step-1" v-if="mobileStep === 1">
                <div class="row w-100">
                    <div class="col-auto">
                        <div class="prev" v-if="mobileStep > 1" @click.stop="stepPrev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col">
                        <h4 class="text-center">Выбери город</h4>
                    </div>
                </div>
                <div class="row mt-5 w-100">
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            v-for="(item, i) in city"
                            :key="i"
                            v-html="item.name"
                            @click="btnClick"
                            aria-current="page"
                            href="#"
                            :data-city="item.id"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="step-2" v-if="mobileStep === 2">
                <div class="row w-100">
                    <div class="col-auto">
                        <div class="prev" v-if="mobileStep > 1" @click.stop="stepPrev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col">
                        <h4 class="text-center">Выбери станцию</h4>
                    </div>
                </div>
                <div class="row w-100">

                    <div class="col-12">
                        <input
                            ref="searchDistrict"
                            type="text"
                            placeholder="Поиск"
                            class="mb-3 mt-3 w-100"
                            @keyup="searchFor"
                        >
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            v-for="(item, i) in this.district"
                            :key="i"
                            href="#"
                            @click="addInfoService($event, item)"
                        >
                            <span class="ms-3 cursor" :data-ser="item.id">{{ item.name }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </modal-dialog>

    </div>
</template>

<style scoped>
.search-list {
    position: fixed;
    opacity: 10;
    height: 50vh;
    visibility: visible;
    right: calc(50% - 250px);
    width: 500px;
    max-width: 100%;
    z-index: 999;
    pointer-events: all;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #000;

}

.top {
    top: 30px;
}

.bottom {
    bottom: 30px;
}

.long-list {
    height: calc(100% - 45px);
    overflow-y: auto;
}

.left-menu {
    border-right: 1px solid #BDC1D1;
}

.left-menu button {
    color: #525665;
    font-weight: 700;
}

.cursor {
    cursor: pointer;
}

.text-nav {
    display: flex;
    align-items: center;
}

.text-nav span {
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
}

.text-nav img {
    margin-right: 10px;
}

.text-grey {
    color: #525665;
}

.left-menu button.active, .left-menu button.active:hover {
    background: #F5F5F5 url("@/assets/img/row-right-search.svg") no-repeat right;
    border-radius: 14px;
    background-position-x: calc(100% - 10px);
}

.left-menu button:hover {
    background: #F5F5F5;
    border-radius: 14px;
}

.tab-pane img {
    max-width: 17px;
    margin-right: 15px;
}

input {
    border: none;
    background: rgba(218, 218, 218, 0.3);
    border-radius: 100px;
    height: 26px;
    line-height: 24px;
    padding: 15px 15px;
}

@media (max-width: 767px) {
    .search-list {
        position: fixed;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        width: 100%;
        background-color: #fff;
        box-shadow: 0 -5px 12px 3px rgba(0, 0, 0, 0.065);
        border-radius: 15px 15px 0 0;
        transition: 0.4s;
        z-index: 99999;
        padding-bottom: 18px;
        height: 60vh;
        padding-left: 10px;
        padding-right: 10px;
    }

    .text-nav {
        justify-content: center;
    }

    .text-nav span {
        border-bottom: 1px solid #BDC1D1;
        display: flex;
        align-items: center;
        line-height: 33px;
    }

}

.prev {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #fff;
    width: 34px;
    height: 34px;
    border: 1px solid #BDC1D1;
    border-radius: 5px;
}

</style>