<script setup>
import UiLoader from "@/components/ui/UiLoader.vue";
import Slider from "@/components/blocks/Slider.vue";
import axios from "axios";
import sklonyator from 'sklonyator';
import AllTags from "@/components/ui/common/tags/AllTags.vue";
import QuizBlock from "@/components/blocks/QuizBlock.vue";

import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies()
const store = useStore()
const user = store.getters.getUser
const apiUrl = store.getters.getApiUrl

const city = ref('')
const showLoader = ref(true)
const compilationView = ref([])

const userCity = computed(() => {
    return store.getters.getCity
})

const getSelectionView = async () => {
    if (cookies.isKey('views')) {

        const views = JSON.parse(cookies.get('views'))

        try {
            for (let id of views) {
                const URL = `${apiUrl}api-girl/get-profile-by-id&auth=${user.username}:${user.auth_key}&id=${id}&city_id=${user.city?.id || 1}`
                const { data } = await axios.get(URL)

                compilationView.value.push(data)
            }
        } catch (error) {
            console.error('getSelectionView: ', error)
        } finally {
            showLoader.value = false
        }
    }
}

const sklonyatorName = () => {
    if (user.city) {
        city.value = sklonyator.incline(user.city.name)
    }
}

onMounted(async () => {
    await getSelectionView()
    sklonyatorName()
})

watch(userCity, async (newCity, oldCity) => {
    if (newCity && oldCity && newCity !== oldCity.name) {
        showLoader.value = true
        compilationView.value = []
        await getSelectionView()
        sklonyatorName()
    }
})

</script>

<template>
    <main>
        <div class="block-offer">
            <div class="container">
                <div class="row">
                    <div class="col-12 title-cat roboto-bold p-3">
                        Вы смотрели
                        <span> в {{ city }}</span>
                    </div>
                </div>
                <ui-loader :show="showLoader" :preset="'block'" />
                <div class="slider-offer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-3" v-for="p in compilationView" :key="p.id">
                                <Slider :profile="p" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-offer">
            <quiz-block />
        </div>
        <div class="block-offer">
            <div class="container">
                <all-tags />
            </div>
        </div>
    </main>
</template>

<style scoped>
@media screen and (max-width: 767px) {
    main {
        margin-top: 30px;
    }
}

.title-cat {
    color: #2D2F3A;
    font-size: 28px;
}
</style>