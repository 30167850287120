<template>
	<div class="main">
		<div class="reg">
		<div class="container">
			
				<div class="reg-form">
					<tabs  class="tabs">
					<tab name="Вход" class="reg-log-in">
						<form>
							<div class="reg__inner">
								<div class="reg__block-input">
									<label class="reg__label">
										<input
											v-model="authFormData.login"
											type="text" name="" id="" class="reg__input" >
										<span v-if="!authFormData.login" class="reg__span">Логин<sup>*</sup></span>
									</label>

									<label class="reg__label">
										<input
											v-model="authFormData.password"
											type="text" name="" id="" class="reg__input" >
										<span v-if="!authFormData.password" class="reg__span">Пароль<sup>*</sup></span>
									</label>

									<label class="reg-checkbox">
										<input type="checkbox" class="reg-checkbox__input">
										<span class="reg-checkbox__checked"></span>
										<span class="reg-checkbox__name">Забыли пароль? <a href="#">Восстановить</a></span>
									</label>
								</div>
								<div class="reg__submit">
									<button class=" hover-btn" type="submit">Зарегистрироваться</button>
								</div>
							</div>
						</form>
					</tab>
					<tab name="Регистрация" class="reg-seg-in">
						<form>
							<div class="reg__inner">
								<div class="reg__block-input">
								
									<label class="reg__label">
										<input
											v-model="regFormData.nickNameReview"
											type="text" name="" id="" class="reg__input" >
										<span v-if="!regFormData.nickNameReview" class="reg__span">Ник для отзывов<sup>*</sup></span>
									</label>

									<label class="reg__label">
										<input
											v-model="regFormData.email"
											type="text" name="" id="" class="reg__input" >
										<span v-if="!regFormData.email" class="reg__span">Email<sup>*</sup></span>
									</label>

									<label class="reg__label">
										<input
											v-model="regFormData.telegram"
											type="text" name="" id="" class="reg__input" >
										<span v-if="!regFormData.telegram" class="reg__span">Telegram<sup>*</sup></span>
									</label>

									<label class="reg__label">
										<input
											v-model="regFormData.login"
											type="text" name="" id="" class="reg__input" >
										<span v-if="!regFormData.login" class="reg__span">Логин<sup>*</sup></span>
									</label>

									<label class="reg__label">
										<input
											v-model="regFormData.password"
											type="text" name="" id="" class="reg__input" >
										<span v-if="!regFormData.password" class="reg__span">Пароль<sup>*</sup></span>
									</label>

									<label class="reg-checkbox">
										
										<input type="checkbox" class="reg-checkbox__input">
										<span class="reg-checkbox__checked"></span>
										<span class="reg-checkbox__name">Согласие с <a href="./policy.pdf" class="text-decoration-underline" target="_blank">политикой конфиденциальности</a> и <a href="./user_agreement_for_advertisers.pdf" target="_blank" class="text-decoration-underline">пользовательским соглашением</a>. Подтверждаю, что мне есть 18 лет.</span>
									</label>
								</div>
								<div class="reg__submit">
									<button class=" hover-btn" type="submit">Зарегистрироваться</button>
								</div>
							</div>
						</form>
					</tab>
				</tabs>
				</div>
				
			</div>
		</div>
	</div>

</template>

<script>
	import Vue from 'vue';
	import {Tabs, Tab} from 'vue3-tabs-component';
	export default {
		components: {
			Tabs,
			Tab
		},
		data() {
			return {
				regFormData: {
					nickNameReview: '',
					email: '',
					telegram: '',
					login: '',
					password: '',
				},
				authFormData: {
					login: '',
					password: '',
				}
			}
		}
	}
</script>


<style lang="scss" scoped>
@import '@/styles/index';
.reg {
	background-color: #646568;
	margin-top: -155px;
	padding-top: 155px;
	// margin-bottom: 155px;
	padding-bottom: 155px;
	//background-image: url('@/assets/img/reg/bg.webp');
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	// background: linear-gradient(to right, #72666A, #201F36);
	//height: 70vh;
	&__block-input {
		display: flex;
		flex-direction: column;
		// background-color: #A29A9D;
		// border-radius: 19px;
		// padding: 35px 0;
		// max-width: 500px;
		// margin: 0 auto;
	}
	&__inner {
		max-width: 320px;
		margin: 0 auto;
	}

	&__label {

		width: 320px;
		margin: 0 auto;
		border-radius: 12px;
		background-color: #fff;
		margin-bottom: 10px;
		position: relative;
	}
	&__input {
		color: #333;
		font-size: 16px;
		line-height: 50px;
		padding: 0 10px;
		&:focus + .reg__span {
			display: none;
		}
	}
	&__span {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
		color: #BDC1D1;
		font-size: 16px;
		cursor: text;
		sup {
			color: red;
		}
	}
	&__submit {
		margin: 15px auto 0;
		button {
			display: block;
			width: 100%;
			padding: 15px 0;
			color: #fff;
			border-radius: 12px;
			background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
		}
	}
}
.reg-form {
	display: flex;
	flex-direction: column;
	background-color: #A29A9D;
	border-radius: 19px;
	padding: 35px 0;
	max-width: 500px;
	margin: 0 auto;
}
.tab {
	&__item {
		color: #fff;
		background-color: red;
		
	}
}
.tabs {
	ul {
		display: flex;
		justify-content: center;
		margin: 0 0 30px;
		padding-left: 0;
	}
	li + li {
		margin-left: 15px;
	}
	li {
		a {
			padding: 10px 25px;
			border-radius: 100px;
			text-decoration: none;
			background: linear-gradient(93deg, rgba(114, 102, 106, 0.30) 0%, rgba(82, 75, 95, 0.30) 50.09%, rgba(32, 31, 54, 0.30) 99.15%);
			color: #FFF;
			font-size: 16px;
			&.is-active {
				background: linear-gradient(to right, #72666A, #201F36);
			}
		}
	}
}
.reg-checkbox {
	margin-top: 15px;
	position: relative;

	display: flex;

	&__name {
		padding-left: 20px;
		color: #525665;
		font-size: 14px;
		line-height: 120%;
		a {
			color: #525665;
			font-weight: 800;
			line-height: 116.8%;
		}
	}
	&__input {
		appearance: none;
		position: absolute;
		opacity: 0;
	}
	&__checked {
		display: block;
		width: 16px;
		height: 16px;
		border: solid 1px #BDC1D1;
		border-radius: 4px;
		background-color: #fff;
		position: relative;
		position: absolute;
		flex: 0 0 16px;
		left: 0;
		top: -0.1em;
	}
	&__input:checked ~ &__checked {
		&::after {
			content: '';
			background-image: url('@/assets/img/check.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			display: block;
			width: 10px;
			height: 10px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}
	}
}
</style>
