<script>

export default {
    name: 'HintInput',
    emits: ['select', 'citynames'],
    props: {
        array: {
            type: Array,
            default: []
        },
        id: {
            type: String
        },
        name: {
            type: String,
            require: true
        },
        class: {
            type: String
        },
        placeholder: {
            type: String
        },
        current: {
            type: String,
            default: ''
        }
    },
    methods: {
        selectHint(obj) {
            this.value = obj.name

            this.$emit('select', obj)
            this.hints = []
            this.showResult = false
        },
        filterArray(){
            this.hints = this.array.filter(it => it.name.toLowerCase().includes(this.value.toLowerCase()))
        },
        input() {
            this.$emit('citynames', this.value)
            if (this.value === "") { return }
            this.filterArray()
            this.showResult = true
        },
        menuHide(e){
            this.showResult = false
        }
    },
    mounted() {
        
    },
    watch: {
         array:{
            handler: function() {
                this.filterArray() 
            },
            deep: true
        },
      
        current: function(data) {
            if (data) {
                this.value = data
            }
        }
    },
    data() {
        return {
            value: '',
            hints: [],
            showResult: false,
        }
    }
}
</script>

<template>
    <div class="hint-input">
        <div class="row">
            <div class="col-12">
                <input @input="this.input()" type="text" :name="this.name" :id="this.id" :class="this.class"
                    v-model="this.value" :placeholder="this.placeholder">
            </div>
            <div class="col-12 position-relative" v-if="this.showResult">
                <ul class="hint-result" v-click-outside="menuHide">
                    <li v-for="(item, index) in this.hints" @click="this.selectHint(item)" :key="index">{{ item.name }}
                    </li>
                </ul>
            </div>
        </div>
    </div>


</template>

<style scoped lang="scss">
.hint-input input {
    border-radius: 12px;
    padding: 3px 15px;
    color: #333;
    line-height: 30px;
    width: 100%;
}

.hint-input input:not(.is-invalid) {
    border: solid 1px #BDC1D1;
}

.hint-result {
    position: absolute;
    top: 0;
    right: 13px;
    z-index: 9999;
    box-shadow: 1px 1px 3px #2d2f3a;
    background-color: #ffffff;
    border: 1px solid rgba(189, 193, 209, 0.31);
    border-radius: 0.5rem;
    width: calc(100% - 25px);
    margin-bottom: 0;
    padding-left: 0;
}

.hint-result li {
    padding-left: 1rem;
    padding-right: 1rem;
}

li {
    cursor: pointer;
}

li:hover {
    background-color: rgba(189, 193, 209, 0.31);
    font-weight: bold;
}
</style>