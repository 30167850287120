<script setup></script>
<template>
    <button>
        <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="36"
                height="36"
                rx="18"
                stroke="#BDC1D1"
            />
            <path
                d="M10.6464 18.6464C10.4512 18.8417 10.4512 19.1583 10.6464 19.3536L13.8284 22.5355C14.0237 22.7308 14.3403 22.7308 14.5355 22.5355C14.7308 22.3403 14.7308 22.0237 14.5355 21.8284L11.7071 19L14.5355 16.1716C14.7308 15.9763 14.7308 15.6597 14.5355 15.4645C14.3403 15.2692 14.0237 15.2692 13.8284 15.4645L10.6464 18.6464ZM26 18.5L11 18.5L11 19.5L26 19.5L26 18.5Z"
                fill="#BDC1D1"
            />
        </svg>
    </button>
</template>
<style lang="scss" scoped></style>
