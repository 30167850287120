<script setup>
import FutureTimer from "@/components/ui/FutureTimer.vue";

defineProps({
    promo: {
        type: Object,
        required: true,
        default: {}
    }
})

const emits = defineEmits(['update'])

const formatNumberWithSpaces = (number) => {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
</script>

<template>
    <div class="promo-card p-3 mb-2 mb-md-4">
        <div class="promo-card-head d-flex justify-content-between mb-2">
            <div class="m-0">
                <div>{{ promo.title }}</div>
            </div>
            <div class="text-end">
                <div class="form-check form-switch d-flex justify-content-end mb-1">
                    <input class="form-check-input" type="checkbox" role="switch" :checked="promo.checked" @change="emits('update', {promo, status: $event.target})">
                </div>
                <p class="m-0">{{ formatNumberWithSpaces(promo.price) }} ₽</p>
            </div>
        </div>
        <div class="promo-card-body p-2 mb-3">
            <div class="promo-picture mb-2">
                <picture>
                    <img :src="promo.image" alt="promo">
                </picture>
            </div>
            <p class="mb-0 px-2">{{ promo.description }}</p>
        </div>
        <div class="promo-card-bottom">
            <p class="m-0 mb-1">{{ promo.conditions ? 'Условия' : '' }}</p>
            <p class="m-0">{{ promo.conditions }}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
// card
.promo-card {
    color: #fff;
    border-radius: 12px;
    background-color: #2D2F3A;
    width: calc(100% / 3 - 10px);

    @media screen and (max-width: 1245px) {
        width: calc(100% / 2 - 10px);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    &-head {
        &>div {
            min-width: 26%;
        }

        p {
            font-size: 18px;
            font-weight: 600;

            @media screen and (max-width: 567px) {
                font-size: 12px;
            }
        }

        .form-switch .form-check-input {
            width: 42px;
            height: 21px;
            cursor: pointer;
        }

        .form-switch .form-check-input {
            --bs-form-switch-bg: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF8zMDQxXzM4NzcpIj4KPGNpcmNsZSBjeD0iOS43NSIgY3k9IjEwLjUiIHI9IjguNzUiIGZpbGw9IiNEOUQ5RDkiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9kXzMwNDFfMzg3NyIgeD0iMCIgeT0iMC43NSIgd2lkdGg9IjE5LjUiIGhlaWdodD0iMTkuNSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0Lz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMC41Ii8+CjxmZUNvbXBvc2l0ZSBpbjI9ImhhcmRBbHBoYSIgb3BlcmF0b3I9Im91dCIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwLjQzODg4OSAwIDAgMCAwIDAuNDAzOTI5IDAgMCAwIDAgMC4zOTkyNjcgMCAwIDAgMSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93XzMwNDFfMzg3NyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd18zMDQxXzM4NzciIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==) !important;
        }

        .form-check-input:checked:focus,
        .form-switch .form-check-input:checked {
            --bs-form-switch-bg: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF8zNTE4Xzc2ODgpIj4KPGNpcmNsZSBjeD0iOS43NSIgY3k9IjEwLjUiIHI9IjguNzUiIGZpbGw9IiMwOEIxMTkiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9kXzM1MThfNzY4OCIgeD0iMCIgeT0iMC43NSIgd2lkdGg9IjE5LjUiIGhlaWdodD0iMTkuNSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0Lz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMC41Ii8+CjxmZUNvbXBvc2l0ZSBpbjI9ImhhcmRBbHBoYSIgb3BlcmF0b3I9Im91dCIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwLjQzODg4OSAwIDAgMCAwIDAuNDAzOTI5IDAgMCAwIDAgMC4zOTkyNjcgMCAwIDAgMSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93XzM1MThfNzY4OCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd18zNTE4Xzc2ODgiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==) !important;
        }

        .form-check-input:focus,
        .form-check-input {
            background-color: #232323;
            border-color: #525665;
            ;
        }

        .form-check-input:checked:focus,
        .form-check-input:checked {
            background-color: #BDC1D1;
        }

        .form-check-input:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    &-body {
        background-color: #2D2F3A;
        border-radius: 10px;
        box-shadow: 0px 2.58px 44.54px 0px #D2C3C2D6;

        .promo-picture {
            background-color: #fff;
            border-radius: 8px 8px 0 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 216px;
                object-fit: contain;

                @media screen and (max-width: 767px) {
                    height: 158px;
                }
            }
        }

        p {
            font-size: 12px;
            min-height: 90px;

            @media screen and (max-width: 567px) {
                font-size: 10px;
            }
        }
    }

    &-bottom {
        p:nth-child(1) {
            font-weight: 600;
            font-size: 18px;

            @media screen and (max-width: 567px) {
                font-size: 14px;
            }
        }

        p:nth-child(2) {
            font-size: 12px;

            @media screen and (max-width: 567px) {
                font-size: 10px;
            }
        }
    }
}
</style>