<template>
    <section class="promo-usability__container">
        <h2 class="">Удобства сервиса</h2>
        <div class="promo-usability__list">
            <div class="promo-usability__item">
                <div class="promo-usability__item__header">
                    <h3>Привычный мессенджер</h3>
                    <p>Общайтесь с пользователями сайта привычным образом</p>
                </div>
                <div class="promo-usability__item__image">
                    <img
                        class="img-fluid"
                        src="/img/usability_1.png"
                        alt="img"
                    />
                </div>
                <div class="promo-usability__item__description">
                    <p>
                        В нем можно знакомиться, договариваться о встречах, отправлять фото, да все что угодно, хоть
                        играть в города!
                    </p>
                </div>
            </div>
            <div class="promo-usability__item">
                <div class="promo-usability__item__header">
                    <h3>Life лента</h3>
                    <p>Ведите свой личный блог</p>
                </div>
                <div class="promo-usability__item__image">
                    <img
                        class="img-fluid"
                        src="/img/usability_2.png"
                        alt="img"
                    />
                </div>
                <div class="promo-usability__item__description">
                    <p>
                        Рассказывайте свои мысли и истории, выкладывайте уютную атмосферу ваших апартаментов или
                        сногшибающие фотосеты, важно – сделать пользователей своими фанатами!
                    </p>
                </div>
            </div>
            <div class="promo-usability__item">
                <div class="promo-usability__item__header">
                    <h3>Форма заказа</h3>
                    <p>Представьте как удобно</p>
                </div>
                <div class="promo-usability__item__image">
                    <img
                        class="img-fluid"
                        src="/img/usability_3.png"
                        alt="img"
                    />
                </div>
                <div class="promo-usability__item__description">
                    <p>
                        Каждый день вам будут поступать уведомления, где пользователи пишут, что планируют встречу, ваша
                        задача лишь, откликнуться на его запрос первой!
                    </p>
                </div>
            </div>
            <div class="promo-usability__button mx-auto">
                <GradientButton @click="$emit('fill_questionnaire_clicked')">Заполнить анкету</GradientButton>
            </div>
        </div>
    </section>
</template>

<script>
import GradientButton from '@/components/promo/GradientButton.vue';

export default {
    name: 'promo-usability',
    components: { GradientButton },
};
</script>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-usability__container {
    margin-bottom: 85px;
    > h2 {
        text-align: center;
        @include text-regular-small;
        margin-bottom: 40px;
    }
    .promo-usability__list {
        gap: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        .promo-usability__item {
            background-color: #131326;
            border-radius: 40px;

            .promo-usability__item__header {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                > h3 {
                    padding-top: 40px;
                    @include text-bold-x-small;
                    margin-bottom: 20px;
                }

                > p {
                    text-align: center;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 21px;
                    font-style: normal;
                    padding-inline: 40px;
                }
            }

            .promo-usability__item__image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 20px;
                > img {
                    width: 50%;
                }
            }

            .promo-usability__item__description {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 30px 30px 30px 20px;

                > p {
                    text-align: center;
                    @include text-regular-small;
                }
            }
        }

        .promo-usability__button {
            width: 175px;
        }
    }
}

@include media-breakpoint-up(md) {
    .promo-usability__container {
        margin-bottom: 85px;
        > h2 {
            text-align: start;
            @include text-regular-medium;
        }
        .promo-usability__list {
            display: flex;
            flex-direction: column;

            .promo-usability__item {
                padding: 45px 30px;
                display: grid;
                grid-template-columns: 30% 30% 40%;

                border-radius: 40px;
                .promo-usability__item__header {
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-left: 30px;

                    > h3 {
                        text-align: start;
                        @include text-bold-small;
                        margin-bottom: 20px;
                    }

                    > p {
                        text-align: start;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 26px;
                        font-style: normal;
                        padding: 0;
                    }
                }

                .promo-usability__item__image {
                    padding: 0;
                    > img {
                        width: 100%;
                    }
                }

                .promo-usability__item__description {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 50px;

                    > p {
                        text-align: start;
                        @include text-regular-small;
                    }
                }
            }

            .promo-usability__button {
                width: 200px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-usability__container {
        margin-bottom: 110px;
        > h2 {
            @include text-regular-large;
        }
        .promo-usability__list {
            gap: 40px;

            .promo-usability__item {
                margin-bottom: 95px;
                padding: 60px 60px;
                grid-template-columns: 30% 30% 40%;

                border-radius: 40px;
                .promo-usability__item__header {
                    > h3 {
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 48px;
                        font-style: normal;
                        margin-bottom: 35px;
                    }

                    > p {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 32px;
                        font-style: normal;
                        margin-bottom: 35px;
                    }
                }

                .promo-usability__item__image {
                    > img {
                        width: 100%;
                    }
                }

                .promo-usability__item__description {
                    > p {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 32px;
                        font-style: normal;
                        margin-bottom: 35px;
                    }
                }
            }
        }
    }
}
</style>
