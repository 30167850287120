<script>
import axios from "axios";
import { defineComponent } from "vue";
import { useCookies } from "vue3-cookies";
import CloseX from "@/components/ui/CloseX.vue";
import ModalTgInfo from "@/components/ui/popup/ModalTgInfo.vue";
import { VueDraggableNext } from 'vue-draggable-next'

export default defineComponent({
    name: 'LogInCard',
    components: { ModalTgInfo, CloseX, draggable: VueDraggableNext },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '',
            required: false
        },
        autoredirect: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    methods: {
        onUsernameInput(event){        
            let value = event.target.value.trim();
            if (value.includes(' ')) {
                value = value.replace(/\s/g, '')                
            }
            this.error.all = false;
            this.error.username = false
            const regExp =/^[A-Za-z0-9_]{1,50}$/;            
            const matches = value.match(regExp)            
            if(!value){
                this.user.username = '',
                this.newUserName = ''
            }
            else if(matches?.length)
            {
                this.newUserName = matches[0]; 
            } 
            this.user.username  = this.newUserName;            
        },
        checkForSpaces(){
            this.error.all = false;
            this.error.tg = false
            if (this.user.tg.includes(' ')) {
                this.user.tg = this.user.tg.replace(/\s/g, '')
                return
            }
        },
        log(event) {
            console.log(event)
        },
        clearError(field = false){

            if(field){
                for (let err in this.error) {
                    err = ""
                }
            }else{

                this.error[field] = "";
            }
        },
        testEnter(e) {
            if (e.keyCode === 13) {
                this.loginUser()
            }
        },
        loginUser() {
            this.clearError()
            axios
                .post(this.apiUrl + 'api-user/login', this.user)
                .then((response) => {
                    if (response.data.code) {
                        this.$emit('tgmodal', !response.data.status)
                        this.$emit('loginSuccess')
                    }

                    if (response.data.status) {

                        this.$store.commit('setLogged', true)
                        this.$store.commit(
                            'setUserData',
                            {
                                user_id: response.data.user.id,
                                username: response.data.user.username,
                                auth_key: response.data.user.auth_key,
                                tg: response.data.user.tg,
                                role: response.data.user.role,
                                profile: response.data.user.profile,
                                city_id: response.data.user.city_id || 1,
                                city: response.data.user.city || false
                            }
                        )
                        let userSettings
                        if ( this.cookies.isKey('userSettings')) {
                            userSettings = JSON.parse(this.cookies.get('userSettings'))
                        }else{
                            userSettings = []
                            let expireTimes = 60 * 60 * 12
                           this.cookies.set('userSettings', JSON.stringify(userSettings), expireTimes)
                        }
                        this.$store.commit('setUserSettings', userSettings)

                        let expireTimes = 60 * 60 * 12;
                        if (this.user.savePassword) {
                            expireTimes = '30d';
                        }
                        this.cookies.set('auth_key', response.data.user.auth_key, expireTimes)
                        this.cookies.set('username', response.data.user.username, expireTimes)
                        this.cookies.set('user_id', response.data.user.id, expireTimes)
                        this.cookies.set('role', response.data.user.role, expireTimes)
                        this.cookies.set('profile', response.data.user.profile, expireTimes)
                        this.cookies.set('isLogged', response.data.user.isLogged, expireTimes)
                        this.cookies.set('city_id', response.data.user.city_id || 1, expireTimes)
                        this.cookies.set('userSettings', JSON.stringify(response.data.userSettings), expireTimes)
                        this.tgInfo = true

                        if (response.data.user.role?.item_name === 'girl') {
                            if(this.autoredirect) {
                                this.$router.push('/girl/lk')
                            }
                        }
                        this.$emit('loginSuccess')
                    } else {
                        this.$store.commit('setLogged', false)
                        this.cookies.remove('auth_key')
                        this.cookies.remove('username')
                        this.cookies.remove('user_id')
                        this.cookies.remove('role')
                        this.cookies.remove('profile')
                        this.cookies.remove('city_id')
                        this.cookies.set('isLogged', false)


                        if(response.data.field){
                            this.error[response.data.field] = response.data.error
                        }else{
                            this.error.all = response.data.error
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        async registerUser() {
            this.user.role = this.role
            this.validateForm()

            this.user.tg = this.user.tg.replace(/[\s@]/g, '');
            if (!this.isInvalid) {
                this.isEnabledRegisterButton = false
                await axios
                .post(this.apiUrl + 'api-user/register', this.user)
                .then((response) => {
                    if (response.data.status) {
                        this.user.role = {item_name: 'guest'}
                        this.hideDialog()
                    } else {
                        this.user.isLogged = false
                        this.cookies.remove('auth_key')
                        this.cookies.remove('username')
                        this.cookies.remove('user_id')
                        this.cookies.remove('role')
                        this.cookies.remove('profile')
                        this.cookies.remove('city_id')
                        this.cookies.set('isLogged', false)

                        if(response.data.field){
                            this.error[response.data.field] = response.data.error
                        }else{
                            this.error.all = response.data.error
                        }
                    }
                    this.$emit('tgmodal', response.data.status)
                }).catch(error => console.log(error))
                this.isEnabledRegisterButton = true
            }
            
            this.isInvalid = false
        },
        recoveredPassword() {
            axios
                .post(this.apiUrl + 'api-user/send-reset-token&auth=' + this.user.username + ':' + this.user.auth_key, { email: this.user.email })
                .then((response) => {

                    if (response.data.status) {
                        this.newPassword = true
                    }
                    this.error = response.data.error
                })
        },
        hideDialog() {
            this.$emit('hideDialog');
        },
        changePassword() {
            axios
                .post(this.apiUrl + 'api-user/reset-password&auth=' + this.user.username + ':' + this.user.auth_key, {
                    password_reset_token: this.password_reset_token,
                    password: this.password
                })
                .then((response) => {

                    if (response.data.status) {

                    }
                    this.error = response.data.error
                })
        },
        validateForm() {
            let result = true

            if (this.user.name === "") {
                this.isInvalid = true;
                this.error.name = 'Укажите имя';
            }
            if (!this.user.sogl) {
                this.isInvalid = true;
                this.error.sogl = true;
            }
            if (this.role == '') {
                this.isInvalid = true;
                this.error.role = 'Выбирете роль';
            }
            
            let isOrdered = true
            for (let i = 1; i < this.items.length; i++) {
                if (this.items[i].id !== this.items[i - 1].id + 1) {
                    isOrdered = false
                    break;
                }
            }
            if (isOrdered) {
                console.log('порядок');
            }else{
                this.isInvalid = true;
                this.error.role = 'Пройдите тест';
            }

        },
        recoverPassword() {
            this.recover = false
        },
        onRecoverRegisterClick() {
            this.recover = true;
            setTimeout(()=>{
                this.$refs.registerBtn && this.$refs.registerBtn.click()
            },0)
        },
        shuffleArray(array) {
         for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
            }
          return array;
        },
        generateItems() {
         const ids = [1, 2, 3, 4, 5, 6];
         const shuffledIds = this.shuffleArray(ids);

        this.items = [
            { id: shuffledIds[0], img: `${shuffledIds[0]}.jpg` },
            { id: shuffledIds[1], img: `${shuffledIds[1]}.jpg` },
            { id: shuffledIds[2], img: `${shuffledIds[2]}.jpg` },
            { id: shuffledIds[3], img: `${shuffledIds[3]}.jpg` },
            { id: shuffledIds[4], img: `${shuffledIds[4]}.jpg` },
            { id: shuffledIds[5], img: `${shuffledIds[5]}.jpg` }
        ];
        },
        timeSecondClass(){
            this.hasClass = true
            setTimeout(() => {
                this.hasClass = false  
            }, 3000);
        },

      hideRole($event){
        if(!event.target.closest('.select-role')){
          this.showRole = false
        }
      }
    },
    mounted() {},
    updated() {},
    beforeUnmount(){
        
    },
    created() {
        this.generateItems();
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            error: {
                all: false,
                username: false,
                password: false,
                tg: false,
                name: false,
                role: false,
                sogl: false
            },
            hasClass: false,
            enabled: true,
            dragging: false,
            isInvalid: false,
            recover: true,
            newPassword: false,
            password_reset_token: '',
            password: '',
            role: '',
            roleName: 'Выберите вашу роль*',
            showRole: false,
            items: [],
            newUserName: '',
            isEnabledRegisterButton: true
        }
    },
})
</script>

<template>
    <div class="dialog" v-if="show">
        <div @click.stop="showRole = false" class="dialog-content">
            <div class="row">
                <div class="col-12 position-relative register-close-top" style="margin-top: -1rem; background: none;">
                    <close-x :preset="'top'" @click="this.$emit('hideDialog'); recover = true" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 title-log text-center" v-if="this.title">
                    {{ this.title }}
                </div>
            </div>
            <div class="row">
                <div  class="content col-12 p-2 p-md-5 pt-2 pb-2" v-if="recover" @click.stop="() => {error.all = false , hideRole()}">
                    <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active me-3 hover-btn" id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                aria-selected="true">Вход
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link hover-btn" id="pills-profile-tab" data-bs-toggle="pill"                                
                                data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                aria-selected="false" ref="registerBtn">Регистрация
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content p-3 pb-0" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab" tabindex="0">
                            <div class="row">
                                <div class="col-12">
                                    <input
                                        @input="this.error.all = false;this.error.username = false"
                                        :class="error.username ? 'input-log p-2 w-100 invalid' : 'input-log p-2 w-100'"
                                        type="text"
                                        
                                        v-model="this.user.username"
                                        placeholder="Логин для входа*" />
                                </div>
                                <div class="col-12 error-text text-center" v-html="this.error.username ? this.error.username : ''"></div>
                                <div class="col-12 mt-3">
                                    <input
                                        @keyup="testEnter"
                                        @input="this.error.all = false;this.error.password = false"
                                        :class="error.password ? 'input-log p-2 w-100 invalid' : 'input-log p-2 w-100'"
                                        type="password"
                                        v-model="this.user.password"
                                        placeholder="Пароль*"/>
                                </div>
                                <div
                                    class="col-12 error-text text-center"
                                    v-html="this.error.password ? this.error.password : ''"
                                ></div>
                            </div>
                            <div class="row mt-1">
                                <div
                                    class="col-12 error-text text-center"
                                    v-html="this.error.all ? this.error.all : ''"
                                ></div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-1">
                                    <input type="checkbox" class="check" v-model="this.user.savePassword">
                                </div>
                                <div class="col-10 sm-text">
                                    Запомнить пароль
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <button class="btn btn-gradient w-100 p-2 hover-btn" @click="loginUser">Вход</button>
                                </div>
                                <div class="col-12 text-center mt-3">
                                    Забыли пароль? <a class="text-link" :show="show"
                                        @click="recoverPassword">Восстановить</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                            aria-labelledby="pills-profile-tab" tabindex="0">
                            <div class="row">
                                <div class="col-12">
                                    <input
                                        @input="this.error.all = false;this.error.name = false"
                                        :class="error.name ? 'input-log p-2 w-100 invalid' : 'input-log p-2 w-100'"
                                        type="text"
                                        v-model="this.user.name"                                        
                                        placeholder="Имя на сайте*" />
                                </div>
                                <div
                                    class="col-12 error-text text-center"
                                    v-html="this.error.name ? this.error.name : ''"
                                ></div>
                                <div class="col-12 mt-3 telegram">
                                    <input
                                        @input="checkForSpaces"
                                        :class="error.tg ? 'invalid input-log p-2 w-100' : ' input-log p-2 w-100'"
                                        type="text"
                                        v-model="this.user.tg"
                                        placeholder="Telegram" />
                                </div>
                                <div class="row mt-1">
                                <div
                                    class="col-12 error-text text-center"
                                    v-html="this.error.all ? this.error.all : ''"
                                ></div>
                            </div>
                                <div class="col-12 mt-3">
                                    <input
                                        @input="onUsernameInput"                                        
                                        @change="onUsernameInput"  
                                        v-model.trim="user.username"                                     
                                        :class="error.username ? 'invalid input-log p-2 w-100' : ' input-log p-2 w-100'"
                                        type="text"                                        
                                        @keydown.space="(event) => event.preventDefault()"                                                                                
                                        placeholder="Логин для входа*" />
                                </div>
                                <div
                                    class="col-12 error-text text-center"
                                    v-html="this.error.username ? this.error.username : ''"
                                ></div>
                                <div class="col-12 mt-3">
                                    <input
                                        @input="this.error.all = false;this.error.password = false"
                                        :class="error.password ? 'invalid input-log p-2 w-100' : ' input-log p-2 w-100'"
                                        type="text"
                                        v-model="this.user.password"
                                        placeholder="Пароль*">
                                </div>
                                <div
                                    class="col-12 error-text text-center"
                                    v-html="this.error.password ? this.error.password : ''"
                                ></div>
                                <div class="col-12 mt-3 position-relative">
                                    <div @click.stop="this.showRole = true;this.error.all = '';this.error.role = ''"
                                        class="select-role d-flex justify-content-between form-control">
                                        <span v-html="this.roleName"></span>
                                        <i v-if="showRole" class="bi bi-chevron-compact-up"></i>
                                        <i v-else class="bi bi-chevron-compact-down"></i>
                                    </div>
                                    <div class="select-role-child  form-control pt-2" v-if="this.showRole">
                                        <p @click.stop="this.role = 'user'; this.roleName = 'Пользователь';
                                        this.showRole = false">Пользователь</p>
                                        <p @click.stop="this.role = 'girl'; this.roleName = 'Модель';
                                        this.showRole = false">Модель</p>
                                    </div>
                                </div>
                                <div
                                    class="col-12 error-text text-center"
                                    v-html="this.error.role ? this.error.role : ''"
                                ></div>
                                <div class="col-12 overflow-hidden">
                                    <span class="mt-1 mb-1 d-block">Для регистрации необходимо собрать картинку</span>
                                    <draggable class="captcha " :list="items" @change="log">
                                        <div
                                          class="captcha__item"
                                          v-for="el in items"
                                          :key="el.name"
                                        >
                                        <img class="captcha__img" :src="require(`@/assets/img/captcha/${el.img}`)" alt="">
                                        </div>
                                    </draggable>
              
                                </div>
                            </div>
                            <div :class="error.sogl ? 'error-text row mt-3' : ' row mt-3'">
                                <div class="col-1">
                                    <input
                                        @click.stop="error.sogl = false"
                                        type="checkbox"
                                        class="check"
                                        v-model="this.user.sogl">
                                    <span class="input-checked mx-2"></span>
                                </div>
                                <div class="col-11 sm-text">
                                    Согласие с <a href="./policy.pdf" class="text-decoration-underline" target="_blank">политикой конфиденциальности</a> и <a href="./user_agreement_for_advertisers.pdf" target="_blank" class="text-decoration-underline">пользовательским соглашением</a>. Подтверждаю, что мне есть 18 лет.  
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-center">
                                <div class="col-12">
                                    <button class="btn btn-gradient w-100 p-2 hover-btn" 
                                        :disabled="!isEnabledRegisterButton"
                                        @click="registerUser">
                                        Зарегистрироваться
                                    </button>
                                </div>
                                <div class="col-auto mt-3">
                                    Проблемы с регистрацией?

                                    <a class="btn-tg p-2" href="https://t.me/Mark_Egoza" target="_blank">
                                        <img src="@/assets/img/tg.png" alt=""> <span>Поможем</span>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content" v-else>
                    <div class="nav nav-pills mb-3 justify-content-center pt-5">
                        <button class="nav-link active me-3 hover-btn" @click="recover = true">Вход</button>
                        <button class="nav-link active me-3 hover-btn" @click="onRecoverRegisterClick">Регистрация</button>
                    </div>

                    <div class="p-3 p-md-5 pt-0 pt-md-0" v-if="!newPassword">
                        <div class="row">
                            <div class="col-12">
                                <h5>Воссановление пароля</h5>
                                <span>Введите email указанный при регистрации</span>
                            </div>
                            <div class="col-12 mt-3">
                                <input @click.stop="error.email = false"
                                       class="input-log p-2 w-100"
                                       type="text"
                                       v-model="this.user.email"
                                    placeholder="Email"/>
                            </div>
                            <span class="mt-3">На ваш email будет выслано письмо с кодом для изменения пароля</span>
                        </div>
                        <div class="row mt-1">
                            <div
                                class="col-12 error-text text-center"
                                v-html="this.error.email ? this.error.email : ''"
                            ></div>
                        </div> 
                         <div class="row mt-3">
                            <div class="col-12">
                                <button @click="recoveredPassword" class="btn btn-gradient w-100 p-2 hover-btn">Отправить</button>
                            </div>
                            <div class="col-12 text-center mt-3">
                                Не пришло письмо? Проверьте папку спам.
                            </div>
                        </div>
                    </div>
                    <div class="p-3 p-md-5 pt-0 pt-md-0" v-if="newPassword">
                        <div class="row">
                            <div class="col-12">
                                <h5>Воссановление пароля</h5>
                                <span>Введите код из письма</span>
                            </div>
                            <div class="col-12 mt-3">
                                <input class="input-log p-2 w-100" type="text" v-model="this.password_reset_token"
                                    placeholder="Код">
                            </div>
                            <span class="mt-3">Введите новый пароль</span>
                            <div class="col-12 mt-3">
                                <input class="input-log p-2 w-100" type="text" v-model="this.password"
                                    placeholder="Новый пароль">
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12 error-text text-center">{{ this.error }}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <button @click="changePassword" class="btn btn-gradient w-100 p-2 hover-btn">Отправить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<style scoped lang="scss">

body:has(.dialog) {
    overflow: hidden
}
.dialog {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    z-index: 9999;
}

.dialog-content {
    margin: auto;
    padding: 15px;
    border-radius: 10px;
    background: #fff;
    color: #000;
    background-repeat: no-repeat;
    background-position: top right;
    max-width: 500px;
    border: 1px solid #2D2E2E;
}

.dialog-content::-webkit-scrollbar {
  display: none;
}

.dialog-content:has(#pills-profile-tab.active) {
    @media screen and (max-height: 950px) {
        height: calc(100% - 40px);
        max-height: 670px;
        overflow-y: auto;


        .register-close-top {
            top: 50px;
        }

        .title-log {
            font-size: 20px;
            line-height: 24px;
            padding-right: 50px;
        }

    }

    @media screen and (max-width: 767px) {
        .register-close-top {
            top: 50px;
            right: 3px;
        }

        .title-log {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.content {
    border-radius: 24px;
    background: #fff;
    color: #525665;
    border: 1px solid #fff;
}

.btn-gradient {
    border-radius: 15px;
    background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
    color: #fff;
}

.btn-gradient:hover {
    font-weight: 700;
    color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .9);
}

.title-log {
    font-size: 24px;
    color: #2D2F3A;
    line-height: 27px;
}

.text-link {
    font-weight: 700;
    cursor: pointer;
    color: #525665;
    text-decoration: underline;
}

.sm-text {
    font-size: 14px;

    a {
        color: #525665;
    }
}

h5 {
    color: #2D2F3A;
    font-weight: 700;
}

.nav {
    position: relative;
    top: 0;
}

.nav-pills .nav-link.active {
    border-radius: 30px;
    background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
    color: #fff;
}

.nav-pills .nav-link {
    border-radius: 30px;
    background: linear-gradient(93deg, rgba(114, 102, 106, 0.30) 0%, rgba(82, 75, 95, 0.30) 50.09%, rgba(32, 31, 54, 0.30) 99.15%);
    color: #fff;
}

.input-log {
    border-radius: 12px;
    background: #FFF;
    border: solid 1px #BDC1D1;
}

.select-role {
    border-radius: 12px;
    background: #FFF;
    border: solid 1px #BDC1D1;
    cursor: pointer;
}

.select-role-child {
    border-radius: 12px;
    background: #FFF;
    border: solid 1px #BDC1D1;
    position: absolute;
    max-width: 94%;
}

.select-role-child p {
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.select-role-child p:hover {
    background: #BDC1D1;
    border-radius: 5px;
}

select {
    border-radius: 12px;
    background: #FFF;
    border: solid 1px #BDC1D1;
}

select:after {
    background: red;
}

input.check {
    width: 16px;
    max-width: 16px;
    height: 16px;
    border: solid 1px #BDC1D1;
    border-radius: 4px;
    cursor: pointer;
}

.error-text {
    color: #980000;
    font-size: 12px;

    .sm-text a {
        color: #980000;
    }
}

.invalid {
    border: 1px solid #980000 !important;
}
.btn-tg{
    background: #2AABEE;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;

    display: inline-flex;
    align-items: center;
    gap: 10px;
}
.btn-tg:hover{

    box-shadow: 0px 1px 5px #000;
}

.captcha{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 70px;
    gap: 5px;
    padding: 5px;
    border: solid 1px #BDC1D1;
    overflow: hidden;
    &__item{
        cursor: pointer;
        overflow: hidden;
    }
    &__img{
        width: 100%;
        height: 100%;
    }
}

</style>