<script setup>
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
</script>

<template>
    <div class="order-greeting">
        <div class="order-greeting__question mb-3">
            <p>Сделайте поиск проще, заполните бриф, девушки откликнутся сами</p>
        </div>
        <div class="order-greeting__content d-flex justify-content-end gap-2">
            <OrderNextButton
                class="h-100"
                @click="$emit('next-step')"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-greeting {
    .order-greeting__question {
        > p {
            font-size: 16px;            
            line-height: 20px;
        }
    }
}
</style>
