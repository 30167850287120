<script>
import CardWhite from '@/components/ui/CardWhite.vue';
import axios from 'axios';
import UiLoader from '@/components/ui/UiLoader.vue';
import feedbackStatus from '@/pages/Admin/AdminSettings/Lists/FeedbackStatus.vue';
import telegramInputs from '@/mixins/posttg';

export default {
  name: 'FeedbackModeration',
  components: {UiLoader, CardWhite, axios},
  mixins: [telegramInputs],
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      feedback: [],
      offset: null,
      error: '',
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      axios
          .get(
              this.apiUrl +
              'api-feedback/get-feedback-by-params&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key +
              '&limit=' +
              this.offset +
              '&status_id=1'
          )
          .then((response) => {
            if (response.data.status !== false) {
              this.feedback = response.data;
            }else {
              this.feedback = []
            }

            this.error = response.data.error;
          });
    },

    activeFeedback(id, user_id) {
      axios
          .post(
              this.apiUrl +
              'api-feedback/update-feedback-status&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key +
              '&id=' +
              id,
              {status_id: 2}
          )
          .then((response) => {
            this.updateData();
            this.getusersTg(user_id)
          });
    },
    async getusersTg(user_id) {
      try {
        const res = await axios.get(`${this.apiUrl}api-user/get-user-settings&auth=${this.user.username}:${this.user.auth_key}&user_id=${user_id}`)
        const data = res.data.data
        data.find(el => {
          if (el.name == 'feedback' && el.value == 'true') {
            this.telegramInputs(
                user_id,
                'Вы получили новый отзыв'
            );
          }
        })
      } catch (error) {
        console.log('FeedbackModeration tg', error);
      }
    },
    discardFeedback(id) {
      axios
          .post(
              this.apiUrl +
              'api-feedback/update-feedback-status&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key +
              '&id=' +
              id,
              {status_id: 3}
          )
          .then((response) => {
            this.updateData();
          });
    },
  },
};
</script>

<template>
  <card-white class="mt-3">
    <table class="d-flex flex-column">
      <thead class="settings-table__head">
      <tr class="d-flex">
        <td class="text-white settings-table__id-title px-2">ID</td>
        <td class="text-white settings-table__desr-title">Текст</td>
        <td class="text-white settings-table__btns"></td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) of feedback" :key="index" v-if="feedback.length !== 0" class="d-flex  settings-table__rows">
        <td class="settings-table__id-txt text-white px-2">{{item.id}}</td>
        <td class="settings-table__desr-txt text-white">{{item.description}}</td>
        <td class="settings-table__btns">
          <button
              class="btn btn-green p-1 hover-btn"
              @click="activeFeedback(item.id, item.profile.user_id)"
          >
            Опубликовать
          </button>
          <button
              class="btn btn-red p-1 hover-btn"
              @click="discardFeedback(item.id)"
          >
            Отклонить
          </button>
        </td>
      </tr>
      <tr v-else class="d-flex settings-table__rows justify-content-center"><td class="text-white">{{error}}</td></tr>
      </tbody>
    </table>
  </card-white>
</template>

<style scoped lang="scss">
.settings-table {
  max-width: 80%;
  margin: 0 auto;
  color: #fff;

  &__head {
    background-color: #2c2f3c;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #7e7e7e7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__rows {
    background-color: #2c2f3c;
    position: relative;
  }

  &__id-txt,
  &__id-title {
    max-width: 120px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__desr-txt,
  &__desr-title {
    max-width: 1000px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__btns {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: end;
    margin-right: 20px;
    gap: 0px 8px;
    align-items: center;
  }
}

.btn-red {
  background: linear-gradient(
    0deg,
    rgba(236, 34, 34, 1) 0%,
    rgba(170, 13, 5, 1) 100%
  );
  display: inline-block;
  border: solid 1px #2c2f3c;
  font-size: 10px;
  border-radius: 4px;
  color: #fff !important;
  transition: 0.3s;
  max-height: 40px;
}

.btn-green {
  background: linear-gradient(
    0deg,
    rgba(23, 125, 33, 1) 0%,
    rgba(63, 82, 54, 1) 100%
  );
  border: solid 1px #2c2f3c;
  font-size: 10px;
  border-radius: 4px;
  color: #fff !important;
  transition: 0.3s;
  max-height: 40px;
}

@media (max-width: 470px) {
  .settings-table{
    &__btns {
      flex-direction: column;
      margin-right: 5px;
      justify-content: center;
    }

    .btn-green{
      margin-right: 0 !important;
    }
  }
}
</style>
