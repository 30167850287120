import Main from '@/pages/Main';
import Registration from '@/pages/Registration';
import KeptWomen from '@/pages/KeptWomen';
import Escort from '@/pages/Escort';
import Indie from '@/pages/Indie';
import Salon from '@/pages/Salon';
import AdminMain from '@/pages/Admin/AdminMain';
import AdminSettings from '@/pages/Admin/AdminSettings';
import AnketaGirls from '@/pages/AnketaGirls';
import { createRouter, createWebHistory } from 'vue-router';
import Profiles from '@/pages/Admin/Profiles.vue';
import QuizGirls from '@/pages/Quiz/QuizGirls.vue';
import QuizMen from '@/pages/Quiz/QuizMen.vue';
import QuizTs from '@/pages/Quiz/QuizTs.vue';
import QuizGirlsOrder from '@/pages/Quiz/QuizGirlsOrder';
import QuizTsOrder from '@/pages/Quiz/QuizTsOrder.vue';
import AdminModeration from '@/pages/Admin/AdminModeration.vue';
import Lk from '@/pages/Girl/Lk.vue';
import View from '@/pages/Girl/View.vue';
import Schedule from '@/pages/Girl/Schedule.vue';
import Promotion from '@/pages/Girl/Promotion.vue';
import AccSettings from '@/pages/Girl/AccSettings.vue';
import MobileStreamsPage from '@/pages/MobileStreamsPage.vue';
import Collections from '@/pages/Collections.vue';
import PromotionTg from '@/pages/Girl/PromotionTg.vue';
import PromoPage from '@/pages/Promo/PromoPage.vue';
import VideoPenetration from '@/pages/Admin/VideoPenetration.vue';
import UserOrdersPage from '@/pages/UserOrdersPage.vue';
import GirlOrdersPage from '@/pages/GirlOrdersPage.vue';

/**
 * Страница получающая массив анкет из vuex
 */
import CompilationRating from '@/pages/CompilationRating.vue';
import Billing from '@/pages/Girl/Billing.vue';
import AdminModerationBilling from '@/pages/Admin/AdminModerationBilling.vue';
import UserList from '@/pages/Admin/AdminSettings/user/UserList.vue';
import CompilationService from '@/pages/CompilationService.vue';
import CompilationTag from '@/pages/CompilationTag.vue';
import CompilationView from '@/pages/CompilationView.vue';
import QuestionnaireGirl from '@/components/blocks/QuestionnaireGirl.vue';

//layouts
import TheDefaultLayout from '@/layouts/TheDefaultLayout.vue';
import TheAdminLayout from '@/layouts/TheAdminLayout.vue';
import TheGirlLkLayout from '@/layouts/TheGirlLkLayout.vue';
import TheAnketaLayout from '@/layouts/TheAnketaLayout.vue';

import store from '@/store/index.js';

const routes = [
    //admin-layout
    {
        path: '/admin',
        component: TheAdminLayout,
        children: [
            {
                path: '',
                component: AdminMain,
                meta: {
                    title: 'Эскорт сервис Egoza',
                },
            },
            {
                path: 'lists',
                component: AdminSettings,
            },
            {
                path: 'moderation/billing',
                component: AdminModerationBilling,
            },
            {
                path: 'moderation/feedback',
                component: AdminModeration,
            },
            {
                path: 'profiles',
                component: Profiles,
            },
            {
                path: 'users',
                component: UserList,
            },
            {
                path: 'videoPenetration',
                component: VideoPenetration,
            },
        ],
    },
    //girl-lk-layout
    {
        path: '/girl',
        component: TheGirlLkLayout,
        children: [
            {
                path: 'billing',
                component: Billing,
            },
            {
                path: 'lk',
                component: Lk,
                meta: {
                    title: 'Личный кабинет эскорт сервиса Egoza',
                },
            },
            {
                path: 'orders',
                component: GirlOrdersPage,
                beforeEnter: [isGirlRole],
                meta: {
                    title: 'Заказы',
                },
            },
            {
                path: 'promotion',
                component: Promotion,
            },
            {
                path: 'promotionTg',
                component: PromotionTg,
            },
            {
                path: 'settings',
                component: AccSettings,
            },
            {
                path: 'schedule',
                component: Schedule,
            },
            {
                path: 'view',
                component: View,
            },
        ],
    },
    //promo-layout
    {
        path: '/promo-landing',
        component: PromoPage,
        name: 'promo',
    },

    //questionnaire
    {
        path: '/questionnaire',
        component: TheGirlLkLayout,
        children: [
            {
                path: '',
                component: QuestionnaireGirl,
                name: 'questionnaire',
            },
            {
                path: ':id',
                component: QuestionnaireGirl,
            },
        ],
    },

    //anketa-layout
    {
        path: '/quiz-men',
        component: TheAnketaLayout,
        children: [
            {
                path: '',
                component: QuizMen,
            },
        ],
    },    
    {
        path: '/quiz-ts',
        component: TheAnketaLayout,
        children: [
            {
                path: '',
                component: QuizTs,
            },
            {
                path: 'order',
                component: QuizTsOrder,
            },
        ],
    },
    //defult layout
    {
        path: '/',
        component: TheDefaultLayout,
        children: [
            {
                path: '',
                component: Main,
                meta: {
                    title: 'Эскорт сервис Egoza',
                },
            },
            {
                path: 'anketa/:id',
                component: AnketaGirls,
                meta: {
                    title: 'Индивидуалка эскорт сервиса Egoza',
                },
            },
            {
                path: 'collections',
                component: Collections,
            },
            {
                path: 'compilation-rating',
                component: CompilationRating,
                name: 'compilation-rating',
                meta: {
                    title: 'Часто выбирают на эскорт сервисе Egoza',
                },
            },
            {
                path: 'compilation-service/:service_id?/:district_id?',
                component: CompilationService,
                name: 'compilation-service',
                meta: {
                    title: 'Девушки с услугой: ',
                },
            },
            {
                path: 'compilation-tag/:tag_id',
                component: CompilationTag,
                name: 'compilation-tag',
                meta: {                    
                    title: 'Девушки с услугой: ',
                },
            },
            {
                path: 'compilation-view',
                component: CompilationView,
                name: 'compilation-view',
                meta: {
                    title: 'Вы смотрели на эскорт сервисе Egoza',
                },
            },
            {
                path: 'escort',
                component: Escort,
                name: 'Эскорт',
                meta: {
                    title: 'Премиум эскорт сервиса Egoza',
                },
            },
            {
                path: 'indie',
                component: Indie,
                name: 'Инди',
                meta: {
                    title: 'Индивидуалки эскорт сервиса Egoza',
                },
            },
            {
                path: 'kept-women',
                component: KeptWomen,
                name: 'Содержанки',
            },
            {
                path: 'mobile-streams',
                component: MobileStreamsPage,
                name: 'mobile-streams',
                meta: {
                    title: 'Поток - эскорт сервис Egoza',
                },
            },
            {
                path: 'registration',
                component: Registration,
            },
            {
                path: 'salon',
                component: Salon,
                name: 'Салоны',
            },
            {
                path: 'user-orders',
                component: UserOrdersPage,
                beforeEnter: [isUserRole],
                meta: {
                    title: 'Мои заказы',
                },
            },
            {
                path: 'user/settings',
                name: 'user-settings',
                component: AccSettings,
            },
        ],
    },    
];

const router = createRouter({
    mode: 'history',
    routes,
    history: createWebHistory(),
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
});

router.beforeEach(async (to, from, next) => {
    const { title } = to.meta;
    document.title = title ? title : 'Эскорт сервис Egoza';
    next();
});

function isUserRole() {
    if (store.getters.getUser.role.item_name === 'user') {
        return true;
    } else {
        router.push({ path: '/' });
    }
}

function isGirlRole() {
    if (store.getters.getUser.role.item_name === 'girl') {
        return true;
    } else {
        router.push({ path: '/' });
    }
}
export default router;
