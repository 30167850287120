<script >
import {useCookies} from "vue3-cookies";
import {Toast} from "bootstrap";
export default {
    name:'FlashMassage',
    setup() {
        const {cookies} = useCookies();
        return {cookies};
    },
    data(){
        return{
            flash: [],
            // storeMessage: this.$store.state.getFlashMessage
        }
    },
    computed: {
        filteredFlashMessage() {
            return this.$store.getters.getFlashMessage
        }
    },
    mounted() {
        
        this.flash = JSON.parse(this.cookies.get('flash'))

        const self = this
        document.addEventListener("DOMContentLoaded", function(){
            self.show()
        })
        this.cookies.remove('flash')

    },
    methods: {
        show() {
            const toastElList = document.querySelectorAll('.toast')
            const toastList = [...toastElList].map(toastEl => new Toast(toastEl).show())
        }
    },
    watch: {
        filteredFlashMessage: function(data) {
            if (data.massage) {
                this.flash = [data]
                const self = this
                setTimeout(function() {
                    self.show()
                }, 0)
                
            }
        }
    }
}
</script>

<template>
    <div class="toast-container">
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" v-for="f in this.flash" :key="f.massage">
        <div class="toast-header">
            <i :class="'bi bi-circle-fill me-2 text-' + f.type"></i>
            <strong class="me-auto">{{f.title}}</strong>
            <button type="button" class="btn-close hover-btn" data-bs-dismiss="toast" aria-label="Закрыть"></button>
        </div>
        <div class="toast-body">
            {{f.massage}}
        </div>
    </div>
    </div>
</template>

<style scoped lang="scss">
.toast-container{
    position: fixed;
    max-width: 100%;
    bottom: 20px;
    right: 20px;
}

.toast{
    background: #2C2F3C;
    color: #978B8A;
}

.toast-header{
    background: linear-gradient(180deg, rgba(76,77,107,1) 0%, rgba(44,47,60,1) 100%);
    color: #978B8A;
}
</style>