<!--
Настраиваемый select.
принимает Object
Перебирает, устанавливает выбранный пункт.
И передает id элемента в родительский компонент this.$emit('changeSelectVal', id)
В родительском элементе, пример
<ui-select ref="selectCity"  :select="this.cities" @changeSelectVal="selectChange" />
Функция получает id
selectChange(id){
            this.profile.city_id = this.$refs.selectCity.select_id применяем к нужному элементу
        }
Если используется один select можно применять без ref
this.profile.city_id = id

-->
<script>
export default {
    name: 'UiSelect',
    emits: ['changeSelectVal'],
    props:{
        select:{
            type: Array,
            require: true
        },
        value: {
            type: String,
            require: false,
            default: null
        }
    },
    methods:{
        optionSelect(id){
            let selectVal
            for (let s of this.select) {
                if (s.id === id) {
                    selectVal = s;
                }
            }
            this.selectName = selectVal.name
            this.select_id = selectVal.id
            this.showSelect = false
            this.$emit('changeSelectVal', id)
        },
        openSelect() {
            this.showSelect = !this.showSelect
        },
        handleDocumentClick(event) {
            if (!this.$el.contains(event.target)) {
                this.showSelect = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleDocumentClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleDocumentClick);
    },
    data(){
        return{
            showSelect: false,
            selectName: 'Выбрать',
            select_id: null,
        }

    },
    watch: {
        value: function(data) {
            if (data) {
                this.selectName = data
            }
        }
    }
}
</script>

<template>
    <div class="col-12 position-relative">
        <div @click='openSelect'
             class="select-role d-flex justify-content-between form-control">
            <span v-html="this.selectName"></span>
            <i class="bi" :class="{'bi-chevron-compact-up': showSelect, 'bi-chevron-compact-down': !showSelect}"></i>
        </div>
        <div class="select-role-child form-control" v-if="this.showSelect">
            <p @click="optionSelect(s.id)" v-for="s in this.select" :key="s.name">{{s.name}}</p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.select-role {
    border-radius: 12px;
    background: #FFF;
    border: solid 1px #BDC1D1;
}
.select-role-child{
    border-radius: 12px;
    background: #FFF;
    border: solid 1px #BDC1D1;
    position: absolute;
    z-index: 100;
    padding: 0.375rem 0;
    max-height: 230px;
    overflow-y: auto;
}

.select-role-child p {
    padding: 0.375rem 0.75rem;
    text-align: start;
    margin-bottom: 10px;
    cursor: pointer;
}
.select-role-child p:last-child {
    margin-bottom: 0;
}
.select-role-child p:hover {
    background: #BDC1D1;
    border-radius: 5px;
}
.select-role i {
    cursor: pointer;
}
</style>