<script>
import HeaderDefault from '@/components/ui/header/HeaderDefault.vue';
import Footer from '@/components/blocks/Footer.vue';
import getApi from '@/mixins/getApi';
import AdminButton from '@/components/ui/AdminButton.vue';

export default {
    name: 'defaultLayout',
    components: {
        HeaderDefault,
        Footer,
        AdminButton,
    },
    mixins: [getApi],
    data() {
        return {
            user: this.$store.getters.getUser,
            updateHeader: 0,
        };
    },
};
</script>
<template>
    <header-default />
    <div class="d-flex flex-column flex-grow-1">
        <router-view />
    </div>
    <Footer />
    <admin-button />
</template>
<style scoped></style>
