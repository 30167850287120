<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

export default {
  name: 'BillingHistoryWidget',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      history: {
        all: [],
        plus: [],
        minus: [],
      },
      containerWidth: 1200,
      isDragging: false,
    };
  },
  setup() {
    return {
      modules: [Scrollbar],
    };
  },
  methods: {
    getBillingHistory() {
      axios
        .get(
          this.apiUrl +
            'api-billing/get-billing-history' +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key
        )
        .then((response) => {
          if (response.data.status) {
            this.history.all = response.data.data.all;
            this.history.plus = response.data.data.plus;
            this.history.minus = response.data.data.minus;
          }
        });
    },
    timeToDay(time) {
      let dateAdd = new Date(time * 1000);
      let date =
        dateAdd.getDate() < 10 ? '0' + dateAdd.getDate() : dateAdd.getDate();
      let rawMonth = dateAdd.getMonth() + 1;
      let month = rawMonth < 10 ? '0' + rawMonth : rawMonth;

      let year =
        dateAdd.getFullYear() < 10
          ? '0' + dateAdd.getFullYear()
          : dateAdd.getFullYear();
      
      let seconds =
        dateAdd.getSeconds() < 10
          ? '0' + dateAdd.getSeconds()
          : dateAdd.getSeconds();
      return date + '.' + month + '.' + year;
    },
    timeToDaySpan(time){
      let dateAdd = new Date(time * 1000);
      let hours = dateAdd.getHours() < 10 ? '0' + dateAdd.getHours() : dateAdd.getHours();
      let minutes = dateAdd.getMinutes() < 10 ? '0' + dateAdd.getMinutes() : dateAdd.getMinutes();
      return hours + ':' + minutes
    },
    getAmountColor(amount) {
      if (parseFloat(amount) < 0) {
        return 'text-danger';
      } else {
        return '';
      }
    },
    getStatusColor(id) {
      switch (parseInt(id)) {
        case 1:
          return 'text-success';
        case 2:
          return 'text-warning';
        case 3:
          return 'text-danger';
        case 4:
          return 'text-danger';
      }
    },
  },
  computed: {
  },
  mounted() {
    this.getBillingHistory();
  },
};
</script>

<template>
  <div class="billing-history-widget">
    <div class="row">
      <div class="col-12">
        <h4>История операций</h4>
      </div>
      <div class="mt-3 billing-history-block">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item me-3" role="presentation">
            <button
              class="nav-link hover-btn active"
              id="plus-tab"
              data-bs-toggle="pill"
              data-bs-target="#plus"
              type="button"
              role="tab"
              aria-controls="plus"
              aria-selected="false"
            >
              Пополнение баланса
            </button>
          </li>
          <li class="nav-item me-3" role="presentation">
            <button
              class="nav-link hover-btn"
              id="minus-tab"
              data-bs-toggle="pill"
              data-bs-target="#minus"
              type="button"
              role="tab"
              aria-controls="minus"
              aria-selected="false"
            >
              Траты
            </button>
          </li>
        </ul>

        <div class="tab-content " id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="plus"
            role="tabpanel"
            aria-labelledby="all-tab"
            tabindex="0"
          >
            <div class="row mb-3">
              <div class="col-1 col-md-2"><b>Дата</b></div>
              <div class="col-2 col-md-3"><b>Операция</b></div>
              <div class="col-2 col-md-3"><b>Способ оплаты</b></div>
              <div class="col-1 col-md-2"><b>Рубли</b></div>
              <div class="col-1 col-md-2"><b>Статус</b></div>
            </div>
            <div
              class="row table-line"
              v-for="(item, i) in history.all"
              :key="i"
            >
              <div class="col-1 col-md-2">
                {{ timeToDay(item.date_add) }}
                <p class="tab-content__time">{{timeToDaySpan(item.date_add)}}</p>
              </div>
              <div class="col-2 col-md-3">{{ item.description.name }}</div>
              <div class="col-2  col-md-3">{{item.method_id == 1 ? 'Через кошелёк' : 'Через P2P'}}</div>
              <div :class="['col-1 col-md-2', getAmountColor(item.amount)]">
                {{ Number(item.amount).toFixed(2) }}₽
              </div>
              <div :class="['col-1 col-md-2', getStatusColor(item.status.id)]">
                {{ item.status.name }}
              </div>
              <div class="col-12">
                <hr />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="minus"
            role="tabpanel"
            aria-labelledby="minus-tab"
            tabindex="0"
          >
            <div class="row mb-3">
              <div class="col-1 col-md-2"><b>Дата</b></div>
              <div class="col-2 col-md-3"><b>Операция</b></div>
              <div class="col-2 col-md-3"><b>Способ оплаты</b></div>
              <div class="col-1 col-md-2"><b>Рубли</b></div>
              <div class="col-1 col-md-2"><b>Статус</b></div>
            </div>
            <div
              class="row table-line"
              v-for="(item, i) in history.minus"
              :key="i"
            >
              <div class="col-1 col-md-2">{{ timeToDay(item.date_add) }}
                <p class="tab-content__time">{{timeToDaySpan(item.date_add)}}</p>
              </div>
              <div class="col-2 col-md-3">{{ item.description.name }}</div>
              <div class="col-2  col-md-3">{{item.method_id == 1 ? 'Через кошелёк' : 'Через P2P'}}</div>
              <div :class="['col-1 col-md-2', getAmountColor(item.amount)]">
                {{ Number(item.amount).toFixed(2) }}
              </div>
              <div :class="['col-1 col-md-2', getStatusColor(item.status.id)]">
                {{ item.status.name }}
              </div>
              <div class="col-12">
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab-content__time{
  margin-bottom: 0;
  width: max-content;
  margin-left: 40px
}
.billing-history-block{
  max-width: 1000px;
  width: 100%;
  @media (max-width: 1050px) {
    max-width: none;
  }
  & .nav-pills{
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
.tab-pane{
  @media (max-width: 1050px) {
    width: 1500px;
  }
}
.tab-content{
  white-space: nowrap;
  @media (max-width: 1050px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 1500px;
    width: 100%;
  }
}
::-webkit-scrollbar{
  height: 10px;
}
::-webkit-scrollbar-track{
  background: #eff1fa;
  border-radius: 24px;
}
::-webkit-scrollbar-thumb{
  background: #bdc1d1;
  border-radius: 24px;
}
.nav-item {
  max-width: 195px;
  width: 100%;
  margin-right: 0;
  @media (max-width: 576px) {
    max-width: none;
    margin-right: 0 !important;
  }
}
.nav-pills .nav-link {
  background-color: #bdc1d1;
  color: #ffffff;
}
.nav-pills .nav-link.active {
  background-color: #8e7f7d;
}
.nav-link {
  width: 100%;
}
</style>
