<script>
import GirlListCategory from "@/components/ui/GirlListCategory.vue";
import OtzyvModal from "@/components/blocks/OtzyvModal.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import SliderInCategory from "@/components/blocks/SliderInCategory.vue";
import axios from "axios";
import sklonyator from 'sklonyator';
import AllTags from "@/components/ui/common/tags/AllTags.vue";
import QuizBlock from "@/components/blocks/QuizBlock.vue";

export default {
    name: 'CompilationRating',
    components: { SliderInCategory, UiLoader, OtzyvModal, GirlListCategory, sklonyator, AllTags, QuizBlock },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            compilationRating: [],
            showLoader: true,
            title: '',
            category_id: '',
            otzyvModal: false,
            city: ''
        }
    },
    methods: {
        async getSelectionRating() {
            this.sklonyator()
            axios
                .get(this.apiUrl + 'api-girl/get-top-by-likes&status_id=6&auth='
                    + this.user.username + ':' + this.user.auth_key
                    + '&limit=20'
                    + `&city_id=${this.user.city?.id || 1}`)
                .then((response) => {
                    this.compilationRating = response.data.data
                    this.showLoader = false
                }).catch(error => console.log(error))
        },
        sklonyator(){

            if(this.user.city){
                this.city = sklonyator.incline(this.user.city.name);
            }

        }
    },
    mounted() {
        this.getSelectionRating()
    },
    computed: {
        userCity() {
            return this.$store.getters.getCity
        }
    },
    watch: {
        userCity: async function () {
            this.showLoader = true
            this.compilationRating = []
            await this.getSelectionRating()
        }
    }
}
</script>
<template>
    <main>
        <!--		<div class="main-block">
			<div class="container">
				<div class="main-block__img">
					<img src="@/assets/img/main/girls.webp" alt="girls">
				</div>
			</div>
		</div>-->
        <div class="block-offer">
            <div class="container">
                <div class="row">
                    <div class="col-12 title-cat roboto-bold p-3">
                        Часто выбирают
                        <span> в {{this.city}}</span>
                    </div>
                </div>
                <ui-loader :show="showLoader" :preset="'block'" />
                <div class="slider-offer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-3" v-for="p in this.compilationRating" :key="p.id">
                                <SliderInCategory :profile="p.girlProfile" :category-page="false" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <!-- <div v-for="p in this.pages" @click="showPage(p.page)" :class="p.class" :key="p.page">{{ p.name }}</div> -->
                    </div>
                </div>
            </div>

        </div>
        <div class="block-offer">
			<quiz-block />
		</div>
        <div class="block-offer">
			<div class="container">
				<all-tags />
			</div>
		</div>
    </main>

</template>
<style scoped>
@media screen and (max-width: 767px) {
    main {
        margin-top: 30px;
    }
}

.title-cat{
    color: #2D2F3A;
    font-size: 28px;
}
</style>
