<script>
export default {
  name: 'Card-White',
    props:{
      addClass:{
          type:String,
          default: ''
      }
    }
}
</script>

<template>
  <div :class="'card card-white ' + this.addClass">
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-white{
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow: hidden;
}
.flex-body > .card-body {
  display: flex;
}
</style>