<script setup>
import { useStore } from "vuex"
import { computed } from "vue"

const store = useStore()
const activeChat = computed(() => {
    return store.getters.getActiveChat
})
const statusClass = computed(() => {
    return (activeChat.value.id === props.item.id) ? 'active' : ''
})

const props = defineProps({
    item: {
        type: Object,
        default: {}
    }
})
</script>

<template>
    <div v-if="props.item.id"
        class="messenger-list-item py-2 pe-1 px-3 px-md-4 mb-1 d-flex justify-content-between align-items-center gap-2"
        :class="[props.item.status, statusClass]">
        <div>
            <p class="list-item-title mb-1">{{ props.item.name }}</p>
            <p class="list-item-text mb-0" v-html="props.item.text"></p>
        </div>
        <div>
            <p class="list-item-time mb-0">{{ props.item.time }}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.messenger-list-item {
    @media screen and (max-width: 767px) {
        border-radius: 0;
        border-bottom: 1px solid #BDC1D133;
        transition: .3s;
    }

    &.new {
        border-radius: 8px;
        border-bottom: none;
        background-color: #40455B;

        .list-item-title {
            color: #FF4032;
        }
    }

    &.active {
        border-radius: 8px;
        border-bottom: none;
        background-color: #353845;

        .list-item-title {
            color: #6CCB5F;
        }
    }

    &:hover {
        border-radius: 8px;
        background-color: #353845;
        cursor: pointer;
    }

    &>div:first-child {
        width: 85%;
    }

    &>div:last-child {
        width: 10%;
    }

    .list-item-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    .list-item-text {
        font-weight: 400;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    .list-item-time {
        font-weight: 400px;
        font-size: 14px;
        color: #838383;

        @media screen and (max-width: 767px) {
            font-size: 10px;
        }
    }
}
</style>