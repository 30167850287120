<script>
export default {
    name: 'BalanceWidget',
    props:{
      plusUrl:{
          type: String,
          require: true
      }
    },
    data(){
        return {
            color: 'white',
            user: this.$store.getters.getUser
        };
    },
    watch:{
        user() {
            if(this.user.balance.balance < 1){
                this.color = 'danger'
            }else if(this.user.balance.balance < 2000){
                this.color = 'warning'
            }else if(this.user.balance.balance > 1999){
                this.color = 'white'
            }
        }
    }
}
</script>

<template>
<div class="d-flex gap-2 balance-widget">
    <div :class="['col-auto d-flex align-items-center', 'text-'+this.color]">
        <span>{{this.user.balance.balance}}₽</span>
    </div>
    <div class="col-auto ps-0">
        <span class="plus" @click="$router.push(this.plusUrl)">
            <i class="bi bi-plus"></i>
        </span>
    </div>
</div>
</template>

<style scoped>
.balance-widget{
    font-size: 18px;
}
.plus{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 7px;
    padding-top: 5px;
    padding-right: 7px;
    padding-bottom: 1px;
    border-radius: 55%;
    background-color: rgba(207, 196, 194, 0.1);
    transition: 0.3s;
    cursor: pointer;
    color: #ffffff;
}
</style>