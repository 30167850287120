<script setup>
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
import OrderBackwardButton from '@/components/order_modal/OrderBackwardButton.vue';
import { onMounted, ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';

const props = defineProps({
    initialDateTime: {
        type: Object,
        required: true,
        default: () => {
            return new Date();
        },
    },
});
const emit = defineEmits(['next-step']);

const currentDateTime = ref(null);
const minTime = ref({ hours: 0, minutes: 0 });

onMounted(() => {
    currentDateTime.value = props.initialDateTime;
    minTime.value = getMinTime(currentDateTime.value);
});

const getMinTime = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const checkedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (today.getTime() < checkedDate.getTime()) {
        return { hours: 0, minutes: 0 };
    } else return { hours: now.getHours() + 2, minutes: 0 };
};

const handleDate = (date) => {
    minTime.value = getMinTime(date);
    const currentTime = {
        hours: date.getHours(),
        minutes: date.getMinutes(),
    };
    if (currentTime.hours < minTime.value.hours + 2) {
        currentDateTime.value = date;
        currentDateTime.value.setHours(minTime.value.hours, minTime.value.minutes, 0);
    } else currentDateTime.value = date;
};

const handleInternal = (date) => {
    if (date) {
        minTime.value = getMinTime(date);
    }
};

const format = (date) => {
    return date.toLocaleString('ru', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' });
};
</script>

<template>
    <div class="order-time">
        <div class="order-time__question mb-3">
            <p>В какое время планируете встречу?</p>
        </div>
        <div class="order-time__content d-flex flex-wrap justify-content-end gap-3">
            <div>
                <VueDatePicker
                    class="order-time__datepicker"
                    :dark="true"
                    :model-value="currentDateTime"
                    :clearable="false"
                    :minutes-grid-increment="15"
                    :no-minutes-overlay="false"
                    :min-date="new Date()"
                    :no-hours-overlay="false"
                    :min-time="minTime"
                    :format="format"
                    @update:model-value="handleDate"
                    @internal-model-change="handleInternal"
                    minutes-increment="15"
                    locale="ru"
                    select-text="Выбрать"
                    cancel-text="Закрыть"
                    prevent-min-max-navigation
                    time-picker-inline
                />
            </div>
            <div class="order-time__buttons ms-auto">
                <OrderBackwardButton
                    class="h-100"
                    @click="emit('next-step', { dateTime: currentDateTime, forward: false })"
                />
                <OrderNextButton
                    class="h-100"
                    @click="emit('next-step', { dateTime: currentDateTime, forward: true })"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-time {
    .order-time__question {
        font-size: 16px;
        line-height: 20px;
    }
}
</style>
<style lang="scss">
.order-time__datepicker {
    .dp__input {
        background-color: #201c2d;
    }
    .dp__inner_nav {
        background-color: #201c2d;
    }
    .dp__active_date {
        background-color: #312b45;
    }
    .dp__today {
        border-color: #312b45;
    }
    .dp__overlay_cell {
        background-color: #312b45;

        &:hover {
            background-color: #201c2d;
        }
    }
    .dp__overlay_cell_disabled {
        background: transparent;
    }
    .dp__theme_dark {
        background-color: #201c2d;
        --dp-background-color: #201c2d;
        --dp-text-color: #bdc1d1;
        --dp-hover-color: #312b45;
        --dp-hover-text-color: #bdc1d1;
        --dp-hover-icon-color: #959595;
        --dp-primary-color: #312b45;
        --dp-primary-disabled-color: #201c2d;
        --dp-primary-text-color: #bdc1d1;
        --dp-secondary-color: #a9a9a9;
        --dp-border-color: #2d2d2d;
        --dp-menu-border-color: #2d2d2d;
        --dp-border-color-hover: #aaaeb7;
        --dp-border-color-focus: #aaaeb7;
        --dp-disabled-color: #e53935;
        --dp-disabled-color-text: #d0d0d0;
        --dp-scroll-bar-background: #212121;
        --dp-scroll-bar-color: #484848;
        --dp-success-color: #00701a;
        --dp-success-color-disabled: #428f59;
        --dp-icon-color: #959595;
        --dp-danger-color: #e53935;
        --dp-marker-color: #e53935;
        --dp-tooltip-color: #3e3e3e;
        --dp-highlight-color: rgb(0 92 178 / 20%);
        --dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
        --dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
        --dp-range-between-border-color: var(--dp-hover-color, #fff);
    }

    --dp-border-radius: 6px;
    --dp-cell-border-radius: 6px;
    --dp-action-button-height: 40px;
    --dp-cell-size: 34px;
    --dp-input-padding: 6px 10px 6px 12px;
    --dp-time-inc-dec-button-size: 60px;
}
</style>
