<script>
import EmojiPicker from 'vue3-emoji-picker';
import LogInCard from '@/components/blocks/LogInCard.vue';
import axios from 'axios';
import telegramInputs from '@/mixins/posttg';
export default {
  props: {
    post: {
      type: Object,
    },
    girlAnketa: {
      type: Object,
    },
  },
  components: {
    EmojiPicker,
    LogInCard,
  },
  mixins: [telegramInputs],
  data() {
    return {
      user: this.$store.getters.getUser,
      girlId: this.$route.params.id,
        apiUrl: this.$store.getters.getApiUrl,
        apiDomine: this.$store.getters.getApiDomine,
      emojiPopup: false,
      showAl: true,
      comment: '',
      show: true,
      openModal: false,
      isSending: false,
    };
  },
  emits: ['showAl', 'new-post-added'],
  methods: {
    textAreas() {
      const area = document.querySelector('.input-block__textareas');
      area.style.height = '60px';
      area.style.height = `${area.scrollHeight}px`;
    },
    areaSmileClose() {
      this.emojiPopup = false;
    },
    toggleBlock() {
      this.emojiPopup = !this.emojiPopup;
    },
    onSelectEmoji(emoji) {
      this.comment = this.comment + emoji.i;
    },
    handleClickOutside(event) {
      if (
        event.target.className == 'emodjiimg' ||
        event.target.className == 'input-block__smile'
      ) {
        return;
      } else {
        this.emojiPopup = false;
      }
    },
    async sendMess() {
      if (this.isSending) {
        return;
      }
      this.isSending = true;
      try {
        const res = await axios.post(
          this.apiUrl +
            'api-profile-post/set-comment' +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          {
            post_id: this.post.id,
            author_id: this.user.user_id,
            text: this.comment,
          }
        );
        this.$store.dispatch('getPosts', this.girlId);
        this.$emit('new-post-added', true);
        this.comment = '';
        this.getusersTg();
      } catch (error) {
        this.openModal = true;
        console.log('Произошла ошибка при добавлении comment', error);
      } finally {
        this.isSending = false;
      }
    },

    async getusersTg() {
      try {
        const res = await axios.get(
          `${this.apiUrl}api-user/get-user-settings&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.girlAnketa.user_id}`
        );
        const data = res.data.data;
        data.find((el) => {
          if (el.name == 'mess' && el.value == 'true') {
            if (this.girlAnketa.user_id != this.user.user_id) {
              this.telegramInputs(
                this.girlAnketa.user_id,
                'Вы получили новый комментарий в ленте My Life'
              );
            }
          }
        });
      } catch (error) {
        console.log('inputs.vue tg', error);
      }
    },
    openComment() {
      this.$emit('showAl', this.showAl);
    },
    closeModal(event) {
      if (event.target.className == 'dialog') {
        this.openModal = false;
      }
    },
    hideDialog() {
      this.openModal = false;
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<template>
  <div class="input">
    <h2 class="input-text" @click="openComment">
      <span v-if="post?.comments?.length == 1"></span>
      <span @click="show = false" v-if="post?.comments?.length > 1 && show"
        >Показать следующий комментарий</span
      >
    </h2>
    <div class="input-block">
      <img
        v-if="this.user.profile.avatar"
        class="input-block__avatar"
        :src="
          this.apiDomine + '/web/uploads/' + this.user.profile.avatar
        "
        alt=""
      />
      <img
        v-else
        class="input-block__avatar"
        :src="require('@/assets/img/no-avatar.png')"
        alt=""
      />

      <div class="input-block__area">
        <textarea
          class="input-block__textareas"
          placeholder="Написать коментарий..."
          @input="textAreas"
          v-model="comment"
          @click="areaSmileClose"
          @keydown.enter="sendMess"
        />

        <button class="input-block__smile">
          <div
            class="chat-quiz__smile-icon"
            @click="this.emojiPopup = !this.emojiPopup"
          >
            <img src="@/assets/img/smilepost.svg" alt="svg" class="emodjiimg" />
          </div>
          <div class="chat-quiz__smile-emoji" v-if="this.emojiPopup">
            <EmojiPicker :native="true" @select="onSelectEmoji" />
          </div>
        </button>
        <button class="input-block__send" @click="sendMess">
          <img :src="require('@/assets/img/sendB.svg')" alt="" />
        </button>
      </div>
    </div>
  </div>
  <LogInCard
    @click="closeModal"
    :show="openModal"
    @loginSuccess="hideDialog"
    @hideDialog="hideDialog"
  />
</template>

<style lang="scss" scoped>
.input {
  &-text {
    margin-top: 33px;
    margin-bottom: 23px;
    font-size: 17px;
    color: #2d2f3a;
    cursor: pointer;
    @media (max-width: 500px) {
      font-size: 17px;
    }
  }
  &-block {
    display: flex;
    align-items: center;
    gap: 17px;
    @media (max-width: 420px) {
      gap: 10px;
    }
    &__avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
    }
    &__smile {
      position: absolute;
      bottom: 18px;
      right: 65px;
      @media (max-width: 420px) {
        right: 50px;
      }
    }
    &__send {
      position: absolute;
      bottom: 14px;
      right: 22px;
      & img {
        width: 26px;
      }
      @media (max-width: 420px) {
        right: 10px;
      }
    }
    &__area {
      width: 100%;
      position: relative;
      flex: 1 1 0;
      & textarea {
        width: 100%;
        border: 1px solid rgba(189, 193, 209, 0.31);
        border-radius: 16px;
        padding: 17px 26px;
        padding-right: 100px;
        resize: none;
        height: 60px;
        overflow-y: hidden;
        vertical-align: middle;
        @media (max-width: 420px) {
          font-size: 14px;
          padding-right: 80px;
          padding-left: 10px;
        }
      }
    }
  }
}
.input-block__smile .chat-quiz__smile-emoji {
  left: auto;
  right: 0;
}
</style>
