<script>
import ModalCard from "@/components/blocks/ModalCard.vue";
import LogInCard from "@/components/blocks/LogInCard.vue";
import {defineComponent} from "vue";
import ExitButton from "@/components/ui/ExitButton.vue";
import getApi from "@/mixins/getApi";

export default defineComponent({
    name: 'LogInBlock',
    components: {ExitButton, LogInCard, ModalCard},
    mixins: [getApi],
    data() {
        return {
            user: this.$store.getters.getUser,
            show: false,
        }
    },
    methods: {
        modalShow() {
            this.show = true;
        },

        hideDialog() {
            this.show = false;
        }
    }
})

</script>

<template>
    <div class="row justify-content-end">
        <div class="col-auto log-in__btn">
            <button class=" hover-btn" @click="modalShow">Войти</button>
        </div>
    </div>
    <log-in-card :show="this.show" @loginSuccess="hideDialog" @hideDialog="hideDialog"></log-in-card>
</template>

<style scoped lang="scss">
.log-in {
    &__btn {
        button {
            display: inline-block;
            border-radius: 15px;
            transition: 0.3s;
            background-color: #524B5F;
            color: #fff;
            padding: 5px 10px;

            &:hover {
                box-shadow: 0 0 5px rgba(#fff, 0.5) inset;
            }
        }
    }
}

.header-admin {
    &__photo {
        max-width: 50px;
    }
}

// .btn-gradient{
//   border-radius: 15px;
//   background: #524B5F;
//   color: #fff;
// }

// .btn-gradient:hover{
//   //font-weight: 700;
//   color: #fff;
// }
.avatar {
    border-radius: 50%;
}

</style>