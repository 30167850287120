<template>
  <main>

    <form @submit.prevent="addCountry">
      <div class="settings-add d-flex flex-column mx-auto justify-content-center">
        <div class="inputs d-flex justify-content-center gap-2 px-2">
          <div class="settings-add__item ">
            <label>
              <input type="text" v-model="country.shortName"
                     @keyup="country.shortName = cleanText(country.shortName)"
                     placeholder="Короткое название" id="shortName">
            </label>
          </div>
          <div class="settings-add__item ">
            <label>
              <input type="text" required v-model="country.name"
                     @keyup="country.name = cleanText(country.name)"
                     placeholder="Название" id="name">
            </label>
          </div>
          <div class="settings-add__item ">
            <label>
              <input type="text" required v-model="country.alpha2" maxlength="2" placeholder="alpha2"
                     id="alpha2">
            </label>
          </div>
          <div class="settings-add__item ">
            <label>
              <input type="text" required v-model="country.alpha3" maxlength="3" placeholder="alpha3"
                     id="alpha3">
            </label>
          </div>
          <div class="settings-add__item ">
            <label>
              <input type="text" required v-model="country.iso"
                     @keyup="country.iso = cleanNumber(country.iso)"
                     maxlength="4" placeholder="iso" id="iso">
            </label>
          </div>
        </div>
        <div class="wrapper-btn d-flex justify-content-center">
          <button :disabled="this.loaderShow" type="submit" class="mt-4 position-relative" v-if="formBtn"
                  @click="validateForm">
            Создать
            <ui-loader :show="this.loaderShow" :preset="'btn'"/>
          </button>
          <button :disabled="this.loaderShow" type="submit" class="mt-4 position-relative" v-if="!formBtn"
                  @click="validateForm">
            Сохранить
            <ui-loader :show="this.loaderShow" :preset="'btn'"/>
          </button>
        </div>
      </div>
    </form>

    <div class="table-cont d-flex mt-3">
      <table class="col-11 responsive-table">
        <thead>
        <tr>
          <td class="text-white">Короткое название</td>
          <td class="text-white">Название</td>
          <td class="text-white">Alpha 2</td>
          <td class="text-white">Alpha 3</td>
          <td class="text-white">iso</td>
          <td class="text-white"></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in countries" :key="index" class="settings-table__row">
          <td id="shortName" class="settings-table__item text-white">{{ item.shortName }}</td>
          <td id="name" class="settings-table__item text-white">{{ item.name }}</td>
          <td id="alp2" class="settings-table__item text-white">{{ item.alpha2 }}</td>
          <td id="alp3" class="settings-table__item text-white">{{ item.alpha3 }}</td>
          <td id="iso" class="settings-table__item text-white">{{ item.iso }}</td>
          <td id="btns" class="settings-table__item text-white d-flex justify-content-end">
            <button @click="toForm(item.id)">
              <i class="bi bi-pencil"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </main>
  <!--  Всплывающее сообщение, ошибки-->
  <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex">
      <div class="toast-body">
<!--        {{ errorText }}-->
      </div>
      <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Закрыть"></button>
    </div>
  </div>
  <!--Модальное окно, удаление-->
  <modal-card :show="this.show">
    <div>
      <div class="row">
        <div class="col-12 text-end">
          <i class="bi bi-x-lg" @click="hideDialog"></i>
        </div>
      </div>
      <div class="modal-body">
        <p>Уверены, что хотите удалить {{ country.name }}</p>
      </div>
      <div class="modal-footer mt-3 justify-content-between">
        <button type="button" class="btn btn-modal" data-bs-dismiss="modal">Закрыть</button>
        <button type="button" @click="deleteData" class="btn btn-modal">Удалить</button>
      </div>
    </div>
  </modal-card>
</template>


<script>
import axios from 'axios'
import ModalCard from "@/components/blocks/ModalCard.vue";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  components: {
    UiLoader,
    ModalCard,
    axios,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      countries: [],
      country: {
        id: false,
        name: '',
        shortName: '',
        alpha2: '',
        alpha3: '',
        iso: '',
      },
      show: false,
      formBtn: true,
      toDel: false,
      loaderShow: false
    }
  },
  mounted() {
    this.updateData()
  },
  methods: {
    cleanText(val) {
      if (val.match(/^[а-яА-ЯёЁa-zA-Z\s]/g)) {

        return val.replace(/[^[а-яА-ЯёЁa-zA-Z\s]/g, '');
      }

      return val;
    },

    cleanNumber(val) {
      if (val.match(/[^0-9\s]/g)) {

        return val.replace(/[^0-9\s]/g, '');
      }

      return val;
    },

    validateForm() {
      let result = true;
      let shortName = document.getElementById('shortName');
      let name = document.getElementById('name');
      let alpha2 = document.getElementById('alpha2');
      let alpha3 = document.getElementById('alpha3');
      let iso = document.getElementById('iso');
      if (this.shortName === "") {
        shortName.classList.add('invalid');
        result = false;
      } else {
        shortName.classList.remove('invalid');
      }

      if (this.name === "") {
        name.classList.add('invalid');
        result = false;
      } else {
        name.classList.remove('invalid');
      }

      if (this.alpha2 === "") {
        alpha2.classList.add('invalid');
        result = false;
      } else {
        alpha2.classList.remove('invalid');
      }

      if (this.alpha3 === "") {
        alpha3.classList.add('invalid');
        result = false;
      } else {
        alpha3.classList.remove('invalid');
      }

      if (this.iso === "") {
        iso.classList.add('invalid');
        result = false;
      } else {
        iso.classList.remove('invalid');
      }

      if (result) {
        if (this.formBtn) {
          this.postCountry();
        } else {
          this.updateCountry();
        }
      }
    },

    clearForm() {
      this.country.name = '';
      this.country.shortName = '';
      this.country.alpha2 = '';
      this.country.alpha3 = '';
      this.country.iso = '';
      this.formBtn = true;
    },

    updateData() {
      if (!this.user) {
        return false;
      }
      axios
          .get(this.apiUrl + 'api/get-countries&auth=' + this.user.username + ':' + this.user.auth_key)
          .then((response) => {
            this.countries = response.data;
          }).catch((error) => {
      })
      this.clearForm();
    },

    postCountry() {
      this.loaderShow = true
      axios
          .post(this.apiUrl + 'api/set-country&auth=' + this.user.username + ':' + this.user.auth_key, this.country)
          .then((response) => {
            this.updateData()
            this.loaderShow = false
          })
    },

    updateCountry() {
      this.loaderShow = true
      axios
          .post(this.apiUrl + 'api/update-country&id=' + this.country.id + '&auth=' + this.user.username + ':' + this.user.auth_key, this.country)
          .then((response) => {
            this.updateData()
            this.loaderShow = false
          })
      this.clearForm();
    },

    toForm(id) {
      let country;
      this.formBtn = false;
      for (let c of this.countries) {
        if (c.id === id) {
          country = c;
        }
      }
      this.country = country;
    },

    deleteData() {
      axios
          .post(this.apiUrl + 'api/del-country&id=' + this.toDel)
      this.hideDialog();
      this.updateData();
    },

    modalShow(id) {

      this.show = true;
      this.toDel = id;
    },

    hideDialog() {
      this.show = false;
    }
  },
}
</script>

<style scoped lang="scss">
.settings-add {
  max-width: 80%;
  margin: 0 auto 40px;
  border: solid 1px #2C2F3C;
  border-radius: 10px;
  padding: 15px 0;
  background: #2C2F3C;


  button {
    background: #BDC1D1;
    display: inline-block;
    border: solid 1px transparent;
    border-radius: 4px;
    color: #2C2F3C;
    transition: 0.3s;

    &:hover {
      background: #BDC1D1;
      border-color: #BDC1D1;
      color: #2C2F3C;
      font-weight: 600;
    }
  }

  .invalid {
    border: 1px solid red;
  }

  label {
    width: 100%;
  }

  input {
    background-color: #dedede;
    width: 100%;
    border: 1px solid #BDC1D1;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

.wrapper-btn button{
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-table {
  max-width: 80%;
  margin: 0 auto;
  color: #fff;

  &__head {
    background-color: #2C2F3C;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #7e7e7e7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__row {
    background-color: #2C2F3C;
    position: relative;


    &:nth-child(odd) {
      background-color: #2C2F3C;
    }

    button {
      color: #fff;
    }

    td{
      padding: 2px 0px !important;
    }
  }

  &__item {
    border-bottom: solid 1px #7e7e7e7e;
  }
}

button.btn-modal {
  background: #dedede !important;
  display: inline-block;
  border: solid 1px transparent;
  border-radius: 4px;
  color: #fff;
  transition: 0.3s;
}

thead tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

thead tr:last-child td:last-child {
  border-top-right-radius: 10px;
}


.table-cont {
  justify-content: center;
}

.table-wrapper {
  max-width: 1300px;
  width: 100%;
  overflow-x: auto;
  border-radius: 10px 10px 0px 0px;
}

.responsive-table {
  max-width: 1300px;
  width: 100%;
  border-collapse: collapse;
}

.responsive-table thead {
  background-color: #2C2F3C;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #7e7e7e7e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.responsive-table th {
  background-color: #f2f2f2;
  color: #333;
}

.responsive-table td{
  padding: 0px 10px;
  text-align: center;
  line-height: normal;
}

@media (max-width: 700px) {
  .inputs{
    flex-direction: column;
  }
}
@media (max-width: 540px) {
  .responsive-table > thead td:nth-child(3),
  .responsive-table > thead td:nth-child(4){
    display: none;
  }

  #alp2,
  #alp3 {
    display: none;
  }
}
</style>