<script>
export default {
    name: 'DisplayCopyright',
    data(){
        return{
            year:new Date().getFullYear()
        }
    }
}
</script>

<template>
    © {{year}} Все права защищены.
</template>

<style scoped>

</style>