<script>
import UiSwitch from '@/components/ui/UiSwitch.vue';
import Slider from '@/components/blocks/Slider.vue';
import Video from '@/components/ui/UiVideo.vue';
import {Swiper, SwiperSlide} from "swiper/vue";
import {Mousewheel, Pagination, Navigation} from "swiper/modules";
import gsapSlider from '@/components/ui/UiGsap.vue'
import {useCookies} from "vue3-cookies";
import LogInBlock from '@/components/ui/LogInBlock.vue'
import axios from "axios";
import conjugation from "@/mixins/conjugation";
import SelectionItem from "@/components/blocks/SelectionItem.vue";
import SelectionViewed from "@/components/blocks/SelectionViewed.vue";
import SelectionItemOnline from "@/components/blocks/SelectionItemOnline.vue";
import ModalTgInfo from "@/components/ui/popup/ModalTgInfo.vue";
import getApi from "@/mixins/getApi";
import CategoryCarousel from "@/components/ui/CategoryCarousel.vue";
import AdBlock from "@/components/Ad/AdBlock.vue";
import QuizBlock from "@/components/blocks/QuizBlock.vue";
import VideoCards from "@/components/blocks/VideoCards.vue";
import UploadVideoCard from "@/components/ui/uploadMedia/UploadVideoCard.vue";
import UserSelect from "@/components/ui/hint/UserSelect.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import AllTags from "@/components/ui/common/tags/AllTags.vue";
import sklonyator from 'sklonyator';
import VideoWidget from '@/components/ui/VideoWidget.vue';

export default {
  components: {
    AllTags,
    UiLoader,
    UserSelect,
    UploadVideoCard,
    VideoCards,
    QuizBlock,
    AdBlock,
    CategoryCarousel,
    ModalTgInfo,
    SelectionItemOnline,
    SelectionViewed,
    SelectionItem,
    UiSwitch,
    Swiper,
    SwiperSlide,
    Slider,
    gsapSlider,
    Video,
    LogInBlock,
    sklonyator,
    VideoWidget
  },
  mixins: [conjugation, getApi],
  setup() {
    return {
      modules: [Mousewheel, Pagination, Navigation],
    }
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      // openPicture: false,
      compilation: this.$store.getters.getCompilation,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomine: this.$store.getters.getApiDomine,
      loading: true,
      title: 'Look at me',
      city: '',
      videoHover: '',
      pictureGif: '',
      pictureName: '',
      pictureGifId: '',
      pictureGifService: '',
      favourites: [],
      isGsap: false,
      slides: [
        /*{
            num: '01',
            name: 'Салоны',
            video: 'cat_1.mp4',
            url: '/salon',
        },*/
        {
          num: '02',
          name: 'Инди',
          video: 'cat_2.mp4',
          url: '/indie',
        },
        {
          num: '03',
          name: 'Эскорт',
          video: 'cat_3.mp4',
          url: '/escort'
        },

      ],
      slidesVideo: [
        {
          img: 'item1.webp',
          name: 'Анжела',
          btn: 'Открыть анкету',
        },
        {
          img: 'item2.webp',
          name: 'Анжела',
          btn: 'Открыть анкету',
        },
        {
          img: 'item3.webp',
          name: 'Анжела',
          btn: 'Открыть анкету',
        },
        {
          img: 'item4.webp',
          name: 'Анжела',
          btn: 'Открыть анкету',
        },
        {
          img: 'item5.webp',
          name: 'Анжела',
          btn: 'Открыть анкету',
        },
      ],
      limit: 4,
      selection: [],
      selectionBtn: {text: 'Часто выбирают', href: '#', icon: require('@/assets/img/selection1.svg')},
      selectionTop: [],
      selectionTopBtn: {text: 'Сейчас онлайн', href: '#', icon: require('@/assets/img/selection2.svg')},
      gif: [
        {
          id: 55,
          video: 'lesbi.mp4',
          name: 'Лесби шоу',
          picture: 'lesbi.webp',
          service: {
            "id": 55,
            "name": "Лесби - шоу легкое",
            "price": 0,
            "category_id": 5
          }
        },
        {
          id: 30,
          video: 'fisting.mp4',
          name: 'ФИСТИНГ',
          picture: 'lesbi.webp',
          service: {
            "id": 30,
            "name": "Фистинг классический",
            "price": 0,
            "category_id": 6
          }
        },
        {
          id: 23,
          video: 'strapon.mp4',
          name: 'СТРАПОН',
          picture: 'strapon.webp',
          service: {
            "id": 23,
            "name": "Страпон",
            "price": 0,
            "category_id": 6
          }
        },
        {
          id: 24,
          video: 'anilingus.mp4',
          name: 'АНИЛИНГУС',
          picture: 'anilingus.webp',
          service: {
            "id": 24,
            "name": "Анилингус вам",
            "price": 0,
            "category_id": 6
          }
        },
        {
          id: 27,
          video: 'golden-rain.mp4',
          name: 'Золотой дождь',
          picture: 'golden-rain.webp',
          service: {
            "id": 27,
            "name": "Золотой дождь мне",
            "price": 0,
            "category_id": 6
          }
        },
        {
          id: 34,
          video: 'bdsm.mp4',
          name: 'БДСМ',
          picture: 'bdsm.webp',
          service: {
            "id": 34,
            "name": "Доминация",
            "price": 0,
            "category_id": 3
          }
        },
      ],
      swiperOptions: {
        breakpoints: {374: {slidesPerView: 2}, 991: {slidesPerView: 2}, 1240: {slidesPerView: 4}},
        navigations: true
      },
      swiperOptionsSelect: {
        breakpoints: {767: {slidesPerView: 3}, 1240: {slidesPerView: 5}},
      },
      swiperOrderOptions: {
        breakpoints: {320: {slidesPerView: 1}, 575: {slidesPerView: 2}},
        navigations: true
      },

      favouritesClass: 'selection-block__signin-bord'

    }
  },

  computed: {
    userCity() {
      return this.$store.getters.getCity
    },
    isMobile(){
      return this.$store.getters['getIsMobile']
    }
  },
  watch: {
    userCity: function () {
      this.sklonyator()
      this.getSelectionRaiting()
      this.getActiveTop()
    }
  },

  created() {
    this.addSavedPrifile()
    this.checkIosVersion()
  },

  mounted() {
    this.getSelectionRaiting()
    this.getActiveTop()
    this.sklonyator()
  },

  methods: {
    playVideo(e){
      const video = e.target.querySelector('video')

      if(video.paused && !this.isMobile){
        video.play()
      }

    },
    imageLoaded() {
      this.loading = false
    },
    openBlank(id) {
      window.open('/anketa/' + id, '_blank');
    },
    startVideo(i, screen) {
      const currentRef = (screen === 'desktop') ? `video-desktop-${i}` : `video-${i}`
      const element = this.$refs[currentRef][0]
      element.play()
    },
    stopVideo(i, screen) {
      const currentRef = (screen === 'desktop') ? `video-desktop-${i}` : `video-${i}`
      const element = this.$refs[currentRef][0]
      element.pause()
    },
    openPicture(item) {
      console.log('item: ', item)
      this.pictureGif = item.picture
      this.pictureName = item.name
      this.pictureGifId = item.id
      this.pictureGifService = item.service
    },
    addSavedPrifile() {
      if (!this.user.isLogged) {
        return true
      }

      axios
          .get(this.apiUrl + 'api-user/get-saved-profiles&auth='
              + this.user.username + ':' + this.user.auth_key)
          .then((response) => {
            this.favourites = response.data
            if (this.favourites.length != 0) {
              this.favouritesClass = 'selection-block__signin'
            }
          })

    },
    getSelectionRaiting() {
      axios
          .get(this.apiUrl + 'api-girl/get-top-by-likes&status_id=6&auth='
              + this.user.username + ':' + this.user.auth_key
              + `&city_id=${this.user.city?.id || 1}`)
          .then((response) => {
            this.selection = response.data.data
          }).catch(error => console.log(error))

    },

    getActiveTop() {
      axios
          .get(this.apiUrl + 'api-girl/get-active-top&status_id=6&auth='
              + this.user.username + ':' + this.user.auth_key
              + `&city_id=${this.user.city?.id || 1}`)
          .then((response) => {
            this.selectionTop = response.data.data
          }).catch(error => console.log(error))

    },
    temptationClickHandler(id, service) {
      this.$store.commit('setCompilationService', service)
      window.open(`/compilation-service/${id}`)
    },
    sklonyator() {
      this.city = sklonyator.incline(this.user.city.name);
    },
    checkIosVersion() {
      const userAget = navigator.userAgent.toLowerCase()
      const isIphone = /iphone/ig.test(userAget)
      const pattern = /version\/(\d+\.\d+(\.\d+)?)/;
      const versionRegExp = isIphone ? +userAget.match(pattern)[1] : ''
      const version = versionRegExp.length > 4 ? versionRegExp.slice(0, -2) : versionRegExp

      console.log(userAget)
      console.log(versionRegExp)
      console.log(version)

      if (!isIphone) {
        this.isGsap = true
      } else if (isIphone && +version > 17.1) {
        this.isGsap = true
      } else if (isIphone && +version < 17.1) {
        this.isGsap = false
      } else {
        this.isGsap = false
      }
    }
  },
}

</script>

<template>
  <main :class="{'overflow-hidden' : !isGsap}">
    <div class="main-header-wrapper">
      <div class="main-block gsap-padding position-relative">
        <div class="container" v-if="isGsap">
          <div class="main-block-img main-block-img--mobile">
            <gsapSlider/>
          </div>
          <div class="main-block-img main-block-img--desktop">
            <img src="@/assets/img/main/girls.webp" alt="girls" @load="imageLoaded">
            <ui-loader :show="true" :preset="'intro'" v-if="loading"/>
          </div>
        </div>
        <div class="img_block-iphone overflow-hidden" v-else>
          <div class="wrapper-img-gsap py-5">
            <img class="img--mobile-gsap" src="@/assets/img/main/girls.webp" alt="img"/>
          </div>
        </div>
      </div>

      <div class="decription">
        <div class="block-offer__top mt-5 container">
          <div class="title block-offer__title">Категории в {{ city }}</div>

          <div class="block-offer__strip"></div>
          <!--                    <div class="block-offer__row">которым мы доверяем</div>-->
        </div>
        <category-carousel/>
      </div>
    </div>

    <div class="block-offer block-offer__ad-block">
      <div class="container">
        <div class="block-offer__inner">
          <ad-block :block_id="1"/>
        </div>
      </div>
    </div>

    <!--		<div class="block-offer">-->
    <!--			<div class="container">-->
    <!--				<div class="block-offer__inner">-->
    <!--					<new-profiles-block />-->
    <!--				</div>-->
    <!--			</div>-->
    <!--		</div>-->

    <div class="block-offer block-offer__video">
      <div class="container">
        <div class="block-offer__inner">
          <div class="block-offer__top">
            <div class="title block-offer__title">Видеовизитки <span v-if="city"> в {{ this.city }}</span>
            </div>
            <div class="block-offer__strip"></div>
            <div class="block-offer__row">наших роскошных девушек</div>
          </div>
          <div class="block-offer__slider"></div>
        </div>
      </div>
      <div class="container">
        <video-cards class="video-cards"/>
      </div>
    </div>

    <div class="block-offer order">
      <quiz-block/>
    </div>

    <div class="block-offer temptation">
      <div class="container">
        <div class="temptation__top">
          <div class="temptation__title title">Для самых искушенных</div>
        </div>
        <div class="temptation__body  d-none d-lg-block" v-if="!this.$store.getters.getIsMobile">
          <div class="temptation__list">
            <div class="temptation__item" v-for="(item, i) in gif" :key="item.id"
                 @click="temptationClickHandler(item.id, item.service)"
                 @mouseenter="playVideo"
            >
              <div class="temptation__text"><img src="@/assets/img/fire-yellow.svg" alt="svg">{{
                  item.name
                }}
              </div>
              <div class="temptation__video">
                <video :src="require(`@/assets/video/gif/${item.video}`)" loop muted preload="none"
                       :ref="`video-desktop-${i}`">
                </video>
                <span class="temptation__video-name"><img src="@/assets/img/fire-yellow.svg"
                                                          alt="svg">{{ item.name }}</span>
                <span class="temptation__video-ico"><img src="@/assets/img/fire-black.svg"
                                                         alt="svg"></span>
              </div>

            </div>

          </div>
        </div>
        <div class="d-block d-lg-none">
          <div class="temptation__list--mobile">
            <div :class="['temptation__item--mobile']" @click="openPicture(item)" v-for="item in gif"
                 :key="item.id">
              <div class="temptation__text"><img src="@/assets/img/fire-yellow.svg" alt="svg">{{
                  item.name
                }}
              </div>
            </div>
            <div class="temptation-prewie__photo" v-if="pictureGif !== ''"
                 @click="temptationClickHandler(pictureGifId, pictureGifService)">
              <img :src="require(`@/assets/img/prewie-photo/${pictureGif}`)" alt="img">
              <span class="temptation-prewie__ico"><img src="@/assets/img/fire-black.svg"
                                                        alt="svg"></span>
              <span class="temptation-prewie__name">{{ pictureName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block-offer block-offer__selection selection">
      <div class="container">
        <div class="block-offer__inner">
          <div class="block-offer__top">
            <div class="title block-offer__title">Подборки</div>
            <div class="block-offer__strip"></div>
            <div class="block-offer__row">для вашего удобства и быстрого&nbsp;поиска</div>
          </div>
          <div class="selection__inner row gap-0-2">
            <selection-item :selection="this.selection" :btn="this.selectionBtn"/>
            <selection-viewed/>
            <selection-item-online :selection="this.selectionTop" :btn="this.selectionTopBtn"/>
            <div class="selection__block col-lg-3 col-6">
              <div :class="this.favouritesClass">
                <div class="selection-block__text"
                     v-if="!this.user.isLogged || this.favourites.length == 0">
                  <div class="selection-block__ico"><img src="@/assets/img/heart.svg" alt="heart">
                  </div>
                  Место для&nbsp;ваших <br/> сохранёнок
                </div>

                <div class="selection__box"
                     :class="{ 'bord-box': this.user.isLogged && this.favourites.length < 4 }">
                  <div class="selection__item selection-item" v-for="(item, i) in this.favourites"
                       :key="i">
                    <div class="selection-item__bg" :style="{
											width: '100%',
											backgroundSize: 'cover',
											backgroundImage: 'url(' + this.apiDomine + '/web/uploads' + item.girlProfile?.photo[0].pic + ')'
										}">
                      <div class="selection-item--hover">
                        <div class="selection-item__name" v-html="item.girlProfile?.name"></div>
                        <div class="selection-item__age" v-html="m_age(item.girlProfile?.age)">
                        </div>
                        <div class="selection-item__location"
                             v-html="item.girlProfile?.metro?.name"></div>
                        <div class="selection-item__price">
                          <div>за час</div>
                          <div v-if="item.girlProfile?.price_hour_app != 0"
                               v-html="item.girlProfile?.price_hour_app"></div>
                          <div v-else v-html="item.girlProfile?.price_hour_exit"></div>
                        </div>
                        <div class="selection-item__price">
                          <div>за два</div>
                          <div v-if="item.girlProfile?.price_hour_app != 0"
                               v-html="item.girlProfile?.price_two_hour_app"></div>
                          <div v-else v-html="item.girlProfile?.price_two_hour_exit"></div>
                        </div>
                        <div class="selection-item__price">
                          <div>за ночь</div>
                          <div v-if="item.girlProfile?.price_hour_app != 0"
                               v-html="item.girlProfile?.price_night_app"></div>
                          <div v-else v-html="item.girlProfile.price_night_exit"></div>
                        </div>
                        <button class="selection-item__btn hover-btn"
                                @click="openBlank(item.girlProfile.id)">Открыть анкету
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="selection__btn" v-if="this.user.isLogged">
                  <button class=" hover-btn"
                          @click="favourites.length > 0 ? this.$router.push('/collections') : ''">
                    <img src="@/assets/img/star-gold.svg" alt="...">
                    Сохраненные
                  </button>
                </div>
                <div class="selection-block__btn" v-if="!this.user.isLogged">
                  <LogInBlock/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block-offer block-offer__selection selection">
      <div class="container">
        <all-tags/>
      </div>
    </div>
  </main>
  <video-widget/>
</template>
<style lang="scss">
@import '@/styles/index';


.gsap-padding {
  padding-top: 155px;
  @media (max-width: 760px) {

  }
}

.gap-0-2 {
  gap: 20px 0px;
}

.wrapper-img-gsap {
  overflow: hidden;
}

.img--mobile-gsap {
  max-width: 2000px;
  width: 100%;
  height: auto;
  object-fit: cover;
  @media (max-width: 594px) {
    max-width: 800px;
    height: 320px;
    width: 100%;
  }
}


.link {
  position: relative;
  color: #FFFADB;
  margin-left: auto;
  margin-right: 40px;
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 700;
}

.link::before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #FFFADB;
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
}

.main-block {
  background-image: url('@/assets/img/bg-top.webp');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 2%;
  margin-top: -155px;
  @media ($tablet) {
  }

  &__title {
    color: $white;
    text-align: center;
    font-size: 128px;
    font-size: 6vw;
    font-weight: 950;
    letter-spacing: 2rem;
    text-transform: uppercase;
    // @media ($desktop) {
    // 	font-size: 5vw;
    // }
    // @media ($tablet) {
    // 	display: none;
    // }
  }

  &__img {

    @media($phoneS) {
      overflow: hidden;
      margin: 0 -12px;
    }

    img {
      @media($phoneS) {
        max-width: 1250px;
      }
    }
  }
}

.main-block-img {
  &--mobile {
    display: none;

    @media(max-width: 575px) {
      display: block;
    }
  }

  &--desktop {
    display: block;

    @media(max-width: 575px) {
      display: none;
    }
  }
}

.block-offer {
  padding: 3.5rem 0;

  .slider-offer {
    max-width: 100%;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    gap: 10px;
  }

  &__row {
    color: $text;
    text-align: right;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    text-align: right;

    @media ($tablet) {
      font-size: 16px;
    }

    @media ($phoneS) {
      font-size: 10px;
    }
  }

  &__strip {
    display: block;
    height: 1px;
    flex-grow: 1;
    background-color: rgba(189, 193, 209, 0.31);
  }
}

.page-bott {
}

//-----slider cahpter
.chapter-slider-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    bottom: 0px;
    top: auto;
    right: 50px;
  }

  .swiper-button-prev {
    left: auto;
    right: 100px;
  }

  .swiper-wrapper {
    padding: 0 0 60px;
  }

  .swiper-slide {
    padding: 0;
  }
}

.chapter-slide {
  height: 700px;
  display: block;
  width: 100%;
  background-color: $white;
  // border-right: solid 1px transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: 10s;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: #E6E6E6;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    //background-color: rgba(255,255,255,0.3);
    background-color: #fff;
    z-index: 2;
    transition: 1s;
    transform: matrix3d(1, 0, 5, 0, 0, 2, 1, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  @media ($tablet) {
    height: 70vh;
  }

  &:hover {
    &::after {
      opacity: 0;
      top: 50%;
      left: 50%;
      bottom: 50%;
      right: 50%;
      transition: 1s;
      border-radius: 10%;
      //backdrop-filter: blur(50px);
      //box-shadow: 0 0 10px rgba($color: #fff, $alpha: 1.0) inset;
      transform: matrix3d(10, 0, 5, 0, 0, 2, 1, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }

  }

  &__block {
    min-width: 25%;
    flex-grow: 1;

    &:nth-child(5) {
      border-top: solid 1px #E6E6E6;
      border-bottom: solid 1px #E6E6E6;
    }

    &:nth-child(6) {
      border-top: solid 1px #E6E6E6;
      border-bottom: solid 1px #E6E6E6;
    }

    &:nth-child(7) {
      border-top: solid 1px #E6E6E6;
      border-bottom: solid 1px #E6E6E6;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1.8);
    z-index: 1;
    // width: 100%;
  }

  &__num {
    //color: #BDC1D1;
    color: #000;
    text-decoration: none;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: auto;
    position: absolute;
    right: 100px;
    bottom: 45px;
    z-index: 3;
  }

  &__name {
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    text-align: right;
    //color: #525665;
    color: #000;
    font-size: 48px;
    line-height: 130%;
    font-weight: 800;
    position: absolute;
    margin-top: auto;
    right: 25px;
    bottom: 45px;
    z-index: 3;
  }
}

.decription {
  .swiper {
    height: auto;
  }
}

.slider-offer {
  .swiper {
    height: 100%;
  }
}

.swiper-offer-slider {
  padding: 0 0 60px;
  position: relative;

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    bottom: 0px;
    top: auto;
    right: 0;

    @media($phoneB) {
      left: 60%;
    }
  }

  .swiper-button-prev {
    left: auto;
    right: 60px;

    @media($phoneB) {
      right: 60%;
    }
  }
}

.swiper-offer-video {
  .swiper-slide {
    border-radius: 26px;
    overflow: hidden;
    height: 420px;

    img {
      height: auto;
      width: auto;
    }
  }

  &__content {
    color: #fff;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__name {
    text-align: left;
  }

  &__time-strip {
    display: flex;
    width: 100%;
    height: 3px;
    background-color: rgba(#fff, 0.3);
    border-radius: 42px;
    margin-bottom: 5px;

    span {
      display: block;
      background-color: #FF4032;
      width: 25%;
      border-radius: 42px;
    }
  }

  &__time-time {
    color: rgba(#fff, 0.8);
    font-size: 12px;
    line-height: 120%;
  }

  &__function {
    position: absolute;
    right: 15px;
    top: 35px;

    div {
      width: 25px;
      height: 25px;
      backdrop-filter: blur(10px);
      border-radius: 50%;
      cursor: pointer;
      margin-bottom: 5px;
      transition: 0.3s;

      &:hover {
        background: #afafaf;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__bott {
    margin-top: auto;
    margin-bottom: 15px;

    button {
      backdrop-filter: blur(20px);
      color: #fff;
      font-size: 16px;
      line-height: 120%;
      border-radius: 300px;
      padding: 16px 0;
      display: block;
      width: 100%;
      max-width: 80%;
      margin: 0 auto;
      transition: 0.5s;

      &:hover {
        background: linear-gradient(to right, #a89ea1, #7c7aa8);
      }
    }
  }

  &__mid {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

.selection {
  &__block {
    @media ($lg) {
      margin-bottom: 20px;
    }
  }

  &__box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 310px;
    width: 100%;
    flex-grow: 1;
    border-radius: 16px;
    overflow: hidden;

    @media($phone) {
      height: 170px;
    }
  }

  &__item {
    flex: 0 0 46%;
    max-width: 46%;
    position: relative;
    transition: 1s;

    @media($phoneS) {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  &__btn {
    margin-top: 20px;

    button {
      background: #EFEFEF;
      display: inline-flex;
      border-radius: 100px;
      align-items: center;
      padding: 11px 21px 10px 12px;
      transition: 0.3s;
      font-size: 14px;

      @media(max-width: 575px) {
        padding: 10px;
        font-size: 10px;
      }

      &:hover {
        background: #c2c0c0;
      }
    }

    img {
      padding-right: 10px;
      padding-bottom: 2px;
    }
  }
}

.selection-block__signin > .selection__box.bord-box {
  border-radius: 16px;
  border: 1px dashed #8E7F7D;
}

.selection-item {
  transition: 0.8s;

  position: absolute;
  width: 100%;
  line-height: 130%;

  &__name {
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
  }

  &__age {
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
  }

  &__location {
    font-size: 12px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;

    span {
      display: block;
    }

  }

  &__btn {
    background: rgba(#fff, 0.5);
    border-radius: 100px;
    padding: 10px;
    transition: 0.3s;
    max-width: 80%;
    width: 100%;
    display: block;
    margin: 10% auto;
    text-align: center;
    margin-top: auto;

    @media(max-width: 575px) {
      padding: 5px 0;
      max-width: 100%;
      font-size: 10px;
    }

    &:hover {
      background-color: #fff;
    }
  }

  &__price {
    display: flex;
    font-size: 14px;
    line-height: 120%;

    @media(max-width: 575px) {
      font-size: 10px;
    }

    div {
      margin-bottom: 5px;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }

  &:nth-child(1) {
    top: 0;
    left: 0;
  }

  &:nth-child(2) {
    top: 0;
    right: 0;
  }

  &:nth-child(3) {
    left: 0;
    bottom: 0;
  }

  &:nth-child(4) {
    right: 0;
    bottom: 0;
  }

  &--hover {
    opacity: 0;
    visibility: hidden;
    height: 0;
    //transition: 1s;
    color: #fff;
    font-size: 14px;
    position: relative;

    z-index: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &::after {
      content: '';
      background-color: rgba(#000, 0.5);
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      backdrop-filter: blur(4px);
    }
  }

  &:hover {
    flex: 0 0 100%;
    max-width: 100%;
    height: 100%;
    z-index: 10;

    .selection-item__bg {
      width: 100%;
      height: 100% !important;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
    }

    .selection-item--hover {
      opacity: 1;
      visibility: visible;
      height: 100%;
      z-index: 100;
    }
  }

  &__bg {
    height: 145px;
    color: #fff;

    @media ($tablet) {
      height: 79px;
    }
  }


}

.selection-block {
  &__signin-bord {
    border-radius: 16px;
    border: 1px dashed #8E7F7D;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 100%;
  }

  &__signin {
    border-radius: 16px;
    border: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 100%;
  }

  &__btn {
    margin: auto auto 15px;
    text-align: center;
    display: block;

    .log-in {
      &__btn {
        width: 100% !important;

        &:hover button {
          background-color: none;
          box-shadow: 0 0 7px rgba(#1d1d1d, 0.7);
        }
      }

      &__header {
        background-color: #504e4e;
        border-radius: 14px;
        padding: 10px 5px;
      }
    }

    button {
      cursor: pointer;
      color: #fff;
      background: linear-gradient(to right, #9A7F88, #201F36);
      border-radius: 26px;
      text-align: center;
      font-size: 16px;
      padding: 10px 12px;
      transition: 0.3s;

      &:hover {
        box-shadow: 0 0 15px rgba(#000, 0.9) inset;
      }
    }
  }

  &__text {
    color: #000;
    font-size: 24px;
    font-style: normal;
    line-height: 130%;
    text-transform: lowercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media($tablet) {
      font-size: 16px;
    }
  }

}

.order {
  &__body {
    .order-item + .order-item {
      margin-left: 15px;
    }
  }

  &__title {
    line-height: 35px !important;
    margin-bottom: 60px;

    @media(max-width: 767px) {
      margin-bottom: 25px;
    }

    span {
      color: #2D2F3A;
      font-size: 20px;

      @media(max-width: 767px) {
        font-size: inherit;
      }
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
}

.order-item {
  border-radius: 16px;
  border: 1px solid #8E7F7D;
  height: 380px;
  width: 100%;
  overflow: hidden;
  position: relative;

  // flex: 0 0 32.333%;
  // max-width: 32.333%;
  @media(max-width: 991px) {
    // flex: 0 0 280px;
    // max-width: 280px;
    height: 100%;
  }

  &__btn {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);

    button {
      color: #FFF;
      font-size: 16px;
      padding: 10px 26px;
      white-space: nowrap;
      border-radius: 30px;
      background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
      transition: 0.3s;
    }
  }
}

.order-swiper {
  .swiper {
    height: 100%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    bottom: 0px;
    top: auto;
    right: 0;

  }

  .swiper-button-prev {
    left: auto;
    right: 50px;

    @media (max-width: 575px) {
      left: 35%;
      transform: translateX(-50%);
    }
  }

  .swiper-wrapper {
    padding: 0 0 60px;
  }

  .swiper-slide {
    padding: 0;
  }
}

.temptation {
  &__title {
    margin-bottom: 150px;

    @media(max-width: 991px) {
      margin-bottom: 25px;
    }
  }

  &__body {
  }

  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 16px;
    background: #ECECEC;
    padding: 25px 10px;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    &--mobile {
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      display: flex;
      flex-wrap: wrap;
      text-transform: uppercase;
      gap: 8px 8px;
    }

  }

  &__video {
    display: none;
    position: absolute;
    border-radius: 16px;
    overflow: hidden;
    transition: 0.3s;
    bottom: 0;
    border-radius: 16px;
    border: 1px solid #CCC4BE;
    background: #FAFAFA;
    cursor: pointer;

    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__item {
    position: relative;
    text-transform: uppercase;
    border-right: solid 1px #CDC8C7;
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
    text-align: center;
    padding: 0 5px;

    &:last-child {
      border: none;
    }

    &:hover .temptation__video {
      width: 100%;
      height: 220px;

      // opacity: 1;
      // visibility: visible;

      display: block;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__text {
    border-radius: 26px;
    background: #1B2037;
    display: flex;
    padding: 4px 10px 1px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFC804;
    font-size: 13px;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    display: -webkit-box;

    @media(max-width: 1200px) {
      font-size: 10px;
    }

    img {
      height: 0.8em;
      margin-right: 3px;
      margin-top: -3px;
      display: block;
    }
  }

  &__video-name {
    border-radius: 26px;
    background: #1B2037;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFC804;
    font-size: 10px;
    line-height: 100%;
    position: absolute;
    padding: 0px 10px;
    line-height: 20px;
    height: 20px;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    img {
      height: 0.8em;
      margin-right: 3px;
    }
  }

  &__video-ico {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #FFC804;
    border-radius: 50%;
    top: -7px;
    right: -7px;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  &__prewie-photo {
  }
}

.temptation-prewie {
  &__photo {
    position: relative;
    width: 100%;
    height: 50vh;
    border-radius: 16px;
    border: 1px solid #CCC4BE;
    overflow: hidden;
    background: #FAFAFA;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.26);
    margin-top: 30px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__ico {
    display: block;
    width: 55px;
    height: 55px;
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: #FED305;
    border-radius: 50%;

    img {
      position: absolute;
      top: 10px;
      right: 0;
      bottom: 0;
      left: -10px;
      margin: auto;
      width: 23px;
      height: 23px;
    }
  }

  &__name {
    color: #FFC804;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    /* 160% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    border-radius: 30px;
    padding: 3px 5px 2px 15px;

    &::after {
      content: '';
      display: block;
      background-image: url('@/assets/img/fire-yellow.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 3px;
      top: 45%;
      transform: translateY(-50%);
    }
  }
}
</style>
<style lang="css">
/*.block-offer__ad-block,*/
.block-offer__video {
  display: none;
}

/*body:has(.ad-block) .block-offer__ad-block,*/
body:has(.video-cards) .block-offer__video {
  display: block;
}
</style>