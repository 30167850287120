<script>
export default {
    name: 'plane-icon-solid',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        size: {
            type: Object,
            default() {
                return { w: 24, h: 24 };
            },
        },
    },
};
</script>

<template>
    <svg
        :width="size.w"
        :height="size.h"
        :viewBox="`0 0 ${size.w} ${size.h}`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.3085 14.9361L18.6817 10.8166C19.8813 7.21793 20.4811 5.41858 19.5312 4.46876C18.5814 3.51895 16.7821 4.11873 13.1834 5.3183L9.06389 6.69146C6.15939 7.65963 4.70714 8.14371 4.29445 8.85359C3.90185 9.5289 3.90185 10.363 4.29445 11.0383C4.70713 11.7481 6.15939 12.2322 9.06389 13.2004C9.42385 13.3204 9.82881 13.2347 10.0983 12.9677L14.5029 8.60396C14.7506 8.35847 15.1505 8.36033 15.396 8.60812C15.6415 8.85591 15.6397 9.2558 15.3919 9.5013L11.0585 13.7945C10.7614 14.0889 10.6674 14.5394 10.7996 14.9361C11.7678 17.8406 12.2519 19.2929 12.9617 19.7056C13.637 20.0982 14.4711 20.0982 15.1464 19.7056C15.8563 19.2929 16.3404 17.8406 17.3085 14.9361Z"
            :fill="color"
        />
    </svg>
</template>
