<template>
	<div :class="['body-backdrop', {'show' : isShowMenu}]"></div>
	<header class="header-s">
		<div class="header-s__desktop">
			<div class="container">
			<div class="header-s__wrap py-4">
				<div class="header-s__list d-flex align-items-center justify-content-between">
					<div class="header-s__item col-2">
                        <router-link to="/"><img src="../../../assets/img/logo.svg" class="logo"></router-link>
					</div>
					<div class="header-s__item col-3">
						<label class="header-s__label">
							<input type="text" class="header-s__input">
							<span class="header-s__input-img"></span>
						</label>
					</div>
					<div class="header-s__item col-4">
						<div class="d-flex align-items-center">
							<div class="drop-list__wrap d-flex ms-auto align-items-center">
								<div class="drop-list__field" 
									@click="popuCitiesShow = true"
									v-click-outside="popupCitiesHide"
								>
									<div class="header-s__city drop-list__item" v-html="city"></div>
									<div :class="['drop-list__list', {'show' : popuCitiesShow}]">
										<p v-for="(item, i) in cities" :key="i" v-html="item.name" @click.stop="pickCity(item.name)"></p>
								</div>
								</div>
<!--								<ui-switch />-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
		
		<div class="header-mobile">
				<div class="container">
					<div class="row justify-content-between">
						<div class="header-left col-6">
                            <router-link to="/"><img src="../../../assets/img/logo.svg" class="logo"></router-link>
						</div>
						<div class="col-6 header-mobile__burger">
							<div @click="showMenu" class="menu-burger-button">
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				
					<div v-swipe="swipingMenu" :class="['mobile-menu', {'show' : isShowMenu}]">
						<div class="mobile-menu__touch-box"></div>
						<div class="header__inner row justify-content-between">
							<div class="header-right col-12 justify-content-center">
								<div class="header__choice drop-list__wrap">
									<div class="drop-list__field" 
										@click="popuCitiesShowMobile = true"
										v-click-outside="popupCitiesHideMobile"
									>
										<div class="header__city drop-list__item" v-html="city"></div>
										<div :class="['drop-list__list', {'show' : popuCitiesShowMobile}]">
											<p v-for="(item, i) in cities" :key="i" v-html="item.name" @click.stop="pickCity(item.name)"></p>
										</div>
									</div>
									
<!--									<ui-switch />-->
								</div>
								<div class="header-user__photo"
									@click.stop="menuShow = true"
									v-click-outside="menuHide"
								>
									<img src="../../../assets/img/user.png" alt="user">
								</div>

								<div :class="['header-user__drop-menu header-user__drop-menu--mobile', {'show' : menuShow}]">
									<ul class="header-user-menu__list">
										<li 
											v-for="(item, i) in menuLink" 
											:key="i" 
											@click.stop="openSubmenuMobile"
											:ref="`userMenuLink_${i}`"
											:class="['header-user-menu__item']"
											>
											<div>
												<img :src="require(`@/assets/img/anketa/svg2/${item.img}`)" alt="svg">
												<p v-html="item.name"></p>
											</div>
											<ul class="header-user-menu__submenu header-user-menu__submenu--mobile">
												<li
													v-for="(item,i) in item.location" :key="i"
													v-html="item.name"
													@click="$router.push(item.url)"
												></li>
											</ul>
										</li>
									</ul>
								</div>

							</div>
							<div class="header-midd col-12 drop-list__wrap drop-list__field">
								<div class="header-midd__inner">
									<div class="header-midd__choice drop-list__item"
										@click="popupPagesShowMobile = true"
										v-click-outside="popupPagesHideMobile"
										>
										{{ $route.query.search }}
										<img src="../../../assets/img/lens-gray.svg" alt="lens">
									</div>
								</div>
								<div 
									:class="['drop-list__list', {'show' : popupPagesShowMobile}]" 
									>
									<p v-for="(item, i) in list" :key="i" v-html="item.name" @click.stop="pickPage(item.name)"></p>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
	</header>
</template>

<script>

import UiSwitch from "@/components/ui/UiSwitch.vue";
export default {
	components: {
        UiSwitch,
	},
	data() {
		return {
			city: 'Екатеринбург',
			popuCitiesShow: false,
			popupPagesShow: false,
			popupPagesShowMobile: false,
			popuCitiesShowMobile: false,
			isShowMenu: false,
			menuShow: false,
			list: [],
			cities: [
				{
					name: 'Москва',
				},
				{
					name: 'Ектеринбург',
				},
				{
					name: 'Новосибирск',
				},
				{
					name: 'Рязань',
				},
				{
					name: 'Абу&nbsp;-&nbsp;Даби',
				},
				{
					name: 'Санкт-Петербург',
				},
				{
					name: 'Тюмень',
				}
			],
			menuLink: [
				{
					name: 'Мои&nbsp;анкеты',
					img: 'item1.svg',
					url: '#',
					location: [
						{
							name: 'Анкеты в России',
							url: '/questionnaire'
						},
						{
							name: 'Анкеты В&nbsp;ОАЭ',
							url: '#'
						}
					]
				},
				{
					name: 'Просмотры&nbsp;/&nbsp;отзывы',
					img: 'item2.svg',
					url: '#'
				},
				{
					name: 'Message',
					img: 'item3.svg',
					url: '#'
				},
				{
					name: 'Вебкам',
					img: 'item4.svg',
					url: '#',
					location: [
						{
							name: 'Правила трансляции'
						},
						{
							name: 'Тарифы'
						}
					]
				},
				{
					name: 'Продвижение',
					img: 'item5.svg',
					url: '#'
				},
				{
					name: 'Финансы',
					img: 'item6.svg',
					url: '#'
				},
				{
					name: 'Настройка&nbsp;акаунта',
					img: 'item7.svg',
					url: ''
				},
				{
					name: 'Заказы',
					img: 'item8.svg',
					url: '#'
				},
			]
		}
	},
	methods: {
		menuHide() {
			this.menuShow = false
		},
		getCategories() {
			this.list = this.$router.options.routes.filter(route => route.name)
		},
		pickCity(namePath) {
			this.city = namePath
			this.popuCitiesShow = false
			this.popuCitiesShowMobile = false
		},
		popupCitiesHide() {
			this.popuCitiesShow = false
		},
		showMenu() {
			this.isShowMenu = true
		},
		popupCitiesHideMobile() {
			this.popuCitiesShowMobile = false
		},
		popupPagesHideMobile() {
			this.popupPagesShowMobile = false
		},
		swipingMenu(event, element, elementHeight, startY, deltaY, classNonTransition, swipeResult, touchEnd) {
				if(classNonTransition) element.classList.add('non-transition');
				else element.classList.remove('non-transition');  
				if(deltaY > 25 || deltaY < -25) element.style.bottom = `-${deltaY}px`
				if(touchEnd && swipeResult === 'Up' && (deltaY > 25 || deltaY < -25)) {
					this.isShowMenu = true
					element.style.bottom = '0'
				}else if(touchEnd && swipeResult === 'Down' && (deltaY > 25 || deltaY < -25)){
					this.isShowMenu = false
					element.style.bottom = '0'
				}
			},
		openSubmenu(i, nav) {
			const currentRef = `userMenuLink_${i}`
			const element = this.$refs[currentRef][0]
			const isChild = element.querySelectorAll('.header-user-menu__submenu li').length ? true : false
			if(!isChild) {
				//this.$router.push(nav)
				return
			}
			if(element.classList.contains('is-active')) {
				element.classList.remove('is-active')
			} else {
				element.classList.add('is-active')
			}
		},
		openSubmenuMobile() {
			const element = event.target
			const elementWrap = element.closest('li')
			if(elementWrap.classList.contains('is-active')) {
				elementWrap.classList.remove('is-active')
			} else {
				elementWrap.classList.add('is-active')
			}
		}
	},
	created() {
		this.getCategories()
	}
}
</script>

<style lang="scss">
.header-s {
	background: linear-gradient(to right, #72666A, #201F36);
	&__desktop {
		display: block;
		@media(max-width: 1023px) {
			display: none;
		}
	}
	.switch__name{
		color: #525665;
		&.active {
			color: #fff;
			border-color: #525665;
		}
	}
	&__list {
	}
	&__city {
		color: #fff;
		display: inline-block;
		border-bottom: solid 1px #525665;
		margin-right: 25px;
		cursor: pointer;
	}
	&__label {
		position: relative;
		display: block;
		max-width: 260px;
		margin: 0 auto;
	}
	&__input-img {
		position: absolute;
		top: 11px;
		right: 10px;

		background-image: url('@/assets/img/lens-white.svg');
		background-origin: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 13px;
		width: 18px;
	}
	&__input {
		background: red;
		margin: 0 auto;
		display: block;
		border-radius: 44px;
		background: rgba(255, 255, 255, 0.30);
		height: 35px;
		line-height: 35px;
		padding: 0 25px 0 15px;
		width: 100%;
	}
}

.body-backdrop {
	position: fixed;
	backdrop-filter: blur(8px);
	background-color: rgba(#000, 0.3);
	opacity: 0;
	visibility: hidden;
	transition: 0.4s;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9;
	&.show {
		opacity: 1;
		visibility: visible;
	}
}
</style>