<script setup>
import { ref, computed, onBeforeMount, watch, onUnmounted, onMounted } from "vue"
import MessengerSidebarListItem from './MessengerSidebarListItem.vue'
import { useStore } from "vuex"
import axios from "axios"

const store = useStore()
const user = store.getters.getUser
const apiUrl = store.getters.getApiUrl
const isMobile = store.getters.getIsMobile
const activeChat = computed(() => {
    return store.getters.getActiveChat
})

const emits = defineEmits(['choose'])

const search = ref('')
const activeChats = ref([])
const interval = ref(undefined)

const getActiveChats = async () => {
    try {
        const params = '&auth=' + user.username + ':' + user.auth_key + '&user_id=' + user.profile.user_id
        const { data: chats } = await axios.get(apiUrl + 'api-chat/get-chats-by-user-id' + params)
        const result = []

        chats.forEach(chat => {
            if (!chat.chat) return false

            const { chatMessage } = chat.chat
            const lastMessageRecipient = {
                user_id: '',
                name: '',
                recipient_id: ''
            }
            let isLastMessage = true
            let lastMessage
            let i = 0

            if (chatMessage.length) {
                while (isLastMessage && i < 10) {
                    if (+chatMessage[i].recipient_id !== +user.user_id) {
                        isLastMessage = false
                        lastMessage = chatMessage[i]

                        lastMessageRecipient.user_id = lastMessage.author_id
                        lastMessageRecipient.name = lastMessage.recipient?.name
                        lastMessageRecipient.recipient_id = lastMessage.recipient?.user_id
                        break
                    } else if (+chatMessage[i].author_id !== +user.user_id) {
                        isLastMessage = false
                        lastMessage = chatMessage[i]

                        lastMessageRecipient.user_id = lastMessage.author_id
                        lastMessageRecipient.name = lastMessage.author.name
                        lastMessageRecipient.recipient_id = lastMessage.author.user_id
                        break
                    }

                    i++
                }

                if (lastMessageRecipient.name && lastMessageRecipient.recipient_id) {
                    let messageStatus = lastMessage.date_read ? 'read' : 'new'

                    if (+lastMessage.author_id === +user.user_id) {
                        messageStatus = 'read'
                    }

                    const item = {
                        id: chat.chat_id,
                        text: decodeURIComponent(atob(lastMessage.text)),
                        add: lastMessage.date_add,
                        time: timeToDay(lastMessage.date_add),
                        status: messageStatus,
                        name: lastMessageRecipient?.name || `UserID: ${lastMessageRecipient.recipient_id}`,
                        recipient_id: lastMessageRecipient.recipient_id
                    }

                    result.push(item)
                }
            } else {
                lastMessage = {
                    text: '',
                    date_add: chat.chat.date_add,
                    date_read: null
                }
                lastMessageRecipient.user_id = chat.chat.user_id
                lastMessageRecipient.name = activeChat.value.recipient
                lastMessageRecipient.recipient_id = chat.chat.target_user_id
            }
        });

        activeChats.value = result.sort((a, b) => {
            if (a.add < b.add) return 1;
            if (a.add > b.add) return -1;

            return 0;
        })

    } catch (error) {
        console.log('getActiveChats: ', error)
    }
}

const filteredChats = computed(() => {
    return activeChats.value.filter(c => c.name.toLowerCase().includes(search.value.toLowerCase()))
})

const getPrivateChat = async (recipient_id) => {
    try {
        const params = '&auth=' + user.username + ':' + user.auth_key + '&user_id=' + user.user_id + '&target_user_id=' + recipient_id
        const { data } = await axios.get(apiUrl + 'api-chat/get-private-chat' + params)

        console.log('getPrivateChat:', data)
    } catch (error) {
        console.log('getPrivateChat: ', error)
    }
}

const clickListItemHandler = (item) => {
    emits('choose', item)
}

const timeToDay = (time) => {
    const dateAdd = new Date(time * 1000)
    const hours = dateAdd.getHours() < 10 ? "0" + dateAdd.getHours() : dateAdd.getHours()
    const minutes = dateAdd.getMinutes() < 10 ? "0" + dateAdd.getMinutes() : dateAdd.getMinutes()

    return `${hours}:${minutes}`
}

const startTimer = () => {
    stopTimer();

    interval.value = window.setInterval(() => {
        getActiveChats()
    }, 2000)
}

const stopTimer = () => {
    if (interval) {
        window.clearInterval(interval.value)
    }
}

onBeforeMount(() => {
    getActiveChats()
})

onMounted(() => {
    startTimer()
})

onUnmounted(() => {
    stopTimer()
})

watch(activeChat, (newActiveChat) => {
    if (newActiveChat.message_id && !isMobile) {
        console.log('watch sidebar: ', newActiveChat)
        getActiveChats()
    }
}, { deep: true })

</script>

<template>
    <div class="messenger-sidebar w-100">
        <div class="messenger-search-input mb-2 mb-md-3 p-2 p-md-0">
            <label for="sidebar-search" class="w-100">
                <input type="text" id="sidebar-search" placeholder="Поиск" v-model="search">
                <button class="position-absolute"><img src="@/assets/img/loop.svg" alt="search"></button>
            </label>
        </div>
        <div class="messenger-sidebar-list">
            <div class="px-2 px-md-0">
                <messenger-sidebar-list-item v-for="item in filteredChats" :key="item.id" :item="item"
                    @click="clickListItemHandler(item)" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.messenger-sidebar {
    max-width: 347px;
    min-width: 250px;

    @media screen and (max-width: 767px) {
        &:not(.show) {
            display: none !important;
        }

        max-width: unset;
    }

    .messenger-search-input label {
        position: relative;

        button {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
        }
    }

    .messenger-search-input input {
        width: 100%;
        height: 57px;
        border-radius: 12px;
        background-color: #20232E;
        color: #fff;
        padding: 10px 45px 10px 15px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;

        @media screen and (max-width: 767px) {
            height: 30px;
            font-size: 10px;
            border-radius: 8px;
            background-color: #2D2F3A;
        }
    }

    .messenger-sidebar-list {
        max-height: 600px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #797979 #535353;

        @media screen and (max-width: 767px) {
            max-height: calc(100vh - 140px);
        }

        @media screen and (max-height: 850px) {
            max-height: calc(100vh - 240px);
        }
    }


}
</style>