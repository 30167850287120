<script>
import InputWithCopy from '@/components/ui/InputWithCopy.vue';
import axios from 'axios';
import UiLoader from '@/components/ui/UiLoader.vue';
import CopyText from '@/components/ui/CopyText.vue';
export default {
  name: 'AddingForm',
  components: { CopyText, UiLoader, InputWithCopy },
  props: {
    rate: {
      type: Number,
      require: true,
    },
    wallet: {
      type: String,
      require: true,
    },
  },
  methods: {
    async uploadPhoto(event) {
      const file = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file)
      const formData = new FormData();
      formData.append('image', file);

      try {
        const res = await axios.post(
          this.apiUrl +
            'api-billing/upload-photo' +
            '&user_id=' +
            this.user.user_id +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          formData
        );
        if (res.status == 200) {
          this.userScreen = res.data.name;
        }
      } catch (error) {
        console.log('addingfromnowallet', error);
      }
    },
    checkNumbers(input, a, b) {
            const value = input.value;
            const isValid = value && value >= a && value <= b;
            if (!isValid) {
                input.classList.add('is-invalid');
                this.error = 'invalid value'
            } else {
                input.classList.remove('is-invalid');
                this.error = ''
            }
    }, 
    rubToUsdt() {
      this.errorText = false;
      this.rubClass = 'form-control';
      this.usdtClass = 'form-control';
      this.usdt = parseFloat(this.rub) / this.rate;
      this.usdt = Number(this.usdt).toFixed(7);
    },
    usdtToRub() {
      this.errorText = false;
      this.usdtClass = 'form-control';
      this.rubClass = 'form-control';
      this.rub = parseFloat(this.usdt) * this.rate;
      this.rub = Number(this.rub).toFixed(2);
    },
    send() {
      this.errorText = false;

      let valid = true;
      if (this.usdt == '') {
        this.usdtClass = 'form-control is-invalid';
        this.errorText = 'Заполните все поля';
        valid = false;
      }
      if (this.userScreen == '') {
        this.usdtClass = 'form-control is-invalid';
        this.errorText = 'Заполните все поля';
        valid = false;
      }
      if (this.requestNum == '') {
        this.usdtClass = 'form-control is-invalid';
        this.errorText = 'Заполните все поля';
        valid = false;
      }

      if (this.rub == '' || this.rub < 6000) {
        this.rubClass = 'form-control is-invalid';
        this.errorText = 'Заполните все поля';
        valid = false;
      }

      if (!valid) {
        return false;
      }

      let params = {
        usdt: this.usdt,
        rub: this.rub,
        rate: this.rate,
        wallet: this.wallet,
        image: this.userScreen,
        request_num: this.requestNum,
        method_id: 2,
      };

      this.showLoader = true;
      axios
        .post(
          this.apiUrl +
            'api-billing/balance-plus-request' +
            '&user_id=' +
            this.user.user_id +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          params
        )

        .then((response) => {
          if (response.data.status) {
            this.rub = ''
            this.usdt = ''
            this.requestNum = ''
            this.imageUrl = ''
            this.$emit('modalOpen', true);
          }
          this.showLoader = false;
          this.btnText = 'Запрос отправлен';
        })
        .catch((error) => {
          this.btnText = 'Ошибка.';
          return false;
        });
    },
    filterInput(){
      this.requestNum = this.requestNum.replace(/[^a-zA-Z0-9]/g, '')
    },
    openImages(e){
      if (e.target.closest('.users-img')) {
        this.exampleImg = true
        this.defImages = false
        this.usersImages = true
      }
      if (e.target.closest('.examp-img')) {
        this.exampleImg = true
        this.defImages = true
        this.usersImages = false
      }
    },
    clearImages(){
      this.imageUrl = ''
      this.userScreen = ''
    }
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      rub: '',
      rubClass: 'form-control',
      usdtClass: 'form-control',
      userWalletClass: 'form-control',
      usdt: '',
      userScreen: '',
      requestNum: '',
      errorText: false,
      showLoader: false,
      btnText: 'Оплата произведена',
      exampleImg: false,
      imageUrl: '',
      defImages: false,
      usersImages: false
    };
  },
};

//TODO: Меню, шрифты
//TODO:
</script>

<template>
  <div class="adding-form">
    <div class="row">
      <div class="col-12">
        <h4>Пополнить баланс</h4>
      </div>
      <div class="col-12 mt-3">
        <div class="form-block position-relative">
          <ui-loader :preset="'fullBlock'" :show="showLoader" />
          <div class="row">
            <div class="col-12">
              <h5>Введите сумму</h5>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="input-group">
                <input
                  @keyup="rubToUsdt"
                  v-model="this.rub"
                  type="number"
                  :class="this.rubClass"
                  aria-label="Имя пользователя получателя"
                  aria-describedby="basic-addon2"
                  @blur="checkNumbers($event.target, 6000, 100000)"
                />
                <span class="input-group__info">Минимальная сумма пополнения 6000</span>
                <span class="input-group-text icon-block" id="basic-addon2">
                  <img
                    src="@/assets/img/flag_rf.jpg"
                    alt="Флаг Российской Федерации"
                  />
                  <span><b>RUB</b></span>
                </span>
              </div>
            </div>
            <div
              class="col-md-auto d-flex align-items-center justify-content-center"
            >
              <span class="equally">=</span>
            </div>

            <div class="col">
              <div class="input-group">
                <span class="input-group-text icon-block" id="basic-addon2">
                  <img src="@/assets/img/usdt.png" alt="usdt" />
                  <span><b>USDT</b></span>
                </span>
                <copy-text :copy-text="this.usdt" />
                <input
                  @keyup="usdtToRub"
                  v-model="this.usdt"
                  type="number"
                  :class="this.usdtClass"
                  placeholder="10000"
                  aria-label="Имя пользователя получателя"
                  aria-describedby="basic-addon2"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-auto text-danger">
              <b>Используйте сеть TRC20</b>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 adding-form__copy">
              Скопируйте адрес нашего крипто кошелька и произведите оплату
            </div>
            <div class="col-12 mt-1">
              <input-with-copy :text="this.wallet" :disabled="true" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-danger">
              Проверяйте адрес скопированного кошелька в обменнике, это
              обезопасит ваши средства! Встречаются случаи мошенничества
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 adding-from__prostocash">
              <p>
                Для простого способа оплаты нашего сервиса, мы рекомендуем
                воспользоваться проверенным обменником:
                <a href="https://prostocash.com/ru/?rid=17167994483866" target="_blank"
                  >prostocash.com</a
                >
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 adding-from__scrintext">
              После перевода, подтвердите оплату, прикрепив скриншот перевода и
              введите номер заявки
            </div>
          </div>
          <div class="mt-3 adding-form__foot">
            <div class="adding-form__btns">
              <div class="mt-1 adding-form__btn">
                <input
                  class="adding-form__number"
                  type="text"
                  placeholder="Номер заявки"
                  v-model="requestNum"
                  @input="filterInput"
                />
                <button
                  class="adding-form-btn media-none hover-btn"
                  @click="this.send"
                >
                  {{ this.btnText }}
                </button>
              </div>
              <div
                class="col-12 col-md-auto text-danger d-flex align-items-center mt-1"
              >
                <div class="error-text" v-if="this.errorText">
                  {{ this.errorText }}
                </div>
              </div>
            </div>
            <div class="adding-form__screen">
              <div class="adding-form__add">
                <label for="addscreen" class="adding-form__label">
                  <img src="@/assets/img/add-screen.svg" alt="" />
                  <p class="adding-form__descr">Прикрепите скриншот</p>
                  <input
                    @change="uploadPhoto"
                    id="addscreen"
                    type="file"
                    style="display: none"
                  />
                </label>
              </div>
            </div>
           <div class="adding-form__images">
            <div v-if="imageUrl != ''" class="adding-form__examp users-img" @click="openImages">
              <img :src="imageUrl" alt="" />
              <button @click.stop="clearImages">✖</button>
            </div>
            <div class="adding-form__examp examp-img" @click="openImages">
              <img src="@/assets/img/examp.jpg" alt="" />
              <p>Пример</p>
            </div>
           </div>
            <div class="adding-form__btns media-block">
              <div class="mt-1 adding-form__btn">
                <button class="adding-form-btn hover-btn" @click="this.send">
                  {{ this.btnText }}
                </button>
              </div>
              <div
                class="col-12 col-md-auto text-danger d-flex align-items-center mt-1"
              >
                <div class="error-text" v-if="this.errorText">
                  {{ this.errorText }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition name="fade">
      <div class="example" v-if="exampleImg" @click="exampleImg = false">
        <div class="example-images" @click.stop>
          <img
          v-if="usersImages"
            :src="imageUrl"
            alt=""
            class="example-images__img"
          />
          <img
          v-if="defImages"
            src="@/assets/img/examp.jpg"
            alt=""
            class="example-images__img"
          />
          <button class="example-images__close" @click="exampleImg = false">X</button>
        </div>
      </div>
    </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
 .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.example {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  z-index: 9999;
  &-images {
    width: 300px;
    height: 500px;
    position: relative;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__close {
    position: absolute;
    top: -45px;
    right: 3px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 9px;
    cursor: pointer;
  }
  }
}
.media-none {
  @media (max-width: 768px) {
    display: none;
  }
}
.media-block {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
.adding-form {
  &__images{
    display: flex;
    gap: 25px;
  }
  &__examp {
    max-width: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 230px;
    cursor: pointer;
    & button{
      text-align: start;
    }
    @media (max-width: 768px) {
      text-align: center;
    }
    & img {
    height: 100%;
    object-fit: cover;
      @media (max-width: 768px) {
        max-width: 300px;
        width: 100%;
      }
    }
    & p {
      margin-bottom: 0;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 16px;
      color: #525665;
      margin-top: 10px;
    }
  }
  &__foot {
    display: flex;
    gap: 25px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
  }
  &__number {
    padding: 15px 20px;
    max-width: 270px;
    width: 100%;
    height: 50px;
    border: 1px solid #cdcdcd;
    border-radius: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
    @media (max-width: 768px) {
      max-width: none;
    }
    &::placeholder {
      font-size: 16px;
      color: #bdc1d1;
    }
  }
  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__descr {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    color: #525665;
    width: 170px;
    margin-bottom: 0;
  }
}
.adding-form__copy {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 16px;
  color: #525665;
}
.adding-from {
  &__scrintext {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    color: #525665;
  }
  &__prostocash {
    & p {
      padding: 20px 28px;
      background: #312b45;
      border-radius: 10px;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 14px;
      color: #bdc1d1;
      margin-bottom: 0;
      & a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

.form-block {
  border: 1px solid #d9d9d9;
  border-radius: 23px;
  padding: 15px;
}

.icon-block img {
  max-width: 30px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.equally {
  font-size: 28px;
}

.adding-form-btn {
  background-color: #39354b;
  border-radius: 12px;
  color: #ffffff;
  padding: 15px 20px;
  max-width: 270px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: none;
  }
}

.error-text {
  border-radius: 5px;
  border: 1px solid red;
  padding: 5px 8px;
}
.input-group{
  position: relative;
}
.input-group__info{
  display: none;
  position: absolute;
  top: -18px;
  font-size: 13px;
  left: 0;
  color: red;
  font-weight: 700;
}
.input-group .is-invalid ~ .input-group__info{
  display: block;
}
</style>
