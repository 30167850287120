<script>
export default {
    name:'UserIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:23, h:23}
        }
    }
}
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w - 1) + ' ' + (this.size.h - 1)" fill="none">
        <path d="M4.18007 13.7886C2.92249 14.5257 -0.374787 16.0308 1.63348 17.9142C2.6145 18.8342 3.7071 19.4922 5.08077 19.4922H12.9192C14.2929 19.4922 15.3855 18.8342 16.3665 17.9142C18.3748 16.0308 15.0775 14.5257 13.8199 13.7886C10.8709 12.0601 7.12906 12.0601 4.18007 13.7886Z" :stroke="this.color"stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 5.49219C13 7.70133 11.2091 9.49219 9 9.49219C6.79086 9.49219 5 7.70133 5 5.49219C5 3.28305 6.79086 1.49219 9 1.49219C11.2091 1.49219 13 3.28305 13 5.49219Z" :stroke="this.color" stroke-width="1.5"/>
    </svg>
</template>
