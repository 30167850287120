<script setup>
import GradientButton from '@/components/promo/GradientButton.vue';

import { Modal } from 'bootstrap';
import { onMounted, ref, watch } from 'vue';

const emit = defineEmits(['hide']);

const submitModal = ref(null);

onMounted(() => {
    submitModal.value = new Modal(submitModal.value);
    submitModal.value.show();
});

const onHideModal = () => {
    emit('hide');
};
</script>

<template>
    <div
        class="form-submit-modal__wrapper modal fade"
        aria-colspan=""
        ref="submitModal"
        tabindex="-1"
        v-on:[`hide.bs.modal`]="onHideModal"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header justify-content-end">
                    <GradientButton
                        class="close_button"
                        data-bs-dismiss="modal"
                        ><svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="7.69238"
                                y="6.80469"
                                width="1.25523"
                                height="9.62343"
                                rx="0.627615"
                                transform="rotate(135 7.69238 6.80469)"
                                fill="white"
                            />
                            <rect
                                x="0.887573"
                                y="7.69238"
                                width="1.25523"
                                height="9.62343"
                                rx="0.627615"
                                transform="rotate(-135 0.887573 7.69238)"
                                fill="white"
                            />
                        </svg>
                    </GradientButton>
                </div>
                <div class="modal-body">
                    <div class="form-submit-modal__body">
                        <div>
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.form-submit-modal__wrapper {
    .modal-dialog {
        .modal-content {
            background-color: #131326;
            border-radius: 50px;
            $modal-inner-padding: 100px; //не работаеть

            .modal-header {
                border: none;

                .close_button {
                    margin-top: 10px;                    
                    margin-right: 10px;
                    margin-left: auto;
                    width: 40px;
                    height: 40px;

                    > button {
                        line-height: 100%;

                        > svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }

            .modal-body {
                .form-submit-modal__body {
                    padding-inline: 40px;
                    margin-bottom: 60px;                    
                }
            }
        }
    }
}
</style>
