<script>
export default {
    name:'CityIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:20, h:19}
        }
    }
}
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w - 4) + ' ' + (this.size.h - 3)" fill="none">
        <path d="M2.2002 15.0002V8.0002C2.2002 6.68026 2.2002 6.0203 2.61025 5.61025C3.0203 5.2002 3.68026 5.2002 5.0002 5.2002C6.32013 5.2002 6.98009 5.2002 7.39014 5.61025C7.8002 6.0203 7.8002 6.68026 7.8002 8.0002" :stroke="this.color" stroke-width="1.5"/>
        <path d="M12 15V10.8C12 9.48007 12 8.8201 11.5899 8.41005C11.1799 8 10.5199 8 9.2 8H7.8C6.48007 8 5.8201 8 5.41005 8.41005C5 8.8201 5 9.48007 5 10.8V15" :stroke="this.color" stroke-width="1.5"/>
        <path d="M14.8002 14.9996V5.04003C14.8002 4.10144 14.8002 3.63215 14.5509 3.2724C14.3016 2.91265 13.8622 2.74787 12.9833 2.41831C11.2647 1.77381 10.4053 1.45156 9.80276 1.86914C9.2002 2.28672 9.2002 3.20449 9.2002 5.04003V7.99963" :stroke="this.color" stroke-width="1.5"/>
        <path d="M2.90039 5.2V4.15C2.90039 3.49003 2.90039 3.16005 3.10542 2.95503C3.31044 2.75 3.64042 2.75 4.30039 2.75H5.70039C6.36036 2.75 6.69034 2.75 6.89537 2.95503C7.10039 3.16005 7.10039 3.49003 7.10039 4.15V5.2" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M5 2.4V1" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M15.5 15L1.5 15" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M7.09961 10.0996H9.89961" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M7.09961 12.2002H9.89961" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
</template>
