import axios from "axios";
export default  {
    methods:{
        api_setAuth(username, auth_key){
            this.api_auth = '&auth=' + username + ':' + auth_key
        },

        api_getCountries(username, auth_key, params = '') {

            this.api_log('getCountries')

            this.api_log( username)

            this.api_setAuth(username, auth_key)
            this.api_route = 'api/get-countries'

            this.api_sendGet(params)
            return this.api_response
        },

        api_updateCountry(username, auth_key, params = {}){

            this.api_log('updateCountry')

            this.api_setAuth(username, auth_key)
            this.api_route = 'api/update-country'
            return this.api_sendPost(params)

        },

        api_sendGet(params){
             axios
                .get(this.api_baseUrl + this.api_route + this.api_auth + params)
                    .then((response) => {
                        this.api_log(response.data)
                        return response.data;
                    }).catch((error) => {
                        this.api_log(error)
                        return false;
                    })

        },

        api_sendPost(params){
             axios
                .post(this.api_baseUrl + this.api_route + this.api_auth, params)
                    .then((response) => {
                        this.api_log(response.data)
                        return response.data;
                    }).catch((error) => {
                        this.api_log(error)
                        return false;
                    })

        },


        api_log(msg) {

            if (this.api_debug) {
                console.log('API: ', msg)
            }
        }
    },
    data(){
        return{
            apiDomine: this.$store.getters.getApiDomine,
            api_debug: true,
            api_baseUrl: this.apiDomine + '/web/index.php?r=',
            api_auth: false,
            api_route: false,
            api_response: false,
        }
    }
}