<script setup>
const props = defineProps({
    errorText: {
        type: String,
        default: 'Произошла оишбка при оформлении заказа',
        required: false,
    },
});
const emit = defineEmits(['close-error']);
</script>

<template>
    <div class="order-error ps-4 pe-4 pb-3 pt-2">
        <div class="order-error__question mb-3">
            <p class="text-center">{{ errorText }}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-error {
    font-size: 16px;
    line-height: 24px;

    .order-error__question {
        font-weight: bold;
        color: #DF2012;
    }
}
</style>
