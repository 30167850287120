<template>
	<div class="container">
		<div class="quiz">

			<!-- step 1 1% Цвет волос-->
			<div class="quiz__block" v-if="quizStep === 1">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
									<div class="col-auto">Заказ</div>
									<div class="col-auto quiz-info__block--gray">Заполнен на 1 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Цвет волос</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step1" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizGirlsStep1"
												:value="item.text"
												v-model="quizGirlsStep1"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button disabled>Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(2)">Далее</button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<!-- step 2 8% Рост-->
			<div class="quiz__block" v-else-if="quizStep === 2">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 8 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Рост</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step2" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizGirlsStep2"
												:value="item.text"
												v-model="quizGirlsStep2"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(1)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(3)">Далее</button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<!-- step 3 15% Возраст-->
			<div class="quiz__block" v-else-if="quizStep === 3">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 pe-4 col-xl-3 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 15 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Возраст</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step3" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizGirlsStep3"
												:value="item.text"
												v-model="quizGirlsStep3"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(2)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(4)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 4 22% Размер груди-->
			<div class="quiz__block" v-else-if="quizStep === 4">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 22 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Размер груди</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step4" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizGirlsStep4"
												:value="item.text"
												v-model="quizGirlsStep4"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(3)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(5)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 5 29% Секс-->
			<div class="quiz__block" v-else-if="quizStep === 5">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 29 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Секс</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12 col-md-6" v-for="(item, i) in step5" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizGirlsStep5"
												:value="item.text"
												v-model="quizGirlsStep5"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
										
									</div>
									<!-- <p v-for="(item,i) in quizGirlsStep5" :key="i">{{ item }}</p> -->
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(4)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(6)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 6 36% Дополнительно-->
			<div class="quiz__block" v-else-if="quizStep === 6">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 36 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Дополнительно</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12 col-md-6" v-for="(item, i) in step6" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizGirlsStep6"
												:value="item.text"
												v-model="quizGirlsStep6"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
									<div class="col-12 col-md-6">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizGirlsStep6"
												value="Не интересует"
												:checked="quizGirlsStep6.length ? false : true"
												@input="quizGirlsStep6 = []"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">Не интересует</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(5)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(7)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 7 43% BDSM-->
			<div class="quiz__block" v-else-if="quizStep === 7">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 43 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">BDSM</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step7" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizGirlsStep7"
												:value="item.text"
												v-model="quizGirlsStep7"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>

									<div class="col-12">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizGirlsStep7"
												value="Не интересует"
												:checked="quizGirlsStep7.length ? false : true"
												@input="quizGirlsStep7 = []"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">Не интересует</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(6)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(8)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 8 50% Массаж-->
			<div class="quiz__block" v-else-if="quizStep === 8">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 50 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Массаж</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">

									<div class="col-12" v-for="(item, i) in step8" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizGirlsStep8"
												:value="item.text"
												v-model="quizGirlsStep8"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>

									<div class="col-12">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizGirlsStep8"
												value="Не интересует"
												:checked="quizGirlsStep8.length ? false : true"
												@input="quizGirlsStep8 = []"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">Не интересует</span>
										</label>
									</div>

								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(7)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(9)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 9 57% Время-->
			<div class="quiz__block" v-else-if="quizStep === 9">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 57 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Время</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step9" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizGirlsStep9"
												:value="item.text"
												v-model="quizGirlsStep9"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(8)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(10)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 10 64% Дата-->
			<div class="quiz__block" v-else-if="quizStep === 10">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 64 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							
							</div>
						</div>
					</div>
					
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Когда желаете встретиться</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Дата</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step10" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizGirlsStep10"
												:value="item.text"
												v-model="quizGirlsStep10"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(9)">Назад</button>
									</div>
									<div v-if="quizGirlsStep10 === 'Выберу день в календаре'" class="col-auto quiz__btn">
										<button @click="quizNavStep(101)">Далее</button>
									</div>
									<div v-else class="col-auto quiz__btn">
										<button @click="quizNavStep(11)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 10.1 70% календарь-->
			<div class="quiz__block" v-else-if="quizStep === 101">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12  mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 70 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Когда желаете встретиться</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Дата</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12">
										<div class="quiz-data">
											<VueDatePicker v-model="date" range multi-calendars inline locale="ru" cancelText="Выбрать"  selectText="Выбрать" :format="format"/>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(10)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(11)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 11 75% локация-->
			<div class="quiz__block" v-else-if="quizStep === 11">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 75 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Место встречи</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Место встречи</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step11" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizGirlsStep11"
												:value="item.text"
												v-model="quizGirlsStep11"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(10)">Назад</button>
									</div>
									<div v-if="quizGirlsStep11 === 'Выезд'" class="col-auto quiz__btn">
										<button @click="quizNavStep(111)">Далее</button>
									</div>
									<div v-else class="col-auto quiz__btn">
										<button @click="quizNavStep(12)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 11.1 80% карта-->
			<div class="quiz__block" v-else-if="quizStep === 111">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 80 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Место встречи</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Место встречи</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" >
										<label class="quiz-content-location__input d-blcok w-100 mb-3">
											<input type="text" v-model="quizGirlsStep103" placeholder="quizGirlsStep103" class="w-100">
										</label>
										<div class="quiz-content-location__map">
											<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A1ba7a1880ded2b91d40f20256f684e416e45f2c521da9ee1d96e661f1a082538&amp;source=constructor" width="100%" height="auto" frameborder="0"></iframe>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(11)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(12)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 12 85% цена-->
			<div class="quiz__block" v-else-if="quizStep === 12">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 85 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете стоимость</div>
							</div>
							
							<div class="col-12 quiz-radio" >
								<div class="row">
									<div class="col-12 col-md-6 mb-4 mb-lg-2">
										<div class="quiz-content__subtitle">RUB</div>
										<div class="row">
											<div class="col-12" v-for="(item, i) in step12Rub" :key="i">
												<label class="quiz-radio__label" >
													<input
														type="radio"
														name="quizGirlsStep12"
														:value="item.text"
														v-model="quizGirlsStep12"
														class="quiz-radio__input">
													<span class="quiz-radio__checked"></span>
													<span class="quiz-radio__name">{{ item.text }}</span>
												</label>
											</div>
										</div>
									</div>

									<div class="col-12 col-md-6">
										<div class="quiz-content__subtitle">USD</div>
										<div class="row">
											<div class="col-12" v-for="(item, i) in step12Usd" :key="i">
												<label class="quiz-radio__label" >
													<input
														type="radio"
														name="quizGirlsStep12"
														:value="item.text"
														v-model="quizGirlsStep12"
														class="quiz-radio__input">
													<span class="quiz-radio__checked"></span>
													<span class="quiz-radio__name">{{ item.text }}</span>
												</label>
											</div>
										</div>
									</div>
									
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(11)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(13)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 13 92% описание-->
			<div class="quiz__block" v-else-if="quizStep === 13">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 92 %</div>
									</div>
								</div>
							</div>
							<div class="col-12 quiz-info__block">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>

							<div class="col-12 quiz-textarea mb-3 mb-lg-5">
								<div class="row">
									<div class="col-12">
										<textarea name="" id="" v-model="quizGirlsStep13" placeholder="Оставьте пожелания к заказу"></textarea>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(12)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(14)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 14 99% телефон-->
			<div class="quiz__block" v-else-if="quizStep === 14">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 99 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Заказ почти создан</div>
							</div>

							<div class="col-12 quiz-textarea mb-3 mb-lg-5">
								<div class="row">
									<div class="quiz-phone">
										<label class="quiz-phone__label">
											<input type="text"  v-on:keyup="setTel" v-model="quizGirlsStep14" class="quiz-phone__input col-12 mb-2" placeholder="+ 7 123 123 12-12">
											<span class="col-12">Номер не виден  в заказе. Вы сами решите, кому его&nbsp;показать</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-7 quiz-big__btn">
									<button class="d-block w-100" @click="quizNavStep(15)">Создать заказ</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step finish -->
			<div class="quiz__block" v-else-if="quizStep === 15">
				<div class="row">
					<div class="col-12 quiz__title">Ваш заказ</div>
					<div class="col-12 col-lg-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">7 просмотров</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-feedback text-center d-flex flex-column">
									<div class="quiz-feedback__img mt-auto">
										<img src="@/assets/img/quiz/item1.svg" alt="svg">
									</div>
									<div class="quiz-feedback__text mt-3 mb-5">Отклики появятся здесь</div>
								</div>
							</div>
							<div class="col-12 mb-4">
								<div class="quiz-wait d-flex align-items-center justify-content-center">
									<div class="quiz-wait__img me-3 mb-1"><img src="@/assets/img/quiz/item2.svg" alt="svg"></div>
									<div class="quiz-wait__text">Отправляем заказ 11 моделям</div>
								</div>
							</div>
							<div class="col-12 mb-4">
								<div class="quiz-wait d-flex align-items-center justify-content-center">
									<div class="quiz-wait__img me-3 mt-3"><img src="@/assets/img/quiz/item3.svg" alt="svg"></div>
									<div class="quiz-wait__text">Ждем откликов моделей</div>
								</div>
							</div>
							<div class="col-12">
								<!-- <div class="quiz-slider__girls quiz-slider">
									<swiper
										:slidesPerView="1"
										:navigation="swiperOptions.navigations"
										:modules="modules"
										:loop="true"
										:simulateTouch="true"
										:watchSlidesProgress="true"
										:mousewheel="false"
										:spaceBetween="1"
										:grabCursor="false"
										:touchRatio="1"
										:slideToClickedSlide="true"
										class="quiz-slider__block"
									>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<div class="quiz-slider__name">
											<span>Далее</span>
											<span>Анфиса</span>
										</div>
									</swiper>
									

								</div> -->

							</div>
							<div class="col-12 col-md-6 col-lg-12 mx-auto quiz-big__btn my-4">
								<button class="w-100" @click="$router.push('/quiz-girls/order')">Обсудить детали</button>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 quiz-content quiz-order">
						<div class="row quiz-order__choice">
							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">
									<div class="col-12 quiz-order__title">Параметры девушки</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title" >Цвет волос</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep1">{{ quizGirlsStep1 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Рост</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep2">{{ quizGirlsStep2 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Возраст</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep3">{{ quizGirlsStep3 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Размер груди</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep4">{{ quizGirlsStep4 }}</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">
									<div class="col-12 quiz-order__title">Выбранные услуги</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Секс</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep5">
											<span v-for="(item, i) in quizGirlsStep5"
										 :key="i">{{ item }}{{ quizGirlsStep5.length === (i+1) ? '' : ', '}}</span>
										</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Дополнительно</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep6.length">
											<span 
												v-for="(item, i) in quizGirlsStep6"
										 		:key="i"
											>{{ item }}{{ quizGirlsStep6.length === (i+1) ? '' : ', '}}
											</span>
										</div>
										<div class="quiz-order__item-text" v-else>
											<span>Не&nbsp;интересует</span>
										</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">БДСМ</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep7.length">
											<span 
												v-for="(item, i) in quizGirlsStep7"
										 		:key="i"
											>{{ item }}{{ quizGirlsStep7.length === (i+1) ? '' : ', '}}
											</span>
										</div>
										<div class="quiz-order__item-text" v-else>
											<span>Не&nbsp;интересует</span>
										</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Массаж</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep8.length">
											<span 
												v-for="(item, i) in quizGirlsStep8"
										 		:key="i"
											>{{ item }}{{ quizGirlsStep8.length === (i+1) ? '' : ', '}}
											</span>
										</div>
										<div class="quiz-order__item-text" v-else>
											<span>Не&nbsp;интересует</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row quiz-order__choice mt-5">
							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title" >Время</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep9">{{ quizGirlsStep9 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Место встречи</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep11">{{ quizGirlsStep11 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Пожелания к заказу</div>
										<div class="quiz-order__item-text" >{{ quizGirlsStep13 !== '' ? quizGirlsStep13 :  'Нет'}}</div>
									</div>

								</div>
							</div>

							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Дата</div>
										<div v-if="date.length">
											<div class="quiz-order__item-text">
												{{ (this.date[0] !== null && this.date[0] !== undefined) ? formateDate(this.date[0]) : '' }}
												{{ (this.date[1] !== null && this.date[1] !== undefined) ? ' - ' + formateDate(this.date[1]) : '' }}
											</div>
										</div>
										<div v-else>
											<div class="quiz-order__item-text">{{ quizGirlsStep10 }}</div>
										</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Стоимость</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep12">{{ quizGirlsStep12 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Номер телефона в заказе</div>
										<div class="quiz-order__item-text" v-if="quizGirlsStep14">{{ quizGirlsStep14 }}</div>
									</div>

								</div>
							</div>
						</div>
						<div class="row quiz-orde-strip mt-5">
							<div class="col-auto quiz-orde-strip__btn me-5">
								<button>Отменить заказ</button>
							</div>
							<div class="col-3 quiz-orde-strip__block">
								<div class="quiz-orde-strip__name">Номер заказа</div>
								<div class="quiz-orde-strip__text">123123123</div>
							</div>
							<div class="col-3 quiz-orde-strip__block">
								<div class="quiz-orde-strip__name">Создан</div>
								<div class="quiz-orde-strip__text">28.11.2023</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref } from 'vue';
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { FreeMode, Navigation, Thumbs, Pagination, EffectFade, Mousewheel } from 'swiper/modules';

// import 'swiper/css/free-mode';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';

// import 'swiper/swiper-bundle.css';
// import '@/styles/swiper.css'
// import 'swiper/css/effect-fade';
// import Slider from '@/components/blocks/Slider.vue';

export default {
	components: {
		// Swiper,
		// SwiperSlide,
		// Slider
	},
	data() {
		return {
			testVar: 1,
			date: [],
			quizStep: 1,
			quizGirlsStep1: 'Не имеет значения',
			quizGirlsStep2: 'Не имеет значения',
			quizGirlsStep3: 'Не имеет значения',
			quizGirlsStep4: 'Не имеет значения',
			quizGirlsStep5: ['Секс классический'],
			quizGirlsStep6: ['Стриптиз'],
			quizGirlsStep7: ['Госпожа'],
			quizGirlsStep8: ['Расслабляющий'],
			quizGirlsStep9: 'Ночь',
			quizGirlsStep10: 'Сегодня, 28 ноября',
			quizGirlsStep101: '',
			quizGirlsStep11: 'Апартаменты у девушки',
			quizGirlsStep111: 'Москва, улица Центральная, д. 123, корпус 6, стр. 8, кв. 97',
			quizGirlsStep12: 'Предложит модель',
			quizGirlsStep13: '',
			quizGirlsStep14: '+7 999 999 99 99',
			mask: '',
			calendarData: {},
			calendarConfigs: {

			},
			step1: [
				{
					text: 'Блондинка'
				},{
					text: 'Брюнетка'
				},{
					text: 'Шатенка'
				},{
					text: 'Рыжая'
				},{
					text: 'Русая'
				},{
					text: 'Не имеет значения'
				},
			],
			step2: [
				{
					text: 'Низкий - ниже среднего  (150-162 см)'
				},
				{
					text: 'Средний - выше среднего (163-173 см)'
				},
				{
					text: 'Высокий и выше (от 174 см) '
				},
				{
					text: 'Не имеет значения'
				},
			],
			step3: [
				{
					text: '18-23'
				},
				{
					text: '24-28'
				},
				{
					text: '29-35'
				},
				{
					text: '36-40'
				},
				{
					text: '41-50'
				},
				{
					text: 'Не имеет значения'
				},
			],
			step4: [
				{
					text: 1
				},
				{
					text: 2
				},
				{
					text: 3
				},
				{
					text: 4
				},
				{
					text: 5
				},
				{
					text: 'Не имеет значения'
				},
			],
			step5: [
				{text: 'Секс классический'},{text: 'Секс анальный'},{text: 'Секс групповой'},{text: 'Секс лесбийский'},{text: 'Услуги семейной паре'},{text: 'Минет в презервативе'},{text: 'Минет без презерватива'},{text: 'Минет глубокий'},{text: 'Минет в машине'},{text: 'Кунилингус'},{text: 'Игрушки'},{text: 'Окончание на грудь'},{text: 'Окончание на лицо'},{text: 'Окончание в рот'}
			],
			step6: [
				{
					text: 'Стриптиз',
				},
				{
					text: 'Лесби шоу',
				},
				{
					text: 'Страпон',
				},
				{
					text: 'Эскорт',
				},
				{
					text: 'Анилингус вам',
				},
				{
					text: 'Анилингус мне',
				},
				{
					text: 'Золотой дождь вам',
				},
				{
					text: 'Золотой дождь мне',
				},
				{
					text: 'Фистинг анальный вам',
				},
				{
					text: 'Фистинг анальный мне',
				},
				{
					text: 'Фистинг классический',
				},
				{
					text: 'Фото/Видео съёмка',
				},
				{
					text: 'Виртуальный секс',
				},
				{
					text: 'Секс по телефону',
				}
			],
			step7: [
				{text: 'Госпожа'},{text: 'Рабыня'},{text: 'Доминация'},{text: 'Бандаж'},{text: 'Порка'},{text: 'Фетиш'},{text: 'Трамплинг'},{text: 'Ролевые игры'}
			],
			step8: [
				{text: 'Расслабляющий'},{text: 'Эротический'},{text: 'Профессиональный'},{text: 'Урологический'}
			],
			step9: [
				{text: 'Один час'},{text: 'Два часа'},{text: 'Три часа'},{text: 'Ночь'},
			],
			step10: [
				{text: 'Сегодня, 28 ноября'},{text: 'Завтра, 29 ноября'},{text: 'Выберу день в календаре'},{text: 'Когда удобно модели'},
			],
			step11: [
				{text: 'Апартаменты у девушки'},{text: 'Выезд'},
			],
			step12Rub: [
				{text: '2 000 – 5 000 ₽'},{text: '5 000 – 10 000 ₽'},{text: '15 000 – 20 000 ₽'},{text: '20 000 – 30 000 ₽'},{text: '30 000 – 40 000 ₽'},{text: '50 000 – 100 000 ₽'},{text: 'Предложит модель'}
			],
			step12Usd: [
				{text: '20 – 50 $'},{text: '50 – 100 $'},{text: '150 – 200 $'},{text: '200 – 300 $'},{text: '300 – 400 $'},{text: '500 – 1000 $'}
			],
			swiperOptions: {
				navigations: true
			},

		}
	},
	methods: {
		setTel(event) {
      const val = event.target.value.replace(/[^0-9]/g, "");
			event.target.value = val
    },
		quizNavStep(step) {
			this.quizStep = step
		},
		formateDate(date) {
			let currentDate = new Date(date);
			const day = currentDate.getDate();
			const month = currentDate.getMonth() + 1;
			const year = currentDate.getFullYear();

			return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`
		}
	},
}
</script>

<style lang="scss">
.quiz {
	color: #2D2F3A;
	&__block {
		padding: 45px 0 70px;
	}
	&__title {
		font-size: 32px;
		margin-bottom: 10px;
	}
	&__btn {
		button {
			cursor: pointer;
			color: #FFF;
			text-align: center;
			padding: 10px 25px;
			border-radius: 30px;
			background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
			font-size: 16px;
			transition: 0.3s;
			&:hover {
				box-shadow: 0 0 5px rgba(#000, 0.9);
			}
			&:disabled{
				cursor: default;
				background: linear-gradient(93deg, rgba(114, 102, 106, 0.30) 0%, rgba(82, 75, 95, 0.30) 50.09%, rgba(32, 31, 54, 0.30) 99.15%);
				&:hover {
					box-shadow: none
				}
			}
		}
	}
}
.quiz-info {
	&__block {
		border-radius: 12px;
		color: #39354B;
		font-size: 16px;
		padding: 15px 20px;
		&--gray {
			color: #838383;
			font-size: 14px;
		}
		&--dark {
			background-color: #D9D9D9;
		}
		&--ligth {
			background-color: #F5F5F5;
		}
	}
}
.quiz-content {
	&__title {
		font-size: 24px;
		font-weight: 600;
		line-height: 100%;
		margin: 15px 0 25px;
	}
	&__subtitle {
		color: #FFF;
		font-size: 14px;
		font-weight: 700;
		border-radius: 9px;
		background: #8E7F7D;
		padding: 10px 25px;
		margin-bottom: 25px;
	}
}
.quiz-radio {
	margin-bottom: 35px;
	&__label {
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	&__input {
		appearance: none;
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
	&__name {
		color: #39354B;
		font-size: 16px;
		padding: 5px 0;
	}
	&__checked {
		display: block;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: #F5F5F5;
		margin-right: 20px;
		position: relative;
		flex: 0 0 18px;
	}
	&__input:checked ~ &__checked {
		&::after {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: #525665;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
.quiz-content-location {
	&__map {
		width: 100%;
	}
	&__input {
		input {
			color: #838383;
			font-size: 14px;
			padding: 10px 20px;
			border-radius: 44px;
			border: 1px solid #BDC1D1;
			background: rgba(255, 255, 255, 0.30);
		}
	}
}
.quiz-textarea {
	textarea {
		border-radius: 12px;
		background: #F5F5F5;

		color: #838383;
		font-size: 16px;
		padding: 20px;
		resize: none;
		border: none;
		width: 100%;
		height: 200px;
	}
}
.quiz-phone {
	&__label {
		span {
			color: #838383;
			font-size: 14px;
		}
	}
	&__input {
		border-radius: 12px;
		background: #F5F5F5;
		padding: 20px 50px;
	}
}
.quiz-big {
	&__btn {
		button {
			border-radius: 12px;
			background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%));
			color: #fff;
			font-size: 16px;
			padding: 15px 0;
			transition: 0.3s;
			&:hover {
				box-shadow: 0 0 5px rgba(#000, 0.9);
			}
		}

	}
}
.quiz-order {
	&__title {
		color: #2D2F3A;
		font-size: 24px;
		font-weight: 700;
		line-height: 100%;
		margin-bottom: 30px;
	}
	&__choice {
		&:first-child {
			border-bottom: solid 1px #BDC1D1;
		}
	}
	&__item {
		min-height: 40px;
		margin-bottom: 30px;
	}
	&__item-title {
		color: #2D2F3A;
		font-size: 14px;
		font-weight: 700;
	}
	&__item-text {
		color: #39354B;
		font-size: 16px;
	}
}
.quiz-orde-strip {
	&__btn {
		button {
			color: #FFF;
			text-align: center;
			font-size: 16px;
			border-radius: 30px;
			background: linear-gradient(93deg, rgba(114, 102, 106, 0.30) 0%, rgba(82, 75, 95, 0.30) 50.09%, rgba(32, 31, 54, 0.30) 99.15%);
			padding: 10px 25px;
			border: solid 1px transparent;
			transition: 0.3s;
		}
	}
	&__name {
		color: #2D2F3A;
		font-size: 14px;
		font-weight: 700;
	}
	&__text {
		color: #2D2F3A;
		font-size: 16px;
	}
}
.quiz-feedback {
	height: 200px;
	margin: 10px 0;
	&__text {
		color: #BDC1D1;
		font-size: 16px;
	}
}
.quiz-wait {
	border-radius: 24px;
	background: #F8F8F8;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
	height: 110px;
	margin: 0 auto;
	padding: 0 35px;
	&__text {
		color: #39354B;
		font-size: 16px;
		font-weight: 700;
	}
	&__img {
		flex: 0 0 35px;
		max-width: 35px;
	}
}
.quiz-slider {
	@media (max-width: 991px) {
		max-width: 300px;
		margin: 0 auto;
	}
	.swiper {
		height: auto;
		display: flex;
		flex-wrap: wrap;
	}
	.slider-offer__big .swiper-slide > div {
		height: 420px!important;
		// width: 100%!important;
		// max-width: 300px!important;
	}
	.swiper-slide {
		width: auto;
		max-width: 100%;
	}
	.slider-offer {
		max-width: 100%;
		// @media (max-width: 991px) {
		// 	max-width: 300px;
		// }
	}
	.swiper-button-prev, .swiper-button-next {
		position: static;
		margin-top: 0.5em;
	}
	.swiper-button-next {
		background-image: url('@/assets/img/quiz/arrow-slide.svg');
		background-repeat: no-repeat;
		width: 6px;
		height: 10px;
		order: 2;
		margin-left: 5px;
		margin-top: 0.55em;
	}
	.swiper-button-prev {
		background-image: url('@/assets/img/quiz/arrow-slide.svg');
		transform: rotate(180deg);
		background-repeat: no-repeat;
		width: 6px;
		height: 10px;
		order: 1;
		margin-left: 5px;
	}
	&__name {
		order: 0;
		span {
			&:first-child {
				color: #525665;
				font-size: 14px;
				margin-right: 10px;
			}
			&:last-child {
				color: #484C5E;
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
}



//--- style data picker
.quiz-data {
	.dp--tp-wrap {
		display: none;
	}
	.dp__calendar_item {
		
	}
	.dp__cell_inner {
		border-radius: 50%;
		font-size: 12px;
		padding: 10px;
		width: 25px;
		height: 25px;
		padding: 0;
	}
	.dp__flex_display {
		padding: 0;
	}
	.dp__menu, .dp__relative, .dp__theme_light {
		border: none;
		 > div {
			--dp-menu-width: 100%!important;
		 }
	}
	.dp__date_hover_end {

	}
	.dp__range_between {
		background: #dedede;
	}
	.dp__range_end, .dp__range_start, .dp__active_date {
		background: #4C4D6B;
	}
	.dp__today {
		border: solid 1px #4C4D6B;
	}
	.dp__action_button, .dp__action_select {
		background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
		color: #fff;
		transition: 0.3s;
		border: none;
		padding: 20px;
		&:hover {
			background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
			box-shadow: 0 0 5px rgba(#fff, 0.9) inset;
		}
	}
	.dp__calendar_header_item {
		font-size: 14px;
		font-weight: 500;
	}
	.dp__month_year_select, .dp__month_year_select {
		font-size: 14px;
		width: auto;
	}
	.dp__month_year_wrap {
		justify-content: center;
	}
	
}
</style>