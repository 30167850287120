<script>
import axios from "axios";

export default {
    name: 'TagsToProfile',
    emits: ["selectTags"],
    props: {
        items: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            user: this.$store.getters.getUser,
            tags: [],
            maxSelect: 7,
            loaded: false,
            selectedTags: [],
        }
    },
    methods: {
        getAllTags() {
            axios
                .get(this.apiUrl + 'api-tag/get-tags&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.tags = response.data
                })
        },
        clickTag(id) {

            let tagItem = document.getElementById('tagItem_' + id)
            // console.log(tagItem);
            if (tagItem.classList.contains('selected')) {
                tagItem.classList.remove('selected')

                for (var i = 0; i < this.selectedTags.length; i++){
                    if (this.selectedTags[i] === id){
                        this.selectedTags.splice(i,1)
                    }
                }

            } else {
                if(this.maxSelect === this.selectedTags.length){
                    let tagItem = document.getElementById('tagItem_' + id)
                    tagItem.classList.add('selected-error')
                    document.getElementById('selectedText').style.color= '#ff0000';
                    setTimeout(()=>{
                        let tagItem = document.getElementById('tagItem_' + id)
                        tagItem.classList.remove('selected-error')
                        document.getElementById('selectedText').style.color= 'inherit';
                    }, 2000)
                    return
                }
                tagItem.classList.add('selected')
                this.selectedTags.push(id)
            }

            this.$emit('selectTags', this.selectedTags);
        }
    },
    beforeMount() {},
    mounted() {
        this.getAllTags()
    },
    watch: {
        tags: function () {
            if (this.tags.length > 0) {
                this.loaded = true
            }
        },
        items: function(data) {
            if (data.length) {
                this.selectedTags = data
            }
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <h3>Выберите {{ maxSelect }} тегов, подходящих к вашей анкете (<span id="selectedText">Выбрано {{ selectedTags.length }}/{{ maxSelect }}</span>)</h3>
        </div>
    </div>
    <div class="row mt-3 mt-md-5">
        <div class="col-auto p-1" v-for="(item, i) in tags" :key="i">
            <div class="tag-item p-2" :class="{'selected': selectedTags.includes(item.id)}" :id="'tagItem_'+item.id" @click="clickTag(item.id)">
                <img :src="apiDomine + '/web/uploads/' + item.pic" alt="" class="tag-img">
                <span class="p-3">{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.tag-item {
    background-color: #2D2F3A;
    border-radius: 1.5rem;
    color: #fff;
    cursor: pointer;
    transition: all 1s ease;
}

.tag-img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.tag-item.selected {
    background: linear-gradient(45deg, #0a7308, #020000);
    opacity: .6;
}
.tag-item.selected::after {
    /* content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgOC44MzMzM0MxIDguODMzMzMgMi44NzUgOC44MzMzMyA0Ljc1IDEyLjVDNC43NSAxMi41IDEwLjcwNTkgMy4zMzMzMyAxNiAxLjUiIHN0cm9rZT0iIzIyQkMzMiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==); */
}
.tag-item.selected-error {
    background: linear-gradient(45deg, #ff0000, #020000);
}
#selectedText{
    transition: all 1s;
}

</style>