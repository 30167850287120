<script>

import { Swiper, SwiperSlide } from "swiper/vue";
import Slider from "@/components/blocks/Slider.vue";
import axios from "axios";
import UiLoader from "@/components/ui/UiLoader.vue";
import SliderInCategory from "@/components/blocks/SliderInCategory.vue";
import VideoCards from "@/components/blocks/VideoCards.vue";
import sklonyator from 'sklonyator';
import VideoWidget from '@/components/ui/VideoWidget.vue';

export default {
    name: 'GirlListCategory',
    components: {
        VideoCards,
        SliderInCategory,
        UiLoader,
        Swiper,
        SwiperSlide,
        Slider,
        sklonyator,
        VideoWidget
    },
    props: {
        category: {
            type: String,
            default: false
        },
        categoryName: {
            type: String,
            default: false
        }
    },
    methods: {
        async updateData() {
            let params = '&auth=' + this.user.username + ':' + this.user.auth_key;

            if (this.category) {
                params += '&category_id=' + this.category
            }
            if (this.profile.offset) {
                params += '&offset=' + this.profile.offset
            }
            if (this.profile.limit) {
                params += '&limit=' + this.profile.limit
            }

            params += '&status_id=6'
            params += `&city_id=${this.user.city?.id || 1}`
            this.showLoader = true

            try {
                let result = []
                const {data: topProfiles} = await axios.get(this.apiUrl + 'api-ad/get-ad-category-top'  + params)
                
                result = result.concat(topProfiles)

                const {data: otherProfiles} = await axios.get(this.apiUrl + 'api-girl/get-profiles-list'  + params)
                result = result.concat(otherProfiles)

                const chunkSize = 20;
                for (let i = 0; i < result.length; i += chunkSize) {
                    this.pages.push(result.slice(i, i + chunkSize))
                }

                this.profiles = this.pages[0]

                if (this.profiles.count) {
                    delete this.profiles.count
                }

                this.cityName = sklonyator.incline(this.user.city.name);
                window.scroll(0, 0)

            } catch (error) {
                console.log('updateData: ', error)
            } finally {
                this.showLoader = false
            }

            // axios
            //     .get(this.apiUrl + 'api-girl/get-profiles-list' + params)
            //     .then((response) => {
            //         console.log('response', response)

            //         const chunkSize = 20;
            //         for (let i = 0; i < response.data.length; i += chunkSize) {
            //             this.pages.push(response.data.slice(i, i + chunkSize));
            //         }

            //         this.profiles = this.pages[0]
            //         this.profileCount = this.profiles.count;
            //         delete this.profiles.count
            //         this.cityName = sklonyator.incline(this.user.city.name);
            //         this.showLoader = false
            //         window.scroll(0, 0)
            //     }).catch(error => console.log(error))

        },

        displayNext() {
            this.profiles = this.profiles.concat(this.pages[this.lastView + 1])

            this.lastView++;

            let next = this.lastView + 1;
            if (!this.pages[next]) {

                this.updateData()
            }
        },

        showPage(page) {
            this.profile.offset = page * 20 - 20
            this.currentPage = page
            this.updateData();
        }
    },
    mounted() {
        this.updateData()
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            profiles: [],
            showLoader: false,
            btnText: 'Показать ещё',
            showBtn: true,
            pages: [],
            lastView: 0,
            cityName: '',
            profile: {
                category_id: '',
                offset: '',
                limit: 100,
            },
            profileCount: 0,
            allPage: 0,
            currentPage: 1,
            swiperOptions: {
                breakpoints: { 374: { slidesPerView: 2 }, 991: { slidesPerView: 2 }, 1240: { slidesPerView: 4 } },
                navigations: true
            },
            swiperOptionsSelect: {
                breakpoints: { 767: { slidesPerView: 3 }, 1240: { slidesPerView: 5 } },
            },
            swiperOrderOptions: {
                breakpoints: { 320: { slidesPerView: 1 }, 575: { slidesPerView: 2 } },
                navigations: true
            }
        }

    },
    computed: {
        userCity() {
            console.log('user-city: ', this.$store.getters.getCity)
            return this.$store.getters.getCity
        },
    },
    watch: {
        userCity: function () {
            this.showBtn = true
            this.lastView = 0
            this.pages = []
            this.updateData()
        }
    }
}
</script>

<template>
    <div class="block-offer">
        <div class="container">
            <div class="block-offer__top">
                <div class="title block-offer__title">Видеовизитки {{ this.categoryName }} <span v-if="this.user.city">
                        в {{ this.cityName }}</span></div>
                <div class="block-offer__strip"></div>
                <div class="block-offer__row"></div>
            </div>
            <video-cards page="category" :id="this.category" />
            <ui-loader v-if="this.showLoader" :show="this.showLoader" :preset="'block'" />
            <div class="slider-offer mt-5" v-else>
                <div class="container">
                    <div class="block-offer__top">
                        <div class="title block-offer__title">
                            <span class="string-capitalize"> {{ this.categoryName }}</span>
                            <span v-if="this.user.city"> в {{ this.cityName }}</span>
                        </div>
                        <div class="block-offer__strip"></div>
                        <div class="block-offer__row"></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-3" v-for="p in this.profiles" :key="p.id">
                            <Slider :profile="p" :category-page="false" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 justify-content-center" v-if="showBtn">
                <div class="col-auto">
                    <button class="display-next hover-btn" @click="this.displayNext">{{ this.btnText }}</button>
                </div>
            </div>
        </div>

        <video-widget page="category" :id="category" />
    </div>
</template>

<style scoped lang="scss">
@import '@/styles/index';

.block-offer {
    padding: 3.5rem 0;

    @media (max-width: 991px) {
        padding: 1.5rem 0;
    }

    .slider-offer {
        max-width: 100%;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 50px;
        gap: 10px;
    }

    &__row {
        color: $text;
        text-align: right;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        text-align: right;

        @media ($tablet) {
            font-size: 16px;
        }

        @media ($phoneS) {
            font-size: 10px;
        }
    }

    &__strip {
        display: block;
        height: 1px;
        flex-grow: 1;
        background-color: rgba(189, 193, 209, 0.31);
    }
}

.block-offer__title .string-capitalize {
    text-transform: capitalize;
}

.button-pagination {
    background: #39354B;
    padding: 5px 10px;
    color: #fff;
    border: none;
    border-radius: 19px;
    margin-left: 15px;
    cursor: pointer;
}

.button-pagination:hover {
    background: #39354B;
}


.show-page {
    color: #39354B;
    width: 30px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
}

.show-page:hover {
    background: #DCE3EC;
    border-radius: 50%;
    color: #39354B;
}

.show-page.active {
    background: #DCE3EC;
    border-radius: 50%;
    color: #39354B;
}

.title-cat {
    color: #2D2F3A;
    font-size: 28px;
}

button.display-next {
    background-color: #72666A;
    color: #FFFFFF;
    border-radius: 25px;
    padding: 8px 22px;
    font-size: 16px;
    font-weight: 500;
}

button.display-next:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}
</style>