<script>
import { computed } from 'vue';
import axios from 'axios';
import telegramInputs  from "@/mixins/posttg";
export default {
  props: ['index', 'post', 'showAll', 'girlAnketa'],
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
        apiDomine: this.$store.getters.getApiDomine,
      isAnswer: null,
      replyComment: '',
      girlId: this.$route.params.id,
      scrollActive: false,
      isSending: false
    };
  },
  mixins: [telegramInputs],
  computed: {
    getCommentById() {
      return this.post.comments.filter(
        (obj) => obj.author_id == this.user.user_id
      );
    },
  },

  methods: {
    usk(text) {
      try {
        return decodeURIComponent(escape(atob(text)));
      } catch (error) {
        return;
      }
    },
    formattedDate(time) {
      const date = new Date(time * 1000);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      day = day < 10 ? '0' + day : day;
      year = year < 10 ? '0' + year : year;
      month = month < 10 ? '0' + month : month;

      return `${day}.${month}.${year}`;
    },
    formattedTime(time) {
      const date = new Date(time * 1000);
      let hours = date.getHours();
      let min = date.getMinutes();
      hours = hours < 10 ? '0' + hours : hours;
      min = min < 10 ? '0' + min : min;
      return `${hours}:${min}`;
    },
    dropInput() {
      const area = document.querySelector('.chatitem-drop__inputs');
      area.style.height = '49px';
      area.style.height = `${area.scrollHeight}px`;
    },
    answerActive(index) {
      if (this.isAnswer == index) {
        this.isAnswer = null;
      } else {
        this.isAnswer = index;
        this.$nextTick(() => {
          const crollContainer = document.getElementById('crollContainer');
          crollContainer.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        });
      }
    },
    async sendReply(el, index) {
      if (this.isSending) {
        return
      }
      this.isSending = true
      try {
        const res = await axios.post(
          this.apiUrl +
            'api-profile-post/set-comment' +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          {
            post_id: this.post.id,
            author_id: this.user.user_id,
            reply_id: el.id,
            text: this.replyComment,
          }
        );
        this.$store.dispatch('getPosts', this.girlId);
        this.replyComment = '';
        this.isAnswer = null;
        this.scrollActive = false;
        this.getusersTg()
       
      } catch (error) {
        console.log('Произошла ошибка при добавлении set-comment', error);
      }
      finally {
        this.isSending = false
      }
    },
    async getusersTg() {
      try {
        const res = await axios.get(
          `${this.apiUrl}api-user/get-user-settings&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.girlAnketa.user_id}`
        );
        const data = res.data.data;
        data.find((el) => {
          if (el.name == 'mess' && el.value == 'true') {
            if (this.girlAnketa.user_id != this.user.user_id)  {
              this.telegramInputs(
                this.girlAnketa.user_id,
                'Вы получили новый комментарий в ленте My Life'
              );
            }
          }
        });
      } catch (error) {
        console.log('inputs.vue tg', error);
      }
    },
    scrollToBottom() {
      const el = document.querySelector('.chats');
      this.$nextTick(() => {
        el.scrollTop = el.scrollHeight;
      });
    },
    closeDropitemOutside(event) {
      const dropitem = document.getElementById('crollContainer');
      // console.log(event.target.className);
      if (event.target.className == 'chatitem-drop dropitem' ||event.target.className == 'chatitem' || event.target.className == 'block-reply'  || event.target.className == 'chatitem-body') {
        dropitem?.classList.add('closeinp')
      }else{
        dropitem?.classList.remove('closeinp')
      }
  },
  },
  computed: {
    arrayReverse() {
      const qwe = this.post.comments;
      return qwe.map((item) => {
        return {
          id: item.id,
          profile_post_id: item.profile_post_id,
          author_id: item.author_id,
          reply_id: item.reply_id,
          text: item.text,
          created_at: item.created_at,
          reply: item.reply.slice().reverse(),
          author: {
            avatar: item.author.avatar,
            id: item.author.id,
            name: item.author.name,
            user_id: item.author.user_id,
            whatsapp: item.author.whatsapp,
          },
        };
      });
    },
    arrayReplyReverse() {
      const arrayR = this.arrayReverse;
      const elements = [];
      arrayR.forEach((item) => {
        item.reply.forEach((el) => {
          elements.push(el);
        });
      });
      return elements;
    },
  },
  watch: {
    'post.comments': {
      handler(newVal, oldVal) {
        if (this.scrollActive) {
          if (newVal.length != oldVal.length) {
            this.scrollToBottom();
          }
        } else {
          this.scrollActive = true;
        }
      },
      deep: true,
    },
  },
  

  mounted() {
   
    document.addEventListener('click', this.closeDropitemOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropitemOutside);
  },
};
</script>

<template>
  <div class="chats" ref="postContainer">
    <div
      class=""
      v-for="(el, index) in post.comments"
      :key="el.id"
      v-show="showAll || index == 0"
    >
      <div class="chatitem">
        <div
          class="chatitem-body"
          :class="{
            send: el.author_id == this.user.user_id,
            get: el.author_id != this.user.user_id,
            girlpost: girlAnketa?.user_id == el.author.user_id,
            userColor: el.author.role.item_name == 'user',
            girlColor: el.author.role.item_name == 'girl',
          }"
        >
          <div class="chatitem-body__top">
            <span class="chatitem-body__name">{{ el.author.name }}</span>
            <span class="chatitem-body__date">{{
              formattedDate(el.created_at)
            }}</span>
          </div>
          <p class="chatitem-body__text">{{ usk(el.text) }}</p>
          <span class="chatitem-body__time">
            {{ formattedTime(el.created_at) }}
            <button
              class="chatitem-body__answer"
              :class="{ active: this.isAnswer }"
              @click="answerActive(index, el)"
              @keydown.enter="answerActive(index, el)"
              v-if="user.user_id"
            >
              Ответить
            </button>
          </span>
        </div>
        <div class="block-reply" v-if="showAll || index === 0">
          <div
            class="block-reply__item"
            v-for="(replys, i) in el.reply"
            :key="i"
            :class="{
              userColor: replys.author.role.item_name == 'user',
              girlColor: replys.author.role.item_name == 'girl',
            }"
          >
            <div class="chatitem-body__top">
              <span class="chatitem-body__name">{{ replys.author.name }}</span>
              <span class="chatitem-body__date">{{
                formattedDate(replys.created_at)
              }}</span>
            </div>
            <p class="chatitem-body__text">{{ usk(replys.text) }}</p>
            <span class="chatitem-body__time">
              {{ formattedTime(replys.created_at) }}
            </span>
          </div>
        </div>
        <div class="chatitem-drop " v-if="isAnswer == index" id="crollContainer">
          <img
          v-if="this.user.profile.avatar"
            class="chatitem-drop__avatar"
            :src="
              this.apiDomine + '/web/uploads/' +
              this.user.profile.avatar
            "
            alt=""
          />
          <img  v-else
        class="chatitem-drop__avatar"
        :src="require('@/assets/img/no-avatar.png')"
        alt=""
      /> 
          
          <div class="chatitem-drop__input">
            <textarea
              type="text"
              class="chatitem-drop__inputs"
              @input="dropInput"
              v-model="replyComment"
              @keydown.enter="sendReply(el, index)"
            ></textarea>
            <button class="chatitem-drop__send" @click="sendReply(el, index)">
              <img :src="require('@/assets/img/sendB.svg')" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chats {
  margin-bottom: 30px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  // gap: 30px;
  max-height: 365px;
  overflow-y: auto;
}
.chatitem {
  display: flex;
  flex-direction: column;
  &-drop {
    max-width: 556px;
    width: 100%;
    display: none;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
   
    &__send {
      position: absolute;
      right: 15px;
      bottom: 8px;
      & img {
        width: 22px;
      }
    }
    &__avatar {
      width: 44px;
      height: 44px;
      object-fit: cover;
      border-radius: 50%;
    }
    &__input {
      position: relative;
      max-width: 528px;
      width: 100%;
    }
    &__inputs {
      border: 2px solid rgba(189, 193, 209, 0.31);
      border-radius: 16px;
      resize: none;
      height: 44px;
      overflow-y: hidden;
      padding: 10px 50px 10px 10px;
      vertical-align: middle;
      width: 100%;
    }
  }
  &-body {
    &__answer {
      font-weight: 800;
      font-size: 12px;
      color: #787a84;
      transition: 0.3s;
      border-bottom: 1px solid transparent;
      padding: 0 !important;
      display: none;
      &:hover {
        border-bottom-color: #787a84;
      }
    }
    &__time {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.get {
      align-self: flex-start;
      & .chatitem-body__answer.active {
        border-bottom-color: #787a84;
      }
      & .chatitem-body__answer {
        display: block;
      }
    }
    &.send {
      &.userColor {
        background: #eaecf3;
      }
      align-self: flex-end;
    }
    &.get {
      &.girlColor {
        background: #f3e7e6;
      }
      border-radius: 0 18px 18px 18px;
      background: #eaecf3;
    }
    &.send {
      background: #f3e7e6;
      border-radius: 18px 18px 0 18px;
    }

    max-width: 500px;
    width: 100%;
    padding: 15px 25px;
    &__date {
      font-weight: 500;
      color: #a3aac2;
    }
    &__time {
      color: #a3aac2;
    }
    &__text {
      font-size: 17px;
      font-weight: 500;
      color: #838383;
    }
    &__name {
      font-size: 18px;
      font-weight: 500;
      color: #525665;
    }

    &__text {
      word-wrap: break-word;
    }
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.chatitem-body.get ~ .chatitem-drop {
  display: flex;
}
.block {
  &-reply {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    &__item {
      &.userColor {
        background: #eaecf3;
      }
      &.girlColor {
        background: #f3e7e6;
      }
      border-radius: 0 18px 18px 18px;
      background: #eaecf3;
      max-width: 500px;
      width: 100%;
      padding: 15px 25px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        width: 2px;
        height: 80%;
        background: red;
        transform: translateY(-50%);
      }
    }
  }
}
.chatitem {
  &-one {
    display: flex;
    flex-direction: column;
  }
  &-body {
    &.get {
      align-self: flex-start;
      & .chatitem-body__answer.active {
        border-bottom-color: #787a84;
      }
      & .chatitem-body__answer {
        display: block;
      }
    }
    &.send {
      align-self: flex-end;
    }
  }
}

.chatitem-drop.closeinp{
  display: none !important;
}
.chatitem-body.send ~ .block-reply {
  align-items: flex-end;
}
.chatitem-body.get ~ .block-reply {
  align-items: flex-start;
}
</style>
