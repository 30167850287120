<template>
    <div class="gradient-button__wrapper">
        <button>
            <slot />
        </button>
    </div>
</template>

<script>
export default {
    name: 'gradient-button',
};
</script>

<style lang="scss" scoped>
//@import 'bootstrap/scss/bootstrap';
@import '@/styles/promo/main.scss';
.gradient-button__wrapper {
    border-radius: 76px;
    height: 56px;
    width: 100%;
    padding: 1px;
    background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);

    > button {
        height: 100%;
        width: 100%;
        background: linear-gradient(278deg, #bf5152 0%, #312dda 100%);
        border-radius: 68px;
        border: solid 1px $background-main-color;
        color: $font-main-color;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-wrap: nowrap;
    }
}

@include media-breakpoint-up(xl) {
    .gradient-button__wrapper {
        > button {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }
    }
}
</style>
