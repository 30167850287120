<script>
import GirlListCategory from "@/components/ui/GirlListCategory.vue";
import OtzyvModal from "@/components/blocks/OtzyvModal.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import SliderInCategory from "@/components/blocks/SliderInCategory.vue";
import axios from "axios";
import Slider from "@/components/blocks/Slider.vue";
import sklonyator from 'sklonyator';
import AllTags from "@/components/ui/common/tags/AllTags.vue";
import QuizBlock from "@/components/blocks/QuizBlock.vue";
import NewProfilesBlock from "@/components/blocks/NewProfilesBlock.vue";

export default {
    name: 'CompilationService',
    components: {
      NewProfilesBlock,
      Slider, SliderInCategory, UiLoader, OtzyvModal, GirlListCategory, sklonyator, AllTags, QuizBlock},
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            compilationService: this.$store.getters.getCompilationService,
            compilationRating: [],
            showLoader: false,
            city: '',
            title: '',
            category_id: '',
            otzyvModal: false,
        }
    },
    methods: {
        getSelectionRating() {
            let params = ''
            if (this.$route.params.service_id) {
                params += '&service_id=' + this.$route.params.service_id
            }
            if (this.$route.params.district_id) {
                params += '&district_id=' + this.$route.params.district_id
            }
            params += '&auth=' + this.user.username + ':' + this.user.auth_key
            params += '&limit=50'
            params += `&city_id=${this.user.city?.id || 1}`

            axios
                .get(this.apiUrl + 'api-girl/get-profiles-by-params' + params)
                .then((response) => {
                    this.sklonyator()
                    this.compilationRating = response.data.data
                    this.showLoader = false

                }).catch(error => console.log(error))
        },
        getSelectedService() {
            axios
                .get(this.apiUrl + 'api-girl/get-profiles-by-service-id'
                    + '&service_id=' + this.$route.params.service_id
                    + '&auth=' + this.user.username + ':' + this.user.auth_key
                    + '&limit=50')
                .then((response) => {
                    this.compilationRating = response.data.data

                })
        },
        sklonyator() {
               this.city = sklonyator.incline(this.user.city.name);
        },
        async getCompilationService() {
            if (!this.compilationService || !this.compilationService.name) {
                try {
                    const URL = `${this.apiUrl}api/get-profile-service-by-id&auth=${this.user.username}:${this.user.auth_key}&id=${this.$route.params.service_id}`
                    const { data } = await axios.get(URL)

                    this.compilationService = data
                } catch (error) {
                    console.log('getCompilationService: ', error)
                }
            }
        }
    },
    async mounted() {
        this.getSelectionRating()
        await this.getCompilationService()

        this.showLoader = true
    },
    watch: {
        $route: function () {
            this.getSelectionRating()
            this.compilationService = this.$store.getters.getCompilationService
        },
        $store: function () {

        },
        userCity: async function (newCity, oldCity) {
            if (newCity.name && oldCity && newCity.name !== oldCity.name) {
                this.showLoader = true
                this.compilationRating = []
                this.getSelectionRating()
                this.getCompilationService()
            }
        },
        city: function (newCity, oldCity) {
            document.title = `Девушки с услугой: ${this.compilationService.name} в ${this.city}`
        }
    },
    computed: {
        userCity(){
            return this.$store.getters.getCity
        }
    }
}
</script>
<template>
    <main>
        <div class="block-offer">
            <div class="container">
                <div class="row">
                    <div class="col-12 title-cat roboto-bold p-3">
                        Девушки с услугой:
                        <span v-if="compilationService?.name"> {{ compilationService.name }}</span>
                        <span v-else>Секс классический</span>
                        <span v-if="city"> в {{city}}</span>
                    </div>
                </div>
                <ui-loader :show="this.showLoader" :preset="'block'"/>
                <div class="slider-offer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-3" v-for="p in this.compilationRating" :key="p.id">
                                <Slider :profile="p"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <!-- <div v-for="p in this.pages" @click="showPage(p.page)" :class="p.class" :key="p.page">{{ p.name }}</div> -->
                    </div>
                </div>
            </div>
        </div>

      <div class="block-offer">
        <div class="container">
          <div class="block-offer__inner">
            <NewProfilesBlock
                categoryId=""
                titleCards=""
                flagFilter="Service"
            />
          </div>
        </div>
      </div>

        <div class="block-offer">
			<quiz-block />
		</div>
        <div class="block-offer">
			<div class="container">
				<all-tags />
			</div>
		</div>
    </main>
</template>

<style scoped>
@media screen and (max-width: 767px) {
    main {
        margin-top: 30px;
    }
}
.title-cat {
    color: #2D2F3A;
    font-size: 28px;
}
</style>

