<script setup>
import GradientPopper from '@/components/promo/GradientPopper.vue';
import GradientButton from '@/components/promo/GradientButton.vue';
import FormSubmitModal from '@/components/promo/FormSubmitModal.vue';

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

const store = useStore();
const isShowSubmitModal = ref(false);
const isShowError = ref(false);

const defaultFormData = {
    name: '',
    tg: '',
    link: '',
};

function clearFormData(defaultData) {
    return { ...defaultData };
}
const formData = ref(clearFormData(defaultFormData));
const tgUserName = ref('');

//computeds
const user = computed(() => store.getters.getUser);
const apiUrl = computed(() => store.getters.getApiUrl);

//methods
const senData = async () => {
    const { data, status } = await axios
        .post(
            apiUrl.value + 'api-tg/get-lead-video',
            {
                ...formData.value,
            },
            {
                params: {
                    auth: user.value.username + ':' + user.value.auth_key,
                },
            }
        )
        .catch((error) => {
            console.error('Request error: ', error);
        });
    if (status) {
        return { data, status };
    } else return { data: undefined, status: false };
};

const onInputTgUsername = (e) => {
    const regExp = /^@[a-zA-Z0-9_]{0,33}$/;
    if (regExp.test(e.target.value)) {
        formData.value.tg = regExp.exec(e.target.value)[0];
    }
    tgUserName.value = formData.value.tg;
};

const onFormSubmit = async () => {
    const { data, status } = await senData();
    if (status) {
        formData.value = clearFormData(defaultFormData);
        isShowSubmitModal.value = true;
        isShowError.value = false;
    } else {
        isShowSubmitModal.value = true;
        isShowError.value = true;
    }
    tgUserName.value = '';
};
</script>

<template>
    <section class="promo-visit-card__container">
        <div class="promo-visit-card__header">
            <div>Создание видеовизитки</div>
            <h2>
                Мы создаём видео<br />
                для ЭСКОРТ МОДЕЛЕЙ
            </h2>
            <div>
                <p>
                    Незабываемые видео, превращающие<br />
                    посетителей анкеты в фанатов.
                </p>
            </div>
            <GradientPopper>
                <template v-slot:button>Как снять видео?</template>
                <template v-slot:text
                    >Вы снимаете видео по нашим рекомендациям, мы профессионально монтируем и отдаем Вам огненную
                    видеовизитку!</template
                >
            </GradientPopper>
        </div>
        <div class="promo-visit-card__image">
            <img
                class="img-fluid"
                src="/img/cv_image.png"
                alt="cv_image"
            />
        </div>
        <div class="promo-visit-card__form">
            <form @submit.prevent="onFormSubmit">
                <div class="promo-visit-card__form__fields">
                    <div class="promo-visit-card__form__field-wrapper">
                        <input
                            v-model.trim="formData.name"
                            type="text"
                            class="form-control"
                            id="promo-visit-card__form__name"
                            placeholder="Меня зовут"
                            pattern="[A-Za-zА-Яа-яЁё\s]{2,}"
                            required
                        />
                    </div>
                    <div class="promo-visit-card__form__field-wrapper">
                        <input
                            v-model.trim="tgUserName"
                            type="text"
                            class="form-control"
                            id="promo-visit-card__form__telegram"
                            placeholder="Мой telegram"
                            required
                            @input="onInputTgUsername"
                        />
                    </div>
                    <div class="promo-visit-card__form__field-wrapper">
                        <input
                            v-model.trim="formData.link"
                            type="text"
                            class="form-control"
                            id="promo-visit-card__form__resume-link"
                            placeholder="Ссылка на вашу анкету"
                        />
                    </div>
                </div>
                <div class="promo-visit-card__form__submit-button">
                    <GradientButton> Хочу видеовизитку </GradientButton>
                </div>
            </form>
        </div>
    </section>
    <FormSubmitModal
        v-if="isShowSubmitModal"
        @hide="isShowSubmitModal = false"
    >
        <div
            v-if="!isShowError"
            class="promo-visit-card__modal-submit"
        >
            <h3>Запрос отправлен, мы его получили!</h3>
            <p>Свяжемся с Вами через telegram.</p>
        </div>
        <div
            v-else-if="isShowError"
            class="promo-visit-card__modal-submit"
        >
            <h3>Ошибка!</h3>
            <p>Произошла ошибка во время отправки формы.</p>
        </div>
    </FormSubmitModal>
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-visit-card__modal-submit {
    > h3 {
        text-align: center;
        margin-bottom: 40px;
        @include text-bold-small;
    }

    > p {
        text-align: center;
        @include text-regular-medium;
    }
}

.promo-visit-card__container {
    margin-inline: 20px;
    margin-bottom: 110px;

    .promo-visit-card__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        > div:first-child {
            @include text-regular-small;
        }

        h2 {
            text-align: center;
            @include text-bold-small;
        }

        p {
            text-align: center;
            @include text-regular-small;
            margin-bottom: 20px;
        }
    }

    .promo-visit-card__image {
        width: 100%;
        margin-bottom: 30px;
    }

    .promo-visit-card__form {
        margin-bottom: 120px;

        .promo-visit-card__form__fields {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .promo-visit-card__form__field-wrapper {
                padding: 1px;
                background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
                border-radius: 50px;

                .form-control::placeholder {
                    color: $font-placeholder-color;
                }

                input {
                    background-color: $background-main-color;
                    padding: 28px 45px;
                    border-radius: 50px;
                    border: none;
                    font-weight: 600;
                    font-size: 16px;
                    color: $font-placeholder-color;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .promo-visit-card__container {
        margin-inline: auto;
        display: grid;
        grid-template-columns: 60% 40%;

        .promo-visit-card__header {
            align-items: flex-start;
            margin-bottom: 70px;

            > div:first-child {
                @include text-regular-medium;
                text-align: start;
            }

            h2 {
                text-align: start;
                @include text-h2-medium;
            }

            p {
                text-align: start;
                @include text-regular-medium;
                margin-bottom: 20px;
            }
        }
        .promo-visit-card__image {
            margin-inline: auto;
            display: flex;
            align-items: center;
        }

        .promo-visit-card__form {
            grid-column: 1/3;
            .promo-visit-card__form__fields {
                gap: 40px;
                margin-bottom: 30px;
            }
            .promo-visit-card__form__submit-button {
                width: 200px;
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .promo-visit-card__container {
        grid-template-columns: 45% 55%;

        .promo-visit-card__header {
            grid-row: 1/2;

            > div:first-child {
                @include text-regular-large;
                text-align: start;
            }

            h2 {
                text-align: start;
                @include text-bold-medium;
            }

            p {
                text-align: start;
                @include text-regular-large;
                margin-bottom: 20px;
            }
        }
        .promo-visit-card__image {
            margin-inline: 10px;
            display: flex;
            align-items: flex-start;
            grid-row: 1/3;
        }

        .promo-visit-card__form {
            grid-column: 1/2;
            .promo-visit-card__form__fields {
                margin-bottom: 50px;
                .promo-visit-card__form__field-wrapper {
                    input {
                        font-size: 20px;
                    }
                }
            }
            .promo-visit-card__form__submit-button {
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
            }
        }
    }
}
</style>
