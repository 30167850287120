<script>
import {ref} from 'vue';
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import {FreeMode, Navigation, Thumbs, Pagination, EffectFade, Mousewheel} from 'swiper/modules';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import 'swiper/swiper-bundle.css';
import '@/styles/swiper.css'
import 'swiper/css/effect-fade';
import conjugation from "@/mixins/conjugation";


export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [conjugation],
    props: {
        profile: {
            type: Object,
            require: true
        }
    },
    methods: {
        openBlank(id, id2) {
            if (!id2) {
                 window.open('/anketa/' + id, '_blank');
            }else{
                 window.open('/anketa/' + id2, '_blank');
            }
            console.log(id,id2);
           
        }
    },

    mounted() {

    },

    data() {
        return {
            apiDomine: this.$store.getters.getApiDomine,
            path: [],
            imgName: '',
            swiperOptions: {
                breakpoints: {
                    breakpoints: {374: {slidesPerView: 2}, 1240: {slidesPerView: 4}},
                },
                navigations: true
            }
        }
    },
    setup() {
        const thumbsSwiper = ref(null);

        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        }
        return {
            thumbsSwiper,
            setThumbsSwiper,
            modules: [FreeMode, Navigation, Thumbs, Pagination, EffectFade, Mousewheel],
        };
    },
};
</script>
<template>
    <div class="slider-offer mt-2 mt-md-3">
        <div class="slider-offer__inner">
            <div class="slider-offer__big ">
                <swiper
                    :loop="true"
                    :pagination="{clickable: true}"
                    :slidesPerView="4"
                    :navigation="{
                 nextEl: '.next',
                 prevEl: '.prev',
            }"
                    :thumbs="{ swiper: thumbsSwiper }"
                    :modules="modules"
                    :mousewheel="false"
                    :grabCursor="true"
                    :effect="'fade'"
                    class="mySwiper2"
                >
                    <swiper-slide v-for="(item, i) in this.profile.photo || profile.girlProfile?.photo" :key="i"  @click="openBlank(this.profile.id, profile.profile_id)">
                        <div class="slide-offer"
                             :style="{
							width: '100%',
							height: '100%',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							backgroundImage: 'url(' + this.apiDomine + '/web/uploads' + item.pic + ')' }">

                            <div class="slide-offer__info">
                                <div class="slide-offer__info-name" v-html="this.profile.name || profile.girlProfile?.name"></div>
                                <div class="slide-offer__info-age">{{ m_age(this.profile.age || profile.girlProfile?.age) }}</div>
                                <div class="slide-offer__info-location"></div>
                            </div>
<!--                            <div class="slide-offer__help">-->
<!--                                <div class="slide-offer__help-ico">-->
<!--									<span v-if="this.profile.status_id == 6">-->
<!--                                        <img src="@/assets/img/footer/svg/item1.svg" alt="Анкета проверена"-->
<!--                                             title="Анкета проверена">-->
<!--                                    </span>-->
<!--									<span v-if="this.profile.girlProfile?.status_id == 6">-->
<!--                                        <img src="@/assets/img/footer/svg/item1.svg" alt="Анкета проверена"-->
<!--                                             title="Анкета проверена">-->
<!--                                    </span>-->
<!--                                    &lt;!&ndash;									<span v-if="">-->
<!--                                                                            <img src="@/assets/img/footer/svg/item2.svg" alt="Анкета в топе" title="Анкета в топе">-->
<!--                                                                        </span>&ndash;&gt;-->
<!--                                    <span v-if="this.profile.video">-->
<!--                                        <img src="@/assets/img/footer/svg/item3.svg" alt="В анкете есть видео визитка"-->
<!--                                             title="В анкете есть видео визитка">-->
<!--                                    </span>-->
<!--                                    <span v-if="this.profile.girlProfile?.video">-->
<!--                                        <img src="@/assets/img/footer/svg/item3.svg" alt="В анкете есть видео визитка"-->
<!--                                             title="В анкете есть видео визитка">-->
<!--                                    </span>-->
<!--                                    &lt;!&ndash;									<span v-if="">-->
<!--                                                                            <img src="@/assets/img/footer/svg/item4.svg" alt="Идет видео трансляция" title="Идет видео трансляция">-->
<!--                                                                        </span>&ndash;&gt;-->
<!--                                </div>-->

<!--                            </div>-->
                            <div class="slide-offer__price" v-if="this.profile">
                                <div>
                                    <span>за час </span>
                                    <span v-if="this.profile.price_hour_app != 0"
                                          v-html="this.profile.price_hour_app"></span>
                                    <span v-else v-html="this.profile.price_hour_exit"></span>
                                </div>
                                <div>
                                    <span>за два</span>
                                    <span v-if="this.profile.price_two_hour_app != 0"
                                          v-html="this.profile.price_two_hour_app"></span>
                                    <span v-else v-html="this.profile.price_two_hour_exit"></span>
                                </div>
                                <div>
                                    <span>за ночь</span>
                                    <span v-if="this.profile.price_night_app != 0"
                                          v-html="this.profile.price_night_app"></span>
                                    <span v-else v-html="this.profile.price_night_exit"></span>
                                </div>

                            </div>
                            <div class="slide-offer__price" v-if="profile.girlProfile">
                                <div>
                                    <span>за час </span>
                                    <span v-if="profile.girlProfile.price_hour_app != 0"
                                          v-html="profile.girlProfile.price_hour_app"></span>
                                    <span v-else v-html="profile.girlProfile.price_hour_exit"></span>
                                </div>
                                <div>
                                    <span>за два</span>
                                    <span v-if="profile.girlProfile.price_two_hour_app != 0"
                                          v-html="profile.girlProfile.price_two_hour_app"></span>
                                    <span v-else v-html="profile.girlProfile.price_two_hour_exit"></span>
                                </div>
                                <div>
                                    <span>за ночь</span>
                                    <span v-if="profile.girlProfile.price_night_app != 0"
                                          v-html="profile.girlProfile.price_night_app"></span>
                                    <span v-else v-html="profile.girlProfile.price_night_exit"></span>
                                </div>

                            </div>
                        </div>
                    </swiper-slide>
                    <div class="prev">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                    </div>
                    <div class="next">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>

                </swiper>
            </div>
            <div class="slider-offer__small">
                <swiper
                    :loop="true"
                    @swiper="setThumbsSwiper"

                    :slidesPerView="4"
                    :breakpoints="swiperOptions.breakpoints"
                    :freeMode="true"

                    :spaceBetween="20"
                    :mousewheel="true"
                    :watchSlidesProgress="true"
                    :modules="modules"
                    class="mySwiper slider-mini"
                >
                    <swiper-slide v-for="(item, i) in this.profile.photo || profile.girlProfile.photo" :key="i">
                        <div class="slider-mini__img"
                             @click="this.path = 'url(' + this.apiDomine + '/web/uploads' + item.pic + ')';">
                            <img :src="this.apiDomine + `/web/uploads${item.pic}`" alt="girls" loading="lazy">
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="row justify-content-center mt-3 mb-3">
            <div class="col-auto">
                <button class="btn-gradient hover-btn" @click="openBlank(this.profile.id, profile.profile_id)">Посмотреть анкету</button>
            </div>
        </div>
    </div>
    
</template>


<style scoped lang="scss">
@import '@/styles/index';

.slider-offer {
    max-width: 300px;

    &__big {
        height: 450px;
        width: 100%;
        @media ($phoneB) {
            height: 450px;
        }

        .swiper {
            height: 100%;
        }

        .swiper-slide {
            border-radius: 9px;
            > div {
                border-radius: 8px;
                overflow: hidden;
            }

            img {
                height: auto;
            }
        }
    }

    &__small {
        .swiper-wrapper {
            justify-content: space-between;

            .swiper-slide {
                max-width: 67px;
                width: 67px !important;
                flex: 0 0 67px;
                margin-right: 12px !important;
                height: 67px !important;
                @media(min-width: 374px) {
                    flex: 0 0 60px;
                    max-width: 60px;
                    height: 60px;
                }
                @media(min-width: 575px) {
                    flex: 0 0 67px;
                    max-width: 67px;
                    height: 67px !important;
                }
            }
        }

        .swiper-slide {
            width: 65px;
            height: 65px;
            overflow: hidden;
            cursor: pointer;
            border-radius: 8px !important;
        }
    }

    .swiper-pagination-bullet {
        background: #fff;
        width: 10%;
        height: 3px;
        border-radius: 10px;
    }

    .swiper-pagination {
        top: 0px;
        left: 0;
        right: 0;
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: auto;
    }
}

.slide-offer {
    overflow: hidden;
    border-radius: 26px;
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 2rem 1rem;
    padding: 30px 15px;

    &__info {
        text-align: left;
        color: #FFF;
        font-size: 12px;
        line-height: 100%;
    }

    &__info-name {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
    }

    &__info-age {
        margin-bottom: 10px;
    }

    &__help {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 100;

        &--online {
            padding: 0 10px 0;
            position: relative;
            z-index: 10;
            margin-bottom: 5px;
        }
    }

    &__help-ico {
        backdrop-filter: blur(15px);
        padding: 10px;
        border-radius: 10px;

        span {
            margin-bottom: 7px;
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__info-location {
        span {
            display: block;
        }
    }

    &__price {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        text-align: left;
        width: 100%;
        padding: 4% 5%;
        background-color: rgba(0, 0, 0, 0.3);

        span {
            &:first-child {
                color: rgba(#fff, 0.80);
                font-size: 12px;
                line-height: 120%;
                white-space: nowrap;
                display: block;
            }

            &:last-child {
                color: #FFF;
                font-size: 17px;
                font-weight: 600;
                line-height: 120%;
            }
        }

        div {
            @media(max-width: 575px) {
                display: flex;
                flex-direction: column;
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            backdrop-filter: blur(10px);
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }
}

.slider-mini {
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.mySwiper2 .prev,
.mySwiper2 .next {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 15px);
    background: #fff;
    opacity: 0.4;
    width: 29px;
    height: 29px;
    border: 1px solid #BDC1D1;
    border-radius: 5px;
    z-index: 9;
}

.mySwiper2 .prev {
    left: 15px;
}

.mySwiper2 .next {
    right: 15px;
}

.mySwiper .swiper-slide {
    opacity: 1;
}

.mySwiper {
    padding: 5px 0px;
}

.swiper-slide-thumb-active .slider-mini__img {
    border: 4px solid transparent;
    border-image: linear-gradient(to right, #21045D, #DB0F00);
    -moz-border-image: -moz-linear-gradient(left, #21045D, #DB0F00);
    -webkit-border-image: -webkit-linear-gradient(left, #21045D, #DB0F00);
    border-image-slice: 1;
}

.btn-gradient {
    border-radius: 30px;
    background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
    color: #fff;
    font-weight: 600;
    padding: 8px 22px;
}

.btn-gradient:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, .9);
}
</style>
 