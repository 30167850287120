<script>
export default {
    name:'MetroIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:18, h:16}
        }
    }
}
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w - 3) + ' ' + (this.size.h - 1)" fill="none">
        <path d="M11.4914 11C11.4914 11 12.878 9.325 12.9856 7.35312C13.127 4.85312 12.2108 3.33437 10.9934 2.25937C10.4049 1.72786 9.72965 1.30484 8.99805 1.00937L7.00582 11L5.01051 1C4.27296 1.28708 3.59429 1.71094 3.00906 2.25C1.79774 3.33125 0.875407 4.8625 1.01376 7.34375C1.13673 9.33125 2.52023 11 2.52023 11" :stroke="this.color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
</template>
