/*
Краткое описание:
Это компонент я написал для избежания копипасты верстки в компонентах, где требуется модальное окно
для таблицы на мобильных устройствах.
- Props: Принимает данные в виде массива объектов {title: id, content: user.id} и т.д
- Emits: Испускает событие для родительского компонента. (служит для закрытия модалки)
- Slot: Обязателен. Каждая таблица, увы, имеет универсальные кнопки, так и еще каждая со своей логикой,
поэтому было принято решение сделать это слотом. Если слот пустой, будет выводится просто 'button'
*/
<script setup>
const props = defineProps({
  tableContent: {
    type: Array,
    required: true
  },
})

const emits = defineEmits(['closeModal'])

const modalOutside = (e) => {
  if (e.target.matches('.modal__wrapper') || e.target.closest('.modal__close')) {
    emits('closeModal')
  }
}
</script>

<template>
  <div class="modal__wrapper position-fixed" @click="modalOutside">
    <div class="modal__detail position-absolute">
      <div class="modal__tr">
        <div class="modal__info position-relative">
          <div class="modal__row flex-column gap-2">

            <div v-for="item of tableContent" class="modal__mini-table w-100 d-flex">
              <div class="modal__table-cont">
                <div class="text-center modal__table-title"><b>{{ item.title }}</b></div>
                <div class="text-center">{{ item.content }}</div>
              </div>
            </div>

            <div class="d-flex modal__controlls justify-content-center mt-4">
              <slot name="buttons">button</slot>
            </div>
          </div>

          <span class="modal__close position-absolute">
            <i class="bi bi-x-lg"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal {
  &__controlls {
    font-size: 25px;
  }

  &__table-title {
    background: #2C2F3C;
    color: #fff;
  }

  &__wrapper {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
  }

  &__row {
    padding: 50px 10px 15px 10px;
    display: flex;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    margin: auto;
    position: relative;
  }

  &__detail {
    top: 30%;
    transform: translate(-50%, -30%);
    left: 50%;
    max-width: 800px;
    width: 100%;
    padding: 0px 10px;
  }

  &__tr {
    background: white;
    border-radius: 8px;
  }

  &__table-cont {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }

  &__close {
    right: 15px;
    top: 15px;
  }

  &__table-cont > div {
    border-bottom: 1px solid black;
    padding: 0px 10px;
  }

  @media (max-width: 500px) {
    &__mini-table {
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
    &__table-cont {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
</style>