import axios from "axios";

export default {
    methods: {
        m_getAllCategory() {
            axios
                .get(this.apiUrl + 'api/get-categories&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.categories = response.data;
                })
        },

        m_getAllService() {
            axios
                .get(this.apiUrl + 'api/get-profile-services&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    for (let d of response.data) {
                        d.checked = false
                        d.description = ''
                        for (let cs of this.checkService) {

                            if (parseInt(cs.service_id) === parseInt(d.id)) {
                                d.checked = true
                                d.price = cs.price
                                if (cs.description == null) {
                                    d.description = ''
                                } else {
                                    d.description = cs.description
                                }
                            }
                        }
                        if (d.category_id == 1) {
                            this.service.sex.push(d)
                        }
                        if (d.category_id == 2) {
                            this.service.dop.push(d)
                        }
                        if (d.category_id == 3) {
                            this.service.bdsm.push(d)
                        }
                        if (d.category_id == 4) {
                            this.service.massage.push(d)
                        }
                        if (d.category_id == 5) {
                            this.service.strip.push(d)
                        }
                        if (d.category_id == 6) {
                            this.service.extreme.push(d)
                        }
                    }
                })
        },
        async m_getAllCity() {            
            await axios
                .get(this.apiUrl + 'api/get-cities&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.cities = response.data;
                    this.$store.commit('setCities', this.cities)
                })
        },
        m_getSelectionRaiting() {
            axios
                .get(this.apiUrl + 'api-girl/get-top-by-likes&status_id=6&limit=20&auth='
                    + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.$store.commit('setCompilation', response.data)
                })

        },
        m_getBalance() {
            if (this.user.role.item_name === 'guest') {
                return
            }
            axios
                .get(this.apiUrl + 'api-billing/get-balance'
                    + '&user_id=' + this.user.user_id
                    + '&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {

                    this.$store.commit('setUserBalance', response.data.balance)
                })
        },

    },
    data(){
        return{
            apiUrl: this.$store.getters.getApiUrl,
        }
    }

}