<script>
import axios from 'axios';
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
    name: 'UploadVideo',
    components: {UiLoader},
    props: {
        videoName: {
            type: Object,
            default: '',
        }
    },

    watch: {
        videoName: function () {
            if (this.videoName) {
                this.src = this.apiDomine + '/web/uploads/girls/video/' + this.videoName.name
            }
        }
    },
    data() {
        return {
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            video: '',
            loading: false,
            src: '',
            uploadVideoLink: this.apiUrl + "api-girl/upload-video",
            user: this.$store.getters.getUser,
        }
    },

    methods: {
        handleFileUpload(event) {
            this.video = event.target.files[0];
        },

        submitFile() {
            let formData = new FormData();
            this.loading = true
            formData.append('video', this.video);

            axios.post(this.uploadVideoLink + "&auth=" + this.user.username + ':' + this.user.auth_key,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response) => {
                this.loading = false
                this.src = this.apiDomine + '/web/uploads/girls/video/' + response.data.name
                this.$emit('video', response.data.name)
            })
                .catch((response) => {
                    this.loading = false
                });
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <ui-loader :show="loading" :preset="'block'"/>
            <div v-if="!loading">
                <video class="video-card" :src="this.src" controls
                       type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'></video>
            </div>
            <hr/>
            <label class="input-file">
                <span class="input-file-text" type="text">{{ video.name }}</span>
                <input type="file" @change="handleFileUpload( $event )">
                <span class="input-file-btn">Выберите файл</span>
            </label>
            <br>
            <button class="btn btn-dark mt-3 w-100 hover-btn" v-on:click="submitFile()">Загрузить</button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.btn-dark {
    background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%)) !important;
    color: #fff;
    border: none;
    border-radius: 14px;
}

.input-file {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    max-width: 100%;
}

.input-file-text {
    padding: 0 10px;
    line-height: 40px;
    text-align: left;
    height: 40px;
    display: block;
    float: left;
    box-sizing: border-box;
    width: 80%;
    border-radius: 14px 0px 0 14px;
    border: 1px solid #ddd;
}

.input-file-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 14px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 0 14px 14px 0;
    background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
    line-height: 22px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
}

.input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

/* Focus */
.input-file input[type=file]:focus + .input-file-btn {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Hover/active */
.input-file:hover .input-file-btn {
    background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
    font-weight: 700;
}

.input-file:active .input-file-btn {
    background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
}

/* Disabled */
.input-file input[type=file]:disabled + .input-file-btn {
    background-color: #eee;
}

.video-card {
    max-width: 100%;
    max-height: 100%;
    border-radius: 14px;
}
</style>