<script setup>
import OrderBackwardButton from '@/components/order_modal/OrderBackwardButton.vue';
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
import { onMounted, ref } from 'vue';

const props = defineProps({
    types: {
        type: Array,
        required: true,
        default: () => {
            return [{ value: '', label: 'Не указано' }];
        },
    },
    initialType: {
        type: String,
        required: true,
        default: '',
    },
});
const emit = defineEmits(['next-step']);
const currentType = ref(null);

onMounted(() => {
    currentType.value = props.initialType;
});
</script>

<template>
    <div class="order-type">
        <div class="order-type__question mb-3">
            <p>Планируете приехать в апартаменты, пригласить девушку к себе или желаете сопровождение?</p>
        </div>
        <div class="order-type__content d-flex flex-wrap gap-3">
            <div class="order-type__radio-buttons">
                <div
                    class="btn-group d-flex flex-wrap justify-content-center gap-3"
                    role="group"
                >
                    <div
                        v-for="type in types"
                        :key="'key' + type.value"
                    >
                        <input
                            type="radio"
                            class="order-type__radio-input btn-check"
                            name="type-radio"
                            :id="'apartments_' + type.value"
                            :value="type.value"
                            v-model="currentType"
                        />
                        <label
                            class="order-type__radio-label"
                            :for="'apartments_' + type.value"
                            >{{ type.label }}</label
                        >
                    </div>
                </div>
            </div>
            <div class="order-type__buttons ms-auto">
                <OrderBackwardButton
                    class="h-100"
                    @click="emit('next-step', { type: currentType, forward: false })"
                />
                <OrderNextButton
                    class="h-100"
                    @click="emit('next-step', { type: currentType, forward: true })"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-type {
    .order-type__question {
        font-size: 16px;
        line-height: 20px;
    }

    .order-type__content {

        .order-type__radio-input:checked + label {
            background-color: #A8B0CE;
        }

        .order-type__radio-label {
            background-color: #201c2d;
            color: white;
            width: 180px;
            height: 38px;
            font-size: 14px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;

            &:hover {
                cursor: pointer;
            }

        }
    }
}
</style>
