// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/vue-select/dist/vue-select.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".order-city .order-city__question > p[data-v-165a4306] {\n  font-size: 16px;\n  line-height: 20px;\n}\n.order-city .order-city__content .order-city__picker[data-v-165a4306] {\n  height: 30px;\n  width: 200px;\n  --vs-colors--lightest: #bdc1d1;\n  --vs-colors--light: #bdc1d1;\n  --vs-colors--dark: #333;\n  --vs-colors--darkest: rgba(0, 0, 0, 0.15);\n  /* Font */\n  --vs-font-size: 20px;\n  --vs-line-height: 30px;\n  --vs-border-color: var(--vs-colors--lightest);\n  --vs-border-width: 1px;\n  --vs-border-style: solid;\n  --vs-border-radius: 12px;\n  /* Actions: house the component controls */\n  --vs-actions-padding: 4px 6px 0 3px;\n  /* Component Controls: Clear, Open Indicator */\n  --vs-controls-color: var(--vs-colors--light);\n  --vs-controls-size: 1;\n  --vs-controls--deselect-text-shadow: 0 1px 0 #fff;\n  /* Selected */\n  --vs-selected-bg: #312b45;\n  --vs-selected-color: white;\n  --vs-selected-border-color: var(--vs-border-color);\n  --vs-selected-border-style: var(--vs-border-style);\n  --vs-selected-border-width: var(--vs-border-width);\n  /* Dropdown */\n  --vs-dropdown-bg: #312b45;\n  --vs-dropdown-color: inherit;\n  --vs-dropdown-z-index: 1000;\n  --vs-dropdown-min-width: 160px;\n  --vs-dropdown-max-height: 350px;\n  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);\n  /* Options */\n  --vs-dropdown-option-bg: #312b45;\n  --vs-dropdown-option-color: var(--vs-dropdown-color);\n  --vs-dropdown-option-padding: 8px 12px;\n  /* Active State */\n  --vs-dropdown-option--active-bg: #201c2d;\n  --vs-dropdown-option--active-color: #fff;\n  /* Deselect State */\n  --vs-dropdown-option--deselect-bg: #fb5858;\n  --vs-dropdown-option--deselect-color: #fff;\n  /* Transitions */\n  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);\n  --vs-transition-duration: 150ms;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
