<script>
/**
 * Компонент мобильного меню.
 * Пропсы:
 * show: отображение
 * Использование <swiping-menu :show="true" >Содержимое</swiping-menu>
 */
export default {
    name: 'SwipingMenu',
    props: {
        show: {
            type: Boolean,
            require: true
        }
    },
    methods:{
        swipingMenu(
            event,
            element,
            elementHeight,
            startY,
            deltaY,
            classNonTransition,
            swipeResult,
            touchEnd
        ) {
            if (classNonTransition) element.classList.add("non-transition");
            else element.classList.remove("non-transition");
            if (deltaY > 25 || deltaY < -25) element.style.bottom = `-${deltaY}px`;
            if (touchEnd && swipeResult === "Up" && (deltaY > 25 || deltaY < -25)) {
                this.$emit('open');
                element.style.bottom = "0";
            } else if (touchEnd && swipeResult === "Down" && (deltaY > 25 || deltaY < -25)) {
                this.$emit('close');
                element.style.bottom = "0";
            }
        },
    },
}
</script>

<template>

    <div v-swipe="swipingMenu" :class="['swiping-menu', { 'show': this.show }]">
        <div class="touch-box"></div>
        <slot></slot>
    </div>
</template>

<style scoped>
.swiping-menu{
    position: fixed;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -5px 12px 3px rgba(0, 0, 0, 0.065);
    border-radius: 15px 15px 0 0;
    transition: 0.4s;
    z-index: 1040;
    padding-bottom: 18px;
    height: 50vh;
    padding-left: 10px;
    padding-right: 10px;
}
.swiping-menu.show{
    transform: translateY(0);
}
.touch-box{
    position: relative;
    height: 30px;
}

.touch-box::after{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 5px;
    border-radius: 50px;
    background-color: rgba(0,0,0, 0.15);
}
</style>