<script setup>
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
import vSelect from 'vue-select';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

const props = defineProps({
    initialCityId: {
        type: Number,
        default: 1,
        required: true,
    },
});
const emit = defineEmits(['next-step']);
const store = useStore();
const user = store.getters.getUser;
const apiUrl = store.getters.getApiUrl;
const cities = ref(null);
const currentCity = ref(null);

onMounted(async () => {
    cities.value = await getCityes();
    currentCity.value = cities.value.find((city) => city.id === props.initialCityId);
});

//methods
const getCityes = async () => {
    const { data } = await axios
        .get(apiUrl + 'api/get-cities', {
            params: {
                auth: user.username + ':' + user.auth_key,
            },
        })
        .catch((error) => {
            console.error(error.message);
            return error;
        });
    if (data !== null && data !== undefined) {
        return data;
    } else return [];
};

</script>

<template>
    <div class="order-city">
        <div class="order-city__question mb-3">
            <p>Выберите город, где планируете организовать встречу</p>
        </div>
        <div class="order-city__content d-flex justify-content-between align-items-center flex-wrap gap-3">
            <div class="mb-3">
                <vSelect
                    class="order-city__picker"
                    :options="cities || []"
                    label="name"
                    v-model="currentCity"
                    :clearable="false"
                    :searchable="false"                    
                />
            </div>
            <OrderNextButton
                class="h-10 ms-auto"
                @click="$emit('next-step', currentCity?.id)"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import 'vue-select/dist/vue-select.css';

.order-city {
    .order-city__question {
        > p {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .order-city__content {
        .order-city__picker {
            height: 30px;
            width: 200px;

            --vs-colors--lightest: #bdc1d1;
            --vs-colors--light: #bdc1d1;
            --vs-colors--dark: #333;
            --vs-colors--darkest: rgba(0, 0, 0, 0.15);

            /* Font */
            --vs-font-size: 20px;
            --vs-line-height: 30px;

            --vs-border-color: var(--vs-colors--lightest);
            --vs-border-width: 1px;
            --vs-border-style: solid;
            --vs-border-radius: 12px;

            /* Actions: house the component controls */
            --vs-actions-padding: 4px 6px 0 3px;

            /* Component Controls: Clear, Open Indicator */
            --vs-controls-color: var(--vs-colors--light);
            --vs-controls-size: 1;
            --vs-controls--deselect-text-shadow: 0 1px 0 #fff;

            /* Selected */
            --vs-selected-bg: #312b45;
            --vs-selected-color: white;
            --vs-selected-border-color: var(--vs-border-color);
            --vs-selected-border-style: var(--vs-border-style);
            --vs-selected-border-width: var(--vs-border-width);

            /* Dropdown */
            --vs-dropdown-bg: #312b45;
            --vs-dropdown-color: inherit;
            --vs-dropdown-z-index: 1000;
            --vs-dropdown-min-width: 160px;
            --vs-dropdown-max-height: 350px;
            --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);

            /* Options */
            --vs-dropdown-option-bg: #312b45;
            --vs-dropdown-option-color: var(--vs-dropdown-color);
            --vs-dropdown-option-padding: 8px 12px;

            /* Active State */
            --vs-dropdown-option--active-bg: #201c2d;
            --vs-dropdown-option--active-color: #fff;

            /* Deselect State */
            --vs-dropdown-option--deselect-bg: #fb5858;
            --vs-dropdown-option--deselect-color: #fff;

            /* Transitions */
            --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
            --vs-transition-duration: 150ms;
        }
    }
}
</style>
