<template>
    <section class="promo-promotion__container">
        <div class="promo-promotion__header">
            <div>На главной странице сервиса</div>
            <h2>Услуги в продвижении</h2>
        </div>
        <div class="promo-promotion__content">
            <div class="promo-promotion__content-list d-md-none">
                <div class="promo-promotion__content-list__item">
                    <img
                        class="img-fluid"
                        src="/img/video_cv_sm.png"
                        alt="img"
                    />
                </div>
                <div class="promo-promotion__content-list__item">
                    <img
                        class="img-fluid"
                        src="/img/cv_on_top_sm.png"
                        alt="img"
                    />
                </div>
                <div class="promo-promotion__content-list__item">
                    <img
                        class="img-fluid"
                        src="/img/video_widget_sm.png"
                        alt="img"
                    />
                </div>
            </div>
            <div class="promo-promotion__carousel__wrapper d-none d-md-block">
                <div
                    id="promo-promotion-carousel"
                    class="promo-promotion__carousel carousel slide"
                    data-bs-ride="carousel"
                >
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img
                                class="img-fluid"
                                src="/img/cv_on_top_lg.png"
                                alt="img"
                            />
                        </div>
                        <div class="carousel-item middle-item">
                            <img
                                class="img-fluid"
                                src="/img/video_widget_lg.png"
                                alt="img"
                            />
                        </div>
                        <div class="carousel-item">
                            <img
                                class="img-fluid"
                                src="/img/video_cv_lg.png"
                                alt="img"
                            />
                        </div>
                    </div>
                    <div class="promo-promotion__carousel__buttons">
                        <div>
                            <div class="carousel__btn previous__button">
                                <button
                                    class=""
                                    type="button"
                                    data-bs-target="#promo-promotion-carousel"
                                    data-bs-slide="prev"
                                >
                                    <svg
                                        width="24"
                                        height="8"
                                        viewBox="0 0 24 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.646446 3.64644C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976309 4.7308 0.659727 4.53553 0.464464C4.34027 0.269202 4.02369 0.269202 3.82843 0.464464L0.646446 3.64644ZM24 3.5L1 3.5L1 4.5L24 4.5L24 3.5Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div class="carousel__btn next__button">
                                <button
                                    class=""
                                    type="button"
                                    data-bs-target="#promo-promotion-carousel"
                                    data-bs-slide="next"
                                >
                                    <svg
                                        width="24"
                                        height="8"
                                        viewBox="0 0 24 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM0 4.5L23 4.5V3.5L0 3.5L0 4.5Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="promo-promotion__button">
            <GradientButton @click="$emit('fill_questionnaire_clicked')">Заполнить анкету</GradientButton>
        </div>
    </section>
</template>

<script>
import GradientButton from '@/components/promo/GradientButton.vue';

export default {
    name: 'promo-promotion',
    components: { GradientButton },
};
</script>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-promotion__container {
    margin-bottom: 125px;

    .promo-promotion__header {
        > div {
            text-align: center;
            @include text-regular-small;
            margin-bottom: 20px;
        }

        > h2 {
            text-align: center;
            @include text-bold-small;
            margin-bottom: 30px;
        }
    }

    .promo-promotion__content {
        margin-bottom: 20px;
        .promo-promotion__content-list {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    .promo-promotion__button {
        margin-inline: auto;
        width: 175px;
    }
}
.promo-promotion__carousel__wrapper {
    position: relative;
    width: 70%;
    margin-inline: auto;

    .carousel-item {
        padding-inline: 10px;
    }
    .promo-promotion__carousel__buttons {
        position: absolute;
        top: -85px;
        right: -20%;        
        background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
        border-radius: 76px;
        padding: 1px;

        > div {
            display: flex;
            flex-direction: row;
            gap: 7px;
            padding: 1px;
            border-radius: 76px;
            border: solid 1px $background-main-color;
            background: $background-main-color;

            .carousel__btn {
                > button {
                    height: 52px;
                    width: 52px;
                    border-radius: 50%;
                    border: none;
                    background: linear-gradient(278deg, #bf5152 0%, #312dda 100%);
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .promo-promotion__container {
        .promo-promotion__header {
            margin-bottom: 40px;
            > div {
                text-align: start;
                margin-bottom: 30px;
                @include text-regular-medium;
            }
            > h2 {
                text-align: start;
                @include text-h2-medium;
            }
        }
        .promo-promotion__content {
            margin-bottom: 40px;
        }

        .promo-promotion__carousel__buttons {
            top: -85px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-promotion__container {
        .promo-promotion__header {
            margin-bottom: 70px;
            > div {
                text-align: start;
                margin-bottom: 30px;
                @include text-regular-large;
            }
            > h2 {
                text-align: start;
                @include text-h2-large;
            }
        }
        .promo-promotion__content {
            margin-bottom: 80px;
        }

        .promo-promotion__carousel__buttons {            
            top: -140px;
        }
    }
}
</style>
