<script>
import InputWithCopy from '@/components/ui/InputWithCopy.vue';
import axios from 'axios';
import UiLoader from '@/components/ui/UiLoader.vue';
import CopyText from '@/components/ui/CopyText.vue';

export default {
  name: 'AddingForm',
  emits: ['modalOpen', 'noWallet', 'haveWallet'],
  components: { CopyText, UiLoader, InputWithCopy },
  props: {
    rate: {
      type: Number,
      require: true,
    },
    wallet: {
      type: String,
      require: true,
    },
  },
  methods: {
    rubToUsdt() {
     
      this.errorText = false;
      this.rubClass = 'form-control';
      this.usdtClass = 'form-control';
      this.usdt = parseFloat(this.rub) / this.rate;
      this.usdt = Number(this.usdt).toFixed(7);
    },

    usdtToRub() {
      this.errorText = false;
      this.usdtClass = 'form-control';
      this.rubClass = 'form-control';
      this.rub = parseFloat(this.usdt) * this.rate;
      this.rub = Number(this.rub).toFixed(2);
    },
    checkNumbers(input, a, b) {
            const value = input.value;
            const isValid = value && value >= a && value <= b;
            if (!isValid) {
                input.classList.add('is-invalid');
                this.error = 'invalid value'
            } else {
                input.classList.remove('is-invalid');
                this.error = ''
            }
        }, 
    send() {
      this.errorText = false;

      let valid = true;
      if (this.usdt == '') {
        this.usdtClass = 'form-control is-invalid';
        this.errorText = 'Заполните все поля';
        valid = false;
      }

      if (this.rub == '' || this.rub < 6000) {
        this.rubClass = 'form-control is-invalid';
        this.errorText = 'Заполните все поля';
        valid = false;
      }

      if (this.userWallet == '') {
        this.userWalletClass = 'form-control is-invalid';
        this.errorText = 'Заполните все поля';
        valid = false;
      }

      if (!valid) {
        return false;
      }

      let params = {
        usdt: this.usdt,
        rub: this.rub,
        rate: this.rate,
        userWallet: this.userWallet,
        wallet: this.wallet,
        method_id: 1,
      };

      this.showLoader = true;
      axios
        .post(
          this.apiUrl +
            'api-billing/balance-plus-request' +
            '&user_id=' +
            this.user.user_id +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          params
        )
        .then((response) => {
          this.userWallet = ''
          this.rub = ''
          this.usdt = ''
          if (response.data.status) {
            this.$emit('modalOpen', true);
            
          }
          this.showLoader = false;
          this.btnText = 'Запрос отправлен';
        })
        .catch((error) => {
          this.btnText = 'Ошибка.';
          return false;
        });
    },
    walletInfq(e) {
      if (e.target.classList.contains('have')) {
        this.walletHave = true;
        this.$emit('haveWallet', true)
      } 
      if (e.target.classList.contains('no')) {
        this.walletNo = true;
        this.$emit('noWallet', true)
      }
    },
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      rub: '',
      rubClass: 'form-control',
      usdtClass: 'form-control',
      userWalletClass: 'form-control',
      usdt: '',
      userWallet: '',
      errorText: false,
      showLoader: false,
      btnText: 'Оплата произведена',
      walletHave: false,
      walletNo: false,
      error: ''
    };
  },
};

//TODO: Меню, шрифты
//TODO:
</script>

<template>
  <div
    class="billing-checkimages"
    v-if="walletHave != true && walletNo != true"
  >
    <div class="billing-img" @click="walletInfq"  >
      <img class="cursor have" src="@/assets/img/have-wallet.svg" alt="" />
    </div>
    <div class="billing-img" @click="walletInfq">
      <img class="cursor no" src="@/assets/img/no-wallet.svg" alt="" />
    </div>
  </div>
  <div class="adding-form"  v-if="walletHave">
        <div class="row">
            <div class="col-12">
                <h4>Пополнить баланс</h4>
            </div>
            <div class="col-12 mt-3">
                <div class="form-block position-relative">
                    <ui-loader :preset="'fullBlock'" :show="showLoader"/>
                    <div class="row">
                        <div class="col-12">
                            <h5>Введите сумму</h5>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <div class="input-group">
                                <input @keyup="rubToUsdt" v-model="this.rub" type="number" :class="this.rubClass"
                                       aria-label="Имя пользователя получателя"
                                       aria-describedby="basic-addon2"
                                       @blur="checkNumbers($event.target, 6000, 100000)"
                                       >
                                  <span class="input-group__info">Минимальная сумма пополнения 6000</span>
                                <span class="input-group-text icon-block" id="basic-addon2">
                                <img src="@/assets/img/flag_rf.jpg" alt="Флаг Российской Федерации">
                                <span><b>RUB</b></span>
                            </span>
                            </div>
                        </div>
                        <div class="col-md-auto d-flex align-items-center justify-content-center">
                            <span class="equally">=</span>
                        </div>

                        <div class="col">
                            <div class="input-group">
                                <span class="input-group-text icon-block" id="basic-addon2">
                                    <img src="@/assets/img/usdt.png" alt="usdt">
                                    <span><b>USDT</b></span>
                                </span>
                                <copy-text :copy-text="this.usdt"/>
                                <input @keyup="usdtToRub" v-model="this.usdt" type="number" :class="this.usdtClass"
                                       placeholder="10000" aria-label="Имя пользователя получателя"
                                       aria-describedby="basic-addon2">

                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-auto text-danger">
                            <b>Используйте сеть TRC20</b>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">Скопируйте адрес нашего крипто кошелька и произведите оплату</div>
                        <div class="col-12 mt-1">
                            <input-with-copy :text="this.wallet" :disabled="true"/>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">Введите в это поле адрес вашего крипто кошелька, с которого вы производите
                            оплату
                        </div>
                        <div class="col-12 mt-1">
                            <input @keyup="this.errorText = '';this.userWalletClass='form-control';"
                                   v-model="this.userWallet" type="text" :class="this.userWalletClass"
                                   placeholder="AaJhViBihbibIvgG78GVY65Ff67fiVgv" aria-label="Ваш кошелёк">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">Если вы произвели оплату, нажмите эту кнопку</div>

                        <div class="col-12 mt-1">
                            <button class="adding-form-btn hover-btn" @click="this.send">{{ this.btnText }}</button>
                        </div>
                        <div class="col-12 col-md-auto text-danger d-flex align-items-center mt-1">
                            <div class="error-text" v-if="this.errorText">
                                {{ this.errorText }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.billing-img {
  max-width: 354px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  & img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.billing-checkimages {
  display: flex;
  gap: 30px;
  height: 100%;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
}
.billing-blocks__title {
  @media (max-width: 768px) {
    margin-top: 15px;
  }
}
.form-block {
  border: 1px solid #d9d9d9;
  border-radius: 23px;
  padding: 15px;
}

.icon-block img {
  max-width: 30px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.equally {
  font-size: 28px;
}

.adding-form-btn {
  background-color: #39354b;
  border-radius: 12px;
  color: #ffffff;
  padding: 8px 20px;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.error-text {
  border-radius: 5px;
  border: 1px solid red;
  padding: 5px 8px;
}
.input-group{
  position: relative;
}
.input-group__info{
  display: none;
  position: absolute;
  top: -18px;
  font-size: 13px;
  left: 0;
  color: red;
  font-weight: 700;
}
.input-group .is-invalid ~ .input-group__info{
  display: block;
}
</style>
