<script setup>
import MessengerChatItem from './MessengerChatItem.vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { onUnmounted, onMounted, ref, watch, computed, onUpdated } from 'vue';

const store = useStore();
const user = store.getters.getUser;
const apiUrl = store.getters.getApiUrl;
const activeChat = computed(() => {
  return store.getters.getActiveChat;
});

const emits = defineEmits(['back', 'choose']);

const chatMessages = ref([]);
const chatWrapper = ref(undefined);
const messagesScroll = ref(undefined);
const currentMessagesHeight = ref(0);
const interval = ref(undefined);

const getChatMessages = async () => {
  try {
    const chatId = activeChat.value.id;
    const params =
      '&auth=' + user.username + ':' + user.auth_key + '&chat_id=' + chatId;
    const { data: messages } = await axios.get(
      apiUrl + 'api-chat/get-message' + params
    );
    const result = [];

    messages.sort((a, b) => (a.date_add > b.date_add ? 1 : -1));

    messages.forEach((msg) => {
      result.push({
        id: msg.id,
        name: msg.author.name,
        text: decodeURIComponent(atob(msg.text)),
        time: timeToDay(msg.date_add),
        status: msg.author_id == user.user_id ? 'in' : 'out',
        user_id: msg.author_id,
      });
    });

    chatMessages.value = result;
    setChatMessagesRead(chatMessages.value);
  } catch (error) {
    console.log('getChatMessages: ', error);
  } finally {
    currentMessagesHeight.value = 0;
  }
};

const setChatMessagesRead = async (array = []) => {
  try {
    const params = '&auth=' + user.username + ':' + user.auth_key;
    const ids = array
      .filter((m) => +m.user_id !== +user.user_id)
      .map((m) => m.id);

    const { data } = await axios.post(apiUrl + 'api-chat/set-read' + params, {
      ids,
    });
  } catch (error) {
    console.log('setChatMessagesRead: ', error);
  }
};

const scrollMessagesBottom = () => {
  const heightChatWrapper = chatWrapper.value.offsetHeight;

  if (heightChatWrapper !== currentMessagesHeight.value) {
    currentMessagesHeight.value = heightChatWrapper;

    messagesScroll.value.scrollTo({
      top: heightChatWrapper,
      left: 0,
    });
  }
};

const timeToDay = (time) => {
  const dateAdd = new Date(time * 1000);
  const hours =
    dateAdd.getHours() < 10 ? '0' + dateAdd.getHours() : dateAdd.getHours();
  const minutes =
    dateAdd.getMinutes() < 10
      ? '0' + dateAdd.getMinutes()
      : dateAdd.getMinutes();

  return `${hours}:${minutes}`;
};

const startTimer = () => {
  stopTimer();

    interval.value = window.setInterval(() => {
        getChatMessages()
    }, 2000)
}

const stopTimer = () => {
  if (interval) {
    window.clearInterval(interval.value);
  }
};

onMounted(() => {
  if (activeChat.value.id) {
    getChatMessages();
    startTimer();
  }
});

onUnmounted(() => {
  stopTimer();
});

onUpdated(() => {
  scrollMessagesBottom();
});

watch(activeChat, (newActiveChat) => {
    if (newActiveChat.id) {
        getChatMessages()
        startTimer()
    }
  },
  { deep: true }
);

</script>
<template>
  <div class="messenger-area mb-2 w-100 h-100">
    <div
      class="messenger-area-head d-flex justify-content-center justify-content-md-start align-items-center p-3 px-0 px-md-3"
    >
      <button
        class="d-flex d-md-none justify-content-center align-items-center gap-1 position-absolute"
        @click="emits('back')"
      >
        <img src="@/assets/img/back.svg" alt="back" />
        <span>Назад</span>
      </button>
      <div class="fw-bold text-center text-md-start">
        <span>{{ activeChat.recipient }}</span>
        <span class="d-flex d-md-none text-white fw-semibold"
          >был(а) недавно</span
        >
      </div>
    </div>
    <div
      class="messenger-area-chat p-3 px-0 px-md-3 mt-2 mt-md-0"
      ref="messagesScroll"
    >
      <div class="px-3 px-md-0" ref="chatWrapper">
        <messenger-chat-item
          v-for="item in chatMessages"
          :key="item.id"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.messenger-area {
  background-color: #20222e;
  border-radius: 12px;

  .messenger-area-head {
    border-bottom: 1px solid #bdc1d133;
    position: relative;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      color: #6ccb5f;

      & > div > span:last-child {
        font-size: 10px;
      }
    }

    button {
      width: 65px;
      height: 25px;
      border-radius: 36px;
      border: 1px solid #2c2f3c;
      background-color: transparent;
      font-size: 10px;
      color: #bdc1d1;
      left: 1rem;
    }
  }

  .messenger-area-chat {
    min-height: 590px;
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #797979 #535353;

    @media screen and (max-width: 767px) {
      min-height: 300px;
      max-height: calc(100vh - 205px);
    }

    @media screen and (max-height: 850px) {
      min-height: 390px;
      max-height: calc(100vh - 305px);
    }

    @media screen and (max-height: 650px) {
      min-height: 200px;
      max-height: calc(100vh - 605px);
    }
  }
}
</style>
