<template>
	<div class="service row my-2">
		<div class="service-title mb-4">Услуги </div>
		<div class="service__block col-md-6 col-xxl-3 " v-for="(item, i) in list" :key="i">
		
				<div 
				class="service__title"
				v-html="item.name"
				@click="toggleShow"
				></div>
	
			<div class="service__body mb-3">
				<div class="service__subtitle row justify-content-between">
				<div class="col-6">Услуга</div>
				<div class="col-6 text-end">Доплата</div>
			</div>

			<div class="service-box" v-for="(list, i) in item.fuck" :key="i">
				<label class="service-box__label">
					<input type="checkbox" name="" id="" class="service-box__input">
					<span class="service-box__checked"></span>
					<span class="service-box__name" v-html="list.name"></span>
				</label>
				<label class="service-prcie">
					<span class="service-prcie__symbol"></span>
					<input type="text"
						name=""
						id=""
						v-model="list.price"
						v-on:keyup="setTel"
						class="service-prcie__input" 
						placeholder="0"
						>
					<span class="service-prcie__rouble">{{ currency.symbol }}</span>
				</label>
			</div>

			<div class="service-box" v-for="(list, i) in item.extra" :key="i">
				<label class="service-box__label">
					<input type="checkbox" name="" id="" class="service-box__input">
					<span class="service-box__checked"></span>
					<span class="service-box__name" v-html="list.name"></span>
				</label>
				<label class="service-prcie">
					<span class="service-prcie__symbol"></span>
					<input type="text"
						name=""
						id=""
						v-model="list.price"
						v-on:keyup="setTel"
						class="service-prcie__input" 
						placeholder="0"
						>
					<span class="service-prcie__rouble">{{ currency.symbol }}</span>
				</label>
			</div>

			<div class="service-box" v-for="(list, i) in item.bdsm" :key="i">
				<label class="service-box__label">
					<input type="checkbox" name="" id="" class="service-box__input">
					<span class="service-box__checked"></span>
					<span class="service-box__name" v-html="list.name"></span>
				</label>
				<label class="service-prcie">
					<span class="service-prcie__symbol"></span>
					<input type="text"
						name=""
						id=""
						v-model="list.price"
						v-on:keyup="setTel"
						class="service-prcie__input" 
						placeholder="0"
						>
					<span class="service-prcie__rouble">{{ currency.symbol }}</span>
				</label>
			</div>

			<div class="service-box" v-for="(list, i) in item.massage" :key="i">
				<label class="service-box__label">
					<input type="checkbox" name="" id="" class="service-box__input">
					<span class="service-box__checked"></span>
					<span class="service-box__name" v-html="list.name"></span>
				</label>
				<label class="service-prcie">
					<span class="service-prcie__symbol"></span>
					<input type="text"
						name=""
						id=""
						v-model="list.price"
						v-on:keyup="setTel"
						class="service-prcie__input" 
						placeholder="0"
						>
					<span class="service-prcie__rouble">{{ currency.symbol }}</span>
				</label>
			</div>
			</div>
			
			

		</div>
	</div>
</template>	

<script>
export default {
	props:{
		currency:{
			require:true,
		}
	},
	data() {
		return {
			openList: false,
			list: [
				{
					name: 'Секс',
					fuck: [
						{
							id: '1',
							name: 'Секс классический',
							check: true,
							price: '',
						},
						{
							id: '2',
							name: 'Секс анальный',
							check: true,
							price: '',
						},
						{
							id: '3',
							name: 'Секс групповой',
							check: true,
							price: '',
						},
						{
							id: '4',
							name: 'Секс лесбийский',
							check: true,
							price: '',
						},
						{
							id: '5',
							name: 'Услуги семейной паре',
							check: true,
							price: '',
						},
						{
							id: '6',
							name: 'Минет в презервативе',
							check: true,
							price: '',
						},
						{
							id: '7',
							name: 'Минет без презерватива',
							check: true,
							price: '',
						},
						{
							id: '8',
							name: 'Минет глубокий',
							check: true,
							price: '',
						},
						{
							id: '9',
							name: 'Минет в машине',
							check: true,
							price: '',
						},
						{
							id: '10',
							name: 'Кунилингус',
							check: true,
							price: '',
						},
						{
							id: '11',
							name: 'Игрушки',
							check: true,
							price: '',
						},
						{
							id: '12',
							name: 'Окончание на грудь',
							check: true,
							price: '',
						},
						{
							id: '13',
							name: 'Окончание на лицо',
							check: true,
							price: '',
						},
						{
							id: '14',
							name: 'Окончание в рот',
							check: true,
							price: '',
						},
					],
				},
				{
					name: 'Дополнительно',
					extra: [
					{
							id: '1',
							name: 'Стриптиз',
							check: true,
							price: '',
						},
						{
							id: '2',
							name: 'Лесби шоу',
							check: true,
							price: '',
						},
						{
							id: '3',
							name: 'Страпон',
							check: true,
							price: '',
						},
						{
							id: '4',
							name: 'Эскорт',
							check: true,
							price: '',
						},
						{
							id: '5',
							name: 'Анилингус вам',
							check: true,
							price: '',
						},
						{
							id: '6',
							name: 'Анилингус мне',
							check: true,
							price: '',
						},
						{
							id: '7',
							name: 'Золотой дождь вам',
							check: true,
							price: '',
						},
						{
							id: '8',
							name: 'Золотой дождь мне',
							check: true,
							price: '',
						},
						{
							id: '9',
							name: 'Фистинг анальный вам',
							check: true,
							price: '',
						},
						{
							id: '10',
							name: 'Фистинг анальный мне',
							check: true,
							price: '',
						},
						{
							id: '11',
							name: 'Фистинг классический',
							check: true,
							price: '',
						},
						{
							id: '12',
							name: 'Фото/Видео съёмка',
							check: true,
							price: '',
						},
						{
							id: '13',
							name: 'Виртуальный секс',
							check: true,
							price: '',
						},
						{
							id: '14',
							name: 'Секс по телефону',
							check: true,
							price: '',
						}
					],
				},
				{
					name: 'BDSM',
					bdsm: [
					{
							id: '1',
							name: 'Госпожа',
							check: true,
							price: '',
						},
						{
							id: '2',
							name: 'Рабыня',
							check: true,
							price: '',
						},
						{
							id: '3',
							name: 'Доминация',
							check: true,
							price: '',
						},
						{
							id: '4',
							name: 'Бандаж',
							check: true,
							price: '',
						},
						{
							id: '5',
							name: 'Порка',
							check: true,
							price: '',
						},
						{
							id: '6',
							name: 'Фетиш',
							check: true,
							price: '',
						},
						{
							id: '7',
							name: 'Трамплинг',
							check: true,
							price: '',
						},
						{
							id: '8',
							name: 'Ролевые игры',
							check: true,
							price: '',
						}
					],
				},
				{
					name: 'Массаж',
					massage: [
					{
							id: '1',
							name: 'Расслабляющий',
							check: true,
							price: '',
						},
						{
							id: '2',
							name: 'Эротический',
							check: true,
							price: '',
						},
						{
							id: '3',
							name: 'Профессиональный',
							check: true,
							price: '',
						},
						{
							id: '4',
							name: 'Урологический',
							check: true,
							price: '',
						}
					],
				}
			]
		}
	},
	methods: {
    setTel(event) {
      const val = event.target.value.replace(/[^0-9]/g, "");
			event.target.value = val
    },
		toggleShow(event) {
			const width = document.body.getBoundingClientRect().width
			if(width > 768) return
			const element = event.target
			if(element.classList.contains('is-hide')) {
				element.classList.remove('is-hide')
			} else {
				element.classList.add('is-hide')
			}
		}
  }
}
</script>

<style lang="scss">
	@import '@/styles/index';
	.service {
		&__body {
			display: block;
		}
		&__title {
			background-color: #8E7F7D;
			color: #FFF;
			font-size: 14px;
			line-height: 40px;
			border-radius: 9px;
			padding: 0 0 0 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			&:after {
				@media(max-width: 767px) {
					content: '';
					display: flex;
					background-image: url('@/assets/img/arrow-tab.svg');
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					width: 10px;
					height: 7px;
					position: absolute;
					top: 17px;
					right: 15px;
					transition: 0.3s;
				}
			}
			&.is-hide {
				& + .service__body {
					display: none;
					@media(min-width: 768px) {
						display: block;
					}
				}
				&:after{
					transform: rotate(180deg);
				}
			}
		}
		&__block {
			padding: 0 15px;
			@media (max-width: 767px) {
				margin-bottom: 1rem;
			}
		}
		&__subtitle {
			color: #2D2F3A;
			font-size: 18px;
			font-weight: 600;
			line-height: 120%;
			padding: 10px 0;
		}
	}
	.service-box {
		padding: 0 10px 0 5px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		justify-content: space-between;

		&__label {
			display: flex;
			align-items: flex-start;
			cursor: pointer;
		}
		&__name {
			color: #525665;
			font-size: 14px;
			line-height: 120%
		}
		&__input {
			appearance: none;
			width: 0;
			height: 0;
			opacity: 0;
		}
		&__checked {
			display: block;
			width: 16px;
			flex: 0 0 16px;
			max-width: 16px;
			margin-right: 10px;
			height: 16px;
			border: solid 1px #BDC1D1;
			border-radius: 4px;
			cursor: pointer;
			position: relative;
			margin-top: -2px;
		}
		&__input:checked ~ &__checked {
			&:after {
				content: '';
				background-image: url('@/assets/img/check.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				display: block;
				width: 10px;
				height: 10px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;

			}																									
		}
	}
	.service-prcie {
		position: relative;
		display: inline-flex;
		align-items: center;
		flex: 0 0 85px;
		max-width: 85px;
		input {
			border: solid 1px #BDC1D1;
			border-radius: 50px;
			line-height: 26px;
			font-size: 14px;
			padding: 0 20px 0 10px;
			max-width: 100%;
			&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
				appearance: none;
				display: none;
			}
		}
		&__symbol {
			display: block;
			width: 12px;
			height: 12px;
			position: relative;
			padding-right: 10px;
			&::after {
				content: '';
				display: block;
				//border-right: solid 1px;
				width: 2px;
				height: 100%;
				background-color: #333;
			}
			&::before {
				content: '';
				display: block;
				//border-right: solid 1px;
				width: 2px;
				height: 100%;
				background-color: #333;
				

				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%) rotate(90deg) ;
			}
		}
		&__rouble {
			font-family: $rouble;
			font-size: 14px;
			position: absolute;
			top: 50%;
			right: 5px;
			transform: translateY(-50%);
		}
	}
	.service-title {
		color: #2D2F3A;
		font-size: 24px;
		font-weight: 600;
		line-height: 120%;
		@media(max-width: 767px) {
			font-size: 16px;
		}
	}
	.load-block {
		&__mw {
			max-width: 85%!important;
			@media (max-width: 1024px) {
				max-width: 100%!important;
				margin: 0 auto!important;
			}
		}
	}
</style>