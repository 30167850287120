<script>
export default {
    name:'SettingSliderIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:23, h:23}
        }
    }
}
</script>

<template>
    <svg :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w + 1) + ' ' + (this.size.h + 1)" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 17.5C21 19.433 19.433 21 17.5 21C15.567 21 14 19.433 14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5Z" :stroke="this.color" stroke-width="1.5"/>
        <path d="M17.5 14H6.5C4.567 14 3 15.567 3 17.5C3 19.433 4.567 21 6.5 21H17.5C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14Z" :stroke="this.color" stroke-width="1.5"/>
        <path d="M3 6.5C3 8.433 4.567 10 6.5 10C8.433 10 10 8.433 10 6.5C10 4.567 8.433 3 6.5 3C4.567 3 3 4.567 3 6.5Z" :stroke="this.color" stroke-width="1.5"/>
        <path d="M6.5 3H17.5C19.433 3 21 4.567 21 6.5C21 8.433 19.433 10 17.5 10H6.5C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3Z" :stroke="this.color" stroke-width="1.5"/>
    </svg>
</template>
