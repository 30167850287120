<script>
import GirlListCategory from "@/components/ui/GirlListCategory.vue";
import OtzyvModal from "@/components/blocks/OtzyvModal.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import SliderInCategory from "@/components/blocks/SliderInCategory.vue";
import axios from "axios";
import Slider from "@/components/blocks/Slider.vue";
import sklonyator from 'sklonyator';
import AllTags from "@/components/ui/common/tags/AllTags.vue";
import QuizBlock from "@/components/blocks/QuizBlock.vue";
import NewProfilesBlock from "@/components/blocks/NewProfilesBlock.vue";

export default {
    name: 'CompilationTag',
    components: {
      NewProfilesBlock,
      Slider, SliderInCategory, UiLoader, OtzyvModal, GirlListCategory, sklonyator, AllTags, QuizBlock},
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            compilationRating: [],
            showLoader: false,
            title: '',
            city: '',
            category_id: '',
            otzyvModal: false,
            selectedTag: {}
        }
    },
    methods: {
        async getSelectionRating() {
            this.sklonyator()

            axios
                .get(this.apiUrl + 'api-girl/get-profiles-by-params'
                    + '&tag_id=' + this.$route.params.tag_id
                    + '&auth=' + this.user.username + ':' + this.user.auth_key
                    + '&limit=50'
                    + `&city_id=${this.user.city?.id || 1}`)
                .then((response) => {
                    this.compilationRating = response.data.data
                    this.showLoader = false
                }).catch(error => console.log(error))
        },
        async getSelectedTag() {
            axios
                .get(this.apiUrl + 'api-tag/get-tag-by-id'
                    + '&auth=' + this.user.username + ':' + this.user.auth_key
                    + '&id=' + this.$route.params.tag_id
                )
                .then((response) => {
                    this.selectedTag =  response.data
                    document.title = `Девушки с услугой: ${this.selectedTag.name} в ${this.city}`
                })
        },
        sklonyator(){
            if(this.user.city){
                this.city = sklonyator.incline(this.user.city.name);
            }

        }
    },
    mounted() {
        this.showLoader = true
        this.getSelectionRating()
        this.getSelectedTag()
    },
    computed: {
        userCity(){
            return this.$store.getters.getCity
        }
    },
    watch:{
        userCity: function () {
            this.showLoader = true
            this.compilationRating = []
            this.getSelectionRating()
            this.getSelectedTag()
        }
    }
}
</script>
<template>
    <main>
        <div class="block-offer">
            <div class="container">
                <div class="row">
                    <div class="col-12 title-cat roboto-bold p-3">
                        Девушки с тегом <span style="text-transform: lowercase;">{{ selectedTag.name }}</span>
                        <span> в {{this.city}}</span>
                    </div>
                </div>
                <ui-loader :show="this.showLoader" :preset="'block'"/>
                <div class="slider-offer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-3" v-for="p in this.compilationRating" :key="p.id">
                                <Slider :profile="p"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex mt-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <div v-for="p in this.pages" @click="showPage(p.page)" :class="p.class" :key="p.page">{{ p.name }}</div>
                    </div>
                </div> -->
            </div>
        </div>
      <div class="block-offer">
        <div class="container">
          <div class="block-offer__inner">
            <NewProfilesBlock
                categoryId=""
                titleCards=""
                flagFilter="Tag"
            />
          </div>
        </div>
      </div>

        <div class="block-offer">
			<quiz-block />
		</div>
        <div class="block-offer">
			<div class="container">
				<all-tags />
			</div>
		</div>
    </main>
</template>

<style scoped>
@media screen and (max-width: 767px) {
    main {
        margin-top: 30px;
    }
}
.title-cat{
    color: #2D2F3A;
    font-size: 28px;
}
</style>

