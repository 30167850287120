<script>
export default {
    name:'LikeIcon',
    props:{
        color:{
            type: String,
            default: '#fff'
        },
        size:{
            type: Object,
            default: {w:25, h:25}
        }
    }
}
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w + 3) + ' ' + (this.size.h + 3)"  fill="none">
        <circle cx="11" cy="14" r="11" fill="#FF4032" fill-opacity="0.46"/>
        <path d="M11.1623 10.4405L12.972 9.40969C15.055 8.22321 17.648 9.70122 17.6886 12.098V12.098C17.703 12.9533 17.3697 13.7777 16.7648 14.3825L11.1623 19.985L5.27318 14.0959C4.70534 13.528 4.38033 12.7618 4.36674 11.9589V11.9589C4.32622 9.56514 6.90144 8.03298 8.98579 9.21071L11.1623 10.4405Z" :stroke="this.color" :fill="this.color"/>
        <path d="M10.9164 10.8758L11.1633 11.0154L11.4098 10.875L13.2195 9.84415C14.9724 8.84569 17.1545 10.0895 17.1886 12.1065C17.2008 12.8262 16.9203 13.52 16.4113 14.0289L11.1623 19.2779L5.62674 13.7423C5.15059 13.2662 4.87806 12.6237 4.86667 11.9504C4.83269 9.94322 6.99206 8.65848 8.73982 9.64602L10.9164 10.8758Z" :stroke="this.color" stroke-opacity="2.54"/>
    </svg>
</template>
