<script>
import ExitButton from '../ui/ExitButton.vue';
export default {
  components: { ExitButton },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    buttonsDropdown: {
      type: Array,
    },
    isActivemenu:{
      type: Boolean
    }
  },
  setup() {
    return {
      isActiveBtn: false,
    };
  },
  methods: {
    changeColor(index) {
      return;
    },
  },
};
</script>

<template>
  <div
    class="col-auto header-admin__btn d-flex align-items-center position-relative"
    :class="{
      'header-admin__gradient': this.buttonsDropdown[index].showDropdown,
    }"
    @click="changeColor(index)"
  >
    <span class="header-admin__title">{{ item.title }}</span>
    <img
      class="header-admin__arrow"
      src="../../assets/img/arrow-bottom.svg"
      alt=""
    />

    <div class="header-admin__drop" v-if="item.showDropdown" @click.stop :class="{'header__step': index > 3 }">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="mb-0 text-white">{{ item.title }}</h3>
        <img
          @click="item.showDropdown = false"
          class="header-admin__arrow__bottom"
          src="../../assets/img/arrow-bottom.svg"
          alt=""
        />
      </div>
      <div class="d-flex gap-5 justify-content-between">
        <div class="d-flex flex-column" >
          <div class="header-admin__drop__block" v-for="(text,index) in item.entries" :key="index" @click.stop="$emit('closeDropDown', item, false)">
            <router-link class="header-admin__drop__link" v-if="!text.blank" :to="text.link" @click="$emit('clearMenu', false)">
              {{ text.text }}
            </router-link>
            <a :href="text.blank" target="_blank" v-else class="header-admin__drop__link">
             {{text.text}}
            </a>
          </div>
        </div>
        <!-- <div v-else class="d-flex flex-column">
          dabla
        </div> -->
        <a href="https://t.me/EgozaBiz" target="_blank">
          <img
            class="header-admin__drop__img"
            src="../../assets/img/egozasubsc.svg"
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/index';

.header-admin {
  &__btn {
    padding: 11px 13px 11px 24px;
    border-radius: 70px;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: 70px;
      z-index: -1;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      background: #413d51;
      @media (max-width: 1370px) {
        box-shadow: none;
      }
    }

    @media (max-width: 1370px) {
      padding: 0;
      box-shadow: none;
    }
  }
  &__gradient {
    background: linear-gradient(to right, #21045d 17%, #db0f00 100%);
    @media (max-width: 1370px) {
      background: none;
    }
  }
  &__title {
    font-size: 18px;
    margin-right: 18px;
    color: white;
    @media (max-width: 1370px) {
      display: none;
    }
  }
  &__drop {
    top: 0px;
    left: 0px;
    display: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    border-radius: 25px;
    padding: 11px 13px 13px 23px;
    min-width: 480px;
    width: 100%;
    z-index: 9999;
    position: absolute !important;
    background: linear-gradient(to right, #21045d 17%, #db0f00 100%);
    &.header__step{
      left: -100px;
    }
    @media (max-width: 1370px) {
      display: block ;
    }
    @media (max-width: 510px) {
      min-width: auto;
    }
    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      background: #413d51;
      z-index: -1;
      border-radius: 25px;
    }

    @media (max-width: 1370px) {
      position: static !important;
      background: transparent;
      box-shadow: none;
    }
    &__img {
      @media (max-width: 1370px) {
        display: none;
      }
    }

    cursor: default;
    & h3 {
      font-size: 18px;
    }
    .exit-btn,
    &__link {
      color: #bdc1d1;
      text-decoration: none;
      font-size: 18px;
      transition: 0.3s;
      &:hover {
        color: white;
      }
    }

  }
  &__arrow {
    cursor: pointer;

    @media (max-width: 1370px) {
      display: none;
    }
    &__bottom{
          transform: rotate(180deg);
          @media (max-width: 1370px) {
            display: none;
          }
    }
  }
}
</style>