<script>
import UiLoader from "@/components/ui/UiLoader.vue";
import ModalCard from "@/components/blocks/ModalCard.vue";
import CardWhite from "@/components/ui/CardWhite.vue";
import AdminUserReg from "@/components/blocks/AdminUserReg.vue";
import UserSelect from "@/components/ui/hint/UserSelect.vue";
import axios from "axios";
import UserForm from "@/pages/Admin/AdminSettings/user/UserForm.vue";
import AdminModalTable from "@/components/ui/AdminModalTable.vue";

export default {
  name: 'UserList',
  components: {
    AdminModalTable,
    UserForm,
    UserSelect,
    AdminUserReg,
    CardWhite,
    ModalCard,
    UiLoader
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      users: [],
      usersCount: 0,
      totalPages: '',
      currentPage: 1,
      filterUser: {
        id: null,
        username: null,
        offset: '',
        status: 10,
        limit: 20,
      },
      selectedUser: {},
      show: false,
      formBtn: true,
      toDel: false,
      loaderShow: false,
      formShow: false,
      openRegistration: false,
      arrowDisplay: false,
      isModalDel: false,
      isModalDetail: false,
      singleBilling: ''
    }
  },
  computed: {
    visiblePages() {
      const visiblePages = [];
      if (this.totalPages <= 5) {
        for (let i = 1; i <= this.totalPages; i++) {
          visiblePages.push(i);
        }
      } else {
        if (this.currentPage <= 4) {
          for (let i = 1; i <= 5; i++) {
            visiblePages.push(i);
          }
          visiblePages.push('...');
          visiblePages.push(this.totalPages);
        } else if (this.currentPage >= this.totalPages - 2) {
          visiblePages.push(1);
          visiblePages.push('...');
          for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
            visiblePages.push(i);
          }
        } else {
          visiblePages.push(1);
          visiblePages.push('...');
          for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
            visiblePages.push(i);
          }
          visiblePages.push('...');
          visiblePages.push(this.totalPages);
        }
      }
      return visiblePages;
    },
    currentPageClass() {
      return pageNumber => {
        return pageNumber == this.currentPage ? 'current-page' : ''
      }
    },
    arrowStyle() {
      if (this.currentPage == 1) {
        return 'display: none'
      }
    }
  },
  mounted() {
    this.fetchUsers(1)
    this.updateData()

  },
  methods: {
    cleanNumber(val) {
      if (val.match(/[^0-9\s]/g)) {
        return val.replace(/[^0-9\s]/g, '');
      }

      return val;
    },
    formatDate(t) {
      let myDate = new Date(t * 1000) // date object
      return ((myDate.getDate() < 10) ? "0" : "") + myDate.getDate() + "." + (((myDate.getMonth() + 1) < 10) ? "0" : "") + (myDate.getMonth() + 1) + "." + myDate.getFullYear();
    },
    getStatus(id) {
      if (parseInt(id) === 10) {
        return '<span class="text-success">Активен</span>'
      } else if (parseInt(id) === 5) {
        return '<span class="text-primary">ожидает подтверждения</span>'
      } else if (parseInt(id) === 0) {
        return '<span class="text-danger">Удален</span>'
      }
    },
    updateData() {
      let params = '&auth=' + this.user.username + ':' + this.user.auth_key;

      if (this.filterUser.id) {
        params += '&id=' + this.filterUser.id
      }
      if (this.filterUser.status) {
        params += '&status=' + this.filterUser.status
      }
      if (this.filterUser.username) {
        params += '&username=' + this.filterUser.username
      }

      if (this.filterUser.offset) {
        params += '&offset=' + this.filterUser.offset
      }
      if (this.filterUser.limit) {
        params += '&limit=' + this.filterUser.limit
      }
      axios
          .get(this.apiUrl + 'api-user/get-users-by-params' + params)
          .then((response) => {
            this.totalPages = Math.ceil(response.data.count / 20)
            this.users = response.data.data;
            this.usersCount = response.data.count

          })
    },
    fetchUsers(pageNumber) {
      this.currentPage = pageNumber
      if (this.currentPage == this.totalPages) {
        this.arrowDisplay = true
      } else {
        this.arrowDisplay = false
      }
      this.filterUser.offset = (pageNumber - 1) * 20;
      let params = '&auth=' + this.user.username + ':' + this.user.auth_key;
      if (this.filterUser.offset) {
        params += '&offset=' + this.filterUser.offset
      }
      if (this.filterUser.limit) {
        params += '&limit=' + this.filterUser.limit
      }
      if (this.filterUser.status) {
        params += '&status=' + this.filterUser.status
      }
      axios
          .get(this.apiUrl + 'api-user/get-users-by-params' + params)
          .then((response) => {
            this.users = response.data.data;
            this.usersCount = response.data.count
          })
    },
    nextPage() {
      if (this.currentPage == this.totalPages - 1) {
        this.arrowDisplay = true
      }
      this.currentPage++
      this.filterUser.offset = Number(this.filterUser.offset) + 20;
      this.updateData()
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
      if (this.currentPage < this.totalPages) {
        this.arrowDisplay = false
      }

      if (this.filterUser.offset <= 20) {
        this.filterUser.offset = 0;
      } else {
        this.filterUser.offset = Number(this.filterUser.offset) - 20;
      }

      this.updateData();
    },
    openForm(user) {
      this.selectedUser = user
      this.formShow = true
    },
    modalShow(user, del) {
      this.isModalDel = true
      if (del == 'del') {
        this.updateUser()
      } else {
        this.selectedUser = user
      }
    },
    updateUser() {
      this.isModalDel = false
      let deluser = Object.assign({}, this.selectedUser)
      deluser.status = 'del'
      let params = '&auth=' + this.user.username + ':' + this.user.auth_key;
      axios
          .post(this.apiUrl + 'api-user/update-user' + params, deluser)
          .then((response) => {
            if (response.data.status) {
              this.updateData()
            } else {

              this.error = response.data.error
            }
          })
    },
    openModalDetail(e, item) {
      if (window.innerWidth > 1050) return

      if (!e.target.closest('.example')) {
        this.singleBilling = item
        this.isModalDetail = true
      }
    },
  },
}
</script>

<template>
  <card-white v-if="!formShow">
    <form class="mt-5" @submit.prevent="Profiles">
      <div class="flex-wrap form-panel gap-2 position-relative">
        <div class="add-profile" @click="this.openRegistration = !this.openRegistration"><i
            class="bi bi-plus-lg"></i></div>
        <div class="col-11  p-3 settings-add ">
          <div class="row justify-content-center mb-3">
            <div class="col-12 col-sm-10" v-if="openRegistration">
              <admin-user-reg :role="'girl'" @register="this.updateData"></admin-user-reg>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="settings-add__item col-6 col-sm-5 col-xl-2">
              <select v-model="this.filterUser.status">
                <option value="10">Активен</option>
                <option value="5">Ожидает подтверждения</option>
                <option value="del">Удален</option>
              </select>
            </div>
            <div class="settings-add__item col-6 col-sm-5 col-xl-1">
              <label>
                <input type="text" v-model="this.filterUser.id"
                       @keyup="this.filterUser.id = cleanNumber(this.filterUser.id)"
                       placeholder="ID"
                       id="idProfile">
              </label>
            </div>
            <div class="settings-add__item col-12 col-sm-10 col-xl-2 mt-2 mt-xl-0">
              <label>
                <user-select
                    :labelText="''"
                    @selected="(u) => {this.filterUser.username = u.username}"
                    :placeholder="'Логин'"
                />
              </label>
            </div>
            <div class="col-12 col-sm-10 col-xl-1 mt-2 mt-md-0">
              <button type="submit" class="submit-height " @click="this.updateData"><i
                  class="bi bi-funnel"></i>
              </button>
            </div>

          </div>
        </div>
      </div>
    </form>

    <div class="table-cont mt-3">
      <table class="col-11 responsive-table">
        <thead>
        <tr>
          <td class="text-white">ID</td>
          <td class="text-white">Логин</td>
          <td class="text-white">Имя</td>
          <td class="text-white">email</td>
          <td class="text-white">Создан</td>
          <td class="text-white">Статус</td>
          <td class="text-white"></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in users" :key="item.id" @click="openModalDetail($event,item)" class="settings-table__row">
          <td id="id" class="settings-table__item text-white">{{ item?.id }}</td>
          <td id="username" class="settings-table__item text-white">{{ item?.username }}</td>
          <td id="name" class="settings-table__item text-white">{{ item?.profile?.name }}</td>
          <td id="email" class="settings-table__item text-white">{{ item?.email }}</td>
          <td id="cerate_add" class="settings-table__item text-white">{{ this.formatDate(item?.created_at) }}</td>
          <td id="status" class="settings-table__item text-white" v-html="this.getStatus(item?.status)"></td>
          <td id="btns" class="settings-table__item trash-view text-white justify-content-end">
            <button @click="this.openForm(item)">
              <i class="bi bi-eye"></i>
            </button>
            <button>{{ }}</button>
            <button @click.stop="modalShow(item); isModalDetail = false;">
              <i class="bi bi-trash"></i>
            </button>
          </td>
          <Transition name="fade">
            <div class="example" v-if="isModalDel">
              <div class="example__block">
                <p class="example__text">Вы дествительно хотите удалить?</p>
                <button class="example__yes" @click="modalShow(item,'del')">Да</button>
                <button class="example__no" @click="isModalDel = false">Нет</button>
              </div>
            </div>
          </Transition>
        </tr>
        </tbody>
      </table>
    </div>

    <AdminModalTable
      v-if="isModalDetail"
      :tableContent="[
          {title: 'ID', content: singleBilling.id},
          {title: 'Логин', content: singleBilling.username},
          {title: 'Имя', content: singleBilling.profile?.name},
          {title: 'email', content: singleBilling.email},
          {title: 'Создан', content: this.formatDate(singleBilling?.created_at)},
          {title: 'Статус', content: singleBilling.status}
      ]"
      @closeModal="isModalDetail = false"
    >
      <template v-slot:buttons>
        <button @click="this.isModalDetail = false; this.openForm(singleBilling)">
          <i class="bi bi-eye"></i>
        </button>
        <button>{{ }}</button>
        <button @click="modalShow(singleBilling); this.isModalDetail = false">
          <i class="bi bi-trash"></i>
        </button>
      </template>
    </AdminModalTable>

    <div class="d-flex justify-content-center mt-4 align-items-center">
      <div>
        <button :style="arrowStyle" class="arrow-page" @click="prevPage"><i class="bi bi-arrow-left-circle"></i>
        </button>
      </div>
      <div class="wrapper-numbers text-center">
        <button class="number-page"
                v-for="pageNumber in visiblePages" :key="pageNumber"
                @click="fetchUsers(pageNumber)"
                :class="currentPageClass(pageNumber)"
        >
                <span :ref="pageNumber" v-if="pageNumber !== '...'">
                      {{ pageNumber }}
                </span>
          <span v-else>
                      ...
                </span>
        </button>
      </div>
      <div>
        <button :class="{'arrow-none': arrowDisplay}" class="arrow-page" @click="nextPage"><i
            class="bi bi-arrow-right-circle"></i></button>
      </div>
    </div>
  </card-white>
  <card-white v-else>
    <div class="row">
      <div class="col-12 text-end">
        <i @click="formShow = false" class="bi bi-x-lg"></i>
      </div>
      <div class="col-12">
        <user-form :user-data="this.selectedUser" @userUpdated="this.formShow = false; this.updateData"/>
      </div>
    </div>
  </card-white>
  <!--Модальное окно, удаление-->
  <modal-card :show="this.show">
    <div>
      <div class="row">
        <div class="col-12 text-end">
          <i class="bi bi-x-lg" @click="hideDialog"></i>
        </div>
      </div>
      <div class="modal-body">
        <p>Уверены, что хотите удалить {{ this.profile.user_id }}</p>
      </div>
      <div class="modal-footer mt-3 justify-content-between">
        <button type="button" @click="hideDialog" class="btn btn-modal">Закрыть</button>
        <button type="button" @click="deleteProfile" class="btn btn-modal">Удалить</button>
      </div>
    </div>
  </modal-card>

</template>

<style scoped lang="scss">
.example {
  &__yes {
    color: green !important;
    font-size: 20px;
    margin-right: 10px;
    transition: .3s;
    font-weight: 600;

    &:hover {
      color: rgb(7, 231, 7);
    }
  }

  &__no {
    color: rgb(238, 61, 61) !important;
    font-size: 20px;
    transition: .3s;
    font-weight: 600;

    &:hover {
      color: rgb(255, 2, 2);
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.07);
  z-index: 9999;


  &__block {
    max-width: 300px;
    background: white;
    position: relative;
    width: 100%;
    border-radius: 9px;
    padding: 50px 5px 20px 5px;
    text-align: center;
  }

  &__text {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 117%;
    text-align: center;
    color: #525665;
    margin-bottom: 10px;
  }
}

.arrow-none {
  display: none;
}

.list-item {
  border: 1px solid #2C2F3C;
  background: #2C2F3C;
  border-radius: 7px;
  color: #fff;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
}

.number-page.current-page {
  font-weight: 700;
}

.settings-add {
  border: solid 1px #2C2F3C;
  border-radius: 10px;
  background: #2C2F3C;


  button {
    background: #BDC1D1;
    display: inline-block;
    border: solid 1px transparent;
    border-radius: 4px;
    color: #2C2F3C;
    transition: 0.3s;

    &:hover {
      background: #BDC1D1;
      border-color: #BDC1D1;
      color: #2C2F3C;
      font-weight: 600;
    }
  }

  .invalid {
    border: 1px solid red;
  }

  label {
    width: 100%;
  }

  input {
    background-color: #dedede;
    width: 100%;
    border: 1px solid #BDC1D1;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  select {
    width: 100%;
    background-color: #dedede;
    border: 1px solid #BDC1D1;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

#btns {
  display: flex;
}

.settings-table {
  color: #fff;

  &__head {
    background-color: #2C2F3C;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #7e7e7e7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__row {
    background-color: #2C2F3C;
    position: relative;


    &:nth-child(odd) {
      background-color: #2C2F3C;
    }

    button {
      color: #fff;
    }
  }

  &__row:hover {
    background-color: #524B5F;
  }

  &__item {
    border-bottom: solid 1px #7e7e7e7e;
  }
}

.submit-height {
  height: 40px;
  width: 100%;
}

button.btn-modal {
  background: #dedede !important;
  display: inline-block;
  border: solid 1px transparent;
  border-radius: 4px;
  color: #000;
  transition: 0.3s;
}

.arrow-page {
  font-size: 25px;
  font-weight: 400;
  color: #2C2F3C;
}

.number-page {
  font-size: 16px;
}

.add-profile {
  border-radius: 50%;
  overflow: hidden;
  background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
  color: #fff;
  font-weight: 700;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.form-panel,
.table-cont {
  display: flex;
  justify-content: end;
  width: 100%;
  overflow-x: auto;
}

.responsive-table {
  border-collapse: collapse;
}

.responsive-table thead {
  background-color: #2C2F3C;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #7e7e7e7e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.responsive-table th {
  background-color: #f2f2f2;
  color: #333;
}

.responsive-table td {
  padding: 0px 10px;
  text-align: center;
  line-height: normal;
}

thead tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

thead tr:last-child td:last-child {
  border-top-right-radius: 10px;
}

@media (max-width: 1050px) {
  .responsive-table > thead td:nth-child(3),
  .responsive-table > thead td:nth-child(4),
  .responsive-table > thead td:nth-child(7) {
    display: none;
  }

  #btns,
  #name,
  #email {
    display: none;
  }

  thead tr:last-child td:nth-child(6) {
    border-top-right-radius: 10px;
  }
}

@media (max-width: 875px) {
  .form-panel,
  .table-cont {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .trash-view {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 560px) {
  .responsive-table > thead td:nth-child(5) {
    display: none;
  }

  #cerate_add {
    display: none;
  }

  .number-page {
    font-size: 16px;
  }

  .wrapper-numbers {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 410px) {
  #btns {
    padding: 5px;
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .arrow-page {
    font-size: 22px;
    padding: 0 4px;
  }
}
</style>