export default {
    methods:{
        pickPage(namePath) {
            this.$router.push({
                name: namePath,
                query: {
                    search: namePath,
                },
            });
            this.popupPagesShow = false;
            this.popupPagesShowMobile = false;
        },

        hideDialog() {
            this.show = false;
            this.$emit('successLogin')
        },

        addProfile(){
            if(this.user.role.item_name === 'girl'){
                window.location.href = '/questionnaire'
            }
            if(this.user.role.item_name === 'admin'){
                window.location.href = '/admin/profiles'
            }
            if(this.user.role.item_name === 'guest' || this.user.role.item_name === 'user'){
                this.show = true
            }
        },
        pickCity(namePath) {
            this.city = namePath;
            this.popuCitiesShow = false;
            this.popuCitiesShowMobile = false;
        },
        getCategories() {
            this.list = this.$router.options.routes.filter((route) => route.name);
        },
        popupPagesHide() {
            this.popupPagesShow = false;
        },
        popupCitiesHide() {
            this.popuCitiesShow = false;
        },
        menuHide() {
            this.menuShow = false;
        },
        popupCitiesHideMobile() {
            this.popuCitiesShowMobile = false;
        },
        popupPagesHideMobile() {
            this.popupPagesShowMobile = false;
        },
        showMenu() {
            this.isShowMenu = true;
        },
        swipingMenu(
            event,
            element,
            elementHeight,
            startY,
            deltaY,
            classNonTransition,
            swipeResult,
            touchEnd
        ) {
            if (classNonTransition) element.classList.add("non-transition");
            else element.classList.remove("non-transition");
            if (deltaY > 25 || deltaY < -25) element.style.bottom = `-${deltaY}px`;
            if (touchEnd && swipeResult === "Up" && (deltaY > 25 || deltaY < -25)) {
                this.isShowMenu = true;
                element.style.bottom = "0";
            } else if (touchEnd && swipeResult === "Down" && (deltaY > 25 || deltaY < -25)) {
                this.isShowMenu = false;
                element.style.bottom = "0";
            }
        },
        openSubmenu(i, nav) {
            const currentRef = `userMenuLink_${i}`;
            const element = this.$refs[currentRef][0];
            const isChild = element.querySelectorAll(".header-user-menu__submenu li").length
                ? true
                : false;
            if (!isChild) {
                this.$router.push(nav);
                return;
            }
            if (element.classList.contains("is-active")) {
                element.classList.remove("is-active");
            } else {
                element.classList.add("is-active");
            }
        },
        openSubmenuMobile() {
            const element = event.target;
            const elementWrap = element.closest("li");
            if (elementWrap.classList.contains("is-active")) {
                elementWrap.classList.remove("is-active");
            } else {
                elementWrap.classList.add("is-active");
            }
        },
    }
}