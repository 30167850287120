<script>

import axios from "axios";

export default {
    name: 'OtzyvModal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        profile_id: {
            type: Number,
            require: true
        },
        user_id: {
            type: Number,
            require: true
        }
    },
    methods: {
        hideDialog() {
            this.$emit('hideDialog');
        },

        updateOtzyv() {
            if (this.otzyv.rating === 0) {
                this.error = 'Оставьте Вашу оценку'
            }
            this.otzyv.user_id = this.user_id
            this.otzyv.profile_id = this.profile_id
            this.otzyv.stars = this.otzyv.rating
            axios
                .post(this.apiUrl + 'api-feedback/set-feedback'
                    + '&auth=' + this.user.username + ':' + this.user.auth_key, this.otzyv)
                .then((response) => {
                    if (response.data.status) {
                        this.hideDialog()
                    }
                })
        },


        reitingStar(id) {

            for (let i = 1; i <= 5; i++) {
                let el = document.getElementById('otzyvModalStar' + i)
                el.style.fill = '#D9D9D9'
            }

            for (let i = 1; i <= id; i++) {
                let el = document.getElementById('otzyvModalStar' + i)
                el.style.fill = '#FFC804'

                this.otzyv.rating = i
            }

        }
    },

    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            textError: '',
            error: '',
            otzyv: {
                rating: 0,
                description: '',
                photo: null,
                once: null
            },

        }
    }
}
</script>

<template>

    <div class="dialog" v-if="show">
        <div @click.stop class="dialog-content">
            <div class="row">
                <div class="col-12 text-end">
                    <i class="bi bi-x-lg" @click="hideDialog"></i>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <h3 class="title-otzyv">Оставить отзыв</h3></div>
                <div class="col-12 mt-3 ms-0">
                    <input class="w-100 p-3" :value="this.user.profile.name">
                </div>
            </div>
            <div class="row justify-content-center ps-3">
                <div class="col-12 text-center text-md-start mt-3 modal-star">
                    <svg class="" @mouseover="reitingStar(1)" id="otzyvModalStar1" xmlns="http://www.w3.org/2000/svg"
                         width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                        <path
                            d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                    </svg>
                    <svg @mouseover="reitingStar(2)" id="otzyvModalStar2" xmlns="http://www.w3.org/2000/svg"
                         width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                        <path
                            d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                    </svg>
                    <svg @mouseover="reitingStar(3)" id="otzyvModalStar3" xmlns="http://www.w3.org/2000/svg"
                         width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                        <path
                            d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                    </svg>
                    <svg @mouseover="reitingStar(4)" id="otzyvModalStar4" xmlns="http://www.w3.org/2000/svg"
                         width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                        <path
                            d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                    </svg>
                    <svg @mouseover="reitingStar(5)" id="otzyvModalStar5" xmlns="http://www.w3.org/2000/svg"
                         width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                        <path
                            d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                    </svg>
                </div>
            </div>
            <div class="row ms-0">
                <div class="col-12 mt-3">
                    <select v-model="otzyv.photo" name="photo" class="w-100 p-3">
                        <option :value="null" selected="selected">Фото соответствует?</option>
                        <option value="1">Да</option>
                        <option value="0">Нет</option>
                    </select>
                </div>
                <div class="col-12 mt-3 ms-0">
                    <select v-model="otzyv.once" name="" class="w-100 p-3">
                        <option value="null" selected="selected">Принимает в апартаментах одна?</option>
                        <option value="1">Да</option>
                        <option value="0">Нет</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <textarea class="mt-3 w-100" type="text" rows="6" v-model="otzyv.description"
                          placeholder="Введите текст сюда. Запрещаются оскорбления и откровенная агрессия.Такие коментарии публиковаться не будут">
            </textarea>
            </div>

            <div class="row">
                <div class="col-12">
                    <button class="btn btn-gradient w-100 p-3 mt-3 hover-btn" @click="updateOtzyv">Отправить</button>
                </div>
                <div class="col-12 error-text">
                    {{ this.error }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dialog {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    z-index: 999;
}

.dialog-content {
    margin: auto;
    padding: 15px 60px;
    border-radius: 24px;
    background: #A29A9D;
    color: #fff;
    background-repeat: no-repeat;
    background-position: top right;
    max-width: 600px;
    border: 1px solid #2D2E2E;
}

textarea {
    border: solid 1px #BDC1D1;
    border-radius: 10px;
    padding: 0 15px;
    color: #333;
}

input {
    border: solid 1px #BDC1D1;
    border-radius: 12px;
    background: #fff;
}

select {
    border: solid 1px #BDC1D1;
    border-radius: 12px;
    background: linear-gradient(180deg, #EEE 0%, #CFCFCF 100%);

}

.title-otzyv {
    font-weight: 700;
}

.btn-gradient {
    background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%)) !important;
    color: #fff;
    border: none;
    border-radius: 14px;
    font-size: 16px;
}

.btn-gradient:hover{
    box-shadow: 0 0 5px rgba(0,0,0,.9);
}

.error-text {
    color: #980000;
    font-size: 16px;
}


</style>