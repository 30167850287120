<script setup>
import OrderBackwardButton from '@/components/order_modal/OrderBackwardButton.vue';
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
import { ref } from 'vue';
import { onMounted } from 'vue';

const props = defineProps({
    initialExpectations: {
        type: [String, null],
        required: true,
        default: null,
    },
});

const emit = defineEmits(['next-step']);
const currentExpectations = ref(null);

onMounted(() => {
    currentExpectations.value = props.initialExpectations;
});
</script>

<template>
    <div class="order-expectations">
        <div class="order-expectations__question mb-4">
            <p>
                Опишите, что ожидаете от встречи. <br />
                Не стесняйтесь, девушки должны быть готовыми к вашей встрече.
            </p>
        </div>
        <div class="order-expectations__content d-flex flex-column gap-3">
            <div class="order-expectations__description">
                <textarea
                    class="p-2"
                    name="order-expectations"
                    id="order-expectations__textarea"
                    v-model="currentExpectations"
                    maxlength="200"
                ></textarea>
                <div class="order-expectations__caution">
                    Внимание! Не допкускается не цензурная брань, оскробления по рассовому и религиозному признаку!
                </div>
            </div>
            <div class="order-expectations__buttons ms-auto">
                <OrderBackwardButton
                    @click="emit('next-step', { expectations: currentExpectations, forward: false })"
                />
                <OrderNextButton
                    class="h-100"
                    @click="emit('next-step', { expectations: currentExpectations, forward: true })"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-expectations {
    .order-expectations__question {
        font-size: 16px;
        line-height: 20px;
    }
    .order-expectations__content {
        .order-expectations__description {
            width: 100%;
            textarea {
                width: 100%;
                min-height: 70px;
                font-size: 14px;
                border-radius: 12px;
            }
            .order-expectations__caution {
                font-size: 12px;
            }
        }
    }
}
</style>
