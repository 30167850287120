<script setup>
import OrderPreviewUser from '@/components/blocks/OrderPreviewUser.vue';
import OrderModal from '@/components/order_modal/OrderModal.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

const store = useStore();
const user = store.getters.getUser;
const apiUrl = store.getters.getApiUrl;
const isShowOrderModal = ref(false);
const orderList = ref([]);
const statusList = ref([]);
const cityMap = ref(new Map());
const statusMap = ref(new Map());
const typeMap = new Map([
    ['invite', 'Выезд'],
    ['apartments', 'Апартаменты'],
    ['escort', 'Сопровождение'],
]);

onMounted(async () => {
    statusList.value = await getOrderStatusList();
    orderList.value = await getOrderList();
    const cities = await getCities();
    cities.forEach((city) => {
        cityMap.value.set(city.id, city);
    });
    statusList.value.forEach((status) => {
        statusMap.value.set(status.id, status.name);
    });
});

const onCreateOrder = async (status) => {
    if (status) {
        orderList.value = await getOrderList();
    }
};

const getOrderStatusList = async () => {
    const { data } = await axios
        .get(apiUrl + 'api-order/get-status-list', {
            params: {
                auth: user.username + ':' + user.auth_key,
            },
        })
        .catch((error) => {
            console.error(error.message);
        });

    if (data) {
        return data.data;
    } else return [];
};

const getOrderList = async () => {
    const { data } = await axios
        .get(apiUrl + 'api-order/get-orders-by-user-id', {
            params: {
                auth: user.username + ':' + user.auth_key,
                user_id: user.user_id,
            },
        })
        .catch((error) => {
            console.error(error.message);
            return error;
        });
    if (Array.isArray(data?.data)) {
        return data.data.reverse();
    } else return [];
};

const getCities = async () => {
    const { data } = await axios
        .get(apiUrl + 'api/get-cities', {
            params: {
                auth: user.username + ':' + user.auth_key,
            },
        })
        .catch((error) => {
            console.error(error.message);
        });
    if (data !== null && data !== undefined) {
        return data;
    } else return [];
};

const cancelOrder = async (orderId, statusId, index) => {
    const { data } = await axios
        .post(
            apiUrl + 'api-order/update-status',
            {
                order_id: orderId,
                status_id: statusId,
            },
            {
                params: {
                    auth: user.username + ':' + user.auth_key,
                },
            }
        )
        .catch((error) => {
            console.error(error.message);
        });

    if (data?.status) {
        orderList.value.splice(index, 1);
    }
};
</script>
<template>
    <div class="user-orders pt-2 pe-2 pb-2 ps-2 pt-sm-4 pe-sm-4 pb-sm-4 ps-sm-4">
        <div class="pb-4">
            <h1 class="user-orders__title">Заказы</h1>
        </div>
        <div class="d-flex justify-content-start pb-3">
            <button
                class="user-orders__create-button"
                @click="isShowOrderModal = true"
            >
                Создать
            </button>
        </div>
        <div class="user-orders__order-list__wrapper pt-2 pb-2 ps-2 pe-2 pt-md-3 pb-md-3 ps-md-3 pe-md-3">
            <div
                v-if="!orderList.length"
                class="user-orders__order-list__message"
            >
                У Вас нет созданных заказов.
            </div>
            <div class="user-orders__order-list d-flex flex-column gap-3">
                <TransitionGroup name="bounce">
                    <OrderPreviewUser
                        v-for="(order, index) in orderList"
                        :key="`key_${order.id}`"
                        :order-data="order"
                        :status-list="statusList"
                        :city-name="cityMap.get(order.city_id)?.name || ''"
                        :type-name="typeMap.get(order.type) || ''"
                        @cancel-order="cancelOrder(order.id, 2, index)"
                    />
                </TransitionGroup>
            </div>
        </div>
    </div>
    <OrderModal
        v-if="isShowOrderModal"
        @hide-modal="isShowOrderModal = false"
        @create-order="(status) => onCreateOrder(status)"
    ></OrderModal>
</template>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.75s;
}

.bounce-leave-active {
    animation: bounce-in 0.75s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
.user-orders {
    .user-orders__title {
        font-size: 24px;
        line-height: 54px;
        font-weight: bold;
    }
    .user-orders__create-button {
        background-color: #bdc1d1;
        border-radius: 30px;
        padding: 10px 30px;
        color: #2d2f3a;
        font-size: 14px;

        &:hover {
            background-color: #a3a7b4;
        }
    }

    .user-orders__order-list__wrapper {
        background-color: white;
        border-radius: 12px;

        .user-orders__order-list__message {
            font-size: 20px;
        }
    }
}
</style>
