<script>
export default {
    name:'StatIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:23, h:23}
        }
    }
}
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w + 1) + ' ' + (this.size.h + 1)" fill="none">
        <path d="M1 11.5C1 6.78595 1 4.42893 2.46447 2.96447C3.92893 1.5 6.28595 1.5 11 1.5C15.714 1.5 18.0711 1.5 19.5355 2.96447C21 4.42893 21 6.78595 21 11.5C21 16.214 21 18.5711 19.5355 20.0355C18.0711 21.5 15.714 21.5 11 21.5C6.28595 21.5 3.92893 21.5 2.46447 20.0355C1 18.5711 1 16.214 1 11.5Z" :stroke="this.color" stroke-width="1.5"/>
        <path d="M6 17.5V8.5" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M11 17.5V5.5" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M16 17.5V12.5" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>
