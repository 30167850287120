<script setup>
import GradientButton from '@/components/promo/GradientButton.vue';

import { ref, watch } from 'vue';
import { useScroll } from '@vueuse/core';

const textBlock = ref(null);
const fireIcon = ref(null);
const yScroll = useScroll(window).y;

const moveText = (percent) => {
    textBlock.value.animate([{ backgroundPosition: `-${percent}% center` }], 2000);
    textBlock.value.style.backgroundPosition = `-${percent}% center`;
};

const moveFire = (percent) => {
    const blockHeight = textBlock.value.getBoundingClientRect().height;
    fireIcon.value.style.transition = 'transform 2s cubic-bezier(.13,1.04,.58,1.13)';
    const yScale = Math.random() * (2.1 - 0.8) + 0.8;
    const xScale = Math.random() * (1.8 - 0.8) + 0.8;

    fireIcon.value.style.transform = `translateY(${
        blockHeight * 0.01 * percent
    }px) scaleX(${xScale}) scaleY(${yScale})`;
};

//watchers
watch(yScroll, (newValue, oldValue) => {
    const { innerHeight } = window;
    const blockOffset = textBlock.value.getBoundingClientRect().y;
    const blockOffsetBottom = textBlock.value.getBoundingClientRect().bottom;
    const blockHeight = textBlock.value.getBoundingClientRect().height;
    const rangeHeight = blockHeight + 0.6 * innerHeight;

    if (
        (innerHeight >= blockOffset || innerHeight * 0.3 >= blockOffsetBottom) &&
        (blockOffset > 0 || blockOffsetBottom > 0)
    ) {
        const completed = rangeHeight - blockOffsetBottom + innerHeight * 0.3;
        const percent = completed / (rangeHeight * 0.01);

        if (percent >= 0 && percent <= 100) {
            //чтобы концы текста полностью анимировались
            if (percent <= 10) {
                moveText(0);
            } else if (percent >= 90) {
                moveText(100);
            } else {
                moveText(percent);
            }
            moveFire(percent);
        }
    }
});
</script>

<template>
    <section class="promo-design__container position-relative">
        <div>Дизайн и разработка</div>
        <div
            ref="textBlock"
            class="promo-design__description d-inline"
        >
            <h2 class="d-inline">
                Эффектно и эффективно занимаемся дизайном и разработкой,
                <span class="promo-design__animated-text">
                    каждый наш сайт уникален, мы продумали все до мелочей, а главное позаботились над удобством и
                    простотой использования
                </span>
            </h2>
        </div>
        <div class="promo-design__button">
            <GradientButton
                @click="$emit('fill_questionnaire_clicked')"
                class="mx-auto"
                >Заполнить анкету</GradientButton
            >
        </div>
        <div
            ref="fireIcon"
            class="promo-design__fire-icon position-absolute"
        >
            <img
                class="img-fluid"
                src="/img/fire_sm.png"
                alt=""
            />
        </div>
    </section>
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-design__container {
    margin-bottom: 95px;
    > div:first-child {
        @include text-regular-x-small;
        margin-bottom: 25px;
    }

    .promo-design__description {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        font-style: normal;

        h2 {
            font-weight: 700;
            font-size: 32px;
            line-height: 41px;
            font-style: normal;
        }

        background: linear-gradient(to right, #ffffff, #ffffff1f);
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .promo-design__button {
            padding-top: 20px;
            width: 175px;
        }
    }
    .promo-design__fire-icon {
        width: 50px;
        top: 0;
        right: 15px;
    }
}

@include media-breakpoint-up(md) {
    .promo-design__container {
        margin-bottom: 170px;
        > div:first-child {
            @include text-regular-large;
            margin-bottom: 25px;
        }
        .promo-design__description {
            @include text-bold-medium;
            h2 {
                @include text-bold-medium;
            }
        }
        .promo-design__button {
            padding-top: 20px;
            width: 200px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-design__container {
        margin-bottom: 240px;
        > div:first-child {
            @include text-regular-large;
        }
        .promo-design__description {
            @include text-h2-large;
            h2 {
                @include text-h2-large;
            }
        }
    }
}
</style>
