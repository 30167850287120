<script>
import axios from 'axios';
import MobileStreamCV from '@/components/mobile_streams/MobileStreamCV.vue';
import MobileStreamPost from '@/components/mobile_streams/MobileStreamPost.vue';

export default {
    name: 'mobile-streams',
    components: { MobileStreamPost, MobileStreamCV },
    data() {
        return {
            isMobile: this.$store.getters.getIsMobile,
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            currentTab: 'cvs',
            videos: [],
            posts: [],
            itemsLimit: 10,
            isMoreVideos: false,
            isMorePosts: false,
            eventsQueue: [],
        };
    },

    methods: {
        async getPosts(offset = null) {
            const { data, status } = await axios
                .get(this.apiUrl + 'api-profile-post/get-all-posts', {
                    params: {
                        auth: this.user.username + ':' + this.user.auth_key,
                        limit: this.itemsLimit,
                        offset,
                        city_id: this.user?.city?.id || 1,
                    },
                })
                .catch((error) => {
                    console.error(error.message);
                    return error;
                });
            if (status) {
                return data.data;
            } else return undefined;
        },

        async getVideoCVs(offset = null, count = this.itemsLimit) {
            const { data, status } = await axios
                .get(this.apiUrl + 'api-girl/get-all-video', {
                    params: {
                        auth: this.user.username + ':' + this.user.auth_key,
                        limit: count,
                        offset,
                        city_id: this.user?.city?.id || 1,
                    },
                })
                .catch((error) => {
                    console.error(error.message);
                    return error;
                });
            if (status) {
                return data.data;
            } else return undefined;
        },

        async changeTab(tabName) {
            if (tabName !== this.currentTab) {
                if (tabName === 'cvs') {
                    this.currentTab = 'cvs';
                    if (!this.videos?.length) {
                        await this.fetchContent();
                    }
                } else if (tabName === 'posts') {
                    this.currentTab = 'posts';
                    if (!this.posts?.length) {
                        await this.fetchContent();
                    }
                }
            }
        },

        async fetchContent() {
            switch (this.currentTab) {
                case 'cvs':
                    const newVideos = await this.getVideoCVs(this.videos?.length || 0);
                    if (Array.isArray(newVideos)) {
                        this.videos.push(...newVideos);
                        this.isMoreVideos = newVideos.length == this.itemsLimit;
                    } else this.isMoreVideos = false;
                    break;

                case 'posts':
                    const newPosts = await this.getPosts(this.posts?.length || 0);
                    if (Array.isArray(newPosts)) {
                        this.posts.push(...newPosts);
                        this.isMorePosts = newPosts.length == this.itemsLimit;
                    } else this.isMorePosts = false;
                    break;

                default:
                    break;
            }
        },

        updateEventsQueue() {
            this.eventsQueue.push(async () => {
                return await this.onCanNotShowCV();
            });

            //стартуем очередь, если таска только одна
            //иначе считаем, что уже запущена очередь выполнения
            if (this.eventsQueue.length === 1) {
                this.runEventsQueue();
            }
        },

        async runEventsQueue() {
            await this.eventsQueue[0]();
            //дожидаемся выполнения, удаляем из очереди
            this.eventsQueue.splice(0, 1);
            if (this.eventsQueue.length >= 1) {
                //запустим первую таску:
                await this.runEventsQueue();
            }
        },

        async onCanNotShowCV() {
            //загружаем одну визитку взамен недоступной
            const newVideos = await this.getVideoCVs(this.videos.length, 1);

            if (Array.isArray(newVideos)) {
                this.videos.push(...newVideos);
                this.isMoreVideos = newVideos.length == 1;
            } else this.isMoreVideos = false;
        },
    },
    computed: {
        isShowMoreButton() {
            return (
                (this.currentTab === 'cvs' && this.isMoreVideos) ||
                (this.currentTab === 'posts' && this.isMorePosts) ||
                false
            );
        },
    },
    watch: {
        'user.city.id': {
            async handler(newCity) {
                if (newCity) {
                    this.videos = [];
                    this.posts = [];
                    await this.fetchContent();
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<template>
    <KeepAlive>
        <main>
            <div
                v-if="isMobile"
                class="mobile-streams__wrapper"
            >
                <div class="mobile-streams__container mx-auto">
                    <div class="mobile-streams__menu d-flex justify-content-between align-items-center">
                        <button
                            class="menu__item flex-grow-1 text-center"
                            :class="currentTab === 'cvs' ? 'menu__item--active' : ''"
                            @click="changeTab('cvs')"
                        >
                            Видеовизитки
                        </button>
                        <button
                            class="menu__item flex-grow-1 text-center"
                            :class="currentTab === 'posts' ? 'menu__item--active' : ''"
                            @click="changeTab('posts')"
                        >
                            Публикации
                        </button>
                    </div>
                    <div class="mobile-streams__content">
                        <template v-if="currentTab === 'cvs'">
                            <div class="content__video-cvs d-flex flex-column gap-4">
                                <MobileStreamCV
                                    v-for="(videoCV, index) in videos"
                                    :key="index + '_' + videoCV.id"
                                    :video-c-v="videoCV"
                                    @can-not-show="() => updateEventsQueue()"
                                />
                            </div>
                        </template>

                        <template v-if="currentTab === 'posts'">
                            <div class="content__posts d-flex flex-column gap-4">
                                <MobileStreamPost
                                    v-for="(post, index) in posts"
                                    :post="post"
                                    :key="index + '_' + post.id"
                                />
                            </div>
                        </template>
                    </div>
                    <div
                        v-if="isShowMoreButton"
                        class="mobile-streams__load-more mt-4 mb-4 d-flex justify-content-center align-items-center"
                    >
                        <button
                            class="load-more__button"
                            @click="fetchContent"
                        >
                            Загрузить еще
                        </button>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="mobile-streams__caution d-flex align-items-center justify-content-center"
            >
                <div class="text-center">Данный раздел доступен для просмотра только на мобильных устройствах.</div>
                <video
                    loop
                    muted
                    autoplay
                    :src="require(`@/assets/video/cat_1.mp4`)"
                ></video>
            </div>
        </main>
    </KeepAlive>
</template>
<style lang="scss" scoped>
@import '@/styles/index';

.mobile-streams__caution {
    font-size: 24px;
    height: auto;
    position: relative;

    > div {
        position: absolute;
        top: 25%;
        font-size: 32px;
        font-weight: bold;
    }

    > video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.mobile-streams__wrapper {
    background-color: #1d192a;
    padding: 70px 10px 20px 10px;
    min-height: 100%;

    .mobile-streams__container {
        max-width: 768px;

        .mobile-streams__menu {
            margin-bottom: 20px;
            height: 46px;
            padding: 8px;
            border-radius: 32px;
            background-color: #0f0d16;

            .menu__item {
                height: 30px;
                border-radius: 62px;
                font-size: 14px;
                color: $white;

                &:hover {
                    cursor: pointer;
                }

                &--active {
                    background-color: #525665;
                }
            }
        }

        .mobile-streams__load-more {
            height: 40px;

            .load-more__button {
                height: 100%;
                border-radius: 25px;
                padding-inline: 22px;
                color: $white;
                background-color: #525665;

                &:active {
                    -webkit-box-shadow: 0px 0px 10px 8px rgba(82, 86, 101, 0.2);
                    -moz-box-shadow: 0px 0px 10px 8px rgba(82, 86, 101, 0.2);
                    box-shadow: 0px 0px 10px 8px rgba(82, 86, 101, 0.2);
                }
            }
        }
    }
}
</style>
