<script setup>
import { ref, computed } from "vue"
import EmojiPicker from "vue3-emoji-picker"
import "vue3-emoji-picker/css"
import { useStore } from "vuex"
import axios from "axios"

const emits = defineEmits(['send'])

const store = useStore()
const user = store.getters.getUser
const apiUrl = store.getters.getApiUrl
const activeChat = computed(() => {
    return store.getters.getActiveChat
})

const isShowEmoji = ref(false)
const newMessage = ref('')

const toggleEmoji = () => {
    isShowEmoji.value = !isShowEmoji.value
}
const closeEmoji = () => {
    isShowEmoji.value = false
}

const onSelectEmoji = (emoji) => {
    newMessage.value += emoji.i
}
const userSettings = computed(() => {
  return store.getters.getUserSettings;
});
const tg = async (id = parseInt(this.user.user_id), message) => {
  try {
    if (user.profile.tg != '') {
      const res = await axios.post(
        apiUrl + 'api-tg/tg-msg&auth=' + user.username + ':' + user.auth_key,
        {
          user_id: id,
          msg: message,
        }
      );
      console.log(res);
    }

  } catch (error) {
    console.log('tg: ', error);
  }
};

const setChatMessage = async () => {
    try {
        const chatId = activeChat.value.id || null
        const typeId = 3 // 1 Public, 3 Privat, 2 Support
        const recipientId = activeChat.value.recipient_id
        const message = btoa(encodeURIComponent(newMessage.value))

        const params = '&auth=' + user.username + ':' + user.auth_key
        const { data } = await axios.post(apiUrl + 'api-chat/set-message' + params, {
            chat_id: chatId,
            type_id: typeId,
            author_id: +user.user_id,
            recipient_id: recipientId,
            text: message
        })

        
        const activeChatItem = {message_id: data.data.message_id, id: data.data.chat_id, name: activeChat.value.recipient, recipient_id: recipientId }
        // console.log('setChatMessage:', activeChatItem)
       
        store.commit('setActiveChat', activeChatItem)
        emits('send', data.data.message_id)
        newMessage.value = ''
         // Отправка в tg уведомления
         getusersTg()
         usersTg()
        
    } catch (error) {
        console.log('setChatMessage: ', error)
    }
}
const getusersTg = async () => {
    try {
        const res = await axios.get(`${apiUrl}api-user/get-user-settings&auth=${user.username}:${user.auth_key}&user_id=${activeChat.value.recipient_id}`);
        const data = res.data.data;
        data.find((el) => {
            if (el.name == 'mess' && el.value == 'true') {
              if (activeChat.value.recipient_id != user.user_id) {
                tg(
                    activeChat.value.recipient_id,
                  'Вам написали в мессенджере'
                );
              }
            }
          });
    } catch (error) {
        console.log('messsengersAction.vue:', error);
    }
}
const usersTg = async () => {
    try {
        const res = await axios.get(`${apiUrl}api-user/get-user-settings&auth=${user.username}:${user.auth_key}&user_id=${user.user_id}`);
        const data = res.data.data;
        data.find((el) => {
            if (el.name == 'mess' && el.value == 'true') {
              if (activeChat.value.recipient_id != user.user_id) {
                tg(
                    user.user_id,
                  'Вам написали в мессенджере'
                );
              }
            }
          });
    } catch (error) {
        console.log('messsengersAction.vue:', error);
    }
}

</script>
<template>
    <div class="messenger-actions d-flex justify-content-between align-items-center p-2 px-3 px-md-2 mt-auto gap-2">
        <div class="actions-emoji">
            <div class="button" @click.stop="toggleEmoji">
                <img src="@/assets/img/smile.svg" alt="emoji">
            </div>
            <div class="emoji-wrapper position-absolute" v-if="isShowEmoji">
                <EmojiPicker :native="true" @select="onSelectEmoji" v-click-outside="closeEmoji" />
            </div>
        </div>
        <div class="actions-textarea d-flex w-100">
            <textarea name="message" v-model="newMessage" @keypress.enter="setChatMessage" @keypress.ctrl="newMessage += '\n'"></textarea>
        </div>
        <div class="actions-btn">
            <div class="button" @click="setChatMessage" :class="{'disabled': (!activeChat.recipient_id || !newMessage)}">
                <img src="@/assets/img/send.svg" alt="send" />
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.messenger-actions {
    background-color: #20222E;
    border-radius: 12px;

    @media screen and (max-width: 767px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .actions-emoji {
        position: relative;

        .emoji-wrapper {
            left: 0;
            bottom: 100%;

            .v3-emoji-picker {
                background-color: #484c5e !important;
            }

            .v3-tone,
            .v3-footer {
                display: none !important;
            }

            .v3-search {
                display: none !important;
            }

            .v3-sticky {
                background: #484c5e !important;
                color: #fff !important;
                padding: 0.5rem 0 !important;
                font-size: 12px;
                box-shadow: 0px 19px 7px 0px rgba(#484c5e, 1);
                margin-bottom: 10px !important;
                margin-top: -1px !important;
            }

            .v3-header {
                padding: 15px 15px 0 !important;
            }
        }
    }

    textarea {
        width: 100%;
        background-color: #2D2F3A;
        border-radius: 8px;
        border: none;
        outline: none;
        color: #fff;
        padding: 10px;
        resize: none;
        font-size: 14px;
        font-weight: 300;

        @media screen and (max-width: 767px) {
            height: 41px;
        }
    }

    .button,
    button {
        @media screen and (max-width: 767px) {
            background-color: transparent !important;
        }

        &:disabled {
            opacity: .1;
            &:hover {
                opacity: .1;
            }
        }
    }

    .button.disabled {
        opacity: .1;
        cursor: no-drop;

            &:hover {
                opacity: .1;
            }
    }
}
</style>