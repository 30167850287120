<script>
export default {
    name: "VideoPlayer",
    components: {},
    props: {
        videoCards: {
            type: Object,
            required: false,
            default: {},
        },
        name: {
          type: String,
          required: false,
          default: ''
        },
        photos: {
            type: Array,
            required: false,
        },
        heightFixed: {
          type: Boolean,
          required: false,
          default: false
        }
    },
    data() {
        return {
            apiDomine: this.$store.getters['getApiDomine'],
            videoCrad: [
                {
                    id: 1,
                    type: "video/mp4",
                    src: require('@/assets/video/6.mp4'),
                    codecs: "avc1.42E01E, mp4a.40.2",
                    name: 'Анжела',
                    link: '',
                    poster: require('@/assets/img/slider-girls/item1.webp'),
                    card: false
                }
            ],
            video: {
                id: '',
                type: "video/mp4",
                src: '',
                codecs: "avc1.42E01E, mp4a.40.2",
                name: '',
                link: '',
                poster: '',
                card: false
            }
        }
    },
    computed: {
        videoPosterSrc() {
          return this.name !== '' ? this.apiDomine + '/web/uploads' + this.photos[0]?.pic : this.apiDomine + '/web/uploads' + this.photos[0]?.name
        }
    },
    methods: {
        playToggle(i) {
            const currentRef = `itemVideo_${i}`
            const videoTest = this.$refs[currentRef]
            const button = videoTest.closest('.video-card').querySelector('.video-play')
            const poster = videoTest.closest('.video-card').querySelector('.video-card__poster')
            const vids = document.querySelectorAll('video')

            vids.forEach(vid => vid.classList.remove('active'))

            videoTest.classList.add('active')

            vids.forEach(vid => {
                if (!vid.classList.contains('active')) {
                    const btnWrap = vid.closest('.video-card')
                    if (btnWrap !== null) {
                        btnWrap.querySelector('.video-play').style.backgroundImage = 'url(' + require('@/assets/img/play.svg')
                    }
                    vid.pause()
                }
            })

            if (videoTest.paused) {
                videoTest.play()
                poster.classList.add('hide')
                button.style.backgroundImage = 'url(' + require('@/assets/img/pause.png')
            } else {
                videoTest.pause()

                button.style.backgroundImage = 'url(' + require('@/assets/img/play.svg')
            }
        },
        volumeToggle(i) {
            const currentRef = `itemVideo_${i}`
            const videoTest = this.$refs[currentRef]
            const button = videoTest.closest('.video-card').querySelector('.video-volume');
            if (videoTest.muted) {
                videoTest.muted = false
                button.style.backgroundImage = 'url(' + require('@/assets/img/volume.svg')
            } else {
                videoTest.muted = true
                button.style.backgroundImage = 'url(' + require('@/assets/img/mute.svg')
            }
        },
        updateRemainingTime(event) {
            if(!this.$refs['videoCardBlock']) return

            const videoCardBlock = this.$refs['videoCardBlock']
            const remainingTimeElement = videoCardBlock.querySelector('.video-remaining-time');
            const progressiveBar = videoCardBlock.querySelector('.video-progressive-bar--pr');

            let remainingTime = event.target.currentTime
            let duration = event.target.duration

            let minutes = Math.floor(remainingTime / 60);
            let seconds = Math.floor(remainingTime % 60);
            minutes = isNaN(minutes) ? 0 : minutes;
            seconds = isNaN(seconds) ? 0 : seconds;
            remainingTimeElement.textContent = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;

            let progressiveBarMoving = (remainingTime / duration) * 100;
            progressiveBar.style.width = `${progressiveBarMoving}%`
        },
    },
    mounted() {
        if (this.videoCards && this.videoCards.id) {
            let src = this.$store.getters.getApiDomine + '/web/uploads';

            if (!this.videoCards.name.includes('/girls/video/')) {
                src = src + `/girls/video/${this.videoCards.name}`
            } else {
                src = src + this.videoCards.name
            }

            this.video = {
                id: this.videoCards.id,
                type: "video/mp4",
                src: src,
                codecs: "avc1.42E01E, mp4a.40.2",
                name: this.name,
                link: '',
                poster: this.videoPosterSrc,
                card: false
            }
        }

    },
    watch: {
        videoCards: function (data) {
            if (!data.id) return
            if (!data.name.includes('/girls/video/')) {
                data.name = `/girls/video/${data.name}`
            }
            this.video = {
                id: data.id,
                type: "video/mp4",
                src: this.$store.getters.getApiDomine + '/web/uploads' + data.name,
                codecs: "avc1.42E01E, mp4a.40.2",
                name: this.name,
                link: '',
                poster: this.videoPosterSrc,
                card: false
            }
        }
    }
}
</script>
<template>
    <div v-if="video.src" class="video-card d-flex" ref="videoCardBlock">
        <video
            :ref="`itemVideo_${video.id}`"
            muted
            playsinline
            webkit-playsinline
            preload="metadata"
            @click="playToggle(video.id)"
            @timeupdate="updateRemainingTime"
            :poster="video.poster"
            :class="this.heightFixed ? 'maxHeight': ''"
        >
            <source :src="`${video.src}`" :type="video.type" :codecs="video.codecs"/>
        </video>
        <div class="video-card__poster"
             :style="{
					backgroundImage: `url(${video.poster})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}"
             @click="playToggle(video.id)"
        ></div>
        <div class="video-card__name">{{ video.name }}</div>
        <div class="video-progressive-bar">
            <div class="video-progressive-bar--pr"></div>
        </div>
        <div class="video-remaining-time">00:00</div>
        <button class="video-play video-btns hover-btn" @click="playToggle(video.id)"></button>
        <button class="video-volume video-btns hover-btn" @click="volumeToggle(video.id)"></button>
    </div>
</template>
<style lang="scss" scoped>

.maxHeight{
  max-height: 240px;
}

.video-card {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 416px;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #525665;

    video {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
        border-bottom: 1px solid transparent; /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
        &::-webkit-media-controls-panel {
            display: none !important;
            -webkit-appearance: none;
            opacity: 0;
            visibility: hidden;
        }

        &::-webkit-media-controls-play-button {
            display: none !important;
            -webkit-appearance: none;
            opacity: 0;
            visibility: hidden;
        }

        &::-webkit-media-controls-start-playback-button {
            display: none !important;
            -webkit-appearance: none;
            opacity: 0;
            visibility: hidden;
        }
    }


    &__poster {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        &.hide {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__name {
        position: absolute;
        top: 35px;
        left: 20px;
        color: #fff;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%
    }

    &:hover .video-play {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}

.video-link {
    font-size: 14px;
    line-height: 100%;
    border-radius: 50px;
    padding: 9px 0;
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 35px;
    color: #fff;
    transform: translateX(-50%);
    background-color: rgba(#fff, 0.2);
    transition: 0.3s;
    border: solid 1px transparent;
    backdrop-filter: blur(10px);
    @media (max-width: 575px) {
        max-width: 90%;
        font-size: 10px;
    }

    &:hover {
        background-color: rgba(#fff, 0);
        border: solid 1px #fff;
    }
}

.video-play {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    display: block;
    background-color: rgba(#fff, 0.1);
    background-image: url('@/assets/img/play.svg');
}

.video-volume {
    position: absolute;
    top: 30px;
    right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(#fff, 0.1);
    backdrop-filter: blur(10px);
    background-image: url('@/assets/img/mute.svg');
}

.video-btns {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 2;
}

.video-remaining-time {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 12px;
    color: rgba(#fff, 0.8);
}

.video-progressive-bar {
    position: absolute;
    top: 12px;
    height: 4px;
    background-color: rgba(#fff, 0.3);
    border-radius: 15px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);

    &--pr {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;
        background-color: #FF4032;
        border-radius: 10px;
    }
}
</style>
