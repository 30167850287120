<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

export default {
    mounted() {
        this.startGsap()
    },
    methods:{
        startGsap(){
            if(!this.isMobile){return}
            console.log(this.$refs.plane)
            gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
            //gsap.utils.toArray(".scroll-img .scroll-img__inner").forEach((section) => {
                gsap.fromTo('.scroll-img__inner', {x:0}, {
                    /*x: () => {
                        return -(section.scrollWidth - document.body.offsetWidth)
                    },*/
                    x: '-800px',//'-150%',//Ширина гор. прокрутки
                    //ease: "none",
                    scrollTrigger: {
                        trigger: '.main-header-wrapper',
                        start: () => "top top",
                        end: () => "600",//Стоять на месте 6 скроллов + (section.scrollWidth - document.body.offsetWidth),
                        scrub: true,
                        //pinSpacing: false,
                        pin: true,
                        invalidateOnRefresh: true,
                        anticipatePin: 1,
                    },
                });
            //});
        }



    },
    data(){
        return {}
    },
    computed: {
        isMobile() {
            return this.$store.getters['getIsMobile']
        }
    },
}
</script>
<template>
    <div class="scroll-img" ref="universeStart">
        <div class="scroll-img__inner">
            <img
                src="@/assets/img/main/girls.webp"
                alt="girls"
                class="plane"
                ref="plane"
            />
        </div>
    </div>
</template>
<style lang="scss">
.scroll-img {
    img {
        @media screen and (max-width: 575px) {
            max-width: initial;
            height: 100%;
            max-height: 400px;
        }
    }
}
</style>