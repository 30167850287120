<template>
	<div class="chat">
		<tabs class="chat__tabs d-flex tabs flex-column">
			<tab name="Чат" title="title" class="chat__tab col-auto">
				<div class="chat__wrap d-flex flex-column">
					<div class="chat__field flex-grow-1 p-4 d-flex flex-column">

						<div class="chat__messages-box" ref="publicMessagesWrapper">
							<div
								v-for="(message, i) in this.publicMessages"
								:key="i"
								:class="[
									'chat-message w-50 py-2 mb-3',
									{ 'chat-message__out': message.author_id == userId },
									{ 'chat-message__in': message.author_id != userId },
								]"
							>
								<div class="chat-message__top row mx-0 mb-2 justify-content-between">
									<div class="chat-message__name col-auto">
										{{ message.author.username }}
									</div>
									<div
										class="chat-message__time col-auto"
										v-html="timeToDay(message.date_add)"
									></div>
								</div>
								<div class="chat-message__text row mx-0">
									<div class="col-auto" v-html="message.text"></div>
								</div>
							</div>
						</div>

						<!-- <div class="chat-message__in chat-message w-50 py-2 mb-3">
							<div class="chat-message__top row mx-0 mb-2 justify-content-between">
								<div class="chat-message__time col-auto">21:22</div>
								<div class="chat-message__name col-auto">Анжела</div>
							</div>
							<div class="chat-message__text row mx-0">
								<div class="col-auto">Привет!</div>
							</div>
						</div>

						<div class="chat-message__out chat-message w-50 py-2 mb-3">
							<div class="chat-message__top row mx-0 mb-2 justify-content-between">
								<div class="chat-message__name col-auto">Василий</div>
								<div class="chat-message__time col-auto">21:22</div>
							</div>
							<div class="chat-message__text row mx-0">
								<div class="col-auto">Привет</div>
							</div>
						</div> -->
						
					</div>
					<div class="chat__info d-flex align-items-center mt-auto justify-content-between">
						<div class="chat__smile me-2 mb-2 ms-2">
							<div class="chat__smile-icon"
								@click.stop="this.emojiPopup = !this.emojiPopup"
								
							>
								<img src="@/assets/img/smile.svg" alt="svg">
							</div>
							<div class="chat__smile-emoji" v-if="this.emojiPopup">
								<EmojiPicker :native="true" @select="onSelectEmoji" v-click-outside="emojiPopupHide" />
							</div>
						</div>
						<div class="chat__text w-100">
							<textarea v-model="currentMessage" class="w-100">
								<img src="@/assets/img/text-color.svg" alt="svg">
							</textarea>
						</div>
						<div class="chat__send">
							<button
								type="submit"
								@click="this.sendMessage(userId, recipientId, 1, this.publicChatId)"
							>
								<img src="@/assets/img/send.svg" alt="svg" />
							</button>
						</div>
					</div>
				</div>
			</tab>
			<tab name="ЛС" class="chat__tab col-auto">
				<div class="chat__wrap d-flex flex-column">
					<div class="chat__field flex-grow-1 p-4 d-flex flex-column" ref="messagesScroll">
						<div class="chat__messages-box" ref="messagesWrapper">
							<div
								v-for="(message, i) in this.userMessages"
								:key="i"
								:class="[
									'chat-message w-50 py-2 mb-3',
									{ 'chat-message__out': message.author_id == userId },
									{ 'chat-message__in': message.author_id != userId },
								]"
							>
								<div class="chat-message__top row mx-0 mb-2 justify-content-between">
									<div class="chat-message__name col-auto">
										{{ message.author.username }}
									</div>
									<div
										class="chat-message__time col-auto"
										v-html="timeToDay(message.date_add)"
									></div>
								</div>
								<div class="chat-message__text row mx-0">
									<div class="col-auto" v-html="message.text"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="chat__info d-flex align-items-center mt-auto justify-content-between">
						<div class="chat__smile me-2 mb-2 ms-2">
							<div class="chat__smile-icon"
								@click.stop="this.emojiPopup = !this.emojiPopup"
								
							>
								<img src="@/assets/img/smile.svg" alt="svg">
							</div>
							<div class="chat__smile-emoji" v-if="this.emojiPopup">
								<EmojiPicker :native="true" @select="onSelectEmoji" v-click-outside="emojiPopupHide" />
							</div>
						</div>
						<div class="chat__text w-100">
							<textarea v-model="currentMessage" class="w-100">
								<img src="@/assets/img/text-color.svg" alt="svg">
							</textarea>
						</div>
						<div class="chat__send">
							<button
								type="submit"
								@click="this.sendMessage(userId, recipientId, 1, this.chatId)"
							>
								<img src="@/assets/img/send.svg" alt="svg" />
							</button>
						</div>
					</div>
				</div>
			</tab>
		</tabs>
	</div>
</template>

<script>
import { Tabs, Tab } from "vue3-tabs-component";
import EmojiPicker from "vue3-emoji-picker";
//import DiscordPicker from "vue3-discordpicker";
import "vue3-emoji-picker/css";
import axios from "axios";

export default {
	components: {
		Tabs,
		Tab,
		EmojiPicker
	},
	data() {
		return {
			user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
			emojiPopup: false,
			recipientId: this.$route.params.id,
			userMessages: [],
			chatId: 1,
			publicMessages: [],
			publicChatId: 1,
			currentMessage: "",
			currentMessagesHeight: 0,
			currentPublicMessagesHeight: 0,
		};
	},
	computed: {
		userId() {
			return this.user.user_id
		}
	},
	methods: {
		getPublicChatId() {
			axios
				.get(this.apiUrl + "api-chat/get-shared-chat&target_user_id=" + this.recipientId + '&auth=' + this.user.username + ':' + this.user.auth_key)
				.then((response) => {
					this.publicChatId = response.data.id
				})
		},
		getPrivateChatId() {
			axios
				.get(this.apiUrl + "api-chat/get-private-chat&user_id=" + this.userId + '&target_user_id=' + this.recipientId + '&auth=' + this.user.username + ':' + this.user.auth_key)
				.then((response) => {
					this.chatId = response.data.id
				})
		},
		sendMessage(author_id, recipient_id, type_id, chat_id = false) {

			let message = btoa(unescape(encodeURIComponent(this.currentMessage)))
			axios
				.post(this.apiUrl + "api-chat/set-message" + '&auth=' + this.user.username + ':' + this.user.auth_key, {
					author_id: author_id,
					recipient_id: recipient_id,
					type_id: type_id,
					text: message,
					chat_id: chat_id,
				})
				.then((response) => {
					this.getMessage();
					this.getPublicMessage();
					this.currentMessage = ''
				});
		},
		getPublicMessage() {
			axios
				.get(this.apiUrl + "api-chat/get-message&chat_id=" + this.publicChatId + '&auth=' + this.user.username + ':' + this.user.auth_key)
				.then((response) => {
					let messages = response.data;
					messages.sort((a, b) => a.date_add > b.date_add ? 1 : -1);
					messages.forEach((item, index) => {
						let decodingText = decodeURIComponent(escape(atob(item.text)))
						messages[index].text = decodingText

					})
					
					this.publicMessages = messages;
					const messagesWrap = this.$refs.publicMessagesWrapper;
					if (messagesWrap === null || messagesWrap === undefined) return;
					let heightMessagesWrap = this.$refs.publicMessagesWrapper.offsetHeight;
					if (heightMessagesWrap !== this.currentPublicMessagesHeight) {
						this.currentPublicMessagesHeight = heightMessagesWrap;
						this.$refs.messagesScroll.scrollTo({
							top: heightMessagesWrap,
							left: 0,
						});
					}
				});
		},
		getMessage() {
			axios
				.get(this.apiUrl + "api-chat/get-message&chat_id=" + this.chatId + '&auth=' + this.user.username + ':' + this.user.auth_key)
				.then((response) => {
					let messages = response.data;
					messages.sort((a, b) => a.date_add > b.date_add ? 1 : -1);
					messages.forEach((item, index) => {
						let decodingText = decodeURIComponent(escape(atob(item.text)))
						messages[index].text = decodingText
					})
					
					this.userMessages = messages;
					const messagesWrap = this.$refs.messagesWrapper;
					if (messagesWrap === null || messagesWrap === undefined) return;
					let heightMessagesWrap = this.$refs.messagesWrapper.offsetHeight;
					if (heightMessagesWrap !== this.currentMessagesHeight) {
						this.currentMessagesHeight = heightMessagesWrap;
						this.$refs.messagesScroll.scrollTo({
							top: heightMessagesWrap,
							left: 0,
						});
					}
				});
		},
		timeToDay(time) {
			let dateAdd = new Date(time * 1000);
			let hours = dateAdd.getHours() < 10 ? "0" + dateAdd.getHours() : dateAdd.getHours();
			let minutes =
				dateAdd.getMinutes() < 10 ? "0" + dateAdd.getMinutes() : dateAdd.getMinutes();
			let timeMessage = hours + ":" + minutes;
			return timeMessage;
		},
		stopTimer() {
			if (this.interval) {
				window.clearInterval(this.interval);
			}
		},
		emojiPopupHide() {
			this.emojiPopup = false
		},
		startTimer() {
			this.stopTimer();
			this.interval = window.setInterval(() => {
				this.getMessage();
				this.getPublicMessage();
			}, 1000);
		},
		onSelectEmoji(emoji) {
			this.currentMessage = this.currentMessage + emoji.i
		}
	},
	mounted() {
		this.getMessage();
		this.getPublicMessage();
		// this.startTimer();
		this.getPrivateChatId();
		this.getPublicChatId();
		console.log(this.user)
		document.querySelectorAll('.tabs-component-tab-a').forEach(element => {
			element.addEventListener('click', function(event) {
				event.preventDefault();
			})
		})
	},
	beforeDestroy() {
		this.stopTimer();
	},
};
</script>

<style lang="scss">
.chat {
	height: 590px;
	background-color: #2d2f3a;
	border-radius: 24px;
	overflow: hidden;
	color: #fff;
	//padding: 10px;
	.v3-tone, .v3-footer {
		display: none!important;
	}
	.v3-search {
		display: none!important;
	}
	.v3-sticky  {
		background: #484c5e !important;
		color: #fff!important;
		padding: 0.5rem 0 !important;
		font-size: 12px;
		box-shadow: 0px 19px 7px 0px rgba(#484c5e, 1);
		margin-bottom: 10px!important;
		margin-top: -1px!important;
	}
	.v3-header {
		padding: 15px 15px 0!important;
	}
	&__smile {
		position: relative;
		.v3-emoji-picker {
			background-color: #484c5e!important;
		}
	}
	&__smile-emoji {
		position: absolute;
		left: 0;
		bottom: 100%;
	}
	&__smile-icon {
		cursor: pointer;
	}
	.tabs-component-tabs {
		display: flex !important;
		align-items: center !important;
	}
	.tabs-component-tab-a {
		color: #fff !important;
		font-size: 16px !important;
	}
	.tabs-component-tab {
		padding: 10px 0 0;
		&:first-child {
			margin-left: 15px;
		}
		a {
			border-radius: 10px 10px 0px 0px;
		background: #484c5e;
		padding: 5px 15px;
		margin-right: 15px;
		}
		&.is-active {
			a {
				background: #8e7f7d;
			}
			
		}
	}
	&__text {
		// overflow-y: auto;
		//background-color: #2d2f3a;
		//border-radius: 8px;
		//resize: none;
		//border: none;
		//color: #fff;
		//font-size: 14px;
		//padding: 5px 10px;
		//height: 70px;
		textarea {
			overflow-y: auto;
			background-color: #2d2f3a;
			border-radius: 8px;
			resize: none;
			border: none;
			color: #fff;
			font-size: 14px;
			padding: 5px 10px;
			height: 70px;
		}
	}
	&__field {
		padding-bottom: 70%;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color:  #797979 #535353;
		&::-webkit-scrollbar {
			width: 10px;
		}
		&::-webkit-scrollbar-track {
			background-color: #797979;
			border-radius: 30px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #535353;
			border-radius: 20px;
			border: solid 1px #797979;
		}
	}
	&__messages-box {
	}
	&__wrap {
		background-color: #20222e;
		position: relative;
		height: 555px;
		border-radius: 0 0 24px 24px;
	}
	&__info {
		padding-bottom: 10px;
		padding-top: 10px;
		margin-top: 10px;
		border-top: solid 1px #797979;
	}
	
}
.chat-message {
	background-color: #2c2f3c;
	&__out {
		margin-right: auto;
		border-radius: 0px 12px 12px 12px;
	}
	&__in {
		margin-left: auto;
		border-radius: 12px 0px 12px 12px;
	}
	&__name {
		color: #6ccb5f;
		font-size: 16px;
		font-weight: 600;
	}
	&__time {
		color: #bdc1d1;
		font-size: 14px;
	}
	&__text {
		color: #fff;
		font-size: 14px;
	}
}
</style>
