<script>
import GirlListCategory from "@/components/ui/GirlListCategory.vue";
import OtzyvModal from "@/components/blocks/OtzyvModal.vue";
import AllTags from "@/components/ui/common/tags/AllTags.vue";
import QuizBlock from "@/components/blocks/QuizBlock.vue";
import NewProfilesBlock from "@/components/blocks/NewProfilesBlock.vue";

export default {
  components: {NewProfilesBlock, OtzyvModal, GirlListCategory, AllTags, QuizBlock},
  data() {
    return {
      title: 'Эскорт',
      category_id: '7',
      topBanner: require('@/assets/video/premiumPageDeck.mp4'),
      topBannerMobile: require('@/assets/video/premiumMobilePage.mp4'),
      poster: require('@/assets/img/main/premiumDeck1.png'),
      posterMobile: require('@/assets/img/main/premiumMob1.png'),
    }
  },
  computed: {
    isMobile(){
      return this.$store.getters['getIsMobile']
    }
  },
}
</script>
<template>
  <main>
    <div class="main-block">
      <div class="container-fluid p-0">
        <div class="main-block__img video-padding">
            <div class="py-0 my-0">
                <img :src="!isMobile ? poster : posterMobile" alt="">
            </div>
<!--          <video autoplay muted playsinline webkit-playsinline loop width="100%" preload="metadata"
                 :poster="!isMobile ? poster : posterMobile">
            <source :src="topBanner" type="video/mp4" media="(min-width: 992px)"/>
            <source :src="topBannerMobile" type="video/mp4" media="(max-width: 992px)"/>
          </video>-->
        </div>
      </div>
    </div>
    <girl-list-category :category="'7'" :category-name="' премиум'"></girl-list-category>
    <div class="block-offer">
      <div class="container">
        <div class="block-offer__inner">
          <NewProfilesBlock
              :categoryId="category_id"
              titleCards="Премиум"
          />
        </div>
      </div>
    </div>
    <div class="block-offer">
      <quiz-block/>
    </div>
    <div class="block-offer">
      <div class="container">
        <all-tags/>
      </div>
    </div>
  </main>

</template>
<style scoped lang="scss">
.video-padding {
  padding-top: 150px !important;
}

@media (max-width: 760px) {
  .video-padding {
    padding-top: 160px !important;
  }
}

video {
  position: relative;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid transparent; /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */

  &::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }
}

img {
  width: 100%;
}

.main-block {
  padding-bottom: 0;
}
</style>