// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/row-right-search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.search-list[data-v-cd873d92] {\n    position: fixed;\n    opacity: 1;\n    height: auto;\n    width: calc(100% - 40px);\n    visibility: visible;\n\n    left: 20px;\n    right: 20px;\n    z-index: 999;\n    pointer-events: all;\n    background-color: #fff;\n    border-radius: 5px;\n    box-shadow: 0px 0px 10px #000;\n}\n.left-menu button[data-v-cd873d92] {\n    color: #525665;\n    font-weight: 500;\n}\n.cursor[data-v-cd873d92] {\n    cursor: pointer;\n}\n.left-menu button.active[data-v-cd873d92], .left-menu button.active[data-v-cd873d92]:hover {\n    background: #F5F5F5 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right;\n    border-radius: 14px;\n    background-position-x: calc(100% - 10px);\n}\n.left-menu button[data-v-cd873d92]:hover {\n    background: #F5F5F5;\n    border-radius: 14px;\n}\n.tab-pane img[data-v-cd873d92] {\n    max-width: 17px;\n    margin-right: 15px;\n}\n.nav-link[data-v-cd873d92] {\n    background: #21232F !important;\n    border-radius: 20px;\n    border: none;\n    color: #fff !important;\n    margin-right: 15px;\n    margin-left: 15px;\n}\n.nav-pills .nav-link.active[data-v-cd873d92] {\n    background: #21232F;\n    border-radius: 20px;\n    border: none;\n    color: #1AD42C;\n}\n.name-service[data-v-cd873d92] {\n    font-size: 14px;\n    font-weight: 400;\n    color: #525665;\n}\n.name-service[data-v-cd873d92]:hover {\n    font-weight: 500;\n}\n.title-service[data-v-cd873d92] {\n    font-size: 24px;\n    color: #525665;\n}\n.prev[data-v-cd873d92] {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    background: #fff;\n    width: 34px;\n    height: 34px;\n    border: 1px solid #BDC1D1;\n    border-radius: 5px;\n}\n@media (max-width: 500px) {\nbutton[data-v-cd873d92]{\n    -webkit-text-fill-color: #000;\n    opacity: 1;\n}\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
