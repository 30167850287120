<script>
export default {
    name: 'copy-icon-solid',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        size: {
            type: Object,
            default() {
                return { w: 24, h: 24 };
            },
        },
    },
};
</script>

<template>
    <svg
        :width="size.w"
        :height="size.h"
        :viewBox="`0 0 ${size.w} ${size.h}`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.4501 12.5178L18.8642 10.9723L18.8642 10.9723C19.3476 9.16827 19.5893 8.26624 19.4073 7.48562C19.2635 6.86925 18.9403 6.30935 18.4784 5.8767C17.8933 5.32876 16.9913 5.08706 15.1872 4.60366C13.3832 4.12027 12.4811 3.87857 11.7005 4.06058C11.0841 4.2043 10.5242 4.52756 10.0916 4.98949C9.62245 5.49039 9.37781 6.22366 9.01375 7.55649C8.95261 7.78032 8.88811 8.02105 8.81861 8.28043L8.81856 8.28062L8.40445 9.8261C7.92105 11.6302 7.67935 12.5322 7.86136 13.3128C8.00508 13.9292 8.32834 14.4891 8.79027 14.9217C9.3753 15.4697 10.2773 15.7114 12.0814 16.1948L12.0814 16.1948C13.7075 16.6305 14.6007 16.8698 15.3328 16.7795C15.4129 16.7696 15.4911 16.7558 15.5681 16.7379C16.1845 16.5941 16.7444 16.2709 17.177 15.8089C17.725 15.2239 17.9667 14.3219 18.4501 12.5178Z"
            :fill="color"
        />
        <path
            d="M4.40445 14.1716L4.81856 15.7171C5.30195 17.5212 5.54365 18.4232 6.09159 19.0082C6.52424 19.4702 7.08415 19.7934 7.70051 19.9371C8.48113 20.1192 9.38316 19.8775 11.1872 19.3941L11.1872 19.3941C12.9913 18.9107 13.8933 18.669 14.4784 18.121C14.5269 18.0756 14.5739 18.0287 14.6194 17.9805C14.352 17.958 14.0824 17.915 13.8083 17.8605C13.2514 17.7498 12.5897 17.5725 11.8072 17.3628L11.7217 17.3399L11.7019 17.3346C10.8504 17.1064 10.139 16.9154 9.571 16.7102C8.97364 16.4944 8.43086 16.2284 7.96996 15.7967C7.33481 15.2018 6.89032 14.432 6.69271 13.5845C6.54932 12.9695 6.59031 12.3664 6.70211 11.7412C6.80925 11.142 7.00166 10.424 7.23218 9.56374L7.23219 9.56372L7.6597 7.96822L7.67474 7.91211C6.1383 8.326 5.32959 8.57087 4.79027 9.07599C4.32834 9.50864 4.00508 10.0685 3.86136 10.6849C3.67935 11.4655 3.92105 12.3676 4.40445 14.1716Z"
            :fill="color"
        />
    </svg>
</template>
