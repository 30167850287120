<template>
	<div class="container">
		<div class="quiz">

			<!-- step 1 1% Цвет волос-->
			<div class="quiz__block" v-if="quizStep === 1">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
									<div class="col-auto">Заказ</div>
									<div class="col-auto quiz-info__block--gray">Заполнен на 1 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры TS</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Цвет волос</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step1" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep1"
												:value="item.text"
												v-model="quizTsStep1"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button disabled>Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(2)">Далее</button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<!-- step 2 6% Возраст-->
			<div class="quiz__block" v-else-if="quizStep === 2">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 6 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры TS</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Возраст</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step2" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep2"
												:value="item.text"
												v-model="quizTsStep2"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(1)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(3)">Далее</button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<!-- step 3 12% рост-->
			<div class="quiz__block" v-else-if="quizStep === 3">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 12 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Рост</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in stepHeight" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep3"
												:value="item.text"
												v-model="quizTsStep3"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(2)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(4)">Далее</button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			<!-- step 4 18% Размер груди-->
			<div class="quiz__block" v-else-if="quizStep === 4">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 pe-4 col-xl-3 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 18 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры TS</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Размер груди</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step3" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep4"
												:value="item.text"
												v-model="quizTsStep4"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(3)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(5)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 5 24%  Размер члена -->
			<div class="quiz__block" v-else-if="quizStep === 5">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 24 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры TS</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Размер члена</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step4" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep5"
												:value="item.text"
												v-model="quizTsStep5"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(4)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(6)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 6 30% Национальность-->
			<div class="quiz__block" v-else-if="quizStep === 6">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 30 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры TS</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Национальность</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step5" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep6"
												:value="item.text"
												v-model="quizTsStep6"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(5)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(7)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 7 36% Секс-->
			<div class="quiz__block" v-else-if="quizStep === 7">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 36 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выбрать услугу</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Секс</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12 col-md-6" v-for="(item, i) in step6" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizTsStep7"
												:value="item.text"
												v-model="quizTsStep7"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(6)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(8)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 8 42% Выбрать услугу -->
			<div class="quiz__block" v-else-if="quizStep === 8">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 42 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выбрать услугу</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Дополнительно</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12 col-md-6" v-for="(item, i) in step7" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizTsStep8"
												:value="item.text"
												v-model="quizTsStep8"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
									<div class="col-12 col-md-6">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizTsStep8"
												value="Не интересует"
												:checked="quizTsStep8.length ? false : true"
												@input="quizTsStep8 = []"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">Не интересует</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(7)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(9)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 9 48% BDSM-->
			<div class="quiz__block" v-else-if="quizStep === 9">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 48 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выбрать услугу</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">BDSM</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12 col-md-6" v-for="(item, i) in step8" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizTsStep9"
												:value="item.text"
												v-model="quizTsStep9"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
									<div class="col-12 col-md-6">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizTsStep9"
												value="item.text"
												:checked="quizTsStep9.length ? false : true"
												@input="quizTsStep9 = []"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">Не интересует</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(8)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(10)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 10 54% Массаж-->
			<div class="quiz__block" v-else-if="quizStep === 10">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 54 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выбрать услугу</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Массаж</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step9" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizTsStep10"
												:value="item.text"
												v-model="quizTsStep10"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
									<div class="col-12">
										<label class="quiz-radio__label" >
											<input
												type="checkbox"
												name="quizTsStep10"
												value="Не интересует"
												:checked="quizTsStep10.length ? false : true"
												@input="quizTsStep10 = []"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">Не интересует</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(9)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(11)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 11 60% продолжительность встречи-->
			<div class="quiz__block" v-else-if="quizStep === 11">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 60 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							
							</div>
						</div>
					</div>
					
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выбрать продолжительность встречи</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Дата</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step10" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep11"
												:value="item.text"
												v-model="quizTsStep11"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(10)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(12)">Далее</button>
									</div>
			
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 12 66% Дата-->
			<div class="quiz__block" v-else-if="quizStep === 12">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 66 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Когда желаете встретиться</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Дата</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step11" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep12"
												:value="item.text"
												v-model="quizTsStep12"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(11)">Назад</button>
									</div>
									<div v-if="quizTsStep12 === 'Выберу день в календаре'" class="col-auto quiz__btn">
										<button @click="quizNavStep(121)">Далее</button>
									</div>
									<div v-else class="col-auto quiz__btn">
										<button @click="quizNavStep(13)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 12.1 72% календарь-->
			<div class="quiz__block" v-else-if="quizStep === 121">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12  mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 72 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Когда желаете встретиться</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Дата</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12">
										<div class="quiz-data">
											<VueDatePicker v-model="date" range multi-calendars inline locale="ru" cancelText="Выбрать"  selectText="Выбрать" :format="format"/>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(12)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(13)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 13 78% Место встречи-->
			<div class="quiz__block" v-else-if="quizStep === 13">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 78 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберите место для встречи</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Место встречи</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" v-for="(item, i) in step12" :key="i">
										<label class="quiz-radio__label" >
											<input
												type="radio"
												name="quizTsStep13"
												:value="item.text"
												v-model="quizTsStep13"
												class="quiz-radio__input">
											<span class="quiz-radio__checked"></span>
											<span class="quiz-radio__name">{{ item.text }}</span>
										</label>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(12)">Назад</button>
									</div>
									<div v-if="quizTsStep13 === 'Выезд'" class="col-auto quiz__btn">
										<button @click="quizNavStep(131)">Далее</button>
									</div>
									<div v-else class="col-auto quiz__btn">
										<button @click="quizNavStep(14)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 13.1 84% карта-->
			<div class="quiz__block" v-else-if="quizStep === 131">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 84 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберите место для встречи</div>
							</div>
							
							<div class="col-12">
								<div class="quiz-content__subtitle">Место встречи</div>
							</div>

							<div class="col-12 quiz-radio">
								<div class="row">
									<div class="col-12" >
										<label class="quiz-content-location__input d-blcok w-100 mb-3">
											<input type="text" v-model="quizTsStep131" placeholder="quizTsStep131" class="w-100">
										</label>
										<div class="quiz-content-location__map">
											<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A1ba7a1880ded2b91d40f20256f684e416e45f2c521da9ee1d96e661f1a082538&amp;source=constructor" width="100%" height="auto" frameborder="0"></iframe>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(13)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(14)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 14 90% цена-->
			<div class="quiz__block" v-else-if="quizStep === 14">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ TS по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 90 %</div>
									</div>
								</div>
								
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете стоимость</div>
							</div>
							
							<div class="col-12 quiz-radio" >
								<div class="row">
									<div class="col-12 col-md-6 mb-4 mb-lg-2">
										<div class="quiz-content__subtitle">RUB</div>
										<div class="row">
											<div class="col-12" v-for="(item, i) in step13Rub" :key="i">
												<label class="quiz-radio__label" >
													<input
														type="radio"
														name="quizTsStep14"
														:value="item.text"
														v-model="quizTsStep14"
														class="quiz-radio__input">
													<span class="quiz-radio__checked"></span>
													<span class="quiz-radio__name">{{ item.text }}</span>
												</label>
											</div>
										</div>
									</div>

									<div class="col-12 col-md-6">
										<div class="quiz-content__subtitle">USD</div>
										<div class="row">
											<div class="col-12" v-for="(item, i) in step13Usd" :key="i">
												<label class="quiz-radio__label" >
													<input
														type="radio"
														name="quizTsStep14"
														:value="item.text"
														v-model="quizTsStep14"
														class="quiz-radio__input">
													<span class="quiz-radio__checked"></span>
													<span class="quiz-radio__name">{{ item.text }}</span>
												</label>
											</div>
										</div>
									</div>
									
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(13)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(15)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 15 96% описание-->
			<div class="quiz__block" v-else-if="quizStep === 15">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 96 %</div>
									</div>
								</div>
							</div>
							<div class="col-12 quiz-info__block">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Выберете параметры девушки</div>
							</div>

							<div class="col-12 quiz-textarea mb-3 mb-lg-5">
								<div class="row">
									<div class="col-12">
										<textarea name="" id="" v-model="quizTsStep15" placeholder="Оставьте пожелания к заказу"></textarea>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="d-flex quiz__btns">
									<div class="col-auto me-2 quiz__btn">
										<button @click="quizNavStep(14)">Назад</button>
									</div>
									<div class="col-auto quiz__btn">
										<button @click="quizNavStep(16)">Далее</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step 16 99% телефон-->
			<div class="quiz__block" v-else-if="quizStep === 16">
				<div class="row">
					<div class="col-12 quiz__title">Создайте заказ модели по своим интересам</div>
					<div class="col-12 col-lg-3 col-xl-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">Заполнен на 99 %</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Модели</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 col-xl-5 quiz-content">
						<div class="row">
							<div class="col-12">
								<div class="quiz-content__title">Заказ почти создан</div>
							</div>

							<div class="col-12 quiz-textarea mb-3 mb-lg-5">
								<div class="row">
									<div class="quiz-phone">
										<!-- <vue-tel-input v-model="phone">asdasd</vue-tel-input>
										<label class="quiz-phone__label">
											<input type="text"  v-on:keyup="setTel" v-model="quizTsStep16" class="quiz-phone__input col-12 mb-2" placeholder="+ 7 123 123 12-12">
											<span class="col-12">Номер не виден  в заказе. Вы сами решите, кому его&nbsp;показать</span>
										</label> -->
									</div>

									<div class="custom-tel-input">
										<CustomInput
										class="border"
											ref="input"
											type="tel"
											:id="id"
											:modelValue="modelValue"
											@update:modelValue="onInput"
										>
											<slot>Contact Number</slot>
										</CustomInput>

										<div class="border"><vue-tel-input v-model="phone"></vue-tel-input></div>
										
									
									</div>

								</div>
							</div>
							<div class="col-12 col-md-7 quiz-big__btn">
									<button class="d-block w-100" @click="quizNavStep(17)">Создать заказ</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- step finish -->
			<div class="quiz__block" v-else-if="quizStep === 17">
				<div class="row">
					<div class="col-12 quiz__title">Ваш заказ</div>
					<div class="col-12 col-lg-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">7 просмотров</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="quiz-feedback text-center d-flex flex-column">
									<div class="quiz-feedback__img mt-auto">
										<img src="@/assets/img/quiz/item1.svg" alt="svg">
									</div>
									<div class="quiz-feedback__text mt-3 mb-5">Отклики появятся здесь</div>
								</div>
							</div>
							<div class="col-12 mb-4">
								<div class="quiz-wait d-flex align-items-center justify-content-center">
									<div class="quiz-wait__img me-3 mb-1"><img src="@/assets/img/quiz/item2.svg" alt="svg"></div>
									<div class="quiz-wait__text">Отправляем заказ 11 моделям</div>
								</div>
							</div>
							<div class="col-12 mb-4">
								<div class="quiz-wait d-flex align-items-center justify-content-center">
									<div class="quiz-wait__img me-3 mt-3"><img src="@/assets/img/quiz/item3.svg" alt="svg"></div>
									<div class="quiz-wait__text">Ждем откликов моделей</div>
								</div>
							</div>
							<div class="col-12">
								<!-- <div class="quiz-slider__girls quiz-slider">
									<swiper
										:slidesPerView="1"
										:navigation="swiperOptions.navigations"
										:modules="modules"
										:loop="true"
										:simulateTouch="true"
										:watchSlidesProgress="true"
										:mousewheel="false"
										:spaceBetween="1"
										:grabCursor="false"
										:touchRatio="1"
										:slideToClickedSlide="true"
										class="quiz-slider__block"
									>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<div class="quiz-slider__name">
											<span>Далее</span>
											<span>Анфиса</span>
										</div>
									</swiper>
								</div> -->

							</div>
							<div class="col-12 col-md-6 col-lg-12 mx-auto quiz-big__btn my-4">
								<button class="w-100" @click="$router.push('/quiz-ts/order')">Обсудить детали</button>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 quiz-content quiz-order">
						<div class="row quiz-order__choice">
							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">
									<div class="col-12 quiz-order__title">Параметры TS</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title" >Цвет волос</div>
										<div class="quiz-order__item-text" v-if="quizTsStep1">{{ quizTsStep1 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Возраст</div>
										<div class="quiz-order__item-text" v-if="quizTsStep2">{{ quizTsStep2 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Рост</div>
										<div class="quiz-order__item-text" v-if="quizTsStep3">{{ quizTsStep3 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Размер груди</div>
										<div class="quiz-order__item-text" v-if="quizTsStep4">{{ quizTsStep4 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Размер члена</div>
										<div class="quiz-order__item-text" v-if="quizTsStep5">{{ quizTsStep5 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Национальность</div>
										<div class="quiz-order__item-text" v-if="quizTsStep6">{{ quizTsStep6 }}</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">
									<div class="col-12 quiz-order__title">Выбранные услуги</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Секс</div>
										<div class="quiz-order__item-text" v-if="quizTsStep7">
											<span v-for="(item, i) in quizTsStep7"
										 :key="i">{{ item }}{{ quizTsStep7.length === (i+1) ? '' : ', '}}</span>
										</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Дополнительно</div>
										<div class="quiz-order__item-text" v-if="quizTsStep8.length">
											<span 
												v-for="(item, i) in quizTsStep8"
										 		:key="i"
											>{{ item }}{{ quizTsStep8.length === (i+1) ? '' : ', '}}
											</span>
										</div>
										<div class="quiz-order__item-text" v-else>
											<span>Не&nbsp;интересует</span>
										</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">БДСМ</div>
										<div class="quiz-order__item-text" v-if="quizTsStep9.length">
											<span 
												v-for="(item, i) in quizTsStep9"
										 		:key="i"
											>{{ item }}{{ quizTsStep9.length === (i+1) ? '' : ', '}}
											</span>
										</div>
										<div class="quiz-order__item-text" v-else>
											<span>Не&nbsp;интересует</span>
										</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Массаж</div>
										<div class="quiz-order__item-text" v-if="quizTsStep10.length">
											<span 
												v-for="(item, i) in quizTsStep10"
										 		:key="i"
											>{{ item }}{{ quizTsStep10.length === (i+1) ? '' : ', '}}
											</span>
										</div>
										<div class="quiz-order__item-text" v-else>
											<span>Не&nbsp;интересует</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row quiz-order__choice mt-5">
							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title" >Время</div>
										<div class="quiz-order__item-text" v-if="quizTsStep11">{{ quizTsStep11 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Место встречи</div>
										<div class="quiz-order__item-text" v-if="quizTsStep13">{{ quizTsStep13 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Пожелания к заказу</div>
										<div class="quiz-order__item-text">{{ quizTsStep15 !== '' ? quizTsStep15 :  'Нет'}}</div>
									</div>

								</div>
							</div>

							<div class="col-12 col-lg-6">
								<div class="row quiz-order__list">

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Дата</div>
										<div v-if="date.length">
											<div class="quiz-order__item-text">
												{{ (this.date[0] !== null && this.date[0] !== undefined) ? formateDate(this.date[0]) : '' }}
												{{ (this.date[1] !== null && this.date[1] !== undefined) ? ' - ' + formateDate(this.date[1]) : '' }}
											</div>
										</div>
										<div v-else>
											<div class="quiz-order__item-text">{{ quizTsStep12 }}</div>
										</div>
										
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Стоимость</div>
										<div class="quiz-order__item-text" v-if="quizTsStep14">{{ quizTsStep14 }}</div>
									</div>

									<div class="col-12 quiz-order__item ">
										<div class="quiz-order__item-title">Номер телефона в заказе</div>
										<div class="quiz-order__item-text" v-if="quizTsStep16">{{ quizTsStep16 }}</div>
									</div>

								</div>
							</div>
						</div>
						<div class="row quiz-orde-strip mt-5">
							<div class="col-auto quiz-orde-strip__btn me-5">
								<button>Отменить заказ</button>
							</div>
							<div class="col-3 quiz-orde-strip__block">
								<div class="quiz-orde-strip__name">Номер заказа</div>
								<div class="quiz-orde-strip__text">123123123</div>
							</div>
							<div class="col-3 quiz-orde-strip__block">
								<div class="quiz-orde-strip__name">Создан</div>
								<div class="quiz-orde-strip__text">28.11.2023</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref } from 'vue';
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { FreeMode, Navigation, Thumbs, Pagination, EffectFade, Mousewheel } from 'swiper/modules';

// import 'swiper/css/free-mode';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';

// import 'swiper/swiper-bundle.css';
// import '@/styles/swiper.css'
// import 'swiper/css/effect-fade';

// import Slider from '@/components/blocks/Slider.vue';

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'


export default {
	components: {
		// Swiper,
		// SwiperSlide,
		// Slider,
		VueTelInput,
	},
	data() {
		return {
			date: [],
			quizStep: 1,
			quizTsStep1: 'Не имеет значения',
			quizTsStep2: 'Не имеет значения',
			quizTsStep3: 'Не имеет значения',
			quizTsStep4: 'Не имеет значения',
			quizTsStep5: 'Не имеет значения',
			quizTsStep6: 'Не имеет значения',
			quizTsStep7: ['Секс анальный (я активный)'],
			quizTsStep8: ['Стриптиз'],
			quizTsStep9: ['Не интересует'],
			quizTsStep10: ['Расслабляющий'],
			quizTsStep11: 'Один час',
			quizTsStep12: 'Сегодня, 28 ноября',
			quizTsStep121: '',
			quizTsStep13: 'Апартаменты у TS',
			quizTsStep131: 'Москва, улица Центральная, д. 123, корпус 6, стр. 8, кв. 97',
			quizTsStep14: '2 000 – 5 000 ₽',
			quizTsStep15: '',
			quizTsStep16: '+ 7 123 123 12-12',
			mask: '',

			step1: [
				{
					text: 'Блондинка'
				},{
					text: 'Брюнетка'
				},{
					text: 'Шатенка'
				},{
					text: 'Рыжая'
				},{
					text: 'Русая'
				},{
					text: 'Не имеет значения'
				},
			],
			step2: [
				
				{
					text: '18-23',
				},
				{
					text: '24-28',
				},
				{
					text: '29-35',
				},
				{
					text: '36-40',
				},
				{
					text: '41-50',
				},
				{
					text: 'Не имеет значения'
				},
			],
			stepHeight: [
				{text: 'Низкий - ниже среднего  (150-162 см)'},{text: 'Средний - выше среднего (163-173 см)'},{text: 'Высокий и выше (от 174 см) '},{text: 'Не имеет значения'},
			],
			step3: [
				
				{
					text: '1'
				},
				{
					text: '2'
				},
				{
					text: '3'
				},
				{
					text: '4'
				},
				{
					text: '5'
				},
				{
					text: 'Не имеет значения'
				},
			],
			step4: [
				{text: '7 – 9 XS'},{text: '10 – 12 S'},{text: '13 – 15 M'},{text: '16 – 18 L'},{text: '19 – 21 XL'},{text: '22+ XXL'},{text: 'Не имеет значения'},
			],
			step5: [
				{text: 'Славянская внешность (Россия, Беларусь, Украина, Молдавия и т.д)'},{text: 'Татарки'},{text: 'Китаянки'},{text: 'Негритянки'},{text: 'Кавказская внешность'},{text: 'Не имеет значения'},
			],
			step6: [
				{text: 'Секс анальный (я активный)'},{text: 'Секс анальный (я пассивный)'},{text: 'Секс групповой'},{text: 'Секс с семейной парой'},{text: 'Минет в презервативе (делаю я)'},{text: 'Минет в презервативе (делает TS)'},{text: 'Минет без презерватива (делаю я)'},{text: 'Минет без презерватива (делает TS)'},{text: 'Минет глубокий (делаю я)'},{text: 'Игрушки'},{text: 'Окончание на грудь (делаю я)'},{text: 'Окончание на грудь (делает TS)'},{text: 'Окончание на лицо (делаю я)'},{text: 'Окончание на лицо (делает TS)'},{text: 'Окончание в рот (делаю я)'},{text: 'Окончание в рот (делает TS)'},
			],
			step7: [
				{text: 'Стриптиз'},{text: 'Анилингус вам'},{text: 'Анилингус мне'},{text: 'Золотой дождь вам'},{text: 'Золотой дождь мне'},{text: 'Фистинг анальный вам'},{text: 'Фистинг анальный мне'},{text: 'Фото/Видео съёмка'},{text: 'Виртуальный секс'},{text: 'Секс по телефону'}
			],
			step8: [
				{text: 'Господин (Я)'},{text: 'Госпожа (Вы)'},{text: 'Раб (Я)'},{text: 'Рабыня (Вы)'},{text: 'Доминация'},{text: 'Бандаж'},{text: 'Порка'},{text: 'Фетиш'},{text: 'Трамплинг'},{text: 'Ролевые игры'}
			],
			step9: [
				{text: 'Расслабляющий'},{text: 'Эротический'},{text: 'Профессиональный'},{text: 'Урологический'}
			],
			step10: [
				{text: 'Один час'},{text: 'Два часа'},{text: 'Три часа'},{text: 'Ночь'},
			],
			step11: [
				{text: 'Сегодня, 28 ноября'},{text: 'Завтра, 29 ноября'},{text: 'Выберу день в календаре'},{text: 'Когда удобно TS'},
			],
			step12: [
				{text: 'Апартаменты у TS'},{text: 'Выезд'},
			],
			step13Rub: [
				{text: '2 000 – 5 000 ₽'},{text: '5 000 – 10 000 ₽'},{text: '15 000 – 20 000 ₽'},{text: '20 000 – 30 000 ₽'},{text: '30 000 – 40 000 ₽'},{text: '50 000 – 100 000 ₽'},{text: 'Предложит модель'}
			],
			step13Usd: [
				{text: '20 – 50 $'},{text: '50 – 100 $'},{text: '150 – 200 $'},{text: '200 – 300 $'},{text: '300 – 400 $'},{text: '500 – 1000 $'}
			],
			swiperOptions: {
				navigations: true
			},

		}
	},
	methods: {
		setTel(event) {
      const val = event.target.value.replace(/[^0-9]/g, "");
			event.target.value = val
    },
		renderNextMonth() {
      this.countPaginate++
    },
		quizNavStep(step) {
			this.quizStep = step
		},
		formateDate(date) {
			let currentDate = new Date(date);
			const day = currentDate.getDate();
			const month = currentDate.getMonth() + 1;
			const year = currentDate.getFullYear();

			return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`
		}
	},
	// setup() {
	// 	const thumbsSwiper = ref(null);
	// 	const setThumbsSwiper = (swiper) => {
	// 	thumbsSwiper.value = swiper;
	// }
	
	// return {
	// 	thumbsSwiper,
	// 	setThumbsSwiper,
	// 	modules: [FreeMode, Navigation, Thumbs, Pagination, EffectFade, Mousewheel],
	// 	};
	// },
}
</script>

<style lang="scss">
// .iti__flag {
//   background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/img/flags.png");
// }

// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//   .iti__flag {
//     background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/img/flags@2x.png");
//   }
// }
</style>