<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: 'ModalCard',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        name: {
            type: [String,Boolean],
            default: false
        }
    },
    methods: {
        hideDialog() {
            this.$emit('hideDialog');
        }
    }
})
</script>

<template>
    <div class="dialog" v-if="show">
        <div @click.stop class="dialog-content">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped>
.dialog {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    z-index: 999;
}

.dialog-content {
    margin: auto;
    padding: 15px;
    border-radius: 24px;
    background: #72666A;
    color: #000;
    background-repeat: no-repeat;
    background-position: top right;
    max-width: 600px;
    border: 1px solid #2D2E2E;
}
</style>