<template>
    <div class="row px-2">
        <h5 class="text-white mb-3 px-0">
            Продвижение в каналах
        </h5>
        <div class="card-body_block">
          <div class="text__block">
            <p class="text__descr mb-3">
              Вы можете присылать нам свои фото и видео, на разные темы, по услугам которые вы предоставляете, ваши шалости, дефиле, всё, что может привлечь внимание на вашу анкету😈.
            </p>
            <p class="text__descr">
              Мы в свою очередь, будем размещать их в своих официальных каналах. Это даст максимальный охват аудитории именно к вашей анкете
            </p>
          </div>
          <div class="demand__block">
            <p class="demand__block-title my-3">
              Требования
            </p>
            <div class="demand__wrapper-card pb-5 pt-3 justify-content-start">
              <div class="demand__card col-3 d-flex align-items-center position-relative" v-for="(item, index) of demandCard" :key="index">
                <img class="position-absolute" :class="'demand__img-float-' + index" :src="require(`@/assets/img/${item.imgBefore}.svg`)" alt="icon">
                <p class="demand__card-text" :class="'text-' + index">
                  {{item.text}}
                </p>
              </div>
            </div>
          </div>

          <div class="example__photo mt-4">
            <p class="example__photo-title mb-4">
              Примеры фото и видео
            </p>
                <div class="example__wrapper_card">
                  <div class="example__photo-card col-auto px-0" v-for="path of examplePhoto.path" :key="path">
                    <img class="example__stub-img" :src="require(`@/assets/img/promotionTg/${path}.png`)" alt="img"/>
                  </div>
              </div>
          </div>


          <div class="warn__demand-block d-flex">
            <div>
              <p class="warn__text-link mb-3">
                Присылать вот сюда <a href="https://t.me/EgozaComfortManager">Telegram</a>
              </p>
              <div class="d-flex warn__wrapper-icon">
                <div class="d-flex align-items-end">
                  <img src="@/assets/img/warnImg.svg" alt="icon" class="warn__img">
                </div>
                <p class="warn__text-descr my-0">
                  Все примеры взяты из общего доступа, никакого отношения девушки к проекту Egoza не имеют. Это всего лишь пример.
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  data(){
   return{
     examplePhoto: {
       path: [
         'IMG_2503',
         'IMG_2504',
         'IMG_2505',
         'IMG_2506',
         'IMG_2508',
         'IMG_2509',
         'IMG_2511',
         'IMG_2514',
         'IMG_2516',
         'IMG_2517',
         'IMG_2518',
         'IMG_2520',
         'IMG_2521',
         'IMG_2524',
       ],
     },
     demandCard: [
       {
         text: 'Хронометраж видео до 15 секунд, в хорошем качестве',
         imgBefore: 31
       },
       {
         text: 'Фото в хорошем качестве. Квадратное или вертикальное',
         imgBefore: 301
       },
       {
         text: 'Важно! Отправляйте незабываемые видео и фото, превращающие анкеты посетителей в фанатов',
         imgBefore: 21
       }
     ]
   }
  },
}
</script>

<style scoped lang="scss">

.text-0{
  max-width: 300px;
  width: 100%;
}

.text-1{
  max-width: 329px;
  width: 100%;
}

.text-2{
  max-width: 371px;
  width: 100%;
}

.card-body_block{
    border-radius: 12px;
    background: rgb(255, 255, 255);
    padding: 30px 30px 60px 30px;
  .text{
    &__descr{
      max-width: 1450px;
      color: #525665;
      font-size: 18px;
      line-height: 120%;
      width: 100%;
      @media (max-width: 450px) {
        font-size: 16px;
      }
    }
  }

  .demand {
    &__block {
      border-bottom: 1px solid rgb(189, 193, 209);
    }

    &__block-title {
      color: rgb(82, 86, 101);
      font-size: 24px;
      font-weight: 700;
      line-height: 100%;
      text-align: left;
      font-family: "Roboto", sans-serif;
    }

    &__card{
      background: url("../../assets/img/demandCard.png") no-repeat center/cover;
      border-radius: 23px;
      max-width: 584px;
      width: 100%;
      flex: 1 0 calc(584px - 296px);
      height: 220px;
      min-height: 200px;
      @media (max-width: 485px) {
        height: 200px;
        padding-left: 5px;
        justify-content: center;
      }
    }

    &__wrapper-card{
      display: flex;
      gap: 29px 13px;
      flex-wrap: wrap;
    }

    &__card-text{
      color: rgb(255, 255, 255);
      font-size: 20px;
      font-weight: 600;
      line-height: 100%;
      text-align: left;
      padding-left: 74px;
    }

    &__img-float-0{
      top: 0;
      right: 0;
    }
    &__img-float-1{
      top: 5px;
      right: 10px;
    }
    &__img-float-2{
      top: 15px;
      right: 0;
    }

    @media (max-width: 1560px) {
      &__card-text{
        padding-left: 35px;
        max-width: 260px;
      }
    }
    @media (max-width: 1380px){
      &__img-float-0{
        width: 120px;
      }
      &__img-float-1{
        width: 100px;
      }
      &__img-float-2{
        width: 100px;
      }
    }
    @media (max-width: 1160px){
      &__card-text{
        font-size: 17px;
        font-weight: 400;
        padding-top: 14px;
        padding-left: 20px;
        max-width: 220px;
      }
    }
    @media (max-width: 990px){
        &__wrapper-card{
          flex-wrap: wrap;
        }
      &__card{
        background: url("../../assets/img/demandMobile.png") no-repeat center/cover;
        max-width: 322px;
        width: 100%;
        flex: 1 0 100%;
        height: 200px;
        justify-content: center;
      }
      &__card-text{
        padding-top: 0;
        padding-left: 0;
        font-size: 14px ;
        line-height: 120%;
      }
      &__img-float-0{
        top: 20px;
        right: 20px;
        width: 85px;
      }
      &__img-float-1{
        top: 20px;
        right: 20px;
        width: 59px;
      }
      &__img-float-2{
        top: 20px;
        right: 20px;
        width: 62px;
      }
    }
  }

  .example{
    &__photo-title {
      color: rgb(82, 86, 101);
      font-family: Roboto;
      font-size: 24px;
      font-weight: 700;
      line-height: 100%;
      text-align: left;
    }
    &__photo-card{
      max-width: 160px;
      width: 100%;
      flex: 1 0 calc(160px - 30px);
    }
    &__stub-img {
      box-sizing: border-box;
      border: 0.95px solid rgb(217, 217, 217);
      border-radius: 7.57px;
      height: 220px;
      object-fit: cover;
    }
    &__wrapper_card{
      gap: 40px 13px;
      flex-wrap: wrap;
      display: flex;
    }
    &__title-img{
      margin-top: 10px;
      color: rgb(82, 86, 101);
      font-size: 17px;
      font-weight: 600;
      line-height: 100%;
      text-align: center;
    }
    @media (max-width: 480px) {
      &__photo-card{
        max-width: 148px;
        width: 100%;
        flex: 1 0 calc(148px - 30px);
      }
    }
  }

  .warn{
    &__demand-block{
      margin-top: 80px;
    }
    &__img{
      max-width: 48px;
      width: 48px;
      height: 48px;
      margin-right: 10px;
    }
    &__text-descr,
    &__text-link{
      font-size: 20px;
      font-weight: 600;
      line-height: 100%;
      text-align: left;
      color: rgb(82, 86, 101);
    }

    &__text-link{
      padding-left: 58px;
    }

    &__text-descr{
      max-width: 400px;
      width: 100%;
      line-height: normal;
    }
    @media (max-width: 480px) {
      &__text-descr,
      &__text-link{
        font-size: 14px;
        padding-left: 0;
      }
      &__wrapper-icon{
        flex-direction: column;
      }
      &__img{
        width: 29px;
        height: 29px;
      }
    }
  }
}
</style>