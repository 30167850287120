<script>
import axios from 'axios';
import CardWhite from '@/components/ui/CardWhite.vue';
import CopyText from '@/components/ui/CopyText.vue';
import AdminModalTable from "@/components/ui/AdminModalTable.vue";

export default {
  name: 'AdminModerationBilling',
  components: {AdminModalTable, CopyText, CardWhite},
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomine: this.$store.getters.getApiDomine,
      adding: [],
      offset: null,
      error: '',
      userScreenOpen: false,
      userScreenImg: '',
      singleBilling: {},
      isModalDetail: false
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      axios
          .get(
              this.apiUrl +
              'api-billing/get-adding-list' +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key
          )
          .then((response) => {
            if (response.data.status !== false) {
              this.adding = response.data;
            }
            this.error = response.data.error;
          });
    },
    timeToDay(time) {
      let dateAdd = new Date(time * 1000);
      let date =
          dateAdd.getDate() < 10 ? '0' + dateAdd.getDate() : dateAdd.getDate();
      let rawMonth = dateAdd.getMonth() + 1;
      let month = rawMonth < 10 ? '0' + rawMonth : rawMonth;

      let year =
          dateAdd.getFullYear() < 10
              ? '0' + dateAdd.getFullYear()
              : dateAdd.getFullYear();
      let hours =
          dateAdd.getHours() < 10 ? '0' + dateAdd.getHours() : dateAdd.getHours();
      let minutes =
          dateAdd.getMinutes() < 10
              ? '0' + dateAdd.getMinutes()
              : dateAdd.getMinutes();
      let seconds =
          dateAdd.getSeconds() < 10
              ? '0' + dateAdd.getSeconds()
              : dateAdd.getSeconds();
      return date + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    },
    acceptPay(id) {
      axios
          .post(
              this.apiUrl +
              'api-billing/amount-adding-status' +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
              {
                id: id,
                status_id: 1,
              }
          )
          .then((response) => {
            this.updateData();
            this.isModalDetail = false
          });
    },

    discardPay(id) {
      axios
          .post(
              this.apiUrl +
              'api-billing/amount-adding-status' +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
              {
                id: id,
                status_id: 4,
              }
          )
          .then((response) => {
            this.updateData();
            this.isModalDetail = false
          });
    },
    openScreen(img) {
      this.userScreenOpen = true
      this.userScreenImg = this.apiDomine + '/web/uploads/' + img
    },
    clearScreen() {
      this.userScreenImg = ''
    },
    openModalDetail(item) {
      if (window.innerWidth > 950) return

      this.singleBilling = item
      this.isModalDetail = true
    },
  },
};
</script>
<template>
  <main>
    <h1 class="text-white">Модерация платежей</h1>
    <card-white class="mt-3">

      <div class="table-wrapper">
        <table class="responsive-table">
          <thead>
          <tr>
            <td class="text-white">Наш колешелёк</td>
            <td class="text-white">Имя или ник</td>
            <td class="text-white">Кошелёк пользователя</td>
            <td class="text-white">RUB</td>
            <td class="text-white">USDT</td>
            <td class="text-white">Номер заявки</td>
            <td class="text-white">Скрин</td>
            <td class="text-white">Дата</td>
            <td class="text-white">Действия</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in this.adding" :key="i" @click.stop="openModalDetail(item)" class="settings-table__row">
            <td id="wallet" class="settings-table__item text-white wallet-text">{{ item.wallet }}</td>
            <td id="userName" class="settings-table__item text-white">{{ item.user.username }}</td>
            <td id="userWallet" class="settings-table__item text-white wallet-text">{{ item.userWallet ?? '—' }}</td>
            <td id="rub" class="settings-table__item text-white">{{ item.rub.replace(/0+$/, '') }}</td>
            <td id="usdt" class="settings-table__item text-white">{{ item.usdt.replace(/0+$/, '') }}</td>
            <td id="request_num" class="settings-table__item text-white">{{ item.request_num ?? '—' }}</td>
            <td id="screen" class="settings-table__item text-white">
            <span class="settings-table__span"
                  @click="item.photo?.image !== undefined ? openScreen(item.photo?.image): ''"
            >Открыть</span
            >
            </td>
            <td id="date" class="settings-table__item text-white">{{ this.timeToDay(item.date_add) }}</td>
            <td id="btns" class="settings-table__item text-white">
              <button
                  class="btn btn-success p-1 me-2 hover-btn"
                  @click="acceptPay(item.id)"
              >
                <i class="bi bi-check"></i>
              </button>
              <button
                  class="btn btn-danger p-1 hover-btn"
                  @click="discardPay(item.id)"
              >
                <i class="bi bi-x"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <AdminModalTable
      v-if="isModalDetail"
      :tableContent="[
          {title: 'Наш кошелёк', content: singleBilling.wallet},
          {title: 'Имя или ник', content: singleBilling.user.username},
          {title: 'Кошелёк пользователя', content: singleBilling.userWallet ?? '—'},
          {title: 'RUB', content: singleBilling.rub.replace(/0+$/, '')},
          {title: 'USDT', content: singleBilling.usdt.replace(/0+$/, '')},
          {title: 'Номер заявки', content: singleBilling.request_num ?? '—'},
          {title: 'Скрин', content: singleBilling.photo?.image ?? '—'},
          {title: 'Дата', content: this.timeToDay(singleBilling.date_add)}
      ]"
      @closeModal="isModalDetail = false"
      >
        <template v-slot:buttons>
          <button
              class="btn btn-success p-1 me-2 hover-btn"
              @click="acceptPay(singleBilling.id)"
          >
            <i class="bi bi-check"></i>
          </button>
          <button
              class="btn btn-danger p-1 hover-btn"
              @click="discardPay(singleBilling.id)"
          >
            <i class="bi bi-x"></i>
          </button>
        </template>
      </AdminModalTable>
      <Transition name="fade">
        <div class="example" v-if="userScreenOpen" @click="userScreenOpen = false; this.clearScreen()">
          <div class="example-images" @click.stop>
            <img
                :src="userScreenImg"
                alt=""
                class="example-images__img"
            />
            <button class="example-images__close" @click="userScreenOpen = false; this.clearScreen()">
              X
            </button>
          </div>
        </div>
      </Transition>
    </card-white>
  </main>
</template>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.example {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  z-index: 9999;

  &-images {
    width: 500px;
    height: 500px;
    position: relative;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    &__close {
      position: absolute;
      top: -45px;
      right: 3px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 9px;
      cursor: pointer;
    }
  }
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px 10px 0px 0px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table thead {
  background-color: #2C2F3C;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #7e7e7e7e;
}

thead tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

thead tr:last-child td:last-child {
  border-top-right-radius: 10px;
}

.responsive-table th {
  background-color: #f2f2f2;
  color: #333;
}

.responsive-table td {
  padding: 5px 10px;
  text-align: center;
  line-height: normal;
  max-width: 150px;
}

.settings-table {
  color: #fff;

  &__head {
    background-color: #2C2F3C;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #7e7e7e7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__row {
    background-color: #2C2F3C;
    position: relative;


    &:nth-child(odd) {
      background-color: #2C2F3C;
    }

    button {
      color: #fff;
    }
  }

  &__row:hover {
    background-color: #524B5F;
  }

  &__item {
    border-bottom: solid 1px #7e7e7e7e;
  }
}


.btn-red {
  background: linear-gradient(
          0deg,
          rgba(236, 34, 34, 1) 0%,
          rgba(170, 13, 5, 1) 100%
  );
  display: inline-block;
  border: solid 1px #2c2f3c;
  font-size: 10px;
  border-radius: 4px;
  color: #fff !important;
  transition: 0.3s;
}

.btn-green {
  background: linear-gradient(
          0deg,
          rgba(23, 125, 33, 1) 0%,
          rgba(63, 82, 54, 1) 100%
  );
  border: solid 1px #2c2f3c;
  font-size: 10px;
  border-radius: 4px;
  color: #fff !important;
  transition: 0.3s;
}

@media (max-width: 950px) {
  .responsive-table > thead td:nth-child(7),
  .responsive-table > thead td:nth-child(8),
  .responsive-table > thead td:nth-child(9) {
    display: none;
  }

  #btns,
  #date,
  #screen {
    display: none;
  }
}

@media (max-width: 690px) {

  .responsive-table > thead td:nth-child(3),
  .responsive-table > thead td:nth-child(5),
  .responsive-table > thead td:nth-child(6) {
    display: none;
  }
  #userWallet,
  #usdt,
  #request_num {
    display: none;
  }
}
</style>
