/**
 * для работы необходимо подключить в компоненте cookies
 * import {useCookies} from "vue3-cookies";
 *
 * setup() {
 *         const {cookies} = useCookies();
 *         return {cookies};
 *
 *     },
 */
export default {
    methods:{
        /**
         * Добавляет flash сообщения
         * @param title String Заголовок
         * @param massage String Текст сообщения
         * @param type String тип сообщения может быть одним из типов сообщений bootstrap
         * (danger| info ...)
         */
        m_setFlash(title, massage, type) {

            let flash
            
            if (this.cookies && this.cookies.isKey('flash')) {
                flash = JSON.parse(this.cookies.get('flash'))

                flash.push({title: title, massage: massage, type: type})
            } else {
                flash = []
                flash.push({title: title, massage: massage, type: type})
            }

            if (this.cookies) {
                this.cookies.set('flash', JSON.stringify(flash))
            }
        }
    }
}