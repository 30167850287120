<script setup>
import OrderBackwardButton from '@/components/order_modal/OrderBackwardButton.vue';
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
import { onMounted, ref } from 'vue';

const props = defineProps({
    initialPlace: {
        type: [String, null],
        required: true,
        default: '',
    },
});
const emit = defineEmits(['next-step']);

const currentPlace = ref('');

onMounted(() => {
    currentPlace.value = props.initialPlace;
});
</script>

<template>
    <div class="order-place">
        <div class="order-place__question mb-3">
            <p>Укажите адрес, где планируете встречу</p>
        </div>
        <div class="order-place__content d-flex flex-column gap-3">
            <div class="order-place__description">
                <textarea
                    class="p-2"
                    name="order-place"
                    id="order-place__description"
                    v-model="currentPlace"
                    maxlength="200"
                ></textarea>
            </div>
            <div class="order-place__buttons ms-auto">
                <OrderBackwardButton
                    class="h-100"
                    @click="emit('next-step', { place: currentPlace, forward: false })"
                />
                <OrderNextButton
                    :disabled="!currentPlace"
                    class="h-100"
                    @click="emit('next-step', { place: currentPlace, forward: true })"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-place {
    .order-place__question {
        font-size: 16px;
        line-height: 20px;
    }

    .order-place__content {
        .order-place__description {
            width: 100%;
            textarea {
                width: 100%;
                min-height: 70px;
                font-size: 14px;
                border-radius: 12px;
            }
        }
    }
}
</style>
