<script>
export default {
    name: 'open-icon-solid',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        size: {
            type: Object,
            default() {
                return { w: 24, h: 24 };
            },
        },
    },
};
</script>

<template>
    <svg
        :width="size.w"
        :height="size.h"
        :viewBox="`0 0 ${size.w} ${size.h}`"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 12C4 8.22876 4 6.34315 5.17157 5.17157C6.34315 4 8.22876 4 12 4C15.7712 4 17.6569 4 18.8284 5.17157C20 6.34315 20 8.22876 20 12C20 15.7712 20 17.6569 18.8284 18.8284C17.6569 20 15.7712 20 12 20C8.22876 20 6.34315 20 5.17157 18.8284C4 17.6569 4 15.7712 4 12ZM12.4 8C12.4 7.66863 12.6686 7.4 13 7.4H16C16.3314 7.4 16.6 7.66863 16.6 8V11C16.6 11.3314 16.3314 11.6 16 11.6C15.6686 11.6 15.4 11.3314 15.4 11V9.44853L9.44853 15.4H11C11.3314 15.4 11.6 15.6686 11.6 16C11.6 16.3314 11.3314 16.6 11 16.6H8C7.66863 16.6 7.4 16.3314 7.4 16V13C7.4 12.6686 7.66863 12.4 8 12.4C8.33137 12.4 8.6 12.6686 8.6 13V14.5515L14.5515 8.6H13C12.6686 8.6 12.4 8.33137 12.4 8Z"
            :fill="color"
        />
    </svg>
</template>
