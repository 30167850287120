<script>
export default {
    name:'HeartIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:23, h:23}
        }
    }
}
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg"  :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w + 1) + ' ' + (this.size.h + 1)"  fill="none">
        <path d="M7.26872 7.49708C8.60954 6.67461 9.77981 7.00606 10.4828 7.53401C10.7711 7.75048 10.9152 7.85871 11 7.85871C11.0848 7.85871 11.2289 7.75048 11.5172 7.53401C12.2202 7.00606 13.3905 6.67461 14.7313 7.49708C16.491 8.57647 16.8891 12.1374 12.8302 15.1417C12.0571 15.7139 11.6706 16 11 16C10.3294 16 9.94286 15.7139 9.16976 15.1417C5.11086 12.1374 5.50903 8.57647 7.26872 7.49708Z" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z" :stroke="this.color" stroke-width="1.5"/>
        </svg>
</template>
