<script>
export default {
    name: 'close-icon',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        size: {
            type: Object,
            default() {
                return { w: 12, h: 12 };
            },
        },
    },
};
</script>

<template>
    <svg
        :width="size.w"
        :height="size.h"
        :viewBox="`0 0 ${size.w} ${size.h}`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 0.814454L6 5.84439M6 5.84439L11 10.8743M6 5.84439L11 0.814453M6 5.84439L1 10.8743"
            :stroke="color"
            stroke-width="0.980838"
            stroke-linecap="round"
        />
    </svg>
</template>
