<script setup>
const props = defineProps({
    stepCount: {
        type: Number,
        required: true,
        default: 3,
    },
    currentStep: {
        type: Number,
        required: true,
        default: 1,
    },
});
</script>
<template>
    <div class="order-progress-bar d-flex flex-nowrap">
        <div
            v-for="step in stepCount"
            :key="`step_${step}`"
            class="col order-progress-bar__item"
            :style="{ backgroundColor: step === currentStep ? '#ff3288' : '#35313f' }"
        ></div>
    </div>
</template>
<style lang="scss" scoped>
.order-progress-bar {
    height: 4px;
    width: 100%;

    .order-progress-bar__item {
        border-radius: 63px;
    }
}
</style>
