<script>
import axios from 'axios';
import CardWhite from '@/components/ui/CardWhite.vue';
import BillingHistoryWidget from '@/components/ui/billing/BillingHistoryWidget.vue';
import AddingForm from '@/components/ui/billing/AddingForm.vue';
import AddingFromNoWallet from '@/components/ui/billing/AddingFromNoWallet.vue';

export default {
  name: 'Billing',
  components: {
    AddingForm,
    BillingHistoryWidget,
    CardWhite,
    AddingFromNoWallet,
  },
  methods: {},
  data() {
    return {
      user: this.$store.getters.getUser,
      walletHave: false,
      walletNo: false,
      contols: false,
      isModalOpen: false,
      modalVideo: false
    };
  },
  methods: {
    playVideo() {
      const video = this.$refs.video;
      if (video.paused) {
        video.play();
        this.contols = true;
      } else {
        video.pause();
        this.contols = false;
      }
    },
  },
};
</script>

<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-3 title">Финансы</div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <card-white>
        <div class="row">
          <div class="col-12 col-xl-6">
            <div class="billing-choice h-100">
              <adding-form
                :rate="92.36"
                :wallet="'TPK6RsQAyb2ZP4w98dk1PWZQDzh2vqcQXT'"
                @modalOpen="
                  (value) => {
                    this.isModalOpen = value;
                  }
                "
                @noWallet="
                  (value) => {
                    this.walletNo = value;
                  }
                "
                @haveWallet="
                  (value) => {
                    this.walletHave = value;
                  }
                "
              />
              <AddingFromNoWallet
                :rate="92.36"
                :wallet="'TPK6RsQAyb2ZP4w98dk1PWZQDzh2vqcQXT'"
                @modalOpen="
                  (value) => {
                    this.isModalOpen = value;
                  }
                "
                v-if="walletNo"
              />
            </div>
          </div>
          <div class="col col-xl-6 billing-blocks">
            <h4 class="billing-blocks__title">
              Посмотрите видео как оплатить с обменника
            </h4>
            <div class="billing-blocks__images position-relative">
              <img class="poster" src="@/assets/img/poster-video.jpg" alt="">
              <img @click="modalVideo = true" class="poster-play" src="@/assets/img/poster-play.png" alt="">
            </div>
          </div>
          <div
            class="col-12 mt-5"
            v-if="walletHave == true || walletNo == true"
          >
            <hr />
          </div>
          <div
            class="col-12 mt-5"
            v-if="walletHave == true || walletNo == true"
          >
            <billing-history-widget />
          </div>
        </div>
        
      </card-white>
    </div>
  </div>
  <Transition name="fade">
    <div class="modal-billing" v-if="isModalOpen" @click="isModalOpen = false">
      <div class="modal-billing__block" @click.stop>
        <p class="modal-billing__text">
          <span> Подтверждение оплаты принято.</span>
          <span
            >Ваша заявка обрабатывается <br />
            оператором.</span
          >
          <span
            >После того как мы получим ваши деньги, мы зачислим
            их на ваш баланс.</span
          >
        </p>
        <button class="modal-billing__close" @click="isModalOpen = false">
          X
        </button>
      </div>
    </div>
  </Transition>
  <Transition name="fade">
    <div class="video-block" @click="modalVideo = false" v-if="modalVideo">
      <div class="video-element" @click.stop>
        <video  src="@/assets/video/billingVideo.mp4" playsinline
                 webkit-playsinline autoplay controls></video>
        <button class="video-block__btn" @click="modalVideo = false">✖</button>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.video-element{
    max-width: 280px;
    width: 100%;
    position: relative;
  
}
.video-block{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  &__btn{
    position: absolute;
    top: -50px;
    right: 0;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 9px;
    cursor: pointer;
  }
  & video{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 3px solid  #524B5F;
  }
}
.modal-billing {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  &__text {
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 117%;
    text-align: center;
    color: #525665;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__block {
    border-radius: 9px;
    max-width: 470px;
    width: 100%;
    background: #fff;
    padding: 70px 5px;
    position: relative;
  }
  &__close {
    position: absolute;
    top: -52px;
    right: 0;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 9px;
    cursor: pointer;
  }
}
.poster-play{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 66px;
  height: 66px;
  transition:  .3s;
  cursor: pointer;
}
.title {
  color: #fff;
  font-size: 30px;
}
.cursor {
  cursor: pointer;
}

.billing-blocks__title {
  @media (max-width: 768px) {
    margin-top: 15px;
    text-align: center;
  }
}
.billing-blocks__title{
  margin-bottom: 10px;
}
.billing-blocks{
  &__images{
    & .poster{
      width: 100%;
      object-fit: cover;
      min-height: 360px;
      border-radius: 20px;
    }
  }
}
</style>
