<script>
import Footer from '@/components/blocks/Footer.vue'
import HeaderSmall from '@/components/ui/header/HeaderSmall.vue'
import AdminButton from "@/components/ui/AdminButton.vue";
export default {
    name: 'customerLayout',
    components: {
        HeaderSmall,
        Footer,
        AdminButton
    },
}
</script>
<template>
    <HeaderSmall/>
    <main>
        <router-view />
    </main>
    <Footer/>
    <admin-button/>
</template>
<style></style>