<template>
	<div class="switch">
		<div class="switch__name" :class="{'active': local === 'ru'}" @click="changeLocation">ru</div>
		<span :class="['switch__span', 'switch__span--' +local]"></span>
		<div class="switch__name" :class="{'active': local === 'en'}" @click="changeLocation">en</div>
	</div>
</template>

<script>
	export default {
		name: 'UiSwitch',
		data () {
			return {
				local: 'ru',
			}
		},
		methods: {
			changeLocation(e) {
				const elem = e.target
				this.local = elem.textContent
			}
		}
}
</script>
<style lang="scss">
	@import '@/styles/index';
	.switch {
		display: flex;
		align-items: center;
		&__span {
			width: 25px;
			height: 14px;
			border-radius: 300px;
			margin: 0 10px;
			position: relative;
			background: #8E7F7D;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
			&::after {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #D9D9D9;
				position: absolute;
				top: 2px;
				transition: 0.3s;
			}
			&--ru {
				&::after {
					left: 2px;
				}
			}
			&--en {
				&::after {
					left: calc(100% - 2px);
					transform: translateX(-100%);
				}
			}
		}
		&__name {
			color: #838383;
			font-size: 14px;
			line-height: 120%;
			font-weight: 500;
			display: inline-block;
			cursor: pointer;
			position: relative;
			border-bottom: solid 1px transparent;
			text-transform: capitalize;
			&.active {
				border-bottom: solid 1px;
				color: #fff;
			}
			
		}
	}
</style>