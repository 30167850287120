<script>
export default {
    name: 'promo-faq',
};
</script>

<template>
    <section class="promo-faq__container">
        <h2 class="promo-faq__title text-center">FAQ</h2>
        <div
            class="accordion"
            id="promo-faq__accordion"
        >
            <div class="accordion-item">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-1"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-1"
                    >
                        Премиум
                    </button>
                </h3>
                <div
                    id="panelsStayOpen-1"
                    class="accordion-collapse collapse"
                >
                    <div class="accordion-body">
                        Категория, в которой собраны все топовые эскортницы, эстетичные и сексуальные модели как с
                        обложек модных журналов, отвязные дебоширки словно Харли Квинн, готовые на многое для своих
                        гостей. Тариф начинается от 20 000 руб. Попасть в эту категорию не просто из-за строгих
                        критериев отбора анкет и тщательной модерации.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-2"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-2"
                    >
                        Инди
                    </button>
                </h3>
                <div
                    id="panelsStayOpen-2"
                    class="accordion-collapse collapse"
                >
                    <div class="accordion-body">
                        Доступный выбор для тех, кто ценит качество, ищет удовольствие и наслаждение от встреч.
                        Мастерство и страсть дадут вам незабываемые моменты в постели. Тариф начинается от 3&nbsp;000
                        руб.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-3"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-3"
                    >
                        Будут ли новые категории?
                    </button>
                </h3>
                <div
                    id="panelsStayOpen-3"
                    class="accordion-collapse collapse"
                >
                    <div class="accordion-body">
                        Премиум и инди, это первые две категории с которых сервис начинает свою работу, планируем
                        добавить категорию для Боди – массажисток, салонов эротического массажа, агентств.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-4"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-4"
                    >
                        В каких городах будет работать сервис?
                    </button>
                </h3>
                <div
                    id="panelsStayOpen-4"
                    class="accordion-collapse collapse"
                >
                    <div class="accordion-body">
                        На старте сервис предложил три города, Москва, Санкт – Петербург, Екатеринбург, но, если вы из
                        другого города, вы можете так же разместить свою анкету, ваш город станет доступным в выборе
                        городов.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-5"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-5"
                    >
                        Как проходит верификация анкеты?
                    </button>
                </h3>
                <div
                    id="panelsStayOpen-5"
                    class="accordion-collapse collapse"
                >
                    <div class="accordion-body">
                        Стандартно мы сделали верификацию анкеты по видео проходке, если вы желаете верифицироваться
                        другим способом, свяжитесь с нашим комфорт менеджером, вы обязательно с ним что-нибудь
                        придумаете!
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-6"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-6"
                    >
                        Будет ли верификация по возрасту?
                    </button>
                </h3>
                <div
                    id="panelsStayOpen-6"
                    class="accordion-collapse collapse"
                >
                    <div class="accordion-body">
                        Ее пока нет, не знаем нужна ли она, если будут плохие преценденты, то ее легко добавить.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h3 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-7"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-7"
                    >
                        Как попасть на главную страницу?
                    </button>
                </h3>
                <div
                    id="panelsStayOpen-7"
                    class="accordion-collapse collapse"
                >
                    <div class="accordion-body">
                        Что бы попасть на главную страницу, необходимо воспользоваться услугами продвижения на сайте,
                        необходимо зарегистрироваться или войти
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-faq__container {
    .promo-faq__title {
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        font-style: normal;
    }

    .accordion {
        margin-bottom: 60px;

        //bgs
        --bs-accordion-bg: #13131a;
        --bs-accordion-active-bg: #13131a;
        --bs-accordion-btn-bg: #13131a;

        //colors
        --bs-accordion-color: $font-main-color;
        --bs-accordion-active-color: $font-main-color;
        --bs-accordion-btn-color: $font-main-color;

        //borders
        --bs-accordion-border-width: 0;
        --bs-accordion-border-radius: 50px;
        --bs-accordion-inner-border-radius: 50px;

        //other
        --bs-accordion-btn-focus-box-shadow: none;
        --bs-accordion-btn-icon-width: 14px;
        --bs-accordion-btn-icon-transform: rotate(180deg);
        --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6.76721 7.21084C7.16284 7.6369 7.83716 7.6369 8.23279 7.21084L14 1' stroke='%23BF5152' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg  width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6.76721 7.21084C7.16284 7.6369 7.83716 7.6369 8.23279 7.21084L14 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");

        .accordion-item {
            border-radius: 50px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .accordion-button {
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;

                padding: 30px 20px 30px 35px;

                font-size: 20px;
                line-height: 26px;
                font-weight: 700;

                &.collapsed {
                    border-radius: 50px;
                }

                &::after {
                    background-color: #2d2f3a;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background-position: center center;
                }
            }

            .accordion-body {
                padding-left: 35px;
                padding-right: 50px;
                padding-bottom: 35px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .promo-faq__container {
        .promo-faq__title {
            margin-bottom: 45px;
        }
        > div:last-child {
            margin-bottom: 95px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-faq__container {
        .promo-faq__title {
            @include text-h2-large;
            margin-bottom: 60px;
        }

        > div:last-child {
            margin-bottom: 135px;
        }
        .accordion {
            .accordion-item {
                margin-bottom: 25px;

                .accordion-button {
                    @include text-bold-small;
                    padding: 65px 50px 50px 65px;
                }

                .accordion-body {
                    padding-left: 65px;
                    padding-right: 50px;
                    padding-bottom: 65px;
                }
            }
        }
    }
}
</style>
