<script setup>
import {
  onMounted,
  onBeforeUnmount,
  ref,
  onBeforeMount,
  computed,
  watch,
} from 'vue';
import MessengerSidebar from './MessengerSidebar.vue';
import MessengerArea from './MessengerArea.vue';
import MessengerAction from './MessengerAction.vue';
import MessengerButton from '@/components/messenger/MessengerButton.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import axios from 'axios';

const store = useStore();
const route = useRoute();
const user = store.getters.getUser;
const apiUrl = store.getters.getApiUrl;
const activeChat = computed(() => {
  return store.getters.getActiveChat;
});

const isMessengerShow = ref(false);
const isMessengerHide = ref(false);
const isSidebarShow = ref(true);

const openMessagerHandler = async () => {  
  if (route.path && route.path.startsWith('/anketa/')) {
    const anketaID = route.params.id || null;

    const params =
      '&auth=' + user.username + ':' + user.auth_key + '&id=' + anketaID;
    const { data } = await axios.get(
      apiUrl + 'api-girl/get-profile-by-id' + params
    );

    const recipientId = data.user_id
    const recipientName = data.name

    if (recipientId) {
      openMessenger()
      isSidebarShow.value = false

      const auth = '&auth=' + user.username + ':' + user.auth_key

      const { data } = await axios.get(
        apiUrl + 'api-chat/get-private-chat' + `${auth}&user_id=${user.user_id}&target_user_id=${recipientId}`
      )

      let chatId = data.id || null;

      if (!data.id && !data.status) {
        const setChat = {
          type_id: 3,
          user_id: +user.user_id,
          target_user_id: +recipientId,
        }

        const { data } = await axios.post(
          apiUrl + 'api-chat/set-chat' + auth,
          setChat
        )

        chatId = data.chat && data.chat.id ? data.chat.id : null
      }
    
      const activeChatItem = {
        id: chatId,
        name: recipientName,
        recipient_id: +recipientId,
        message_id: new Date().getTime(),
      }

      console.log('activeChatItem:', activeChatItem)
      store.commit('setActiveChat', activeChatItem)
    } else {
      openMessenger()
    }
  } else {
    openMessenger()
  }
}

const openMessenger = () => {
  isMessengerShow.value = true;
  isMessengerHide.value = false;
};

const closeMessenger = () => {
  isMessengerShow.value = false;
  isMessengerHide.value = false;
  isSidebarShow.value = true;
};

const chooseChatHandler = (chatListItem) => {
  store.commit('setActiveChat', chatListItem);
  isSidebarShow.value = false;
};

const handleEscKey = (event) => {
  if (event.code === 'Escape' && !isMessengerHide.value) {
    isMessengerShow.value = false;
  }
};

const getPrivateChat = async () => {
  try {
    const params =
      '&auth=' +
      user.username +
      ':' +
      user.auth_key +
      '&user_id=' +
      user.user_id +
      '&target_user_id=' +
      1248;
    const { data } = await axios.get(
      apiUrl + 'api-chat/get-private-chat' + params
    );

    console.log('getPrivateChat:', data);
  } catch (error) {
    console.log('getPrivateChat: ', error);
  }
};

const getSharedChat = async () => {
  try {
    const params =
      '&auth=' +
      user.username +
      ':' +
      user.auth_key +
      '&target_user_id=' +
      1248;
    const { data } = await axios.get(
      apiUrl + 'api-chat/get-shared-chat' + params
    );

    console.log('getSharedChat:', data);
  } catch (error) {
    console.log('getSharedChat: ', error);
  }
};

const updateChatMessages = (id) => { };

onBeforeMount(() => { });

onMounted(() => {
  document.addEventListener('keydown', handleEscKey);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleEscKey);
});

watch(
  activeChat,
  (newActiveChat) => {
    if (newActiveChat.recipient && newActiveChat.recipient_id) {
      setTimeout(() => {
        openMessenger();
        isSidebarShow.value = false
      }, 0);
    }
  },
  { deep: true }
);
</script>
<template>
  <div v-if="isMessengerShow" class="messenger-wrapper p-3 px-0 px-md-3 text-white m-auto"
    :class="{ hide: isMessengerHide }" v-click-outside="closeMessenger">
    <div class="messenger">
      <div class="messenger-head d-flex justify-content-between align-items-center px-3 px-md-0">
        <h2 class="mb-0 fw-bold">Мессенджер</h2>
        <div class="messenger-head-buttons d-flex gap-1 gap-md-2">
          <div class="button" @click="isMessengerHide = !isMessengerHide">
            <img src="@/assets/img/dash.svg" class="mt-2" alt="dash" />
          </div>
          <div class="button" @click="closeMessenger">
            <img src="@/assets/img/chat_close.png" alt="close" />
          </div>
        </div>
      </div>
      <div class="messenger-body mt-3 gap-3" v-show="!isMessengerHide">
        <messenger-sidebar :class="{ show: isSidebarShow }" @choose="chooseChatHandler" />
        <div class="messenger-area-wrapper d-flex flex-column w-100 ms-auto" :class="{ show: !isSidebarShow }">
          <messenger-area @back="isSidebarShow = !isSidebarShow" />
          <messenger-action @send="updateChatMessages" />
        </div>
      </div>
    </div>
  </div>
  <messenger-button v-if="!isMessengerShow" @click.stop @open="openMessagerHandler" />
</template>
<style lang="scss">
.messenger-wrapper {
  background-color: #2d2f3a;
  max-width: 1180px;
  width: 100%;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  border-radius: 12px;

  @media screen and (max-width: 767px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: unset;
    border-radius: 0;
    max-width: 100vw;
    width: 100%;
  }

  &.hide {
    width: 350px;

    @media screen and (max-width: 767px) {
      width: 100%;
      top: auto;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: #20222e;
  }

  h2 {
    font-size: 24px;
    line-height: 100%;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  button,
  .button {
    width: 44px;
    height: 44px;
    border-radius: 9px;
    background-color: #20222e;
    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }

    @media screen and (max-width: 767px) {
      width: 28px;
      height: 28px;
      background-color: #2c2f3c;
    }

    img {
      vertical-align: middle;
    }
  }

  .messenger {
    .messenger-body {
      display: flex;

      @media screen and (max-width: 767px) {
        border-top: 1px solid #bdc1d133;
      }
    }

    .messenger-area-wrapper {
      min-width: 450px;
      max-width: 780px;

      @media screen and (max-width: 767px) {
        &:not(.show) {
          display: none !important;
        }

        min-width: unset;
        max-width: unset;
      }
    }
  }

  .messenger.hide {}
}
</style>
