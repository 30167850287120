<script>
import CardWhite from "@/components/ui/CardWhite.vue";
import axios from "axios";
import Service from "@/components/blocks/Service.vue";
import ServiceFoto from "@/components/blocks/ServiceFoto.vue";
import ModalCard from "@/components/blocks/ModalCard.vue";
import UiSelect from "@/components/ui/UiSelect.vue";
import UserSelect from "@/components/ui/hint/UserSelect.vue";
import QuestionnaireGirl from "@/components/blocks/QuestionnaireGirl.vue";
import AdminModalTable from "@/components/ui/AdminModalTable.vue";

export default {
  name: "Profiles",
  components: {
    AdminModalTable,
    UserSelect, QuestionnaireGirl, UiSelect, ModalCard, ServiceFoto, Service, CardWhite, axios
  },
  methods: {
    changeCurrency(cur) {
      this.selectedMoney = cur
    },
    cleanText(val) {
      if (val.match(/^[а-яА-ЯёЁ\s]/g)) {

        return val.replace(/[^[а-яА-ЯёЁ\s]/g, '');
      }

      return val;
    },
    updateData() {
      let params = '&auth=' + this.user.username + ':' + this.user.auth_key;

      if (this.profile.category_id) {
        params += '&category_id=' + this.profile.category_id
      }
      if (this.profile.city_id) {
        params += '&city_id=' + this.profile.city_id
      }
      if (this.profile.status_id) {
        params += '&status_id=' + this.profile.status_id
      }
      if (this.profile.id) {
        params += '&id=' + this.profile.id
      }
      if (this.profile.user_id) {
        params += '&user_id=' + this.profile.user_id
      }
      if (this.profile.author_id) {
        params += '&author_id=' + this.profile.author_id
      }
      if (this.profile.offset) {
        params += '&offset=' + this.profile.offset
      }
      if (this.profile.limit) {
        params += '&limit=' + this.profile.limit
      }

      params += '&date_add=' + this.dateAdd

      axios
          .get(this.apiUrl + 'api-girl/get-profiles-list-admin' + params)
          .then((response) => {
            this.profiles = response.data;
            this.profilesCount = this.profiles.count
            delete this.profiles.count

          })
    },
    getAllStatus() {
      axios
          .get(this.apiUrl + 'api/get-profile-status&auth=' + this.user.username + ':' + this.user.auth_key)
          .then((response) => {
            this.profileStatus = response.data;
          })
    },
    getAllAuthor() {
      axios
          .get(this.apiUrl + 'api/get-admin-list&auth=' + this.user.username + ':' + this.user.auth_key)
          .then((response) => {
            this.author = response.data;
          })
    },
    formatDate(t) {
      let myDate = new Date(t * 1000) // date object
      return ((myDate.getDate() < 10) ? "0" : "") + myDate.getDate() + "." + (((myDate.getMonth() + 1) < 10) ? "0" : "") + (myDate.getMonth() + 1) + "." + myDate.getFullYear();
    },
    cleanNumber(val) {
      if (val.match(/[^0-9\s]/g)) {
        return val.replace(/[^0-9\s]/g, '');
      }

      return val;
    },
    getCategoryList() {
      axios
          .get(this.apiUrl + 'api/get-categories&auth=' + this.user.username + ':' + this.user.auth_key)
          .then((response) => {
            this.categoryList = response.data;
          })
    },
    getUserList() {
      axios
          .get(this.apiUrl + 'api-user/get-user-list&auth=' + this.user.username + ':' + this.user.auth_key)
          .then((response) => {
            this.userList = response.data;
          })
    },
    nextPage() {
      this.profile.offset = Number(this.profile.offset) + 20;
      this.updateData()
    },
    prevPage() {
      if (this.profile.offset <= 20) {
        this.profile.offset = 0;
      } else {
        this.profile.offset = Number(this.profile.offset) - 20;
      }

      this.updateData();
    },
    openProfile(id) {
      this.profileShow = false;
      this.profId = id
    },
    deleteProfile() {
      let params = {
        status_id: 5,
        id: this.toDel,
      }
      axios
          .post(this.apiUrl + 'api-girl/update-status&status_id=5&auth=' + this.user.username + ':' + this.user.auth_key, params)
          .then((response) => {
            this.hideDialog();
            this.updateData();
          })
    },
    modalShow(id) {

      this.show = true;
      this.toDel = id;

    },
    hideDialog() {
      this.show = false;
    },
    getRowBg(status_id) {

      switch (status_id) {
        case 1:
          return 'bg-dark-info';
        case 5:
          return 'bg-dark-danger';
        case 6:
          return 'bg-dark-success';
        default:
          return '';
      }
    },
    openModalDetail(item) {
      if (window.innerWidth > 1050) return

      this.singleBilling = item
      this.isModalDetail = true
    },
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      profileShow: true,
      profiles: [],
      profilesCount: 0,
      profile: {
        id: '',
        user_id: '',
        category_id: '',
        status_id: '',
        offset: '',
        limit: 20,

      },
      categoryList: [],
      author: [],
      userList: [],
      dateAdd: '',
      profId: 1,
      profileStatus: [],
      save: true,
      show: false,
      toDel: false,
      selectedMoney: {},
      isModalDetail: false,
      singleBilling: '',
      allCity: this.$store.getters.getCities
    }
  },
  mounted() {
    this.updateData()
    this.getCategoryList()
    this.getUserList()
    this.getAllStatus()
    this.getAllAuthor()
  },
}
</script>

<template>
  <card-white v-if="profileShow">
    <form class="mt-5" @submit.prevent="Profiles">
      <div class="flex-wrap form-panel position-relative gap-2">
        <div class="add-profile" @click=" this.save=true;openProfile(1);"><i class="bi bi-plus-lg"></i></div>
        <div class="col-11  p-3 settings-add ">
          <div class="row justify-content-center">
            <div class="settings-add__item col-3 col-md-1">
              <label>
                <input type="text" v-model="profile.id" @keyup="profile.id = cleanNumber(profile.id)"
                       placeholder="ID"
                       id="idProfile">
              </label>
            </div>
            <div class="settings-add__item col-9 col-md-2">
              <label>
                <select v-model="profile.user_id" class="form-control">
                  <option value="">Выбрать логин</option>
                  <option :value="c.id" v-for="(c, i) in this.userList" :key="i">{{ c.username }}</option>
                </select>
              </label>
            </div>
            <div class="settings-add__item col-12 col-md-2 mt-2 mt-md-0">
              <label>
                <select v-model="profile.category_id" class="form-control">
                  <option value="">Выбрать категорию</option>
                  <option :value="c.id" v-for="c in this.categoryList">{{ c.name }}</option>
                </select>
              </label>
            </div>
            <div class="settings-add__item col-12 col-md-1 mt-2 mt-md-0">
              <label class="position-relative">
                <span class="position-absolute span-label">{{profile.city_id ? '' : 'город'}}</span>
                <select v-model="profile.city_id" class="form-control">
                  <option v-for="city of allCity" :value="city.id">{{city.name}}</option>
                </select>
              </label>
            </div>
            <div class="settings-add__item col-12 col-md-2 mt-2 mt-md-0">
              <label>
                <select v-model="profile.status_id" class="form-control">
                  <option value="">Выбрать статус</option>
                  <option :value="s.id" v-for="s in this.profileStatus">{{ s.name }}</option>
                </select>
              </label>
            </div>
            <div class="settings-add__item col-12 col-md-1 mt-2 mt-md-0">
              <label>
                <input v-model="dateAdd" type="date">
              </label>
            </div>
            <div class="settings-add__item col-12 col-md-2 mt-2 mt-md-0">
              <label class="position-relative">
                <span class="position-absolute span-label">{{profile.author_id ? '' : 'Выбрать автора'}}</span>
                <select v-model="profile.author_id" class="form-control">
                  <option :value="a.user.id" v-for="a in this.author">{{ a.user.username }}</option>
                </select>
              </label>
            </div>
            <button type="submit" class="col-12 col-md-1 mt-2 mt-md-0 hover-btn" @click="updateData"><i
                class="bi bi-funnel"></i>
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="table-cont mt-3">
      <table class="col-11 responsive-table">
        <thead>
        <tr>
          <td class="text-white">ID</td>
          <td class="text-white">Логин</td>
          <td class="text-white">Категория</td>
          <td class="text-white">Город</td>
          <td class="text-white">Статус</td>
          <td class="text-white">Дата загрузки видео</td>
          <td class="text-white">Дата загрузки анкеты</td>
          <td class="text-white">Автор</td>
          <td class="text-white">Действия</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item of profiles" :key="item.id" @click="openModalDetail(item)" class="settings-table__row">
          <td id="id" class="settings-table__item text-white">{{ item.id }}</td>
          <td id="shortUser" class="settings-table__item text-white">{{ item.shortUser.username }}</td>
          <td id="category" class="settings-table__item text-white">{{ item.category.name }}</td>
          <td id="city" class="settings-table__item text-white">{{ item.city.name }}</td>
          <td id="status" class="settings-table__item text-white">{{ item.status.name }}</td>
          <td id="date_update" class="settings-table__item text-white">{{ formatDate(item.date_update) }}</td>
          <td id="date_add" class="settings-table__item text-white">{{ formatDate(item.date_add) }}</td>
          <td id="author" class="settings-table__item text-white">{{ item.author.username }}</td>
          <td id="btns" class="settings-table__item text-white">
            <router-link :to="'/anketa/'+item.id" target="_blank" style="color:#fff;"><i
                class="bi bi-eye hover-btn"></i></router-link>
            <button @click=" this.save=false;openProfile(item.id);">
              <i class="bi bi-pencil hover-btn"></i>
            </button>
            <button @click="modalShow(item.id)">
              <i class="bi bi-trash hover-btn"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <AdminModalTable
        v-if="isModalDetail"
        :tableContent="[
            {title: 'ID', content: singleBilling.id},
            {title: 'Логин', content: singleBilling.shortUser.username},
            {title: 'Категория', content: singleBilling.category.name},
            {title: 'Город', content: singleBilling.city.name},
            {title: 'Статус', content: singleBilling.status.name},
            {title: 'Дата загрузки видео', content: formatDate(singleBilling.date_update)},
            {title: 'Дата загрузки анкеты', content: formatDate(singleBilling.date_add)},
            {title: 'Автор', content: singleBilling.author.username},
        ]"
        @closeModal="isModalDetail = false"
    >
      <template v-slot:buttons>
        <router-link :to="'/anketa/'+ singleBilling.id" target="_blank" style="color:#fff;"><i
            class="bi bi-eye hover-btn text-black"></i></router-link>
        <button @click="isModalDetail = false; this.save=false;openProfile(singleBilling.id);">
          <i class="bi bi-pencil hover-btn"></i>
        </button>
        <button @click="modalShow(singleBilling.id); isModalDetail = false;">
          <i class="bi bi-trash hover-btn"></i>
        </button>
      </template>
    </AdminModalTable>

    <div class="d-flex justify-content-center mt-4">
      <div>
        <button class="arrow-page" @click="prevPage"><i class="bi bi-arrow-left-circle hover-btn"></i></button>
      </div>
      <div>
        <button class="arrow-page" @click="nextPage"><i class="bi bi-arrow-right-circle hover-btn"></i></button>
      </div>
    </div>
  </card-white>
  <card-white v-else>
    <div class="row">
      <div class="col-12 text-end">
        <i @click="profileShow = true" class="bi bi-x-lg"></i>
      </div>
    </div>
    <QuestionnaireGirl
        :anketaId="this.profId"
        @closeEditModal="profileShow = true"
    />
  </card-white>
  <!--Модальное окно, удаление-->
  <modal-card :show="this.show">
    <div>
      <div class="row">
        <div class="col-12 text-end">
          <i class="bi bi-x-lg" @click="hideDialog"></i>
        </div>
      </div>
      <div class="modal-body">
        <p>Уверены, что хотите удалить {{ this.profile.user_id }}</p>
      </div>
      <div class="modal-footer mt-3 justify-content-between">
        <button type="button" @click="hideDialog" class="btn btn-modal hover-btn">Закрыть</button>
        <button type="button" @click="deleteProfile" class="btn btn-modal hover-btn">Удалить</button>
      </div>
    </div>
  </modal-card>
</template>


<style lang="scss" scoped>
.span-label{
  top: 65%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.list-item {
  border: 1px solid #2C2F3C;
  background: #2C2F3C;
  border-radius: 7px;
  color: #fff;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
}

thead tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

thead tr:last-child td:last-child {
  border-top-right-radius: 10px;
}

.settings-add {
  border: solid 1px #2C2F3C;
  border-radius: 10px;
  background: #2C2F3C;


  button {
    background: #BDC1D1;
    display: inline-block;
    border: solid 1px transparent;
    border-radius: 4px;
    color: #2C2F3C;
    transition: 0.3s;

    &:hover {
      background: #BDC1D1;
      border-color: #BDC1D1;
      color: #2C2F3C;
      font-weight: 600;
    }
  }

  .invalid {
    border: 1px solid red;
  }

  label {
    width: 100%;
  }

  input {
    background-color: #dedede;
    width: 100%;
    border: 1px solid #BDC1D1;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  select {
    width: 100%;
    background-color: #dedede;
    border: 1px solid #BDC1D1;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

.settings-table {
  color: #fff;

  &__head {
    background-color: #2C2F3C;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #7e7e7e7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__row {
    background-color: #2C2F3C;
    position: relative;


    &:nth-child(odd) {
      background-color: #2C2F3C;
    }

    button {
      color: #fff;
    }
  }

  &__row:hover {
    background-color: #524B5F;
  }

  &__item {
    border-bottom: solid 1px #7e7e7e7e;
  }
}

button.btn-modal {
  background: #dedede !important;
  display: inline-block;
  border: solid 1px transparent;
  border-radius: 4px;
  color: #000;
  transition: 0.3s;
}

.arrow-page {
  font-size: 30px;
  font-weight: 600;
  color: #2C2F3C;
}

.add-profile {
  border-radius: 50%;
  overflow: hidden;
  background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
  color: #fff;
  font-weight: 700;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.form-panel,
.table-cont {
  display: flex;
  justify-content: end;
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 875px) {
  .form-panel,
  .table-cont {
    justify-content: center;
  }
}

.responsive-table {
  border-collapse: collapse;
}

.responsive-table thead {
  background-color: #2C2F3C;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #7e7e7e7e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.responsive-table th {
  background-color: #f2f2f2;
  color: #333;
}

.responsive-table td {
  padding: 0px 10px;
  text-align: center;
  line-height: normal;
}

@media (max-width: 1050px) {
  .responsive-table > thead td:nth-child(3),
  .responsive-table > thead td:nth-child(7),
  .responsive-table > thead td:nth-child(9) {
    display: none;
  }

  #btns,
  #category,
  #date_add {
    display: none;
  }

  thead tr:last-child td:nth-child(8) {
    border-top-right-radius: 10px;
  }
}

@media (max-width: 500px) {
  .responsive-table > thead td:nth-child(8),
  .responsive-table > thead td:nth-child(6),
  .responsive-table > thead td:nth-child(4) {
    display: none;
  }

  #city,
  #date_update,
  #author {
    display: none;
  }

  thead tr:last-child td:nth-child(5) {
    border-top-right-radius: 10px;
  }
}
</style>