<script>
import {defineComponent} from "vue";
import CloseX from "@/components/ui/CloseX.vue";

export default defineComponent({
    name: 'ModalTgInfo',
    components: {CloseX},
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        hideDialog(){
            this.$emit('hideDialog')
        }
    }
})
</script>

<template>

    <div class="dialog" v-if="show">
        <div @click.stop class="dialog-content position-relative">
            <close-x :preset="'top'"  @click="this.$emit('hideDialog')"/>
            <div class="row position-relative">
                <img src="../../../assets/img/airplan.png" alt="" class="air d-none d-md-block">
                <div class="col-2 d-none d-md-flex">

                </div>
                <div class="col-12 col-md-8">
                    <p class="fw-bold mt-2 text-center text-md-start">Уважаемый пользователь!</p>
                    <p class="mt-3 ">Egoza вынуждена иногда менять ссылки на все сайты и ресурсы, это связанно с блокировками на территории РФ, подпишитесь на официальный канал чтобы не потерять актуальные ссылки!</p>
                </div>
                <div class="col-12 col-md-2 p-0 d-flex align-items-end justify-content-center justify-content-md-start mt-3 mt-md-0">
                    <a class="btn-tg p-2 fw-bold d-flex align-items-center" href="https://t.me/EgozaBiz" target="_blank">
                        <img src="../../../assets/img/tg.png" alt=""> <span class="ms-3">Подписаться</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dialog {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    z-index: 999;
}

.dialog-content {
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;
    padding: 15px;
    border-radius: 5px;
    background: #fff;
    color: #525665;
    background-repeat: no-repeat;
    background-position: top right;
    max-width: 70%;
    width: 70%;
}

.air{
    position: absolute;
    top: -32px;
    left: -79px;
    width: 270px;
    height: auto;
}

.btn-tg{
    background: #2AABEE;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    font-size: 15px;
}
.btn-tg:hover{

    box-shadow: 0px 1px 5px #000;
}
a{
    text-decoration: none;
}

</style>