<script>
import axios from "axios";
import UploadPhoto from "@/components/ui/uploadMedia/UploadPhoto.vue";
import VideoIndividually from '@/components/ui/UiVideoIndividually.vue'

export default {
  name: 'UploadVideoCard',
  components: { UploadPhoto, VideoIndividually },
  emits: ['uploadFail', 'uploadVideoSuccess'],
  props: {
    type: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String],
      required: false,
    },
    photoArr: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomine: this.$store.getters.getApiDomine,
      uploadedVideo: '',
      file: '',
      icon: 'plus', // plus | reload
      uploadPercentage: 0,
      uploadStatus: false,
      uploadStart: false,
      error: false,
      errorMessage: 'Во время загрузки видео произошла ошибка. Попробуйте загрузить другой файл или попробуйте ещё раз. В любом случае - мы на связи и готовы помочь!',
      isWatch: false,
      isUpload: false,
      successMessage: 'Видео загружено на сервер. В данный момент мы обрабатываем его. Как только процесс завершится - видео появится в анкете.',
      successTypeMessage: 'Ваше видео загружено, модератор уже скоро его получит и активирует вашу анкету.',
    };
  },
  computed: {
    uploadLink() {
      const param = this.type !== 'verification' ? "api-girl/upload-video" : "api-girl/upload-verification-video"

      return this.apiUrl + param;
    }
  },
  watch: {
    value: function (data) {
      if (!this.isWatch && !this.UploadPhoto && (data && data.id)) {
        this.uploadStatus = true
        if (!data.name.includes('/girls/video/')) {
          data.name = `/girls/video/${data.name}`
        }
        this.uploadedVideo = data
        this.isWatch = true
        this.icon = 'reload'
      }
    }
  },
  methods: {
    changeInput() {
      const self = this;

      this.file = this.$refs.video.files[0];
      const { type } = this.$refs.video.files[0];

      this.uploadStatus = false

      if (type && type.startsWith('video/')) {
        this.errorMessage = 'Во время загрузки видео произошла ошибка. Попробуйте загрузить другой файл или попробуйте ещё раз. В любом случае - мы на связи и готовы помочь!'
        this.uploadStart = true
        this.error = false

        let formData = new FormData();
        formData.append('video', this.file);

        this.uploadedVideo = ''
        axios.post(this.uploadLink + '&auth=' + this.user.username + ':' + this.user.auth_key,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: function (progressEvent) {
                    this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                  }.bind(this)
                }
            )
            .then(function (res) {
              if (!res.data.name) {
                self.error = true
                self.$emit('uploadFail', res)
                console.log('fail', res)
              } else {
                self.uploadStatus = true
                self.isUpload = true
                console.log('Видео загружено. Создаём событие. Данные: ', res)
                self.$emit('uploadVideoSuccess', res.data, self.type)
              }
            })
            .catch(function (res) {
              console.log('fail', res)
              self.error = true
              self.$emit('uploadFail', res)
            });
      } else {
        this.errorMessage = 'Неверный формат файла. Попробуйте загрузить другой файл или попробуйте ещё раз.'
        this.error = true
      }
    }
  },
}
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-12 pe-0 ps-0 ps-lg-3">
      <div class="title-video-block" ref="videoBlock">
        <label class="h-0" v-if="!uploadStatus || (uploadedVideo && uploadedVideo.id)">
          <input type="file" ref="video" @change="changeInput()" class="title-video-input" accept="video/*">
          <span class="icon" v-if="!uploadStart">
                        <i class="bi bi-plus" v-if="this.icon == 'plus'"></i>
                        <i class="bi bi-arrow-clockwise" v-if="this.icon == 'reload'"></i>
                    </span>
        </label>
        <div class="progress" v-if="!uploadStatus && uploadStart">
          <progress max="100" :value.prop="uploadPercentage"></progress>
          <span>{{ uploadPercentage }}%</span>
        </div>
        <div class="video-uploaded text-center text-white p-3" v-if="uploadStatus && !uploadedVideo">
          {{ type === "verification" ? successTypeMessage : successMessage }}
        </div>
        <div class="video-uploaded-error text-center text-light p-3" v-if="error">
          {{ errorMessage }}
        </div>
        <VideoIndividually
            v-if="uploadedVideo && uploadedVideo.id"
            :videoCards="uploadedVideo"
            :photos="this.photoArr"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.h-0 {
  height: 0 !important;
  display: block;
}

.progress {
  position: relative;
  background: transparent;
  border-radius: 0;
}

.progress progress {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
}

.progress span {
  position: absolute;
  bottom: 0;
  right: 5%;
  color: #FFFFFF;
  text-shadow: 1px 1px #000;
}

.title-video-block {
  /**/
  position: relative;
  border-radius: 24px;
  background: linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%);
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;
  max-width: 280px;
  height: 100%;
  min-height: 450px;

  @media (max-width: 767px) {
    max-width: unset;
  }
}

.title-video-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 55%;
  background-color: rgba(207, 196, 194, 0.1);
  position: absolute;
  left: calc(50% - 27px);
  top: calc(50% - 27px);
  transition: 0.3s;
  cursor: pointer;
  color: #ffffff;
  z-index: 10;

  /* @media (max-width: 575px) {
      width: 13px;
      height: 13px;
  } */
}

.icon:hover {
  background-color: rgba(207, 196, 194, 0.5);
}
</style>