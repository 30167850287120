<script>
import LikeIcon from '@/components/icons/LikeIcon.vue';
import { mapActions } from 'vuex';
import { Base64 } from 'js-base64';
import { encode, decode } from 'js-base64';
import { utf8 } from 'utf8';
import axios from 'axios';

export default {
  components: {
    LikeIcon,
  },
  props: {
    post: {
      type: Object,
    },
    girlAnketa: {
      type: Object,
    },
  },

  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomine: this.$store.getters.getApiDomine,
      hasLiked: false,
      girlId: this.$route.params.id,
      like: ''
    };
  },
  methods: {
    ...mapActions(['getPosts']),
    usk(text) {
      try {
        return decodeURIComponent(escape(atob(text)));
      } catch (error) {
        return;
      }
    },
    async updateView(type) {
      try {
        const res = await axios.post(
          this.apiUrl +
            'api-profile-post/update-stat' +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          {
            post_id: this.post.id,
            type: type,
          }
        );
        this.$store.dispatch('getPosts', this.girlId);
      } catch (error) {
        console.log('Произошла ошибка при добавлении update-state', error);
      }
    },
    //   try {
    //     const res = await axios.post(
    //       this.apiUrl +
    //         'api-profile-post/update-stat' +
    //         '&auth=' +
    //         this.user.username +
    //         ':' +
    //         this.user.auth_key,
    //       {
    //         post_id: this.post.id,
    //         type: type,
    //       }
    //     );

    //   } catch (error) {
    //     console.log('Произошла ошибка при добавлении update-state', error);
    //   }
    // },
    copyUrl() {
      const element = this.$refs.copyAlert;
      console.log(this.anketaURL);
      if ('clipboard' in navigator) {
        navigator.clipboard.writeText(this.anketaURL).then(
          function () {
            element.textContent = 'Адрес страницы скопирован';
            element.classList.add('success');
          },
          function (err) {
            element.textContent = 'Ошибка копирования: ' + err;
            element.classList.add('error');
          }
        );
      } else {
        const textArea = document.createElement('textarea');

        textArea.value = this.anketaURL;
        document.body.appendChild(textArea);
        textArea.select();

        try {
          console.log(document.execCommand('copy'));
          document.execCommand('copy');
          element.textContent = 'Адрес страницы скопирован';
          element.classList.add('success');
        } catch (err) {
          element.textContent = 'Ошибка копирования: ' + err;
          element.classList.add('error');
        } finally {
          document.body.removeChild(textArea);
        }
      }
    },
    currentDate(time) {
      const date = new Date(time * 1000).toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      return date;
    },
   async delPost(){
      try {
        const res = await axios.post( this.apiUrl +
            'api-profile-post/del-post' +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,{
              post_id: this.post.id
            })
            this.getPosts(this.girlId)
      } catch (error) {
        console.log('Ошибка при удалении поста', error);
      }
    },

async updateLike(type) {
    try {
        // Получаем текущего пользователя
        const currentUser = this.getCurrentUser; // Функция getCurrentUser() должна возвращать текущего пользователя

        // Проверяем, был ли уже поставлен лайк пользователем
        if (!localStorage.getItem(`like_${this.post.id}_${currentUser}`)) {
            // Условие для автора поста
            if (currentUser == this.post.author_id) {
                // Проверяем, был ли у автора поста уже поставлен лайк
                if (!localStorage.getItem(`like_${this.post.id}_${this.user.user_id}`)) {
                    const res = await axios.post(
                        this.apiUrl +
                        'api-profile-post/update-stat' +
                        '&auth=' +
                        this.user.username +
                        ':' +
                        this.user.auth_key,
                        {
                            post_id: this.post.id,
                            type: type,
                        }
                    );

                    // Устанавливаем флаг, что автор поста поставил лайк
                    localStorage.setItem(`like_${this.post.id}_${this.user.user_id}`, 'true');
                } else {
                    console.log('Вы уже поставили лайк своему собственному посту');
                }
            } else {
                const res = await axios.post(
                    this.apiUrl +
                    'api-profile-post/update-stat' +
                    '&auth=' +
                    this.user.username +
                    ':' +
                    this.user.auth_key,
                    {
                        post_id: this.post.id,
                        type: type,
                    }
                );

                // Устанавливаем флаг, что пользователь поставил лайк
                localStorage.setItem(`like_${this.post.id}_${currentUser}`, 'true');
            }
        } else {
            console.log('Вы уже поставили лайк этому посту');
        }
        this.$store.dispatch('getPosts', this.girlId);
    } catch (error) {
        console.log('Ошибка при like', error);
    }
},
  },

  computed: {
    anketaURL() {
      return window.location.href
    },
    anketaPhoto() {
      if (this.girlAnketa.photo) {
        return this.girlAnketa.photo[0];
      }
    },
    likeKey() {
      return `hasLiked_${this.post.id}`;
    },
    getCurrentUser(){
      return  this.user.user_id
    }
  },
  mounted() {
    this.updateView('views');
  },
};
</script>

<template>
  <div class="about">
    <div class="about-avatar" v-if="girlAnketa.photo">
      <div class="about-avatar__descr">
        <img
          class="about-avatar__avatar"
          :src="this.apiDomine + '/web/uploads/' +
            this.girlAnketa?.photo[0]?.original_filename
          "
          alt=""
        />
        <div class="about-avatar__right">
          <span class="about-avatar__name">{{ girlAnketa.name }}</span>
          <span class="about-avatar__date">{{
            currentDate(girlAnketa.date_add)
          }}</span>
        </div>
      </div>
      <div class="about-avatar__del" @click="delPost" v-if="girlAnketa.author_id == user.user_id">
        <img src="@/assets/img/dot.svg" alt="" class="about-avatar__dot" />
        <span>Удалить пост</span>
      </div>
    </div>
    <div class="about-info">
      <div class="about-info__descr">
        <img
          v-if="post.photo !== 'null'"
          :src="this.apiDomine + '/web/uploads' + post.photo"
          alt=""
          class="about-info__photo"
        />
        <video
          v-if="post.video !== 'null'"
          :src="
            this.apiDomine + '/web/uploads/post/video/' + post.video
          "
          controls
          loop
        ></video>
        <p
          class="about-info__text"
          :class="{ width: post.photo == 'null' && post.video == 'null' }"
        >
          {{ usk(post.text) }}
        </p>                                                
        <div class="about-social" :class="{'nomedia': post.photo == 'null' &&  post.video == 'null'}">
          <div class="about-social__left">
            <button
              :disabled="hasLiked"
              @click="updateLike('likes')"
              class="about-social__like"
            >
              <LikeIcon />
              <span>{{post?.stat?.likes}}</span>
            </button>
            <div
              @click="
                () => {
                  updateView('share');
                  copyUrl();
                }
              "
              class="about-social__copy"
            >
              <img src="@/assets/img/reply.png" alt="">
              <div class="copy-alert" ref="copyAlert"></div>
              </div>
          </div>
          <div class="about-social__right">
            <img src="@/assets/img/eye-grey.png" alt="" />
            <span>{{ post?.stat?.views }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.about {
  &-avatar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__del {
      position: relative;
      cursor: pointer;
      & span {
        background: grey;
        padding: 10px 15px;
        border-radius: 15px;
        color: white;
        opacity: 0;
        position: absolute;
        left: -124px;
        bottom: -39px;
        z-index: -1;

        transition: 0.3s;
      }
      &:hover span {
        z-index: 1;
        opacity: 0.6;
      }
    }
    &__descr {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &__avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
    }
    &__right {
      display: flex;
      flex-direction: column;
    }
  }
  &-info {
    margin-top: 25px;
    position: relative;
    &__photo {
      max-width: 530px;
      width: 100%;
      height: 560px;
      border-radius: 15px;
      object-fit: cover;
      @media (max-width: 991px) {
        max-width: none;
        width: 100%;
      }
    }
    &__text {
      &.width {
        width: 100%;
      }
      &::first-letter {
        text-transform: uppercase;
      }
      width: calc((100% - 530px) - 40px);
      font-size: 18px;
      word-wrap: break-word;
      @media (max-width: 991px) {
        width: 100%;
        order: 2;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      opacity: 0.25;
      bottom: -15px;
      height: 1px;
      background: black;
    }

    &__descr {
      display: flex;
      gap: 38px;
      margin-bottom: 28px;
      flex-wrap: wrap;
      @media (max-width: 991px) {
        flex-direction: column;
        gap: 15px;
      }

      & video {
        max-width: 530px;
        width: 100%;
        height: 560px;
        border-radius: 15px;
        object-fit: cover;
      }
    }
  }
  &-social {
    max-width: 530px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.nomedia{
      max-width: 180px;
    }
    @media (max-width: 991px) {
      max-width: none;
      width: 100%;
    }
    &__left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__like {
      padding: 0 12px;
      width: 63px;
      height: 30px;

      background: rgba(255, 64, 50, 0.1);
      border-radius: 51px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & span {
        font-size: 12px;
        color: #ff4032;
      }
    }
    &__copy {
      display: flex;
      align-items: center;
      background: rgba(82, 86, 101, 0.7);
      border-radius: 51px;
      width: 33px;
      height: 33px;
      justify-content: center;
      & span {
        font-size: 12px;
        color: #fff;
      }
    }
    &__right {
      background: rgba(82, 86, 101, 0.7);
      border-radius: 51px;
      display: flex;
      align-items: center;
      gap: 7px;
      padding: 0 12px;
      min-width: 63px;
      height: 30px;
      justify-content: space-between;
      cursor: pointer;
      & span {
        font-size: 14px;
        color: white;
      }
    }
  }
}
.about-social__copy {
  position: relative;
}
.copy-alert {
  position: absolute;
  left: 20px;
  top: -40px;
  padding-left: 0.5rem;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: #dedede;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 5px;
  width: max-content;

  &.success {
    color: #333;
    animation: copy-alert-hide 2s linear;
  }

  &.error {
    color: red;
    animation: copy-alert-hide 2s linear;
  }
}

@keyframes copy-alert-hide {
  0% {
    opacity: 1;
    visibility: visible;
  }

  60% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
