<script setup>
import CardWhite from "@/components/ui/CardWhite.vue"
import PromoCard from "@/components/ui/PromoCard.vue"

import UiSelect from "@/components/ui/UiSelect.vue"
import { onBeforeMount, onMounted, watch, ref, computed, reactive } from "vue"
import { useStore } from "vuex";
import axios from "axios";

const PROMOTIONS = {
    homepage: [
        {
            id: 'adTitlePage',
            title: 'Размещение вашей анкеты на главной странице',
            price: 7500,
            image: require('@/assets/img/promo/promo_01.png'),
            description: 'Вашу анкету мы поднимем не просто в ТОП, мы разместим ее на главной странице сайта, с подписью «Девушки, которым мы доверяем».',
            conditions: 'Анкету мы разместим на 30 дней, с момента подключения услуги. После того как услуга прекратится, ваша анкета смешается с остальными.',
            checked: false
        },
        {
            id: 'adVideoTitlePage',
            title: 'Видеовизитка на главной странице',
            price: 8000,
            image: require('@/assets/img/promo/promo_02.png'),
            description: 'Видеовизитка - яркий способ выделиться на фоне конкуренток! Размещайте свою видеовизитку на главной странице! И она соберет вам самые горячие отклики посетителей сайта!',
            conditions: 'Срок размещения вашей видеовизитки 30 дней, с момента подключения услуги.',
            checked: false
        },
        {
            id: 'adWidgetTitle',
            title: 'Видеовиджет',
            price: 8000,
            image: require('@/assets/img/promo/promo_03.png'),
            description: 'Задавайте тренды! Будьте на пике популярности и активности с видеовиджетом. Записывайте короткие видео в стиле reels и tik-tok, повышай интерес своей аудитории. Будьте обворожительными и максимально сексуальными!',
            conditions: 'Срок размещения вашей видеовизитки 14 дней, с момента подключения услуги.',
            checked: false
        }
    ],
    categoryPage: [
        {
            id: 'adVideoInProfiles',
            title: 'Видеовизитка среди анкет',
            price: 5000,
            image: require('@/assets/img/promo/promo_04.png'),
            description: 'Яркий способ выделиться на фоне конкуренток! Размещайте свою видеовизитку на странице категорий! И она соберет вам самые горячие отклики посетителей сайта!',
            conditions: 'Срок размещения вашей видеовизитки 30 дней, с момента подключения услуги.',
            checked: false
        },
        {
            id: 'adWidgetCategory',
            title: 'Видеовиджет',
            price: 5000,
            image: require('@/assets/img/promo/promo_05.png'),
            description: 'Задавайте тренды! Будьте на пике популярности и активности с видеовиджетом. Записывайте короткие видео в стиле reels и tik-tok, повышай интерес своей аудитории. Будьте обворожительными и максимально сексуальными!',
            conditions: 'Срок размещения вашей видеовизитки 14 дней, с момента подключения услуги.',
            checked: false
        },
        {
            id: 'adInTop',
            title: 'Поднятие анкеты в ТОП',
            price: 4000,
            image: require('@/assets/img/promo/promo_06.png'),
            description: 'Размещайте свою в ТОПе на страницах категорий! И она соберет вам самые горячие отклики посетителей сайта!',
            conditions: 'Услуга действует 14 дней. После того как услуга прекратится, ваша анкета смешается с остальными',
            checked: false
        }
    ]
}

const store = useStore()
const user = store.getters.getUser
const apiUrl = store.getters.getApiUrl

const selectAnketa = ref(null)
const girlFormsId = ref([])
const selectedProfileID = ref(null)
const promotions = ref([])
const showModal = ref(false)
const hpPromos = ref(PROMOTIONS.homepage)
const categoryPromos = ref(PROMOTIONS.categoryPage)
const promotionError = ref('')

const total = computed(() => {
    const number = promotions.value.reduce((acc, red) => acc + +red.price, 0)
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
})

const updateData = async () => {
    const params = '&auth=' + user.username + ':' + user.auth_key + '&user_id=' + user.user_id
        + '&limit=40';

    const response = await axios.get(apiUrl + 'api-girl/get-profiles-list' + params)

    if (response && response.data && response.data.length) {
        return response.data.map(a => {
            const promotions = {
                adInTop: {checked: false, end_date: null},
                adTitlePage: {checked: false, end_date: null},
                adVideoInProfiles: {checked: false, end_date: null},
                adVideoTitlePage: {checked: false, end_date: null},
                adWidgetCategory: {checked: false, end_date: null},
                adWidgetTitle: {checked: false, end_date: null}
            }

            if (a.adInTop && a.adInTop.status && a.adInTop.status !== 99) {
                promotions.adInTop.checked = true 
                promotions.adInTop.end_date = a.adInTop.end_date 
            }
            if (a.adTitlePage && a.adTitlePage.status && a.adTitlePage.status !== 99) {
                promotions.adTitlePage.checked = true
                promotions.adTitlePage.end_date = a.adTitlePage.end_date
            }
            if (a.adVideoInProfiles && a.adVideoInProfiles.status && a.adVideoInProfiles.status !== 99) {
                promotions.adVideoInProfiles.checked = true
                promotions.adVideoInProfiles.end_date = a.adVideoInProfiles.end_date
            }
            if (a.adVideoTitlePage && a.adVideoTitlePage.status && a.adVideoTitlePage.status !== 99) {
                promotions.adVideoTitlePage.checked = true
                promotions.adVideoTitlePage.end_date = a.adVideoTitlePage.end_date
            }
            if (a.adWidgetCategory && a.adWidgetCategory.status && a.adWidgetCategory.status !== 99) {
                promotions.adWidgetCategory.checked = true
                promotions.adWidgetCategory.end_date = a.adWidgetCategory.end_date
            }
            if (a.adWidgetTitle && a.adWidgetTitle.status && a.adWidgetTitle.status !== 99) {
                promotions.adWidgetTitle.checked = true
                promotions.adWidgetTitle.end_date = a.adWidgetTitle.end_date
            }

            return { id: a.id, name: a.name, promotions, status_id: a.status?.id, video: (a.video && a.video.id) ? true : false }
        }).filter(a => [6,2,9].includes(a.status_id))
    }

    return []
}

const selectChange = (id) => {
    promotions.value = []

    const activeProfilePromotions = girlFormsId.value.find(p => p.id  ===  id).promotions

    hpPromos.value = PROMOTIONS.homepage.map(p => { return { ...p, checked: activeProfilePromotions[p.id].checked, end_date: activeProfilePromotions[p.id].end_date } })
    categoryPromos.value = PROMOTIONS.categoryPage.map(p => { return { ...p, checked: activeProfilePromotions[p.id].checked, end_date: activeProfilePromotions[p.id].end_date } })
    selectedProfileID.value = id
}

const updatePromotionsHandler = (promo) => {
    promotionError.value = ''
    
    if (!selectedProfileID.value || !+user.balance.balance) {
        showModal.value = true
        promo.status.checked = false
        return false
    } else if (selectedProfileID.value) {
        const activeProfile = girlFormsId.value.find(p => p.id === selectedProfileID.value)
        const activePromotion = activeProfile?.promotions
        const isVideo = activeProfile?.video

        if (activePromotion[promo.promo.id].checked) {
            promo.status.checked = true
            return false
        }
        
        if (!isVideo && ['adVideoInProfiles','adVideoTitlePage','adWidgetCategory','adWidgetTitle'].includes(promo.promo.id)) {
            promotionError.value = 'В анкете нет видео для продвижения'
            showModal.value = true
            promo.status.checked = false
            return false
        }
    }

    if (promo.status.checked) {
        promotions.value.push(promo.promo)

        if (hpPromos.value.find(p => p.id === promo.promo.id)) {
            hpPromos.value.find(p => p.id === promo.promo.id).checked = true
        }

        if (categoryPromos.value.find(p => p.id === promo.promo.id)) {
            categoryPromos.value.find(p => p.id === promo.promo.id).checked = true
        }
    } else {
        promotions.value = promotions.value.filter(p => p.id !== promo.promo.id)

        if (hpPromos.value.find(p => p.id === promo.promo.id)) {
            hpPromos.value.find(p => p.id === promo.promo.id).checked = false
        }

        if (categoryPromos.value.find(p => p.id === promo.promo.id)) {
            categoryPromos.value.find(p => p.id === promo.promo.id).checked = false
        }
    }
}

const savePromoHandler = async () => {
    const promos = promotions.value.map(p => p.id)
    const methods = []

    promos.forEach(p => {
        switch (p) {
            case 'adTitlePage':
                methods.push('api-ad/add-to-title-page')
                break;
            case 'adVideoTitlePage':
                methods.push('api-ad/add-video-to-title-page')
                break;
            case 'adWidgetTitle':
                methods.push('api-ad/add-video-to-widget-title-page')
                break;
            case 'adVideoInProfiles':
                methods.push('api-ad/add-video-in-profiles')
                break;
            case 'adWidgetCategory':
                methods.push('api-ad/add-video-to-widget-category-page')
                break;
            case 'adInTop':
                methods.push('api-ad/add-profile-in-top')
                break;
        }
    })

    if (!methods.length) return false

    try {
        let isError = false
        const auth = `&auth=${user.username}:${user.auth_key}`
        const params = {
            user_id: +user.user_id,
            profile_id: selectedProfileID.value,
            method_id: 3
        }

        for (const method of methods) {
            const { data } = await axios.post(apiUrl + method + auth, params)

            if (data && !data.status) {
                promotionError.value = data.error || ''
                showModal.value = true
                isError = true
            }
        }

        if (!isError) {
            promotionError.value = 'Продвижение активированно'
            showModal.value = true
            isError = false
            promotions.value = []
            selectedProfileID.value = null
            selectAnketa.value.selectName = 'Выбрать анкету'
            hpPromos.value = PROMOTIONS.homepage.map(p => { return { ...p, checked: false } })
            categoryPromos.value = PROMOTIONS.categoryPage.map(p => { return { ...p, checked: false } })
        }
    } catch (error) {
        console.log('savePromoHandler: ', error)
        showModal.value = true
    }
}

const swipingMenu = (
    event,
    element,
    elementHeight,
    startY,
    deltaY,
    classNonTransition,
    swipeResult,
    touchEnd
) => {
    if (classNonTransition) element.classList.add("non-transition");
    else element.classList.remove("non-transition");
    if (deltaY > 25 || deltaY < -25) element.style.bottom = `-${deltaY}px`;
    if (touchEnd && swipeResult === "Up" && (deltaY > 25 || deltaY < -25)) {
        showModal.value = false
        element.style.bottom = "0";
    } else if (touchEnd && swipeResult === "Down" && (deltaY > 25 || deltaY < -25)) {
        showModal.value = false
        element.style.bottom = "0";
    }
}

onBeforeMount(async () => {
    girlFormsId.value = await updateData()
    selectAnketa.value.selectName = girlFormsId.value.length ? 'Выбрать анкету' : 'Нет проверенных анкет'
})

onMounted(() => {})
</script>

<template>
    <div class="row align-items-center mb-3 justify-content-between justify-content-md-start">
        <div class="col-auto title">Продвижение на сайте</div>
    </div>

    <card-white>
        <div class="container">
            <div class="row mb-4">
                <div class="col-12 col-md-auto title title-dark">Услуги продвижения</div>
                <div class="col-12 col-md-auto ms-0 ms-md-auto mb-2 mb-md-0 select order-first order-md-0">
                    <ui-select ref="selectAnketa" :select="girlFormsId" @changeSelectVal="selectChange" />
                </div>
                <div class="col-12 sub-title mt-1">На главной странице сервиса</div>
            </div>
            <div class="row justify-content-between">
                <promo-card v-for="item in hpPromos" :promo="item" :key="item.title"
                    @update="updatePromotionsHandler"></promo-card>
            </div>
            <div class="row mb-4">
                <div class="col-12 title title-dark">Услуги продвижения</div>
                <div class="col-12 sub-title mt-1">Среди анкет в инди или премиум</div>
            </div>
            <div class="row justify-content-between">
                <promo-card v-for="item in categoryPromos" :promo="item" :key="item.title"
                    @update="updatePromotionsHandler"></promo-card>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3 total">
                <p>ИТОГО</p>
                <p class="strip"></p>
                <p> {{ total !== '0' ? `${total} ₽` : 0 }}</p>
            </div>
            <div class="row mt-auto justify-content-start justify-content-lg-end actions">
                <button class="button hover-btn" :disabled="total === '0'" @click="savePromoHandler">Активировать
                    продвижение</button>
            </div>
        </div>
    </card-white>

    <div v-show="showModal" class="promo-modal" :class="{ 'active': showModal }" v-swipe="swipingMenu" v-click-outside="() => {showModal = false}">
        <div class="promo-modal-close" @click="showModal = false"><i class="bi bi-x"></i></div>
        <div v-if="promotionError" class="promo-modal-body p-5">{{ promotionError }}</div>
        <div v-else-if="selectedProfileID" class="promo-modal-body p-5">
            На вашем балансе не достаточно средств, пополните, что бы воспользоваться продвижением.<br/>
            <router-link to="/girl/billing"><b>Пополнить баланс</b></router-link>
        </div>
        <div v-else class="promo-modal-body p-5">
            Выберете анкету для продвижения.
        </div>
    </div>
</template>
<style lang="css">
body:has(.promo-modal.active) {
    overflow: hidden;
}
</style>
<style lang="scss" scoped>
.title {
    color: #fff;
    font-size: 30px;
}

.sub-title {
    color: #525665;
    font-size: 18px;
    line-height: 100%;
}

.title-dark {
    color: #525665;
}

.btn-dark-title {
    background-color: #39354B;
    color: #fff;
    border-radius: 14px;
    padding: 10px 15px;
}

.actions .button {
    width: max-content;
    height: 30px;
    align-items: center;
    border-radius: 10px;
    background-color: #39354B;
    border: 1px solid #39354B;
    color: #fff;
    font-size: 12px;
    margin-right: 10px;
}

.select {
    width: 320px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.total {
    p {
        font-weight: 800;
        font-size: 24px;
        color: #525665;

        @media screen and (max-width: 567px) {
            font-size: 20px;
        }
    }

    .strip {
        display: block;
        margin: 0 15px;
        height: 1px;
        background-color: rgba(#BDC1D14F, 0.31);
        flex-grow: 1;
    }
}

button:disabled {
    cursor: no-drop;
    opacity: 0.5;
}

button:disabled:hover {
    box-shadow: none;
}

@media(max-width: 767px) {
    .title {
        color: #fff;
        font-size: 20px;
    }

    .sub-title {
        font-size: 12px;
    }

    .title-dark {
        color: #525665;
    }

    .btn-dark-title {
        background-color: #39354B;
        color: #fff;
        border-radius: 14px;
        font-size: 14px;
    }
}

@media(max-width: 561px) {
    .actions .button {
        width: 100%;
    }
}

.promo-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 9px;
    max-width: 475px;
    text-align: center;
    font-size: 20px;
    color: #525665;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #39354B;
    box-shadow: 5px 5px 15px 5px #525665;

    &-body {
        user-select: none;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        height: 290px;
        transform: none;
        bottom: 0;
        top: auto;
        left: 0;
        right: 0;
        z-index: 100;
        border-radius: 9px 9px 0 0;
    }

    a {
        color: #525665;
    }

    .promo-modal-close {
        width: 44px;
        height: 44px;
        background-color: #fff;
        cursor: pointer;
        position: absolute;
        top: -46px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9px;
        border: 1px solid #39354B;

        i {
            @media screen and (max-width: 767px) {
                width: 36px;
                height: 4px;
                background: #DCE3EC;
                border-radius: 26px;

                &::before {
                    content: none;
                }
            }
        }

        @media screen and (max-width: 767px) {
            width: 100%;
            height: 44px;
            border-radius: 0;

            background-color: transparent;
            top: 0;
            right: 50%;
            transform: translateX(50%);
            border-color: transparent;
        }
    }
}
</style>