<script>
export default {
    name: 'promo-logo',
}
</script>

<template>
    <div class="promo-logo">
        <!-- <img src="@/assets/svg/logo__promo_eg.svg" alt="">         -->
        <router-link to="/"
            ><img
                src="@/assets/img/logo.svg"
                alt="logo"
        /></router-link>
    </div>
</template>

<style lang="scss" scoped>
.promo-logo {
    width: 120px;
    padding: 10px;    
    position: absolute;
    top: 0;
    left: 0;
}
</style>
