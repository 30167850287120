<script setup>
const props = defineProps({
    text: {
        type: String,
        default: 'Продолжить',
        required: false,
    },
});
</script>
<template>
    <button class="order-next-button">{{ text }}</button>
</template>
<style lang="scss" scoped>
.order-next-button {
    background-color: #bdc1d1;
    border-radius: 30px;
    padding: 10px 16px;
    color: #2d2f3a;
    font-size: 14px;

    &:disabled {
        background-color: #7e8292;
        cursor: default;
    }
}
</style>
