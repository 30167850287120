// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/Mont-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/Mont-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/Mont-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/fonts/Mont-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/assets/fonts/Mont-SemiBold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("@/assets/fonts/Mont-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("@/assets/fonts/Mont-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("@/assets/fonts/Mont-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("@/assets/fonts/Mont-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("@/assets/fonts/Mont-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("@/assets/fonts/Mont-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("@/assets/fonts/Mont-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Mont\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n  font-weight: bold;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Mont\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\");\n  font-weight: 600;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Mont\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n.gradient-button__wrapper[data-v-f565fa9c] {\n  border-radius: 76px;\n  height: 56px;\n  width: 100%;\n  padding: 1px;\n  background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);\n}\n.gradient-button__wrapper > button[data-v-f565fa9c] {\n  height: 100%;\n  width: 100%;\n  background: linear-gradient(278deg, #bf5152 0%, #312dda 100%);\n  border-radius: 68px;\n  border: solid 1px #0A0A0C;\n  color: #FFFFFF;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 17px;\n  text-wrap: nowrap;\n}\n@media (min-width: 1200px) {\n.gradient-button__wrapper > button[data-v-f565fa9c] {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 17px;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
