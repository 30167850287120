<script setup>
import OrderBackwardButton from '@/components/order_modal/OrderBackwardButton.vue';
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
import { computed } from 'vue';
import { ref } from 'vue';
import { onMounted } from 'vue';

const props = defineProps({
    initialCoast: {
        type: Object,
        required: true,
        default: () => {
            return { from: null, to: null };
        },
    },
});

const emit = defineEmits(['next-step']);
const minCoast = 3000;
const maxCoast = 1000000;
const currentCoast = ref({ from: 0, to: 0 });

onMounted(() => {
    currentCoast.value = props.initialCoast;
});

const isNextButtonEnabled = computed(() => {
    const { from, to } = currentCoast.value;
    if (
        from != null &&
        from >= minCoast &&
        from <= maxCoast &&
        (to == null || (to >= minCoast && to <= maxCoast && to >= from))
    ) {
        return true;
    } else return false;
});

const onChangeCoast = () => {
    const { from, to } = currentCoast.value;

    if (from != null && Number.isFinite(from)) {
        if (from <= minCoast) {
            currentCoast.value.from = minCoast;
        }
        if (from >= maxCoast) {
            currentCoast.value.from = maxCoast;
            currentCoast.value.to = maxCoast;
        }
        if (from >= to && Number.isFinite(to)) {
            currentCoast.value.to = from;
        }
    }

    if (to != null && Number.isFinite(to)) {
        if (to <= minCoast) {
            currentCoast.value.to = null;
        }
        if (to <= from) {
            currentCoast.value.to = from;
        }
        if (to >= maxCoast) {
            currentCoast.value.to = maxCoast;
        }
    }
};

const onNextButtonClick = () => {
    onChangeCoast();
    emit('next-step', { coast: currentCoast.value, forward: true });
};
</script>

<template>
    <div class="order-coast">
        <div class="order-coast__question mb-3">
            <p>Какую сумму готовы заплатить модели?</p>
        </div>
        <div class="order-coast__content d-flex flex-wrap align-items-center gap-3">
            <div class="order-coast__inputs d-flex flex-wrap gap-4">
                <div class="order-coast__input-wrapper d-flex align-items-center ps-3 pe-2">
                    <span>от:</span>
                    <input
                        class="order-coast__input ms-2 text-end px-2"
                        v-model="currentCoast.from"
                        type="number"
                        @change="onChangeCoast"
                        placeholder="от 3000"
                    />
                </div>

                <div class="order-coast__input-wrapper d-flex align-items-center ps-3 pe-2">
                    <span>до:</span>
                    <input
                        class="order-coast__input ms-2 text-end px-2"
                        v-model="currentCoast.to"
                        placeholder="до 1 000 000"
                        type="number"
                        @change="onChangeCoast"
                    />
                </div>
            </div>
            <div class="order-coast__buttons ms-auto">
                <OrderBackwardButton
                    class="h-100"
                    @click="emit('next-step', { coast: currentCoast, forward: false })"
                />
                <OrderNextButton
                    class="h-100"
                    :disabled="!isNextButtonEnabled"
                    @click="onNextButtonClick"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-coast {
    .order-coast__question {
        font-size: 16px;
        line-height: 20px;
    }
    .order-coast__content {
        .order-coast__inputs {
            .order-coast__input-wrapper {
                height: 38px;
                background-color: #201c2d;
                border-radius: 12px;

                .order-coast__input {
                    border-radius: 6px;
                    background-color: white;
                    width: 100px;
                }
            }
        }
    }
}
</style>
