<script>
import axios from 'axios';
import LogInCard from '@/components/blocks/LogInCard.vue';
import HeartIconSolid from '@/components/icons/HeartIconSolid.vue';
import CopyIconSolid from '@/components/icons/CopyIconSolid.vue';
import PlaneIconSolid from '@/components/icons/PlaneIconSolid.vue';
import OpenIconSolid from '@/components/icons/OpenIconSolid.vue';
import CloseIcon from '@/components/icons/CloseIcon.vue';

export default {
    name: 'mobile-stream-c-v',
    props: ['videoCV'],
    emits: ['can-not-show'],
    components: { LogInCard, HeartIconSolid, CopyIconSolid, PlaneIconSolid, OpenIconSolid, CloseIcon },
    data() {
        return {
            profile: null,
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            videoContent: null,
            duration: 0,
            isPlaying: false,
            isMuted: false,
            currentTime: '00:00',
            passedPart: 0,
            videoUrl: null,            
            loginTitle: '',
            show: false,
            canShow: false,
        };
    },
    async mounted() {
        //проверить наличие в localStorage записи об нежелательной визитке
        if (!localStorage.getItem(`dont_show_${this.videoCV.id}_${this.user.user_id}`)) {
            this.profile = await this.getProfile();
            this.videoContent = this.$refs.videoContent;
            this.videoUrl = this.videoCV?.name
                ? this.apiDomine +
                  '/web/uploads' +
                  (this.videoCV.name.includes('/girls/video/')
                      ? this.videoCV.name
                      : `/girls/video/${this.videoCV.name}`)
                : undefined;            
            this.videoContent.load();
        } else {
            this.$emit('can-not-show', this.videoCV.id);
        }
    },
    methods: {
        async getProfile() {
            const { data, message } = await axios
                .get(this.apiUrl + 'api-girl/get-profile-by-id', {
                    params: {
                        auth: this.user.username + ':' + this.user.auth_key,
                        id: this.videoCV.profile_id,
                    },
                })
                .catch((error) => {
                    console.error(error.message);
                    return error;
                });
            if (data !== null && data !== undefined) {
                return data;
            } else return undefined;
        },
        playToggle() {
            if (this.isPlaying) {
                this.videoContent.pause();
                this.isPlaying = false;
            } else {
                this.videoContent.play();
                this.isPlaying = true;
            }
        },
        volumeToggle() {
            if (this.videoContent) {
                this.isMuted = !this.isMuted;
                this.videoContent.muted = this.isMuted;
            }
        },
        onLoadedmetadata(e) {
            this.duration = e.target?.duration || 0;
        },
        onEnded() {
            this.passedPart = 0;
            this.isPlaying = false;
        },
        onError(e) {
            console.log('onError:', e);
        },
        onCanplay(e) {
            this.canShow = true;
        },
        updateRemainingTime(currentTime) {
            const minutes = Math.floor(currentTime / 60);
            const seconds = Math.floor(currentTime % 60);
            this.currentTime = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
            this.passedPart = (currentTime / this.duration) * 100;
        },
        async copyLink() {
            const link = `${window.location.origin}/anketa/${this.videoCV.profile_id}`;
            await window.navigator.clipboard.writeText(link);
            const element = this.$refs.copyAlert;
            element.textContent = 'Адрес страницы скопирован';
            element.classList.add('success');
            setTimeout(() => {
                element.classList.remove('success');
            }, 2000);
        },
        openProfile() {
            this.$router.push({ path: `/anketa/${this.videoCV.profile_id}` });
        },
        async sendMessage() {
            if (this.user.isLogged) {
                const auth = '&auth=' + this.user.username + ':' + this.user.auth_key;
                const params = auth + '&user_id=' + this.user.user_id + '&target_user_id=' + this.profile.user_id;
                const { data } = await axios.get(this.apiUrl + 'api-chat/get-private-chat' + params);
                let chatId = data.id || null;

                if (!data.id && !data.status) {
                    const setChat = {
                        type_id: 3,
                        user_id: +this.user.user_id,
                        target_user_id: +this.profile.user_id,
                    };

                    const { data } = await axios.post(this.apiUrl + 'api-chat/set-chat' + auth, setChat);

                    chatId = data.chat && data.chat.id ? data.chat.id : null;
                }

                const activeChatItem = {
                    id: chatId,
                    name: this.profile.name,
                    recipient_id: +this.profile.user_id,
                    message_id: new Date().getTime(),
                };

                this.$store.commit('setActiveChat', activeChatItem);
            } else {
                this.loginTitle = 'Что бы написать, войдите или зарегистрируйтесь в сервисе';
                this.show = true;
            }
        },
        hideDialog() {
            this.show = false;
            this.$emit('successLogin');
        },
        hideCV() {
            localStorage.setItem(`dont_show_${this.videoCV.id}_${this.user.user_id}`, true);
            this.videoContent.pause();
            this.canShow = false;
        },
        addSaved() {
            axios
                .post(this.apiUrl + 'api-user/save-girl&auth=' + this.user.username + ':' + this.user.auth_key, {
                    user_id: this.user.user_id,
                    profile_id: this.profile.id,
                })
                .then((response) => {
                    if (response.data.status) {
                        this.profile.isSaved = true;
                    }
                })
                .catch((error) => console.log(error));
        },
        delSaved() {
            axios
                .post(this.apiUrl + 'api-user/unsave-girl&auth=' + this.user.username + ':' + this.user.auth_key, {
                    user_id: this.user.user_id,
                    profile_id: this.profile.id,
                })
                .then((response) => {
                    if (response.data.status) {
                        this.profile.isSaved = false;
                    }
                })
                .catch((error) => console.log(error));
        },
        saveProfile() {
            if (!this.user.isLogged) {
                this.loginTitle = 'Что бы добавить анкету в избранное, войдите или зарегистрируйтесь в сервисе';
                this.show = true;
            } else if (this.profile.isSaved) {
                this.delSaved();
            } else {
                this.addSaved();
            }
        },
    },
    computed: {},
};
</script>

<template>
    <Transition name="bounce">
        <div
            v-show="canShow"
            class="mobile-stream-c-v__container"
        >
            <div class="mobile-stream-c-v__player">
                <div
                    class="copy-alert"
                    ref="copyAlert"
                ></div>
                <div class="player__header">
                    <div class="header__progressive-bar">
                        <div
                            class="header__progressive-bar--pr"
                            :style="{ width: `${passedPart}%` }"
                        ></div>
                    </div>
                    <div class="header__remaining-time">{{ currentTime }}</div>
                    <div class="header__name">
                        {{ videoCV?.profile?.name || 'Без имени' }}
                    </div>
                </div>
                <div class="player__content d-flex justify-content-center align-items-center">
                    <video
                        ref="videoContent"
                        preload="metadata"
                        playsinline
                        webkit-playsinline
                        :muted="isMuted"
                        @loadedmetadata="(e) => onLoadedmetadata(e)"
                        @ended="onEnded"
                        @timeupdate="(e) => updateRemainingTime(e.target.currentTime)"
                        @error="(e) => onError(e)"
                        @canplay="(e) => onCanplay(e)"
                    >
                        <source
                            :src="videoUrl"
                            type="video/mp4"
                            codecs="avc1.42E01E, mp4a.40.2"
                        />
                    </video>
                </div>
                <div class="player__buttons">
                    <button
                        class="player__buttons__play player-button"
                        @click.stop="playToggle()"
                        :style="{
                            backgroundImage: `url(${
                                isPlaying ? require('@/assets/img/pause.png') : require('@/assets/img/play.svg')
                            })`,
                        }"
                    ></button>
                    <button
                        class="player__buttons__mute player-button"
                        @click.stop="volumeToggle()"
                        :style="{
                            backgroundImage: `url(${
                                isMuted ? require('@/assets/img/mute.svg') : require('@/assets/img/volume.svg')
                            })`,
                        }"
                    ></button>
                    <button
                        class="player__buttons__close action-button d-flex align-items-center justify-content-center"
                        @click="hideCV()"
                    >
                        <CloseIcon
                            color="white"
                            :size="{ w: 12, h: 12 }"
                        />
                    </button>
                    <div class="player__action-buttons d-flex flex-column gap-3">
                        <button
                            @click="saveProfile()"
                            class="action-button d-flex justify-content-center align-items-center"
                        >
                            <HeartIconSolid
                                :color="this.profile?.isSaved ? '#FF4032' : 'white'"
                                :size="{ w: 24, h: 24 }"
                            />
                        </button>
                        <button
                            @click="copyLink()"
                            class="action-button d-flex justify-content-center align-items-center"
                        >
                            <CopyIconSolid
                                color="white"
                                :size="{ w: 24, h: 24 }"
                            />
                        </button>
                        <button
                            @click="sendMessage()"
                            class="action-button d-flex justify-content-center align-items-center"
                        >
                            <PlaneIconSolid
                                color="white"
                                :size="{ w: 24, h: 24 }"
                            />
                        </button>
                        <button
                            @click="openProfile()"
                            class="action-button d-flex justify-content-center align-items-center"
                        >
                            <OpenIconSolid
                                color="white"
                                :size="{ w: 24, h: 24 }"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Transition>

    <log-in-card
        :show="this.show"
        @loginSuccess="hideDialog"
        @hideDialog="hideDialog"
        :title="loginTitle"
    />
</template>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.75s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.copy-alert {
    position: absolute;
    left: 50%;
    bottom: 40px;
    padding-left: 0.5rem;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: #dedede;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
    margin-top: 5px;
    width: max-content;
    transform: translateX(-50%);

    &.success {
        color: #333;
        animation: copy-alert-hide 2s linear;
    }
}

@keyframes copy-alert-hide {
    0% {
        opacity: 1;
        visibility: visible;
    }

    60% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.mobile-stream-c-v__container {
    width: 100%;

    .mobile-stream-c-v__player {
        position: relative;

        .player__header {
            position: absolute;
            width: 100%;

            .header__progressive-bar {
                position: absolute;
                top: 12px;
                height: 4px;
                background-color: rgba(#fff, 0.3);
                border-radius: 15px;
                width: 90%;
                left: 5%;

                &--pr {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 0%;
                    background-color: #ff4032;
                    border-radius: 10px;
                }
            }

            .header__remaining-time {
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                font-weight: 700;
                font-size: 12px;
                color: rgba(#fff, 0.8);
            }

            .header__name {
                position: absolute;
                top: 45px;
                left: 30px;
                color: #fff;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        .player__content {
            background-color: black;
            border-radius: 16px;

            > video {
                width: 100%;
                min-height: 480px;
                border-radius: 16px;

                border-bottom: 1px solid transparent; /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
                &::-webkit-media-controls-panel {
                    display: none !important;
                    -webkit-appearance: none;
                    opacity: 0;
                    visibility: hidden;
                }
                &::-webkit-media-controls-play-button {
                    display: none !important;
                    -webkit-appearance: none;
                    opacity: 0;
                    visibility: hidden;
                }
                &::-webkit-media-controls-start-playback-button {
                    display: none !important;
                    -webkit-appearance: none;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .player__buttons {
            > .player-button {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-position: 50% 50%;
                background-repeat: no-repeat;
            }

            &__play {
                position: absolute;
                height: 50px;
                width: 50px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #ffffff80;

                &:hover {
                    background-color: rgba(#fff, 0);
                }
            }

            &__mute {
                position: absolute;
                height: 32px;
                width: 32px;

                backdrop-filter: blur(10px);
                background-color: #cfc4c21a;
                top: 85px;
                right: 25px;

                &:hover {
                    background-color: rgba(#fff, 0.2);
                }
            }

            &__close {
                position: absolute;
                top: 40px;
                right: 25px;
                width: 40px;
                height: 40px;
                backdrop-filter: blur(10px);
                background-color: #cfc4c21a;
                border-radius: 50%;

                &:hover {
                    background-color: rgba(#fff, 0.2);
                }
            }

            .player__action-buttons {
                position: absolute;
                right: 20px;
                bottom: 20px;

                .action-button {
                    width: 40px;
                    height: 40px;
                    background-color: #ffffff21;
                    border-radius: 11px;
                    padding: 0;

                    &:hover {
                        background-color: rgba(#fff, 0.2);
                    }
                }
            }
        }
    }
}
</style>
