<script>

import {useCookies} from "vue3-cookies";

export default {
    name: 'CloseX',
    props:{
        preset:{
            type: String,
            require: true
        }
    },
    mounted() {
        if(this.preset === 'bottom'){
            this.positionPreset = this.bottom;
        }
        if(this.preset === 'top'){
            this.positionPreset = this.top;
        }

    },

    data(){
        return{
            positionPreset: '',
            bottom:{
                position: 'pos-bottom'
            },
            top:{
                position: 'pos-top'
            }
        }
    }
}
</script>

<template>
    <div class="close-btn cursor" >
        <i :class="'bi bi-x ' + this.positionPreset.position"></i>
    </div>
</template>


<style scoped>
.cursor {
    cursor: pointer;
}

.bi-x{
    background: #fff;
    border: 1px solid #978B8A;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    position: absolute;
    z-index: 99999999;
    color: #525665;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 1px 8px #000;
}

.pos-bottom{
    bottom: -45px;
    right: 0;
}
.pos-top{
    top: -45px;
    right: 0;
}

.close-btn{

}
</style>