<script>
/**
 * Иконка копирования в буфер обмена
 * Родительский элемент должен быть position:relative;
 */
export default {
    name: 'CopyText',
    props: {
        copyText: {
            type: [String, Number],
            require: true
        },
        position: {
            type: String,
            default: 'absolute'
        }
    },
    methods: {
        copy() {
            let element = this.$refs.copyIcon
            navigator.clipboard.writeText(this.copyText).then(function () {

                element.classList = []
                element.classList.add('bi', 'bi-check', 'text-success')

                setTimeout(function () {

                    element.classList = []
                    element.classList.add('bi', 'bi-copy')
                }, 3000)
            }, function (err) {

                element.classList = []
                element.classList.add('bi', 'bi-x-octagon', 'text-danger')

                setTimeout(function () {

                    element.classList = []
                    element.classList.add('bi', 'bi-copy')
                }, 3000)
            });
        },
    },
    data(){

        if(this.position === 'absolute'){
            return {iconClass: 'bi bi-copy absolute'}
        }

        if(this.position === 'relative'){
            return {iconClass: 'bi bi-copy relative'}
        }

    }
}
</script>

<template>
    <i @click="this.copy" ref="copyIcon" :class="this.iconClass"></i>
</template>

<style scoped>
i.absolute {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 7px;
    z-index: 110;
}
i.relative {
    position: relative;
    cursor: pointer;
    z-index: 110;
}
</style>