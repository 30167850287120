<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Thumbs } from "swiper/modules";
import { ref } from "vue";

export default {
  name: 'CategoryCarousel',
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    if (window.innerWidth >= 540) {
      this.createObserver()
    }
  },
  methods: {
    createObserver() {
      const slides = document.querySelectorAll('.category-slide')

      const options = {
        rootMargin: "50px",
        threshold: 1,
      }

      const swiperObserver = new IntersectionObserver(this.heandeElements, options)

      slides.forEach((item) => {
        swiperObserver.observe(item)
      })
    },

    heandeElements(entries, observer) {
      entries.forEach((entry) => {
        const video = entry.target.querySelector('video')
        if (entry.isIntersecting && video) {
          video.play()
        } else if (video) {
          video.pause()
        }
      })
    }
  },

  data() {
    return {
      modules: [Navigation, Thumbs],
      slides: [
        /*{
            num: '01',
            name: 'Салоны',
            video: 'cat_1.mp4',
            url: '/salon',
        },*/
        {
          num: '01',
          name: 'Инди',
          video: 'EgozaIndie.mp4',
          videoMobile: 'mobileIndexIndie.mp4',
          poster: require('@/assets/img/main/indieDeck.png'),
          posterMobile: require('@/assets/img/main/indieMob.png'),
          url: '/indie',
          id: 0,
        },
        {
          num: '02',
          name: 'Премиум',
          video: 'EgozaPremium.mp4',
          videoMobile: 'mobileIndexPremium.mp4',
          poster: require('@/assets/img/main/premiumDeck.jpg'),
          posterMobile: require('@/assets/img/main/premiumMob.png'),
          url: '/escort',
          id: 1,
        },
        /*{
            num: '01',
            name: 'Салоны',
            video: 'cat_1.mp4',
            url: '/salon',
        },*/
        {
          num: '01',
          name: 'Инди',
          video: 'EgozaIndie.mp4',
          videoMobile: 'mobileIndexIndie.mp4',
          poster: require('@/assets/img/main/indieDeck.png'),
          posterMobile: require('@/assets/img/main/indieMob.png'),
          url: '/indie',
          id: 2,
        },
        {
          num: '02',
          name: 'Премиум',
          video: 'EgozaPremium.mp4',
          videoMobile: 'mobileIndexPremium.mp4',
          poster: require('@/assets/img/main/premiumDeck.jpg'),
          posterMobile: require('@/assets/img/main/premiumMob.png'),
          url: '/escort',
          id: 3,
        },
      ],

        };
    },
    setup() {
        const thumbsSwiper = ref(null);

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    return {
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters['getIsMobile']
    }
  },
}

</script>

<template>
  <swiper :slidesPerView="'auto'" :centeredSlides="true" :spaceBetween="this.isMobile ? false : 15" :navigation="{
        nextEl: '.next',
        prevEl: '.prev',
    }" :thumbs="{ swiper: thumbsSwiper }" :modules="modules" :loop="true" :prevEl="'.prev'" :nextEl="'.next'"
          id="categorySwiper" class="category-swiper">
    <swiper-slide @click="$router.push(item.url)" v-for="(item, i) in slides" :key="item.id"
                  class="category-slide">
        <div class="category-video" :ref="`video-desktop-${i}`">
            <img :src="this.isMobile ? item.posterMobile : item.poster" alt="">
        </div>
<!--      <video class="category-video" :ref="`video-desktop-${i}`" autoplay muted playsinline
             webkit-playsinline loop width="100%" preload="metadata"
             :poster="this.isMobile ? item.posterMobile : item.poster">
        <source type="video/mp4" media="(min-width: 992px)" :src="require(`@/assets/video/${item.video}`)+'#t=0.001'"/>
        <source type="video/mp4" media="(max-width: 992px)" :src="require(`@/assets/video/gif/${item.videoMobile}`)+'#t=0.001'"/>
      </video>-->
      <div class="layer d-flex flex-column justify-content-between">
        <div class="category-name-block">
          <div class="category-name" v-html="item.name"></div>

        </div>
        <div class="category-num-block">
          <div class="category-num" v-html="item.num"></div>
        </div>
      </div>
      <div class="category-name" v-html="item.name"></div>
      <div class="category-num" v-html="item.num"></div>
    </swiper-slide>

    <div class="prev">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
           class="bi bi-chevron-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
      </svg>
    </div>
    <div class="next" @click="next">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
           class="bi bi-chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </div>
  </swiper>
  <!--    <div class="container-fluid">
      <div class="row justify-content-md-center justify-content-between">
          <div
              v-for="(item, i) in  slidesBtn"
              :key="i"
              class="col-md-auto col-6 mt-3"
          >
              <div class="thumb-slide p-3 ps-4 pe-4 text-center" @click="$router.push(item.url)">
                  <img class="me-2" :src="item.icon" alt=""> {{ item.name }}
              </div>
          </div>

      </div>
  </div>-->

</template>

<style scoped lang="scss">
video {
  position: relative;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid transparent;

  /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
  &::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }
}

#categorySwiper {
  cursor: pointer;
  height: 70vh;
}

#categorySwiper .prev,
#categorySwiper .next {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 22px);
  background: #fff;
  width: 44px;
  height: 44px;
  border: 1px solid #BDC1D1;
  border-radius: 5px;
  z-index: 9;
}

#categorySwiper .prev {

  left: calc(15% + 20px);

  @media (max-width: 767px) {
    left: 15px;
    opacity: .5;
  }
}

#categorySwiper .next {

  right: calc(15% + 20px);

  @media (max-width: 767px) {
    right: 15px;
    opacity: .5;
  }
}

.category-slide {
  border: 1px solid #BDC1D1;
  border-radius: 10px;
  overflow: hidden;
  max-width: 70%;

  @media (max-width: 767px) {
    max-width: calc(100% - 7.5px);
    margin-left: 7.5px;
  }
}

.category-slide video {

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.layer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #2D2F3A;
  opacity: .3;
}

.category-name {
  color: #FFFFFF;
  font-weight: 800;
  font-size: 40px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.category-num {
  color: #BDC1D1;
  font-size: 24px;
  position: absolute;
  left: 15px;
  top: 15px;
}

.category-video {
  width: 100%;
  object-fit: cover;
}

.thumb-swiper {
  width: 70%;
  margin: 0 auto;
}

.thumb-slide {
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
  background: linear-gradient(45deg, #72666A, #524B5F, #201F36);
}
</style>