<script setup>
import OrderPreviewGirl from '@/components/blocks/OrderPreviewGirl.vue';

import vSelect from 'vue-select';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

const store = useStore();
const user = store.getters.getUser;
const apiUrl = store.getters.getApiUrl;

const orderList = ref([]);
const cities = ref([]);
const cityMap = ref(new Map());
const currentCity = ref(null);
const typeMap = new Map([
    ['invite', 'Выезд'],
    ['apartments', 'Апартаменты'],
    ['escort', 'Сопровождение'],
]);
const onModal = ref(false)
const succesBuy = ref('')

onMounted(async () => {
    cities.value = await getCities();
    cities.value.forEach((city) => {
        cityMap.value.set(city.id, city);
    });
    currentCity.value = cityMap.value.get(parseInt(localStorage.getItem(`search_orders_city_id`))) || null;
    await fetchOrders();
});

const getOrderList = async () => {
    const { data } = await axios
        .get(apiUrl + 'api-order/get-orders', {
            params: {
                auth: user.username + ':' + user.auth_key,
                city_id: currentCity.value?.id,
            },
        })
        .catch((error) => {
            console.error(error.message);
        });
  console.log(data)
    if (data) {
        return data.data;
    } else return [];
};

const getCities = async () => {
    const { data } = await axios
        .get(apiUrl + 'api/get-cities', {
            params: {
                auth: user.username + ':' + user.auth_key,
            },
        })
        .catch((error) => {
            console.error(error.message);
        });
    if (data !== null && data !== undefined) {
        return data;
    } else return [];
};

const filterOrderList = (orderList) => {
    return orderList.filter((order) => {
        return !isCanceled(order.id);
    });
};

const fetchOrders = async () => {
    orderList.value = filterOrderList(await getOrderList());
};

const isCanceled = (orderId) => {
    if (localStorage.getItem(`dont_show_order_${orderId}_${user.user_id}`)) {
        return true;
    } else return false;
};

const hideOrder = (orderId, index) => {
    localStorage.setItem(`dont_show_order_${orderId}_${user.user_id}`, true);
    orderList.value.splice(index, 1);
};

const onCitySelected = async (city) => {
    currentCity.value = city;
    localStorage.setItem(`search_orders_city_id`, currentCity.value.id);
    await fetchOrders();
};

const custOutside = (e) => {
  if(e.target.className === 'wrapper_modal-wind'){
    onModal.value = false
  }
}

//for messenger
const onWriteMessage = async (shortUser) => {
    let chatId = null;
    const { data } = await axios
        .get(apiUrl + 'api-chat/get-private-chat', {
            params: {
                auth: user.username + ':' + user.auth_key,
                user_id: user.user_id,
                target_user_id: shortUser.id,
            },
        })
        .catch((error) => {
            console.error(error.message);
        });

    if (data?.id) {
        chatId = data.id;
    } else {
        const setChat = {
            type_id: 3,
            user_id: user.user_id,
            target_user_id: shortUser.id,
        };

        const { data } = await axios
            .post(
                apiUrl + 'api-chat/set-chat',
                { ...setChat },
                {
                    params: {
                        auth: user.username + ':' + user.auth_key,
                    },
                }
            )
            .catch((error) => {
                console.error(error.message);
            });

        if (data?.chat?.id && data?.status) {
            chatId = data?.chat?.id || null;
        }
    }

    if (chatId) {
        const activeChatItem = {
            id: chatId,
            name: shortUser?.username || null,
            recipient_id: shortUser?.id || null,
            message_id: new Date().getTime(),
        };
        store.commit('setActiveChat', activeChatItem);
    }
};
</script>

<template>
    <div class="girl-orders">
        <div class="pb-4">
            <h1 class="girl-orders__title">Заказы</h1>
        </div>
        <div class="girl-orders__order-list__wrapper pt-3 pb-3 ps-3 pe-3">
            <div class="girl-orders__order-list__settings mb-3 d-flex flex-wrap gap-3 align-items-center">
                <div>Выберите город:</div>
                <div>
                    <vSelect
                        class="girl-orders__city-picker"
                        :options="cities || []"
                        label="name"
                        v-model="currentCity"
                        :clearable="false"
                        :searchable="false"
                        placeholder="Выберите город"
                        v-on:[`option:selected`]="onCitySelected"
                    />
                </div>
                <div>
                    <button
                        class="girl-orders__update-button"
                        :disabled="!currentCity?.id"
                        @click="fetchOrders"
                    >
                        Обновить список
                    </button>
                </div>
            </div>
            <div
                v-if="!orderList.length"
                class="girl-orders__order-list__message text-center pt-4"
            >
                В данный момент нет активных заказов в этом городе.
            </div>
            <div class="girl-orders__order-list d-flex flex-column gap-3">
                <TransitionGroup name="bounce">
                    <OrderPreviewGirl
                        v-for="(order, index) in orderList"
                        :key="`key_${order.id}`"
                        :order-data="order"
                        :city-name="cityMap.get(order.city_id)?.name || ''"
                        :type-name="typeMap.get(order.type) || ''"
                        @hide-order="hideOrder(order.id, index)"
                        @write-message="onWriteMessage(order.shortUser)"
                        @openModal="(succesVal) => {onModal = true, succesBuy = succesVal}"
                    />
                </TransitionGroup>
            </div>
        </div>
    </div>

  <div v-show="onModal" @click.stop="custOutside" class="wrapper_modal-wind">
    <div class="promo-modal">
      <div class="promo-modal-close" @click="onModal = false"><i class="bi bi-x"></i></div>
      <div v-if="succesBuy" class="succesRebound promo-modal-body p-5">Отклик оплачен</div>
      <div v-else-if="!succesBuy" class="promo-modal-body p-5">
        На вашем балансе не достаточно средств, пополните, что бы воспользоваться продвижением.<br/>
        <router-link to="/girl/billing"><b>Пополнить баланс</b></router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'vue-select/dist/vue-select.css';

.promo-modal-close {
  width: 44px;
  height: 44px;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  top: -46px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1px solid #39354B;

  i {
    @media screen and (max-width: 767px) {
      width: 36px;
      height: 4px;
      background: #DCE3EC;
      border-radius: 26px;

      &::before {
        content: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 44px;
    border-radius: 0;

    background-color: transparent;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    border-color: transparent;
  }
}
.promo-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 9px;
  max-width: 475px;
  text-align: center;
  font-size: 20px;
  color: #525665;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #39354B;
  box-shadow: 5px 5px 15px 5px #525665;

  &-body {
    user-select: none;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    height: 290px;
    transform: none;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    z-index: 100;
    border-radius: 9px 9px 0 0;
  }

  a {
    color: #525665;
  }

  .promo-modal-close {
    width: 44px;
    height: 44px;
    background-color: #fff;
    cursor: pointer;
    position: absolute;
    top: -46px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    border: 1px solid #39354B;

    i {
      @media screen and (max-width: 767px) {
        width: 36px;
        height: 4px;
        background: #DCE3EC;
        border-radius: 26px;

        &::before {
          content: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 44px;
      border-radius: 0;

      background-color: transparent;
      top: 0;
      right: 50%;
      transform: translateX(50%);
      border-color: transparent;
    }
  }
}
.wrapper_modal-wind{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999;
}

.bounce-enter-active {
    animation: bounce-in 0.75s;
}

.bounce-leave-active {
    animation: bounce-in 0.75s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.girl-orders {
    .girl-orders__title {
        font-size: 24px;
        line-height: 54px;
        color: white;
        font-weight: bold;
    }

    .girl-orders__order-list__wrapper {
        min-height: 450px;
        background-color: white;
        border-radius: 12px;

        .girl-orders__order-list__settings {
            font-size: 18px;
            .girl-orders__city-picker {
                width: 200px;
                --vs-border-radius: 12px;
                --vs-dropdown-option--active-bg: #bdc1d1;
                --vs-line-height: 26px;
                font-size: 16px;
            }

            .girl-orders__update-button {
                background-color: #bdc1d1;
                border-radius: 30px;
                padding: 10px 30px;
                color: #2d2f3a;
                font-size: 14px;

                &:hover {
                    background-color: #a3a7b4;
                }

                &:disabled {
                    background-color: #7e8292;
                    cursor: default;
                }
            }
        }

        .girl-orders__order-list__message {
            font-size: 20px;
        }
    }
}
</style>
