<script>
import axios from "axios";
import CardWhite from "@/components/ui/CardWhite.vue";
import Anketa from "@/components/ui/Anketa.vue";
import logInBlock from "@/components/ui/LogInBlock.vue";

export default {
    name: 'Lk',
    components: { CardWhite, Anketa },
    methods: {
        async updateData() {
            const params = '&auth=' + this.user.username + ':' + this.user.auth_key + '&user_id=' + this.user.user_id
                + '&limit=40';

            axios
                .get(this.apiUrl + 'api-girl/get-profiles-list' + params)
                .then((response) => {
                    console.log(this.apiUrl + 'api-girl/get-profiles-list' + params);
                    this.profilesCount = response.data.length
                    delete response.data.count

                    for(let status of this.myProfiles){
                        status.items = []
                    }

                    for (let p of response.data) {
                        if (p.photo && p.photo.length && p.city && p.city.name) {
                            if (p.status_id == 5) {
                                this.myProfiles.find(p => p.id === 5)?.items.push(p)
                            }
                            if (p.status_id == 6) {
                                this.myProfiles.find(p => p.id === 6)?.items.push(p)
                            }
                            if (p.status_id == 7) {
                                this.myProfiles.find(p => p.id === 7)?.items.push(p)
                            }
                            if (p.status_id == 1) {
                                this.myProfiles.find(p => p.id === 1)?.items.push(p)
                            }
                            if (p.status_id == 8) {
                                this.myProfiles.find(p => p.id === 8)?.items.push(p)
                            }
                            if (p.status_id == 9) {
                                this.myProfiles.find(p => p.id === 9)?.items.push(p)
                            }
                            if (p.status_id == 10) {
                                this.myProfiles.find(p => p.id === 10)?.items.push(p)
                            }
                        }
                    }


                }).catch(error => console.log(error))

        },

        async updateStatus(id, status_id) {
            try {
                const params = '&auth=' + this.user.username + ':' + this.user.auth_key + '&user_id=' + this.user.user_id
                const response = await axios.post(this.apiUrl + 'api-girl/update-status' + params, { id, status_id })

                console.log(response)

                if (response.status) {
                    this.updateData()

                    const message = {
                        title: 'Удаление анкеты',
                        massage: 'Анкета перенесена в архив',
                        type: 'success'
                    }
                    this.$store.commit('setFlashMessage', message)
                }

            } catch (error) {
                console.error('Update status error: ', error)

                const message = {
                    title: 'Удаление анкеты',
                    massage: 'Ошибка при удалении анкеты',
                    type: 'danger'
                }
                this.$store.commit('setFlashMessage', message)
            } finally {
                let anketa

                for (const entry of Object.entries(this.myProfiles)) {
                    if (entry[1].items && entry[1].items.length) {
                        anketa = entry[1].items.find(i => i.id === id)
                        if (anketa && anketa.id) {
                            break
                        }
                    }
                }

                if (anketa) {
                    this.myProfiles.find(p => p.id === anketa.status.id).items = this.myProfiles.find(p => p.id === anketa.status.id)?.items.filter(p => p.id !== anketa.id)
                    anketa.status.name = 'В архиве'
                    anketa.status.id = 5
                    this.myProfiles.find(p => p.id === 5)?.items?.push(anketa)
                }
            }
        },
      
        // handleAddClick(){
        //     if (this.profilesCount == 0 && !this.goTo) {
        //         this.showModal = true
        //     }else{
        //         this.$router.push('/questionnaire')
        //     }
        // },
        // handleGoTo(){
        //     this.showModal = false
        //     this.goTo = true
        // }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            myProfiles: [
                {
                    id: 7,
                    status: 'Черновики',
                    items: [],
                    active: true
                },
                {
                    id: 6,
                    status: 'Без продвижения',
                    items: [],
                    active: true
                },
                {
                    id: 1,
                    status: 'На модерации',
                    items: [],
                    active: true
                },
                {
                    id: 5,
                    status: 'В архиве',
                    items: [],
                    active: true
                },
                {
                    id: 2,
                    status: 'На продвижении',
                    items: [],
                    active: true
                },
                {
                    id: 8,
                    status: 'Загружена проходка',
                    items: [],
                    active: true
                },
                {
                    id: 9,
                    status: 'Проходка подтверждена',
                    items: [],
                    active: true
                },
                {
                    id: 10,
                    status: 'Проходка отклонена',
                    items: [],
                    active: true
                }
            ],
            profilesCount: 0,
        
        }
    },
    async mounted() {
        await this.updateData()

    },
}
</script>

<template>
    <div class="row align-items-center mb-3 justify-content-between justify-content-md-start">
        <div class="col-auto title">Мои анкеты</div>
        <div class="col-auto">
            <button class="btn-dark-title hover-btn" @click="this.$router.push('/questionnaire')">Добавить анкету</button>
        </div>
    </div>
    <card-white>
        <div class="row justify-content-center" v-if="profilesCount == 0">
                <div class="col-12 pt-5 pb-3">
                    <h2 class="text-center">У вас ещё пока нет ни одной анкеты. Есть повод добавить.</h2>
                </div>
            <div class="col-auto pb-5">
                <button  class="btn-dark-title hover-btn" @click="this.$router.push('/questionnaire')">Добавить анкету</button>
            </div>
        </div>
        <div v-for="profile in myProfiles" :key="profile.id">
            <div v-if="profile.items.length && profile.active" class="row">
                <div class="col-12 title title-dark">{{ profile.status }}</div>
                <div class="col-12 col-md-6 col-xxl-4 mt-3" v-for="p in profile.items" :key="p.id">
                    <anketa :item="p" :url="apiDomine" @delete="updateStatus" />
                </div>
            </div>
            <hr v-if="profile.items.length && profile.active">
        </div>
    </card-white>
    

</template>

<style scoped lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.example {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  z-index: 9999;
  &__link{
    display: block;
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat';
    text-decoration: underline;
  }
  &__block{
    max-width: 300px;
    background: white;
    position: relative;
    width: 100%;
    border-radius: 9px;
    padding: 50px 5px 20px 5px;
  }
  &__text{
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 117%;
    text-align: center;
    color: #525665;
    margin-bottom: 10px;
  }
}
hr {
    color: #BDC1D1;
}

.title {
    color: #fff;
    font-size: 30px;
}

.title-dark {
    color: #525665;
}

.btn-dark-title {
    background-color: #39354B;
    color: #fff;
    border-radius: 14px;
    padding: 10px 15px;
}

@media(max-width: 767px) {
    .title {
        color: #fff;
        font-size: 20px;
    }   

    .title-dark {
        color: #525665;
    }

    .btn-dark-title {
        background-color: #39354B;
        color: #fff;
        border-radius: 14px;
        font-size: 14px;
    }
}
</style>