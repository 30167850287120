<script>
import About from './About.vue';
import Posts from './Posts.vue';
import Inputs from './Inputs.vue';
import BodyChat from './BodyChat.vue';
import { mapActions, mapState } from 'vuex';

export default {
  props: ['girlAnketa'],
  components: {
    About,
    Posts,
    Inputs,
    BodyChat,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      girlId: this.$route.params.id,
      showAll: false,
      inputAdd: false
    };
  },
  methods: {
    getPost(data) {
      this.posts = data.reverse();
    },
    ...mapActions(['getPosts']),
    showAl(isActive) {
      this.showAll = isActive;
    },
  },

  computed: {
    post() {
      return this.$store.state.posts;
    },
    userGirl() {
      return this.user.profile.user_id;
    },
    userRole() {
      return this.user.role.item_name;
    },
  },

  mounted() {
    this.getPosts(this.girlId);
  },
};
</script>

<template>
  <div class="mylife" v-if="post || girlAnketa.user_id == user.user_id">
    <h2 class="mylife-title">My life</h2>
    <Posts :girlAnketa="girlAnketa" @send="getPost" v-if="this.user.user_id && girlAnketa.user_id == user.user_id" />
    <div class="mylife-wrapper" v-for="(post, index) in post" :key="index">
      <About :girlAnketa="girlAnketa" :post="post" />
      <BodyChat
        :showAll="showAll"
        :index="index"
        :post="post"
        :girlId="girlId"
        :girlAnketa="girlAnketa"
      />
      <Inputs 
      @showAl="showAl" 
      :girlAnketa="girlAnketa" 
      :post="post"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mylife {
  position: relative;
  margin-top: 18px;
  &::before{
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 1px;
    background: black;
    opacity: 0.25;
  }
  &-wrapper {
    border: 1px solid rgba(189, 193, 209, 0.31);
    border-radius: 16px;
    padding: 15px 25px 42px 25px;
    background: #f5f5f5;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      padding: 13px;
    }
  }
}
</style>
