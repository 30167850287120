<script>
import axios from "axios";

export default {
    name: 'VideoCards',
    props: {
        page: {
            type:  String,
            required: false,
            default:  'main',
        },
        id: {
          type: String,
          required: false
        }
    },
    methods: {
        getCards() {
            const method = (this.page && this.page === 'category') ? 'api-ad/get-ad-video-in-profiles' : 'api-ad/get-ad-video-to-title-page'
            axios
                .get(this.apiUrl + method
                    + '&auth=' + this.user.username + ':' + this.user.auth_key
                    + `&city_id=${this.user.city?.id || 1}`
                    + `&category_id=${this.id}`
                )
                .then((response) => {
                    console.log(response)
                    if (response.data && response.data.length === 0) { return }

                    const chunkSize = 4;

                    for (let i = 0; i < response.data.length; i += chunkSize) {
                        this.pages.push(response.data.slice(i, i + chunkSize));
                    }
                    
                    this.videos = this.pages[0]
                    console.log('this.videos', this.videos)

                    if (!this.pages[1] || this.pages[0].length === 0) {
                        this.showBtn = false;
                    }
                }).catch(error => console.log('getCards: ', error))

        },
        updateView(video_id) {

            axios
                .get(this.apiUrl + 'api-girl/update-video-view'
                    + '&auth=' + this.user.username + ':' + this.user.auth_key
                    + '&id=' + video_id)
        },
        displayNext() {
            console.log('this.lastView', this.lastView)
            this.videos = this.videos.concat(this.pages[this.lastView + 1])

            for (const p of this.pages[this.lastView + 1]) {
                this.updateView(p.id)
            }


            this.lastView++;

            let next = this.lastView + 1;
            if (!this.pages[next]) {

                this.showBtn = false
            }
        },
        playToggle(i) {
            const currentRef = `itemVideo_${i}`
            const videoTest = this.$refs[currentRef][0]
            const button = videoTest.closest('.video-card').querySelector('.video-play')
            const poster = videoTest.closest('.video-card').querySelector('.video-card__poster')
            const vids = document.querySelectorAll('video')

            vids.forEach(vid => vid.classList.remove('active'))

            videoTest.classList.add('active')

            vids.forEach(vid => {
                if (!vid.classList.contains('active')) {
                    const btnWrap = vid.closest('.video-card')
                    if (btnWrap !== null) {
                        btnWrap.querySelector('.video-play').style.backgroundImage = 'url(' + require('@/assets/img/play.svg')
                    }
                    vid.pause()
                }
            })

            if (videoTest.paused) {
                videoTest.play()
                poster.classList.add('hide')
                button.style.backgroundImage = 'url(' + require('@/assets/img/pause.png')
            } else {
                videoTest.pause()

                button.style.backgroundImage = 'url(' + require('@/assets/img/play.svg')
            }
        },
        volumeToggle(i) {
            const currentRef = `itemVideo_${i}`
            const videoTest = this.$refs[currentRef][0]
            const button = videoTest.closest('.video-card').querySelector('.video-volume');
            if (videoTest.muted) {
                videoTest.muted = false
                button.style.backgroundImage = 'url(' + require('@/assets/img/volume.svg')
            } else {
                videoTest.muted = true
                button.style.backgroundImage = 'url(' + require('@/assets/img/mute.svg')
            }
        },
        updateRemainingTime(i) {
            const currentRef = `itemVideo_${i}`
            const videoTest = this.$refs[currentRef][0]
            const videoTestWrap = videoTest.closest('.video-card')
            const remainingTimeElement = videoTestWrap.querySelector('.video-remaining-time');
            const progressiveBar = videoTestWrap.querySelector('.video-progressive-bar--pr');

            let remainingTime = videoTest.currentTime;
            let minutes = Math.floor(remainingTime / 60);
            let seconds = Math.floor(remainingTime % 60);
            minutes = isNaN(minutes) ? 0 : minutes;
            seconds = isNaN(seconds) ? 0 : seconds;
            remainingTimeElement.textContent = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;

            let progressiveBarMoving = (videoTest.currentTime / videoTest.duration) * 100;
            progressiveBar.style.width = `${progressiveBarMoving}%`

        },
        getVideoSource(card) {
            let name = card.name;

            if (card?.video?.name) {
                
                name = card.video.name;
                if(!name.includes('/girls/video/')) {
                    return this.apiDomine + '/web/uploads/girls/video/' + name;                    
                }           
                else {
                    return this.apiDomine + '/web/uploads' + name;
                }
            }

            return '';
        },
        getVideoPoster(card) {
            console.log('getVideoPoster(card)', card)
            let name = '';

            if (card && card.photo && card.photo[0] && card.photo[0].pic) {
                name = card.photo[0].pic;
            }

            return this.apiDomine + '/web/uploads' + name;
        }
    },
    mounted() {
        this.getCards()
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            videos: [],
            pages: [],
            btnText: 'Показать ещё',
            showBtn: true,
            lastView: 0
        }
    },
    computed: {
        userCity(){
            return this.$store.getters.getCity
        }
    },
    watch:{
        userCity: function () {
            this.showBtn = true
            this.lastView = 0
            this.pages = []
            this.videos = []
            this.getCards()
        }
    }
}
</script>

<template>
    <div class="container" v-if="videos.length > 0">
        <div class="row">
            <div class="col-12 col-md-3 mb-3 mb-md-3" v-for="(v, i) in this.videos" :key="v.id">
                <div class="video-card">
                    <video 
                        :ref="`itemVideo_${i}`" 
                        preload="none"
                        playsinline
                        webkit-playsinline
                        muted @click="playToggle(i)" @timeupdate="updateRemainingTime(i)"
                        loop>
                        <source :src="getVideoSource(v)+'#t=0.1'" type="video/mp4"
                            codecs="avc1.42E01E, mp4a.40.2" />
                    </video>
                    <div class="video-card__poster" :style="{
                        backgroundImage: `url(${getVideoPoster(v)})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }"></div>
                    <div class="video-card__name" v-html="v.name"></div>
                    <div class="video-progressive-bar">
                        <div class="video-progressive-bar--pr"></div>
                    </div>
                    <div class="video-remaining-time">00:00</div>
                    <button class="video-play video-btns hover-btn" @click="playToggle(i)"></button>
                    <button class="video-volume video-btns hover-btn" @click="volumeToggle(i)"></button>

                    <router-link :to="'/anketa/' + v.id" target="_blank">
                        <button class="video-link hover-btn">
                            Открыть анкету
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row mt-3 justify-content-center" v-if="showBtn">
            <div class="col-auto">
                <button class="display-next hover-btn" @click="this.displayNext">{{ this.btnText }}</button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
button.display-next {
    background-color: #72666A;
    color: #FFFFFF;
    border-radius: 25px;
    padding: 8px 22px;
    font-size: 16px;
    font-weight: 500;
}

button.display-next:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}

.video-card {
    background: #2D2F3A;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 420px;
    overflow: hidden;

    video {
        width: 100%;
        object-fit: cover;
        border-bottom: 1px solid transparent; /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
        &::-webkit-media-controls-panel {
            display: none !important;
            -webkit-appearance: none;
            opacity: 0;
            visibility: hidden;
        }
        &::-webkit-media-controls-play-button {
            display: none !important;
            -webkit-appearance: none;
            opacity: 0;
            visibility: hidden;
        }
        &::-webkit-media-controls-start-playback-button {
            display: none !important;
            -webkit-appearance: none;
            opacity: 0;
            visibility: hidden;
        }
    }

    

    &__poster {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        &.hide {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__name {
        position: absolute;
        top: 45px;
        left: 30px;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%
    }

    &:hover .video-play {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }    
}

.video-link {
    font-size: 14px;
    line-height: 100%;
    border-radius: 50px;
    padding: 9px 0;
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 35px;
    color: #fff;
    transform: translateX(-50%);
    background-color: rgba(#fff, 0.2);
    transition: 0.3s;
    border: solid 1px transparent;
    backdrop-filter: blur(10px);

    @media (max-width: 575px) {
        max-width: 90%;
        font-size: 10px;
    }

    &:hover {
        background-color: rgba(#fff, 0);
        border: solid 1px #fff;
    }
}

.video-play {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    display: block;
    background-color: rgba(#fff, 0.1);
    background-image: url('@/assets/img/play.svg');
}

.video-volume {
    position: absolute;
    top: 60px;
    right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(#fff, 0.1);
    backdrop-filter: blur(10px);
    background-image: url('@/assets/img/mute.svg');
}

.video-btns {
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.video-remaining-time {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 12px;
    color: rgba(#fff, 0.8);
}

.video-progressive-bar {
    position: absolute;
    top: 12px;
    height: 4px;
    background-color: rgba(#fff, 0.3);
    border-radius: 15px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);

    &--pr {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;
        background-color: #FF4032;
        border-radius: 10px;
    }
}
</style>