<script>
import axios from "axios";
import {defineComponent} from "vue";

export default defineComponent({
    name: 'AdminUserReg',
    props: {
        role: {
            type: String,
            require: true,
        }
    },
    methods: {
        registerUser() {
            this.girl.role = this.role
            this.validateForm()
            axios
                .post(this.apiUrl + 'api-user/register', this.girl)
                .then((response) => {
                    if (response.data.status) {
                        this.girl.auth_key = response.data.user.auth_key
                        this.show = false;
                        this.$emit('register', response.data.user);
                    } else {

                        this.error = response.data.error
                    }
                })
        },
        validateForm() {
            this.isInvalid = false;
            let result = true
            let regEx = this.girl.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i)
            if (this.girl.name === "") {
                this.isInvalid = true;
            }
            if (regEx == null) {
                this.isInvalid = true;
            }
        },
        recoverPassword() {
            this.recover = false
        },
        generatePassword(){
            let length = 8,
                charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            for (let i = 0, n = charset.length; i < length; ++i) {
                this.password += charset.charAt(Math.floor(Math.random() * n));
                if(this.girl.password === "" || this.girl.password === undefined || this.girl.password.length < 8 ){
                    this.girl.password = this.password + Math.floor(Math.random() * 100)
                }
            }
        }
    },
    data() {
        return {
            apiUrl: this.$store.getters.getApiUrl,
            girl: {},
            error: '',
            isInvalid: false,
            recover: true,
            show: true,
            password:''
        }
    },

})
</script>

<template>
    <div class="row" v-if="this.show">
        <div class="content col-12 p-5">
            <div class="row">
                <div class="col-12">
                    <input class="input-log p-2 w-100" type="text" v-model="this.girl.name" placeholder="Имя*">
                </div>
                <div class="col-12 mt-3">
                    <input :class="isInvalid ? 'invalid input-log p-2 w-100': ' input-log p-2 w-100'" type="text"
                           v-model="this.girl.email"
                           placeholder="Email*">
                </div>
                <div class="col-12 mt-3">
                    <input class="input-log p-2 w-100" type="text" v-model="this.girl.tg" placeholder="Telegram">
                </div>
                <div class="col-12 mt-3">
                    <input class="input-log p-2 w-100" type="text" v-model="this.girl.username" placeholder="Логин*">
                </div>
                <div class="col-12 mt-3">
                    <input class="input-log p-2 w-100" type="text" @click="generatePassword" v-model="this.girl.password" placeholder="Пароль*">
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12 error-text text-center">{{ this.error }}</div>
            </div>
            <div :class="isInvalid ? 'error-text row mt-3': ' row mt-3'">

            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <button class="btn btn-gradient w-100 p-2 hover-btn" @click="registerUser">Зарегистрироваться</button>
                </div>
            </div>
        </div>
    </div>


</template>

<style scoped>
.content {
    border-radius: 24px;
    background: #F4EAE9;
    color: #525665;
    border: 1px solid #F4EAE9;
}

.btn-gradient {
    border-radius: 15px;
    background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
    color: #fff;
}

.btn-gradient:hover {
    font-weight: 700;
    color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.9);
}

.text-link {
    font-weight: 700;
    cursor: pointer;
    color: #525665;
    text-decoration: underline;
}

.sm-text {
    font-size: 14px;
}

h5 {
    color: #2D2F3A;
    font-weight: 700;
}

.nav {
    position: relative;
    top: 0;
}

.nav-pills .nav-link.active {
    border-radius: 30px;
    background: linear-gradient(93deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
    color: #fff;
}

.nav-pills .nav-link {
    border-radius: 30px;
    background: linear-gradient(93deg, rgba(114, 102, 106, 0.30) 0%, rgba(82, 75, 95, 0.30) 50.09%, rgba(32, 31, 54, 0.30) 99.15%);
    color: #fff;
}

.input-log {
    border-radius: 12px;
    background: #FFF;
}

input.check {
    width: 16px;
    max-width: 16px;
    height: 16px;
    border: solid 1px #BDC1D1;
    border-radius: 4px;
    cursor: pointer;
}

.error-text {
    color: #980000;
    font-size: 12px;
}

.invalid {
    border: 1px solid #980000 !important;
}


</style>