<script>
import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import telegramInputs from '@/mixins/posttg';
export default {
  data() {
    return {
      apiUrl: this.$store.getters.getApiUrl,
      user: this.$store.getters.getUser,
      errorPass: false,
      apiDomine: this.$store.getters.getApiDomine,
      imgUrl: '',
      selectFile: null,
      imageUrl: '',
      tgChange: '',
      isModal: false,
      charRus: false,
      settings: [],
      isModalOpen: false,
      arrayInputs: [
        {
          title: 'Ник',
          descr: 'отображается на сайте',
          inpval: '',
          labelfor: 'nik',
          types: 'text',
          placeDef: '',
          messTg: 'Ник изменён',
        },
        {
          title: 'Email',
          descr: 'Нужен только для восстановления пароля',
          inpval: '',
          labelfor: 'email',
          types: 'email',
          placeDef: '',
          messTg: 'Email изменён',
        },
        {
          title: 'Старый пароль',
          inpval: '',
          labelfor: 'oldpass',
          types: 'password',
        },
        {
          title: 'Новый пароль',
          inpval: '',
          labelfor: 'newpass',
          types: 'password',
          messTg: 'Пароль изменён',
        },
        {
          title: 'Подтвердите пароль',
          inpval: '',
          labelfor: 'confirm',
          types: 'password',
        },
      ],
      arrayCheckbox: [
        {
          title: 'Сообщения из мессенджера',
          descr: 'отображается на сайте',
          inpval: false,
          labelfor: 'mess',
          messTg: 'Сообщения из мессенджера включены',
        },
        {
          title: 'Вы получили новый комментарий в ленте My Life',
          inpval: false,
          labelfor: 'life',
          messTg: 'Вы получили новый комментарий в ленте My Life',
        },
        {
          title: 'Появился новый заказ, вы можете откликнуться на него',
          inpval: false,
          labelfor: 'order',
          messTg:
            'Уведомления появился новый заказ откликнуться на него включены',
        },
        {
          title: 'Вы получили новый отзыв',
          inpval: false,
          labelfor: 'feedback',
          messTg: 'Вы получили новый отзыв',
        },
        {
          title: 'Вашу анкету добавили в избранное',
          inpval: false,
          labelfor: 'favourites',
          messTg: 'Вашу анкету добавили в избранное',
        },
        {
          title: 'Платное размещение завершено ',
          inpval: false,
          labelfor: 'posted',
          messTg: 'Уведомление платное размещение включены',
        },
      ],
      arrayCheckboxUser: [
        {
          title: 'Сообщения из мессенджера',
          inpval: false,
          labelfor: 'mess',
          messTg: 'Уведомления сообщения из мессенджера включены',
        },
      ],
      myArray: [],
    };
  },
  mixins: [telegramInputs],
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  
  methods: {
    saveInputs(val){
      console.log('qweqweqwe');
  
      if (val == 'inp') {
        this.arrayInputs.forEach(item => {
          if (item.inpval != '') {
            this.isModalOpen = true
            setTimeout(() => {
             this.isModalOpen = false
             }, 1500);
          }
        })
      }else{
        this.isModalOpen = true
         setTimeout(() => {
          this.isModalOpen = false
         }, 1500);
      }
    },
    async saveCheckbox() {
       await this.checkboxPost()
        this.checkboxGet()
    },
    
    async checkboxPost(){
      const filteredArray = this.arrayCheckbox.filter((obj) => obj.inpval);
      localStorage.setItem('checkbox', JSON.stringify(filteredArray));
      try {
        for (let obj of this.arrayCheckbox) {
          const res = await axios.post(
            this.apiUrl +
              'api-user/add-setting' +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            {
              user_id: this.user.user_id,
              name: obj.labelfor,
              value: obj.inpval ? 'true' : 'false',
            }
          );
        }
      } catch (error) {
        console.log('Ошибка при получении checkboxPost:', error);
      }
    },
    async checkboxGet(){
      try {
        const res = await axios.get(`${this.apiUrl}api-user/get-user-settings&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.user.user_id}`)
        if (res.data.status == true) {
          this.cookies.set('userSettings', JSON.stringify(res.data.data));
          this.$store.commit('setUserSettings', res.data.data); 
        }
      } catch (error) {
        console.log('Ошибка при получении checkboxGet', error);
      }
    },

  async  saveCheckboxUser() {
      const filteredArrayUser = this.arrayCheckboxUser.filter((obj) => obj.inpval);
      localStorage.setItem('checkboxUser', JSON.stringify(filteredArrayUser));
      try {
        for (let obj of this.arrayCheckboxUser) {
          const res = await axios.post(
            this.apiUrl +
              'api-user/add-setting' +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            {
              user_id: this.user.user_id,
              name: obj.labelfor,
              value: obj.inpval ? 'true' : 'false',
            }
          );
        }
      } catch (error) {
        console.log('Ошибка при получении checkboxPost:', error);
      }
      
    },
    defaultModel() {
      this.arrayInputs.forEach((el, index) => {
        if (index == 0) el.placeDef = this.user.profile.name;
        else if (index == 1) el.placeDef = this.user.email;
      });
    },
    async inputSubmit() {
      this.inputName();
      this.inputEmail();
      this.changePass();
    },
    async changePass() {
      try {
        if (
          this.arrayInputs[2].inpval != '' &&
          this.arrayInputs[3].inpval != '' &&
          this.arrayInputs[3].inpval == this.arrayInputs[4].inpval
        ) {
          const res = await axios.post(
            this.apiUrl +
              'api-user/update-password' +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            {
              user_id: parseInt(this.user.user_id),
              old_password: this.arrayInputs[2].inpval,
              password: this.arrayInputs[3].inpval,
            }
          );
          this.telegramInputs(this.arrayInputs[3].messTg);
        } else if (
          this.arrayInputs[3].inpval == '' &&
          this.arrayInputs[4].inpval == ''
        ) {
          return;
        } else if (this.arrayInputs[3].inpval != this.arrayInputs[4].inpval) {
          this.errorPass = true;
        }

        this.arrayInputs[3].inpval = '';
        this.arrayInputs[2].inpval = '';
        this.arrayInputs[4].inpval = '';
      } catch (error) {
        console.log('Ошибка изменения пароля', error);
      }
    },
    async inputName() {
      try {
        if (this.arrayInputs[0].inpval != '') {
          const res = await axios.post(
            this.apiUrl +
              'api-user/update-user-name&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            {
              user_id: parseInt(this.user.user_id),
              name: this.arrayInputs[0].inpval,
            }
          );
          this.updateName();
          this.telegramInputs(this.arrayInputs[0].messTg);
        }

        this.arrayInputs[0].inpval = '';
        this.arrayInputs[0].placeDef = '';
      } catch (error) {
        console.log('Произошла ошибка обновления имени', error);
      }
    },
    updateName() {
      const profile = this.cookies.isKey('profile')
        ? this.cookies.get('profile')
        : false;
      if (profile) {
        const updateProfile = { ...profile, name: this.arrayInputs[0].inpval };
        this.cookies.set('profile', updateProfile);
        this.$store.commit('changeName', this.arrayInputs[0].inpval);
      }
    },
    updateAvatarCookie() {
      const profile = this.cookies.isKey('profile')
        ? this.cookies.get('profile')
        : false;
      if (profile) {
        const updateProfile = { ...profile, avatar: this.imgUrl };
        this.cookies.set('profile', updateProfile);
        this.$store.commit('changeAvatar');
      }
    },
    async inputEmail() {
      try {
        if (this.arrayInputs[1].inpval != '') {
          const res = await axios.post(
            this.apiUrl +
              'api-user/update-email&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            {
              user_id: parseInt(this.user.user_id),
              email: this.arrayInputs[1].inpval,
            }
          );
          this.updateEmail();
          this.telegramInputs(this.arrayInputs[1].messTg);
          this.$store.commit('changeEmail', this.arrayInputs[1].inpval);
          this.arrayInputs[1].inpval = '';
          this.arrayInputs[1].placeDef = '';
        }
      } catch (error) {
        console.log('Произошла ошибка обновления email', error);
      }
    },
    updateEmail() {
      let email = this.cookies.isKey('email')
        ? this.cookies.get('email')
        : false;
      if (email) {
        email = this.arrayInputs[1].inpval;
        this.cookies.set('email', email);
        this.$store.commit('changeEmail', this.arrayInputs[1].inpval);
      }
    },
    handleFileChange(event) {
      this.selectFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.selectFile);
      this.publishImage();
    },
    async publishImage() {
      let formData = new FormData();
      formData.append('image', this.selectFile);
      try {
        const response = await axios.post(
          this.uploadLink +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          formData
        );
        if (response.status == 200) {
          this.imgUrl = response.data.name;
        }
      } catch (error) {
        console.log('Не загрузилось фото', error);
      }
      this.updateAvatar();
    },
    async updateAvatar() {
      try {
        const response = await axios.post(
          this.uploadAvatar +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          {
            user_id: parseInt(this.user.user_id),
            avatar: this.imgUrl,
          }
        );
        this.updateAvatarCookie();
        console.log(response);
      } catch (error) {
        console.log('Не загрузилась аватарка', error);
      }
    },
    async changeTg() {
      try {
        if (this.tgChange != '') {
          const res = await axios.post(
            this.apiUrl +
              'api-user/update-tg&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            {
              user_id: parseInt(this.user.user_id),
              tg: this.tgChange,
            }
          );
          this.$store.commit('changeTg', this.tgChange);
          this.tgChange = '';
          this.isModal = true;
        }
      } catch (error) {
        console.log('Произошла ошибка обновления telegram', error);
      }
    },
    localeCheck() {
      const storedData = JSON.parse(localStorage.getItem('checkbox')) || [];
      storedData.forEach((data) => {
        const index = this.arrayCheckbox.findIndex(
          (item) => item.labelfor == data.labelfor
        );
        if (index !== -1 && data.inpval) {
          this.arrayCheckbox[index].inpval = true;
        }
      });
    },
    localeUser() {
      const storedData = JSON.parse(localStorage.getItem('checkboxUser')) || [];
      storedData.forEach((data) => {
        const index = this.arrayCheckboxUser.findIndex(
          (item) => item.labelfor == data.labelfor
        );
        if (index !== -1 && data.inpval) {
          this.arrayCheckboxUser[index].inpval = true;
        }
      });
    },
    modalClose() {
      this.isModal = false;
    },
    checkInput1(event) {
      const inputValue = event.target.value;
      if (/^[а-яё]+$/i.test(inputValue)) {
        this.charRus = true;
      } else {
        this.charRus = false;
        this.tgChange = inputValue.replace(/[^a-zA-Z0-9,.!?_]/g, '');
      }
    },
    checkInput2(event) {
      const inputValue = event.target.value;
      if (event.target.id == 'email') {
        if (/^[а-яё]+$/i.test(inputValue)) {
          event.target.value = '';
        }
      }
    },
  },
  mounted() {
    this.defaultModel();
    this.tgChange = this.user.profile.tg;
    this.localeCheck();
    this.localeUser();
    this.$el.addEventListener('keypress', this.restrictInput);
  },
  computed: {
    uploadLink() {
      return this.apiUrl + 'api-user/upload-avatar';
    },
    uploadAvatar() {
      return this.apiUrl + 'api-user/update-avatar';
    },
    filteredArrayCookie() {
      return this.arrayCheckbox.filter((obj) => obj.inpval == false);
    },
  },
};
</script>

<template>
  <div class="setting">
    <h1 class="setting-title">Настройка аккаунта</h1>

    <div class="setting-wrapper">
      <div class="setting-wrapper-left">
        <div class="setting-wrapper-left__photos">
          <span class="setting-wrapper-left__title">Добавьте аватарку</span>
          <label for="change" class="setting-wrapper-left__photo cursor">
            <img
              v-if="imageUrl == ''"
              class="setting-wrapper-left__add"
              :src="this.apiDomine + '/web/uploads/' + this.user.profile.avatar"
              alt=""
            />
            <img
              v-else
              class="setting-wrapper-left__add"
              :src="imageUrl"
              alt=""
            />
            <i class="bi bi-arrow-clockwise"></i>
            <input
              @change="handleFileChange"
              id="change"
              type="file"
              style="display: none"
            />
          </label>
        </div>
        <form @submit.prevent="inputSubmit" class="">
          <div
            class="setting-wrapper-left__forms"
            :class="{ active: errorPass }"
          >
            <div
              class="setting-wrapper-left__changes"
              v-for="(item, index) in arrayInputs"
              :key="index"
            >
              <label :for="item.labelfor" class="cursor">
                {{ item.title }}
                <span v-if="item.descr" class="setting__span">{{
                  item.descr
                }}</span>
              </label>
              <input
                :type="item.types"
                :id="item.labelfor"
                v-model="item.inpval"
                :placeholder="item.placeDef"
                @input="checkInput2($event)"
              />
            </div>
            <p class="setting-wrapper__error" :class="{ active: errorPass }">
              Пароли не совпадают
              <span @click="errorPass = false" class="cursor">❌</span>
            </p>
          </div>

          <button class="setting-wrapper-btn"  @click="saveInputs('inp')">Сохранить изменения</button>
        </form>
      </div>
      <div class="setting-wrapper-right">
        <h3 class="setting-wrapper-right__title">Уведомления</h3>
        <div class="setting-wrapper-right__tg">
          <label for="tg"> Укажите Telegram чтобы получать уведомления </label>
          <input
            required
            v-model="tgChange"
            placeholder="telegram"
            type="text"
            id="tg"
            @input="checkInput1"
          />
          <p v-if="charRus" class="char-rus">Только английские буквы</p>
          <button :disabled="charRus" @click="changeTg">Изменить</button>
        </div>
        <form
          @submit.prevent="saveCheckbox"
          v-if="user.role.item_name == 'girl'"
        >
          <div class="setting-wrapper-right__check">
            <div
              class="setting-wrapper-right__box"
              v-for="(item, index) in arrayCheckbox"
              :key="index"
            >
              <label :for="item.labelfor" class="cursor">
                {{ item.title }}
              </label>
              <div class="setting-wrapper-right__switch">
                <input
                  type="checkbox"
                  :id="item.labelfor"
                  v-model="item.inpval"
                  class="cursor"
                />
              </div>
            </div>
          </div>
          <button class="setting-wrapper-btn" @click="saveInputs">Сохранить изменения</button>
        </form>
        <form @submit.prevent="saveCheckboxUser" v-else>
          <div class="setting-wrapper-right__check">
            <div
              class="setting-wrapper-right__box"
              v-for="(item, index) in arrayCheckboxUser"
              :key="index"
            >
              <label :for="item.labelfor" class="cursor">
                {{ item.title }}
              </label>
              <div class="setting-wrapper-right__switch">
                <input
                  type="checkbox"
                  :id="item.labelfor"
                  v-model="item.inpval"
                  class="cursor"
                />
              </div>
            </div>
          </div>
          <button class="setting-wrapper-btn" @click="saveInputs">Сохранить изменения</button>
        </form>
      </div>
    </div>
    <div class="modal-tg" v-if="isModal">
      <div class="modal-tg__content" v-click-outside="modalClose">
        <p>Для подтверждения пройдите по ссылке и напишите боту</p>
        <a
          @click="modalClose"
          class="modal-tg__link"
          href="https://t.me/Egoza_push_bot"
          target="_blank"
        >
          <img src="@/assets/img/tg.png" alt="" /> <span>Перейти</span>
        </a>
      </div>
    </div>
  </div>
  <Transition name="fade">
    <div class="modal" v-if="isModalOpen">
      <div class="modal-settings">
        <p class="modal-settings__text">Изменения сохранены</p>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  &-settings{
    position: relative;
    background: #fff;
    border-radius: 9px;
    padding: 15px;
    &__text{
      font-size: 20px;
    }
  }
}

.setting {
  font-family: 'Montserrat';
  margin-top: 40px;
  &__span {
    color: #bdc1d1;
    font-weight: 400;
    font-size: 10px;
  }
  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 225%;
    color: #fff;
    margin-bottom: 7px;
    @media (max-width: 786px) {
      font-size: 20px;
    }
  }
  &-wrapper {
    padding: 30px 44px 98px 44px;
    border-radius: 12px;
    background: #fff;
    display: flex;
    gap: 110px;
    @media (max-width: 1312px) {
      gap: 40px;
    }
    @media (max-width: 1232px) {
      padding: 30px 44px 30px 44px;
    }
    @media (max-width: 786px) {
      flex-wrap: wrap;
    }
    @media (max-width: 576px) {
      padding: 30px;
    }
    &__error {
      padding: 5px;
      font-size: 14px;
      border-style: solid;
      border-width: 1px;
      border-radius: 6px;
      width: max-content;
      border-color: red;
      background-image: linear-gradient(
        rgba(248, 81, 73, 0.1),
        rgba(248, 81, 73, 0.1)
      );
      color: red;
      display: none;
      margin-bottom: 0;
      &.active {
        display: block;
      }
      & span {
        margin-left: 5px;
      }
    }

    &-btn {
      font-weight: 700;
      font-size: 10px;
      text-align: center;
      color: #fff;
      background: #39354b;
      border-radius: 10px;
      width: 155px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-left {
      position: relative;
      max-width: 525px;
      width: 100%;
      @media (max-width: 786px) {
        max-width: none;
      }
      &__photo {
        position: relative;

        &::after {
          content: 'Аватар по умолчанию';
          position: absolute;
          left: -40px;
          bottom: -35px;
          display: block;
          width: max-content;
          font-weight: 600;
          font-size: 14px;
        }
      }
      &__add {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
      }

      &::after {
        content: '';
        height: 95%;
        position: absolute;
        width: 1px;
        background: #bdc1d1;
        right: -57px;
        top: 0;
        @media (max-width: 1312px) {
          right: -20px;
        }
        @media (max-width: 786px) {
          display: none;
        }
      }
      &__forms {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin-bottom: 70px;
        &.active {
          margin-bottom: 23px;
        }
      }
      &__changes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1241px) {
          flex-direction: column;
          align-items: flex-start;
        }
        & label {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          font-size: 15px;
          color: #525665;
        }
        & input {
          max-width: 320px;
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 12px;
          height: 50px;
          padding: 10px;
          @media (max-width: 1241px) {
            max-width: none;
          }
        }
      }

      &__avatar {
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
        height: 100%;
      }
      &__title {
        font-weight: 600;
        color: #525665;
        margin-right: 60px;
        @media (max-width: 1241px) {
          margin-right: 0;
        }
      }
      &__photos {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        @media (max-width: 1241px) {
          flex-direction: column;
          gap: 10px;
        }
      }
    }
    &-right {
      max-width: 525px;
      width: 100%;
      @media (max-width: 786px) {
        max-width: none;
      }
      &__title {
        font-weight: 800;
        font-size: 24px;
        color: #525665;
        margin-bottom: 8px;
      }
      &__tg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        gap: 15px;
        position: relative;
        & .char-rus {
          position: absolute;
          top: -23px;
          right: 110px;
          font-size: 15px;
          color: red;
          margin: 0;
          @media (max-width: 1241px) {
            left: 0;
            bottom: 0;
            transform: translateY(100px);
            height: max-content;
          }
          @media (max-width: 917px) {
            transform: translateY(125px);
            width: 100%;
          }
          @media (max-width: 786px) {
            transform: translateY(100px);
            width: 100%;
          }
          @media (max-width: 486px) {
            transform: translateY(120px);
            width: 100%;
          }
        }
        @media (max-width: 1241px) {
          flex-direction: column;
          align-items: flex-start;
        }
        & label {
          font-weight: 600;
          font-size: 14px;
          color: #525665;
        }
        & input {
          max-width: 220px;
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 12px;
          height: 40px;
          padding: 10px;
          @media (max-width: 1241px) {
            max-width: none;
          }
        }
        & button {
          font-weight: 700;
          font-size: 11px;
          text-align: center;
          color: #fff;
          background: #39354b;
          border-radius: 10px;
          width: 155px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 1241px) {
            margin-top: 20px;
          }
        }
      }
      &__check {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 22px;
      }
      &__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1241px) {
          gap: 20px;
        }
        & label {
          font-weight: 600;
          font-size: 14px;
          color: #525665;
          @media (max-width: 1241px) {
            max-width: 238px;
            width: 100%;
          }
        }
      }
    }
  }
}

.cursor {
  cursor: pointer;
}

input[type='checkbox'] {
  position: relative;
  width: 42px;
  height: 21px;
  -webkit-appearance: none;
  outline: none;
  border-radius: 20px;
  transition: 0.3s;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background: #d9d9d9;
}
input:checked[type='checkbox'] {
  background: #232323;
}

input[type='checkbox']::before {
  content: '';
  position: absolute;
  width: 17.5px;
  height: 17.5px;
  border-radius: 50%;
  top: 1.75px;
  left: 1.75px;
  background: #232323;
  box-shadow: 0 0 1px 0 #706766;
  transition: 0.3s;
}
input:checked[type='checkbox']::before {
  background: #d9d9d9;
  left: 22px;
}
.bi-arrow-clockwise {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: white;
  font-size: 20px;
}

.modal-tg {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 9999;
  display: flex;
  &__content {
    margin: auto;
    padding: 15px;
    border-radius: 10px;
    background: #fff;
    color: #000;
    background-repeat: no-repeat;
    background-position: top right;
    max-width: 400px;
    border: 1px solid #2d2e2e;
  }
  &__link {
    background: #2aabee;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
  }
}
</style>
