<template>
  <div>
    <select name="" id="">
      <option value=""></option>
    </select>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'my-select'
}
</script>

<style>

</style>