import axios from 'axios';

export default {
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
    };
  },
  methods: {
    async telegramInputs(id = parseInt(this.user.user_id), message) {
        try {
            if (this.user.profile.tg != '') {
                const res = await axios.post(
                    this.apiUrl +
                      'api-tg/tg-msg&auth=' +
                      this.user.username +
                      ':' +
                      this.user.auth_key,
                    {
                      user_id: id,
                      msg: message,
                    }
                  );
                  console.log(res);
            }
        } catch (error) {
            console.log('Ошибка уведомлений', error);
        }
    }
  },
};
