<script>
export default {
    name: 'AdminButton',
    mounted() {
        if(this.user.role.item_name === 'admin'){
            this.show = true
        }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            show: false
        }
    }
}
</script>

<template>
    <router-link class="admin-btn" :to="'/admin'" v-if="this.show">
        <i class="bi bi-gear"></i>
        <div class="caption">
            В админку
        </div>
    </router-link>
</template>

<style scoped>
.admin-btn {
    display: flex;
    position: fixed;
    top: 20%;
    right: 0;
    border: 1px solid #2d2f3a;
    border-right: none;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background: #ffffff;
    padding-top: 7px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 11px;
    transition: all .5s;
    z-index: 9999;
}
.caption{
    width: 0;
    opacity: 0;
    display: none;
    padding-left: 15px;
}
.admin-btn:hover{
    color: #FFFFFF;
    background: linear-gradient(45deg, #72666A, #524B5F, #201F36);
}
.admin-btn:hover .caption{
    width: auto;
    opacity: 1;
    text-decoration: none;
    display: block;
}
</style>