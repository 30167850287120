<script>
import HeaderAdmin from '@/components/ui/header/HeaderAdmin.vue'
import NavigationMenu from '@/components/blocks/NavigationMenu.vue'
import {list} from "postcss";
import UiLoader from "@/components/ui/UiLoader.vue";
import flashMassage from "@/mixins/flashMassage";
import {useCookies} from "vue3-cookies";
import HeaderGirl from "@/components/ui/header/HeaderGirl.vue";


export default {
    computed: {
        list() {
            return list
        }
    },
    components: {
        HeaderGirl,
        UiLoader,
        HeaderAdmin,
        NavigationMenu,
        flashMassage
    },
    mixins: [flashMassage],
    setup() {
        const {cookies} = useCookies();
        return {cookies};

    },
    data() {
        return {
            user: this.$store.getters.getUser,
            sideBarShow: true,
            menuList: [
                {
                    id: 10,
                    name: 'Модерация',
                    ico: 'item1.svg',
                    url: '#',
                    submenuShow: false,
                    submenu: [
                        {
                            id: 101,
                            name: 'Отзывы',
                            url: '/admin/moderation/feedback',
                        },
                        {
                            id: 102,
                            name: 'Платежи',
                            url: '/admin/moderation/billing',
                        },
                    ]
                },
                {
                    id: 20,
                    name: 'Анкеты',
                    ico: 'item7.svg',
                    url: '/admin/profiles',
                },
                {
                    id: 30,
                    name: 'Пользователи',
                    ico: 'item7.svg',
                    url: '/admin/users',
                },
                {
                    id: 40,
                    name: 'Списки',
                    ico: 'item7.svg',
                    url: '/admin/lists',
                },
            ],
            showLoader: true,
        }
    },
    methods: {
        sidebarHide() {
            for (let i of this.menuList) {

                i.submenuShow = false
            }
            this.sideBarShow = false

        },

        sidebarShow() {
            this.sideBarShow = true
        },
        sidebarToggle(){

            if(this.sideBarShow){
                for (let i of this.menuList) {

                    i.submenuShow = false
                }
                this.sideBarShow = false
            }else{

                this.sideBarShow = true
            }
        },
        activeClean(id) {

            this.sidebarShow()
            for (let i of this.menuList) {

                i.active = false;

                i.submenuShow = false
                if (i.id === id) {

                    i.active = true;
                    i.submenuShow = true
                }

                if (i.submenu) {
                    for (let sub of i.submenu) {
                        sub.active = false
                        if (sub.id === id) {
                            sub.active = true
                            i.active = true
                        }
                    }
                }
            }
        }
    },
    mounted() {
        if (this.$store.state.user.role.item_name !== 'admin') {
            this.m_setFlash('В доступе отказано', 'Страница доступна только администраторам', 'danger')
            window.location.href = '/'
        } else {
            this.showLoader = false
        }
    },
}
</script>
<template>
    <ui-loader :show="this.showLoader" :preset="'page'"/>
    <div class="container-fluid">
        <div class="bg row flex-column justify-content-between">
            <div class="col-auto">
                <HeaderAdmin/>
            </div>
            <div class="col">
                <div class="row content h-100">
                    <div class="col" ref="page">
                        <router-view />
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <footer></footer>
            </div>
        </div>
    </div>
</template>
<style scoped>
.bg {
    background: linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%);
    min-height: 100vh;
}

.content {
    min-height: 100%;
}

.toggle-sidebar.close img {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}



.nav-link {
    font-size: 18px;
    font-weight: 400;
    color: #BDC1D1;
    stroke: #BDC1D1;
}

.nav-item.active .nav-link {
    color: #FFFFFF !important;
    stroke: #FFFFFF;
}

.submenu {
    color: #BDC1D1;
}

.submenu li {
    cursor: pointer;
    padding-left: calc(1rem + 25px);
}

.submenu li.active {
    color: #FFFFFF !important;
}
</style>