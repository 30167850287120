<script>
import axios from "axios";
import router from "@/router/router";

export default {
    name: 'AllTags',
    components: [router,],
    data(){
        return {
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            user: this.$store.getters.getUser,
            tags:[],
            loaded: false
        }
    },
    methods:{
        getAllTags() {
            axios
                .get(this.apiUrl + 'api-tag/get-tags&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.tags =  response.data
                })
        },
        clickTag(id){
            axios.post(this.apiUrl + 'api-tag/update-tag-weight&auth=' + this.user.username + ':' + this.user.auth_key,{tag_id:id})
                .then(res => {
                  console.log(res.data)
                })
            const route = this.$router.resolve('/compilation-tag/' + id)
            window.open(route.href, '_blank')
        }
    },
    mounted() {
        this.getAllTags()
    },
    watch:{
        tags: function (){
            if(this.tags.length > 0 ){
                this.loaded = true
            }
        }
    }
}
</script>

<template>
<div class="all-tags">
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-auto p-1" v-for="(item, i) in this.tags" :key="i">
            <div class="tag-item p-2" @click="this.clickTag(item.id)">
                <img :src="this.apiDomine + '/web/uploads/' + item.pic" alt="" class="tag-img">
                <span class="ps-3 pe-3">{{item.name}}</span>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.all-tags hr{
    margin-left: -15px;
    margin-right: -15px;
}
.tag-item{
    background-color: #2D2F3A;
    border-radius: 1.5rem;
    color:#fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tag-img{
    width: 26px;
    height: 26px;
    border-radius: 50%;
}
.tag-item span{

}
</style>