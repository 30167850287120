<script>
import CountryAdd from "@/pages/Admin/AdminSettings/Lists/CountryAdd.vue";
import StatusAdd from "@/pages/Admin/AdminSettings/Lists/StatusAdd.vue";
import ServiсeAdd from "@/pages/Admin/AdminSettings/Lists/ServiсeAdd.vue";
import CardWhite from "@/components/ui/CardWhite.vue";
import CategoryAdd from "@/pages/Admin/AdminSettings/Lists/CategoryAdd.vue";
import CityAdd from "@/pages/Admin/AdminSettings/Lists/CityAdd.vue";
import MetroAdd from "@/pages/Admin/AdminSettings/Lists/MetroAdd.vue";
import CurrencyAdd from "@/pages/Admin/AdminSettings/Lists/CurrencyAdd.vue";
import LogInModalCard from "@/components/blocks/LogInCard.vue";
import LogInCard from "@/components/blocks/LogInCard.vue";
import FeedbackStatus from "@/pages/Admin/AdminSettings/Lists/FeedbackStatus.vue";
import DistrictAdd from "@/pages/Admin/AdminSettings/Lists/DistrictAdd.vue";
import TagAdd from "@/pages/Admin/AdminSettings/Lists/TagAdd.vue";

export default {
    components: {
        TagAdd,
        DistrictAdd,
        FeedbackStatus,
        LogInCard,
        LogInModalCard, CurrencyAdd, MetroAdd, CityAdd, CategoryAdd, CardWhite, ServiсeAdd, StatusAdd, CountryAdd
    },
    data() {
        return {
            list: [
                {name: 'Страны', slug: 'country'},
                {name: 'Города', slug: 'city'},
                {name: 'Метро', slug: 'metro'},
                {name: 'Районы', slug: 'district'},
                {name: 'Статусы анкет', slug: 'profileStatus'},
                {name: 'Статусы отзывов', slug: 'feedbackStatus'},
                {name: 'Услуги', slug: 'profileService'},
                {name: 'Теги', slug: 'tag'},
                {name: 'Категории', slug: 'profileCategory'},
                {name: 'Валюта', slug: 'currency'},

            ],
            selectUi: 'country',
        }
    },

}
</script>

<template>
    <div class="row mb-5 ">
        <div class="col-12">
            <card-white>
                <div class="d-flex gap-2 flex-wrap justify-content-between">
                    <div class="col-5 col-md-2 list-item text-center justify-content-center d-flex align-items-center" v-for="l in list" @click="this.selectUi = l.slug">
                        {{ l.name }}
                    </div>
                </div>
            </card-white>
        </div>
    </div>


    <card-white>
        <country-add v-if="this.selectUi === 'country'"></country-add>
        <status-add v-if="this.selectUi === 'profileStatus'"></status-add>
        <feedback-status v-if="this.selectUi === 'feedbackStatus'"></feedback-status>
        <serviсe-add v-if="this.selectUi === 'profileService'"></serviсe-add>
        <category-add v-if="this.selectUi === 'profileCategory'"></category-add>
        <city-add v-if="this.selectUi === 'city'"></city-add>
        <metro-add v-if="this.selectUi === 'metro'"></metro-add>
        <currency-add v-if="this.selectUi === 'currency'"></currency-add>
        <district-add v-if="this.selectUi === 'district'"></district-add>
        <tag-add v-if="this.selectUi === 'tag'"></tag-add>
    </card-white>
</template>
<style lang="scss" scoped>
.list-item {
    border: 1px solid #2C2F3C;
    background: #2C2F3C;
    border-radius: 7px;
    color: #fff;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
}

@media(max-width: 767px) {
    .list-item {
        margin: 3px 0px;
    }
}
</style>