<script>
import FeedbackModeration from "@/components/blocks/FeedbackModeration.vue";

export default {
    name: 'AdminModeration',
    components: {FeedbackModeration},
    data() {
        return {
            panelIsShow: false,
            content: 'admin'
        }
    },
    methods: {}
}
</script>
<template>
    <main>
        <h1 class="text-white">Модерация отзывов</h1>
        <feedback-moderation></feedback-moderation>
    </main>
</template>
<style scoped>

</style>