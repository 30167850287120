<script setup>
import { onMounted, ref, computed } from 'vue';
import {useUpdateTimer} from "@/mixins/updateTimerOrder";


const props = defineProps({
    orderData: {
        type: Object,
        required: true,
        default: () => {
            return {
                city_id: null,
                coast_from: null,
                coast_to: null,
                date_add: null,
                date_time: null,
                expectations: null,
                id: null,
                place: null,
                status_id: null,
                type: null,
                user_id: null,
            };
        },
    },
    statusList: {
        type: Array,
        required: true,
        default: () => {
            return [];
        },
    },
    cityName: {
        type: String,
        default: ' ',
        required: true,
    },
    typeName: {
        type: String,
        default: ' ',
        required: true,
    },
});
const emit = defineEmits(['cancel-order']);
const {hour, min} = useUpdateTimer(props)


onMounted(async () => {});

const statusString = computed(() => {
    const status = props.statusList.find((status) => status.id === props.orderData.status_id);
    return status.name;
});

const dateString = computed(() => {
    return props.orderData.date_time
        ? new Date(props.orderData.date_time * 1000).toLocaleString('ru', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
          })
        : 'Не указано';
});

</script>
<template>
    <div class="order-preview__container position-relative pt-4 pb-4 ps-4 pe-4">
        <div class="order-preview__status mb-4">{{ statusString }}</div>
        <div class="time-order d-flex align-items-center gap-2">
        <span>Заказ закроется через {{hour + ' ' + min}}</span>
        <img
            class="img-fluid img-fire"
            src="/img/fire_sm.png"
            alt="img"
        />
      </div>
        <div class="order-preview__content row row-cols-1 row-cols-md-2">
            <div class="order-preview__details col d-flex flex-column gap-3">
                <div class="row">
                    <div class="text-gray col-12 col-sm-5">Город:</div>
                    <div class="text-white col-12 col-sm-5 pt-2 pt-sm-0">{{ cityName }}</div>
                </div>
                <div class="row">
                    <div class="text-gray col-12 col-sm-5">Тип встречи:</div>
                    <div class="text-white col-12 col-sm-5 pt-2 pt-sm-0">{{ typeName }}</div>
                </div>
                <div
                    class="row"
                    v-if="orderData.place"
                >
                    <div class="text-gray col-12 col-sm-5">Место встречи:</div>
                    <div class="text-white col-12 col-sm-7 pt-2 pt-sm-0">
                        <div class="order-preview__place">{{ orderData.place }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-gray col-12 col-sm-5">Дата и время:</div>
                    <div class="text-white col-12 col-sm-7 pt-2 pt-sm-0">{{ dateString }}</div>
                </div>
                <div class="row">
                    <div class="text-gray col-12 col-sm-5">Сумма:</div>
                    <div class="text-white col-12 col-sm-7 pt-2 pt-sm-0">
                        От {{ orderData.coast_from }} до {{ orderData.coast_to }}
                    </div>
                </div>
            </div>
            <div class="order-preview__description col pt-3 pt-md-0">
                <div class="text-white">Ожидания от встречи:</div>
                <div class="w-full mb-3 pt-3">
                    <div class="order-preview__description-text p-2">
                        {{ orderData.expectations }}
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button
                        class="order-preview__button"
                        @click="emit('cancel-order')"
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.img-fire{
  max-width: 30px;
  width: 100%;
  position: relative;
  animation: bounce 2s linear infinite;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.time-order{
  top: 1.2rem;
  right: 50px;
  position: absolute;
  @media (max-width: 520px) {
    position: static !important;
  }
}
.order-preview__container {
    background-color: #312b45;
    border-radius: 28px 28px 18px 0px;
    max-width: 955px;
    color: white;

    .text-gray {
        color: #bdc1d1;
        font-size: 14px;
    }

    .text-white {
        color: white;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
    }

    .order-preview__status {
        font-size: 22px;
        line-height: 28px;
        font-weight: bold;
    }

    .order-preview__content {
        .order-preview__details {
            .order-preview__username {
                color: #22bc32;
            }
        }
        .order-preview__place {
            word-wrap: break-word;
        }

        .order-preview__description {
            .order-preview__description-text {
                width: 100%;
                min-height: 120px;
                border-radius: 6px;
                background-color: white;
                color: #2d2f3a;
            }

            .order-preview__button {
                background-color: #bdc1d1;
                border-radius: 30px;
                padding: 10px 30px;
                color: #2d2f3a;
                font-size: 14px;

                &:hover {
                    background-color: #a3a7b4;
                }
            }
        }
    }
}
</style>
