<script setup>
import { ref } from 'vue';
const player = ref(null);
</script>

<template>
    <div class="promo-video__container">
        <div class="promo-video__wrapper">
            <video
                ref="player"
                loop
                muted
                playsinline
                webkit-playsinline
                autoplay
                preload="metadata"
            >
                <source
                    src="/video/intro_lg.mp4"
                    type="video/mp4"
                    media="(min-width: 768px)"
                />
                <source
                    src="/video/intro_sm_576p.mp4"
                    type="video/mp4"
                    media="(max-width: 767.98px)"
                />
            </video>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-video__container {
    top: 0;
    left: 0;
    width: 100%;
    background-color: $background-main-color;

    .promo-video__wrapper {
        position: relative;
        width: 100%;
        overflow-x: hidden;

        > video {
            width: 100%;
            height: auto;
            position: relative;
            object-fit: cover;
            overflow: hidden;

            border-bottom: 1px solid transparent; /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
                opacity: 0;
                visibility: hidden;
            }
            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
                opacity: 0;
                visibility: hidden;
            }
            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .promo-video__container {
        .promo-video__wrapper {
            > video {
                height: auto;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-video__container {
        .promo-video__wrapper {
            > video {
                height: 100vh;
            }
        }
    }
}
</style>
