<script setup>
import Popper from 'vue3-popper';
import { onMounted, ref } from 'vue';

const popoverContent = ref(null);

</script>

<template>
    <Popper class="what-is-luk__container">
        <div class="what-is-luk__button d-inline-flex align-items-center">
            <div class="what-is-luk__button__text me-2">
                <div>
                    <slot name="button" />
                </div>
            </div>
            <div class="what-is-luk__button__icon">
                <div class="text-center">?</div>
            </div>
        </div>

        <template #content="{ close }">
            <div
                ref="popoverContent"
                class="what-is-luk__popper"
            >
                <div>
                    <div class="what-is-luk__popper__close">
                        <div @click="close">
                            <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="7.69238"
                                    y="6.80469"
                                    width="1.25523"
                                    height="9.62343"
                                    rx="0.627615"
                                    transform="rotate(135 7.69238 6.80469)"
                                    fill="white"
                                />
                                <rect
                                    x="0.887573"
                                    y="7.69238"
                                    width="1.25523"
                                    height="9.62343"
                                    rx="0.627615"
                                    transform="rotate(-135 0.887573 7.69238)"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="what-is-luk__popper__content">
                    <div>
                        <p class="what-is-luk__popper__text">
                            <slot name="text" />
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </Popper>
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';
.what-is-luk__container {
    .what-is-luk__button {
        &:hover {
            cursor: pointer;
        }

        .what-is-luk__button__text {
            background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
            line-height: 48px;
            border-radius: 65px;
            padding: 1px;

            > div {
                height: 48px;
                padding-inline: 35px;
                background-color: $background-main-color;
                border-radius: 65px;
            }
        }

        .what-is-luk__button__icon {
            background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
            line-height: 48px;
            border-radius: 65px;
            padding: 1px;
            > div {
                height: 48px;
                width: 48px;
                background: linear-gradient(90deg, #bf5152 0%, #372fd5 100%);
                border-radius: 65px;
                border: 2px $background-main-color solid;
            }
        }
    }
    .what-is-luk__popper {
        max-width: 400px;
        .what-is-luk__popper__close {
            cursor: pointer;
            background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
            border-radius: 65px;
            height: 52px;
            width: 52px;
            padding: 1px;
            margin-bottom: 8px;
            margin-left: auto;

            &:hover {
                cursor: pointer;
            }

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                border: 1px solid $background-main-color;
            }
        }

        .what-is-luk__popper__content {
            background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
            border-radius: 23px;
            padding: 2px;

            > div {
                border-radius: 15px;

                .what-is-luk__popper__text {
                    border-radius: 25px;
                    border: 7px solid $background-main-color;
                    background-color: #d9d9d9;
                    color: #14161a;
                    padding: 20px;
                    margin: 0;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }
    }
}
</style>
