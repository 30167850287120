<template>
	<button class="btn hover-btn">
		<slot></slot>
	</button>
</template>
 
 <script>
 export default {
	name: 'my-button'
 }
 </script>
 
 <style scoped>
	.btn{
		background: tomato;
		cursor: pointer;
		display: inline-block;
		margin: 0 auto;
		padding-bottom: 18px;
		border: none;
		outline: none;
		font-style: italic;
		font-size: 48px;
		font-weight: 700;
		transition: 0.3s;
		position:  relative;
		z-index: 10;
		
	}
	.btn:hover {
		opacity: 0.8;
	}
 </style>