<script setup>
import OrderBackwardButton from '@/components/order_modal/OrderBackwardButton.vue';
import OrderNextButton from '@/components/order_modal/OrderNextButton.vue';
import LogInCard from '@/components/blocks/LogInCard.vue';

import { ref } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits(['next-step', 'invalid-auth']);
const store = useStore();
const user = store.getters.getUser;
const isShowLoginCard = ref(false);
const isShowModalTg = ref(false);
const isNextButtonEnabled = ref(false);

const onLoginSuccess = () => {
    isShowLoginCard.value = false;
    if (user.role.item_name == 'user') {
        isNextButtonEnabled.value = true;
        emit('next-step', { forward: true });
    } else {
        emit('invalid-auth');
    }
};

const onHideDialog = () => {
    isShowLoginCard.value = false;
};

const showLoginCard = () => {
    isShowLoginCard.value = true;
};

const tgmodal = (el) => {    
    if (el == false) {
        isShowModalTg.value = false;
    } else {
        isShowModalTg.value = true;
    }
};
</script>

<template>
    <div class="order-auth">
        <div class="order-auth__question mb-3">
            <p class="mb-2">Отлично! Остался последний шаг! <br /></p>
            <p>
                Для того что бы получить отклики от девушек, и они могли с вами связаться, вам осталось
                <span
                    class="order-auth__login-link"
                    @click="showLoginCard"
                    >войти</span
                >
                или
                <span
                    class="order-auth__login-link"
                    @click="showLoginCard"
                    >зарегистрироваться</span
                >
                в нашем сервисе
            </p>
        </div>
        <div class="order-auth__content d-flex justify-content-end gap-2">
            <OrderBackwardButton
                class="h-100"
                @click="emit('next-step', { forward: false })"
            />
            <OrderNextButton
                :disabled="!isNextButtonEnabled"
                class="h-100"
                @click="emit('next-step', { forward: true })"
            />
        </div>
    </div>
    <LogInCard
        :show="isShowLoginCard"
        :autoredirect="false"
        @loginSuccess="onLoginSuccess"
        @hideDialog="onHideDialog"
        @tgmodal="tgmodal"
    />
    <div
        class="dialog-modal"
        v-if="isShowModalTg"
        @click="isShowModalTg = false"
    >
        <div
            class="modal-register"
            @click.stop
        >
            <p class="modal-register__text">
                Ваш аккаунт еще не активирован! <br />
                Перейдите в
                <a
                    href="https://t.me/Egoza_push_bot"
                    target="_blank"
                    >Telegram bot</a
                >, отправьте боту /start, чтобы активировать аккаунт
            </p>
            <button
                class="modal-register__close"
                @click="isShowModalTg = false"
            >
                X
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-auth {
    .order-auth__question {
        font-size: 16px;
        line-height: 20px;

        .order-auth__login-link {
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.dialog-modal{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 99999;
}

.modal-register{
    max-width: 470px;
    width: 100%;
    background: #fff;
    padding: 70px 5px;
    position: relative;
    border-radius: 9px;
    &__text{
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 20px;
        line-height: 117%;
        text-align: center;
        color: #525665;
        & a{
            text-decoration: underline;
            text-decoration-skip-ink: none;
            color: #4ba5ff;
            cursor: pointer
        }
    }
    &__close{
        position: absolute;
        top: -50px;
        right: 0;
        width: 44px;
        height: 44px;
        background: #fff;
        border-radius: 9px;
        cursor: pointer;
        &:hover{
            box-shadow: none;
        }
    }
}
</style>
