<script>
import CloseX from "@/components/ui/CloseX.vue";

/**
 * Компонент модального окна.
 * show - отображать при загрузке
 * id - ID
 * btnDisplay - Отображать кнопку вызова модалки
 * btnToggleClass - Класс кнопки вызова модалки
 * dialogParams - стили для самой модалки.
 */
export default {
    name: 'ModalDialog',
    components: {CloseX},
    props:{
        show:{
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            require: true,
        },
        btnDisplay:{
            type:Boolean,
            default: true
        },
        btnToggleClass: {
            type: String,
            default: 'btn btn-primary'
        },
        dialogParams:{
            type: Object,
            default: {
                height: 'auto',
                overflow: 'hidden'
            }
        },
        btnToggleText: 'Открыть'
    },
    data(){
        return {
            showModal: this.show,
        }
    },
}
</script>

<template>
<!--    <div v-if="btnDisplay" :class="this.btnToggleClass" @click.stop="this.showModal = true">-->
<!--        {{btnToggleText}}-->
<!--    </div>-->
    <div class="dialog" v-if="showModal">
        <div class="dialog-content" :style="[{height: this.dialogParams.height}]" >
            <close-x :preset="'top'" @click.stop="this.showModal = false; this.$emit('modalClose')" style="z-index: 1055"></close-x>
            <div class="content" :style="[{'overflow-y': this.dialogParams.overflow}]">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dialog {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    z-index: 1050;
}

.dialog-content {
    position: relative;
    margin: auto;
    padding: 15px;
    border-radius: 10px;
    background: #fff;
    color: #000;
    background-repeat: no-repeat;
    background-position: top right;
    width: 95%;
    max-width: 500px;
    border: 1px solid #2D2E2E;

}
.content{
    height: 100%;
}
</style>