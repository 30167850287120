<script>
export default {
    name:'SettingIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:20, h:20}
        }
    }
}
</script>

<template>
    <svg :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w - 4) + ' ' + (this.size.h - 4)" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4201_3871)">
            <path d="M6.66641 9.06659C7.55006 9.06659 8.26641 9.78293 8.26641 10.6666C8.26641 11.5502 7.55006 12.2666 6.66641 12.2666C5.78275 12.2666 5.06641 11.5502 5.06641 10.6666C5.06641 9.78293 5.78275 9.06659 6.66641 9.06659Z" :stroke="this.color" stroke-width="1.5"/>
            <path d="M9.33359 3.73358C8.44994 3.73358 7.73359 4.44992 7.73359 5.33358C7.73359 6.21724 8.44994 6.93358 9.33359 6.93358C10.2172 6.93358 10.9336 6.21724 10.9336 5.33358C10.9336 4.44992 10.2172 3.73358 9.33359 3.73358Z" :stroke="this.color" stroke-width="1.5"/>
            <path d="M9.59961 10.6445L13.3329 10.6445" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M6.40039 5.31152L2.66706 5.31152" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M2.66699 10.6445L3.73366 10.6445" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M13.333 5.31152L12.2663 5.31152" :stroke="this.color" stroke-width="1.5" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_4201_3871">
                <rect width="16" height="16" rx="5" :fill="this.color"/>
            </clipPath>
        </defs>
    </svg>
</template>
