<template>
    <main >
        <h1>admin main page</h1>
    </main>
</template>


<script>
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
    name: 'anketa',
    components: {UiLoader},
    data() {
        return {
            user: this.$store.getters.getUser,
            panelIsShow: false,
            content: 'admin',
        }
    },
    methods: {
        panelIsHide() {
            this.panelIsHide = false
        },


    },
}
</script>

<style lang="scss">
</style>