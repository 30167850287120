<script>
import { useCookies } from 'vue3-cookies';
import api from '@/mixins/api';
import getApi from '@/mixins/getApi';
//components
import FlashMassage from '@/components/ui/FlashMassage.vue';
import ModalTgInfo from '@/components/ui/popup/ModalTgInfo.vue';
import AdminButton from '@/components/ui/AdminButton.vue';
import Messenger from '@/components/messenger/Messenger.vue';

export default {
    components: { AdminButton, ModalTgInfo, FlashMassage, Messenger },
    mixins: [api, getApi],
    methods: {
        upUser() {
            let user = {
                user_id: this.cookies.isKey('user_id') ? this.cookies.get('user_id') : false,
                username: this.cookies.isKey('username') ? this.cookies.get('username') : false,
                auth_key: this.cookies.isKey('auth_key') ? this.cookies.get('auth_key') : false,
                tg: this.cookies.isKey('tg') ? this.cookies.get('tg') : false,
                email: this.cookies.isKey('email') ? this.cookies.get('email') : false,
                role: this.cookies.isKey('role') ? this.cookies.get('role') : false,
                profile: this.cookies.isKey('profile') ? this.cookies.get('profile') : false,                
                city_id: this.cookies.get('city_id') || localStorage.getItem(`user_not_logged_city_id`) || 1,
            };
            let userSettings;
            if (this.cookies.isKey('userSettings')) {
                userSettings = JSON.parse(this.cookies.get('userSettings'));
            } else {
                userSettings = [];
                let expireTimes = 60 * 60 * 12;
                this.cookies.set('userSettings', JSON.stringify(userSettings), expireTimes);
            }
            this.$store.commit('setUserSettings', userSettings);

            if (this.cookies.isKey('username') && this.cookies.get('username') !== 'guest') {
                user.isLogged = true;
                this.$store.commit('setLogged', true);
            }

            this.$store.commit('setUserData', user);
        },
        async upCities() {
            await this.m_getAllCity();
        },
        modalTgInfo() {
            if (this.cookies.isKey('massageTg')) {
                this.tgInfo = false;
            } else {
                this.tgInfo = true;
                let expireTimes = 60 * 60 * 12;
                this.cookies.set('massageTg', true, expireTimes);
            }
        },
        updateBalance() {
            if (!this.cookies.isKey('balance') || this.cookies.get('balance') == 0) {
                this.m_getBalance();
            }

            setInterval(this.m_getBalance, 5 * 60 * 1000);
        },
    },
    created() {        
        this.upUser();
        this.$store.commit('setIsMobile', window.innerWidth <= 992);
        window.addEventListener('resize', () => {
          this.$store.commit('setIsMobile', window.innerWidth <= 992);
        })
    },
    async mounted() {
        setTimeout(() => {
            this.modalTgInfo();
        }, 40000);
        this.updateBalance();        
        await this.upCities();

        console.log('user: ', this.user);
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            tgInfo: false,
            isloaded: false,
            cities: [],
        };
    },
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    beforeCreate() {

        document.addEventListener('DOMContentLoaded', (e) => {
            setTimeout(() => {
                this.isloaded = true;
            }, 1000);
        })
        /*document.onreadystatechange = () => {
            if (document.readyState == 'complete') {
                setTimeout(() => {
                    this.isloaded = true;
                }, 1000);
            }
        };*/
    },
};
</script>
<template>
    <router-view />
    <flash-massage></flash-massage>
    <modal-tg-info
        :show="this.tgInfo"
        @hideDialog="this.tgInfo = false"
    />
    <div
        class="loader"
        v-if="!isloaded"
    >
        <img
            src="../src/assets/img/Prew.gif"
            alt=""
        />
    </div>
    <Messenger v-if="user.isLogged" />
</template>
<style lang="scss">
@import 'styles/index';
@import 'styles/font-class.css';
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow-y: hidden;
    background: black;
    & img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
    }
}

#app {
    max-width: 100%;
    min-width: 280px;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    &.container-fluid {
        padding: 0;
    }
}

main {
    flex-grow: 1;
}

*,
*:focus,
*:after,
*:before {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

.rouble {
    font-family: $rouble;
    font-size: 1em;
    font-weight: 400;
    display: inline-block;
}

input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        appearance: none;
    }
}

.ml-auto {
    margin-left: auto;
}

button {
    cursor: pointer;
}
input::-webkit-input-placeholder {
    color: #bdc1d1;
}
input:-moz-placeholder {
    color: #bdc1d1;
}
.logo {
    margin-top: -1.5rem;
    margin-bottom: -0.5rem;
}
.hover-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}
body:has(.messenger-wrapper:not(.hide)) {
    overflow: hidden;
}
</style>
