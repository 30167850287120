<script>
import GirlListCategory from "@/components/ui/GirlListCategory.vue";
import OtzyvModal from "@/components/blocks/OtzyvModal.vue";
import AllTags from "@/components/ui/common/tags/AllTags.vue";
import QuizBlock from "@/components/blocks/QuizBlock.vue";
import NewProfilesBlock from "@/components/blocks/NewProfilesBlock.vue";

export default {
  components: {OtzyvModal, GirlListCategory, AllTags, QuizBlock, NewProfilesBlock},
  computed: {
    isMobile(){
      this.$store.getters['getIsMobile']
    }
  },
  data() {
    return {
      title: 'Инди',
      category_id: '8',
      otzyvModal: false,
      topBanner: require('@/assets/video/indiePageDeck.mp4'),
      topBannerMobile: require('@/assets/video/gif/MobileIndie.mp4'),
      poster: require('@/assets/img/main/indieDeck1.png'),
      posterMobile: require('@/assets/img/main/indieMob1.png'),
    }
  },
}
</script>
<template>
  <main class="my-0 px-0">
    <div class="main-block">
      <div class="container-fluid p-0">
        <div class="main-block__img video-padding py-0 my-0">
            <div class="py-0 my-0">
                <img :src="!isMobile ? poster : posterMobile" alt="">
            </div>
<!--          <video class="py-0 my-0" autoplay muted playsinline webkit-playsinline loop width="100%" preload="metadata"
                 :poster="!isMobile ? poster : posterMobile">
            <source :src="topBanner" type="video/mp4" media="(min-width: 992px)"/>
            <source :src="topBannerMobile" type="video/mp4" media="(max-width: 991px)"/>
          </video>-->
        </div>
      </div>
    </div>
    <girl-list-category :category="'8'" :category-name="'инди'"></girl-list-category>
    <div class="block-offer">
      <div class="container">
        <div class="block-offer__inner">
          <NewProfilesBlock
              :categoryId="category_id"
              :titleCards="title"
          />
        </div>
      </div>
    </div>
    <div class="block-offer">
      <quiz-block/>
    </div>
    <div class="block-offer">
      <div class="container">
        <all-tags/>
      </div>
    </div>
  </main>

</template>

<style scoped lang="scss">

.video-padding {
  padding-top: 150px !important;
}

@media (max-width: 760px) {
  .video-padding {
    padding-top: 160px !important;
  }
}

video {
  position: relative;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
  &::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }
}

img {
  width: 100%;
}

.main-block {
  padding-bottom: 0;
}

</style>