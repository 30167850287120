<script>
import axios from "axios";

export default {
    name: 'UploadPhoto',
    props: {
        basePicUrl: {
            type: String,
            default: ''
        },
        titlePhoto: {
            type: [String, Boolean],
            default: false
        },
        value: {
            type: String,
            default: ''
        }
        // uploadLink: {
        //     type: String,
        //     default: "api-girl/upload-photo"
        // },

    },
    methods: {
        changeInput() {
            const self = this;
            this.file = this.$refs.titlePhoto.files[0];

            const fileType = this.file.type
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp']

            if (allowedExtensions.indexOf(fileType.substr(fileType.lastIndexOf('/') + 1)) === -1) {
                console.log('file type bad: ', fileType.substr(fileType.lastIndexOf('/') + 1))
                return false
            }

            let formData = new FormData();
            formData.append('image', this.file);
            this.isUpload = true
            axios.post(this.uploadLink + '&auth=' + this.user.username + ':' + this.user.auth_key,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(function (data) {

                console.log('Загружено фото: ', data.data)
                self.$emit('uploadSuccess', {old: self.fileName, new: data.data.name})
                self.$refs.titlePhotoBlock.style.background = `url('` + self.basePictureUrl + data.data.name + `')`
                self.$refs.titlePhotoBlock.style.backgroundSize = 'cover'
                self.icon = 'reload'
                self.fileName = data.data.name;
            }).catch(function (data) {
                self.$emit('uploadFail', data)
            });
        },
        onDeleteItemHandler() {
            this.$emit('uploadSuccess', {old: this.fileName, new: ''})
            this.$refs.titlePhotoBlock.style.background = ''
            this.icon = 'plus'
            this.file = ''
            this.fileName = ''
            this.isUpload = false
            this.isWatch = true
            this.$refs.titlePhoto.value = ''
        }
    },
    mounted() {
        if (this.titlePhoto) {
            this.$refs.titlePhotoBlock.style.background = `url('${this.titlePhoto}')`
        }
    },
    watch: {
        value: function (data) {
            if (!this.isWatch && !this.isUpload && data) {
                this.$refs.titlePhotoBlock.style.background = `url('${this.apiDomine}/web/uploads${data}')`
                this.$refs.titlePhotoBlock.style.backgroundSize = 'cover'
                this.icon = 'reload'
                this.fileName = data
                this.isWatch = true
            }
        }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiDomine: this.$store.getters.getApiDomine,
            apiUrl: this.$store.getters.getApiUrl,
            fileName: '',
            file: '',
            icon: 'plus', // plus | reload
            isWatch: false,
            isUpload: false
        };
    },
    computed: {
        basePictureUrl(){
          return this.basePicUrl || this.apiDomine + '/web/uploads'
        },
        uploadLink() {
            return this.apiUrl + "api-girl/upload-photo"
        }
    }
}
</script>

<template>
    <div>
        <div class="title-photo-block" ref="titlePhotoBlock">
            <label class="">
                <input type="file" ref="titlePhoto" @change="changeInput" class="title-photo-input" accept="image/png, image/jpeg, image/webp">
                <span class="icon">
                <i class="bi bi-plus" v-if="this.icon == 'plus'"></i>
                <i class="bi bi-arrow-clockwise" v-if="this.icon == 'reload'"></i>
            </span>
            </label>

            <div v-if="fileName" class="delete-item hover-btn" @click="onDeleteItemHandler">
                    <i class="bi bi-trash3"></i>
                </div>
        </div>
    </div>

</template>

<style scoped lang="scss">
.title-photo-block { 
    position: relative;
    border-radius: 10px;
    background: linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%);
    border: 1px solid #524B5F;
    overflow: hidden;
    transition: 0.3s;
    cursor: pointer;
    height: 5vw;
    width: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
        width: 100%;
        height: 60px;
    }
}

.title-photo-input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 9px;
    padding-top: 5px;
    padding-right: 9px;
    padding-bottom: 5px;
    border-radius: 55%;
    background-color: rgba(207, 196, 194, 0.1);
    transition: 0.3s;
    cursor: pointer;
    color: #ffffff;
    @media (max-width: 575px) {
        width: 13px;
        height: 13px;
    }
}

.icon:hover {
    background-color: rgba(207, 196, 194, 0.5);
}

.delete-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    opacity: 0.8;
    background-color: rgba(255, 255, 255, .5);
    position: absolute;
    top: 2%;
    right: 5%;
    border-radius: 50%;
    font-size: 10px;
    width: 20px;
    height: 20px;

    @media (max-width: 575px) {
        font-size: 8px;
        width: 16px;
        height: 16px;
    }
}
</style>