<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
    timestamp: {
      type: Number,
      required: true,
      default: new Date()
    }
})

const timeLeft = ref('');
const timer = ref(null);

const calculateTimeLeft = () => {
  const now = new Date().getTime();

  const difference = props.timestamp - now;

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  timeLeft.value = `${days} дней ${hours} часов ${minutes} минут`;
};

onMounted(() => {
  calculateTimeLeft();
  timer.value = setInterval(calculateTimeLeft, 60000);
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>

<template>
  <div class="mb-2">{{ timeLeft }}</div>
</template>
