<template>
   <main>
		<div class="main-block">
			<div class="container">
				<div class="main-block__img">
					<img src="@/assets/img/main/girls.webp" alt="girls">
				</div>
			</div>
		</div>
   </main>
</template>

<script>
   export default {
		data() {
			return {
				title: 'Салоны'
			}
		}
   }
</script>