<template>
    <section class="promo-bonuses__container">
        <div class="promo-bonuses__header">
            <h2>Приятный бонус за регистрацию!</h2>
        </div>
        <div class="promo-bonuses__content">
            <div class="promo-bonuses__content-item">
                <img
                    class="img-fluid"
                    src="/img/bonus_1_sm.png"
                    alt=""
                />
                <div>
                    <div class="promo-bonuses__content-separator"></div>
                </div>
            </div>

            <div class="promo-bonuses__content-item">
                <img
                    class="img-fluid"
                    src="/img/bonus_2_sm.png"
                    alt=""
                />
                <div>
                    <div class="promo-bonuses__content-separator"></div>
                </div>
            </div>

            <div class="promo-bonuses__content-item">
                <img
                    class="img-fluid"
                    src="/img/bonus_3_sm.png"
                    alt=""
                />
                <div>
                    <div class="promo-bonuses__content-separator"></div>
                </div>
            </div>

            <div class="promo-bonuses__content-item">
                <img
                    class="img-fluid"
                    src="/img/bonus_4_sm.png"
                    alt=""
                />
            </div>
            <div class="promo-bonuses__note">
                <p>
                    *при получении подарка необходимо записать видео пруф (доказательство получения подарка). Видео
                    будет размещено на информационных ресурсах EZOGA.
                </p>
            </div>
        </div>
        <div class="promo-bonuses__button">
            <GradientButton @click="$emit('fill_questionnaire_clicked')">Заполнить анкету</GradientButton>
        </div>
    </section>
</template>

<script>
import GradientButton from '@/components/promo/GradientButton.vue';

export default {
    name: 'promo-bonuses',
    components: { GradientButton },
};
</script>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-bonuses__container {
    margin-bottom: 85px;
    .promo-bonuses__header {
        margin-bottom: 20px;
        > h2 {
            text-align: center;
            @include text-bold-small;
        }
    }
    .promo-bonuses__content {
        position: relative;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 45px;

        .promo-bonuses__content-item {
            position: relative;
            > div {
                height: 0;
                .promo-bonuses__content-separator {
                    background-image: url('@/assets/svg/separator_x_sm.svg');
                    position: relative;
                    height: 74px;
                    background-repeat: no-repeat;
                    z-index: 1000;
                    bottom: 13px;
                    width: 100%;
                    margin-inline: auto;
                    background-position: center;
                }
            }
        }
        .promo-bonuses__note {
            margin-bottom: 40px;
            > p {
                text-align: center;
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                font-style: normal;
            }
        }
    }

    .promo-bonuses__button {
        width: 175px;
        margin-inline: auto;
    }
}

@include media-breakpoint-up(md) {
    .promo-bonuses__container {
        .promo-bonuses__header {
            > h2 {
                text-align: start;
            }
        }

        .promo-bonuses__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            gap: 0;
            justify-items: center;

            .promo-bonuses__content-item {
                width: 100%;

                > img {
                    width: 100%;
                }
            }

            .promo-bonuses__content-item:nth-child(1) {
                .promo-bonuses__content-separator {
                    background-image: url('@/assets/svg/separator_1_md.svg');
                    position: relative;
                    height: 180px;
                    background-repeat: no-repeat;
                    z-index: 1000;
                    right: -83%;
                    bottom: 195px;
                    background-position: center;
                    background-size: contain;
                    width: 100%;
                }
            }

            .promo-bonuses__content-item:nth-child(2) {
                grid-column: 2/3;
                grid-row: 2/3;
                .promo-bonuses__content-separator {
                    background-image: url('@/assets/svg/separator_2_md.svg');
                    position: relative;
                    height: 150px;
                    background-repeat: no-repeat;
                    z-index: 1000;
                    left: -85%;
                    bottom: 175px;
                    background-position: center;
                    background-size: contain;
                    width: 100%;
                }
            }
            .promo-bonuses__content-item:nth-child(3) {
                grid-column: 1/2;
                grid-row: 3/4;

                .promo-bonuses__content-separator {
                    background-image: url('@/assets/svg/separator_3_md.svg');
                    position: relative;
                    height: 150px;
                    background-repeat: no-repeat;
                    z-index: 1000;
                    right: -85%;
                    bottom: 175px;
                    background-position: center;
                    background-size: contain;
                    width: 100%;
                }
            }
            .promo-bonuses__content-item:nth-child(4) {
                grid-column: 2/3;
                grid-row: 4/5;
            }

            .promo-bonuses__note {
                grid-column: 1/2;
                grid-row: 4/5;
                width: 344px;
                display: flex;
                align-items: flex-end;
                > p {
                    margin: 0;
                    text-align: start;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 21px;
                    font-style: normal;
                }
            }
        }

        .promo-bonuses__button {
            width: 200px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .promo-bonuses__container {
        .promo-bonuses__content {
            .promo-bonuses__content-item {
                //width: 430px;
                width: 100%;

            }

            .promo-bonuses__content-item:nth-child(1) {
                .promo-bonuses__content-separator {
                    height: 220px;
                    bottom: 240px;
                }
            }

            .promo-bonuses__content-item:nth-child(2) {
                .promo-bonuses__content-separator {
                    height: 220px;
                    bottom: 235px;
                }
            }
            .promo-bonuses__content-item:nth-child(3) {
                .promo-bonuses__content-separator {
                    height: 180px;
                    bottom: 200px;
                }
            }
            .promo-bonuses__note {
                width: 430px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-bonuses__container {
        .promo-bonuses__header {
            > h2 {
                @include text-h2-large;
                margin-bottom: 160px;
            }
        }
        .promo-bonuses__content {

            .promo-bonuses__content-item:nth-child(1) {
                .promo-bonuses__content-separator {
                    height: 220px;
                    bottom: 240px;
                }
            }

            .promo-bonuses__content-item:nth-child(2) {
                .promo-bonuses__content-separator {
                    height: 220px;
                    bottom: 235px;
                }
            }
            .promo-bonuses__content-item:nth-child(3) {
                .promo-bonuses__content-separator {
                    height: 230px;
                    bottom: 250px;
                }
            }

            .promo-bonuses__note {
                width: 100%;
                > p {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 31px;
                    font-style: normal;
                }
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .promo-bonuses__container {
        .promo-bonuses__header {
            > h2 {
                @include text-h2-large;
                margin-bottom: 160px;
            }
        }
        .promo-bonuses__content {
            margin-bottom: 110px;
            .promo-bonuses__content-item {
                width: 100%;
            }

            .promo-bonuses__content-item:nth-child(1) {
                .promo-bonuses__content-separator {
                    height: 360px;
                    bottom: 380px;
                }
            }

            .promo-bonuses__content-item:nth-child(2) {
                .promo-bonuses__content-separator {
                    height: 385px;
                    bottom: 420px;
                    left: -85%;
                }
            }
            .promo-bonuses__content-item:nth-child(3) {
                .promo-bonuses__content-separator {
                    height: 330px;
                    bottom: 360px;
                }
            }

            .promo-bonuses__note {
                width: 610px;
            }
        }
    }
}
</style>
