<script>
import Video from '@/components/ui/UiVideo.vue';
import OrderModal from '@/components/order_modal/OrderModal.vue';
import { SwiperSlide } from 'swiper/vue';

export default {
    name: 'QuizBlock',
    components: { SwiperSlide, Video, OrderModal },
    data() {
        return {
            isShowOrderModal: false,
        };
    },
    computed: {
        isMobile() {
            return this.$store.getters['getIsMobile']
        }
    },
    methods: {
        startVideo() {
            const element = this.$refs.video;
            element.play();
        },
        stopVideo() {
            const element = this.$refs.video;
            element.pause();
        },
        showOrderModal() {
            this.isShowOrderModal = true;
        },
        closeOrderModal() {
            this.isShowOrderModal = true;
        },
        mouseHandlerOver(){
          if(this.isMobile) return

      this.startVideo()
    },

    mouseHandlerLeave() {
      if (this.isMobile) return

      this.stopVideo()
    },
  },
};
</script>

<template>
  <div
      class="container"
      @mouseover="mouseHandlerOver"
      @mouseleave="mouseHandlerLeave"
  >
    <div class="quiz-block">
      <video
          ref="video"
          :autoplay="isMobile" muted playsinline webkit-playsinline loop preload="metadata"
          :src="require(`@/assets/video/cat-5.mp4`)"
      ></video>
      <div class="layer">
        <div class="row h-100 flex-column justify-content-between">
          <div class="col-12 title p-4 pt-md-5 ps-md-5 text-md-start text-center text-white">
            <b>Сделайте поиск проще, </b> заполните бриф, девушки откликнутся сами.
          </div>
          <div
              class="col-12 d-md-block d-flex justify-content-center justify-content-md-start mt-5 p-4 pb-md-5 ps-md-5"
          >
            <button
                class="hover-btn"
                @click="showOrderModal"
            >
              Найти девушку
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OrderModal
      v-if="isShowOrderModal"
      @hide-modal="isShowOrderModal = false"
  ></OrderModal>
</template>

<style scoped>
video {
  position: relative;
  width: 100%;
  max-height: 650px;
  border-radius: 28px !important;
  object-fit: cover;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;

  &::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
  }
}

.quiz-block {
  position: relative;
  border: 1px solid #8e7f7d;
  border-radius: 28px;
  height: 400px;
  overflow: hidden;
}

@media (max-width: 767px) {
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 26px;
  font-weight: normal;
}

.layer a,
.layer button {
  color: #ffffff;
  font-size: 16px;
  padding: 10px 26px;
  white-space: nowrap;
  border-radius: 30px;
  background: linear-gradient(93deg, #72666a 0%, #524b5f 50.09%, #201f36 99.15%);
  transition: 0.3s;
  text-decoration: none;
}
</style>
