<template>
    <div class="promo-intro__container">
        <div class="promo-intro__title__wrapper">
            <div class="d-flex flex-column align-items-center d-md-inline">
                <h1 class="promo-intro__title text-center text-md-start">Студия цифрового дизайна</h1>
                <span class="promo-intro__title text-center text-md-start mb-2">и разработки для &nbsp;</span>
                <div class="text-center d-md-inline">
                    <span><br class="d-none d-md-inline-block d-lg-none" /></span>
                    <span class="intro__animated-text first-animated">эскорт моделей</span>

                    <span class="intro__animated-text second-animated">эскорт агентств</span>
                    <span><br class="d-none d-lg-inline-block" /></span>
                    <span class="intro__animated-text third-animated">салонов эротического массажа</span>
                </div>
                <br />
            </div>
        </div>
        <div class="intro__about d-flex flex-column mx-auto align-items-center">
            <div class="text-center text-md-end ms-md-auto order-0 order-md-1">
                <p class="">
                    Создаём цифровые продукты будущего <br />
                    для миллионов людей
                </p>
            </div>
            <GradientButton
                @click="$emit('fill_questionnaire_clicked')"
                class="align-self-md-start order-1 order-md-0 ms-0"
                >Заполнить анкету</GradientButton
            >
        </div>
    </div>
</template>

<script>
import GradientButton from '@/components/promo/GradientButton.vue';

export default {
    name: 'promo-intro',
    components: { GradientButton },
};
</script>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-intro__container {
    min-height: 75vh;
    padding-top: 25%;

    .promo-intro__title__wrapper {
        margin-bottom: 17.5%;
        > div {
            .promo-intro__title {
                @include text-bold-small;
            }

            .intro__animated-text {
                @include text-bold-x-small;
                height: 46px;
                border-radius: 93px;
                display: inline-flex;
                align-items: center;
                background-color: rgb(195 199 216);
                animation-timing-function: linear;
                animation-fill-mode: both;
                animation-name: word-animation;
                animation-duration: 15s;
                animation-iteration-count: infinite;
                overflow: hidden;
                white-space: nowrap;
                max-width: fit-content;

                &.second-animated {
                    animation-delay: 5s;
                }

                &.third-animated {
                    animation-delay: 10s;
                }
            }
        }
    }

    .intro__about {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;

        > div:first-child {
            max-width: 700px;

            > p {
                padding-bottom: 10%;
            }
        }

        > div:last-child {
            width: 175px;
        }
    }
}

@include media-breakpoint-up(md) {
    .promo-intro__container {
        min-height: 75vh;
        padding-top: 15%;

        .promo-intro__title__wrapper {
            margin-bottom: 0;
            > div {
                .promo-intro__title {
                    font-size: 42px;
                    line-height: 62px;
                    font-weight: 700;
                }

                .intro__animated-text {
                    height: 62px;
                    @include text-bold-medium;
                }
            }
        }

        .intro__about {
            width: 100%;
            padding-top: 40px;
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;

            > div:last-child {
                margin-bottom: 15vh;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-intro__container {
        min-height: 100vh;

        .promo-intro__title__wrapper {
            > div {
                margin-bottom: 0;
                .promo-intro__title {
                    @include text-h2-large;
                }

                .intro__animated-text {
                    height: 72px;
                    @include text-bold-medium;
                }
            }
        }

        .intro__about {
            padding-top: 40px;

            > div:last-child {
                margin-bottom: 15vh;
            }
        }
    }
}

//анимации

@keyframes word-animation {
    0% {
        opacity: 0;
        width: 0px;
        padding-inline: 0px;
    }

    2% {
        opacity: 1;
        background-color: rgb(195, 199, 216);
    }

    10% {
        padding-inline: 26px;
        width: 100%;
    }

    25% {
        padding-inline: 26px;
        width: 100%;
        color: white;
    }

    32% {
        background-color: rgb(195, 199, 216, 0);
    }

    33% {
        width: 0px;
        padding-inline: 0px;
    }

    40% {
        color: rgba(0, 0, 0, 0);
    }

    100% {
        width: 0px;
        padding-inline: 0px;
    }
}
</style>
