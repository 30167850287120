import {createLogger, createStore} from "vuex";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default createStore({
    state:{
        user: {
            user_id: false,
            username: '', //--не залогинен
            auth_key: 'zj2nU8rcTqnrWob3evkLByeyIeU1l-8u',
            email:'',
            role:{
                item_name:'guest'
            },
            name:'',
            tg:'',
            savePassword: false,
            sogl: false,
            isLogged: false,
            profile:{
                name: 'Гость'
            },
            balance:{
                balance: '0.00'
            },
            city: false,
            city_id: false,
            district: false,
            metro: false,
        },
        userSettings: [],
        countries:[],
        metro:[],
        cities:[],
        compilationService: [],
        posts: [],

        /**
         * В переменную загружаются данные для страницы с подборками (CompilationRating.vue)
         */

        compilation: {
            title: "Подборка",
            data: []
        },
        isMobile: false,
        apiUrl: `${BASE_URL}/web/index.php?r=`,
        apiDomine: BASE_URL,
        flash_messsage: {
            title: '',
            massage: '',
            type: ''
        },
        messenger: {
            activeChat: {
                id: null,
                recipient: null,
                recipient_id: null,
                message_id: null
            }   
        }
    },
    mutations:{
        // настройка акк
        changeName(state, newName){
            state.user.profile.name = newName
        },
        changeEmail(state, newEmail){
            state.user.email = newEmail
        },
        changeTg(state, newTg){
            state.user.profile.tg = newTg
        },
        changeAvatar(state, newAvatar){
            state.user.profile.avatar = newAvatar
        },
        // настройка акк
        getPosts(state, posts){
            state.posts = posts
        },
        setUser(state, user){
            state.user = user
        },
        setUserData(state, user){

            if(user.user_id)     { state.user.user_id = user.user_id}
            if(user.username)    { state.user.username = user.username}
            if(user.auth_key)    { state.user.auth_key = user.auth_key}
            if(user.tg)          { state.user.tg = user.tg}
            if(user.email)       { state.user.email = user.email}
            if(user.role)        { state.user.role = user.role}
            if(user.profile)     { state.user.profile = user.profile}            
            if(user.city_id)     { state.user.city_id = user.city_id }
        },
        setUserSettings(state, info){
            state.userSettings = info
        },
        setUserBalance(state, balance){
            state.user.balance.balance = Number(balance).toFixed(2)
        },
        setLogged(state, logged){
            state.user.isLogged = logged
        },
        setCountries: (state, countries) => {

            state.countries = countries;
        },
        setMetro: (state, metro) => {

            state.metro = metro;
        },
        setCities: (state, cities) => {

            state.cities = cities;
        },
        setCity: (state, city) => {

            state.user.city = city;
        },
        setCityId: (state, cityId) => {

            state.user.city_id = cityId;
        },
        setDistrict: (state, district) => {

            state.district = district;
        },
        setCompilation(state, compilation){
            state.compilation = compilation
        },
        setIsMobile(state, isMobile){
            state.isMobile = isMobile
        },
        setCompilationService(state, compilationService){
            state.compilationService = compilationService
        },
        setFlashMessage(state, flashMessage) {
            state.flash_messsage = flashMessage
        },
        setActiveChat(state, activeChatItem) {
            state.messenger.activeChat.id = activeChatItem.id ? +activeChatItem.id : null
            state.messenger.activeChat.recipient = activeChatItem.name
            state.messenger.activeChat.recipient_id = +activeChatItem.recipient_id
            state.messenger.activeChat.message_id = activeChatItem.message_id ? +activeChatItem.message_id : null
        }
    },
    actions:{
        async getPosts({commit,state}, id){
            try {
                const res = await axios.get(`${state.apiUrl}api-profile-post/get-posts-by-profile-id&id=${id}&auth=${state.user.username}:${state.user.auth_key}&limit=500`)
                commit('getPosts', res.data.data)
            } catch (error) {
                console.log('Ошибка при получении постов');
            }
          },
          updateBalance({commit, state}){
            axios.get(`${state.apiUrl}api-billing/get-balance&auth=${state.user.username}:${state.user.auth_key}`).then(res => {
                commit('setUserBalance', +res.data.balance)
            })
          }
    },
    getters:{
        getUser:state => {
            return state.user
        },
        getUserSettings:state => {
            return state.userSettings
        },
        getCountries:state => {
            return state.countries
        },
        getMetro:state => {
            return state.metro
        },
        getCities:state => {
            return state.cities
        },
        getCity:state => {
            return state.user.city
        },
        getDistrict:state => {
            return state.district
        },
        getCompilation:state => {

            return state.compilation
        },
        getIsMobile:state => {

            return state.isMobile
        },
        getApiUrl:state => {

            return state.apiUrl
        },
        getApiDomine:state => {

            return state.apiDomine
        },
        getCompilationService:state => {

            return state.compilationService
        },
        getFlashMessage:state => {
            return state.flash_messsage
        },
        getActiveChat:state => {
            return state.messenger.activeChat
        }
    },
})