<template>

  <div class="collections">
      <div class="container-fluid">
        <h2 class="collections__title" :class="{'title-media': compilationView?.length == 0 && selection?.length == 0}" >{{
            compilationView?.length == 0 && selection?.length == 0 ?
                'На этой странице будут отображаться анкеты, которые вы смотрели и сохраняли' : 'Мои сохранёнки'
          }}</h2>
        <div class="row" :class="{'white-block': compilationView?.length == 0 && selection?.length == 0}">
          <div class="collections-my">
            <div class="block-offer">
              <ui-loader :show="showLoader" :preset="'block'" />
              <div class="slider-offer">
                <div class="row">
                  <div
                      class="col-12 mycoll col-xxl-3 col-lg-4 col-md-6 col-sm-6"
                      v-for="p in compilationView"
                      :key="p.id"
                  >
                    <Slider :profile="p" />
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="compilationView?.length > 0 && selection?.length > 0"/>
            <h2 class="collections-my__title" v-if="selection?.length > 0">Недавно смотрел</h2>
            <div class="block-offer">
              <ui-loader :show="showLoader" :preset="'block'" />
              <div class="slider-offer">
                <div class="row">
                  <div
                      class="col-12 col-xxl-3 col-lg-4 col-md-6 col-sm-6"
                      v-for="p in selection"
                      :key="p.id"
                  >
                    <Slider :profile="p" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>




<script>
import { useCookies } from 'vue3-cookies';
import axios from 'axios';
import Slider from '@/components/blocks/Slider.vue';
import UiLoader from '@/components/ui/UiLoader.vue';
export default {
  components: {
    Slider,
    UiLoader,
  },
  data() {
    return {
      apiUrl: this.$store.getters.getApiUrl,
      user: this.$store.getters.getUser,
      showLoader: true,
      compilationView: [],
      selection: [],
    };
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  methods: {
    async getSelectionView() {
      if (this.cookies.isKey('views')) {
        const views = JSON.parse(this.cookies.get('views'));
        const viewsSliced = views.slice(0, 24)
        console.log(viewsSliced);
        try {
          for (let id of viewsSliced) {
            const URL = `${this.apiUrl}api-girl/get-profile-by-id&auth=${
              this.user.username
            }:${this.user.auth_key}&id=${id}&city_id=${
              this.user.city?.id || 1
            }`;
            const { data } = await axios.get(URL);

            this.selection.push(data);
          }
        } catch (error) {
          console.error('getSelectionView: ', error);
        } finally {
          this.showLoader = false;
        }
      }
    },
    async getSelectionSave() {
      try {
        const res = await axios.get(
          this.apiUrl +
            'api-user/get-saved-profiles&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key
        );

        this.compilationView = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.showLoader = false;
      }
    },
  },
  mounted() {
    this.getSelectionView();
    this.getSelectionSave();
  },
};
</script>

<style lang="scss" scoped>
.white-block{
  height: 70vh;
}
.collections {
  background: linear-gradient(to right, #72666a, #201f36);
  padding: 0 42px;
  height: 100%;
  @media(max-width: 768px){
    padding: 0 12px;
  }
  &-my {
    background: white;
    border-radius: 12px;
    & .block-offer {
      padding: 0;
    }
    &__title {
      font-family:  'Montserrat';
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #525665;
      margin-top: 20px;
     
    }
  }
  &__title {
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 24px;
    line-height: 225%;
    color: #fff;
    &.title-media{
        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 100%;
        }
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 100%;
          padding-top: 20px;
        }
        @media (max-width: 576px) {
          font-size: 18px;
          line-height: 100%;
          padding-top: 20px;
        }
      }
  }
}
</style>
