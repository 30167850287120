<template>
    <input
        @input="checkUnic"
        type="tel"
        class="form-control"
        id="telephone"
        v-model="this.phone"
    />
    <div class="col-11 mt-1 error-text">{{ this.errorTel }}</div>
</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/intlTelInput.js";
import intlTelInput from "intl-tel-input";

export default {
    props: {
        phone: {
            type: [String, Boolean],
            default: ''
        }
    },
    data() {
        return {
            apiUrl: this.$store.getters.getApiUrl,
            errorTel: "",
            loadingTel: false,
            intlInput: false,
        };
    },
    methods: {
        checkUnic() {
            this.errorTel = "";
            if (this.phone.length < 10) {

                return;
            }
            if (!this.intlInput.isValidNumber()) {

                return;
            }

            this.phone = this.intlInput.getNumber();

            this.loadingTel = true;

            axios
                .get(
                    this.apiUrl + "api/check-phone&phone=" +
                    this.phone.slice(1) +
                    "&auth=" +
                    this.user.username +
                    ":" +
                    this.user.auth_key
                )
                .then((response) => {
                    if (response.status) {
                        this.loadingTel = false;
                        this.errorTel = "";
                    }
                    this.errorTel = response.data.error;
                });
        },
    },
    mounted() {
        let option = {
            preferredCountries: ["ru", "us", "ca"],
            autoInsertDialCode: true,
            autoPlaceholder: "aggressive",
            showSelectedDialCode: true,
            separateDialCode: true,
            options: {
                autoInsertDialCode: true,
                autoPlaceholder: "aggressive",
                showSelectedDialCode: true,
                separateDialCode: true,
            },
        };
        const input = document.querySelector("#telephone");
        this.intlInput = intlTelInput(input, option);
    },
};
</script>

<style></style>
