<script>
export default {
    name:'LogInIcon',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:18, h:17}
        }
    }
}
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="this.size.w" :height="this.size.h" :viewBox="'0 0 '+ (this.size.w - 4) + ' ' + (this.size.h - 3)" fill="none">
        <path opacity="0.5" d="M3.7825 1.56152C3.75 1.92277 3.75 2.37652 3.75 2.95027V9.54777C3.75 10.1215 3.75 10.5753 3.7825 10.9365H3.125C1.65187 10.9365 0.915 10.9365 0.4575 10.479C7.45058e-08 10.0209 0 9.28465 0 7.81152V4.68652C0 3.2134 7.45058e-08 2.47652 0.4575 2.01902C0.915 1.56152 1.65187 1.56152 3.125 1.56152H3.7825Z" :fill="this.color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19187 0.254977C3.75 0.646852 3.75 1.36373 3.75 2.79685V9.70185C3.75 11.135 3.75 11.8519 4.19187 12.2437C4.63375 12.6356 5.30937 12.5181 6.66062 12.2825L8.11688 12.0287C9.61313 11.7675 10.3612 11.6369 10.8056 11.0881C11.25 10.5387 11.25 9.74498 11.25 8.15685V4.34185C11.25 2.75435 11.25 1.9606 10.8063 1.41123C10.3613 0.862477 9.6125 0.731852 8.11625 0.471227L6.66125 0.216852C5.31 -0.0187727 4.63438 -0.136273 4.1925 0.255602M6.09438 5.59623C6.09438 5.32498 5.88438 5.1056 5.62563 5.1056C5.36688 5.1056 5.15688 5.32498 5.15688 5.5956V6.90435C5.15688 7.17498 5.36688 7.39435 5.62563 7.39435C5.88438 7.39435 6.09438 7.17498 6.09438 6.90435V5.59623Z" :fill="this.color"/>
        </svg>
</template>
