<script>
import axios from "axios";
import CardWhite from "@/components/ui/CardWhite.vue";
import data from "bootstrap/js/src/dom/data";

export default {
    name: 'Lk',
    components: {CardWhite},
    methods: {
        updateData() {
            let params = '&auth=' + this.user.username + ':' + this.user.auth_key + '&user_id=' + this.user.id

            axios
                .get(this.apiUrl + 'api-girl/get-profiles-list&status_id=6' + params)
                .then((response) => {

                    delete response.data.count
                    this.myRaiting = response.data
                })
            this.feedbackByProfile()
        },

        feedbackByProfile() {
            let params = '&auth=' + this.user.username + ':' + this.user.auth_key + '&user_id=' + this.user.id
                +'&date_add=' + this.newData

            axios
                .get(this.apiUrl + 'api-feedback/get-feedback-by-params' + params)
                .then((response) => {
                    delete response.data.count
                    this.feedbackByProfile = response.data
                })
            this.dateRaiting()
        },

        dateRaiting() {
            let now = new Date();
            this.newData = now.getFullYear()+"."+(((now.getMonth()+1) < 10)?"0":"") + (now.getMonth()+1) +"."+ ((now.getDate() < 10)?"0":"") + now.getDate() ;
            this.yesterdayData = now.getFullYear() +"."+(((now.getMonth()+1) < 10)?"0":"") + (now.getMonth()+1) +"."+ ((now.getDate() < 10)?"0":"") + (now.getDate()-1);
        },

        openBlank(id) {
            window.open('/anketa/' + id, '_blank');
        }

    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            myRaiting: [],
            newData: '',
            yesterdayData: '',
        }
    },
    mounted() {
        this.dateRaiting()
        this.updateData()
    }
}
</script>

<template>
    <div class="row mt-5">
        <div class="col-12 col-md-6 mt-3" v-for="p in this.myRaiting">
            <card-white>
                <div class="row">
                    <div class="col-4">
                        <img :src="this.apiDomine + `/web/uploads${p.photo[0].pic}`"
                             alt="girls"
                             class="img-girl">
                    </div>
                    <div class="col-4">
                        <div class="row my-anketa">
                            <div class="col-12 title-name d-flex justify-content-between align-items-center">
                                <span>{{ p.name }}</span>
                                <img src="@/assets/img/anketa/png/eye.png" alt="">
                            </div>
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div>Сегодня</div><div class="line"></div><div>{{p.stat.view}}</div>
                            </div>
                            <div class="col-12 d-flex justify-content-between align-items-center">
                                <div>Вчера</div><div class="line"></div><div>12</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row my-anketa">
                            <div class="col-12 title-name d-flex justify-content-end align-items-center">
                                <img src="@/assets/img/anketa/png/massage.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </card-white>
        </div>
    </div>

</template>

<style scoped lang="scss">
hr {
    color: #BDC1D1;
}

.title {
    color: #fff;
    font-size: 30px;
}

.title-name {
    font-size: 15px;
    color: #484C5E;
    font-weight: 600;
}

.img-girl {
    border-radius: 16px;
}

.line{
    flex-grow: 1;
    height: 1px;
    background-color: #dedede;
}

.my-anketa {
    color: #484C5E;

    &_text {
        display: flex;
        align-items: center;
        line-height: 16px
    }

    img {
        width: 15px;
    }
}

.btn-dark {
    background-color: #39354B;
    color: #fff;
    border-radius: 14px;
    padding: 5px 10px;
    font-size: 14px;
}

@media(max-width: 767px) {
    .title {
        color: #fff;
        font-size: 20px;
    }

}
</style>