<script>
import axios from "axios";
import OtzyvModal from "@/components/blocks/OtzyvModal.vue";
import LogInBlock from "@/components/ui/LogInBlock.vue";
import LogInCard from "@/components/blocks/LogInCard.vue";

export default {
    name: 'Feedback',
    components: {LogInCard, LogInBlock, OtzyvModal},
    props: {
        id: {
            type: Number,
            require: true
        }
    },
    methods: {
        updateData() {
            axios
                .get(this.apiUrl + 'api-feedback/get-feedback-by-profile-id&auth=' + this.user.username + ':' + this.user.auth_key
                    + '&limit=' + this.offset + '&profile_id=' + this.id)
                .then((response) => {
                    console.log(response);
                    this.feedback = response.data;
                    // console.log('this.feedback', this.feedback.count)
                    

                    if (this.feedback.rating) {
                        this.updateRating(this.feedback.rating)
                        this.resustRaiting()
                    }

                    this.error = this.feedback.error
                })
        },

        addFeedback() {
            if (!this.user.isLogged) {
                this.show = true
            } else {
                this.otzyvModal = true
            }

        },

        timeAdd(time) {
            let dateAdd = new Date(time * 1000)
            let hours = dateAdd.getHours() < 10 ? '0' + dateAdd.getHours() : dateAdd.getHours()
            let minutes = dateAdd.getMinutes() < 10 ? '0' + dateAdd.getMinutes() : dateAdd.getMinutes()
            let timeMessage = hours+':'+ minutes
            return timeMessage
        },

        dateAdd(time){
            let dateAdd = new Date(time * 1000);
            let date = dateAdd.getDate() < 10 ? "0" + dateAdd.getDate() : dateAdd.getDate();
            let rawMonth = dateAdd.getMonth() +1;
            let month = rawMonth  < 10 ? "0" + rawMonth : rawMonth;
            let year = dateAdd.getFullYear() < 10 ? "0" + dateAdd.getFullYear() : dateAdd.getFullYear();
            return date + '.' + month + '.' + year;
        },

        hideFeedback() {
            this.otzyvModal = false
        },
        updateRating(rating) {
            console.log('rating', rating)
            let remainder = rating % 1;
            for (let i = 1; i <= 5; i++) {
                let el = document.getElementById('feedbackStar' + i)
                el.style.fill = '#D9D9D9'
            }

            let lastStar = 0;
            for (let i = 1; i <= rating; i++) {
                let el = document.getElementById('feedbackStar' + i)
                el.style.fill = '#FFC804'

                lastStar = i;
            }

            if (remainder > 0) {
                let el = document.getElementById('feedbackStar' + (lastStar + 1))

                el.innerHTML = `<path d="M12.0489 2.92705C12.3483 2.00574 13.6517 2.00574 13.9511 2.92705L15.6942 8.29179C15.828 8.70382 16.212 8.98278 16.6452 8.98278H22.2861C23.2548 8.98278 23.6576 10.2224 22.8738 10.7918L18.3103 14.1074C17.9598 14.362 17.8132 14.8134 17.947 15.2254L19.6902 20.5902C19.9895 21.5115 18.935 22.2776 18.1513 21.7082L13.5878 18.3926C13.2373 18.138 12.7627 18.138 12.4122 18.3926L7.84869 21.7082C7.06498 22.2776 6.0105 21.5115 6.30985 20.5902L8.05296 15.2254C8.18683 14.8134 8.04018 14.362 7.68969 14.1074L3.12616 10.7918C2.34245 10.2224 2.74523 8.98278 3.71395 8.98278H9.35477C9.788 8.98278 10.172 8.70382 10.3058 8.2918L12.0489 2.92705Z" fill="#FFC804"/>
<path d="M13.9093 2.90911C13.9689 2.96884 14.0135 3.04192 14.0392 3.12229L15.6968 8.2883C15.8296 8.7021 16.2144 8.98278 16.649 8.98278H22.287C23.2557 8.98278 23.6585 10.2224 22.8748 10.7918L18.3113 14.1074C17.9608 14.362 17.8141 14.8134 17.948 15.2254L19.6911 20.5902C19.9905 21.5115 18.936 22.2776 18.1523 21.7082L13.4133 18.2651C13.1543 18.0769 13.001 17.7761 13.0011 17.4559L13.0017 14.5344L13.0003 12L13.0001 8.98278L13.0014 3.28459C13.0015 2.8108 13.5745 2.57381 13.9093 2.90911Z" fill="#D9D9D9"/>`;
                el.setAttribute("viewBox", "0 1 24 23")
            }

        },

        resustRaiting() {
            this.star = this.feedback.starsCount
            let maxStars = 0;

            for (let s of this.star) {
                if (s.count > maxStars) {
                    maxStars = s.count
                }
            }
            for (let s of this.star) {
                let svg = ''
                for (let i = 1; i <= 5; i++) {
                    let fill = '#D9D9D9';
                    if (i <= s.stars) {
                        fill = '#FFC804'
                    }
                    svg += `<svg xmlns="http://www.w3.org/2000/svg"
                         width="26" height="25" viewBox="0 0 16 15" style="fill:` + fill + `;">
                        <path
                            d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                    </svg>`;

                }
                this.ratingList.push({
                    svg: svg,
                    count: s.count,
                    width: (100 / maxStars) * s.count
                });
            }

        },
        hideDialog() {
            this.show = false;
            this.$emit('successLogin')
        }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            feedback: [],
            otzyvModal: false,
            offset: '10',
            ratingList: [],
            star: [],
            error: '',
            show: false,
        }
    },

    watch: {
        id: function () {
            this.updateData()
        },
    },
    mounted() {

    }
}
</script>

<template>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="anketa-recall d-flex align-items-center">
                <div class="anketa-recall__title anketa-title">Отзывы</div>
                <div class="anketa-raiting__btns">
                    <div v-if="user?.role?.item_name !== 'girl'" class="ms-5">
                        <button class=" hover-btn" @click="addFeedback">Добавить отзыв</button>
                    </div>
                    <otzyv-modal :user_id="+this.user.user_id" :profile_id="this.id" :show="this.otzyvModal"
                                 @hideDialog="hideFeedback"></otzyv-modal>
                </div>
            </div>
            <div class="col-12 col-md-11">
                <div v-if="feedback.feedback && feedback.feedback.length" class="card-feedback-user mt-3">
                    <div class="row" v-for="(item, i) in this.feedback.feedback" :key="i">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-9 feedback-name roboto-medium" v-if="item.description != null"
                                     v-html="item.user.username"></div>
                                <div class="col-3 text-end date-text" v-html="dateAdd(item.date_add)"></div>
                            </div>
                            <div class="col-12 feedback-text roboto-light" v-html="item.description"></div>
                            <div class="col-12 date-text" v-html="timeAdd(item.date_add)"></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0">
            <div class="anketa-raiting">
                <div class="anketa-raiting__row row align-items-start">
                    <div class="col-md-6 col-12">
                        <div class="d-flex align-items-center">
                            <div class="anketa-raiting__info me-3">
                                <div>Рейтинг <span>{{ this.feedback.rating }}</span></div>
                                <div v-if="this.error !== undefined">{{ this.error }}</div>
                                <div v-else>на сновании {{ this.feedback.count }} оценок</div>
                            </div>
                            <div class="anketa-raiting__star d-flex mb-1">
                                <svg class="" id="feedbackStar1" xmlns="http://www.w3.org/2000/svg"
                                     width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                                    <path
                                        d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                                </svg>
                                <svg class="" id="feedbackStar2" xmlns="http://www.w3.org/2000/svg"
                                     width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                                    <path
                                        d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                                </svg>
                                <svg class="" id="feedbackStar3" xmlns="http://www.w3.org/2000/svg"
                                     width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                                    <path
                                        d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                                </svg>
                                <svg class="" id="feedbackStar4" xmlns="http://www.w3.org/2000/svg"
                                     width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                                    <path
                                        d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                                </svg>
                                <svg class="" id="feedbackStar5" xmlns="http://www.w3.org/2000/svg"
                                     width="26" height="25" viewBox="0 0 16 15" style="fill:#D9D9D9;">
                                    <path
                                        d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="anketa-raiting-graf ">
                    <div class="anketa-raiting-graf__item" v-for="i in this.ratingList">
                        <div class="anketa-raiting-graf__star" v-html="i.svg">

                        </div>
                        <div class="anketa-raiting-graf__line">
                            <div :style="'width:'+ i.width + '%;'"></div>
                        </div>
                        <div class="anketa-raiting-graf__result">{{ i.count }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <log-in-card :show="this.show" @loginSuccess="hideDialog" @hideDialog="hideDialog"
                 :title="'Что бы писать сообщения, войдите или зарегистрируйтесь в сервисе'"/>

</template>

<style scoped lang="scss">
.anketa-raiting {

    &__row {
        display: flex;
    }

    &__info {
        position: relative;

        div {
            &:first-child {
                color: #2D2F3A;
                font-size: 24px;
                font-style: normal;

                span {
                    color: #2D2F3A;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 100%;
                }
            }

            &:last-child {
                color: #39354B;
                font-size: 16px;
                font-style: normal;
                position: absolute;
                white-space: nowrap;
            }
        }
    }

    &__star {
        margin-left: 5px;
        white-space: nowrap;

        img {
            width: 25px;
            height: 25px;
            margin-bottom: -7px;

            @media (max-width: 1400px) {
                width: 20px;
                height: 20px;
            }
            @media (max-width: 1200px) {
                width: 18px;
                height: 18px;

            }
        }
    }

    &__btns {
        display: flex;

        button {
            color: #FFF;
            text-align: center;
            font-size: 10px;
            border-radius: 10px;
            background: #39354B;
            transition: 0.3s;
            padding: 8px 12px;
            display: flex;
            align-items: center;

            img {
                padding-right: 3px;
            }

            &:hover {
                background-color: lighten(#39354B, 10%);
            }
        }
    }
}

.anketa-raiting-graf {
    margin: 40px 0 20px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__star {
        flex: 0 0 15%;
        max-width: 15%;
        white-space: nowrap;
        display: flex;
    }

    &__line {
        display: block;
        height: 4px;
        background-color: #BDC1D1;
        border-radius: 50px;
        flex: 0 0 70%;
        max-width: 70%;
        @media (max-width: 575px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        div {
            display: block;
            width: 80%;
            height: 100%;
            background-color: #484C5E;
            border-radius: 50px;
        }
    }

    &__result {
        flex: 0 0 5%;
        max-width: 5%;
        text-align: right;
    }
}

.anketa-recall {
    &__title {
        color: #2D2F3A;
        font-size: 24px;
        line-height: 100%;
    }

    &__item {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        color: #39354B;
        font-size: 19px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 14px;
        line-height: 120%;
        @media (max-width: 991px) {
            font-size: 12px;
        }
    }
}

.star {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(@/assets/img/star-gray.svg) no-repeat;
    background-size: cover;
}

.card-feedback-user {
    background: #EAECF3;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    border-top-left-radius: 0;
    padding: 10px 25px;
}

.feedback-name {
    color: #39354B;
    font-size: 20px;
}

.feedback-text {
    color: #39354B;
    font-size: 18px;
}

.date-text{
    font-size: 12px;
    color: #A1A8C1;
}

</style>