<template>
	<div class="container">
		<div class="quiz">
			<div class="quiz__block">
				<div class="row">
					<div class="col-12 quiz__title">Ваш заказ</div>
					<div class="col-12 col-lg-3 pe-4 quiz-info">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="quiz-info__block quiz-info__block--dark">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">Заказ</div>
										<div class="col-auto quiz-info__block--gray">7 просмотров</div>
									</div>
								</div>
							</div>
							<div class="col-12 mb-4">
								<div class="quiz-info__block quiz-info__block--ligth">
									<div class="d-flex justify-content-between align-items-center">
										<div class="col-auto">TS</div>
										<div class="col-auto quiz-info__block--gray">123</div>
									</div>
								</div>
							</div>
							<div class="col-12 mb-4 mb-lg-0">
								<div class="quiz-slider__girls quiz-slider">
									<!-- <swiper
										:slidesPerView="1"
										:navigation="swiperOptions.navigations"
										:modules="modules"
										:loop="true"
										:simulateTouch="true"
										:watchSlidesProgress="true"
										:mousewheel="false"
										:spaceBetween="1"
										:grabCursor="false"
										:touchRatio="1"
										:slideToClickedSlide="true"
										class="quiz-slider__block"
									>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<swiper-slide class="quiz-slider__slide"><Slider /></swiper-slide>
										<div class="quiz-slider__name">
											<span>Далее</span>
											<span>Анфиса</span>
										</div>
									</swiper> -->
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-9 quiz-content quiz-chat">
						<div class="quiz-chat__title mb-3">Чат</div>
						<ChatQuiz />
						<div class="quiz-order__btns row my-3">
							<div class="col-6 col-lg-5">
								<div class="quiz-big__btn">
									<button class="w-100">Обменяться контактами</button>
								</div>
							</div>
							<div class="col-6 col-lg-5">
								<div class="quiz-big__btn">
									<button class="w-100">Выбрать модель</button>
								</div>
							</div>
						</div>
					</div>
						
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref } from 'vue';
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { FreeMode, Navigation, Thumbs, Pagination, EffectFade, Mousewheel } from 'swiper/modules';

// import 'swiper/css/free-mode';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';

// import 'swiper/swiper-bundle.css';
// import '@/styles/swiper.css'
// import 'swiper/css/effect-fade';

// import Slider from '@/components/blocks/Slider.vue';

import ChatQuiz from '@/components/blocks/ChatQuiz.vue'

export default {
	components: {
		// Swiper,
		// SwiperSlide,
		// Slider,
		ChatQuiz
	},
	data() {
		return {
			swiperOptions: {
				navigations: true
			},

		}
	},
	methods: {
	},
	setup() {
		const thumbsSwiper = ref(null);
		const setThumbsSwiper = (swiper) => {
		thumbsSwiper.value = swiper;
	}
	
	return {
		thumbsSwiper,
		setThumbsSwiper,
		modules: [FreeMode, Navigation, Thumbs, Pagination, EffectFade, Mousewheel],
		};
	},
}
</script>

<style lang="scss">
.quiz-chat {
	&__title {
		color: #2D2F3A;
		font-size: 24px;
		font-weight: 700;
	}
}
</style>