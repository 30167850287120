import axios from "axios";
export default {
    components:{
        axios
    },
    methods:{
        m_updateView(profile_id = false, counter = false){
            if(!profile_id || !counter){
                return false
            }

            axios
                .get(
                    this.apiUrl + 'api-stat/update-count&auth='
                    + this.user.username + ':' + this.user.auth_key
                    + '&id=' + profile_id
                    + '&counter=' + counter)
                .then((response) =>{

                    return true
                }  )
        }
    },
    data(){
        return{
            user: this.$store.getters.getUser,
        }
    }
}