<script>
/**
 * Компонент отображает поле логина пользователя с подсказками при вводе.
 * Возвращает событие this.$emit('selected', u) где u - объект пользователя содержащий id и username
 *
 * пропс labelText - подпись перед полем
 */
import axios from "axios";

export default{
    name: 'UserSelect',
    props:{
        labelText:{
            type: String,
            required: false,
            default: 'Логин пользователя:'
        },
        placeholder:{
            type: String,
            required: false,
            default: 'Начните вводить...'
        },
        cssheight:{
            type: String,
            required: false,
        }
    },
    methods:{
        hintUsername() {
            if (this.username.length > 1) {
                let param = this.user.username + ':' + this.user.auth_key
                    + '&str=' + this.username
                axios
                    .get(this.apiUrl + 'api-hint/username&auth=' + param)
                    .then((response) => {
                        if (!response.data.status) {
                            this.error = response.data.error
                        }
                        this.userList = response.data;
                    })
            }
        },
        selectHint(u){
            this.$emit('selected', u)
            this.userList = []
            this.username = u.username
        }
    },
    data(){
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            username: '',
            userList: [],
            error:''
        }
    }
}
</script>

<template>
    <div class="ui-hint-user-select">
        <div class="row">
            <div class="col-auto d-flex justify-content-center align-items-center">
                <span>
                    {{ this.labelText }}
                </span>
            </div>
            <div>
                <input :class="cssheight" :placeholder="this.placeholder" class="form-control" type="text" @input="hintUsername" v-model="this.username">
            </div>
        </div>
        <div class="row">
            <div class="col-12 position-relative">
                <div class="hint-username me-3" v-if="userList.length > 0">
                    {{ this.error }}
                    <ul class="p-0">
                        <li class="cursor p-1 ps-3 pe-3" @click="selectHint(u)" v-for="(u, i) in this.userList" :key="i">
                            {{u.username}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    </div>
</template>

<style scoped>
.cssheight{
    height: 40px;
}
.hint-username{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    box-shadow: 1px 1px 3px #2d2f3a;
    background-color: #ffffff;
}
ul{
    border: 1px solid rgba(189, 193, 209, 0.31);
    border-radius: 0.5rem;
    border-radius: 0.5rem;
    min-width: 150px;
    margin-bottom: 0;
}
li{
    cursor: pointer;
}
li:hover{
    background-color: rgba(189, 193, 209, 0.31);
    font-weight: bold;
}

.form-control{
  width: 100%;
  border-radius: 12px;
}
</style>