<script>
import CloseX from '@/components/ui/CloseX.vue';
import CityIcon from '../icons/CityIcon.vue';

import axios from 'axios';
import { useCookies } from 'vue3-cookies';

const defaultCity = {
    id: 1,
    name: 'мАсква',
};

export default {
    name: 'UiGeo',
    components: { CloseX, CityIcon },
    props: {
        cities: {
            type: Array,
            default: () => {
                return [];
            },
        },
        iconColor: {
            type: String,
            default: '#fff',
        },
    },
    data() {
        return {
            apiUrl: this.$store.getters.getApiUrl,
        };
    },
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    async mounted() {        
        if (!this.user?.isLogged) {
            if (!this.getStoredCityId()) {
                this.setStoredCityId(defaultCity.id);
            }
        }
    },
    methods: {
        kostil() {
            document.querySelectorAll('.modal-backdrop').forEach((el) => {
                el.remove();
            });
        },

        hideMenu() {
            this.$emit('menu-hide-geo');
        },

        async updateUserCity(city) {
            if (this.user.isLogged) {
                const { data } = await axios
                    .post(
                        this.apiUrl + 'api-user/set-city',
                        {
                            user_id: this.user.user_id,
                            city_id: city.id,
                        },
                        {
                            params: {
                                auth: this.user.username + ':' + this.user.auth_key,
                            },
                        }
                    )
                    .catch((error) => {
                        console.error(error.message);
                    });

                if (data?.status) {
                    let expireTimes = 60 * 60 * 12;
                    if (this.user?.savePassword) {
                        expireTimes = '30d';
                    }
                    this.cookies.set('city_id', city.id, expireTimes);
                }
            }
            this.setSelectedCity(city);
            this.$store.commit('setCityId', city);
        },

        setSelectedCity(item) {
            if (!this.user.isLogged) {
                this.setStoredCityId(item.id);
            }
            this.$store.commit('setCity', item);
        },

        getStoredCityId() {
            return localStorage.getItem(`user_not_logged_city_id`);
        },

        setStoredCityId(cityId) {
            localStorage.setItem(`user_not_logged_city_id`, cityId);
        },
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },

        userCityId() {
            return this.$store.getters.getUser?.cityId || defaultCity.id;
        },

        shownCity() {
            return this.$store.getters.getUser?.city || defaultCity;
        },
    },
    watch: {
        cities: {
            deep: true,
            immediate: true,
            handler(newCities) {                
                let newCity = undefined;
                if (Array.isArray(newCities) && newCities.length > 0) {
                    newCity = newCities.find((city) => {
                        return city.id == (this.user?.city_id || this.getStoredCityId() || defaultCity.id);
                    });
                    if (newCity) {
                        this.setSelectedCity(newCity);
                    }
                }
            },
        },
    },
};
</script>

<template>
    <div class="ui-geo">
        <div
            class="ui-geo-btn d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#uiGeoModal"
            @click.stop="kostil"
        >
            <CityIcon
                :color="iconColor"
                :size="{ h: 16, w: 18 }"
            ></CityIcon>
            <span
                @click="hideMenu"
                class="ps-2"
                >{{ shownCity.name }}</span
            >
            <!--            <span @click="hideMenu" class="ps-2">msk</span>-->
        </div>
        <div
            class="modal fade"
            id="uiGeoModal"
            tabindex="-1"
            aria-labelledby="uiGeoModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <close-x
                    :preset="'bottom'"
                    data-bs-dismiss="modal"
                />
                <div class="modal-content">
                    <div class="modal-header justify-content-center">
                        <h1
                            class="modal-title fs-5 text-dark"
                            id="uiGeoModalLabel"
                        >
                            Выберите город
                        </h1>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 left-menu">
                                <div class="d-flex flex-column">
                                    <button
                                        class="cat-btn text-start p-1 ps-2 mb-1 hover-btn"
                                        v-for="(item, i) in cities"
                                        :key="i"
                                        v-html="item.name"
                                        @click.stop="updateUserCity(item)"
                                        aria-current="page"
                                        data-bs-dismiss="modal"
                                    ></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
button {
    -webkit-text-fill-color: #000;
    opacity: 1;
}
.ui-geo {
    z-index: 250;
}
</style>
