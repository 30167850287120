<script setup>
import CardWhite from "@/components/ui/CardWhite.vue";
import UiSelect from "@/components/ui/UiSelect.vue";
import { onBeforeMount, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";

const DAYS_OF_WEEK = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

const selectAnketa = ref(null)
const girlFormsId = ref([])
const profileWeekShchedule = ref([])
const selectedProfileID = ref(null)

const store = useStore()
const user = store.getters.getUser
const apiUrl = store.getters.getApiUrl

const getScheduleByProfileId = async (id) => {
    try {
        const params = '&auth=' + user.username + ':' + user.auth_key + '&profile_id=' + id
            + '&limit=10';

        const { data } = await axios.get(apiUrl + 'api-girl/get-schedule-by-profile-id' + params)

        if (data.status) {
            return data.data
        } else {
            return []
        }
    } catch (error) {
        console.log(error)
    }
}

const getScheduleById = async (id) => {
    try {
        const params = '&auth=' + user.username + ':' + user.auth_key + '&id=' + id

        const response = await axios.get(apiUrl + 'api-girl/get-schedule-by-id' + params)

        console.log(response)
    } catch (error) {
        console.log(error)
    }
}

const saveSchedule = async (obj) => {
    try {
        const params = '&auth=' + user.username + ':' + user.auth_key
        const method = obj.id ? 'api-girl/update-schedule-by-id' : 'api-girl/set-schedule'

        const schedule = {
            status: +obj.status,
            date: obj.date
        }

        if (obj.id) {
            schedule.id = obj.id
        } else {
            schedule.profile_id = obj.profile_id
        }

        const { data } = await axios.post(apiUrl + method + params, schedule)

        return data
    } catch (error) {
        console.log(error)

        return {status: false, error: 'Ошибка запроса'}
    }
}

function getDaysOfWeekStartingFromToday(today) {
    const result = [];

    for (let i = today; i < DAYS_OF_WEEK.length; i++) {
        result.push(DAYS_OF_WEEK[i]);
    }

    for (let i = 0; i < today; i++) {
        result.push(DAYS_OF_WEEK[i]);
    }

    return result;
}

const getWeekDates = () => {
    const today = new Date()
    const dayOfWeek = today.getDay()
    const startOfWeek = new Date(today)
    
    // startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek + 1)

    const weekDates = [];

    for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek)
        startOfWeek.setDate(startOfWeek.getDate() + 1)
        weekDates.push(date.toISOString().split('T')[0])
    }

    return { dayOfWeek, weekDates }
}

const formatDate = (dateString) => {
    const parts = dateString.split('-')

    return `${parts[2]}.${parts[1]}`
}

const selectChange = async (id) => {
    selectedProfileID.value = id

    const schedules = await getScheduleByProfileId(id)
    const { dayOfWeek, weekDates } = getWeekDates()
    const weekDays = getDaysOfWeekStartingFromToday(dayOfWeek)
    const result = []

    DAYS_OF_WEEK.forEach((day, index) => {
        const dayInSchedule = schedules.find(s => s.date === weekDates[index])

        const obj = {
            id: dayInSchedule?.id || '',
            profile_id: id,
            day: weekDays[index],
            date: weekDates[index],
            status: !!dayInSchedule?.status || false
        }

        result.push(obj)
    })

    profileWeekShchedule.value = result
}

const clickSaveHandler = async () => {
    const msg = {
        title: 'Расписание анкеты',
        massage: 'Расписание успешно сохранено',
        type: 'success'
    }

    const saves = await Promise.all(profileWeekShchedule.value.map(saveSchedule))

    if (saves.find(s => !s.status) !== undefined) {
        msg.massage = saves.find(s => !s.status)?.error,
        msg.type = 'danger'
    }

    store.commit('setFlashMessage', msg)

    selectedProfileID.value = null
    selectAnketa.value.selectName = 'Выбрать анкету'
}

const updateData = async () => {
    const params = '&auth=' + user.username + ':' + user.auth_key + '&user_id=' + user.user_id
        + '&limit=40';

    const response = await axios.get(apiUrl + 'api-girl/get-profiles-list' + params)

    if (response && response.data && response.data.length) {
        return response.data.map(a => {
            return { id: a.id, name: `#${a.id} ${a.name}` }
        })
    }

    return []
}

onBeforeMount(async () => {
    girlFormsId.value = await updateData()
})

watch(selectAnketa, (selectAnketa, prevAnketa) => {
    if (selectAnketa.selectName) {
        selectAnketa.selectName = 'Выбрать анкету'

        if (girlFormsId.value.length === 1) {
            selectAnketa.optionSelect(girlFormsId.value[1].id)
        }
    }
})
</script>
<template>
    <card-white v-if="girlFormsId.length" >
        <div class="d-flex flex-column h-100">
            <h4>Расписание работы</h4>
            <div class="row my-3">
                <div class="col-12 col-md-4 col-lg-3 col-xxl-2">
                    <ui-select ref="selectAnketa" :select="girlFormsId" @changeSelectVal="selectChange" />
                </div>
            </div>
            <h3 v-if="selectedProfileID">Расписание на неделю</h3>

            <div v-if="selectedProfileID" class="row mt-4 px-2 mb-3">

                <div v-for="day in profileWeekShchedule" :key="day.date"
                    class="col col-12 col-lg-1 px-0 border-end overflow-hidden schedule">
                    <div class="py-1 px-4 text-center schedule-head"><b>{{ day.day }}</b></div>
                    <div class="text-center schedule-body pt-4 pb-3">
                        <p class="schedule-date">{{ formatDate(day.date) }}</p>
                        <div class="form-check form-switch d-flex justify-content-center pb-3">
                            <input class="form-check-input" type="checkbox" role="switch"
                                :checked="day.status" v-model="day.status">
                        </div>
                        <p class="schedule-info">{{ day.status ? 'Работает' : 'Не работает' }}</p>
                    </div>
                </div>

                <p class="mt-3 ps-4 position-relative attention-icon">Регулируйте свой график для корректного
                    отображения режима работы, что бы пользователь мог видеть ваш актуальный график на неделю.</p>
            </div>

            <div v-if="selectedProfileID" class="row mt-auto justify-content-start justify-content-lg-end actions">
                <button class="button hover-btn" @click="clickSaveHandler">Сохранить</button>
            </div>
        </div>
    </card-white>
    <card-white v-else>
        <div class="row justify-content-center">
            <div class="col-12 pt-5 pb-3">
                <h2 class="text-center">У вас ещё пока нет ни одной анкеты. Есть повод добавить.</h2>
            </div>
            <div class="col-auto pb-5">
                <button class="btn-dark-title hover-btn" @click.stop="$router.push('/questionnaire')">Добавить анкету</button>
            </div>
        </div>
    </card-white>
</template>
<style lang="scss" scoped>
.card.card-white {
    min-height: 485px;
}
.attention-icon {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
}

.attention-icon:before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyMyAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguODUwNTYgNC45ODgwMUM5Ljk3ODU1IDIuODY0MzkgMTMuMDIxNSAyLjg2NDM5IDE0LjE0OTQgNC45ODgwMUwxOS4xMTgzIDE0LjM0MjdDMjAuMTc5NyAxNi4zNDA5IDE4LjczMTQgMTguNzUgMTYuNDY4OSAxOC43NUg2LjUzMTE0QzQuMjY4NjEgMTguNzUgMi44MjAzNSAxNi4zNDA5IDMuODgxNjkgMTQuMzQyN0w4Ljg1MDU2IDQuOTg4MDFaIiBmaWxsPSIjRkZDODA0Ii8+CjxwYXRoIGQ9Ik0xMC45NCAxMy45NFY4LjhIMTIuMDZWMTMuOTRIMTAuOTRaTTEwLjg5IDE2VjE0LjcySDEyLjExVjE2SDEwLjg5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
    position: absolute;
    top: -5px;
    left: 0;
}

.schedule.col {
    min-width: 120px;
}

.schedule.col:first-child {
    border-top-left-radius: 10px;

    @media screen and (max-width: 991px) {
        border-top-right-radius: 10px;
    }
}

.schedule.col:last-of-type {
    border-top-right-radius: 10px;

    @media screen and (max-width: 991px) {
        border-top-right-radius: 0;
    }
}

.form-switch .form-check-input {
    width: 42px;
    height: 21px;
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF8zMDQxXzM4NzcpIj4KPGNpcmNsZSBjeD0iOS43NSIgY3k9IjEwLjUiIHI9IjguNzUiIGZpbGw9IiNEOUQ5RDkiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9kXzMwNDFfMzg3NyIgeD0iMCIgeT0iMC43NSIgd2lkdGg9IjE5LjUiIGhlaWdodD0iMTkuNSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0Lz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMC41Ii8+CjxmZUNvbXBvc2l0ZSBpbjI9ImhhcmRBbHBoYSIgb3BlcmF0b3I9Im91dCIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwLjQzODg4OSAwIDAgMCAwIDAuNDAzOTI5IDAgMCAwIDAgMC4zOTkyNjcgMCAwIDAgMSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93XzMwNDFfMzg3NyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd18zMDQxXzM4NzciIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==) !important;
}

.form-check-input:focus,
.form-check-input {
    background-color: #FF4032;
    border-color: #FF4032;
}

.form-check-input:checked:focus,
.form-check-input:checked {
    background-color: #22BC32;
    border-color: #22BC32;
}

.form-check-input:focus {
    outline: 0;
    box-shadow: none;
}

.actions .button {
    width: 130px;
    height: 30px;
    align-items: center;
    border-radius: 10px;
    background-color: #39354B;
    border: 1px solid #39354B;
    color: #fff;
    font-size: 12px;
    margin-right: 10px;
}

.schedule-head {
    line-height: 25px;
    background: #EEE8E6;
    font-weight: 800;
}
.schedule-body {
    background: #FBF8F7;
}
.schedule-date {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
}
.schedule-info {
    font-size: 12px;
    font-weight: 600;
}
.btn-dark-title {
    background-color: #39354B;
    color: #fff;
    border-radius: 14px;
    padding: 10px 15px;
}

@media(max-width: 767px) {

    .btn-dark-title {
        background-color: #39354B;
        color: #fff;
        border-radius: 14px;
        font-size: 14px;
    }
}
</style>