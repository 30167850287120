<script>
    import CopyText from "@/components/ui/CopyText.vue";

    export default {
        name: 'InputWithCopy',
        components: {CopyText},
        props:{
            text: {
                type: [String, Number],
                require: true
            },
            disabled:{
                type: Boolean,
                default: false
            },
            id: {
                type: String,
                default: ''
            },
        },
        data(){
            return {
                model: this.text
            }
        }
    }
</script>

<template>
<div class="input-with-copy">
    <div class="disabled" v-if="this.disabled"></div>
    <input @keyup="$emit('edit', this.model)" :id="this.id" type="text" class="form-control" :placeholder="this.text" v-model="this.model">
    <copy-text :copyText="this.text"/>
<!--    <i @click="this.copyText" ref="copyIcon" class="bi bi-copy"></i>-->
</div>
</template>

<style scoped>
.input-with-copy{
    position: relative;
}
.input-with-copy input{
    padding-right: 20px;
}
.input-with-copy i{
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 7px;
    z-index: 110;
}
.disabled{
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,.1);
}

</style>