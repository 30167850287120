<script setup>
import GradientPopper from '@/components/promo/GradientPopper.vue';
import GradientButton from '@/components/promo/GradientButton.vue';
import FormSubmitModal from '@/components/promo/FormSubmitModal.vue';

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

const store = useStore();
const isShowSubmitModal = ref(false);
const isShowError = ref(false);

const defaultFormData = {
    name: '',
    tg: '',
    link: '',
    description: '',
};
const tgUserName = ref('');

function clearFormData(defaultData) {
    return { ...defaultData };
}
const formData = ref(clearFormData(defaultFormData));

//computeds
const user = computed(() => store.getters.getUser);
const apiUrl = computed(() => store.getters.getApiUrl);

//methods
const senData = async () => {
    const { data, status } = await axios
        .post(
            apiUrl.value + 'api-tg/get-lead-brif',
            {
                ...formData.value,
            },
            {
                params: {
                    auth: user.value.username + ':' + user.value.auth_key,
                },
            }
        )
        .catch((error) => {
            console.error('Request error: ', error);
        });
    if (status) {
        return { data, status };
    } else return { data: undefined, status: false };
};

const onInputTgUsername = (e) => {
    const regExp = /^@[a-zA-Z0-9_]{0,33}$/;
    if (regExp.test(e.target.value)) {
        formData.value.tg = regExp.exec(e.target.value)[0];
    }
    tgUserName.value = formData.value.tg;
};

const onFormSubmit = async () => {
    const { data, status } = await senData();
    if (status) {
        formData.value = clearFormData(defaultFormData);
        isShowSubmitModal.value = true;
        isShowError.value = false;
    } else {
        isShowSubmitModal.value = true;
        isShowError.value = true;
    }
    tgUserName.value = '';
};
</script>

<template>
    <section class="promo-profile__container">
        <h2 class="promo-profile__title">Ваша анкета уникальна</h2>
        <div class="promo-profile__description">
            <p>
                Мы знаем, что каждая девушка хочет выглядеть не так, как все! Почему бы не сделать так, чтобы и дизайн
                анкеты выглядел по-особенному, как личный лук?!
            </p>
            <div>
                <GradientPopper>
                    <template v-slot:button>Что такое лук?</template>
                    <template v-slot:text>
                        Теперь Вы сами можете выбрать, какой наряд сегодня надеть! Расскажите нам, что Вы хотите
                        особенный дизайн для анкеты, мы или разработаем, или предложим Вам готовый лук!
                    </template>
                </GradientPopper>
            </div>
        </div>
        <div class="promo-profile__form__wrapper">
            <form
                class="promo-profile__form"
                @submit.prevent="onFormSubmit"
            >
                <div class="promo-profile__form__title text-center text-md-start">Бриф сам себя не заполнит</div>
                <div class="promo-profile__form__fields">
                    <div class="promo-profile__form__field-wrapper">
                        <input
                            v-model.trim="formData.name"
                            type="text"
                            class="form-control"
                            id="promo-profile__form__name"
                            placeholder="Меня зовут"
                            pattern="[A-Za-zА-Яа-яЁё\s]{2,}"
                            required
                        />
                    </div>
                    <div class="promo-profile__form__field-wrapper">
                        <input
                            v-model.trim="tgUserName"
                            type="text"
                            class="form-control"
                            id="promo-profile__form__telegram"
                            placeholder="Мой telegram"
                            pattern="^@?\w[A-Za-z\d_]{5,32}$"
                            required
                            @input="onInputTgUsername"
                        />
                    </div>
                    <div class="promo-profile__form__field-wrapper">
                        <input
                            v-model.trim="formData.link"
                            type="text"
                            class="form-control"
                            id="promo-profile__form__resume-link"
                            placeholder="Ссылка на вашу анкету"
                        />
                    </div>
                </div>
                <div class="promo-profile__form__text-area">
                    <textarea
                        v-model.trim="formData.description"
                        class="form-control"
                        id="promo-profile__form__description"
                        placeholder="Как бы вы хотели видеть вашу анкету?"
                        required
                    ></textarea>
                </div>
                <div class="promo-profile__form__submit-button">
                    <GradientButton> Хочу новый лук </GradientButton>
                </div>
            </form>
        </div>
    </section>
    <FormSubmitModal
        v-if="isShowSubmitModal"
        @hide="isShowSubmitModal = false"
    >
        <div
            v-if="!isShowError"
            class="promo-visit-card__modal-submit"
        >
            <h3>Запрос отправлен, мы его получили!</h3>
            <p>Свяжемся с Вами через telegram.</p>
        </div>
        <div
            v-else-if="isShowError"
            class="promo-visit-card__modal-submit"
        >
            <h3>Ошибка!</h3>
            <p>Произошла ошибка во время отправки формы.</p>
        </div>
    </FormSubmitModal>
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-profile__container {
    margin-bottom: 105px;
    .promo-profile__title {
        margin-bottom: 20px;
        @include text-bold-small;
    }

    .promo-profile__description {
        margin-bottom: 55px;
        @include text-regular-small;

        > p {
            margin-bottom: 20px;
        }
    }

    .promo-profile__form__wrapper {
        .promo-profile__form {
            padding: 38px 20px 30px 20px;
            background-color: $background-secondary-color;
            border-radius: 40px;

            .form-control {
                background-color: $background-secondary-color;
            }

            .form-control::placeholder {
                color: $font-placeholder-color;
            }

            .promo-profile__form__title {
                @include text-bold-small;
                margin-bottom: 28px;
            }

            .promo-profile__form__fields {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-bottom: 20px;

                .promo-profile__form__field-wrapper {
                    padding: 1px;
                    background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
                    border-radius: 50px;

                    input {
                        padding: 28px 45px;
                        border-radius: 50px;
                        border: none;
                        font-weight: 600;
                        font-size: 16px;
                        color: $font-placeholder-color;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            .promo-profile__form__text-area {
                padding: 1px;
                background: linear-gradient(270deg, #bf5152 0%, #372fd5 100%);
                border-radius: 38px;
                margin-bottom: 20px;

                textarea {
                    padding: 28px 45px;
                    border-radius: 38px;
                    border: none;
                    min-height: 180px;
                    font-weight: 600;
                    font-size: 16px;
                    color: $font-placeholder-color;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .promo-profile__container {
        margin-bottom: 135px;
        .promo-profile__title {
            @include text-h2-medium;
        }

        .promo-profile__description {
            margin-bottom: 70px;
            max-width: 1000px;

            > p {
                @include text-regular-medium;
            }
        }

        .promo-profile__form__wrapper {
            .promo-profile__form {
                padding: 50px 35px;
                border-radius: 50px;

                .promo-profile__form__title {
                    font-size: 32px;
                    line-height: 40px;
                    margin-bottom: 35px;
                }

                .promo-profile__form__fields {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-bottom: 30px;

                    .promo-profile__form__field-wrapper {
                        flex-grow: 1;

                        input {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                }

                .promo-profile__form__text-area {
                    margin-bottom: 35px;

                    textarea {
                        min-height: 200px;
                        font-size: 20px;
                        line-height: 26px;
                    }
                }

                .promo-profile__form__submit-button {
                    width: 195px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-profile__container {
        margin-bottom: 175px;
        .promo-profile__title {
            @include text-h2-large;
        }

        .promo-profile__description {
            margin-bottom: 70px;
            max-width: 1000px;

            > p {
                @include text-regular-large;
            }
        }

        .promo-profile__form__wrapper {
            .promo-profile__form {
                padding: 80px 70px;
                border-radius: 50px;

                .promo-profile__form__title {
                    @include text-bold-medium;
                    margin-bottom: 50px;
                }

                .promo-profile__form__fields {
                    display: flex;
                    flex-direction: row;
                    gap: 40px;
                    margin-bottom: 30px;

                    .promo-profile__form__field-wrapper {
                        flex-grow: 1;

                        input {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                }

                .promo-profile__form__text-area {
                    margin-bottom: 35px;

                    textarea {
                        min-height: 200px;
                        font-size: 20px;
                        line-height: 26px;
                    }
                }

                .promo-profile__form__submit-button {
                    width: 195px;
                }
            }
        }
    }
}

.promo-profile__modal-submit {
    > h3 {
        text-align: center;
        margin-bottom: 40px;
        @include text-bold-small;
    }

    > p {
        text-align: center;
        @include text-regular-medium;
    }
}
</style>
