<template>
	<div class="nav">
		<ul class="nav__list">
			<li
				v-for="(item, i) in itemNav"
				:key="i" 
				class="nav-item"
			>
				<div class="nav-item__block">
					<img :src="require(`@/assets/img/nav-list/svg/${item.img}`)" alt="svg">
					<p>{{ item.name }}</p>
				</div>
				<!-- <ul class="nav-item__subitem">
					<li>li</li>
				</ul> -->
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data () {
		return {
			itemNav: [
				{
					img: 'item1.svg',
					name: 'Модерация',
					subList: [
						{},
					]
				},
				{
					img: 'item2.svg',
					name: 'Статистика',
					subList: [
						{},
					]
				},
				{
					img: 'item3.svg',
					name: 'Message',
					subList: [
						{},
					]
				},
				{
					img: 'item4.svg',
					name: 'Анкеты на продвижении',
					subList: [
						{},
					]
				},
				{
					img: 'item5.svg',
					name: 'Рассылка',
					subList: [
						{},
					]
				},
				{
					img: 'item6.svg',
					name: 'Финансы',
					subList: [
						{},
					]
				},
			]
		}
	},
	methods: {

	}
}
</script>

<style lang="scss">
.nav {
	position: sticky;
	top: 25px;
}
.nav-item {
	&__block {
		display: flex;
		align-content: center;
		height: 40px;
		line-height: 40px;
		p {
			color: #fff;
			opacity: 0.6;
			font-size: 18px;
			padding: 0;
			margin: 0;
			white-space: nowrap;
		}
		img {
			margin-right: 10px;
			max-width: 25px;
			opacity: 0.6;
		}
	}
}
</style>