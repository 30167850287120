<script>
import axios from 'axios';
import { mapActions, mapState } from 'vuex';
import EmojiPicker from 'vue3-emoji-picker';
export default {
  props: ['girlAnketa'],
  components: {
    EmojiPicker,
  },
  data() {
    return {
      show: false,
      selectFile: null,
      imageUrl: null,
        apiUrl: this.$store.getters.getApiUrl,
        apiDomine: this.$store.getters.getApiDomine,
      user: this.$store.getters.getUser,
      girlId: this.$route.params.id,
      uploadedImageUrl: null,
      uploadedVideoUrl: null,
      videoFile: null,
      videoSelect: null,
      comment: '',
      emojiPopup: false,
    };
  },
  methods: {
    textArea() {
      const area = document.querySelector('.input-block__textarea');
      area.style.height = '60px';
      area.style.height = `${area.scrollHeight}px`;
    },
    toggleShow(event) {
      if (!this.show) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    qwe(){
      this.show = true
    },
    areaSmileClose(){
      this.emojiPopup = false;
    },
    handleFileChange(event) {
      this.selectFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.selectFile);
      if (this.videoSelect) {
        this.videoSelect = null;
      }
    },
    handleVideoUpload(event) {
      this.videoFile = event.target.files[0];
      this.videoSelect = URL.createObjectURL(this.videoFile);
      if (this.imageUrl) {
        this.imageUrl = null;
      }
    },
    async publishImage() {
      let formData = new FormData();
      formData.append('image', this.selectFile);
      let formDataVideo = new FormData();
      formDataVideo.append('video', this.videoFile);
      if (this.imageUrl) {
        this.uploadedVideoUrl = null;
        try {
          const response = await axios.post(
            this.uploadLink +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            formData
          );
          if (response.status == 200) {
            this.uploadedImageUrl = response.data.name;
          }
        } catch (error) {
          console.log('Не загрузилось фото', error);
        }
      }
      if (this.videoSelect) {
        this.uploadedImageUrl = null;
        try {
          const response = await axios.post(
            this.uploadLinkV +
              '&auth=' +
              this.user.username +
              ':' +
              this.user.auth_key,
            formDataVideo
          );
          this.uploadedVideoUrl = response.data.name;
        } catch (error) {
          console.log('Не загрузилось видео', error);
        }
      }
      this.publishText();
    },
    async publishText() {
      try {
        const resText = await axios.post(
          this.apiUrl +
            'api-profile-post/set-post' +
            '&auth=' +
            this.user.username +
            ':' +
            this.user.auth_key,
          {
            profile_id: this.girlId,
            text: this.comment,
            photo: this.uploadedImageUrl,
            video: this.uploadedVideoUrl,
          }
        );
        this.$store.dispatch('getPosts', this.girlId);
         const area = document.querySelector('.input-block__textarea');
         area.style.height = '60px';
        this.comment = '';
      } catch (error) {
        console.log(error, 'Ошибка в тексте');
      }
    },
    toggleBlock() {
      this.emojiPopup = !this.emojiPopup;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.emojiPopup = false;
      }
    },
    clickShow() {
      this.show = true;
    },
    onSelectEmoji(emoji) {
      this.comment = this.comment + emoji.i;
    },
  },
  computed: {
    uploadLink() {
      return this.apiUrl + 'api-profile-post/upload-photo';
    },
    uploadLinkV() {
      return this.apiUrl + 'api-profile-post/upload-video';
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<template>
  <div class="newpost" @click="toggleShow">
    <div class="newpost-top">
      <div class="newpost-top__left">
        <img
          :src="
            this.apiDomine + '/web/uploads/' + girlAnketa?.photo[0]?.original_filename
          "
          alt=""
        />
        <span>{{ this.girlAnketa?.name}}</span>
      </div>
      <div class="newpost-top__right">
        <label
          for="file-photo"
          class="newpost-top__upload"
          @click.once="clickShow"
          :class="{ show: show }"
        >
          <img
            :src="require('@/assets/img/add-photo.svg')"
            alt=""
            class="add-photo"
          />
        </label>
        <input
          id="file-photo"
          type="file"
          style="display: none"
          @change="handleFileChange"
          accept="image/png, image/jpeg, image/webp"
        />

        <div class="newpost-top__photo">
          <label
            for="file-video"
            class="newpost-top__upload"
            @click.once="clickShow"
            :class="{ show: show }"
          >
            <img :src="require('@/assets/img/add-video.svg')" alt="" />
          </label>
          <input
            id="file-video"
            type="file"
            style="display: none"
            @change="handleVideoUpload"
          />
        </div>
      </div>
    </div>
    <Transition>
      <div class="input-block__area" v-if="show">
        <div class="input-block__top" @click.stop>
          <img
          class="input-block__avatar"
          :src="
            this.apiDomine + '/web/uploads/' + this.user.profile.avatar
          "
          alt=""
        />
          <textarea
            class="input-block__textarea"
            placeholder="Что у Вас нового?"
            @input="textArea"
            v-model="comment"
            @keydown.enter="publishImage"
           @click="areaSmileClose"
          />
          
          <button class="input-block__smile">
          <div
            class="chat-quiz__smile-icon"
            @click="this.emojiPopup = !this.emojiPopup"
          >
            <img src="@/assets/img/smilepost.svg" alt="svg" />
          </div>
          <div class="chat-quiz__smile-emoji"  v-if="this.emojiPopup">
            <EmojiPicker :native="true" @select="onSelectEmoji" />
          </div>
        </button>
         
        </div>
        <div class="input-block__btns">
          <div class="input-block__addphoto">
            <label
              for="file-photo"
              class="newpost-top__upload"
            >
              <img
                :src="require('@/assets/img/add-photo.svg')"
                alt=""
                class="add-photo"
                style="cursor: pointer"
                @click.once="qwe"
              />
            </label>
            <input
              id="file-photo"
              type="file"
              style="display: none"
              @change="handleFileChange"
              accept="image/png, image/jpeg, image/webp"
            />
          </div>
          <div class="input-block__video" >
            <label
              for="file-video"
              class="input-block__upload"
              style="cursor: pointer"
            >
              <img :src="require('@/assets/img/add-video.svg')" alt=""   @click.once="qwe"/>
            </label>
            <input
              id="file-video"
              type="file"
              style="display: none"
              @change="handleVideoUpload"
            />
          </div>
          <button class="input-block__save" @click="publishImage">
            Опубликовать
          </button>
        </div>
      </div>
    </Transition>
    
  </div>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.newpost {
  border: 1px solid rgba(189, 193, 209, 0.31);
  border-radius: 16px;
  background: #f5f5f5;
  padding: 15px 23px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
      display: flex;
      align-items: center;
      gap: 17px;
      & img {
        width: 44px;
        height: 44px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
.input {
  &-block {
    display: flex;
    align-items: center;
    gap: 17px;
    @media (max-width: 991px) {
      gap: 10px;
    }
    &__btns{
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &__top{
      display: flex;
      align-items: center;
      gap: 22px;
      margin-bottom: 30px;
      @media (max-width: 991px) {
          gap: 10px;
        }
    }
    &__save {
      max-width: 137px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
      font-size: 14px;
      border-radius: 8px;
      background: #39354b;
      color: white;
      font-weight: 500;
    }
    &__img {
      max-width: 300px;
      width: 100%;
      height: 400px;
      @media (max-width: 991px) {
        max-width: none;
        object-fit: cover;
      }
      background: linear-gradient(
        93deg,
        #72666a 0%,
        #524b5f 49.58%,
        #201f36 99.15%
      );
      border-radius: 10px;
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__photo {
      width: 100%;
      height: 100%;
    }
  
    &__avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
    }
    &__area {
      
      & textarea {
        flex: 1 1 0;
        border: 1px solid rgba(189, 193, 209, 0.31);
        border-radius: 16px;
        padding: 17px 26px;
        resize: none;
        height: 60px;
        overflow-y: hidden;
        vertical-align: middle;
       
        @media (max-width: 991px) {
          padding: 18px 10px;
        }
      }
    }
  }
}

.post-emoji {
  right: 0;
  left: auto;
  bottom: 45px;
  @media (max-width: 400px) {
    right: -40%;
  }
}

.newpost-top__upload.show {
  display: none;
}
.input-block__top .input-block__smile{
  position: relative;
  padding: 0 !important;
}
.input-block__top .chat-quiz__smile-emoji{
  right: 0;
  left: auto;
}
.chat-quiz__smile-icon{
  width: 24px;
  & img{
    width: 100%;
  }
}
</style>
