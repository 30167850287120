1<!--
   * пример применения preset.
   * Родительский блок в position-relative
   * пример применения input
     <div class="col-11 mt-2 position-relative">
        Телефон
        <input @input="checkUnic" type="tel" class="form-control" id="telephone"
            v-model="this.prof.tel" :disabled="loadingTel">
            <ui-loader :show="loadingTel" :preset="'input'" />
     </div>
    * пример применения в кнопке
     <button :disabled="this.loaderShow" type="submit" class="col-2 position-relative" v-if="formBtn" @click="validateForm">
     Создать
          <ui-loader :show="this.loaderShow" :preset="'btn'"/>
     </button>
    * block в центре используемого блока
    * page перекрывает всю страницу
    * btn в кнопке
    * input в инпуте

-->
<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: 'UiLoader',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        /**
         * preset String. Тип блока в котором применяется Loader.
         */
        preset: {
            type: String,
            require: true
        }
    },
    mounted() {
        /**
         * проверяем полученный preset
         */
        if (this.preset === 'btn') {
            this.selectedPreset = this.btn;
        }
        if (this.preset === 'input') {
            this.selectedPreset = this.input;
        }
        if (this.preset === 'page') {
            this.selectedPreset = this.page;
        }
        if (this.preset === 'block') {
            this.selectedPreset = this.block;
        }

        if (this.preset === 'fullBlock') {
            this.selectedPreset = this.fullBlock;
        }
        if (this.preset === 'fullPage') {
            this.selectedPreset = this.fullPage;
        }
        if (this.preset === 'intro') {
            this.selectedPreset = this.intro;
        }

        this.startTimer()
    },
    methods: {
        startTimer() {
            setTimeout(() => {
                this.loaderText = 'Получаем данные...'
            }, 2000);
            setTimeout(() => {
                this.loaderText = 'Данные загружаются дольше обычного, пожалуйста подождите...'
            }, 5000);
            setTimeout(() => {
                this.loaderText = 'Вероятно что-то пошло не так. <br>Попробуйте обновить страницу или свяжитесь с технической поддержкой. <br>Приносим извинения за доставленные неудобства.'
            }, 30000);
        }
    },
    data() {
        return {
            selectedPreset: '',

            btn: {
                bgClass: 'btn-bg-class'
            },
            input: {
                bgClass: 'input-bg-class'
            },
            page: {
                bgClass: 'page-bg-class'
            },
            block: {
                bgClass: 'block-bg-class'
            },
            fullBlock: {
                bgClass: 'full-block-bg-class'
            },
            fullPage: {
                bgClass: 'fullPage-block-bg-class'
            },
            intro: {
                bgClass: 'intro-block-bg-class'
            },
            loaderText: '',
        }
    }

})
</script>

<template>
    <div :class="this.selectedPreset.bgClass" v-if="show">
        <div class="row flex-column align-items-center">
            <div class="col-auto">
                <img src="@/assets/img/loader.gif" alt="" class="loader-img">
            </div>
            <div class="col-auto text-center loader-caption" v-html="this.loaderText">
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.loader-img {
    max-height: 80%;
}

.btn-bg-class {
    display: flex;
    justify-content: right;
    align-items: center;
    position: absolute;
    right: 5px;
    top: -3px;
    width: 100%;
    height: 100%;
}
.btn-bg-class .loader-caption{
    display: none;
}

.input-bg-class {
    position: absolute;
    right: 5px;
    top: 16px;
    max-width: 45px;
}
.input-bg-class .loader-caption{
    display: none;
}
.page-bg-class {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.block-bg-class {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.full-block-bg-class {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;

}
.fullPage-block-bg-class {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.849);
}
.intro-block-bg-class{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: grey;
    width: 100%;
    height: 100%;
}
</style>