<script>
import GirlListCategory from "@/components/ui/GirlListCategory.vue";
import axios from "axios";
import Feedback from "@/components/blocks/Feedback.vue";

export default {
    components: {Feedback, GirlListCategory},
    methods:{

    },
    data() {
        return {
            title: 'Содержанки',

        }
    },

}
</script>
<template>
    <main>
        <div class="main-block">
            <div class="container">
                <div class="main-block__img">
                    <img src="@/assets/img/main/girls.webp" alt="girls">
                </div>
            </div>
        </div>
<!--        <div class="">
            <div class="col-6 p-4">
                <feedback :id="'1'"></feedback>
            </div>
        </div>-->


<!--        <girl-list-category></girl-list-category>-->
    </main>

</template>

