<script>
export default {
    name: 'heart-icon-solid',
    props: {
        color: {
            type: String,
            default: '#000',
        },
        size: {
            type: Object,
            default() {
                return { w: 24, h: 24 };
            },
        },
    },
};
</script>

<template>
    <svg
        :width="size.w"
        :height="size.h"
        :viewBox="`0 0 ${size.w} ${size.h}`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.88502 16.9979C6.63855 15.267 4 13.2341 4 9.71007C4 5.81972 8.40013 3.06076 12 6.8009L13.6 8.39981C13.8344 8.63408 14.2143 8.63402 14.4485 8.39966C14.6828 8.1653 14.6827 7.7854 14.4484 7.55113L12.9028 6.00609C16.2948 3.52286 20 6.14011 20 9.71007C20 13.2341 17.3615 15.267 15.115 16.9979C14.8815 17.1778 14.6522 17.3544 14.4306 17.5291C13.6 18.1839 12.8 18.8004 12 18.8004C11.2 18.8004 10.4 18.1839 9.56939 17.5291C9.34777 17.3544 9.11851 17.1778 8.88502 16.9979Z"
            :fill="color"
        />
    </svg>
</template>
