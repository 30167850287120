<script>
import UiSwitch from '@/components/ui/UiSwitch.vue';
import LogInBlock from '@/components/ui/LogInBlock.vue'
import LogInCard from "@/components/blocks/LogInCard.vue";
import UiSearch from "@/components/ui/UiSearch.vue";
import UiSearchCity from "@/components/ui/UiSearchCity.vue";
import UiSearchMetro from "@/components/ui/UiSearchDistrict.vue";
import SettingIcon from "@/components/icons/SettingIcon.vue";
import LogInIcon from "@/components/icons/LogInIcon.vue";
import MetroIcon from "@/components/icons/MetroIcon.vue";
import UiGeo from "@/components/ui/UiGeo.vue";
import CristallIcon from "@/components/icons/CristallIcon.vue";
import DisplayCopyright from "@/components/ui/DisplayCopyright.vue";
import OrderModal from '@/components/order_modal/OrderModal.vue';

export default {
    name: 'Footer',
    components: {
        DisplayCopyright,
        UiGeo,
        MetroIcon,
        SettingIcon,
        LogInIcon,
        UiSearchMetro, UiSearchCity,
        UiSearch,
        LogInCard,
        UiSwitch,
        LogInBlock,
        CristallIcon,
        OrderModal
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            show: false,
            searchPagesShow: false,
            searchDistrictShow: false,
            isShowOrderModal: false
        }
    },
    computed: {
        isMobile() {
            return this.$store.getters['getIsMobile']
        }
    },
    methods: {
        hideDialog() {
            this.show = false;
            this.$emit('successLogin')
        },

    addProfile() {
      console.log('this.user.role.item_name', this.user.role.item_name)
      if (this.user.role.item_name === 'girl') {
        window.location.href = '/questionnaire'
      }
      if (this.user.role.item_name === 'admin') {
        window.location.href = '/admin/profiles'
      }
      if (this.user.role.item_name === 'guest' || this.user.role.item_name === 'user') {
        this.show = true
      }
    },
  }
}
</script>
<template>
  <footer class="footer" v-if="!isMobile">
    <div class="container p-4">
      <div class="row d-none d-md-flex justify-content-between pb-md-5 pt-md-3">
        <div class="col-2 d-flex align-items-center me-auto">
          <router-link to="/"><img src="@/assets/img/logo.svg" class="logo"></router-link>
        </div>

        <div v-if="user.role.item_name !== 'girl'" class="col-auto text-nav cursor" @click="searchPagesShow = true">
          <span><setting-icon :color="'#fff'" class="me-1"/> Предпочтения</span></div>
        <div v-if="user.role.item_name !== 'girl'" class="col-auto text-nav cursor">
                    <span
                        class="align-items-start"
                        @click.stop="isShowOrderModal=true">
                        <CristallIcon class="me-1" color="white" :size="{w: 21, h: 18}"/>
                        Создать заказ
                    </span>
        </div>

        <div class="col-auto text-nav cursor">
                    <span>
                        <ui-geo :cities="this.$store.getters.getCities"></ui-geo>
                    </span>
        </div>

        <div v-if="user.role.item_name === 'girl'" class="col-auto d-flex align-items-center">
          <button class="btn btn-nav hover-btn" @click="addProfile">Добавить анкету</button>
        </div>
        <div class="col-auto fw-bold d-flex align-items-center cursor"
             v-if="!this.user.isLogged"
             @click="this.show = true">
          <log-in-icon :color="'#fff'"/>
          Вход
        </div>
        <div v-if="this.user.isLogged" class="col-auto fw-bold d-flex align-items-center cursor">
          <router-link to="/girl/lk">
            {{ this.user.username }}
          </router-link>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-12 col-md-6 d-flex">
          <div>
            <a href="https://t.me/EgozaComfortManager">По всем вопросам</a>
          </div>
          <div class="ms-md-3">
            <a href="https://t.me/EgozaComfortManager" class="d-flex align-items-center">
              <img src="@/assets/img/support.svg" class="me-2" alt="">
              Телеграм
            </a>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-end">
          <div>
            <a href="./policy.pdf" target="_blank" class="footer-text">Политика конфиденциальности</a>
          </div>
          <div class="ms-md-3 footer-text d-flex align-items-center">
            <display-copyright/>
          </div>
        </div>
      </div>
      <log-in-card :show="this.show" @loginSuccess="hideDialog" @hideDialog="hideDialog"/>
    </div>
  </footer>
  <footer class="footer" v-if="isMobile">
    <div class="container pb-5">
      <div class="row flex-column justify-content-center align-items-center">
        <div class="col-auto p-3 mt-5">
          <router-link to="/"><img src="@/assets/img/logo.svg" class="logo"></router-link>
        </div>

        <div v-if="user.role.item_name === 'girl'" class="col-auto d-flex align-items-center cursor mt-2"
             @click.stop="$router.push('/girl/lk')">
          <span>Анкеты</span></div>
        <div v-if="user.role.item_name !== 'girl'" class="col-auto d-flex align-items-center cursor mt-5"
             @click.stop="searchPagesShow = true">
                    <span>
                        <img src="@/assets/img/setting.png" alt="" style="width: 22px;">
                        Предпочтения
                    </span>
        </div>

        <div v-if="user.role.item_name !== 'user'" class="col-auto mt-3">
          <button class="btn btn-nav hover-btn" @click.stop="addProfile">
            Добавить анкету
          </button>
        </div>
        <div class="col-auto d-flex align-items-center cursor mt-3">
          <ui-geo :icon-color="'#d9d9d9'" :cities="this.$store.getters.getCities"
                  @click.stop="isShowMenu = false"></ui-geo>
        </div>
        <div v-if="user.role.item_name !== 'girl'" class="col-auto mt-3">
                    <span
                        class="align-items-start"
                        @click.stop="isShowOrderModal=true">
                        <CristallIcon class="me-1" color="white" :size="{w: 21, h: 18}"/>
                        Создать заказ
                    </span>
        </div>

        <div class="col-auto mt-3 fw-bold d-flex align-items-center cursor"
             v-if="!this.user.isLogged"
             @click="this.show = true">
          <img src="@/assets/img/signIn.png" class="me-2" alt="">Вход
        </div>
        <div v-if="this.user.isLogged" class="col-auto mt-3 fw-bold d-flex align-items-center cursor">
          {{ this.user.username }}
        </div>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="col-auto">
          <a href="https://t.me/EgozaComfortManager">По всем вопросам</a>
        </div>
        <div class="col-auto">
          <a href="https://t.me/EgozaComfortManager" class="d-flex align-items-center">
            <img src="@/assets/img/support.svg" class="me-2" alt="">
            Телеграм
          </a>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <a href="./policy.pdf" target="_blank" class="">Политика конфиденциальности</a>
        </div>
        <div class="col-12 text-center footer-text">
          © 2023 Все права защищены.
        </div>
      </div>
    </div>
  </footer>
  <ui-search
      :position="'bottom'"
      :show="searchPagesShow"
      @close="searchPagesShow = !searchPagesShow"
      @closeOutside="searchPagesShow = false"
  />
  <log-in-card :show="this.show" @loginSuccess="hideDialog" @hideDialog="hideDialog"/>
  <OrderModal
      v-if="isShowOrderModal"
      @hide-modal="isShowOrderModal = false"
  ></OrderModal>
</template>


<style scoped lang="scss">
@import '@/styles/index';

.footer {
  background: linear-gradient(to right, #72666A, #201F36);
  color: $white;
  margin-top: auto;
}

.text-nav {
  display: flex;
  align-items: center;
}

.text-nav span {
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
}

.text-nav img {
  margin-right: 10px;
}

.btn-nav {
  border-radius: 35px;
  background: #72666A;
  color: #fff;
  cursor: pointer;
}

.btn-nav:hover {
  border-radius: 35px;
  background: #72666A;
  color: #fff;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

a {
  color: #fff;
}

.footer-text {
  font-size: 11px;
}

.logo {
  width: 120px;
  cursor: pointer;
}
</style>
