import { createApp } from 'vue';
import App from '@/App';
// TODO ??? цель импорта?
import { globalCookiesConfig } from 'vue3-cookies';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as bootstrap from 'bootstrap';
import router from './router/router';
import store from '@/store';

//components
import components from '@/components/ui';
import VueMask from '@devindex/vue-mask';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

//TODO для чего?
function base64ToBytes(base64) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
}
const validUTF16StringDecoded = new TextDecoder().decode(base64ToBytes('8J+ko9C/0YDQuNCy0LXRgg=='));

const app = createApp(App);

//TODO нужен глобальный рефакторинг глобальной регистрации компонент
components.forEach((component) => {
    app.component(component.name, component);
});

app.component('VueDatePicker', VueDatePicker);

//TODO вынести директивы в отдельный модуль
app.directive('click-outside', {
    mounted(element, { value }) {
        element.clickOutside = function (event) {
            if (!(element === event.target || element.contains(event.target))) {
                value(event);
            }
        };
        document.body.addEventListener('click', element.clickOutside);
    },
    unmounted(element) {
        document.body.removeEventListener('click', element.clickOutside);
    },
})
    .directive('swipe', {
        mounted(element, { value }) {
            let startY;
            let deltaY;
            let classNonTransition = false;
            let elementHeight = element.getBoundingClientRect().height;
            let swipePoint;
            let swipeResult = '';
            let touchEnd = false;
            let timer;
            let touchTime = 0;
            element.swipeDown = function (event) {
                value(
                    event,
                    element,
                    elementHeight,
                    startY,
                    deltaY,
                    classNonTransition,
                    swipeResult,
                    touchEnd,
                    touchTime
                );
            };
            element.addEventListener('touchstart', function (event) {
                startY = event.touches[0].clientY;
                timer = setInterval(function () {
                    touchTime++;
                }, 2);
            });
            element.addEventListener('touchmove', function (event) {
                event.preventDefault();
                touchEnd = false;
                deltaY = event.touches[0].clientY - startY;
                classNonTransition = true;
                if (event.touches[0].clientY < swipePoint) {
                    swipeResult = 'Up';
                    swipePoint = event.touches[0].clientY;
                } else {
                    swipeResult = 'Down';
                    swipePoint = event.touches[0].clientY;
                }
                element.swipeDown(
                    event,
                    element,
                    elementHeight,
                    startY,
                    deltaY,
                    classNonTransition,
                    swipeResult,
                    touchEnd,
                    touchTime
                );
            });
            element.addEventListener('touchend', function (event) {
                clearInterval(timer);
                classNonTransition = false;
                touchEnd = true;
                element.swipeDown(
                    event,
                    element,
                    elementHeight,
                    startY,
                    deltaY,
                    classNonTransition,
                    swipeResult,
                    touchEnd,
                    touchTime
                );
                touchTime = 0;
                deltaY = 0;
            });
        },
    })

    .use(VueMask)
    .use(router)
    .use(store)
    .mount('#app');