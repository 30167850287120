<script setup>
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from "vuex"
import axios from 'axios'

const emits = defineEmits(['open'])

const store = useStore()
const user = store.getters.getUser
const apiUrl = store.getters.getApiUrl

const messageCount = ref(0)
const interval = ref(undefined)

const onMessageButtonClick = () => {
    emits('open')
}

const getMessageCount = async () => {
    try {
        const params = '&auth=' + user.username + ':' + user.auth_key + '&user_id=' + user.user_id
        const { data } = await axios.get(apiUrl + 'api-chat/get-count-new-message-by-user-id' + params)

        messageCount.value = data

    } catch (error) {
        console.log('getMessageCount: ', error)
    } 
}

const startTimer = () => {
    stopTimer();
    interval.value = window.setInterval(() => {
        getMessageCount()
    }, 5000)
}

const stopTimer = () => {
    if (interval) {
        window.clearInterval(interval.value)
    }
}

onBeforeMount(() => {
    getMessageCount()
})

onMounted(() => {
    startTimer()
})

onUnmounted(() => {
    stopTimer()
})

</script>

<template>
    <div class="messenger-button">
        <div v-if="messageCount" class="messenger-count d-flex justify-content-center align-items-center"><span>{{ messageCount }}</span>
        </div>
        <button @click="onMessageButtonClick">
            <img src="@/assets/img/messenger-button.svg" alt="messenger-button">
        </button>
    </div>
</template>

<style scoped lang="scss">
.messenger-button {
    position: fixed;
    z-index: 10;
    bottom: 50px;
    right: 50px;

    @media screen and (max-width: 767px) {
        bottom: 25px;
        right: 25px;
    }

    .messenger-count {
        width: 18px;
        height: 18px;
        background-color: #FF4032;
        color: #fff;
        border-radius: 50%;
        font-size: 12px;
        line-height: 14px;
        position: absolute;
        top: 0;
        right: 0;
    }

    button {
        width: 67px;
        height: 67px;
        border-radius: 50%;
        border: 1px solid #fff;
        background: linear-gradient(92.51deg, #72666A 0%, #524B5F 50.09%, #201F36 99.15%);
    }
}
</style>