<script>

import {useCookies} from "vue3-cookies";

export default {
    name: 'ExitButton',
    methods: {
        delUserInfo() {
            this.$store.state.user.isLogged = false
            this.cookies.remove('auth_key')
            this.cookies.remove('username')
            this.cookies.remove('user_id')
            this.cookies.remove('role')
            this.cookies.remove('profile')
            this.cookies.remove('city_id')
            this.cookies.set('isLogged', false)
            window.location.href = '/'
        }
    },
    setup() {
        const {cookies} = useCookies();
        return {cookies};
    },
}
</script>

<template>
    <div class="cursor" @click="delUserInfo">
        <slot>Выход</slot>
    </div>
</template>


<style scoped>
.cursor {
    cursor: pointer;
}
</style>