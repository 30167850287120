<template>
  <div class="container">
    <div class="quiz-man d-flex flex-column text-center justify-content-center">
      <h1>Страница находится в разработке</h1>
    </div>
  </div>
 
</template>
<script>
export default {

}
</script>

<style lang="scss">
  .quiz-man {
    height: 50vh;
  }
</style>