import {onMounted, ref} from "vue";

export function useUpdateTimer(props){
    const hour = ref('')
    const min = ref('')

    const numWords = (value, words) => {
        value = Math.abs(value) % 100
        const lastValue = value % 10

        if((value > 10) && (value < 20)) return words[2]
        if((lastValue > 1) && (lastValue < 5)) return words[1]
        if(lastValue === 1) return words[0]
        return words[2]
    }

    const timeKillOrder = (() => {
        const now = new Date();
        const remaindTime = new Date((props.orderData.date_add + 10800) * 1000)
        const stopTime = remaindTime - now

        let hours = Math.floor((stopTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((stopTime % (1000 * 60 * 60)) / (1000 * 60));

        if(stopTime < 0){
            hours = 0
            minutes = 0
        }

        return {hours, minutes}
    })

    const updateTimer = () => {
        const {hours, minutes} = timeKillOrder()

        let hoursText = `${hours} ${numWords(hours, [  'час', 'часа', 'часов'])}`
        let minuteText = `${minutes} ${numWords(minutes, ['минута', 'минуты', 'минут'])}`

        if(hours <= 0){
            hour.value = ''
            min.value = minuteText
        }else {
            hour.value = hoursText
            min.value = minuteText
        }
    }

    onMounted(() => {
        updateTimer()
    })

    return {hour, min}
}