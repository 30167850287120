<script setup>
import CardWhite from "@/components/ui/CardWhite.vue"
import { useRouter } from "vue-router"
import { onMounted, ref } from 'vue';
const router = useRouter()

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    url: {
        type: String,
        required: true
    }
});

const isModelOpen = ref(false)

const emits = defineEmits(['delete'])

const onDeleteHandler = ($event,id, status_id) => {
    isModelOpen.value = true
    if ($event.target.closest('.dell-span')) {
        isModelOpen.value = false
          emits('delete', id, status_id)
    }
}


</script>
<template>
    <card-white :add-class="'h-100'">
        <div class="anketa-wrapper d-flex flex-column h-100">
            <div class="row mb-2">
                <div class="col-5">
                    <img v-if="item.photo.length && item.photo[0].pic" :src="url + `/web/uploads${item.photo[0].pic}`"
                        alt="girls" class="img-girl">
                </div>
                <div class="col-7">
                    <div class="row my-anketa">
                        <div class="col-12 title-name d-flex justify-content-between align-items-center">
                            <span class="w-100">{{ item.name }}</span>
                            <img src="@/assets/img/star-gold.svg" alt="">
                        </div>

                        <div class="col-12 mt-3 mb-0 mb-lg-3 status-text order-0 order-lg-last" :class="{
                            'grey': item.status.name === 'Черновик',
                            'green': item.status.name === 'Активна',
                            'yellow': item.status.name === 'Проходит проверку'
                        }"> {{ item.status.name }}</div>

                        <div v-show="item.city.name" class="col-12 my-anketa_text mt-2"><img
                                src="@/assets/img/anketa/png/location.png" alt="">{{
                                    item.city.name }}</div>
                        <div v-show="item.tel" class="col-12 my-anketa_text mt-2"><img
                                src="@/assets/img/anketa/png/call.png" alt="">{{
                                    item.tel }}
                        </div>
                        <div v-if="item.email" class="col-12 my-anketa_text mt-2"><img
                                src="@/assets/img/anketa/png/mail.png" alt="">{{
                                    item.email }}</div>
                        <div class="d-none d-lg-block mt-2">
                            <div class="col-12 my-anketa_text mt-2"><img src="@/assets/img/anketa/png/eye.png" alt="">{{
                                (item.stat && item.stat.view) ? item.stat.view : '0' }}</div>
                            <div class="col-12 my-anketa_text mt-2"><img src="@/assets/img/anketa/png/heart.png"
                                    alt="heart">{{ (item.stat && item.stat.like) ? item.stat.like : '0' }}</div>
                            <div class="col-12 my-anketa_text mt-2"><img src="@/assets/img/anketa/png/massage.png"
                                    alt="heart">Нет новых сообщений</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex d-lg-none my-anketa my-3">
                <div class="col-auto me-2 my-anketa_text mt-2"><img src="@/assets/img/anketa/png/eye.png" alt="">{{
                    (item.stat &&
                        item.stat.view) ? item.stat.view : '0' }}</div>
                <div class="col-auto me-2 my-anketa_text mt-2"><img src="@/assets/img/anketa/png/heart.png"
                        alt="heart">{{
                            (item.stat
                                && item.stat.like) ? item.stat.like : '0' }}</div>
                <div class="col-auto my-anketa_text mt-2 msg-text"><img src="@/assets/img/anketa/png/massage.png"
                        alt="heart">Нет новых
                    сообщений</div>
            </div>
            <div class="row mt-auto justify-content-between">
                <div v-if="item.status.id !== 5" class="col-6 col-lg-auto"><button class="btn-dark hover-btn" @click="router.push({path: '/girl/promotion'})">Продвигать анкету</button></div>
                <div class="col-auto col-lg-2 p-0 ms-auto me-1 me-sm-2">
                    <router-link :to="`/anketa/${item.id}`" target="_blank">
                        <button class="btn-edit w-100 hover-btn">
                            <i class="bi bi-eye"></i>
                        </button>
                    </router-link>
                </div>
                <div class="col-auto col-lg-2 p-0 me-1 me-sm-2">
                    <router-link :to="`/questionnaire/${item.id}`">
                        <button class="btn-edit w-100 hover-btn"><i class="bi bi-pencil"></i></button>
                    </router-link>
                </div>
                <div class="col-auto col-lg-2 p-0 me-2"><button class="btn-edit w-100 hover-btn"
                        @click="onDeleteHandler($event,item.id, 5)"><i class="bi bi-trash3"></i></button>
                </div>
            </div>
        </div>
    </card-white>
    <div class="block" v-if="isModelOpen" @click="isModelOpen = false">
            <div class="block__text" @click.stop>
                Это действие нельзя отменить. Удалить анкету?
                <br/>
               <div class="del">
                <span class="dell-span" @click="onDeleteHandler($event,item.id, 5)">Да</span>
                <span class="nodell-span" @click="isModelOpen = false">Нет</span>
               </div>
            </div>
    </div>
</template>
<style lang="scss" scoped>
.del{
    margin-top: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
}
.dell-span{
    color: green;
    font-weight: 700;
    cursor: pointer;
}
.nodell-span{
    color: red;
    font-weight: 700;
    cursor: pointer;
}
.block{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    &__text{
       max-width: 350px;
       width: 100%;
       padding: 15px;
       border-radius: 10px;
       background: #fff;
       text-align: center;
       font-size: 20px;
    }
}
.card {
    border: 1px solid #D9D9D9;
}

.title-name {
    font-size: 20px;
    color: #484C5E;
    font-weight: 600;

    img {
        display: none;
    }
}

.status-text {
    font-size: 18px;
    font-weight: 800;
}

.yellow {
    color: #FFC804;
}

.green {
    color: #1AD42C !important;
}

.grey {
    color: #978B8A;
}

.img-girl {
    border-radius: 16px;
}

.my-anketa {
    color: #484C5E;

    &_text {
        display: flex;
        align-items: center;
        line-height: 16px;
    }

    &_text.msg-text {
        @media screen and (max-width: 576px) {
            font-size: 12px;
        }
    }

    img {
        margin-right: 10px;
    }
}

.btn-edit {
    background: #8E7F7D;
    color: #fff;
    border-radius: 14px;
    padding: 5px 10px;
    font-size: 12px;
}

.btn-dark {
    background-color: #39354B;
    color: #fff;
    border-radius: 14px;
    padding: 5px 10px;
    font-size: 12px;
}
</style>