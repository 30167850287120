<script>
import {defineComponent} from "vue";
import getApi from "@/mixins/getApi";
import CloseX from "@/components/ui/CloseX.vue";
import axios from "axios";

export default defineComponent({
    name: 'UiSearchCity',
    components: {CloseX},
    mixins: [getApi],
    props: {
        position: {
            type: String,
            default: 'top'
        }
    },
    methods: {
        btnClick(e, d) {
            let btns = document.querySelectorAll('.cat-btn')
            for (let b of btns) {
                b.classList.remove('active')
            }
            e.target.classList.add('active')
            this.selected.city_id = parseInt(e.target.dataset.city)
            this.city_id = this.selected.city_id
            this.district = d
            this.allDistrict = this.district
        },

        startData() {
            this.searchCityShow = !this.searchCityShow
            this.city = []
            for (let c of this.cities) {
                if (c.id === 1) {
                    this.district = c.district
                    this.allDistrict = this.district
                }
                if (c.district.length !== 0) {
                    this.city.push(c)
                }

            }
        },

        addInfoService(e, n) {
            this.selected.district_id = parseInt(e.target.dataset.district)
            this.myDistrict = n
            this.searchCityShow = false
        },


        compareObjects(o1, o2) {
            let k = '';
            for (k in o1) if (o1[k] !== o2[k]) return false;
            for (k in o2) if (o1[k] !== o2[k]) return false;
            return true;
        },

        searchFor() {
            let toSearch = this.$refs.searchDistrict.value;
            let results = [];
            console.log('toSearch', toSearch, 'this.allDistrict', this.allDistrict)

            for (let m of this.allDistrict) {

                if (m.name.toUpperCase().includes(toSearch.toUpperCase())) {

                    results.push(m)

                }

            }
            this.district = results;
        }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            addActive: '',
            city_id: 1,
            searchMyDistrict: '',
            cities: [],
            city: [],
            allDistrict: [],
            district: [],
            myDistrict: 'Город',
            searchCityShow: false,
            selected: {
                city_id: false,
                district_id: false
            }
        }
    },
    mounted() {
        this.m_getAllCity()
        this.allDistrict = this.district
    },
    computed: {
        isMobile() {
            return this.$store.getters['getIsMobile']
        }
    },

})
</script>

<template>
    <div class="col-12 col-md-auto text-nav cursor" @click.stop="startData">
        <span>
            <img src="@/assets/img/city-Icon.png" alt="">
            {{ this.myDistrict }}
        </span>
    </div>
    <div :class="['search-list p-3', {top: this.position === 'top'}, {bottom: this.position === 'bottom'}]"
         v-if="searchCityShow">
        <close-x v-if="this.position === 'bottom'" :preset="'top'" @click="this.searchCityShow = false"/>
        <div class="row h-100">
            <div class="col-5 left-menu ">
                <div class="d-flex flex-column">
                    <button
                        class="cat-btn text-start p-1 ps-2 mb-1 hover-btn"
                        v-for="(item, i) in city"
                        :key="i"
                        v-html="item.name"
                        @click="btnClick($event, item.district)"
                        aria-current="page"
                        href="#"
                        :id="'district'+ item.id"
                        :data-city="item.id"
                    ></button>
                </div>

            </div>
            <div class="col-12 col-md-7 h-100 ">
                <input
                    ref="searchDistrict"
                    type="text"
                    placeholder="Поиск"
                    class="mb-3"
                    @keyup="searchFor"
                >
                <div class="long-list">
                    <p
                        v-for="(item, i) in this.district"
                        :key="i"
                        v-html="item.name"
                        @click="addInfoService($event, item.name)"
                        :data-district="item.id"
                        class="text-grey cursor"
                    ></p>
                </div>

            </div>
        </div>
        <close-x v-if="this.position === 'top'" :preset="'bottom'" @click="this.searchCityShow = false"/>
    </div>
</template>

<style scoped>
.search-list {
    position: fixed;
    opacity: 10;
    height: 50vh;
    visibility: visible;
    right: calc(50% - 250px);
    width: 500px;
    max-width: 100%;
    z-index: 999;
    pointer-events: all;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px #000;

}

.top {
    top: 30px;
}

.bottom {
    bottom: 30px;
}

.long-list {
    height: calc(100% - 45px);
    overflow-y: auto;
}

.left-menu {
    border-right: 1px solid #BDC1D1;
}

.left-menu button {
    color: #525665;
    font-weight: 700;
}

.cursor {
    cursor: pointer;
}

.text-nav {
    display: flex;
    align-items: center;
}

.text-nav span {
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
}

.text-nav img {
    margin-right: 10px;
}

.text-grey {
    color: #525665;
}

.left-menu button.active, .left-menu button.active:hover {
    background: #F5F5F5 url("@/assets/img/row-right-search.svg") no-repeat right;
    border-radius: 14px;
    background-position-x: calc(100% - 10px);
}

.left-menu button:hover {
    background: #F5F5F5;
    border-radius: 14px;
}

.tab-pane img {
    max-width: 17px;
    margin-right: 15px;
}

input {
    border: none;
    background: rgba(218, 218, 218, 0.3);
    border-radius: 100px;
    height: 26px;
    line-height: 24px;
    padding: 15px 15px;
}

@media (max-width: 767px) {
    .search-list {
        position: fixed;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        width: 100%;
        background-color: #fff;
        box-shadow: 0 -5px 12px 3px rgba(0, 0, 0, 0.065);
        border-radius: 15px 15px 0 0;
        transition: 0.4s;
        z-index: 99999;
        padding-bottom: 18px;
        height: 60vh;
        padding-left: 10px;
        padding-right: 10px;
    }

    .text-nav {
        justify-content: center;
    }

    .text-nav span {
        border-bottom: 1px solid #BDC1D1;
        display: flex;
        align-items: center;
        line-height: 33px;
    }
}

</style>