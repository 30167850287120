<script setup>
defineProps({
    item: {
        type: Object,
        default: {}
    }
})
</script>

<template>
    <div class="chat-item py-2 px-3 mb-3"
        :class="[{ 'chat-item__out': item.status === 'out' }, { 'chat-item__in': item.status === 'in' }]">
        <div class="chat-item__top mx-0 mb-2 d-flex justify-content-between gap-2">
            <div class="chat-item__name">{{ item.name }}</div>
            <div class="chat-item__time">{{ item.time }}</div>
        </div>
        <div class="chat-item__text d-flex mx-0 mb-2 mb-md-0">
            <div v-html="item.text"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.chat-item {
    background-color: #2c2f3c;
    width: 50%;

    @media screen and (max-width: 767px) {
        width: 75%;
    }

    &__out {
        margin-right: auto;
        border-radius: 0px 12px 12px 12px;
    }

    &__in {
        margin-left: auto;
        border-radius: 12px 0px 12px 12px;
    }

    &__name {
        color: #6ccb5f;
        font-size: 16px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__time {
        color: #BDC1D1;
        font-size: 14px;

        @media screen and (max-width: 767px) {
            font-size: 10px;
        }
    }

    &__text {
        color: #fff;
        font-size: 14px;
        line-height: 125%;

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 767px) {
        &:last-of-type {
            margin-bottom: 65px !important;
        }
    }
}
</style>