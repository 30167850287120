<script setup>
import VideoIndividually from '@/components/ui/UiVideoIndividually.vue';
import axios from 'axios';
import { onMounted, watch, ref, computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    page: {
        type: String,
        required: false,
        default: 'main',
    },
});
const store = useStore();
const user = computed(() => store.getters.getUser);
const userCity = computed(() => store.getters.getCity);
const apiUrl = store.getters.getApiUrl;
const profile = ref(null);

const getProfile = async () => {
    const apiMethod =
        props.page && props.page === 'category'
            ? 'api-ad/get-ad-video-to-widget-category-page'
            : 'api-ad/get-ad-video-to-widget-title-page';
    const requestUrl = apiUrl + apiMethod;
    const { data, message } = await axios
        .get(requestUrl, {
            params: {
                auth: user.value.username + ':' + user.value.auth_key,
                city_id: user.value.city_id,
            },
        })
        .catch((error) => {
            console.error(error.message);
            return error;
        });

    if (data?.profile) {
        return data.profile;
    } else return undefined;
};

const openProfileHandler = (id) => {
    window.open(`/anketa/${id}`, '_blank');
};

onMounted(() => {
    setTimeout(async () => {
        profile.value = await getProfile();
    }, 3000);
});

watch(userCity, async (newCity, oldCity) => {
    if (newCity && oldCity && newCity !== oldCity.name) {
        profile.value = await getProfile();
    }
});
</script>

<template>
    <div
        v-if="profile?.id"
        class="video-widget__container"
    >
        <video-individually
            :videoCards="profile.video"
            :name="profile.name"
            :photos="profile.photo"
        />
        <div
            class="video-widget-link hover-btn"
            @click="openProfileHandler(profile.id)"
        >
            Открыть анкету
        </div>
    </div>
</template>

<style lang="scss" scoped>
.video-widget__container {
    position: fixed;
    bottom: 4%;
    left: 2%;
    z-index: 200;
    border-radius: 12px;
    width: 200px;
    height: 300px;
    background-color: #525665;
    box-shadow: 5px 5px 15px 5px #525665;

    @media screen and (max-width: 567px) {
        left: 5%;
        bottom: 3%;
        width: 150px;
        height: 250px;
    }

    .video-widget-link {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 14px;
        padding: 10px 26px;
        white-space: nowrap;
        border-radius: 30px;
        background: rgba(255, 255, 255, 0.2);
        transition: 0.3s;
        cursor: pointer;

        @media screen and (max-width: 567px) {
            font-size: 12px;
        }
    }
}
</style>
