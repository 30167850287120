<template>
	<div class="chat-quiz">
		<div class="chat-quiz__wrap d-flex flex-column">
			<div
				class="chat-quiz__field flex-grow-1 p-4 d-flex flex-column mb-4"
				ref="messagesScroll"
			>
				<div class="chat-quiz__messages-box" ref="messagesWrapper">
					<div
						v-for="(message, i) in this.userMessages"
						:key="i"
						:class="[
							'chat-message w-50 py-2 mb-3',
							{ 'chat-quiz-message__out': message.author_id == userId },
							{ 'chat-quiz-message__in': message.author_id != userId },
						]"
					>
						<div class="chat-quiz-message__top py-2 px-3 d-flex mx-0 mb-2 align-center ">
							<div class="chat-quiz-message__photo">
								<img src="@/assets/img/admin.png" alt="photo">
							</div>
							<div class="chat-quiz-message__text">
								<div class="col-auto" v-html="message.text"></div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<div class="chat-quiz__info d-flex align-items-center mt-auto justify-content-between"
			>
				<div class="chat-quiz__smile me-2 mb-2 ms-2">
					<div class="chat-quiz__smile-icon"
						@click="this.emojiPopup = !this.emojiPopup"
						v-click-outside="emojiPopupHide"
					>
						<img src="@/assets/img/smile.svg" alt="svg">
					</div>
					<div class="chat-quiz__smile-emoji" v-if="this.emojiPopup">
						<EmojiPicker :native="true" @select="onSelectEmoji" />
					</div>
				</div>
				<div class="chat-quiz__text w-100">
					<textarea v-model="currentMessage" class="w-100">
						<img src="@/assets/img/text-color.svg" alt="svg">
					</textarea>
				</div>
				<div class="chat__send">
					<button
						type="submit"
						@click="this.sendMessage(userId, recipientId, 1, this.chatId)"
					>
						<img src="@/assets/img/send.svg" alt="svg" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Tabs, Tab } from "vue3-tabs-component";
import EmojiPicker from "vue3-emoji-picker";
//import DiscordPicker from "vue3-discordpicker";
import "vue3-emoji-picker/css";
import axios from "axios";
import PhoneMask from '@/components/ui/UiPhoneMask.vue'


export default {
	components: {
		Tabs,
		Tab,
		//DiscordPicker,
		EmojiPicker,
		PhoneMask
	},
	data() {
		return {
            apiUrl: this.$store.getters.getApiUrl,
			emojiPopup: false,
			userId: 1,
			recipientId: 2,
			userMessages: [],
			chatId: 1,
			currentMessage: "",
			currentMessagesHeight: 0,
		};
	},
	methods: {
		sendMessage(author_id, recipient_id, type_id, chat_id = false) {
			// let mes = document.getElementById("currentMessage");
			// let text = mes.innerHTML;
			axios
				.post(this.apiUrl + "api-chat/set-message", {
					author_id: author_id,
					recipient_id: recipient_id,
					type_id: type_id,
					text: this.currentMessage,
					chat_id: chat_id,
				})
				.then((response) => {
					this.getMessage();
					this.currentMessage = ''
				});
		},
		getMessage() {
			axios
				.get(
                    this.apiUrl + "api-chat/get-message&chat_id=" +
						this.chatId
				)
				.then((response) => {
					let messages = response.data;
					messages.sort((a, b) => a.date_add > b.date_add ? 1 : -1);
					this.userMessages = messages;
					const messagesWrap = this.$refs.messagesWrapper;
					if (messagesWrap === null || messagesWrap === undefined) return;
					let heightMessagesWrap = this.$refs.messagesWrapper.offsetHeight;
					if (heightMessagesWrap !== this.currentMessagesHeight) {
						this.currentMessagesHeight = heightMessagesWrap;
						this.$refs.messagesScroll.scrollTo({
							top: heightMessagesWrap,
							left: 0,
						});
					}
				});
		},
		timeToDay(time) {
			let dateAdd = new Date(time * 1000);
			let hours = dateAdd.getHours() < 10 ? "0" + dateAdd.getHours() : dateAdd.getHours();
			let minutes =
				dateAdd.getMinutes() < 10 ? "0" + dateAdd.getMinutes() : dateAdd.getMinutes();
			let timeMessage = hours + ":" + minutes;
			return timeMessage;
		},
		stopTimer() {
			if (this.interval) {
				window.clearInterval(this.interval);
			}
		},
		emojiPopupHide() {
			this.emojiPopup = false
		},
		startTimer() {
			this.stopTimer();
			this.interval = window.setInterval(() => {
				this.getMessage();
			}, 1000);
		},
		onSelectEmoji(emoji) {
			this.currentMessage = this.currentMessage + emoji.i
		}
	},
	mounted() {
		this.getMessage();
		this.startTimer();
	},
	beforeDestroy() {
		this.stopTimer();
	},
};
</script>

<style lang="scss">
.chat-quiz {
	height: 590px;
	overflow: hidden;
	color: #fff;
	padding: 10px;
	
	&__smile {
		position: relative;
		.v3-emoji-picker {
			background-color: #484c5e!important;
		}
	}
	&__smile-emoji {
		position: absolute;
		left: 0;
		bottom: 100%;
	}
	&__smile-icon {
		cursor: pointer;
	}
	.tabs-component-tabs {
		display: flex !important;
		align-items: center !important;
	}
	.tabs-component-tab-a {
		color: #fff !important;
		font-size: 16px !important;
	}
	.tabs-component-tab {
		border-radius: 10px 10px 0px 0px;
		background: #484c5e;
		padding: 5px 15px;
		margin-right: 15px;
		&.is-active {
			background: #8e7f7d;
		}
	}
	&__text {
		// background-color: #2d2f3a;
		// border-radius: 8px;
		// resize: none;
		// border: none;
		// color: #fff;
		// font-size: 14px;
		// padding: 5px 10px;
		// height: 70px;
		textarea {
			overflow-y: auto;
			background-color: #2d2f3a;
			border-radius: 8px;
			resize: none;
			border: none;
			color: #fff;
			font-size: 14px;
			padding: 5px 10px;
			height: 70px;
		}
	}
	&__field {
		padding-bottom: 70%;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color:  #797979 #535353;
		&::-webkit-scrollbar {
			width: 10px;
		}
		&::-webkit-scrollbar-track {
			background-color: #797979;
			border-radius: 30px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #535353;
			border-radius: 20px;
			border: solid 1px #797979;
		}
	}
	&__messages-box {
	}
	&__wrap {
		background-color: #fff;
		position: relative;
		height: 535px;
		border-radius: 0 0 24px 24px;
	}
	&__info {
		background-color: #20222E;
		border-radius: 12px;
		padding: 15px 10px 10px;
		margin-top: 30px;
	}
	
}
.chat-quiz-message {
	background-color: #2c2f3c;
	&__photo {
		max-width: 50px;
		margin-right: 15px;
	}
	&__out {
		margin-right: auto;
		border-radius: 12px;
	}
	&__in {
		margin-left: auto;
		border-radius: 12px 0px 12px 12px;
	}
	&__name {
		color: #6ccb5f;
		font-size: 16px;
		font-weight: 600;
	}
	&__time {
		color: #bdc1d1;
		font-size: 14px;
	}
	&__text {
		color: #fff;
		font-size: 14px;
	}
}
</style>
