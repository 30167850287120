<script>
export default{
    name:'WorkTime',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:24, h:24}
        }
    }
}
</script>

<template>
<svg :width="this.size.w" :height="this.size.h" viewBox="0 0 21.5 21" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Vector" d="M12.75 2.25C16.52 2.25 18.4 2.25 19.57 3.42C20.75 4.59 20.75 6.47 20.75 10.25L20.75 12.25C20.75 16.02 20.75 17.9 19.57 19.07C18.4 20.25 16.52 20.25 12.75 20.25L8.75 20.25C4.97 20.25 3.09 20.25 1.92 19.07C0.75 17.9 0.75 16.02 0.75 12.25L0.75 10.25C0.75 6.47 0.75 4.59 1.92 3.42C3.09 2.25 4.97 2.25 8.75 2.25L12.75 2.25Z" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="1.500000"/>
	<path id="Vector" d="M5.75 2.25L5.75 0.75" :stroke="this.color" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linecap="round"/>
	<path id="Vector" d="M15.75 2.25L15.75 0.75" :stroke="this.color" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linecap="round"/>
	<circle id="Vector" cx="15.250000" cy="14.750000" r="1.500000" :stroke="this.color" stroke-opacity="1.000000" stroke-width="1.500000"/>
	<path id="Vector" d="M1.25 7.25L20.25 7.25" :stroke="this.color" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linecap="round"/>
</svg>
</template>

<style>

</style>