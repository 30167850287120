<script>
import axios from "axios";
import SliderInCategory from "@/components/blocks/SliderInCategory.vue";
import Slider from "@/components/blocks/Slider.vue";
import sklonyator from "sklonyator";

export default {
    name: 'AdBlock',
    computed: {
        sklonyator() {
            return sklonyator
        },
        userCity(){
            return this.$store.getters.getCity
        }
    },
    components: {Slider, SliderInCategory},
    props: {
        block_id: {
            type: Number,
            require: false
        },
        class: {
            type: String
        }
    },
    methods: {
        getAdBlock() {
            console.log('getAdBlock')

            let params = '&auth=' + this.user.username + ':' + this.user.auth_key

            if (this.user.city) {
                params += '&city_id=' + this.user.city.id
            } else {
                params += '&city_id=1'
            }

            this.profiles = []
            this.pages = []
            axios
                .get(this.apiUrl + 'api-ad/get-ad-title-page' + params)
                .then((response) => {
                    if (!response.data.length) return false

                    this.block = response.data;



                    const chunkSize = 4;
                    for (let i = 0; i < response.data.length; i += chunkSize) {
                        this.pages.push(response.data.slice(i, i + chunkSize));
                    }
                    
                    if (this.pages[0]) {

                        this.profiles = this.pages[0]

                        // for (const p of this.pages[0]) {
                        //     this.updateView(p.user_id)
                        // }
                    }

                    console.log('this.profiles', this.profiles)

                }).catch(error => console.log(error))

        },
        updateView(profile_id) {

            axios
                .get(this.apiUrl + 'api-ad/update-ad-view'
                    + '&auth=' + this.user.username + ':' + this.user.auth_key
                    + '&block_id=' + this.block_id
                    + '&profile_id=' + profile_id)
        },
        displayNext() {
            this.profiles = this.profiles.concat(this.pages[this.lastView + 1])

            for (const p of this.pages[this.lastView + 1]) {
                this.updateView(p.id)
            }


            this.lastView++;

            let next = this.lastView + 1;
            if (!this.pages[next]) {

                this.showBtn = false
            }
        },
    },
    mounted() {
        this.getAdBlock()
    },

    data() {

        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            block: {},
            profiles: [],
            pages: [],
            btnText: 'Показать ещё',
            showBtn: true,
            lastView: 0
        }
    },
    watch:{
        userCity: function () {
            this.showBtn = true
            this.pages = []
            this.lastView = 0
            this.getAdBlock()
        }
    }
}
</script>

<template>
    <div class="block-offer__top" v-if="this.profiles?.length !== 0">
        <div class="title block-offer__title">
            {{ block.title ? block.title : 'Девушки' }} <span v-if="this.user.city">в {{ sklonyator.incline(this.user.city.name) }},</span>
        </div>
        <div class="block-offer__strip">

        </div>
        <div class="block-offer__row">
            {{ block.sub_title ? block.sub_title : 'которым мы доверяем' }}
        </div>
    </div>

    <div class="row" v-if="this.profiles?.length !== 0">
        <div class="col-12 col-md-3" v-for="(p, i) in this.profiles" :key="i">
            <Slider :profile="p"/>
        </div>
    </div>
    <!-- <div class="row" v-else-if="this.user.role.item_name === 'girl'">
        <div class="col-12 col-md-12 text-center">
            Ваша анкета может быть в этом блоке. <br>Раздел "Продвижение" в личном кабинете
        </div>
    </div> -->
    <div class="row mt-3 justify-content-center" v-if="showBtn && this.profiles?.length > 3">
        <div class="col-auto">
            <button class="hover-btn" @click="this.displayNext">{{ this.btnText }}</button>
        </div>
    </div>

</template>

<style scoped>
button {
    background-color: #72666A;
    color: #FFFFFF;
    border-radius: 25px;
    padding: 8px 22px;
    font-size: 16px;
    font-weight: 500;
}

button:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}
</style>