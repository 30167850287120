<script>
export default{
    name: "mobileMenuBlock",
    props:{
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        swipingMenu(
            event,
            element,
            elementHeight,
            startY,
            deltaY,
            classNonTransition,
            swipeResult,
            touchEnd
        ) {
            if (classNonTransition) element.classList.add("non-transition");
            else element.classList.remove("non-transition");
            if (deltaY > 25 || deltaY < -25) element.style.bottom = `-${deltaY}px`;
            if (touchEnd && swipeResult === "Up" && (deltaY > 25 || deltaY < -25)) {
                this.$emit('open')
                element.style.bottom = "0";
            } else if (touchEnd && swipeResult === "Down" && (deltaY > 25 || deltaY < -25)) {
                this.$emit('close')
                element.style.bottom = "0";
            }
        },
    },
}
</script>

<template>
    <div class="mobile-menu-layer" v-if="show">
        <div class="menu-block" v-swipe="this.swipingMenu">
            <div class="row">
                <div class="col-12">
                    <div class="touch-box"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.non-transition {
    transition: none !important;
}

.mobile-menu-layer{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .3);
    z-index: 10;
}
.menu-block{
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    z-index: 20;
    -webkit-overflow-scrolling: touch;
    margin-top: auto;
}

.touch-box{
    height: 30px;
    position: relative;
}


.touch-box::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 5px;
    border-radius: 50px;
    background-color: rgba(0,0,0, 0.15);
}
</style>