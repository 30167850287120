<!--Блоки с подборками на главной
<div class="selection__inner row">
    <selection-item />
</div>
-->
<script>

import {defineComponent} from "vue";
import LogInBlock from "@/components/ui/LogInBlock.vue";
import conjugation from "@/mixins/conjugation";
import {useCookies} from "vue3-cookies";
import axios from "axios";
export default defineComponent({
    name: 'SelectionViewed',
    mixins:[conjugation],
    components: {LogInBlock},
    methods:{
        openBlank(id){
            window.open('/anketa/' + id, '_blank');
        },
        getProfiles(){

            if(this.cookies.isKey('views')){

                let views;
                views = JSON.parse(this.cookies.get('views'))

                let vi = [];

                if(views.length > 4){

                    for (let i = 1; i <= 4; i++){
                        vi.push(views.splice(Math.floor(Math.random()*views.length), 1)[0])
                    }
                }else{
                    vi = views
                }

                for(let id of vi){

                    axios
                        .get(
                            this.apiUrl + "api-girl/get-profile-by-id&auth="
                            + this.user.username + ':' + this.user.auth_key
                            + '&id=' + id
                            + `&city_id=${this.user.city?.id || 1}`)
                        .then((response) => {
                            this.selection.push(response.data)
                        }).catch(error => console.log(error)).finally(() => {
                            if (this.selection.length > 3) {
                                this.emptyBlockClass = false
                            }
                        })
                }

                this.showEmptyText = false
            }
        }
    },
    computed: {
        userCity() {
			return this.$store.getters.getCity
		}
    },
    watch: {
		userCity: function () {
            this.selection = []
			this.getProfiles()
		}
	},
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            selection: [],
            emptyText: 'Здесь пока ничего нет',
            showEmptyText: true,
            emptyBlockClass: true
        }
    },
    setup() {
        const {cookies} = useCookies();
        return {cookies};
    },
    mounted() {
        this.getProfiles()
    }

})
</script>

<template>

    <div class="selection__block col-lg-3 col-6">
        <div
            v-if="this.showEmptyText"
            class="d-flex justify-content-center align-items-center selection__box bord-box"
        >
            <span class="text-black">{{this.emptyText}}</span>
        </div>
        <div v-else class="selection__box" :class="{'bord-box' : emptyBlockClass}">

            <div class="selection__item selection-item" v-for="(item, i) in selection" :key="i">
                <div class="selection-item__bg" :style="{
										width: '100%',
										backgroundSize: 'cover',
										backgroundImage: 'url(' + this.apiDomine + '/web/uploads' + item?.photo[0]?.pic + ')' }"
                >
                    <div class="selection-item--hover">
                        <div class="selection-item__name" v-html="item?.name"></div>
                        <div class="selection-item__age" v-html="m_age(item?.age)"></div>
                        <div class="selection-item__location">{{item.city?.name}} <span v-if="item.metro"> ({{item.metro?.name}})</span></div>
                        <div class="selection-item__price">
                            <div>за час</div>
                            <div v-if="item.price_hour_app != 0" v-html="item.price_hour_app"></div>
                            <div v-else v-html="item.price_hour_exit"></div>
                        </div>
                        <div class="selection-item__price">
                            <div>за два</div>
                            <div v-if="item.price_hour_app != 0" v-html="item.price_two_hour_app"></div>
                            <div v-else v-html="item.price_two_hour_exit"></div>
                        </div>
                        <div class="selection-item__price">
                            <div>за ночь</div>
                            <div v-if="item.price_hour_app != 0" v-html="item.price_night_app"></div>
                            <div v-else v-html="item.price_night_exit"></div>
                        </div>
                        <button class="selection-item__btn hover-btn"  @click="openBlank(item.id)">Открыть анкету</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="selection__btn">
            <a href="/compilation-view" target="_blank" class="hover-btn"><img src="@/assets/img/selection1.svg" alt="...">Вы смотрели</a>
        </div>
    </div>

</template>

<style scoped lang="scss">
@import '@/styles/index';

.block-offer {
    padding: 3.5rem 0;
    @media (max-width: 991px) {
        padding: 1.5rem 0;
    }

    .slider-offer {
        max-width: 100%;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 50px;
        gap: 10px;
    }

    &__row {
        color: $text;
        text-align: right;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        text-align: right;
        @media ($tablet) {
            font-size: 16px;
        }
        @media ($phoneS) {
            font-size: 10px;
        }
    }

    &__strip {
        display: block;
        height: 1px;
        flex-grow: 1;
        background-color: rgba(189, 193, 209, 0.31);
    }
}

.selection {
    &__block {
        @media ($lg) {
            margin-bottom: 20px;
        }
    }

    &__box {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        height: 310px;
        width: 100%;
        flex-grow: 1;
        border-radius: 16px;
        overflow: hidden;
        @media($phone) {
            height: 170px;
        }
    }

    &__item {
        flex: 0 0 46%;
        max-width: 46%;
        position: relative;
        transition: 1s;

        @media($phoneS) {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }

    &__btn {
        margin-top: 20px;

        a {
            color: #000;
            background: #EFEFEF;
            display: inline-flex;
            border-radius: 100px;
            align-items: center;
            padding: 11px 21px 10px 12px;
            transition: 0.3s;
            font-size: 14px;
            @media(max-width: 575px) {
                padding: 10px;
                font-size: 10px;
            }

            &:hover {
                background: #c2c0c0;
            }
        }

        img {
            padding-right: 10px;
            padding-bottom: 2px;
        }
    }
}

.selection-item {
    transition: 0.8s;

    position: absolute;
    width: 100%;
    line-height: 130%;

    &__name {
        font-size: 20px;
        display: flex;
        justify-content: flex-start;
    }

    &__age {
        font-size: 12px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
    }

    &__location {
        font-size: 12px;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;

        span {
            display: block;
        }

    }

    &__btn {
        background: rgba(#fff, 0.5);
        border-radius: 100px;
        padding: 10px;
        transition: 0.3s;
        max-width: 80%;
        width: 100%;
        display: block;
        margin: 10% auto;
        text-align: center;
        margin-top: auto;
        @media(max-width: 575px) {
            padding: 5px 0;
            max-width: 100%;
            font-size: 10px;
        }

        &:hover {
            background-color: #fff;
        }
    }

    &__price {
        display: flex;
        font-size: 14px;
        line-height: 120%;
        @media(max-width: 575px) {
            font-size: 10px;
        }

        div {
            margin-bottom: 5px;

            &:first-child {
                margin-right: 5px;
            }

            &:last-child {
                font-weight: 600;
            }
        }
    }

    &:nth-child(1) {
        top: 0;
        left: 0;
    }

    &:nth-child(2) {
        top: 0;
        right: 0;
    }

    &:nth-child(3) {
        left: 0;
        bottom: 0;
    }

    &:nth-child(4) {
        right: 0;
        bottom: 0;
    }

    &--hover {
        opacity: 0;
        visibility: hidden;
        height: 0;
        //transition: 1s;
        color: #fff;
        font-size: 14px;
        position: relative;

        z-index: 0;
        padding: 20px;
        display: flex;
        flex-direction: column;

        &::after {
            content: '';
            background-color: rgba(#000, 0.5);
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            backdrop-filter: blur(4px);
        }
    }

    &:hover {
        flex: 0 0 100%;
        max-width: 100%;
        height: 100%;
        z-index: 10;

        .selection-item__bg {
            width: 100%;
            height: 100% !important;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
        }

        .selection-item--hover {
            opacity: 1;
            visibility: visible;
            height: 100%;
            z-index: 100;
        }
    }

    &__bg {
        height: 145px;
        color: #fff;
        @media ($tablet) {
            height: 79px;
        }
    }


}

.selection-block {
    &__signin-bord {
        border-radius: 16px;
        border: 1px dashed #8E7F7D;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        height: 100%;
    }

    &__signin {
        border-radius: 16px;
        border: none;
        padding: 0px;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        height: 100%;
    }

    &__btn {
        margin: auto auto 15px;
        text-align: center;
        display: block;

        .log-in {
            &__btn {
                width: 100% !important;

                &:hover button {
                    background-color: none;
                    box-shadow: 0 0 7px rgba(#1d1d1d, 0.7);
                }
            }

            &__header {
                background-color: #504e4e;
                border-radius: 14px;
                padding: 10px 5px;
            }
        }

        button {
            cursor: pointer;
            color: #fff;
            background: linear-gradient(to right, #9A7F88, #201F36);
            border-radius: 26px;
            text-align: center;
            font-size: 16px;
            padding: 10px 12px;
            transition: 0.3s;

            &:hover {
                box-shadow: 0 0 15px rgba(#000, 0.9) inset;
            }
        }
    }

    &__text {
        color: #000;
        font-size: 24px;
        font-style: normal;
        line-height: 130%;
        text-transform: lowercase;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media($tablet) {
            font-size: 16px;
        }
    }

}
.bord-box{
    border-radius: 16px;
    border: 1px dashed #8E7F7D;
}
</style>