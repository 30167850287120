// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/lens-white.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-s {\n  background: linear-gradient(to right, #72666A, #201F36);\n}\n.header-s__desktop {\n  display: block;\n}\n@media (max-width: 1023px) {\n.header-s__desktop {\n    display: none;\n}\n}\n.header-s .switch__name {\n  color: #525665;\n}\n.header-s .switch__name.active {\n  color: #fff;\n  border-color: #525665;\n}\n.header-s__city {\n  color: #fff;\n  display: inline-block;\n  border-bottom: solid 1px #525665;\n  margin-right: 25px;\n  cursor: pointer;\n}\n.header-s__label {\n  position: relative;\n  display: block;\n  max-width: 260px;\n  margin: 0 auto;\n}\n.header-s__input-img {\n  position: absolute;\n  top: 11px;\n  right: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-origin: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  height: 13px;\n  width: 18px;\n}\n.header-s__input {\n  background: red;\n  margin: 0 auto;\n  display: block;\n  border-radius: 44px;\n  background: rgba(255, 255, 255, 0.3);\n  height: 35px;\n  line-height: 35px;\n  padding: 0 25px 0 15px;\n  width: 100%;\n}\n.body-backdrop {\n  position: fixed;\n  -webkit-backdrop-filter: blur(8px);\n          backdrop-filter: blur(8px);\n  background-color: rgba(0, 0, 0, 0.3);\n  opacity: 0;\n  visibility: hidden;\n  transition: 0.4s;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 9;\n}\n.body-backdrop.show {\n  opacity: 1;\n  visibility: visible;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
