<script setup>
import OrderProgressBar from '@/components/order_modal/OrderProgressBar.vue';
import OrderBossAvatar from '@/components/order_modal/OrderBossAvatar.vue';
import OrderGreeting from '@/components/order_modal/OrderGreeting.vue';
import OrderCity from '@/components/order_modal/OrderCity.vue';
import OrderType from '@/components/order_modal/OrderType.vue';
import OrderPlace from '@/components/order_modal/OrderPlace.vue';
import OrderTime from '@/components/order_modal/OrderTime.vue';
import OrderExpectations from '@/components/order_modal/OrderExpectations.vue';
import OrderCost from '@/components/order_modal/OrderCoast.vue';
import OrderAuth from '@/components/order_modal/OrderAuth.vue';
import OrderFinish from '@/components/order_modal/OrderFinish.vue';
import OrderError from '@/components/order_modal/OrderError.vue';

import axios from 'axios';
import { Modal } from 'bootstrap';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const emit = defineEmits(['hide-modal', 'create-order']);

const store = useStore();
const user = computed(() => {
    return store.getters.getUser;
});
const apiUrl = store.getters.getApiUrl;
const orderModal = ref(null);
const steps = new Map([
    ['intro', 1],
    ['city', 2],
    ['type', 3],
    ['place', 4],
    ['time', 5],
    ['expectations', 6],
    ['coast', 7],
    ['auth', 8],
    ['finish', 9],
    ['error', 0],
]);
const types = [
    { value: 'apartments', label: 'Апартаменты' },
    { value: 'invite', label: 'Пригласить к себе' },
    { value: 'escort', label: 'Сопровождение' },
];

//inital data
const errorMessage = ref('');
const currentStep = ref('intro');
const currentCityId = ref(user.value?.city?.id || 1);
const currentType = ref('invite');
const currentPlace = ref(null);
const currentDateTime = ref(new Date(Date.now() + 2 * (60 * 60 * 1000)));
currentDateTime.value.setMinutes(0);
currentDateTime.value.setSeconds(0);
currentDateTime.value.setMilliseconds(0);
const currentExpectations = ref(null);
const currentCoast = ref({ from: null, to: null });
const createStatus = ref(false);

onMounted(() => {
    orderModal.value = new Modal('#order-modal', {});
    orderModal.value.show();
});

//methods
const createOrder = async () => {
    const { data, status } = await axios
        .post(
            apiUrl + 'api-order/add',
            {
                user_id: user.value.user_id,
                city_id: currentCityId.value,
                expectations: currentExpectations.value,
                type: currentType.value,
                place: currentPlace.value === null ? ' ' : currentPlace.value,
                date_time: currentDateTime.value.getTime() / 1000,
                coast_from: currentCoast.value.from,
                coast_to: currentCoast.value.to,
            },
            {
                params: {
                    auth: user.value.username + ':' + user.value.auth_key,
                },
            }
        )
        .catch((error) => {
            console.error(error.message);
            return error;
        });

    if (data?.status) {
        return true;
    } else return false;
};

const onGreetingComplete = () => {
    currentStep.value = 'city';
};

const onCityComplete = (cityId) => {
    currentCityId.value = cityId;
    currentStep.value = 'type';
};

const onTypeComplete = (type, forward) => {
    currentType.value = type;
    if (type === 'invite') {
        currentStep.value = forward ? 'place' : 'city';
    } else currentStep.value = forward ? 'time' : 'city';
};

const onPlaceComplete = (place, forward) => {
    currentPlace.value = place;
    currentStep.value = forward ? 'time' : 'type';
};

const onTimeComplete = (dateTime, forward) => {
    currentDateTime.value = dateTime;
    if (currentType.value === 'invite' && !forward) {
        currentStep.value = 'place';
    } else currentStep.value = forward ? 'expectations' : 'type';
};

const onExpectationsComplete = (expectations, forward) => {
    currentExpectations.value = expectations;
    currentStep.value = forward ? 'coast' : 'time';
};

const onCoastComplete = async (coast, forward) => {
    currentCoast.value = coast;
    if (user.value.isLogged && forward) {
        createStatus.value = await createOrder();
        if (createStatus.value) {
            currentStep.value = 'finish';
        } else {
            errorMessage.value = 'Произошла ошибка при оформлении заказа';
            currentStep.value = 'error';
        }
    } else currentStep.value = forward ? 'auth' : 'expectations';
};

const onAuthComplete = async (forward) => {
    if (user.value.isLogged && forward) {
        createStatus.value = await createOrder();
        if (createStatus.value) {
            currentStep.value = 'finish';
        } else {
            errorMessage.value = 'Произошла ошибка при оформлении заказа';
            currentStep.value = 'error';
        }
    } else currentStep.value = forward ? 'auth' : 'coast';
};

function onInvalidAuth() {
    errorMessage.value = 'Оформление заказа доступно только для активированных учетных записей с ролью "Пользователь"';
    currentStep.value = 'error';
}

function onHideModal() {
    emit('create-order', createStatus.value);
    emit('hide-modal');
}
</script>

<template>
    <div class="order-modal__wrapper">
        <div
            ref="orderModal"
            id="order-modal"
            class="modal fade order-modal__container"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            v-on:[`hide.bs.modal`]="onHideModal"
        >
            <div class="modal-dialog">
                <div class="modal-content ps-3 pe-3 pb-2">
                    <div class="modal-header px-0 pt-4 d-flex flex-row align-items-center gap-3">
                        <OrderProgressBar
                            :step-count="steps.size - 1"
                            :current-step="steps.get(currentStep)"
                        ></OrderProgressBar>
                        <div class="">
                            <button
                                class="modal-dialog__close-button"
                                data-bs-dismiss="modal"
                                data-bs-target="#order-modal"
                            >
                                <i class="bi bi-x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body mb-3 row d-flex flex-nowrap">
                        <div class="mt-auto col-1 w-auto p-0">
                            <OrderBossAvatar class="modal-body__avatar" />
                        </div>
                        <div class="modal-body__content col ms-2 ps-3 pe-3 pb-3 pt-3">
                            <OrderGreeting
                                v-if="currentStep === 'intro'"
                                @next-step="onGreetingComplete()"
                            />
                            <OrderCity
                                v-else-if="currentStep === 'city'"
                                :initial-city-id="currentCityId"
                                @next-step="(cityId) => onCityComplete(cityId)"
                            />
                            <OrderType
                                v-else-if="currentStep === 'type'"
                                :types="types"
                                :initial-type="currentType"
                                @next-step="(value) => onTypeComplete(value.type, value.forward)"
                            />
                            <OrderPlace
                                v-else-if="currentStep === 'place'"
                                :initial-place="currentPlace"
                                @next-step="(value) => onPlaceComplete(value.place, value.forward)"
                            />
                            <OrderTime
                                v-else-if="currentStep === 'time'"
                                :initial-date-time="currentDateTime"
                                @next-step="(value) => onTimeComplete(value.dateTime, value.forward)"
                            />
                            <OrderExpectations
                                v-else-if="currentStep === 'expectations'"
                                :initial-expectations="currentExpectations"
                                @next-step="(value) => onExpectationsComplete(value.expectations, value.forward)"
                            />
                            <OrderCost
                                v-else-if="currentStep === 'coast'"
                                :initial-coast="currentCoast"
                                @next-step="(value) => onCoastComplete(value.coast, value.forward)"
                            />
                            <OrderAuth
                                v-else-if="currentStep === 'auth'"
                                @next-step="(value) => onAuthComplete(value.forward)"
                                @invalid-auth="onInvalidAuth"
                            />
                            <OrderError
                                v-else-if="currentStep === 'error'"
                                :error-text="errorMessage"
                            />
                            <OrderFinish v-else-if="currentStep === 'finish'" />
                            <div
                                v-if="currentStep === 'finish' || currentStep === 'error'"
                                class="d-flex ms-auto"
                            >
                                <button
                                    class="order-modal__close-button ms-auto"
                                    data-bs-dismiss="modal"
                                    data-bs-target="#order-modal"
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.order-modal__container {
    .modal-dialog {
        max-width: 800px;

        .modal-content {
            min-height: 200px;
            background-color: #0a0813;
            color: white;
            border-radius: 28px;

            .modal-header {
                border-bottom: none;
                .modal-dialog__close-button {
                    border-radius: 6px;
                    border: #312b45 solid 1px;
                    color: #bdc1d1;
                }
            }

            .modal-body {
                .modal-body__avatar {
                    width: 40px;
                    height: 40px;
                }

                .modal-body__content {
                    background-color: #312b45;
                    border-radius: 28px 28px 28px 0;
                }
            }
        }

        .modal-footer {
            border-top: none;
        }
    }
}

.order-modal__close-button {
    background-color: #bdc1d1;
    border-radius: 30px;
    padding: 10px 16px;
    color: #2d2f3a;
    font-size: 14px;
    width: 130px;
}
</style>
