<script>
import CardWhite from "@/components/ui/CardWhite.vue";

import axios from "axios";
import {offset} from "@popperjs/core";
import AdminModalTable from "@/components/ui/AdminModalTable.vue";

export default {
  name: 'VideoPenetranion',
  components: {AdminModalTable, CardWhite},
  data() {
    return {
      profiles: [],
      profilesCount: 0,
      user: this.$store.getters['getUser'],
      apiUrl: this.$store.getters['getApiUrl'],
      offset: 0,
      limit: 20,
      methodPostStatus: 'api-girl/update-status',
      isModalDetail: false,
      singleBilling: ''
    }
  },

  watch: {
    offset(newValue) {
      if (newValue < this.limit) {
        this.offset = 0
      }

      if (this.offset > this.profilesCount) {
        this.offset = 0
        this.getQuestionnaire()
      }

      this.getNextPage()
    }
  },

  mounted() {
    this.getQuestionnaire()
  },

  methods: {
    getQuestionnaire() {
      const methods = 'api-girl/get-profiles-list-admin'

      let params = '&auth=' + this.user.username + ':' + this.user.auth_key

      axios.get(this.apiUrl + methods + params + '&status_id=8').then((res) => {
        this.profiles.length = 0

        for (let key in res.data) {
          if (typeof res.data[key] === 'object') {
            this.profiles.push(res.data[key])
          } else {
            this.profilesCount = res.data[key]
          }
        }

        console.log(this.profiles)

      })
    },

    formatDate(t) {
      let myDate = new Date(t * 1000) // date object
      return ((myDate.getDate() < 10) ? "0" : "") + myDate.getDate() + "." + (((myDate.getMonth() + 1) < 10) ? "0" : "") + (myDate.getMonth() + 1) + "." + myDate.getFullYear();
    },

    getNextPage() {
      const methods = 'api-girl/get-profiles-list-admin'

      let params = '&auth=' + this.user.username + ':' + this.user.auth_key
      let queryParam = `&status_id=8&offset=${this.offset}&limit=${this.limit}`

      axios.get(this.apiUrl + methods + params + queryParam).then((res) => {
        this.profiles.length = 0

        for (let key in res.data) {
          if (typeof res.data[key] === 'object') {
            this.profiles.push(res.data[key])
          }
        }
      })
    },

    examVideo(item, status) {
      let params = {
        status_id: status ? 9 : 10,
        id: item.id
      }

      axios.post(this.apiUrl + this.methodPostStatus + '&auth=' + this.user.username + ':' + this.user.auth_key, params)
          .then(() => {
            this.getQuestionnaire()
            this.isModalDetail = false
          })
          .catch((error) => {
            console.log('произошла ошибка, при смене статуса' + error)
          })
    },

    openModalDetail(item) {
      if (window.innerWidth > 1050) return

      this.singleBilling = item
      this.isModalDetail = true
    },
  }
}
</script>

<template>
  <CardWhite>
    <div class="table-wrapper">
      <table class="responsive-table">
        <thead>
        <tr>
          <td class="text-white">ID</td>
          <td class="text-white">Логин</td>
          <td class="text-white">Категория</td>
          <td class="text-white">Город</td>
          <td class="text-white">Статус</td>
          <td class="text-white">Дата загрузки видео</td>
          <td class="text-white">Дата загрузки анкеты</td>
          <td class="text-white">Автор</td>
          <td class="text-white">Действия</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item of profiles" :key="item.id" @click="openModalDetail(item)" class="settings-table__row">
          <td id="id-row" class="settings-table__item text-white">{{ item.id }}</td>
          <td id="shortUser" class="settings-table__item text-white">{{ item.shortUser.username }}</td>
          <td id="category" class="settings-table__item text-white">{{ item.category.name }}</td>
          <td id="city" class="settings-table__item text-white">{{ item.city.name }}</td>
          <td id="status" class="settings-table__item text-white">{{ item.status.name }}</td>
          <td id="date_update" class="settings-table__item text-white">{{ formatDate(item.date_update) }}</td>
          <td id="date_add" class="settings-table__item text-white">{{ formatDate(item.date_add) }}</td>
          <td id="author" class="settings-table__item text-white">{{ item.author.username }}</td>
          <td id="btns" class="settings-table__item text-white">
            <router-link :to="'/anketa/' + item.id" target="_blank" style="color:#fff;">
              <i class="bi bi-eye hover-btn"></i>
            </router-link>
            <button
                @click="examVideo(item, true)">
              <i class="bi bi-check hover-btn"></i>
            </button>
            <button
                @click="examVideo(item, false)">
              <i class="bi bi-x-circle hover-btn"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <AdminModalTable
        v-if="isModalDetail"
        :tableContent="
           [
            {title: 'ID', content: singleBilling.id},
            {title: 'Логин', content: singleBilling.shortUser.username},
            {title: 'Категория', content: singleBilling.category.name},
            {title: 'Город', content: singleBilling.city.name},
            {title: 'Статус', content: singleBilling.status.name},
            {title: 'Дата загрузки видео', content: formatDate(singleBilling.date_update)},
            {title: 'Дата загрузки анкеты', content: formatDate(singleBilling.date_add)},
            {title: 'Автор', content: singleBilling.author.username},
           ]
         "
        @closeModal="isModalDetail = false"
    >
      <template v-slot:buttons>
        <router-link :to="'/anketa/' + this.singleBilling.id" target="_blank" style="color:#fff;">
          <i class="bi bi-eye hover-btn text-black"></i>
        </router-link>
        <button
            @click="examVideo(singleBilling, true)">
          <i class="bi bi-check hover-btn"></i>
        </button>
        <button
            @click="examVideo(singleBilling, false)">
          <i class="bi bi-x-circle hover-btn"></i>
        </button>
      </template>
    </AdminModalTable>
  </CardWhite>
</template>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px 10px 0px 0px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table thead {
  background-color: #2C2F3C;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #7e7e7e7e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.responsive-table th {
  background-color: #f2f2f2;
  color: #333;
}

.responsive-table td {
  padding: 0px 10px;
  text-align: center;
  line-height: normal;
}

.settings-table {
  color: #fff;

  &__head {
    background-color: #2C2F3C;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #7e7e7e7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }


  &__row {
    background-color: #2C2F3C;
    position: relative;


    &:nth-child(odd) {
      background-color: #2C2F3C;
    }

    button {
      color: #fff;
    }
  }

  &__row:hover {
    background-color: #524B5F;
  }

  &__item {
    border-bottom: solid 1px #7e7e7e7e;
  }
}

.arrow-page {
  font-size: 30px;
  font-weight: 600;
  color: #2C2F3C;
}

@media (max-width: 1050px) {
  .responsive-table > thead td:nth-child(3),
  .responsive-table > thead td:nth-child(7),
  .responsive-table > thead td:nth-child(9) {
    display: none;
  }

  #btns,
  #category,
  #date_add {
    display: none;
  }
}

@media (max-width: 500px) {
  .responsive-table > thead td:nth-child(8),
  .responsive-table > thead td:nth-child(6),
  .responsive-table > thead td:nth-child(4) {
    display: none;
  }

  #city,
  #date_update,
  #author {
    display: none;
  }
}
</style>