<script setup>
import PromoLogo from '@/pages/Promo/PromoLogo.vue';
import PromoVideo from '@/pages/Promo/PromoVideo.vue';
import PromoFaq from '@/pages/Promo/PromoFaq.vue';
import PromoIntro from '@/pages/Promo/PromoIntro.vue';
import PromoPrompt from '@/pages/Promo/PromoPrompt.vue';
import PromoService from '@/pages/Promo/PromoService.vue';
import PromoDesign from '@/pages/Promo/PromoDesign.vue';
import PromoProfile from '@/pages/Promo/PromoProfile.vue';
import PromoVisitCard from '@/pages/Promo/PromoVisitCard.vue';
import PromoUsability from '@/pages/Promo/PromoUsability.vue';
import PromoPromotion from '@/pages/Promo/PromoPromotion.vue';
import PromoBonuses from '@/pages/Promo/PromoBonuses.vue';
import LogInCard from '@/components/blocks/LogInCard.vue';

import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const profileForm = ref(null);
const isShowLoginCard = ref(false);
const loginTitle = null;

const store = useStore();
const router = useRouter();

//methods
const onFillQuestionnaireClicked = () => {
    if (user?.value?.isLogged && user?.value?.role.item_name === 'girl') {
        router.push({ name: 'questionnaire' });
    } else if (user?.value?.isLogged) {
        router.push({ name: 'user-settings' });
    } else {
        isShowLoginCard.value = true;
    }
};

const onLoginSuccess = () => {
    onFillQuestionnaireClicked();
};

const onHideDialog = () => {
    isShowLoginCard.value = false;
};

//computeds
const user = computed(() => {    
    return store.getters.getUser;
});
</script>

<template>
    <div class="promo-page__wrapper">
        <PromoVideo />
        <div class="promo-page__container container-fluid mx-auto">
            <PromoLogo />
            <PromoIntro @fill_questionnaire_clicked="onFillQuestionnaireClicked" />
            <PromoPrompt @fill_questionnaire_clicked="onFillQuestionnaireClicked" />
            <PromoService @fill_questionnaire_clicked="onFillQuestionnaireClicked" />
            <PromoDesign @fill_questionnaire_clicked="onFillQuestionnaireClicked" />
            <PromoProfile ref="profileForm" />
            <PromoVisitCard />
            <PromoPromotion @fill_questionnaire_clicked="onFillQuestionnaireClicked" />
            <PromoUsability @fill_questionnaire_clicked="onFillQuestionnaireClicked" />
            <PromoBonuses @fill_questionnaire_clicked="onFillQuestionnaireClicked" />
            <PromoFaq />
        </div>
    </div>
    <LogInCard
        :show="isShowLoginCard"
        @loginSuccess="onLoginSuccess"
        @hideDialog="onHideDialog"
        :title="loginTitle"
    />
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main';

.promo-page__wrapper {
    height: auto;

    .promo-page__container {
        max-width: 1920px;
        background-color: $background-main-color;
        color: $font-main-color;
        font-family: 'Mont';
        font-weight: normal;
        overflow-x: hidden;
    }
}

@include media-breakpoint-between(xs, md) {
    .promo-page__container {
        padding-inline: 10px;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        font-style: normal;
    }
}

@include media-breakpoint-between(md, xl) {
    .promo-page__container {
        padding-inline: 40px;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        font-style: normal;
    }
}

@include media-breakpoint-up(xl) {
    .promo-page__container {
        padding-inline: 80px;
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        font-style: normal;
    }
}
</style>
