<script setup>
import GradientButton from '@/components/promo/GradientButton.vue';

import { ref, watch } from 'vue';
import { useWindowScroll } from '@vueuse/core';

const cardsContainer = ref(null);
const cardItemFirst = ref(null);
const cardItemSecond = ref(null);
const cardItemThird = ref(null);
const cardsCursor = ref(null);
const isMouseEntered = ref(false);
const yScroll = useWindowScroll().y;

const onMouseMove = (e) => {
    cardsCursor.value.style.left = e.pageX - cardsCursor.value.getBoundingClientRect().width / 2 + 'px';
    cardsCursor.value.style.top = e.pageY - cardsCursor.value.getBoundingClientRect().height / 2 + 'px';
};

const moveCards = (percent) => {
    cardItemFirst.value.style.transition = 'transform .3s ease-out';
    cardItemSecond.value.style.transition = 'transform .3s ease-out';
    cardItemThird.value.style.transition = 'transform .3s ease-out';

    cardItemFirst.value.style.transform = `translateY(-${(200 / 100) * percent}px)`;
    cardItemSecond.value.style.transform = `translateY(${(80 / 100) * percent}px)`;
    cardItemThird.value.style.transform = `translateY(-${(400 / 100) * percent}px)`;
};

//watchers
watch(yScroll, (newValue, oldValue) => {
    const cardYOffset = cardsContainer.value.getBoundingClientRect().y;
    const cardHeight = cardsContainer.value.getBoundingClientRect().height;
    const { innerHeight } = window;
    const rangeHeight = innerHeight + cardHeight * 0.8; //высота, в рамках которой происходит анимация

    //проверяем, что блок с карточками находится в области анимации
    //начало - 10% от высоты блока с карточкой, конец - 90% блока ушло
    if (innerHeight - cardYOffset > cardHeight * 0.1 && cardHeight * 0.9 + cardYOffset > 0) {                
        const scrolledHeight = innerHeight - cardYOffset - cardHeight * 0.1;
        const percent = scrolledHeight / (rangeHeight * 0.01);
        moveCards(percent);
    }
});
</script>

<template>
    <section class="promo-service__container">
        <div class="promo-service__header">
            <div class="">Масштабирование</div>
            <div class="promo-service__header__title">
                <h2 class="">
                    Мы создали главный сервис<br />
                    для эскорт моделей
                </h2>
                <div class="promo-service__header__icon">
                    <img
                        class="img-fluid"
                        src="/img/heart_sm.png"
                        alt=""
                    />
                </div>
            </div>
        </div>

        <div class="promo-service__content d-flex flex-column align-items-center">
            <div class="promo-service__carousel__wrapper d-md-none mx-auto">
                <div
                    id="promo-service-carousel"
                    class="promo-service__carousel carousel slide mx-auto"
                    data-bs-ride="carousel"
                >
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img
                                class="w-100 img-fluid"
                                src="/img/snezhana_1.png"
                                alt="img"
                            />
                        </div>
                        <div class="carousel-item middle-item">
                            <img
                                class="w-100 img-fluid"
                                src="/img/snezhana_2.png"
                                alt="img"
                            />
                        </div>
                        <div class="carousel-item">
                            <img
                                class="w-100 img-fluid"
                                src="/img/snezhana_3.png"
                                alt="img"
                            />
                        </div>
                    </div>
                    <GradientButton
                        class="carousel-control-button carousel-control-next"
                        type="button"
                        data-bs-target="#promo-service-carousel"
                        data-bs-slide="next"
                    >
                        <svg
                            width="24"
                            height="8"
                            viewBox="0 0 24 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM0 4.5L23 4.5V3.5L0 3.5L0 4.5Z"
                                fill="white"
                            />
                        </svg>
                    </GradientButton>
                    <GradientButton
                        class="carousel-control-button carousel-control-prev"
                        type="button"
                        data-bs-target="#promo-service-carousel"
                        data-bs-slide="prev"
                    >
                        <svg
                            width="24"
                            height="8"
                            viewBox="0 0 24 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.646446 3.64644C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976309 4.7308 0.659727 4.53553 0.464464C4.34027 0.269202 4.02369 0.269202 3.82843 0.464464L0.646446 3.64644ZM24 3.5L1 3.5L1 4.5L24 4.5L24 3.5Z"
                                fill="white"
                            />
                        </svg>
                    </GradientButton>
                </div>
            </div>

            <div
                class="promo-service__cards d-none d-md-flex flex-row align-items-center justify-content-between"
                ref="cardsContainer"
                @mousemove="onMouseMove"
                @mouseleave="isMouseEntered = false"
                @mouseenter="isMouseEntered = true"
            >
                <div
                    ref="cardItemFirst"
                    class="promo-service__card"
                >
                    <div class="promo-service__card__image">
                        <img
                            class="img-fluid"
                            src="/img/snezhana_1.png"
                            alt="img"
                        />
                    </div>
                </div>
                <div
                    ref="cardItemSecond"
                    class="promo-service__card"
                >
                    <div class="promo-service__card__image">
                        <img
                            class="img-fluid"
                            src="/img/snezhana_2.png"
                            alt="img"
                        />
                    </div>
                </div>
                <div
                    ref="cardItemThird"
                    class="promo-service__card"
                >
                    <div class="promo-service__card__image">
                        <img
                            class="img-fluid"
                            src="/img/snezhana_3.png"
                            alt="img"
                        />
                    </div>
                </div>
            </div>
            <div class="promo-service__text w-100">
                <p class="text-center text-md-start">
                    Анкеты с которого синхронизируются с десятком других наших сайтов для того, чтобы вы получали как
                    можно больше просмотров
                </p>
                <div class="promo-service__button mx-auto ms-md-auto me-md-0">
                    <GradientButton @click="$emit('fill_questionnaire_clicked')" class="">Заполнить анкету</GradientButton>
                </div>
            </div>
            <div
                ref="cardsCursor"
                class="promo-service__cursor"
                :class="[isMouseEntered ? 'd-block' : 'd-none']"
            ></div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
@import '@/styles/promo/main.scss';

.promo-service__container {
    .promo-service__header {
        > div {
            margin-bottom: 25px;
            @include text-regular-x-small;
        }

        .promo-service__header__title {
            max-width: 880px;
            display: flex;

            > h2 {
                font-size: 23px;
                line-height: 32px;
                font-weight: 700;
                font-style: normal;
            }

            .promo-service__header__icon {
                width: 55px;
            }
        }
    }

    .promo-service__content {
        .promo-service__carousel__wrapper {
            max-width: 430px;
            margin-bottom: 40px;

            .carousel-control-button {
                position: absolute;
                top: 50%;
                width: 52px;
                height: 52px;
            }
        }
        .promo-service__cards {
            gap: 30px;
            margin-bottom: 60px;

            .promo-service__card {
                max-width: 410px;
            }
        }

        .promo-service__text {
            > p {
                margin-bottom: 20px;
            }
        }

        .promo-service__button {
            width: 175px;
            margin-bottom: 80px;
        }

        .promo-service__cursor {
            position: absolute;
            background-image: url('@/assets/svg/cursor.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            height: 100px;
            width: 100px;
            pointer-events: none;
            transition: cubic-bezier(0.49, 1.1, 0.9, 1.5);
            transition-duration: 0.5s;
        }
    }
}

@include media-breakpoint-up(md) {
    .promo-service__container {
        margin-bottom: 100px;
        .promo-service__header {
            .promo-service__header__title {
                > h2 {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 50px;
                    font-style: normal;
                }
            }
        }
        .promo-service__content {
            .promo-service__cards {
                gap: 60px;
                padding-top: 100px;
                padding-bottom: 100px;
                .promo-service__card {
                    max-width: 490px;
                    position: relative;
                }
                .promo-service__card:nth-child(1) {
                    top: 80px;
                }
                .promo-service__card:nth-child(2) {
                    top: -60px;
                }
                .promo-service__card:nth-child(3) {
                    top: 140px;
                }
            }

            .promo-service__text {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    width: 60%;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 32px;
                    font-style: normal;
                }

                .promo-service__button {
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 200px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .promo-service__container {
        margin-bottom: 200px;
        .promo-service__header {
            .promo-service__header__title {
                > h2 {
                    font-weight: 700;
                    font-size: 48px;
                    line-height: 62px;
                    font-style: normal;
                }
            }
        }
        .promo-service__content {
            .promo-service__cards {
                gap: 130px;
            }

            .promo-service__text {
                p {
                    width: 80%;
                    @include text-regular-large;
                }
            }
        }
    }
}
</style>
