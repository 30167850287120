<script>
import InputWithCopy from "@/components/ui/InputWithCopy.vue";
import axios from "axios";

export default {
  name: 'UserForm',
  components: {InputWithCopy},
  props: {
    userData: {
      type: Object,
      require: true
    }
  },
  methods: {
    formatDate(t) {
      let myDate = new Date(t * 1000) // date object
      return ((myDate.getDate() < 10) ? "0" : "") + myDate.getDate() + "." + (((myDate.getMonth() + 1) < 10) ? "0" : "") + (myDate.getMonth() + 1) + "." + myDate.getFullYear();
    },
    getStatus(id) {
      if (parseInt(id) === 10) {
        return '<span class="text-success"> Активен</span>'
      } else {
        return '<span class="text-danger">Не активен</span>'
      }
    },
    validateForm() {
      let result = true;

      ['inputId', 'inputUsername', 'inputEmail'].forEach((id) => {
        let el = document.getElementById(id);
        if (el.value === "") {
          el.classList.add('is-invalid');
          result = false;
        } else {
          el.classList.remove('is-invalid');
        }
      });


      if (result) {

        this.updateUser();

      }
    },
    updateUser() {
      let params = '&auth=' + this.user.username + ':' + this.user.auth_key;
      axios
          .post(this.apiUrl + 'api-user/update-user' + params, this.editUser)
          .then((response) => {
            console.log(response)
            if (response.data.status) {
              this.$emit('userUpdated');
            } else {

              this.error = response.data.error
            }
          })
    },
    changePass() {
      const regex = /^(?=.*\d)(?=.*[A-Z])[A-Za-z\d]{8,}$/;
      if (!regex.test(this.password)) {
        this.isValidPassword = false;
        this.showModals = true
        this.infoModal = 'Пароль должен быть латиницей не менее 8 символов и иметь большую букву, одну цифру'
      } else {
        this.isValidPassword = true;
      }

      if (this.isValidPassword) {
        axios
            .post(this.apiUrl + 'api-user/admin-reset-password' + '&auth=' + this.user.username + ':' + this.user.auth_key, {
              user_id: this.userData.id,
              password: this.password
            })
            .then((res) => {
              this.openChange = true
              this.showModals = true
              this.infoModal = 'Пароль изменен, посоветуйте пользователя сбросить кэш'
              console.log(res);
            })
            .catch((error) => {
              console.log(error);
            })
      }

    },
    checkInput(event) {
      const input = event.data
      const allowedChars = /^[a-zA-Z0-9]+$/
      if (!allowedChars.test(input)) {
        event.target.value = event.target.value.replace(/[а-яА-Я]/g, '')
        this.password = event.target.value
      }
    }
  },
  computed: {
    changeColorSelect() {
      if (this.editUser.status == 10) {
        return this.selectClass = 'green'
      } else if (this.editUser.status == 5) {
        return this.selectClass = 'blue'
      } else {
        return this.selectClass = 'red'
      }
    }
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      editUser: this.userData,
      openChange: true,
      isValidPassword: true,
      password: '',
      showModals: false,
      infoModal: '',
      selectClass: '',
      disabledInput: {
        id: true,
        username: true,
        email: true,
      },
      error: ''
    }
  },
}
</script>

<template>
  <div class="user-form">
    <div class="row">
      <div class="col-12 mb-5">
        <h4>Основные данные</h4>
      </div>
    </div>
    <div class="row">
            <div class="col-12 col-xl-6">
                <div class="row justify-content-center justify-content-xl-end mb-4">
                    <div class="col-auto">Добавлен:</div>
                    <div class="col-6">
                        {{ this.formatDate(this.editUser.created_at) }}
                    </div>
                </div>
                <div class="row justify-content-center justify-content-xl-end">
                    <div class="col-3 col-md-2 justify-content-end d-flex">
                      <p class="user-id">
                        ID <span> пользователя:</span>
                      </p>
                    </div>
                    <div class="col-9 col-md-6">
                        <input-with-copy @edit="(text) => {this.editUser.id = text}" :id="'inputId'" :model="this.editUser.id" :text="this.editUser.id" :disabled="this.disabledInput.id"/>
                    </div>
                </div>
                <div class="row justify-content-center justify-content-xl-end mt-3">
                    <div class="col-3 col-md-2 justify-content-end d-flex">
                        <i
                            class="bi bi-pencil"
                            @click.stop="this.disabledInput.username = !this.disabledInput.username"
                        >
                        </i>
                        Логин:
                    </div>
                    <div class="col-9 col-md-6">
                        <input-with-copy @edit="(text) => {this.editUser.username = text}" :id="'inputUsername'" v-model="this.editUser.username" :text="this.editUser.username" :disabled="this.disabledInput.username"/>
                    </div>
                </div>
                <div class="row justify-content-center justify-content-xl-end mt-3">
                    <div class="col-3 col-md-2 justify-content-end d-flex">
                        <i
                            class="bi bi-pencil"
                            @click.stop="this.disabledInput.email = !this.disabledInput.email"
                        >
                        </i>
                        Email:
                    </div>
                    <div class="col-9 col-md-6">
                        <input-with-copy @edit="(text) => {this.editUser.email = text}" :id="'inputEmail'" v-model="this.editUser.email" :text="this.editUser.email" :disabled="this.disabledInput.email"/>
                    </div>
                </div>
           
                <div class="row justify-content-center justify-content-xl-end mt-3">
                    
                    <div class="col-3 col-md-2 justify-content-end d-flex">
                        
                        Статус:
                    </div>
                    <div class="col-9 col-md-6" > 
                    <select   class="select-status" :class="changeColorSelect"  v-model="this.editUser.status">
                        <option class="green" value="10"> Активен</option>
                        <option class="blue" value="5">Ожидает подтверждения</option>
                        <option class="red" value="del" >Удален</option>
                    </select>
                   
                   </div>
                   
                </div>
                
               
                <div class="row justify-content-center justify-content-xl-end mt-3">
                  <div class="col-3 col-md-2 justify-content-end d-flex">
                        
                    </div>
                    <div class="col-9 col-md-6"> 
                        <button v-if="openChange" @click="openChange = false" class="change-btn">Сменить пароль</button>
                        <div class="pass-input" v-else>
                            <input @input="checkInput" class="change-pass" type="text" v-model="password">
                            <button  @click="changePass" class="change-save mt-3">Сохранить</button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
        <div class="row  mt-5">
            <div class="col-10 col-xxl-6 justify-content-end d-flex"> <!-- col-xl-10 col-xxl-6 -->
                <div class="col-12 p-3">{{ this.error }}</div>
            <div class="col-6 d-flex justify-content-end">
                <button class="btn-dark" @click.stop="this.validateForm">Сохранить</button>
            </div>
            </div>
        </div>
    <Transition name="fade" v-if="showModals">
      <div class="example" @click="showModals = false">
        <div class="example__block" @click.stop>
          <p class="example__text">{{ infoModal }}</p>
          <a class="example__link"
             href="https://help.reg.ru/support/hosting/razmeshcheniye-sayta-otobrazheniye-v-brauzere/kak-ochistit-kesh-brauzera#1">Инструкция
            сброса </a>
        </div>
      </div>
    </Transition>
  </div>

</template>

<style scoped lang="scss">
@media (max-width: 576px) {
  .user-id{
    & span{
      display: none;
    }
  }
}

.input-edit{
  max-width: 800px;
  width: 100%;
}

.wrapper-input{
  max-width: 1200px;
  width: 100%;
}

.blue {
  color: blue !important;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.select-status {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);


  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.example {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  z-index: 9999;

  &__link {
    display: block;
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat';
    text-decoration: underline;
  }

  &__block {
    max-width: 300px;
    background: white;
    position: relative;
    width: 100%;
    border-radius: 9px;
    padding: 50px 5px 20px 5px;
  }

  &__text {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 117%;
    text-align: center;
    color: #525665;
    margin-bottom: 10px;
  }
}

.change-btn,
.change-save {
  background-color: #39354B;
  color: #fff;
  border-radius: 14px;
  padding: 5px 25px;
  width: max-content;
}

.change-pass {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.user-form i {
  cursor: pointer;
}

.btn-dark {
  background-color: #39354B;
  color: #fff;
  border-radius: 14px;
  padding: 5px 25px;
}

@media (max-width: 1030px) {
  .cont-wrapper{
    flex-direction: column;
  }

  .wrapper-input{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
</style>