<script setup></script>
<template>
    <img class="order-boss-avatar"
        src="@/assets/img/admin.png"
        alt="photo"
    />
</template>
<style lang="scss" scoped>
.order-boss-avatar {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    object-fit: cover;
}
</style>
