<script>
import UiSwitch from "@/components/ui/UiSwitch.vue";
import "@/styles/icon-style.css";
import router from "@/router/router";

import ExitBtn from "@/components/ui/ExitButton.vue";
import LogInBlock from "@/components/ui/LogInBlock.vue";
import UiSearch from "@/components/ui/UiSearch.vue";
import LogInCard from "@/components/blocks/LogInCard.vue";
import header from "@/mixins/header";
import MassageIcon from "@/components/icons/MassageIcon.vue";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import PromotionIcon from "@/components/icons/PromotionIcon.vue";
import StatIcon from "@/components/icons/StatIcon.vue";
import LogInIcon from "@/components/icons/LogInIcon.vue";
import RocketIcon from "@/components/icons/Rocket.vue"

export default {
    components: {
        StatIcon,
        PromotionIcon,
        HeartIcon,
        MassageIcon,
        LogInCard,
        UiSearch,
        UiSwitch,
        router,
        ExitBtn,
        LogInBlock,
        LogInIcon,
        RocketIcon
    },
    name: "NavHeaderGirl",
    mixins: [header],
    data() {
        return {
            user: this.$store.getters.getUser,
            apiDomine: this.$store.getters.getApiDomine,
            show: false,
            popupPagesShow: false,
            popupPagesShowMobile: false,
            isShowMenu: false,
            menuShow: false,
            list: [],
            menuLinkGirl: [
                {
                    name: "Мои&nbsp;анкеты",
                    img: "item1.svg",
                    url: "/girl/lk",
                },
                /*{
                    name: "Просмотры&nbsp;/&nbsp;отзывы",
                    img: "item2.svg",
                    url: "#",
                },
                {
                    name: "Message",
                    img: "item3.svg",
                    url: "#",
                },
                {
                    name: "Вебкам",
                    img: "item4.svg",
                    url: "#",
                    location: [
                        {
                            name: "Правила трансляции",
                        },
                        {
                            name: "Тарифы",
                        },
                    ],
                },*/
                /*{
                    name: "Продвижение",
                    img: "item5.svg",
                    url: "#",
                },*/
                {
                    name: "Финансы",
                    img: "item6.svg",
                    url: "/girl/billing",
                },
                {
                    name: "Расписание&nbsp;работы",
                    img: "item11.svg",
                    url: "/girl/schedule",
                },
                {
                    name: "Настройка&nbsp;аккаунта",
                    img: "item7.svg",
                    url: "/girl/settings",
                },
                /*{
                    name: "Заказы",
                    img: "item8.svg",
                    url: "#",
                },*/
            ],
        };
    },
    created() {
        this.getCategories();
    },
};
</script>
<template>
    <div class="row d-none d-md-flex justify-content-between">
        <div class="col-2 d-flex align-items-center">
            <router-link to="/"><img src="@/assets/img/logo.svg" class="logo"></router-link>
        </div>
        <div class="col-10">
            <div class="row justify-content-end">
                <!--                <div class="col-auto text-nav">-->
                <!--                     <massage-icon :color="'#fff'" class="me-1"/> <a href="">Message</a></div>-->
                <div class="col-auto text-nav">
                    <router-link :to="{ name: 'promo' }">
                        <span><rocket-icon :color="'#fff'" class="me-1"></rocket-icon>Промо</span>
                    </router-link>
                </div>
                <div class="col-auto text-nav">
                    <heart-icon :color="'#fff'" class="me-1" />
                    <router-link to="/girl/lk">Мои анкеты</router-link>
                </div>
                <div class="col-auto text-nav">
                    <promotion-icon :color="'#fff'" class="me-1" /> <a href="/girl/promotion">Продвижение</a>
                </div>


                <!-- <div class="col-auto text-nav">
                    <stat-icon :color="'#fff'" class="me-1"/> <a href="">Статистика</a></div> -->
                <!-- <div class="col-auto d-flex align-items-center">
                  <ui-switch/>
                </div> -->
                <div class="col-auto text-nav gap-2">
                    <LogInIcon :color="'#fff'" />
                    <ExitBtn />
                </div>
                <div class="col-auto d-flex align-items-center cursor">
                    <div class="header-user__block d-flex align-items-center">
                        <router-link to="/girl/lk">{{user.username}}</router-link>
                        <div v-if="this.user.isLogged" class="header-user__photo" @click.stop="menuShow = true"
                            v-click-outside="menuHide">
                            <div v-if="this.user.isLogged">
                                <img :src="this.apiDomine + '/web/uploads/' + this.user.profile.avatar" alt="user" />
                            </div>
                            <div v-else>
                                <img class="avatar" :src="this.apiDomine + '/web/uploads/avatar/no_photo.jpg'"
                                    alt="user" />
                            </div>
                        </div>
                        <!--girl menu-->
                        <div :class="['header-user__drop-menu', { show: menuShow }]">
                            <ul class="header-user-menu__list">
                                <li v-for="(item, i) in menuLinkGirl" :key="i" @click.stop="openSubmenu(i, item.url)"
                                    :ref="`userMenuLink_${i}`" :class="['header-user-menu__item']">
                                    <div>
                                        <img :src="require(`@/assets/img/anketa/svg2/${item.img}`)" alt="svg" />
                                        <p v-html="item.name"></p>
                                    </div>
                                    <ul class="header-user-menu__submenu">
                                        <li v-for="(item, i) in item.location" :key="i" v-html="item.name"
                                            @click.stop="$router.push(item.url)"></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<style scoped lang="scss">
@import "@/styles/index";

.text-nav {
    display: flex;
    align-items: center;
}

.text-nav a {
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
}

.text-nav img {
    margin-right: 10px;
}

.btn-nav {
    border-radius: 35px;
    background: #72666A;
    color: #fff;
    cursor: pointer;
}

.btn-nav:hover {
    border-radius: 35px;
    background: #72666A;
    color: #fff;
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

a {
    color: #fff;
}

.header-user {
    position: relative;

    &__photo {
        margin-left: 30px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
    }

    &__drop-menu {
        background-color: #fff;
        border-radius: 12px;
        padding: 10px;

        position: absolute;
        right: 0;
        display: block;
        width: 250px;
        opacity: 0;
        visibility: hidden;
        top: 150%;
        transition: 0.3s;
        border: 1px solid #72666A;
        box-shadow: 2px 2px 5px 2px rgb(0, 0, 0, .5);

        &.show {
            opacity: 1;
            visibility: visible;
            top: 100%;
        }

        &--mobile {
            &.show {
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                z-index: 50;

                ul {
                    padding-left: 1em;
                    overflow-y: auto;
                }
            }
        }
    }

    &__block {
        position: relative;
    }
}

.header-user-menu {
    &__submenu {
        display: none;

        li {
            color: #525665;
            list-style-type: none;
            padding-left: 10px;
            cursor: pointer;
            position: relative;
            @media (max-width: 1023px) {
                text-align: left !important;
                line-height: 100% !important;
            }

            &::before {
                content: "";
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #525665;
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        margin-left: 0;
        margin-bottom: 0;
    }

    &__item {
        margin: 0 0 15px;

        &.is-active {
            p {
                color: #000;
            }

            img {
                opacity: 0.8;
            }

            color: red;
        }

        > div {
            display: flex;
            align-items: center;
            line-height: 120%;
            cursor: pointer;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            padding: 0;
            margin: 0;
            color: #525665;
            font-size: 18px;
            line-height: 100%;
        }

        img {
            margin-right: 15px;
            opacity: 0.4;
        }

        &.is-active {
            .header-user-menu__submenu {
                display: block;
            }
        }
    }
}

.header-exit {
    color: #525665 !important;
    font-size: 18px !important;
}

.menu-burger-button {
    // position: fixed;
    // top: 15px;
    // width: 25px;
    // right: 15px;
    // z-index: 9999;
    div {
        width: 25px;
        height: 3px;
        background-color: #fff;
        border-radius: 100px;
        margin-bottom: 4px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.non-transition {
    transition: none !important;
}

.mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -5px 12px 3px rgba(0, 0, 0, 0.065);
    border-radius: 15px 15px 0 0;
    transition: 0.4s;
    z-index: 99999;
    padding-bottom: 18px;
    height: 50vh;

    .header-user__drop-menu {
        @media (max-width: 1023px) {
            position: fixed;

            &.show {
                overflow-y: scroll;
            }
        }
    }

    @media screen and (max-width: 767px) {
        display: block;
    }

    &.show {
        transform: translateY(0);
    }

    &__touch-box {
        position: relative;
        height: 30px;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 35px;
            height: 5px;
            border-radius: 50px;
            background-color: rgba($color: #000000, $alpha: 0.15);
        }
    }

    ul {
        margin-top: 15px;
        padding: 0;

        li {
            text-align: center;
            margin-bottom: 12px;
        }
    }
}

/*.header {
    background: linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%);
    z-index: 1;
    .header-log-in {
        > div {
            margin-left: 0;
            justify-content: flex-start !important;
        }

        margin-right: auto;
    }

    &__inner {
        position: relative;
        z-index: 2;
        align-items: center;
    }

    &__choice {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    &__city {
        cursor: pointer;
        position: relative;
        border-bottom: solid 1px;
        margin-top: -2px;
        margin-right: 30px;
        color: $title;
        font-size: 16px;
        line-height: 20px;
    }
}

.header-user {
    position: relative;

    &__photo {
        margin-left: 30px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
    }

    &__drop-menu {
        background-color: #fff;
        border-radius: 12px;
        padding: 10px;

        position: absolute;
        right: 0;
        display: block;
        width: 250px;
        opacity: 0;
        visibility: hidden;
        top: 150%;
        transition: 0.3s;

        &.show {
            opacity: 1;
            visibility: visible;
            top: 100%;
        }

        &--mobile {
            &.show {
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                z-index: 50;

                ul {
                    padding-left: 1em;
                    overflow-y: auto;
                }
            }
        }
    }

    &__block {
        position: relative;
    }
}

.header-user-menu {
    &__submenu {
        display: none;

        li {
            color: #525665;
            list-style-type: none;
            padding-left: 10px;
            cursor: pointer;
            position: relative;
            @media (max-width: 1023px) {
                text-align: left !important;
                line-height: 100% !important;
            }

            &::before {
                content: "";
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #525665;
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        margin-left: 0;
        margin-bottom: 0;
    }

    &__item {
        margin: 0 0 15px;

        &.is-active {
            p {
                color: #000;
            }

            img {
                opacity: 0.8;
            }

            color: red;
        }

        > div {
            display: flex;
            align-items: center;
            line-height: 120%;
            cursor: pointer;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            padding: 0;
            margin: 0;
            color: #525665;
            font-size: 18px;
            line-height: 100%;
        }

        img {
            margin-right: 15px;
            opacity: 0.4;
        }

        &.is-active {
            .header-user-menu__submenu {
                display: block;
            }
        }
    }
}

.header-left {
    color: $text-gary;
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;

    span {
        color: $red;
        font-weight: 900;
    }

    a {
        display: inline-block;
        color: $title;
        text-decoration: none;
    }
}

.header-midd {
    .drop-list__list {
        right: 50%;
        transform: translateX(50%);
    }

    &__inner {
        max-width: 260px;
        margin: 0 auto;
    }

    &__choice {
        cursor: pointer;
        width: 100%;
        border-radius: 300px;
        background-color: rgba($white, 0.3);
        font-size: 14px;
        color: $text;
        line-height: 36px;
        padding-left: 20px;
        padding-right: 35px;
        position: relative;
        height: 40px;
        line-height: 40px;
        //max-width: 260px;
        //margin: 0 auto;
        img {
            position: absolute;
            right: 20px;
            top: 15px;
            cursor: pointer;
        }
    }
}

.header-right {
    display: flex;
    align-items: center;
}

.drop-list {
    &__list {
        pointer-events: none;
        position: absolute;

        top: 150%;
        right: 0;
        min-width: 260px;

        opacity: 0;
        visibility: hidden;
        //height: 0;
        transition: 0.3s;

        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        z-index: 10;

        &.show {
            opacity: 1;
            height: auto;
            visibility: visible;
            top: 100%;
            z-index: 50;
            pointer-events: all;
        }

        p {
            padding: 5px 0;
            cursor: pointer;
            transition: 0.3s;
            color: #525665;
            text-align: center;
            font-size: 20px;
            line-height: 130%;
            border-radius: 100px;
            margin-bottom: 5px;

            &:hover {
                background-color: #dedede;
            }
        }
    }

    &__wrap {
        position: relative;
    }
}

.header-reg {
    .header-user {
        display: none;
    }

    .header__city {
        color: #fff;
    }

    .switch__name {
        color: rgba(255, 255, 255, 0.6);

        &.active {
            color: #fff;
        }
    }
}


.menu-burger-button {
    // position: fixed;
    // top: 15px;
    // width: 25px;
    // right: 15px;
    // z-index: 9999;
    div {
        width: 25px;
        height: 3px;
        background-color: #fff;
        border-radius: 100px;
        margin-bottom: 4px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.non-transition {
    transition: none !important;
}

.mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -5px 12px 3px rgba(0, 0, 0, 0.065);
    border-radius: 15px 15px 0 0;
    transition: 0.4s;
    z-index: 99999;
    padding-bottom: 18px;
    height: 50vh;

    .header-user__drop-menu {
        @media (max-width: 1023px) {
            position: fixed;

            &.show {
                overflow-y: scroll;
            }
        }
    }

    @media screen and (max-width: 767px) {
        display: block;
    }

    &.show {
        transform: translateY(0);
    }

    &__touch-box {
        position: relative;
        height: 30px;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 35px;
            height: 5px;
            border-radius: 50px;
            background-color: rgba($color: #000000, $alpha: 0.15);
        }
    }

    ul {
        margin-top: 15px;
        padding: 0;

        li {
            font-weight: 500;
            text-align: center;
            margin-bottom: 12px;
        }
    }
}

.header-desktop {
    display: block;
    @media ($tablet) {
        display: none;
    }
}

.header-mobile {
    display: none;
    background-color: rgba(#333, 0.8);
    @media ($tablet) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        line-height: 50px;
        z-index: 200;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__burger {
        display: flex;
        justify-content: flex-end;
        align-content: center;
    }

    .header-midd__choice {
        background-color: #e7e5e5;
    }

    .header-midd__inner {
        max-width: 100%;
        max-width: 80%;
    }

    .header__choice {
        margin: 0;
    }

    .header-left {
        margin-right: auto;
        display: flex;
        text-align: left;
    }

    .drop-list__list {
        &.show {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            transform: translate(0, 0);
        }
    }
}

.body-backdrop {
    position: fixed;
    backdrop-filter: blur(8px);
    background-color: rgba(#000, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}*/
</style>
