<script>
import VueNextSelect from 'vue-next-select'
import "vue-next-select/dist/index.css";
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/intlTelInput.js';
import 'intl-tel-input/build/js/utils.js';
import axios from "axios";
import UiLoader from "@/components/ui/UiLoader.vue";
import intlTelInput from 'intl-tel-input';
import UploadVideo from "@/components/ui/uploadMedia/UploadVideo.vue";

export default {
    components: {
        UploadVideo,
        UiLoader,
        'vue-select': VueNextSelect,
        intlTelInput,

    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            profile: {
                user_id: '',
                category_id: '',
                name: '',
                age: '',
                height: '',
                breast: '',
                hair_color: '',
                metro: '',
                price_hour_app: '',
                price_two_hour_app: '',
                price_night_app: '',
                price_hour_exit: '',
                price_two_hour_exit: '',
                price_night_exit: '',
                description: '',
                tel: '',
                tg: '',
                city_id: '',
                city: '',
                whatsapp: '',
                currency_id: '',
                checkService: [],
                status_id: '6',
            },
            loadingTel: false,
            errorTel: '',
            intlInput: false,
            intWaInput: false,
            check: false,
            profileStatus: [],
            cities: [],
            categories: [],
            currency: [],
            service: {
                sex:[],
                dop:[],
                bdsm:[],
                massage:[]
            },
            checkService: [],
            metros: [],
            metrosByCity: [],
            isInvalid: false,
            error: '',
            errorHintMetro: '',
            loadingBtn: false,
            hMetro: false,
            metroName: '',
            //////////
            test: [
                'name', 'text', 'test'
            ],
            selectedMoney: {
                id: 1,
                name: 'RUB',
                symbol: 'o'
            },
            choicePayContent: 'out',
        }
    },
    computed: {
        computedCity() {
            let computedArray = this.cities.filter(
                (item) => item.id !== this.profile.city
            );
            this.profile.city_id = this.profile.city.id
            return computedArray;
        },
        computedCategory() {
            let computedArray = this.categories.filter(
                (item) => item.id !== this.profile.category_id
            );
            return computedArray;
        },
        computedMoney() {
            let computedArray = this.currency.filter(
                (item) => item.id !== this.profile.currency_id
            );
            return computedArray;
        },
    },
    methods: {
        setTel(event) {
            const val = event.target.value.replace(/[^0-9]/g, "");
            event.target.value = val
        },
        switching(choice) {
            this.choicePayContent = choice
        },
        testFunction() {

        },

        checkUnic() {
            //проверка телефона на уникальность
            this.errorTel = '';
            if (this.profile.tel.length < 10) {
                return;
            }
            if (!this.intlInput.isValidNumber()) {
                return;
            }

            this.profile.tel = this.intlInput.getNumber();
            this.loadingTel = true
            axios
                .get(this.apiUrl + 'api/check-phone&phone=' + this.profile.tel.slice(1) + '&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    if (response.status) {
                        this.loadingTel = false
                        this.errorTel = '';
                    }
                    this.errorTel = response.data.error
                })
        },
        addWhatsApp() {
            if (this.check) {
                this.profile.whatsapp = this.profile.tel
            } else {
                this.profile.whatsapp = ''
            }
        },
        getAllCategory() {
            axios
                .get(this.apiUrl + 'api/get-categories&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.categories = response.data;
                })
        },
        getAllStatus() {
            axios
                .get(this.apiUrl + 'api/get-profile-status&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.profileStatus = response.data;
                })
        },
        getAllCity() {
            axios
                .get(this.apiUrl + 'api/get-cities&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.cities = response.data;
                })
        },
        getMetroByCityId() {
            axios
                .get(this.apiUrl + 'api/get-metro-by-city-id&auth=' + this.user.username + ':' + this.user.auth_key + '&id=' + this.profile.city_id)
                .then((response) => {
                    this.metrosByCity = response.data;
                    for (let m of this.metrosByCity) {
                        if (m.id === this.profile.metro_id) {
                            this.metroName = m.name
                        }
                    }
                })
        },
        getAllCurrency() {
            axios
                .get(this.apiUrl + 'api/get-currencies&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.currency = response.data;
                })
        },
        getAllService() {
            axios
                .get(this.apiUrl + 'api/get-profile-services&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    for (let d of response.data) {
                        d.checked = false
                        d.description = ''
                        for (let cs of this.profile.checkService) {

                            if (parseInt(cs.service_id) === parseInt(d.id)) {
                                d.checked = true
                                d.price = cs.price
                                if(cs.description == null){
                                    d.description = ''
                                }else {
                                    d.description = cs.description
                                }
                            }
                        }
                        if (d.category_id == 1) {
                            this.service.sex.push(d)
                        }
                        if (d.category_id == 2) {
                            this.service.dop.push(d)
                        }
                        if (d.category_id == 3) {
                            this.service.bdsm.push(d)
                        }
                        if (d.category_id == 4) {
                            this.service.massage.push(d)
                        }
                    }
                })
        },
        hintMetro() {
            if (this.metroName.length > 1) {
                let param = this.user.username + ':' + this.user.auth_key
                    + '&city_id=' + this.profile.city_id
                    + '&str=' + this.metroName
                axios
                    .get(this.apiUrl + 'api-hint/metro&auth=' + param)
                    .then((response) => {
                        if (!response.data.status) {
                            this.errorHintMetro = response.data.error
                        }
                        this.metrosByCity = response.data;
                        this.hMetro = true

                    })
            }
        },
        selectHintMetro(m) {
            this.profile.metro_id = m.id
            this.metroName = m.name
            this.hMetro = false
        },
        validAge() {
            if (this.profile.age < 18) {
                this.isInvalid = true
            } else {
                this.isInvalid = false
            }
        },
        addProfile() {
            this.profile.checkService = [];

            for (let s of this.service.sex) {
                if (s.checked) {
                    this.profile.checkService.push(s)
                }
            }
            for (let s of this.service.bdsm) {
                if (s.checked) {
                    this.profile.checkService.push(s)
                }
            }
            for (let s of this.service.dop) {
                if (s.checked) {
                    this.profile.checkService.push(s)
                }
            }
            for (let s of this.service.massage) {
                if (s.checked) {
                    this.profile.checkService.push(s)
                }
            }
            if(this.isInvalid){
                this.error = 'Возраст не может быть менее 18 лет'
            }
            if (this.media) {
                this.profile.photo = this.media.added
            } else {
                this.profile.photo = []
            }
            this.profile.author_id = this.user.id
        },
    },
    watch: {
        selectedMoney: function (data) {
            this.$emit('changeCurrency', data);
        }
    },
    mounted() {
        this.getAllCategory()
        this.getAllStatus()
        this.getAllCity()
        this.getAllCurrency()
        this.getAllService()

        let option = {
            preferredCountries: ["ru", "us", "ca"],
            autoInsertDialCode: true,
            autoPlaceholder: "aggressive",
            showSelectedDialCode: true,
            separateDialCode: true,
            options: {
                autoInsertDialCode: true,
                autoPlaceholder: "aggressive",
                showSelectedDialCode: true,
                separateDialCode: true
            },

        }
        const input = document.querySelector("#telephone");
        const inputWa = document.querySelector("#whatsApp");
        this.intlInput = intlTelInput(input, option);
        this.intWaInput = intlTelInput(inputWa, option);
    }
}
</script>
<template>
    <!--    первый блок с фото-->

        <div class="row">
            <!--        Загрузка photo-->
            <div class="col-12 col-xxl-4">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xxl-8 mb-3">
                        <div class="foto-block__big load-file-input">
                            <div class="foto-block__big-input load-file-input__field ">
                                <label class="load-file-input__label">
                                    <input type="file" class="load-file-input__input">
                                    <span class="plus"></span>
                                </label>
                            </div>
                            <span class="foto-block__big-descr mt-xxl-3 d-block">Загрузите главную фотографию с&nbsp;пристойным содержанием, на фотографии не должно быть гениталий</span>
                        </div>
                    </div>

                    <div class="col-12 col-xxl-4 col-xl-6">
                        <div class="mb-2 d-flex d-xxl-none yellow-sign load-foto-row">Загрузите дополнительное фото
                        </div>
                        <div class="d-flex flex-xxl-column justify-content-between px-xxl-0">
                            <div class="col-auto">
                                <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                            </div>
                            <div class="col-auto">
                                <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                            </div>
                            <div class="col-auto">
                                <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                            </div>
                            <div class="col-auto">
                                <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                            </div>
                            <div class="col-auto">
                                <button class="foto-btn foto-btn__radius foto-btn__bg foto-btn__lock hover-btn"><span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- mobile switcher -->
                    <div class="col-12 d-block d-xxl-none mt-5">
                        <div class="row input-field-load">
                            <div class="col-12">
                                <div class="row align-items-center justify-content-between">
                                    <div
                                        class="input-field-load__title col-12 col-lg-8 text-center text-lg-start service-title mb-2 mb-lg-4"
                                        v-click-outside="testFunction">Загрузите свой платный контент
                                    </div>
                                    <div class="col-12 col-lg-4 switcher-choice-block">
                                        <div class="switcher-choice">
                                            <div
                                                :class="['switcher-choice__name', {'active' : choicePayContent === 'in'}]"
                                                @click="switching('in')">Bкл
                                            </div>
                                            <span
                                                :class="['switcher-choice__span', {'active' : choicePayContent === 'in'}]"></span>
                                            <div
                                                :class="['switcher-choice__name', {'active' : choicePayContent === 'out'}]"
                                                @click="switching('out')">Выкл
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="choicePayContent === 'in'">
                            <div class="row justify-content-between align-items-center input-field-load__btns">

                                <div class="col-12 col-xl-6 d-flex input-field-load__load justify-content-between mb-4">
                                    <div class="col-auto">
                                        <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="foto-btn foto-btn__radius foto-btn__bg hover-btn"><span></span></button>
                                    </div>
                                </div>

                                <div
                                    class="col-12 col-xl-6 row text-xl-end text-start justify-content-between justify-content-xl-end align-items-center">
                                    <div class="input-field-load__price-text col-7">Назначьте стоимость</div>
                                    <div class="input-field-load__price-input  col-3 mx-0 px-0">
                                        <label class="service-prcie">
                                            <input type="number" v-on:keyup="setTel" class="service-prcie__input">
                                            <span class="service-prcie__rouble" v-html="this.currency.symbol"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 input-field-load__text pt-4 mb-3">
                                Продавайте свой приватный контент безопасно. После оплаты, деньги зачислятся вам на
                                баланс, контент станет доступным в личном кабинете пользователю, тому кто совершил
                                оплату.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--        О себе-->
            <div class="col-12 col-xxl-8 mt-0 mt-lg-4">
                <div class="row">
                    <div class="col-12 col-lg-6 input-field mb-md-2">

                        <div class="input-field__top-input mb-3 ml-lg-0">
                            <div class="row mb-2 justify-content-center">
                                <div class="col-12 col-lg-4">
                                    <div class="input-block__name text-start text-lg-end">Имя<sup>*</sup></div>
                                </div>
                                <div class="col-12 col-lg-8">
                                    <label class="input-block__label">
                                        <input class="input-block__input" type="text" v-model="this.profile.name"
                                               placeholder="">
                                    </label>
                                </div>
                            </div>
                            <!-- !!!!!! phone -->
                            <div class="row mb-3 mb-lg-2 justify-content-center">
                                <div class="col-12 col-lg-4">
                                    <div class="input-block__name text-start text-lg-end">Телефон<sup>*</sup></div>
                                </div>
                                <div class="col-12 col-lg-8">
                                    <input @input="checkUnic" type="tel" class="form-control input-block__input"
                                           id="telephone"
                                           v-model="this.profile.tel" :disabled="loadingTel">
                                    <ui-loader :show="loadingTel" :preset="'input'"/>
                                </div>
                                <div class="col-11 mt-1 error-text">{{ this.errorTel }}</div>
                            </div>
                        </div>

                        <div class="row input-block-messengers mb-3  ">
                            <div class="col-12 col-lg-5">
                                <label class="input-block-messengers__label">
                                    <span class="input-block-messengers__name input-block__name">Мессенджеры<sup>*</sup></span>
                                    <input type="checkbox" v-model="this.check" @change="addWhatsApp"
                                           class="form-check-input">
                                </label>
                                <span class="input-block-messengers__info">такой же как номер телефона</span>
                            </div>
                            <div class="col-12 col-lg-7">
                                <div class="row">
                                    <div class="col-12 col-xxl-10 mb-2  ms-auto w-100 px-0">
                                        <div class="input-field__block input-block">
                                            <label class="input-block__label flex-lg-row flex-row-reverse">
                                                <input @input="checkUnic" type="tel"
                                                       class="form-control input-block__input" id="whatsApp"
                                                       v-model="this.profile.whatsapp">
                                                <span class="input-block__ico"><img src="@/assets/img/wh.png"
                                                                                    alt="whatsApp"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-xxl-10 mb-2 mb-md-0 ms-auto w-100 px-0">
                                        <div class="input-field__block input-block">
                                            <label class="input-block__label flex-lg-row flex-row-reverse">
                                                <input type="text" class="input-block__input ps-1"
                                                       v-model="this.profile.tg">
                                                <span class="input-block__ico"><img src="@/assets/img/t.png"
                                                                                    alt="whatsApp"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div class="col-12 col-lg-6 input-field">
                        <div class="row">
                            <div class="col-12 input-field__block select-block  mb-2">
                                <div class="row">
                                    <div class="col-12 col-md-7">
                                        <div class="select-block__text text-start text-md-end input-block__name">Город
                                            показа анкеты<sup class="text--bs-danger">*</sup></div>
                                    </div>
                                    <div class="col-12 col-md-5">
                                        <vue-select
                                            v-model="this.profile.city"
                                            :options="computedCity"
                                            @change="this.getMetroByCityId"
                                            close-on-select
                                            placeholder=""
                                            label-by="name"
                                            class="w-100"
                                        />
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 input-field__block select-block mb-2">
                                <div class="row">
                                    <div class="col-12 col-md-7">
                                        <div class="select-block__text text-start text-md-end d-felx input-block__name">
                                            Выберите&nbsp;категорию показа&nbsp;анкеты<sup>*</sup></div>
                                    </div>
                                    <div class="col-12 col-md-5">
                                        <vue-select
                                            v-model="this.profile.status_id"
                                            :options="computedCategory"
                                            close-on-select
                                            placeholder=""
                                            label-by="name"
                                            class="w-100"
                                        />
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 input-field__block select-block">
                                <div class="row">
                                    <div class="col-12 col-md-7">
                                        <div class="select-block__text text-start text-md-end d-felx input-block__name">
                                            Выберите&nbsp;валюту, с&nbsp;которой&nbsp;вы&nbsp;работаете<sup>*</sup>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-5">
                                        <vue-select
                                            v-model="this.profile.currency_id"
                                            :options="computedMoney"
                                            close-on-select
                                            label-by="name"
                                            placeholder=""
                                            class="w-100"

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- desktop switcher -->
                    <!--                    <div class="col-12 d-none d-xxl-block">
                                            <div class="row input-field-load">
                                                <div class="col-12">
                                                    <div class="row align-items-center justify-content-between">
                                                        <div
                                                            class="input-field-load__title col-12 col-lg-8 text-center text-lg-start service-title"
                                                            v-click-outside="testFunction">Загрузите свой платный контент
                                                        </div>
                                                        <div class="col-12 col-lg-4 switcher-choice-block">
                                                            <div class="switcher-choice">
                                                                <div
                                                                    :class="['switcher-choice__name', {'active' : choicePayContent === 'in'}]"
                                                                    @click="switching('in')">Bкл
                                                                </div>
                                                                <span
                                                                    :class="['switcher-choice__span', {'active' : choicePayContent === 'in'}]"></span>
                                                                <div
                                                                    :class="['switcher-choice__name', {'active' : choicePayContent === 'out'}]"
                                                                    @click="switching('out')">Выкл
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="choicePayContent === 'in'">
                                                <div class="row justify-content-between align-items-center input-field-load__btns">
                                                    <div class="col-12 col-xl-6 d-flex input-field-load__load m-0 p-0">
                                                        <div class="col-auto">
                                                            <button class="foto-btn foto-btn__radius foto-btn__bg"><span></span></button>
                                                        </div>
                                                        <div class="col-auto">
                                                            <button class="foto-btn foto-btn__radius foto-btn__bg"><span></span></button>
                                                        </div>
                                                        <div class="col-auto">
                                                            <button class="foto-btn foto-btn__radius foto-btn__bg"><span></span></button>
                                                        </div>
                                                        <div class="col-auto">
                                                            <button class="foto-btn foto-btn__radius foto-btn__bg"><span></span></button>
                                                        </div>
                                                        <div class="col-auto">
                                                            <button class="foto-btn foto-btn__radius foto-btn__bg"><span></span></button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-12 col-xl-6 row text-xl-end text-start justify-content-between justify-content-xl-end align-items-center">
                                                        <div class="input-field-load__price-text col-7">Назначьте стоимость</div>
                                                        <div class="input-field-load__price-input  col-3 mx-0 px-0">
                                                            <label class="service-prcie">
                                                                <input type="number" v-on:keyup="setTel" class="service-prcie__input">
                                                                <span class="service-prcie__rouble" v-html="selectedMoney.symbol"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 input-field-load__text pt-4">
                                                    Продавайте свой приватный контент безопасно. После оплаты, деньги зачислятся вам на
                                                    баланс, контент станет доступным в личном кабинете пользователю, тому кто совершил
                                                    оплату.
                                                </div>
                                            </div>
                                        </div>-->
                    <div class="col-12 mt-2">
                        Описание
                        <textarea class="w-100 h-100 p-2" v-model="this.profile.description">
              </textarea>
                    </div>
                </div>
            </div>
        </div>
    <!--    Второй блок с видео-->
    <div class="row video-load my-4">
        <!--        Загрузка видео-->
        <!--        <div class="col-12 col-xl-4 mb-2 mb-xl-0 order-2 order-xl-1 my-4 my-lg-0">
                    <div class="video-load-input">
                        <div class="video-load-input__title service-title my-3">Видеовизитка</div>
                        <div class="load-file-input">
                            <div class="load-file-input__field load-video-file">
                                <label class="load-file-input__label">
                                    <input type="file" class="load-file-input__input">
                                    <span class="plus"></span>
                                </label>
                            </div>
                            <div class="col-12 my-3 px-0 d-md-none d-md-none">
                                <div class="video-price__descr">Загрузите красивое видео вертикальной ориентации с&nbsp;пристойным
                                    содержанием. Длительность видео должно быть не более 1 минуты. На&nbsp;видео не должно быть
                                    гениталий
                                </div>

                            </div>
                        </div>
                    </div>
                </div>-->

        <!--  Видео и личные параметры-->
        <div class="row mt-5">
            <div class="col-11 col-md-4 prof-title">
                <div class="video-load-input__title service-title my-3">Видеовизитка</div>
                <upload-video :video-name="this.videoName"
                              @video="(video) => {this.profile.video = video;}"/>
            </div>
            <div class="col-11 col-md-8">
                <div class="video-load-input__title service-title my-3">О себе</div>
                <div class="row">
                    <div class="col-12 mt-2 my-desc position-relative">
                        <span>Метро, если оно есть:</span>
                        <span class="my-desc_line"></span>
                        <input type="text" @input="hintMetro" v-model="this.metroName">
                        <div class="hint-metro" v-if="hMetro">
                            {{ this.errorHintMetro }}
                            <ul>
                                <li class="cursor" @click="selectHintMetro(m)" v-for="m in this.metrosByCity">
                                    {{m.name}}
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="col-12 mt-2 my-desc">
                        <span>Возраст:</span>
                        <span class="my-desc_line"></span>
                        <input @input="validAge" :class="isInvalid ? 'invalid-input': ' input'"
                               type="number" v-model="this.profile.age">
                    </div>
                    <div class="col-12 mt-2 my-desc">
                        <span>Рост:</span>
                        <span class="my-desc_line"></span>
                        <input type="number" v-model="this.profile.height">
                    </div>
                    <div class="col-12 mt-2 my-desc">
                        <span>Размер груди:</span>
                        <span class="my-desc_line"></span>
                        <input type="number" v-model="this.profile.breast">
                    </div>
                    <div class="col-12 mt-2 my-desc">
                        <span>Цвет волос:</span>
                        <span class="my-desc_line"></span>
                        <input type="text" v-model="this.profile.hair_color">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-4">
                        <div class="video-price row">
                            <!-- desktop-version -->
                            <div class="video-price__table d-none d-md-block">
                                <div class="video-price__head d-flex justify-content-between ">
                                    <div>Время</div>
                                    <div>Апартаменты</div>
                                    <div>Выезд</div>
                                </div>
                                <div class="video-price__body row justify-content-between">
                                    <div class="video-price__row d-flex justify-content-between align-items-center">
                                        <div class="video-price__item">
                                            <div class="video-price__item-name">За&nbsp;1 час</div>
                                        </div>
                                        <div class="video-price__strip"></div>
                                        <div class="video-price__item video-price__item-pos">
                                            <label class="service-prcie">
                                                <input type="number" class="service-prcie__input"
                                                       v-model="this.profile.price_hour_app">
                                                <span class="service-prcie__rouble">{{ this.currency.symbol }}</span>
                                            </label>
                                        </div>
                                        <div class="video-price__strip"></div>
                                        <div class="video-price__item">
                                            <label class="service-prcie">
                                                <input type="number" class="service-prcie__input"
                                                       v-model="this.profile.price_hour_exit">
                                                <span class="service-prcie__rouble">{{ this.currency.symbol }}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="video-price__row d-flex justify-content-between align-items-center">
                                        <div class="video-price__item">
                                            <div class="video-price__item-name">За&nbsp;2 час</div>
                                        </div>
                                        <div class="video-price__strip"></div>
                                        <div class="video-price__item video-price__item-pos">
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_two_hour_app"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                        <div class="video-price__strip"></div>
                                        <div class="video-price__item">
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_two_hour_exit"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="video-price__row d-flex justify-content-between align-items-center">
                                        <div class="video-price__item">
                                            <div class="video-price__item-name">Ночь</div>
                                        </div>
                                        <div class="video-price__strip"></div>
                                        <div class="video-price__item video-price__item-pos">
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_night_app"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                        <div class="video-price__strip"></div>
                                        <div class="video-price__item">
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_night_exit"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- mobile-version -->
                            <div class="video-price__table  px-0 d-flex d-md-none justify-content-center">

                                <div class="video-price__item mb-3">
                                    <div class="video-price__head d-flex justify-content-between ">
                                        <div>Время</div>
                                        <div>За&nbsp;1 час</div>
                                    </div>
                                    <div class="video-price__body">
                                        <div
                                            class="video-price__item video-price__item-pos d-flex align-items-center justify-content-between">
                                            <div>Апартаменты</div>
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_hour_app"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                        <div
                                            class="video-price__item video-price__item-pos d-flex align-items-center justify-content-between">
                                            <div>Выезд</div>
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_hour_exit"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="video-price__item mb-3">
                                    <div class="video-price__head d-flex justify-content-between ">
                                        <div>Время</div>
                                        <div>За&nbsp;2 час</div>
                                    </div>
                                    <div class="video-price__body">
                                        <div
                                            class="video-price__item video-price__item-pos d-flex align-items-center justify-content-between">
                                            <div>Апартаменты</div>
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_two_hour_app"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                        <div
                                            class="video-price__item video-price__item-pos d-flex align-items-center justify-content-between">
                                            <div>Выезд</div>
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_two_hour_exit"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="video-price__item">
                                    <div class="video-price__head d-flex justify-content-between ">
                                        <div>Время</div>
                                        <div>Ночь</div>
                                    </div>
                                    <div class="video-price__body">
                                        <div
                                            class="video-price__item video-price__item-pos d-flex align-items-center justify-content-between">
                                            <div>Апартаменты</div>
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_night_app"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                        <div
                                            class="video-price__item video-price__item-pos d-flex align-items-center justify-content-between">
                                            <div>Выезд</div>
                                            <label class="service-prcie">
                                                <input type="number" v-model="this.profile.price_night_exit"
                                                       class="service-prcie__input">
                                                <span class="service-prcie__rouble">{{ currency.symbol }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Услуги -->
        <div class="row mt-5">
            <div class="col-12 text-center prof-title">
                Услуги
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 col-md-3">
                <div class="service-title">Секс</div>
                <div v-for="item in service.sex">
                    <div class="form-check d-flex">
                        <div class="mt-2 p-0 w-100 d-flex justify-content-between">
                            <div class="">
                                <input class="form-check-input" type="checkbox"
                                       v-bind:value="item" v-model="item.checked"
                                       :id="item.id">
                                <label class="form-check-label service-text" for="flexCheckDefault">
                                    {{ item.name }}
                                </label>
                            </div>
                            <input class="check-input" type="number" v-model="item.price" placeholder="0">
                        </div>
                    </div>
                    <textarea class="w-100 mt-1" v-model="item.description" placeholder="Комментарий к услуге"></textarea>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="service-title">Дополнительно</div>
                <div v-for="item in service.dop">
                    <div class="form-check d-flex">
                        <div class="mt-2 p-0 w-100 d-flex justify-content-between">
                            <div class="">
                                <input class="form-check-input" type="checkbox"
                                       v-bind:value="item" v-model="item.checked"
                                       :id="item.id">
                                <label class="form-check-label service-text" for="flexCheckDefault">
                                    {{ item.name }}
                                </label>
                            </div>
                            <input class="check-input" type="number" v-model="item.price" placeholder="0">
                        </div>
                    </div>
                    <textarea class="w-100 mt-1" v-model="item.description" placeholder="Комментарий к услуге"></textarea>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="service-title">BDSM</div>
                <div v-for="item in service.bdsm">
                    <div class="form-check d-flex">
                        <div class="mt-2 p-0 w-100 d-flex justify-content-between">
                            <div class="">
                                <input class="form-check-input" type="checkbox"
                                       v-bind:value="item" v-model="item.checked"
                                       :id="item.id">
                                <label class="form-check-label service-text" for="flexCheckDefault">
                                    {{ item.name }}
                                </label>
                            </div>
                            <input class="check-input" type="number" v-model="item.price" placeholder="0">
                        </div>
                    </div>
                    <textarea class="w-100 mt-1" v-model="item.description" placeholder="Комментарий к услуге"></textarea>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="service-title">Массаж</div>
                <div v-for="item in service.massage">
                    <div class="form-check d-flex">
                        <div class="mt-2 p-0 w-100 d-flex justify-content-between">
                            <div class="">
                                <input class="form-check-input" type="checkbox"
                                       v-bind:value="item" v-model="item.checked"
                                       :id="item.id">
                                <label class="form-check-label service-text" for="flexCheckDefault">
                                    {{ item.name }}
                                </label>
                            </div>
                            <input class="check-input" type="number" v-model="item.price" placeholder="0">
                        </div>
                    </div>
                    <textarea class="w-100 mt-1" v-model="item.description" placeholder="Комментарий к услуге"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="account-btn">
        <button class=" hover-btn">Удалить</button>
        <button class=" hover-btn" @click="addProfile">Сохранить</button>
    </div>
</template>


<style scoped lang="scss">
.plus {
    display: block;
    width: 55px;
    height: 55px;
    border-radius: 55%;
    background-color: #CFC4C2;
    position: absolute;
    transition: 0.3s;
    @media (max-width: 575px) {
        width: 13px;
        height: 13px;
    }

    &::after, &::before {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        width: 9px;
        background-color: #fff;
        left: 50%;
        top: 50%;
        @media (max-width: 575px) {
            width: 4px;
        }
    }

    &::after {
        content: '';
        transform: translate(-50%, -50%);
    }

    &:before {
        content: '';
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.foto-btn {
    cursor: pointer;
    width: 80px;
    height: 80px;
    color: #fff;
    display: inline-block;
    position: relative;
    @media (max-width: 575px) {
        width: 35px;
        height: 35px;
    }

    &:hover span {
        background-color: rgba(#CFC4C2, 0.5);
    }

    span {
        display: block;
        width: 30px;
        height: 30px;
        background-color: rgba(#CFC4C2, 0.1);
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;

        &::after, &::before {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            width: 9px;
            background-color: #fff;
            left: 50%;
            top: 50%;
            @media (max-width: 767px) {
                width: 5px;
            }
        }

        &::after {
            content: '';
            transform: translate(-50%, -50%);
        }

        &:before {
            content: '';
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    &__radius {
        border-radius: 50%;
        overflow: hidden;
    }

    &__bg {
        background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
    }

    &__img {
        background-image: url('@/assets/img/slider-girls/item4.webp');
        background-position: 100% 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            backdrop-filter: blur(5px);
        }

    }

    &__lock {
        &::before {
            content: '';
            position: absolute;
            display: block;
            background-image: url('@/assets/img/lock.svg');
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 5;
            width: 10px;
            height: 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.foto-block-box {
    flex: 0 0 38%;
    max-width: 38%;
    padding-right: 3%;
    display: flex;

    &__text {
        position: relative;
        margin-top: 15px;
        padding-left: 30px;
        color: #525665;
        font-size: 14px;
        line-height: 100%;

        &::after {
            content: '';
            background-image: url('@/assets/img/attention-yellow.svg');
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 0;
            width: 25px;
            padding-bottom: 25px;
        }
    }

    &__small {
        display: flex;
        flex-direction: column;

        button + button {
            margin-top: 10px;
        }
    }

    &__big {
        //max-width: 280px;
        //margin-right: 20px;
    }

    &__flex {
        display: flex;
        margin-left: auto;
    }

}

.input-block {
    &__input {
        border: solid 1px #BDC1D1;
        border-radius: 10px;
        padding: 0 15px;
        color: #333;
        height: 30px;
        line-height: 30px;
        width: 100%;
    }

    &__label {
        display: flex;
    }

    &__ico {
        flex: 0 0 25px;
        max-width: 25px;
    }

    &__name {
        color: #525665;
        text-align: right;
        font-size: 14px;
        line-height: 120%;
        // margin-right: 15px;
        position: relative;

        sup {
            color: #FF4032;
            //*font-size: 1em;
        }
    }

}

.input-block-messengers {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex: 0 0 100%;
    //max-width: 100%;
    @media(max-width: 767px) {
        margin: 0 auto !important;
    }

    > div {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        @media(max-width: 767px) {
            flex-direction: row;
            align-items: center;
            margin: 0 auto 20px;
            max-width: 100%;
            justify-content: center;
        }
    }

    @media (max-width: 991px) {
        max-width: 80%;
        margin: 0 auto;
    }
    @media(max-width: 575px) {
        max-width: 100%;
    }

    &__block {
        display: flex;
        align-items: center;
    }

    &__input {
        position: absolute;
        appearance: none;
    }

    &__label {
        display: flex;
        align-content: center;
        cursor: pointer;
        margin-left: auto;
        @media(max-width: 767px) {
            margin-left: 0;
        }
    }

    &__info {
        color: #BDC1D1;
        font-size: 10px;
        line-height: 14px;
        max-width: 140px;
        display: block;
        padding-top: 5px;
        margin-left: auto;
        @media(max-width: 767px) {
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
        }
    }

    &__checked {
        display: block;
        width: 16px;
        flex: 0 0 16px;
        max-width: 16px;
        margin: 0 10px 0 0;
        height: 16px;
        border: solid 1px #BDC1D1;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
    }

    &__input:checked ~ &__checked {
        &:after {
            content: '';
            background-image: url('@/assets/img/check.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
    }
}

.input-field {

    &__top-input {
        @media(max-width: 991px) {
            max-width: 70%;
            margin: 0 auto !important;
        }
        @media (max-width: 575px) {
            max-width: 100%;
        }
    }

    &__textarea {
        margin: 15px 0 30px;

        textarea {
            resize: none;
            width: 100%;
            border: solid 1px #BDC1D1;
            border-radius: 12px;
            padding: 10px;
            height: 125px;

            &::placeholder {
                color: #BDC1D1;
            }
        }
    }
}

.input-field {
    .vue-select {
        position: relative;
        border: solid 1px #BDC1D1;
        border-radius: 10px;
        padding: 0 15px;
        color: #333;
        height: 30px;
        line-height: 23px;
        font-size: 16px;

        .vue-input {
            input {
                &::placeholder {
                    color: #201F36;
                }
            }
        }

        .vue-select-header .icon.loading, .vue-select-header .icon.arrow-downward {
            border-width: none;
            border-style: none;
            border-color: none;
            background-image: url('@/assets/img/select-arrow.svg');
            width: 10px;
            height: 5px;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .vue-dropdown {
        border-radius: 10px !important;
        border: solid 1px #BDC1D1;
        // border-top: transparent;
        z-index: 10;
        position: absolute;

        li {
            background-color: transparent;
            margin-bottom: 5px;
            padding: 5px;
            border-radius: 5px;
            max-width: 100%;
            transition: 0.3s;

            &:hover {
                background-color: #dedede;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .vue-dropdown-item.selected.highlighted {
        background-color: #bbbbbb;
    }

    .vue-select[aria-expanded='true'].direction-top {
        border-radius: 10px;
    }

    .vue-select[aria-expanded='true'].direction-bottom {
        border-radius: 10px;
    }

}

.input-field-load {

    &__title {
        margin: 10px 0;
    }

    &__load {
        margin-left: 0;
        padding-left: 0;
        @media(max-width: 991px) {
            justify-content: center;
            margin-bottom: 20px;
        }

        div {
            margin: 0 2px;
        }
    }

    &__text {
        color: #525665;
        font-size: 14px;
        line-height: 120%;
    }

    &__price-text {
        font-size: 14px;
        @media(max-width: 575px) {

        }
    }
}

//----new style
.foto-block {

    &__left {
        flex: 0 0 280px;
        max-width: 280px;
        @media (max-width: 1399px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &__right {
        @media (max-width: 1399px) {
            display: flex;
        }
    }

    &__big {
        @media(max-width: 1399px) {
            display: flex;
        }
    }

    &__big-input {
        position: relative;
        @media (max-width: 1399px) {
            flex: 0 0 280px;
            max-width: 280px;
        }
        @media (max-width: 575px) {
            flex: 0 0 70px;
            max-width: 70px;
            height: 115px !important;
        }

        .plus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__big-descr {
        position: relative;
        color: #525665;
        font-size: 14px;
        line-height: 120%;
        padding-left: 30px;

        &:after {
            content: '';
            background-image: url('@/assets/img/attention-yellow.svg');
            display: block;
            width: 25px;
            padding-bottom: 25px;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    sup {
        color: red;
    }

    &__wrap {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: 85%;
        @media (max-width: 1399px) {
            flex-wrap: wrap;
            max-width: 100%;
        }
    }

    &__small-bg {
        button {
            background-image: url('@/assets/img/slider-girls/item1.webp');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            z-index: 100;

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                margin: 0;
                backdrop-filter: blur(5px);
                z-index: 2;
                width: 100%;
                height: 100%;
            }

            span {
                position: absolute;
                z-index: 5;

                &::after {
                    display: none;
                }

                &::before {
                    content: '';
                    background-image: url('@/assets/img/lock.svg');
                    width: 9px;
                    height: 10px;
                    display: block;
                    background-position: center;
                    background-size: contain;
                    background-color: none;
                }
            }
        }
    }
}

.load-file-input {

    min-height: 250px;
    @media (max-width: 575px) {
        min-height: auto;
    }

    &__field {
        position: relative;
        border-radius: 24px;
        background: var(--gradient1, linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%));
        overflow: hidden;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        cursor: pointer;
        flex: 0 0 280px;
        max-width: 280px;
        height: 400px;

        &:hover .plus {
            background-color: darken(#CFC4C2, 15%);
        }

        @media (max-width: 991px) {
            flex: 0 0 190px;
            max-width: 190px;
            height: 300px;
        }
        @media (max-width: 575px) {
            border-radius: 10px;
            flex: 0 0 70px;
            max-width: 70px;
            //width: 190px;
            height: 115px;
        }
    }

    &__label {
        display: block;
        height: 100%;
    }

    &__input {
        background: red;
        position: absolute;
        opacity: 0;
        //appearance: 0;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .plus {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.switcher-choice-block {
    display: flex;
}

.switcher-choice {
    display: flex;
    align-items: center;
    margin-left: auto;
    @media(max-width: 991px) {
        margin: 0 auto 15px;
    }

    &__span {
        width: 25px;
        height: 14px;
        border-radius: 300px;
        margin: 0 10px;
        position: relative;
        background: #8E7F7D;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;

        &::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #D9D9D9;
            position: absolute;
            top: 2px;
            left: calc(100% - 2px);
            transform: translateX(-100%);
            transition: 0.3s;
        }

        &.active {
            &::after {
                left: 2px;
                transform: translateX(0);

            }
        }
    }

    &__name {
        color: rgba(#000, 0.6);
        font-size: 14px;
        line-height: 120%;
        font-weight: 500;
        display: inline-block;
        cursor: pointer;
        position: relative;
        border-bottom: solid 1px transparent;
        text-transform: capitalize;

        &.active {
            border-bottom: solid 1px;
            color: #333;
        }

    }
}

.foto-block {
    &__bullet {
        display: flex;
        flex-direction: column;

        div + div {
            margin-top: 5px;
            @media(max-width: 1399px) {
                margin: 0;
                //margin-left: 1%;
            }
        }

        @media(max-width: 1399px) {
            flex-direction: row;
            justify-content: space-evenly;
            max-width: 100%;
            width: 70% !important;
            margin: 0 auto !important;
        }
    }
}

.yellow-sign {
    position: relative;
    display: flex;

    &:before {
        content: '';
        display: block;
        background-image: url('@/assets/img/attention-yellow.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
    }
}

.load-foto-row {
    font-weight: 700;
    font-size: 14px;
}

.service-title {
    background-color: #8E7F7D;
    color: #FFF;
    font-size: 14px;
    line-height: 40px;
    border-radius: 9px;
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
}

.form-check-label{
    font-size: 14px;
}

input {
    border: solid 1px #BDC1D1;
    border-radius: 10px;
    padding: 0 15px;
    color: #333;
    height: 30px;
    line-height: 30px;
    width: 100%;
}

.invalid-input {
    border: 1px solid red !important;
}

input.check {
    width: 16px;
    max-width: 16px;
    height: 16px;
    border: solid 1px #BDC1D1;
    border-radius: 4px;
    cursor: pointer;
}

select {
    border: solid 1px #BDC1D1;
    border-radius: 10px;
    padding: 0 15px;
    color: #333;
    height: 30px;
    line-height: 30px;
    width: 100%;
    cursor: pointer;
}

textarea {
    border: solid 1px #BDC1D1;
    border-radius: 10px;
    padding: 0 15px;
    color: #333;
}

.sm-text {
    font-size: 12px;
}

.my-desc {
    display: flex;
    justify-content: space-between;

    input {
        border: solid 1px #BDC1D1;
        border-radius: 100px;
        height: 26px;
        line-height: 24px;
        padding: 0 10px;
        width: 115px;
    }

    select {
        border: solid 1px #BDC1D1;
        border-radius: 100px;
        height: 26px;
        line-height: 24px;
        padding: 0 10px;
        width: 115px;
    }

    &_line {
        height: 1px;
        background: rgba(189, 193, 209, 0.31);
        flex-grow: 1;
        display: block;
        margin: 12px 5px;
    }
}

i {
    cursor: pointer;
}

.video-price {
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;

    &__head {
        background: #EEE8E6;
        color: #312827;
        font-size: 16px;
        font-weight: 700;
        padding: 0 35px;
        margin: 0 -11px;
    }

    &__body {
        background: #FBF8F7;
        padding: 25px 25px;
    }

    &__item {
        height: 50px;
        line-height: 50px;
        @media(max-width: 767px) {
            height: auto;
            border-radius: 10px;
            overflow: hidden;
        }

        input {
            background-color: #fff;
        }
    }

    &__item-name {
        width: 65px;
    }

    &__strip {
        background-color: rgba(#BDC1D1, 0.31);
        flex-grow: 1;
        height: 1px;
        display: block;
        margin: 0 10px;
    }

    &__descr {
        position: relative;
        display: block;
        padding-left: 30px;

        &::after {
            content: "";
            background-image: url('@/assets/img/attention-yellow.svg');
            display: block;
            width: 25px;
            padding-bottom: 25px;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.btn-dark {
    background-color: #39354B;
    color: #fff;
    border-radius: 14px;
}

.prof-title {
    font-size: 18px;
    color: #2D2F3A;
    font-weight: 600;
}

.mu-plusbox {
    border-radius: 14px;
    background: #2C2F3C !important;
}

.form-check-input {
    width: 20px;
    height: 20px;
    padding: 0;
}

.form-check {
    margin-bottom: 0;
    min-height: 0;
}


.form-check-input:checked {
    background-color: #fff;
    border-color: #BDC1D1;
}

.form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%238E7F7D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e);
}

.form-check-input:focus {
    box-shadow: none;
}

.check-input {
    border: solid 1px #BDC1D1;
    border-radius: 50px;
    line-height: 26px;
    font-size: 14px;
    padding: 0 10px 0 10px;
    max-width: 67px;
}

.error-text {
    color: #980000;
    font-size: 16px;
}

.loading-tel {
    position: absolute;
    right: 51px;
    top: 12px;
    max-width: 55px;
}

.service-title {
    background-color: #8E7F7D;
    color: #FFF;
    font-size: 14px;
    line-height: 40px;
    border-radius: 9px;
    padding: 0 0 0 25px;
    display: flex;
    align-items: center;
}

.hint-metro {
    position: absolute;
    top: 30px;
    right: -52px;
    width: 180px;
    background: #fff;
    border: solid 1px #BDC1D1;
    border-radius: 10px;
    padding: 0 15px;
    color: #333;

}

.cursor {
    cursor: pointer;
}

.account-btn {
    display: flex;
    margin-left: auto;

    button {
        color: #FFF;
        font-size: 10px;
        font-weight: 700;
        line-height: normal;
        // line-height: 30px;
        // height: 30px;
        padding: 10px 0;
        width: 95px;
        text-align: center;
        border-radius: 10px;
        transition: 0.3;
        &:HOVER {
            //opacity: 0.8;
            box-shadow: 0 0 4px rgba(#000, 0.5);
        }
        &:first-child {
            margin-left: auto;
            background-color: #978B8A;
        }
        &:last-child {
            background-color: #39354B;
        }
    }
    button + button {
        margin-left: 20px;
    }
    // &:first-child {
    // 	button {
    // 		background-color: #978B8A;
    // 	}
    // }
    // &:last-child {}
}
</style>