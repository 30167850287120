<script>
import {defineComponent} from "vue";
import getApi from "@/mixins/getApi";
import CloseX from "@/components/ui/CloseX.vue";
import SwipingMenu from "@/components/ui/SwipingMenu.vue";
import ModalDialog from "@/components/ui/common/ModalDialog.vue";
import axios from "axios";

export default defineComponent({
    name: 'UiSearch',
    components: {ModalDialog, SwipingMenu, CloseX},
    emits: ['close', 'closeOutside'],
    mixins: [getApi],
    props: {
        position: {
            type: String,
            default: 'top'
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        btnClick(e) {
            if (this.isMobile) {
                this.mobileStep = 2;
            } else {
                let btns = document.querySelectorAll('.cat-btn')
                for (let b of btns) {
                    b.classList.remove('active')
                }
                e.target.classList.add('active')
                this.selected.category_id = parseInt(e.target.dataset.cat)
            }
        },
        btnClickStep2(cat) {
            this.mobileSelectedCat = this.service[cat]
            this.mobileStep = 3
        },
        addInfoService(e, n) {
            this.selected.service_id = parseInt(e.target.dataset.ser)
            this.mobileStep = 1
            this.showMobile = false
            this.$store.commit('setCompilationService', n)
            this.compilationService = this.$store.getters.getCompilationService
            this.$emit('close');
            window.open(`/compilation-service/${n.id}`)
        },
        stepPrev() {
            if (this.mobileStep > 1) {
                this.mobileStep = this.mobileStep - 1
            }
        },
        hideSearchPage(event){
          if(!event.target.closest('.text-nav')){
            this.$emit('closeOutside', false)
          }
        },
      hideMobileDialog(e){
        if(!e.target.closest('.dialog-content') && e.target.localName !== 'button'){
          this.$emit('closeOutside', false)
        }
      }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            compilationService: this.$store.getters.getCompilationService,
            categories: [],
            service: {
                sex: [],
                dop: [],
                bdsm: [],
                massage: [],
                strip: [],
                extreme: [],
            },
            checkService: [],
            addActive: '',
            category_id: '',
            service_id: '',
            selected: {
                category_id: false,
                service_id: false
            },
            mobileStep: 1,
            mobileSelectedCat: '',
            showMobile: this.show
        }
    },
    computed: {
        isMobile() {
            return this.$store.getters['getIsMobile']
        }
    },
    mounted() {
        this.m_getAllCategory()
        this.m_getAllService()
    },
    watch:{
        show: function () {
            this.showMobile = this.show
        }
    }

})
</script>

<template>
    <div v-click-outside="hideSearchPage" :class="['search-list', {top: this.position === 'top'}, {bottom: this.position === 'bottom'}]"
         v-if="show && !isMobile">
        <div class="row" v-if="this.position === 'bottom'">
            <div class="col-12">
                <close-x :preset="'top'" @click="this.$emit('close')"/>
            </div>
        </div>
        <div class=" row p-3">

            <div class="col-12">
                <span class="title-service">Предпочтения</span>
                <ul class="nav nav-pills mb-3 mt-3 justify-content-center" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active hover-btn"
                                id="pills-sex-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-sex"
                                type="button" role="tab"
                                aria-controls="pills-sex"
                                aria-selected="true">Секс
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link hover-btn"
                                id="pills-massage-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-massage"
                                type="button" role="tab"
                                aria-controls="pills-massage"
                                aria-selected="false">Массаж
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link hover-btn"
                                id="pills-strip-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-strip"
                                type="button" role="tab"
                                aria-controls="pills-strip"
                                aria-selected="false">Стриптиз
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link hover-btn"
                                id="pills-extreme-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-extreme"
                                type="button" role="tab"
                                aria-controls="pills-extreme"
                                aria-selected="false">Экстрим
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link hover-btn"
                                id="pills-bdsm-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-bdsm"
                                type="button" role="tab"
                                aria-controls="pills-bdsm"
                                aria-selected="false">Садо-мазо
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link hover-btn"
                                id="pills-dop-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-dop"
                                type="button" role="tab"
                                aria-controls="pills-dop"
                                aria-selected="false">Разное
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active"
                         id="pills-sex" role="tabpanel"
                         aria-labelledby="pills-sex-tab"
                         tabindex="0">
                        <div class="row">
                            <div class="col-3 mt-2 d-flex align-items-center" v-for="item in service.sex" :key="item.id">
                                <img src="@/assets/img/check-green.svg" alt="">
                                <span class="name-service cursor" @click="addInfoService($event, item)"
                                      :data-ser="item.id">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade"
                         id="pills-massage" role="tabpanel"
                         aria-labelledby="pills-massage-tab"
                         tabindex="0">
                        <div class="row">
                            <div class="col-3 mt-2 d-flex align-items-center" v-for="item in service.massage" :key="item.id">
                                <img src="@/assets/img/check-green.svg" alt="">
                                <span class="name-service cursor"
                                      @click="addInfoService($event, item);"
                                      :data-ser="item.id">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade"
                         id="pills-strip" role="tabpanel"
                         aria-labelledby="pills-strip-tab"
                         tabindex="0">
                        <div class="row">
                            <div class="col-3 mt-2 d-flex align-items-center" v-for="item in service.strip" :key="item.id">
                                <img src="@/assets/img/check-green.svg" alt="">
                                <span class="name-service cursor"
                                      @click="addInfoService($event, item);"
                                      :data-ser="item.id">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade"
                         id="pills-extreme" role="tabpanel"
                         aria-labelledby="pills-extreme-tab"
                         tabindex="0">
                        <div class="row">
                            <div class="col-3 mt-2 d-flex align-items-center" v-for="item in service.extreme" :key="item.id">
                                <img src="@/assets/img/check-green.svg" alt="">
                                <span class="name-service cursor"
                                      @click="addInfoService($event, item);"
                                      :data-ser="item.id">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade"
                         id="pills-bdsm" role="tabpanel"
                         aria-labelledby="pills-bdsm-tab"
                         tabindex="0">
                        <div class="row">
                            <div class="col-3 mt-2 d-flex align-items-center" v-for="item in service.bdsm" :key="item.id">
                                <img src="@/assets/img/check-green.svg" alt="">
                                <span class="name-service cursor"
                                      @click="addInfoService($event, item);"
                                      :data-ser="item.id">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade"
                         id="pills-dop" role="tabpanel"
                         aria-labelledby="pills-dop-tab"
                         tabindex="0">
                        <div class="row">
                            <div class="col-3 mt-2 d-flex align-items-center" v-for="item in service.dop" :key="item.id">
                                <img src="@/assets/img/check-green.svg" alt="">
                                <span class="name-service cursor aaa" @click="addInfoService($event, item); "
                                      :data-ser="item.id">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row pe-3" v-if="this.position === 'top'">
            <div class="col-12">
                <close-x :preset="'bottom'" @click="this.$emit('close')"/>
            </div>
        </div>
    </div>
    <div @click="hideMobileDialog"  class="m-search-list" v-if="isMobile && this.showMobile">
        <modal-dialog @modalClose="this.showMobile = false; this.$emit('close');" :id="'uiSearchModal'"
                      :show="showMobile" :dialog-params="{height: '60vh', overflow: 'auto'}">
            <div class="step-1" v-if="mobileStep === 1">
                <div class="row w-100">
                    <div class="col-auto">
                        <div class="prev" v-if="mobileStep > 1" @click.stop="stepPrev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col">
                        <h4 class="text-center">Выбери категорию</h4>
                    </div>
                </div>
                <div class="row mt-5 w-100">
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            v-for="(item, i) in categories"
                            v-show="item.id === 7 || item.id === 8"
                            :key="i"
                            v-html="item.name"
                            @click="btnClick"
                            aria-current="page"
                            href="#"
                            :data-cat="item.id"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="step-2" v-if="mobileStep === 2">
                <div class="row w-100">
                    <div class="col-auto">
                        <div class="prev" v-if="mobileStep > 1" @click.stop="stepPrev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col">
                        <h4 class="text-center">Выбери категорию услуг</h4>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            @click="btnClickStep2('sex')"
                            aria-current="page"
                            href="#"
                        >
                            Секс
                        </button>
                    </div>
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            @click="btnClickStep2('massage')"
                            aria-current="page"
                            href="#"
                        >
                            Массаж
                        </button>
                    </div>
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            @click="btnClickStep2('strip')"
                            aria-current="page"
                            href="#"
                        >
                            Стриптиз
                        </button>
                    </div>
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            @click="btnClickStep2('extreme')"
                            aria-current="page"
                            href="#"
                        >
                            Экстрим
                        </button>
                    </div>
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            @click="btnClickStep2('bdsm')"
                            aria-current="page"
                            href="#"
                        >
                            Садо-мазо
                        </button>
                    </div>
                    <div class="col-12">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            @click="btnClickStep2('dop')"
                            aria-current="page"
                            href="#"
                        >
                            Разное
                        </button>
                    </div>
                </div>
            </div>
            <div class="step-3" v-if="mobileStep === 3">
                <div class="row w-100">
                    <div class="col-auto">
                        <div class="prev" v-if="mobileStep > 1" @click.stop="stepPrev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col">
                        <h4 class="text-center">Выбери услугу</h4>
                    </div>
                </div>
                <div class="row mt-3 w-100">
                    <div class="col-12 ">
                        <button
                            class="cat-btn text-start p-2 mb-1 w-100 text-center hover-btn"
                            v-for="(item, i) in mobileSelectedCat"
                            :key="i"
                            href="#"
                            @click="this.$emit('modalClose'), this.$emit('close'), addInfoService, this.$router.push(`/compilation-service/${item.id}`);"
                        >
                            <img src="@/assets/img/check-green.svg" alt="">
                            <span class="ms-3 cursor" :data-ser="item.id">{{ item.name }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </modal-dialog>

    </div >


</template>

<style scoped>

.search-list {
    position: fixed;
    opacity: 1;
    height: auto;
    width: calc(100% - 40px);
    visibility: visible;

    left: 20px;
    right: 20px;
    z-index: 999;
    pointer-events: all;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #000;
}

.left-menu button {
    color: #525665;
    font-weight: 500;
}

.cursor {
    cursor: pointer;
}

.left-menu button.active, .left-menu button.active:hover {
    background: #F5F5F5 url("@/assets/img/row-right-search.svg") no-repeat right;
    border-radius: 14px;
    background-position-x: calc(100% - 10px);
}

.left-menu button:hover {
    background: #F5F5F5;
    border-radius: 14px;
}

.tab-pane img {
    max-width: 17px;
    margin-right: 15px;
}

.nav-link {
    background: #21232F !important;
    border-radius: 20px;
    border: none;
    color: #fff !important;
    margin-right: 15px;
    margin-left: 15px;
}

.nav-pills .nav-link.active {
    background: #21232F;
    border-radius: 20px;
    border: none;
    color: #1AD42C;
}

.name-service {
    font-size: 14px;
    font-weight: 400;
    color: #525665;
}

.name-service:hover {
    font-weight: 500;
}

.title-service {
    font-size: 24px;
    color: #525665;
}

.prev {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #fff;
    width: 34px;
    height: 34px;
    border: 1px solid #BDC1D1;
    border-radius: 5px;
}

@media (max-width: 500px) {
  button{
    -webkit-text-fill-color: #000;
    opacity: 1;
  }
}
</style>