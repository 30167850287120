<script>
import axios from 'axios';
import LikeIcon from '@/components/icons/LikeIcon.vue';
import LogInCard from '@/components/blocks/LogInCard.vue';
import HeartIconSolid from '@/components/icons/HeartIconSolid.vue';
import CopyIconSolid from '@/components/icons/CopyIconSolid.vue';
import PlaneIconSolid from '@/components/icons/PlaneIconSolid.vue';
import OpenIconSolid from '@/components/icons/OpenIconSolid.vue';
import DotsIcon from '@/components/icons/DotsIcon.vue';
import CopyLinkButton from '@/components/ui/CopyLinkButton.vue';

export default {
    name: 'mobile-stream-post',
    components: {
        LikeIcon,
        LogInCard,
        HeartIconSolid,
        CopyIconSolid,
        PlaneIconSolid,
        OpenIconSolid,
        DotsIcon,
        CopyLinkButton,
    },
    props: ['post'],
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            avatarUrl: null,
            imageUrl: null,
            videoUrl: null,
            profile: null,
            isLiked: false,
            isExpanded: false,
            loginTitle: '',
            show: false,
            isMuted: false,
            isPlaying: false,
            duration: 0,
            currentTime: '00:00',
            videoContent: null,
            passedPart: 0,
            canPlay: false,
        };
    },
    async mounted() {
        this.profile = await this.getProfile();
        if (this.profile?.photo[0]?.original_filename) {
            this.avatarUrl = this.apiDomine + '/web/uploads' + this.profile.photo[0].original_filename;
        } else this.avatarUrl = this.apiDomine + '/web/uploads/avatar/no_photo.jpg';

        this.imageUrl =
            !!this.post.photo && this.post.photo != 'null'
                ? this.apiDomine + '/web/uploads' + this.post.photo
                : undefined;

        this.videoUrl =
            !!this.post.video && this.post.video != 'null'
                ? this.apiDomine + '/web/uploads/post/video/' + this.post.video
                : undefined;        
    },
    methods: {
        playToggle() {
            if (this.isPlaying) {
                this.videoContent.pause();
                this.isPlaying = false;
            } else {
                this.videoContent.play();
                this.isPlaying = true;
            }
        },
        volumeToggle() {
            if (this.videoContent) {
                this.isMuted = !this.isMuted;
                this.videoContent.muted = this.isMuted;
            }
        },
        onLoadedmetadata(e) {
            this.duration = e.target?.duration || 0;
        },
        onCanplay(e) {            
            this.canPlay = true;
            this.videoContent = this.$refs.videoContent;
        },
        onEnded() {
            this.passedPart = 0;
            this.isPlaying = false;
        },
        onError(e) {
            console.error('onError: ', e);
        },
        updateRemainingTime(currentTime) {
            const minutes = Math.floor(currentTime / 60);
            const seconds = Math.floor(currentTime % 60);
            this.currentTime = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
            this.passedPart = (currentTime / this.duration) * 100;
        },

        usk(text) {
            try {
                return decodeURIComponent(escape(atob(text)));
            } catch (error) {
                return;
            }
        },
        hideDialog() {
            this.show = false;
            this.$emit('successLogin');
        },
        async getProfile() {
            const { data, message } = await axios
                .get(this.apiUrl + 'api-girl/get-profile-by-id', {
                    params: {
                        auth: this.user.username + ':' + this.user.auth_key,
                        id: this.post.profile_id,
                    },
                })
                .catch((error) => {
                    console.error(error.message);
                    return error;
                });
            if (data !== null && data !== undefined) {
                return data;
            } else return undefined;
        },
        async copyLink(bottom = false) {
            const link = `${window.location.origin}/anketa/${this.post.profile_id}`;
            await window.navigator.clipboard.writeText(link);

            const element = this.$refs.copyAlert;
            element.textContent = 'Адрес страницы скопирован';
            element.classList.add('success');
            if (bottom) {
                element.classList.add('bottom-position');
            } else {
                element.classList.add('top-position');
            }
            setTimeout(() => {
                element.classList.remove('success');
                element.classList.remove('bottom-position');
                element.classList.remove('top-position');
            }, 2000);
        },
        openProfile() {
            this.$router.push({ path: `/anketa/${this.post.profile_id}` });
        },
        saveProfile() {
            if (!this.user.isLogged) {
                this.loginTitle = 'Что бы добавить анкету в избранное, войдите или зарегистрируйтесь в сервисе';
                this.show = true;
            } else if (this.profile.isSaved) {
                this.delSaved();
            } else {
                this.addSaved();
            }
        },
        async sendMessage() {
            if (this.user.isLogged) {
                const auth = '&auth=' + this.user.username + ':' + this.user.auth_key;
                const params = auth + '&user_id=' + this.user.user_id + '&target_user_id=' + this.profile.user_id;
                const { data } = await axios.get(this.apiUrl + 'api-chat/get-private-chat' + params);
                let chatId = data.id || null;

                if (!data.id && !data.status) {
                    const setChat = {
                        type_id: 3,
                        user_id: +this.user.user_id,
                        target_user_id: +this.profile.user_id,
                    };

                    const { data } = await axios.post(this.apiUrl + 'api-chat/set-chat' + auth, setChat);

                    chatId = data.chat && data.chat.id ? data.chat.id : null;
                }

                const activeChatItem = {
                    id: chatId,
                    name: this.profile.name,
                    recipient_id: +this.profile.user_id,
                    message_id: new Date().getTime(),
                };

                this.$store.commit('setActiveChat', activeChatItem);
            } else {
                this.loginTitle = 'Что бы написать, войдите или зарегистрируйтесь в сервисе';
                this.show = true;
            }
        },

        addSaved() {
            axios
                .post(this.apiUrl + 'api-user/save-girl&auth=' + this.user.username + ':' + this.user.auth_key, {
                    user_id: this.user.user_id,
                    profile_id: this.profile.id,
                })
                .then((response) => {
                    if (response.data.status) {
                        this.profile.isSaved = true;
                    }
                })
                .catch((error) => console.log(error));
        },
        delSaved() {
            axios
                .post(this.apiUrl + 'api-user/unsave-girl&auth=' + this.user.username + ':' + this.user.auth_key, {
                    user_id: this.user.user_id,
                    profile_id: this.profile.id,
                })
                .then((response) => {
                    if (response.data.status) {
                        this.profile.isSaved = false;
                    }
                })
                .catch((error) => console.log(error));
        },
        async updateLike(type) {
            try {
                // Получаем текущего пользователя
                const currentUser = this.getCurrentUser; // Функция getCurrentUser() должна возвращать текущего пользователя

                // Проверяем, был ли уже поставлен лайк пользователем
                if (!localStorage.getItem(`like_${this.post.id}_${currentUser}`)) {
                    // Условие для автора поста
                    if (currentUser == this.post.author_id) {
                        // Проверяем, был ли у автора поста уже поставлен лайк
                        if (!localStorage.getItem(`like_${this.post.id}_${this.user.user_id}`)) {
                            const res = await axios.post(
                                this.apiUrl +
                                    'api-profile-post/update-stat' +
                                    '&auth=' +
                                    this.user.username +
                                    ':' +
                                    this.user.auth_key,
                                {
                                    post_id: this.post.id,
                                    type: type,
                                }
                            );

                            // Устанавливаем флаг, что автор поста поставил лайк
                            localStorage.setItem(`like_${this.post.id}_${this.user.user_id}`, 'true');
                        } else {
                            console.log('Вы уже поставили лайк своему собственному посту');
                        }
                    } else {
                        const res = await axios.post(
                            this.apiUrl +
                                'api-profile-post/update-stat' +
                                '&auth=' +
                                this.user.username +
                                ':' +
                                this.user.auth_key,
                            {
                                post_id: this.post.id,
                                type: type,
                            }
                        );

                        // Устанавливаем флаг, что пользователь поставил лайк
                        localStorage.setItem(`like_${this.post.id}_${currentUser}`, 'true');
                        this.isLiked = true;
                    }
                } else {
                    console.log('Вы уже поставили лайк этому посту');
                }
            } catch (error) {
                console.log('Ошибка при like', error);
            }
        },
    },
    computed: {
        createdAt() {
            if (this.post?.created_at) {
                return new Date(this.post.created_at * 1000).toLocaleDateString('ru-RU', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                });
            } else return undefined;
        },
        getCurrentUser() {
            return this.user.user_id;
        },
        isMediaContent() {
            return !!this.imageUrl || !!this.videoUrl;
        },
    },
};
</script>

<template>
    <Transition name="bounce">
        <div
            v-show="profile"
            class="mobile-stream-post__wrapper"
        >
            <div class="mobile-stream-post__container d-flex flex-column gap-3">
                <div
                    class="copy-alert"
                    ref="copyAlert"
                ></div>
                <div class="mobile-stream-post__header d-flex">
                    <div
                        class="header__avatar"
                        @click="openProfile"
                    >
                        <img
                            :src="avatarUrl"
                            alt="ava"
                        />
                    </div>
                    <div class="header__title d-flex flex-column">
                        <div
                            class="header__name"
                            @click="openProfile"
                        >
                            <b>{{ profile?.name || '' }}</b>
                        </div>
                        <div class="header__created">
                            {{ createdAt }}
                        </div>
                    </div>
                    <div class="header__menu dropdown ms-auto">
                        <button
                            class="menu__button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <DotsIcon
                                :width="30"
                                color="#BAB1BF"
                            />
                        </button>
                        <div class="menu__actions dropdown-menu">
                            <button
                                class="menu__item dropdown-item d-flex"
                                @click="saveProfile()"
                            >
                                <div>
                                    <HeartIconSolid
                                        :color="this.profile?.isSaved ? '#FF4032' : '#2D2F3A'"
                                        :size="{ w: 24, h: 24 }"
                                    />
                                </div>
                                <div class="ps-2">Сохранить в сохраненки</div>
                            </button>
                            <button
                                class="menu__item dropdown-item d-flex"
                                @click="copyLink()"
                            >
                                <div>
                                    <CopyIconSolid
                                        color="#2D2F3A"
                                        :size="{ w: 24, h: 24 }"
                                    />
                                </div>
                                <div class="ps-2">Скопировать ссылку</div>
                            </button>
                            <button
                                class="menu__item dropdown-item d-flex"
                                @click="sendMessage()"
                            >
                                <div>
                                    <PlaneIconSolid
                                        color="#2D2F3A"
                                        :size="{ w: 24, h: 24 }"
                                    />
                                </div>
                                <div class="ps-2">Написать сообщение</div>
                            </button>
                            <button
                                class="menu__item dropdown-item d-flex"
                                @click="openProfile()"
                            >
                                <div>
                                    <OpenIconSolid
                                        color="#2D2F3A"
                                        :size="{ w: 23, h: 23 }"
                                    />
                                </div>
                                <div class="ps-2">Открыть анкету</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="imageUrl"
                    class="mobile-stream-post__image"
                >
                    <img
                        :src="imageUrl"
                        alt=""
                    />
                </div>
                <div
                    v-if="videoUrl"
                    class="mobile-stream-post__video"
                >
                    <div class="mobile-stream-post__video-content">                        
                        <video
                            ref="videoContent"
                            preload="metadata"
                            playsinline
                            webkit-playsinline
                            :muted="isMuted"
                            @loadedmetadata="(e) => onLoadedmetadata(e)"
                            @ended="onEnded"
                            @error="(e) => onError(e)"
                            @canplay="(e) => onCanplay(e)"
                            @timeupdate="(e) => updateRemainingTime(e.target.currentTime)"
                        >
                            <source
                                :src="`${videoUrl}#t=0.001`"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div
                        v-if="canPlay"
                        class="mobile-stream-post__video-controls"
                    >
                        <button
                            class="player__buttons__play player-button"
                            @click.stop="playToggle"
                            :style="{
                                backgroundImage: `url(${
                                    isPlaying ? require('@/assets/img/pause.png') : require('@/assets/img/play.svg')
                                })`,
                            }"
                        ></button>
                        <button
                            class="player__buttons__mute player-button"
                            @click.stop="volumeToggle"
                            :style="{
                                backgroundImage: `url(${
                                    isMuted ? require('@/assets/img/mute.svg') : require('@/assets/img/volume.svg')
                                })`,
                            }"
                        ></button>
                    </div>
                    <div class="mobile-stream-post__video__progressive-bar">
                        <div
                            class="mobile-stream-post__video__progressive-bar--pr"
                            :style="{ width: `${passedPart}%` }"
                        ></div>
                    </div>
                </div>
                <div
                    v-if="!isMediaContent"
                    class="mobile-stream-post__post"
                >
                    <p :class="isExpanded ? '' : 'collapsed'">
                        {{ post?.text ? usk(post.text) : '' }}
                    </p>
                    <button
                        class="post__show-more"
                        @click="isExpanded = !isExpanded"
                    >
                        {{ isExpanded ? 'Свернуть' : 'Показать' }}
                    </button>
                </div>
                <div class="mobile-stream-post__social">
                    <div class="social__left">
                        <button
                            class="social__left__like"
                            :disabled="false"
                            @click="updateLike('likes')"
                        >
                            <LikeIcon />
                            <span>{{ parseInt(post?.stat?.likes + isLiked) || 0 }}</span>
                        </button>
                        <CopyLinkButton @click="copyLink(!isMediaContent)" />
                    </div>
                    <div class="social__right">
                        <img
                            src="@/assets/img/eye-grey.png"
                            alt=""
                        />
                        <span>{{ post?.stat?.views }}</span>
                    </div>
                </div>
                <div
                    v-if="isMediaContent"
                    class="mobile-stream-post__post"
                >
                    <p :class="isExpanded ? '' : 'collapsed'">
                        {{ post?.text ? usk(post.text) : '' }}
                    </p>
                    <button
                        class="post__show-more"
                        @click="isExpanded = !isExpanded"
                    >
                        {{ isExpanded ? 'Свернуть' : 'Показать' }}
                    </button>
                </div>
            </div>
        </div>
    </Transition>
    <log-in-card
        :show="this.show"
        @loginSuccess="hideDialog"
        @hideDialog="hideDialog"
        :title="loginTitle"
    />
</template>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.75s;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.copy-alert {
    position: absolute;
    left: 50%;
    height: 30px;
    transform: translateX(-50%);
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: #dedede;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
    width: max-content;

    &.success {
        color: #333;
        animation: copy-alert-hide 2s linear;
    }

    &.top-position {
        top: 100px;
    }

    &.bottom-position {
        bottom: 50px;
    }
}

@keyframes copy-alert-hide {
    0% {
        opacity: 1;
        visibility: visible;
    }

    60% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.mobile-stream-post__wrapper {
    .mobile-stream-post__container {
        position: relative;
        padding: 15px 10px;
        background-color: #f5f5f5;
        border-radius: 16px;
        width: 100%;

        .mobile-stream-post__header {
            height: 48px;

            .header__avatar {
                > img {
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .header__title {
                margin-left: 20px;
                font-size: 16px;
                line-height: 24px;
            }

            .header__menu {
                .menu__actions {
                    border-radius: 11px;
                    background-color: #ffffffc2 !important;
                    backdrop-filter: blur(2px);

                    .menu__item {
                        padding-inline: 9px;
                    }
                }
            }
        }

        .mobile-stream-post__post {
            display: flex;
            flex-direction: column;
            > p {
                margin: 0;

                &.collapsed {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    line-clamp: 3;
                    box-orient: vertical;
                }
            }

            .post__show-more {
                margin-right: 0px;
                margin-left: auto;
                text-align: end;
                padding-inline: 10px;
                color: #484c5e;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .mobile-stream-post__social {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .social__left {
            display: flex;
            align-items: center;
            gap: 8px;

            &__like {
                padding: 0 12px;
                width: 63px;
                height: 30px;

                background: rgba(255, 64, 50, 0.1);
                border-radius: 51px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #ff4032;
            }
        }

        .social__right {
            background: rgba(82, 86, 101, 0.7);
            border-radius: 51px;
            display: flex;
            align-items: center;
            gap: 7px;
            padding: 0 12px;
            min-width: 63px;
            height: 30px;
            justify-content: space-between;
            cursor: pointer;
            & span {
                font-size: 14px;
                color: white;
            }
        }
    }
}

.mobile-stream-post__image {
    width: 100%;

    > img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        object-fit: cover;
    }
}

.mobile-stream-post__video {
    position: relative;
    width: 100%;

    .mobile-stream-post__video-content {
        position: relative;
        > video {
            width: 100%;
            height: auto;
            border-radius: 18px;

            border-bottom: 1px solid transparent; /* хак для предотвращения мелькания при загрузке на некоторых версиях ios */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
                opacity: 0;
                visibility: hidden;
            }
            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
                opacity: 0;
                visibility: hidden;
            }
            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .mobile-stream-post__video-controls {
        > .player-button {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }

        .player__buttons__play {
            position: absolute;
            height: 50px;
            width: 50px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #ffffff80;

            &:hover {
                background-color: rgba(#fff, 0);
            }
        }

        .player__buttons__mute {
            position: absolute;
            height: 32px;
            width: 32px;

            backdrop-filter: blur(10px);
            background-color: #cfc4c21a;
            top: 35px;
            right: 15px;

            &:hover {
                background-color: rgba(#fff, 0.2);
            }
        }
    }

    .mobile-stream-post__video__progressive-bar {
        position: absolute;
        top: 12px;
        height: 4px;
        background-color: rgba(#fff, 0.3);
        border-radius: 15px;
        width: 90%;
        left: 5%;

        &--pr {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0%;
            background-color: #ff4032;
            border-radius: 10px;
        }
    }
}
</style>
