<script>
import axios from 'axios'
import ModalCard from "@/components/blocks/ModalCard.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import UploadPhoto from "@/components/ui/uploadMedia/UploadPhoto.vue";

export default {
    components: {
        UploadPhoto,
        UiLoader,
        ModalCard,
        axios,
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            tags: [],
            tag: {
                id: false,
                pic: '',
                name: '',
                sort: '',
            },
            show: false,
            formBtn: true,
            toDel: false,
            loaderShow: false,
            errorText: ''
        }
    },
    methods: {
        cleanText(val) {
            if (val.match(/^[а-яА-ЯёЁa-zA-Z\s]/g)) {

                return val.replace(/[^[а-яА-ЯёЁa-zA-Z\s]/g, '');
            }

            return val;
        },

        cleanNumber(val) {
            if (val.match(/[^0-9\s]/g)) {

                return val.replace(/[^0-9\s]/g, '');
            }

            return val;
        },

        validateForm() {
            let result = true;
            let name = document.getElementById('name');

            if (this.name === "") {
                name.classList.add('invalid');
                result = false;
            } else {
                name.classList.remove('invalid');
            }

            if (result) {
                if (this.formBtn) {
                    this.postTag();
                } else {
                    this.updateTag();
                }
            }
        },

        clearForm() {
            this.tag.name = '';
            this.tag.pic = '';
            this.tag.sort = '';
            this.formBtn = true;
        },

        updateData() {
            if (!this.user) {
                return false;
            }
            axios
                .get(this.apiUrl + 'api-tag/get-tags&auth=' + this.user.username + ':' + this.user.auth_key)
                .then((response) => {
                    this.tags = response.data;
                    console.log('updateData', this.tags)
                }).catch((error) => {
            })
            this.clearForm();
        },

        postTag() {
            this.loaderShow = true
            console.log('postTag', this.tag)
            axios
                .post(this.apiUrl + 'api-tag/set-tag&auth=' + this.user.username + ':' + this.user.auth_key, this.tag)
                .then((response) => {
                    this.updateData()
                    this.loaderShow = false
                })
        },

        updateTag() {
            this.loaderShow = true
            axios
                .post(this.apiUrl + 'api-tag/update-tag&id=' + this.tag.id + '&auth=' + this.user.username + ':' + this.user.auth_key, this.tag)
                .then((response) => {
                    this.updateData()
                    this.loaderShow = false
                })
            this.clearForm();
        },

        toForm(id) {
            let tag;
            this.formBtn = false;
            for (let t of this.tags) {
                if (t.id === id) {
                    tag = t;
                }
            }
            this.tag = tag;
        },

        deleteData() {
            axios
                .post(this.apiUrl + 'api-tag/del-tag&id=' + this.toDel)
            this.hideDialog();
            this.updateData();
        },

        modalShow(id) {

            this.show = true;
            this.toDel = id;
        },

        hideDialog() {
            this.show = false;
        }
    },
    mounted() {
        this.updateData()
    }
}
</script>
<template>
    <main>

        <form @submit.prevent="addTag">
            <div class="settings-add row mx-auto justify-content-center align-items-center">
                <div class="settings-add__item col-1">
                   <upload-photo
                       @uploadSuccess="(name)=>{this.tag.pic = name}"
                       :title-photo="this.tag.pic"
                       :basePicUrl="`${apiDomine}/web/uploads/system/tag/`"
                       :uploadLink="'api-tag/upload-tag-photo'"
                       @uploadFail="(text) => {this.errorText = text}"
                   />
                </div>
                <div class="settings-add__item col-3">
                    <label>
                        <input type="text" required v-model="tag.name"
                               @keyup="tag.name = cleanText(tag.name)"
                               placeholder="Название" id="name">
                    </label>
                </div>
                <div class="settings-add__item col-2">
                    <label>
                        <input type="text" required v-model="tag.sort" maxlength="2" placeholder="Сортировка"
                               id="sort">
                    </label>
                </div>
                <button :disabled="this.loaderShow" type="submit" class="col-2 position-relative" v-if="formBtn"
                        @click="validateForm">
                    Создать
                    <ui-loader :show="this.loaderShow" :preset="'btn'"/>
                </button>
                <button :disabled="this.loaderShow" type="submit" class="col-2 position-relative" v-if="!formBtn"
                        @click="validateForm">
                    Сохранить
                    <ui-loader :show="this.loaderShow" :preset="'btn'"/>
                </button>
            </div>
        </form>


        <div class="settings-table">
            <div class="settings-table__title text-center mb-4">Теги</div>
            <div class="settings-table__head row mx-0 justify-content-center">
                <div class="settings-table__item  col-3">Изображение</div>
                <div class="settings-table__item  col-3">Название</div>
                <div class="settings-table__item  col-3">Сортировка</div>
                <div class="settings-table__item  col-1"></div>
            </div>
            <div class="settings-table__content">
                <div
                    v-for="(item, i) in tags"
                    :key="i"
                    class="settings-table__row row mx-0 justify-content-center"
                >
                    <div class="settings-table__item  col-3">
                        <img :src="`${apiDomine}/web/uploads` + item.pic" style="max-width: 50px;">
                    </div>
                    <div class="settings-table__item  col-3">{{ item.name }}</div>
                    <div class="settings-table__item  col-3">{{ item.sort }}</div>
                    <div class="settings-table__item  col-1 d-flex justify-content-center">
                        <button @click="toForm(item.id)">
                            <i class="bi bi-pencil"></i>
                        </button>
                        <!--            <button @click="modalShow(item.id)">
                                      <i class="bi bi-trash"></i>
                                    </button>-->
                    </div>
                </div>
            </div>

        </div>
    </main>
    <!--  Всплывающее сообщение, ошибки-->
    <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                {{ this.errorText }}
            </div>
            <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Закрыть"></button>
        </div>
    </div>
    <!--Модальное окно, удаление-->
    <modal-card :show="this.show">
        <div>
            <div class="row">
                <div class="col-12 text-end">
                    <i class="bi bi-x-lg" @click="hideDialog"></i>
                </div>
            </div>
            <div class="modal-body">
                <p>Уверены, что хотите удалить {{ this.tag.name }}</p>
            </div>
            <div class="modal-footer mt-3 justify-content-between">
                <button type="button" class="btn btn-modal" data-bs-dismiss="modal">Закрыть</button>
                <button type="button" @click="deleteData" class="btn btn-modal">Удалить</button>
            </div>
        </div>
    </modal-card>
</template>


<style scoped lang="scss">
.settings-add {
    max-width: 80%;
    margin: 0 auto 40px;
    border: solid 1px #2C2F3C;
    border-radius: 10px;
    padding: 15px 0;
    background: #2C2F3C;


    button {
        background: #BDC1D1;
        display: inline-block;
        border: solid 1px transparent;
        border-radius: 4px;
        color: #2C2F3C;
        transition: 0.3s;

        &:hover {
            background: #BDC1D1;
            border-color: #BDC1D1;
            color: #2C2F3C;
            font-weight: 600;
        }
    }

    .invalid {
        border: 1px solid red;
    }

    label {
        width: 100%;
    }

    input {
        background-color: #dedede;
        width: 100%;
        border: 1px solid #BDC1D1;
        border-radius: 4px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }
}

.settings-table {
    max-width: 80%;
    margin: 0 auto;
    color: #fff;

    &__head {
        background-color: #2C2F3C;
        font-size: 16px;
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        border-bottom: solid 1px #7e7e7e7e;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &__title {
        color: #000000;
        font-weight: 600;
        font-size: 20px;
    }

    &__content {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &__row {
        background-color: #2C2F3C;
        position: relative;


        &:nth-child(odd) {
            background-color: #2C2F3C;
        }

        button {
            color: #fff;
        }
    }

    &__item {
        border-bottom: solid 1px #7e7e7e7e;
    }
}

button.btn-modal {
    background: #dedede !important;
    display: inline-block;
    border: solid 1px transparent;
    border-radius: 4px;
    color: #fff;
    transition: 0.3s;
}

.settings-add__item .title-photo-block{
    width: 3vh!important;
    height: 3vh!important;
}
</style>