<script>
export default {
    name: 'copy-link-button',
    props: {
        strokeColor: {
            type: String,
            default: '#C6C7CC',
        },
        bgColor: {
            type: String,
            default: '#525665',
        },               
    },
};
</script>

<template>
    <button
        class="copy-link-button"
        :style="{ backgroundColor: bgColor }"
    >
        <svg
            width="17"
            height="12"
            viewBox="0 0 17 12"
            :fill="bgColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.8122 1.12576C8.8122 0.716609 9.27682 0.480661 9.60718 0.722043L15.5004 5.028C15.7738 5.22774 15.7738 5.6357 15.5004 5.83543L9.60718 10.1414C9.27682 10.3828 8.8122 10.1469 8.8122 9.73771L8.8122 8.10263C8.8122 7.68218 8.42961 7.36552 8.01656 7.44412C6.41058 7.74972 4.86802 8.32532 3.4544 9.14645L1.25192 10.4258L1.27239 10.3854C2.7595 7.45169 5.27875 5.17134 8.34565 3.98291C8.62686 3.87394 8.8122 3.60335 8.8122 3.30176L8.8122 1.12576Z"
                :stroke="strokeColor"
                stroke-opacity="0.54"
            />
        </svg>
    </button>
</template>

<style lang="scss" scoped>
.copy-link-button {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    opacity: 0.7;
    padding: 0;

    &:hover {
        opacity: 1;
    }

    &:active {
        -webkit-box-shadow: 0px 0px 5px 4px rgba(82, 86, 101, 0.2);
        -moz-box-shadow: 0px 0px 5px 4px rgba(82, 86, 101, 0.2);
        box-shadow: 0px 0px 5px 4px rgba(82, 86, 101, 0.2);
    }

}
</style>
