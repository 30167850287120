<script>
import axios from "axios";
import UploadPhoto from "@/components/ui/uploadMedia/UploadPhoto.vue";
import { toRaw } from 'vue';

export default {
    name: 'UploadTitlePhoto',
    components: { UploadPhoto },
    props: {
        titlePhoto: {
            type: [String, Boolean],
            default: false
        },
        value: {
            type: [Array, String, Boolean],
            default: false
        }
    },
    methods: {
        changeInput() {
            // сделай проверку файла на соответветствия, должны проходить только jpg и png
            const self = this;
            this.file = this.$refs.titlePhoto.files[0];

            const fileType = this.file.type
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp']
            if (allowedExtensions.indexOf(fileType.substr(fileType.lastIndexOf('/') + 1)) === -1) {
                console.log('file type bad: ', fileType.substr(fileType.lastIndexOf('/') + 1))
                return false
            }

            

            let formData = new FormData();
            formData.append('image', this.file);
            this.isUpload = true
            axios.post(this.uploadLink + '&auth=' + this.user.username + ':' + this.user.auth_key,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(function (data) {
                if (self.fileName === '') {
                    self.media.push({ name: data.data.name });
                } else {
                    self.media = self.media.filter(m => { if (m.name !== self.fileName) return toRaw(m) })
                    self.media.push({ name: data.data.name });
                }

                self.$emit('uploadSuccess', self.media.map(m => toRaw(m)));

                self.$refs.titlePhotoBlock.style.background = `url('${self.apiDomine}/web/uploads${data.data.name}')`
                self.$refs.titlePhotoBlock.style.backgroundSize = 'cover'
                self.icon = 'reload'
                self.fileName = data.data.name
            })
                .catch(function (data) {
                    self.$emit('uploadFail', data)
                });
        },
        addPhotoHandler(data) {
            if (data.old === '') {
                this.media.push({ name: data.new });
            } else {
                this.media = this.media.filter(m => m.name !== data.old);

                if (data.new) this.media.push({ name: data.new });
            }
            this.isUpload = true
            this.$emit('uploadSuccess', this.media.map(m => toRaw(m)));
        },
        onDeleteItemHandler() {
            this.media.splice(0, 1)
            this.$refs.titlePhotoBlock.style.background = ''
            this.icon = 'plus'
            this.file = ''
            this.fileName = ''
            this.isUpload = false
            this.$refs.titlePhoto.value = ''
            this.isWatch = true

            this.$emit('uploadSuccess', this.media.map(m => toRaw(m)));
        }
    },
    mounted() {
        this.media
    },
    watch: {
        value: function (data) {
            if (!this.isWatch && !this.isUpload && data[0]?.name) {
                this.$refs.titlePhotoBlock.style.background = `url('${this.apiDomine}/web/uploads${data[0].name}')`
                this.$refs.titlePhotoBlock.style.backgroundSize = 'cover'
                this.icon = 'reload'
                this.fileName = data[0].name
                this.isWatch = true

                if(this.value){

                    for(let m of this.value){
                        this.media.push(m);
                    }

                }
            }
        }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            apiUrl: this.$store.getters.getApiUrl,
            apiDomine: this.$store.getters.getApiDomine,
            // uploadLink: this.apiUrl + "api-girl/upload-photo",
            fileName: '',
            file: '',
            icon: 'plus', // plus | reload
            media: [],
            isWatch: false,
            isUpload: false
        };
    },
    computed: {
        uploadLink() {
            return this.apiUrl + "api-girl/upload-photo"
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12 col-xl-7 pe-0 ps-0 ps-xl-3">
            <div class="title-photo-block" ref="titlePhotoBlock">
                <label class="">
                    <input type="file" ref="titlePhoto" @change="changeInput" class="title-photo-input"
                        accept="image/png, image/jpeg, image/webp">
                    <span class="icon">
                        <i class="bi bi-plus" v-if="this.icon == 'plus'"></i>
                        <i class="bi bi-arrow-clockwise" v-if="this.icon == 'reload'"></i>
                    </span>
                </label>
                <div v-if="fileName" class="delete-item hover-btn" @click="onDeleteItemHandler">
                    <i class="bi bi-trash3"></i>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-5">
            <div class="row">
                <upload-photo
                    class="col-2 col-md-1 col-lg-3 col-xl-6 p-1 p-sm-2"
                    @upload-success="addPhotoHandler"
                    :basePicUrl="`${apiDomine}/web/uploads`"
                    :value="value[i + 1]?.name || ''"
                    v-for="i in Array.from({ length: 10 }, (_, i) => i)" :key="i"></upload-photo>
            </div>
        </div>
    </div>
</template>

<style scoped>
.title-photo-block {
    /**/
    position: relative;
    border-radius: 24px;
    background: linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%);
    border: 1px solid #524B5F;
    overflow: hidden;
    transition: 0.3s;
    cursor: pointer;
    /*width: 280px;*/
    height: 100%;
    min-height: 150px;
}

@media screen and (max-width: 767px) {
    .title-photo-block {
        min-height: 450px;
    }
}

.title-photo-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 55%;
    background-color: rgba(207, 196, 194, 0.1);
    position: absolute;
    left: calc(50% - 27px);
    top: calc(50% - 27px);
    transition: 0.3s;
    cursor: pointer;
    color: #ffffff;
    /* @media (max-width: 575px) {
        width: 13px;
        height: 13px;
    } */
}

.icon:hover {
    background-color: rgba(207, 196, 194, 0.5);
}

.delete-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    opacity: 0.8;
    background-color: rgba(255, 255, 255, .5);
    position: absolute;
    top: 2%;
    right: 5%;
    border-radius: 50%;
    width: 24px;
    height: 24px;
}
</style>