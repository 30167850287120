<script>
export default{
    name:'Rocket',
    props:{
        color:{
            type: String,
            default: '#000'
        },
        size:{
            type: Object,
            default: {w:16, h:16}
        }
    }
}
</script>

<template>
<svg :width="this.size.w" :height="this.size.h" viewBox="0 0 17.5508 17.5508" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Vector" d="M5.51 12.03C6.78 13.3 7.58 14.96 7.8 16.75C8.68 16.24 9.42 15.51 9.94 14.63C10.47 13.76 10.76 12.76 10.8 11.75C12.47 11.16 13.94 10.08 15.01 8.66C16.08 7.23 16.7 5.52 16.8 3.75C16.8 2.95 16.48 2.19 15.92 1.62C15.35 1.06 14.59 0.75 13.8 0.75C12.02 0.84 10.31 1.46 8.89 2.53C7.46 3.6 6.39 5.07 5.8 6.75C4.78 6.78 3.78 7.07 2.91 7.6C2.03 8.12 1.31 8.86 0.8 9.75C2.58 9.96 4.24 10.76 5.51 12.03Z" :stroke="this.color" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
	<path id="Vector" d="M3.79 10.75C2.75 11.33 1.91 12.22 1.38 13.29C0.84 14.36 0.64 15.56 0.79 16.75C1.98 16.9 3.18 16.7 4.25 16.16C5.32 15.63 6.21 14.79 6.79 13.75M11.09 6.45C11.28 6.64 11.53 6.75 11.79 6.75C12.06 6.75 12.32 6.64 12.5 6.45C12.69 6.26 12.79 6.01 12.79 5.75C12.79 5.48 12.69 5.23 12.5 5.04C12.32 4.85 12.06 4.75 11.79 4.75C11.53 4.75 11.28 4.85 11.09 5.04C10.9 5.23 10.79 5.48 10.79 5.75C10.79 6.01 10.9 6.26 11.09 6.45Z" :stroke="this.color" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round" stroke-linecap="round"/>
</svg>
</template>

<style>

</style>