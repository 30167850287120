<script setup>
</script>

<template>
    <div class="order-finish ps-4 pe-4 pb-3 pt-2">
        <div class="order-finish__question mb-3">
            <p>Готово!</p>
        </div>
        <div class="order-finish__content mb-3">
            <p>Мы отправили уведомления всем девушкам нашего сервиса в вашем городе!</p>
        </div>        
    </div>
</template>

<style lang="scss" scoped>
.order-finish {
    font-size: 16px;
    line-height: 20px;
}
</style>
