<template>
		<div class="slider-offer">
			<div class="container">
				<swiper
					:slidesPerView="1"
					:breakpoints="swiperOptions.breakpoints"
					:navigation="swiperOptions.navigations"
					:modules="modules"
					:loop="true"
					:mousewheel="false"
					:spaceBetween="18"
					class="swiper-offer-video swiper-offer-slider"
					>
					<swiper-slide v-for="(item, i) in videoCrad" :key="i" >
						<div class="video-card">
							<video 
								:ref="`itemVideo_${i}`" 
								muted
								@click="playToggle(i)"
								@timeupdate="updateRemainingTime(i)"
							>
								<source :src="item.src" :type="item.type" :codecs="item.codecs"/>
							</video>
							<div class="video-card__poster"
								:style="{
									backgroundImage: `url(${item.poster})`,
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
								}"
							></div>
							<div class="video-card__name" v-html="item.name"></div>
							<div class="video-progressive-bar">
								<div class="video-progressive-bar--pr"></div>
							</div>
							<div class="video-remaining-time">00:00</div>
							<button class="video-play video-btns hover-btn" @click="playToggle(i)"></button>
							<button class="video-volume video-btns hover-btn" @click="volumeToggle(i)"></button>

							<button class="video-link hover-btn">Открыть анкету</button>
						</div>		
					</swiper-slide>
				</swiper>
			</div>
		</div>

 </template>
 <script>
 	import { Swiper, SwiperSlide } from "swiper/vue";
	import { Mousewheel, Pagination, Navigation } from "swiper/modules";
	export default {
		name: "VideoPlayer",
		components: {
			Swiper,
			SwiperSlide,
		},
    	props: {
        videoCards: {
					type: Array,
					require: false,
					default: [],
        }
    },
		data() {
			return {
				videoCrad: [
					{           
						id: 1,
						type: "video/mp4",
						src: require('@/assets/video/1.mp4'),
						codecs: "avc1.42E01E, mp4a.40.2",
						name: 'Анжела',
						poster: require('@/assets/img/slider-girls/item1.webp'),
						link: '',
						card: false
					},
					{           
						id: 2,
						type: "video/mp4",
						src: require('@/assets/video/2.mp4'),
						codecs: "avc1.42E01E, mp4a.40.2",
						name: 'Снежанна',
						link: '',
						poster: require('@/assets/img/slider-girls/item2.webp'),
						card: false
					},
					{           
						id: 3,
						type: "video/mp4",
						src: require('@/assets/video/3.mp4'),
						codecs: "avc1.42E01E, mp4a.40.2",
						name: 'Кристина',
						link: '',
						poster: require('@/assets/img/slider-girls/item3.webp'),
						card: false
					},
					{           
						id: 4,
						type: "video/mp4",
						src: require('@/assets/video/4.mp4'),
						codecs: "avc1.42E01E, mp4a.40.2",
						name: 'Ксюша',
						link: '',
						poster: require('@/assets/img/slider-girls/item4.webp'),
						card: false
					},
					{           
						id: 5,
						type: "video/mp4",
						src: require('@/assets/video/5.mp4'),
						codecs: "avc1.42E01E, mp4a.40.2",
						name: 'София',
						link: '',
						poster: require('@/assets/img/slider-girls/item5.webp'),
						card: false
					},
					{           
						id: 6,
						type: "video/mp4",
						src: require('@/assets/video/6.mp4'),
						codecs: "avc1.42E01E, mp4a.40.2",
						name: 'Анжела',
						link: '',
						poster: require('@/assets/img/slider-girls/item6.webp'),
						card: false
					}
				],
				swiperOptions: {
					breakpoints: { 374:{ slidesPerView:2 }, 991:{slidesPerView:2}, 1240:{slidesPerView:4} },
					navigations: true
				}
			}
		},
		methods: {
			playToggle(i) {
				const currentRef = `itemVideo_${i}`
				const videoTest = this.$refs[currentRef][0]
				const button = videoTest.closest('.video-card').querySelector('.video-play')		
				const poster = videoTest.closest('.video-card').querySelector('.video-card__poster')	
				const vids = document.querySelectorAll('video')

				vids.forEach(vid => vid.classList.remove('active'))

				videoTest.classList.add('active')

				vids.forEach(vid => {
					if(!vid.classList.contains('active')) {
						const btnWrap = vid.closest('.video-card')
						if(btnWrap !== null) {
							btnWrap.querySelector('.video-play').style.backgroundImage = 'url(' + require('@/assets/img/play.svg') 
						}
						vid.pause()
					}
				})			

				if (videoTest.paused) {
					videoTest.play()
					poster.classList.add('hide')
					button.style.backgroundImage = 'url(' + require('@/assets/img/pause.png') 
				} else {
					videoTest.pause()
					
					button.style.backgroundImage = 'url(' + require('@/assets/img/play.svg') 
				}	
			},

			volumeToggle(i) {
				const currentRef = `itemVideo_${i}`
				const videoTest = this.$refs[currentRef][0]
				const button = videoTest.closest('.video-card').querySelector('.video-volume');
				if (videoTest.muted) {
					videoTest.muted = false
					button.style.backgroundImage = 'url(' + require('@/assets/img/volume.svg')
				} else {
					videoTest.muted = true
					button.style.backgroundImage = 'url(' + require('@/assets/img/mute.svg')
				}
			},
			updateRemainingTime(i){
				const currentRef = `itemVideo_${i}`
				const videoTest = this.$refs[currentRef][0]
				const videoTestWrap = videoTest.closest('.video-card')
				const remainingTimeElement = videoTestWrap.querySelector('.video-remaining-time');
				const progressiveBar = videoTestWrap.querySelector('.video-progressive-bar--pr');
				
				let remainingTime = videoTest.currentTime;
				let minutes = Math.floor(remainingTime / 60);
				let seconds = Math.floor(remainingTime % 60);
				minutes = isNaN(minutes) ? 0 : minutes;
				seconds = isNaN(seconds) ? 0 : seconds;
				remainingTimeElement.textContent = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;

				let progressiveBarMoving = (videoTest.currentTime / videoTest.duration) * 100;
				progressiveBar.style.width = `${progressiveBarMoving}%`

			},
		},
		setup() {
			return {
				modules: [Mousewheel,  Pagination, Navigation]
			}
		},
	}
 </script>

<style lang="scss" scoped>

	.video-card {
		position: relative;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			//object-fit: fill;
		}
		&__poster {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			&.hide {
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}
		}
		&__name {
			position: absolute;
			top: 45px;
			left: 30px;
			color: #fff;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 100%
		}
		&:hover .video-play {
			opacity: 1;
			visibility: visible;
			pointer-events: all;	
		}
	}
	.video-link {
		font-size: 14px;
		line-height: 100%;
		border-radius: 50px;
		padding: 9px 0;
		max-width: 70%;
		width: 100%;
		margin: 0 auto;
		display: block;
		position: absolute;
		left: 50%;
		bottom: 35px;
		color: #fff;
		transform: translateX(-50%);
		background-color: rgba(#fff, 0.2);
		transition: 0.3s;
		border: solid 1px transparent;
		backdrop-filter: blur(10px);
		@media (max-width: 575px) {
			max-width: 90%;
			font-size: 10px;
		}
		&:hover {
			background-color: rgba(#fff, 0);
			border: solid 1px #fff;
		}
	}
	.video-play {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		backdrop-filter: blur(10px);
		display: block;
		background-color: rgba(#fff, 0.1);
		background-image: url('@/assets/img/play.svg');
	}
	.video-volume {
		position: absolute;
		top: 60px;
		right: 15px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: rgba(#fff, 0.1);
		backdrop-filter: blur(10px);
		background-image: url('@/assets/img/mute.svg');
	}
	.video-btns {
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
	.video-remaining-time {
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		font-weight: 700;
		font-size: 12px;
		color: rgba(#fff, 0.8);
	}
	.video-progressive-bar {
		position: absolute;
		top: 12px;
		height: 4px;
		background-color: rgba(#fff, 0.3);
		border-radius: 15px;
		width: 90%;
		left: 50%;
		transform: translateX(-50%);
		&--pr {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 0%;
			background-color: #FF4032;
			border-radius: 10px;
		}
	}
</style>
 