<script>
import HeaderAdmin from '@/components/ui/header/HeaderAdmin.vue'
import NavigationMenu from '@/components/blocks/NavigationMenu.vue'
import {list} from "postcss";
import UiLoader from "@/components/ui/UiLoader.vue";
import flashMassage from "@/mixins/flashMassage";
import {useCookies} from "vue3-cookies";
import HeaderGirl from "@/components/ui/header/HeaderGirl.vue";
import AdminButton from "@/components/ui/AdminButton.vue";
import BalanceWidget from "@/components/ui/billing/BalanceWidget.vue";

export default {
  computed: {
    list() {
      return list
    },
    isMobile() {
      return this.$store.getters['getIsMobile']
    }
  },
  components: {
    HeaderGirl,
    UiLoader,
    HeaderAdmin,
    NavigationMenu,
    flashMassage,
    AdminButton,
    BalanceWidget
  },
  mixins: [flashMassage],
  setup() {
    const {cookies} = useCookies();
    return {cookies};
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiDomine: this.$store.getters.getApiDomine,
      sidebarShow: false,
      menuList: [
        {
          name: 'Мои анкеты',
          ico: 'item1.svg',
          url: '/girl/lk',
          active: true
        },
        {
          name: 'Просмотры/отзывы',
          ico: 'item2.svg',
          url: '/girl/view',
          active: false
        },
        {
          name: 'Финансы',
          ico: 'item6.svg',
          url: '/girl/billing',
          active: false
        },
        {
          name: 'Расписание работы',
          ico: 'item9.svg',
          url: '/girl/schedule',
          active: false
        },
      ],
      showLoader: true,
    }
  },
  methods: {
    sidebarHide() {
      this.sidebarShow = false
    },
    activeClean() {
      for (let i of this.menuList) {
        i.active = false
      }
    }
  },
  beforeMount() {
    if (this.isMobile) {
      this.sidebarShow = false
    }
  },
  mounted() {
    if (this.$store.state.user.role.item_name !== 'girl') {
      this.m_setFlash('В доступе отказано', 'У вас нет прав для просмотра этой страницы', 'danger')
      window.location.href = '/'
    } else {
      this.showLoader = false
    }
  },
}
</script>
<template>
  <!--    <ui-loader :show="this.showLoader" :preset="'page'"/>-->
  <div class="container-fluid">
    <div class="bg row flex-column justify-content-between">
      <div class="col-auto px-0">
        <HeaderGirl/>
      </div>
      <div class="col">
        <div class="row content h-100">
          <div class="col page-content" ref="page">
            <router-view/>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <footer class="p-4"></footer>
      </div>
    </div>
  </div>
  <admin-button/>
</template>
<style scoped lang="scss">
.bg {
  background: linear-gradient(93deg, #72666A 0%, #524B5F 49.58%, #201F36 99.15%);
  min-height: 100vh;
}

.content {
  min-height: 100%;
}

.toggle-sidebar.close img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.page-content {
  margin: 0 20px;
  overflow: hidden;
  @media(max-width: 768px) {
    margin: 0;
  }
}

.nav-link {
  font-size: 18px;
  font-weight: 600;
  color: #BDC1D1;
  stroke: #BDC1D1;
}

.nav-item.active .nav-link {
  color: #FFFFFF !important;
  stroke: #FFFFFF;
}
</style>