<script>
import UiSwitch from '@/components/ui/UiSwitch.vue';
import LogInBlock from '@/components/ui/LogInBlock.vue';
import { defineComponent } from 'vue';
import BalanceWidget from '@/components/ui/billing/BalanceWidget.vue';
import HeaderButton from '@/components/blocks/HeaderButton.vue';
import MobileMenuBlock from "@/components/ui/common/MobileMenuBlock.vue";
import ExitBtn from '../ExitButton.vue';
import LogInIcon from '@/components/icons/LogInIcon.vue';

export default defineComponent({
  components: {
    BalanceWidget,
    LogInBlock,
    UiSwitch,
    HeaderButton,
    MobileMenuBlock,
    ExitBtn,
    LogInIcon
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiDomine: this.$store.getters.getApiDomine,
      city: 'Екатеринбург',
      popuCitiesShow: false,
      popupPagesShow: false,
      popupPagesShowMobile: false,
      popuCitiesShowMobile: false,
      isShowMenu: false,
      menuShow: false,
      isSmallScreen: false,
      isActivemenu: false,
      buttonsDropdown: [
        {
          title: 'Анкеты',
          showDropdown: false,
          entries: [
            { text: 'Мои анкеты', link: '/girl/lk' },
            { text: 'Расписание работы', link: '/girl/schedule' },
          ],
        },
        {
          title: 'Продвижение',
          showDropdown: false,
          entries: [
            { text: 'Продвижение на сайте', link: '/girl/promotion' },
            { text: 'Продвижение в Telegram', link: '/girl/promotionTg' },
            { text: 'Поиск заказов', link: '/girl/orders' },
          ],
        },
        {
          title: 'Настройка и баланс',
          showDropdown: false,
          entries: [
            { text: 'Финансы', link: '/girl/billing' },
            { text: 'Настройка аккаунта', link: '/girl/settings' },
          ],
        },
        {
          title: 'О сервисе',
          showDropdown: false,
          entries: [
            { text: 'Для СМИ и авторов статей', link: '#', blank: 'https://www.google.com/' },
            { text: 'История эскорт сайта EGOZA', link: '#', blank: 'https://www.google.com/' },
            { text: 'Telegram канал  ', link: '#', blank: 'https://t.me/EgozaBiz' },
          ],
        },
        {
          title: 'Помощь',
          showDropdown: false,
          entries: [
            { text: 'Раздел в разработке', link: '#'},
          ],
        },
      ],
    };
  },
  computed: {
    isMobile(){
      return this.$store.getters['getIsMobile']
    }
  },
  methods: {
    checkWindowSize() {
      this.isSmallScreen = window.innerWidth <= 1370;
      if (this.isSmallScreen) {
        this.buttonsDropdown.forEach((button) => {
          button.showDropdown = true;
        });
      } else {
        this.buttonsDropdown.forEach((button) => {
          button.showDropdown = false;
        });
      }
    },

    positionMenu(menu, index){
      switch(index){
        case 0: {
          menu.style.left = '410px'
          break
        }
        case 1: {
          menu.style.left = '540px'
          break
        }
        case 2: {
          menu.style.left = '720px'
          break
        }
        default: {
          menu.style.left = '850px'
        }
      }
    },

    visibleDrop(parentBlock, index){
      if(window.innerWidth > 1700){
        this.$nextTick(() => {
          const droMenu = parentBlock.querySelector('.header-admin__drop')
          droMenu.style.display = 'block'
          droMenu.style.top = '70px'
        })
      }else{
        this.$nextTick(() => {
        const droMenu = parentBlock.querySelector('.header-admin__drop').cloneNode(true)
        const wrapperDrop = this.$refs.dropWrapp

        wrapperDrop.innerHTML = ''

        
        wrapperDrop.append(droMenu)
        droMenu.style.display = 'block'
        this.positionMenu(droMenu, index)
      })
      }
    },  
    closeDropDown(dropTarget, boolVal){
      dropTarget.showDropdown = boolVal;
    },
    toggleDropdown(e, index) {
      this.buttonsDropdown.forEach((button, i) => {       
        if (i === index && e.target.closest('.header-admin__btn')) {
          button.showDropdown = true;
          this.wrapperDropActive = true
          this.visibleDrop(e.target.offsetParent, index)
        } else {
          button.showDropdown = false;
        }
      });
    },
   clearMenu(emitType){
    this.isActivemenu = emitType
   },
   wrapperHide(e) {
     this.$refs.dropWrapp.innerHTML = ''
    },
    getCategories() {
      this.list = this.$router.options.routes.filter((route) => route.name);
    },
    pickCity(namePath) {
      this.city = namePath;
      this.popuCitiesShow = false;
      this.popuCitiesShowMobile = false;
    },
    popupCitiesHide() {
      this.popuCitiesShow = false;
    },
    showMenu() {
      this.isShowMenu = true;
    },
    popupCitiesHideMobile() {
      this.popuCitiesShowMobile = false;
    },
    popupPagesHideMobile() {
      this.popupPagesShowMobile = false;
    },
    swipingMenu(
      event,
      element,
      elementHeight,
      startY,
      deltaY,
      classNonTransition,
      swipeResult,
      touchEnd
    ) {
      if (classNonTransition) element.classList.add('non-transition');
      else element.classList.remove('non-transition');
      if (deltaY > 25 || deltaY < -25) element.style.bottom = `-${deltaY}px`;
      if (touchEnd && swipeResult === 'Up' && (deltaY > 25 || deltaY < -25)) {
        this.isShowMenu = true;
        element.style.bottom = '0';
      } else if (
        touchEnd &&
        swipeResult === 'Down' &&
        (deltaY > 25 || deltaY < -25)
      ) {
        this.isShowMenu = false;
        element.style.bottom = '0';
      }
    },
    openSubmenu(i, nav) {
      const currentRef = `userMenuLink_${i}`;
      const element = this.$refs[currentRef][0];
      const isChild = element.querySelectorAll('.header-user-menu__submenu li')
        .length
        ? true
        : false;
      if (!isChild) {
        //this.$router.push(nav)
        return;
      }
      if (element.classList.contains('is-active')) {
        element.classList.remove('is-active');
      } else {
        element.classList.add('is-active');
      }
    },
    openSubmenuMobile() {
      const element = event.target;
      const elementWrap = element.closest('li');
      if (elementWrap.classList.contains('is-active')) {
        elementWrap.classList.remove('is-active');
      } else {
        elementWrap.classList.add('is-active');
      }
    },
  },
  created() {
    this.getCategories();
  },
  mounted() {
    this.$refs.dropWrapp.innerHTML = ''
    window.addEventListener('click', e =>{
      if (e.target.classList.contains('header-admin__btn') || e.target.classList.contains('header-admin__title') || e.target.classList.contains('header-admin__arrow') || e.target.classList.contains('header-admin__open') || e.target.classList.contains('header-admin__close') )  {
        return
      }else if(!this.isSmallScreen){
         this.buttonsDropdown.forEach(button => {
          button.showDropdown = false
         })
      }
    })
    window.addEventListener('resize', this.checkWindowSize);
    this.checkWindowSize();
  },
});
</script>
<template>
  <header class="header-admin border_shadow">
    <div class="row mx-0 justify-content-between align-items-center">
      <div class="col-2 mt-4">
        <div class="logo">
          <router-link to="/"><img src="../../../assets/img/logo.svg" class="logo" /></router-link>
        </div>
      </div>
      <div class="col-10 d-flex align-items-center log-in__header justify-content-end px-0"
        :class="{ active: isActivemenu }">
        <div class="header-admin__btns d-flex gap-4" ref="scrollElem" v-click-outside="wrapperHide">
          <HeaderButton v-for="(item, index) in buttonsDropdown" 
          :key="index" :item="item" :index="index"
            :buttonsDropdown="buttonsDropdown" 
            :isActivemenu="isActivemenu"
            @click="toggleDropdown($event, index)"
            @clearMenu="clearMenu" 
            @closeDropDown="closeDropDown"
            />
        
          <div class="exit-mob d-flex align-items-center visible_wall" v-if="isSmallScreen">
            <LogInIcon :color="'white'" />
            <ExitBtn class="text-white" />
          </div>
        </div>
        <div class="drop_wrapper" ref="dropWrapp">

        </div>
        <div class="d-flex align-items-center gap-3 shadow-wall py-4" v-if="!isSmallScreen">
          <balance-widget :plusUrl="'/girl/billing'" class="visible_wall px-3" />
          <div class="d-flex align-items-center visible_wall">
            <LogInIcon :color="'white'" />
            <ExitBtn class="text-white" />
          </div>
          <div class="header-admin__block visible_wall flex-column pe-3">
            <div class="col-auto">
              <img class="avatar" :src="this.apiDomine + '/web/uploads/' + user.profile.avatar" alt="user" />
            </div>
            <div class="header-admin__name">
              {{ this.user.username }}
            </div>
          </div>
        </div>

        <div class="px-3" v-else>
          <div class="header-admin__block visible_wall">
            <div class="header-admin__name pe-3 ps-3">
              {{ this.user.username }}
            </div>
            <div class="col-auto">
              <img class="avatar" :src="this.apiDomine + '/web/uploads/' + user.profile.avatar" alt="user" />
            </div>
          </div>
          <balance-widget :plusUrl="'/girl/billing'" class="visible_wall" @click="isActivemenu = false" />
        </div>

      </div>
      <div class="col-md-4 header-admin__menu">
        <button>
          <img class="header-admin__open" v-if="!isActivemenu" src="../../../assets/img/menu.svg" alt=""
            @click="isActivemenu = true" />
          <img class="header-admin__close" v-else src="../../../assets/img/menuclose.svg" alt=""
            @click="isActivemenu = false" />
        </button>
      </div>
    </div>
  </header>
</template>
<style lang="scss">
.exit-mob{
  padding: 11px 13px 13px 23px;
}

.shadow-wall{
    position: relative;
    z-index: 1;
    &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        box-shadow: -5px 0px 4px 0px rgb(48, 47, 64);
        background: rgb(55, 52, 73);
        top: 0;
    }
}
.border_shadow{
    border-bottom: 1px solid #BDC1D1;
    margin-bottom: 40px;
}
.drop_wrapper{
  position: absolute;
  left: 0;
  top: 120px;
  max-width: 480px;
  width: 100%;
}
.visible_wall{
  position: relative;
  z-index: 20;
}
.header-admin {

  &__name {
    color: #fff;
    cursor: pointer;
    text-align: center;
    @media (max-width: 1370px) {
      padding: 0 !important;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    @media (max-width: 1370px) {
      gap: 18px;
      flex-direction: row-reverse;
      margin-bottom: 8px;
    }
  }
  &__btns {
      overflow: visible;
      width: auto;
      margin-right: 100px;
    @media (max-width: 1700px) {
      overflow: auto !important;
      max-width: 800px;
      z-index: 0;
      right: 290px;
      padding: 15px 15px;
      margin-right: 0px;
    }
    @media (max-width: 1370px) {
      flex-direction: column;
      overflow: auto;
      width: 100%;
      margin-right: 0 !important;
      padding: 0;
    }
  }
  &__close {
    position: fixed;
    right: 25px;
    top: 25px;
    z-index: 9999;
  }
  &__open {
    position: absolute;
    top: 23px;
    right: 35px;
  }
  &__menu {
    display: none !important;
    @media (max-width: 1370px) {
      display: flex !important;
      justify-content: flex-end;
    }
  }
  @media (max-width: 1370px) {
    padding: 1.5rem 0rem;
    border-bottom: none;
  }
}

.header-admin__btns::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

.header-admin__btns::-webkit-scrollbar-track {
  background: none;
}

.header-admin__btns::-webkit-scrollbar-thumb {
  background-color: #645d81;
  border-radius: 4px; 
  cursor: pointer;
}

.body-backdrop {
  position: fixed;
  backdrop-filter: blur(8px);
  background-color: rgba(#000, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.log-in {
  &__btn {
    button {
      display: inline-block;
      border-radius: 15px;
      transition: 0.3s;
      background-color: #524b5f;
      color: #fff;
      padding: 5px 10px;

      &:hover {
        box-shadow: 0 0 5px rgba(#fff, 0.5) inset;
      }
    }
  }
  &__header {
    @media (max-width: 1370px) {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: start !important;
      flex-direction: column-reverse;
      background: #413d51;
      z-index: 999;
      padding-top: 11px;
      transform: translateX(100%);
      transition: 0.5s;
    }
    &.active {
      transform: translateX(0);
    }
  }
}


.avatar {
  border-radius: 50%;
  height: 58px;
  width: 58px;
  margin-bottom: 5px;
}
.logo {
  max-width: 160px;
  height: auto;
}
.balance-widget {
  @media (max-width: 1370px) {
    margin-bottom: 13px;
  }
}
</style>