<script>
import UiSwitch from "@/components/ui/UiSwitch.vue";
import "@/styles/icon-style.css";
import router from "@/router/router";

import ExitBtn from "@/components/ui/ExitButton.vue";
import LogInBlock from "@/components/ui/LogInBlock.vue";
import UiSearch from "@/components/ui/UiSearch.vue";
import LogInCard from "@/components/blocks/LogInCard.vue";
import NavHeaderGirl from "@/components/blocks/NavHeaderGirl.vue";
import header from "@/mixins/header";
import NavHeaderUser from "@/components/blocks/NavHeaderUser.vue";
import NavHeaderAdmin from "@/components/blocks/NavHeaderAdmin.vue";
import UiSearchDistrict from "@/components/ui/UiSearchDistrict.vue";
import SettingIcon from "@/components/icons/SettingIcon.vue";
import MetroIcon from "@/components/icons/MetroIcon.vue";
import LogInIcon from "@/components/icons/LogInIcon.vue";
import MobileMenuBlock from "@/components/ui/common/MobileMenuBlock.vue";
import UiGeo from "@/components/ui/UiGeo.vue";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import PromotionIcon from "@/components/icons/PromotionIcon.vue";
import CityIcon from "@/components/icons/CityIcon.vue";
import FinanceIcon from "@/components/icons/FinanceIcon.vue"
import SettingSliderIcon from "@/components/icons/SettingSliderIcon.vue";
import RocketIcon from "@/components/icons/Rocket.vue"
import WorkTime from "@/components/icons/WorkTime.vue"
import CristallIcon from "@/components/icons/CristallIcon.vue";
import userIcon from "@/components/icons/UserIcon.vue";
import OrderModal from '@/components/order_modal/OrderModal.vue';

export default {
  components: {
    UiGeo,
    MobileMenuBlock,
    LogInIcon,
    MetroIcon,
    SettingIcon,
    UiSearchDistrict,
    NavHeaderAdmin,
    NavHeaderUser,
    NavHeaderGirl,
    LogInCard,
    UiSearch,
    UiSwitch,
    router,
    ExitBtn,
    LogInBlock,
    HeartIcon,
    PromotionIcon,
    CityIcon,
    FinanceIcon,
    SettingSliderIcon,
    RocketIcon,
    WorkTime,
    CristallIcon,
    userIcon,
    OrderModal
  },
  name: "HeaderDefault",
  mixins: [header],
  data() {
    return {
      user: this.$store.getters.getUser,
      apiDomine: this.$store.getters.getApiDomine,
      title: "Look at me",
      city: "Екатеринбург",
      show: false,
      popupPagesShow: false,
      popuCitiesShow: false,
      popupPagesShowMobile: false,
      popuCitiesShowMobile: false,
      searchPagesShow: false,
      searchDistrictShow: false,
      searchCityShow: false,
      isShowMenu: false,
      menuShow: false,
      mobileMenu: false,
      list: [],
      cities: [],
      isShowOrderModal: false,
      isModalTg: false,
      isModalTgEntry: false
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters['getIsMobile']
    }
  },

  watch: {
    'user.isLogged'() {
      this.isShowMenu = false
    },
  },

  created() {
    this.getCategories();
    this.checkIosVersion()
  },
  methods: {
    menuHide(event) {
      if (event.target.className === "mobile-menu-layer") {
        this.isShowMenu = false;
      }
    },

    outsideCustom(event) {
      if (event.target.className === 'dialog') {
        this.show = false
      }
    },

    checkIosVersion() {
      const userAget = navigator.userAgent.toLowerCase()
      const isIphone = /iphone/ig.test(userAget)
      const pattern = /version\/(\d+\.\d+(\.\d+)?)/;
      const versionRegExp = isIphone ? +userAget.match(pattern)[1] : ''
      const version = versionRegExp.length > 4 ? versionRegExp.slice(0, -2) : versionRegExp

      if (isIphone && +version < 17.1) {
        return false
      } else {
        return true
      }
    },
    hideDialog() {
      this.show = false

    },
    tgmodal(el) {
      if (el == false) {
        this.isModalTg = false
      } else {
        this.isModalTg = true
      }
    }

  }
};
</script>
<template>
  <header class="header p-md-4 d-flex" v-if="!isMobile">
    <div class="container">
      <div class="container-fluid">
        <div v-if="this.user.role.item_name == 'guest'" class="row justify-content-between">
          <div class="col-2 d-flex align-items-center">
            <router-link to="/"><img src="../../../assets/img/logo.svg" class="logo"></router-link>
          </div>
          <div class="row col-auto">
            <router-link :to="{ name: 'promo' }" class="col-auto text-nav cursor">
              <span><rocket-icon :color="'#fff'" class="me-1"></rocket-icon>Промо</span>
            </router-link>
            <div class="col-auto text-nav cursor" @click="searchPagesShow = true">
                            <span>
                                <setting-icon :color="'#fff'" class="me-1"/> Предпочтения
                            </span>
            </div>
            <div class="col-auto text-nav cursor">
                            <span class="align-items-start" @click="isShowOrderModal = true">
                              <CristallIcon class="me-1" color="white" :size="{w: 21, h: 18}"/>
                                Создать заказ
                            </span>
            </div>
            <div class="col-auto text-nav cursor" @click.stop="searchDistrictShow = true">
                            <span>
                                <ui-geo :cities="this.$store.getters.getCities"></ui-geo>
                            </span>
            </div>
            <div class="col-auto d-flex align-items-center">
              <button class="btn btn-nav hover-btn" @click="addProfile">Добавить анкету</button>
            </div>
            <!-- <div class="col-auto d-flex align-items-center">
                                    <ui-switch/>
        </div> -->
            <div class="col-auto fw-bold d-flex align-items-center cursor" v-if="!this.user.isLogged"
                 @click="this.show = true">
              <LogInIcon :color="'#fff'"/>
              Войти
            </div>
          </div>
          <div v-if="this.user.isLogged" class="col-auto fw-bold d-flex align-items-center cursor">
            {{ this.user.profile?.name }}
            <div class="header-user__block">
              <div v-if="this.user.isLogged" class="header-user__photo" @click.stop="menuShow = true">
                <div v-if="this.user.isLogged">
                  <img src="../../../assets/img/user.png" alt="user"/>
                </div>
                <div v-else>
                  <img class="avatar" :src="this.apiDomine + '/web/uploads/avatar/no_photo.jpg'"
                       alt="user"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav-header-girl v-if="this.user.role.item_name == 'girl'"/>
        <nav-header-user v-if="this.user.role.item_name == 'user'"/>
        <nav-header-admin v-if="this.user.role.item_name == 'admin'"/>

      </div>

    </div>


  </header>
  <header v-if="isMobile" class="position-relative">
    <div :class="this.checkIosVersion ? 'iphone-header' : 'header-mobile'">
      <div class="container">
        <div class="row justify-content-between">
          <div class="header-left col-6">
            <router-link to="/" class=" d-flex align-items-center"><img src="../../../assets/img/logo.svg"
                                                                        class="mobile-logo"></router-link>
          </div>
          <div class="col-6 header-mobile__burger">
            <div @click="this.isShowMenu = true" class="menu-burger-button text-end d-flex align-items-center">
              <img src="../../../assets/img/burger.png" alt="">
            </div>
          </div>
        </div>
        <mobile-menu-block :show="isShowMenu" @close="isShowMenu = false">
          <div v-if="!user.isLogged" class="notLogged" v-click-outside="menuHide">
            <div class="row justify-content-center mb-4 mt-4">
              <div class="col-auto d-flex justify-content-center align-items-center"
                   v-if="!this.user.isLogged" @click="() => {this.show = true, isShowMenu = false}">
                <LogInIcon :color="'#525665'"/>
                <span class="ps-1">Войти</span>
              </div>
            </div>

            <div class="d-flex flex-column gap-3 mb-5">
              <router-link class="col-12 mt-2 text-nav cursor justify-content-center" :to="{name: 'promo'}"
                           @click="isShowMenu = false">
                            <span class="d-flex gap-2 text_notLogged">
                                <rocket-icon :color="'#525665'" class="me-1"></rocket-icon>
                                Промо</span>
              </router-link>
              <div class="col-12 mt-2 text-nav cursor justify-content-center" @click="searchPagesShow = true">
                            <span class="d-flex gap-2 text_notLogged" @click="isShowMenu = false">
                                <SettingIcon :color="'#525665'"/>
                                Предпочтения
                            </span>
              </div>
              <div class="col-12 mt-2 text-nav cursor justify-content-center">
                            <span class="text_notLogged gap-2" @click="isShowOrderModal = true; isShowMenu = false">
                              <CristallIcon :color="'#525665'" :size="{w: 21, h: 21}"/>
                                Создать заказ
                            </span>
              </div>
              <div class="col-12 mt-2 text-nav cursor justify-content-center"
                   @click.stop="searchDistrictShow = true">
                            <span class="text_notLogged">
                                <ui-geo :icon-color="'#525665'" :cities="this.$store.getters.getCities"
                                        @menuHideGeo="isShowMenu = false"></ui-geo>
                            </span>
              </div>
            </div>
            <div class="custom_link">
              <router-link @click="isShowMenu = false" to="/mobile-streams"
                           class="position-absolute d-flex flex-column align-items-center link_heart_cust">
                <img src="@/assets/img/iconHeart3d.svg" alt="icon"/>
                <span class="text-white lh-base">Поток</span>
              </router-link>
            </div>
          </div>

          <div class="user_wind_mobile" v-if="user.role.item_name === 'girl'" v-click-outside="menuHide">
            <div class="avatar_mobile p-3">
              <router-link to="/girl/lk">
                <img :src="this.apiDomine + '/web/uploads/' + user.profile.avatar" alt="img">
                <p class="titile_mob_menu text-nav">{{ user.username }}</p>
              </router-link>
            </div>
            <div class="d-flex flex-column gap-3 mb-5 p-3 lh-base router__link">
              <router-link class="d-flex align-items-center" :to="{name: 'promo'}">
                <rocket-icon :color="'#525665'" class="me-1"></rocket-icon>
                <span
                    class="text_nav_mobile">Промо</span>
              </router-link>
              <router-link class="d-flex align-items-center" to="/girl/lk">
                <HeartIcon :color="'#979AA3'"/>
                <span class="text_nav_mobile">Мои анкеты</span>
              </router-link>
              <router-link class="d-flex align-items-center" to="/girl/promotion">
                <PromotionIcon :color="'#979AA3'"/>
                <span class="text_nav_mobile">Продвижение</span>
              </router-link>
              <router-link class="d-flex align-items-center" to="/girl/billing">
                <FinanceIcon :color="'#979AA3'"/>
                <span class="text_nav_mobile">Финансы</span>
              </router-link>
              <router-link class="d-flex align-items-center" to="/girl/settings">
                <SettingSliderIcon :color="'#979AA3'"/>
                <span class="text_nav_mobile">Настройки
                                аккаунта</span>
              </router-link>
              <div class="d-flex align-items-center">
                            <span class="d-flex align-items-center">
                                <LogInIcon :color="'#979AA3'"/>
                                <ExitBtn class="text_nav_mobile"/>
                            </span>
              </div>
            </div>
            <div class="custom_link" @click="isShowMenu = false">
              <router-link to="/mobile-streams"
                           class="position-absolute d-flex flex-column align-items-center link_heart_cust">
                <img src="@/assets/img/iconHeart3d.svg" alt="icon"/>
                <span class="text-white lh-base">Поток</span>
              </router-link>
            </div>
          </div>

          <div class="user_wind_mobile" v-if="user.role.item_name === 'user'" v-click-outside="menuHide">
            <div class="avatar_mobile p-3">
              <router-link @click="isShowMenu = false" to="/user/settings">
                <img :src="this.apiDomine + '/web/uploads/' + user.profile.avatar" alt="img">
                <p class="titile_mob_menu text-nav">{{ user.username }}</p>
              </router-link>
            </div>

            <div class="d-flex flex-column gap-3 mb-5 p-3">
              <router-link @click="isShowMenu = false" to="/collections" class="d-flex align-items-center">
                <HeartIcon :color="'#979AA3'"/>
                <span class="text_nav_mobile">Мои сохраненки</span>
              </router-link>
              <router-link @click="isShowMenu = false" to="/user/settings" class="d-flex align-items-center">
                <SettingSliderIcon :color="'#979AA3'"/>
                <span class="text_nav_mobile">Настройки
                                аккаунта</span>
              </router-link>
              <div class="col-12 cursor" @click.stop="searchDistrictShow = true">
                            <span class="text_notLogged">
                                <ui-geo :icon-color="'#979AA3'" :cities="this.$store.getters.getCities"
                                        @menuHideGeo="isShowMenu = false"></ui-geo>
                            </span>
              </div>
              <div @click="() => {isShowMenu = false, searchPagesShow = true}" class="d-flex align-items-center">
                <SettingIcon :color="'#979AA3'"/>
                <span class="text_nav_mobile">
                                Предпочтения</span>
              </div>
              <router-link @click="isShowMenu = false" to="/user-orders" class="d-flex align-items-center">
                <CristallIcon color="#979AA3" :size="{w: 21, h: 20}"/>
                <span class="text_nav_mobile">
                  Мои заказы</span>
              </router-link>
              <div class="col-12 cursor">
                            <span class="d-flex align-items-center">
                                <LogInIcon :color="'#979AA3'"/>
                                <ExitBtn class="text_nav_mobile"/>
                            </span>
              </div>
            </div>
            <div class="custom_link">
              <router-link to="/mobile-streams"
                           class="position-absolute d-flex flex-column align-items-center link_heart_cust">
                <img src="@/assets/img/iconHeart3d.svg" alt="icon" @click=" isShowMenu = false"/>
                <span class="text-white lh-base">Поток</span>
              </router-link>
            </div>
          </div>

          <div class="user_wind_mobile" v-if="user.role.item_name === 'admin'" v-click-outside="menuHide">
            <div class="d-flex flex-column gap-3 mb-5">
              <router-link class="col-12 mt-2 text-nav cursor justify-content-center" to="/admin/moderation/feedback"
                           @click="isShowMenu = false">
                            <span class="d-flex gap-2 text_notLogged">
                                <user-icon :color="'#525665'" class="me-1"/>
                                Модерация</span>
              </router-link>
              <div class="col-12 mt-2 text-nav cursor justify-content-center" @click="searchPagesShow = true">
                            <span class="d-flex gap-2 text_notLogged" @click="isShowMenu = false">
                                <PromotionIcon :color="'#525665'"/>
                                Продвижение
                            </span>
              </div>
              <div class="col-12 mt-2 text-nav cursor justify-content-center">
                            <span class="text_notLogged"
                                  @click.stop="() => {$router.push('/admin/lists'), isShowMenu = false}">
                                <SettingSliderIcon :color="'#525665'"/>
                                Настройки аккаунта
                            </span>
              </div>
              <div class="cursor mt-2 d-flex justify-content-center text-nav">
                            <span class="d-flex align-items-center">
                                <LogInIcon :color="'#979AA3'"/>
                                <ExitBtn class="text_nav_mobile"/>
                            </span>
              </div>
            </div>
          </div>
        </mobile-menu-block>
      </div>
    </div>
  </header>

  <log-in-card @tgmodal="tgmodal" :show="this.show" @loginSuccess="hideDialog" @hideDialog="hideDialog"
               @click="outsideCustom"/>
  <ui-search
      @closeOutside="searchPagesShow = false"
      :show="searchPagesShow"
      @close="searchPagesShow = false;
      isShowMenu = false"
  />
  <!--    <ui-search-district
      :show="searchDistrictShow"
      @searchDistrictClose="searchDistrictShow = false"
      :cities="this.$store.getters.getCities"
  />-->
  <OrderModal
      v-if="isShowOrderModal"
      @hide-modal="isShowOrderModal = false"
  ></OrderModal>
  <div class="dialog-modal" v-if="isModalTg" @click="isModalTg = false">
    <div class="modal-register" @click.stop>
      <p class="modal-register__text">Ваш аккаунт еще не активирован! <br/> Перейдите в <a
          href="https://t.me/Egoza_push_bot" target="_blank">Telegram bot</a>, отправьте боту /start, чтобы активировать
        аккаунт</p>
      <button class="modal-register__close" @click="isModalTg = false">X</button>
    </div>
  </div>

</template>
<style scoped lang="scss">
@import "@/styles/index";

.dialog-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 99999;
}

.modal-register {
  max-width: 470px;
  width: 100%;
  background: #fff;
  padding: 70px 5px;
  position: relative;
  border-radius: 9px;

  &__text {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 117%;
    text-align: center;
    color: #525665;

    & a {
      text-decoration: underline;
      text-decoration-skip-ink: none;
      color: #4ba5ff;
      cursor: pointer
    }
  }

  &__close {
    position: absolute;
    top: -50px;
    right: 0;
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 9px;
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }
  }
}

.active_geo {
  opacity: 0;
}

.fixed-height {
  height: 50px !important;
}

.custom_link {
  border-top: 2px solid #FF8CAF;
  background: #1D192A;
  height: 60px;
  bottom: 0;
  position: relative;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .link_heart_cust {
    position: absolute;
    text-decoration: none;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text_notLogged {
  color: rgb(82, 86, 101);
  line-height: 1.5;
  font-weight: 400;
}

.avatar_mobile {
  margin-top: 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    object-fit: cover;
  }

  .titile_mob_menu {
    color: rgb(82, 86, 101);
    font-size: 15.38px;
    font-weight: 800;
    line-height: 20px;
    margin-top: 15px;
    justify-content: center;
  }

  a {
    text-align: center;
  }
}

.header {
  background: linear-gradient(to right, #72666A, #201F36);
  color: $white;
  z-index: 3;
}

.mobile-logo {
  width: 120px;
}

.router__link a {
  max-width: 200px;
  width: 100%;
}

.logo {
  width: 120px;
}

.text-nav {
  display: flex;
  align-items: center;
}

.text_nav_mobile {
  color: rgb(82, 86, 101);
  line-height: 1.5;
  padding-left: 0.5rem;
  font-weight: 400;
}

.text-nav span {
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: center;
}

.text-nav img {
  margin-right: 10px;
}

.btn-nav {
  border-radius: 35px;
  background: #72666A;
  color: #fff;
  cursor: pointer;
}

.btn-nav:hover {
  border-radius: 35px;
  background: #72666A;
  color: #fff;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

a {
  color: #fff;
}

.menu-burger-button {
  div {
    width: 25px;
    height: 3px;
    background-color: #fff;
    border-radius: 100px;
    margin-bottom: 4px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.non-transition {
  transition: none !important;
}


.mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -5px 12px 3px rgba(0, 0, 0, 0.065);
  border-radius: 15px 15px 0 0;
  transition: 0.4s;
  z-index: 200;
  padding-bottom: 18px;
  height: 50vh;
  padding-left: 10px;
  padding-right: 10px;

  .header-user__drop-menu {
    @media (max-width: 1023px) {
      position: fixed;

      &.show {
        overflow-y: scroll;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: block;
  }

  &.show {
    transform: translateY(0);
  }

  &__touch-box {
    position: relative;
    height: 30px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 35px;
      height: 5px;
      border-radius: 50px;
      background-color: rgba($color: #000000, $alpha: 0.15);
    }
  }

  ul {
    margin-top: 15px;
    padding: 0;

    li {
      font-weight: 500;
      text-align: center;
      margin-bottom: 12px;
    }
  }
}

.header-desktop {
  display: block;

  @media ($tablet) {
    display: none;
  }
}

.iphone-header {
  position: relative;
  z-index: 999;
  background: rgba(186, 177, 175, 0.9);
}

.header-mobile {
  background: rgba(186, 177, 175, 0.9);
  z-index: 999;
  width: 100%;
  @media ($tablet) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__burger {
    display: flex;
    justify-content: flex-end;
  }

  .header-midd__choice {
    background-color: #e7e5e5;
  }

  .header-midd__inner {
    max-width: 100%;
    max-width: 80%;
  }

  .header__choice {
    margin: 0;
  }

  .header-left {
    margin-right: auto;
    display: flex;
    text-align: left;
  }

  .drop-list__list {
    &.show {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transform: translate(0, 0);
    }
  }
}

.body-backdrop {
  position: fixed;
  backdrop-filter: blur(8px);
  background-color: rgba(#000, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .text-nav {
    justify-content: center;
  }

  .text-nav span {
    border-bottom: 1px solid #BDC1D1;
    display: flex;
    align-items: center;
    line-height: 33px;
  }
}
</style>